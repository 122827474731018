import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { RouterLink, RouterOutlet } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { FileUploadService } from '../../../services/file-upload.service';
import { AppLoaderService } from '../../../shared/app-loader/app-loader.service';
import { ExcelExportService } from '../../../services/excel.service';
import Swal from 'sweetalert2';
import { validate } from 'uuid';

interface validateProducts {
  id: string;
  productName: string;
  llc: string;
  mlc: string;
  displayOrder: number;
}
@Component({
  selector: 'product-bulk-import-pop-up',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatButtonModule,
    RouterOutlet,
    MatInputModule,
    MatNativeDateModule,
    MatCardModule,
    MatIconModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    CommonModule,
    MatTableModule,
    RouterLink,
    ReactiveFormsModule,
  ],
  providers: [AppLoaderService],
  templateUrl: './product-bulk-import-pop-up.component.html',
})
export class ProductBulkImportPopUpComponent implements OnInit {
  selectedFileName: any;
  isExcelFileInvalid: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ProductBulkImportPopUpComponent>,
    private fileUploadService: FileUploadService,
    private egretLoader: AppLoaderService,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void { }

  async onSelectExcelFile(event: any) {
    try {
      const file: File = event.target.files[0];
      if (!file) {
        this.selectedFileName = null;
        return;
      }

      const excelData = await this.getExcelData(file);
      const mappedData = this.mapExcelData(excelData);
      this.selectedFileName = file.name;
    } catch (error) {
      console.error('Error processing Excel file:', error);
    }
  }
  async readExcelSheet(file: File): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        const workbook = XLSX.read(e.target.result, { type: 'binary' });
        const sheet = workbook.Sheets['DisplayOrder'];
        const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        resolve(sheetData);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsBinaryString(file);
    });
  }

  private async getExcelData(file: File): Promise<any[]> {
    const binaryStr = await this.readExcelSheet(file);
    const headerRow: string[] = binaryStr[0];
    const dataRows: any[] = binaryStr.slice(1);

    return dataRows.map((row) => {
      const rowData: any = {};
      headerRow.forEach((header, index) => {
        rowData[header] = row[index];
      });
      return rowData;
    });
  }

  private mapExcelData(excelData: any[]): validateProducts[] {
    return excelData.map((row: any) => ({
      id: row['Id'],
      productName: row['Product Name'],
      llc: row['Category L1'],
      mlc: row['Category L2'],
      displayOrder: row['Display Order']
    }));
  }

  resetFileInput() {
    const fileInput = document.getElementById('excelInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  async uploadExcel(): Promise<void> {
    try {
      this.egretLoader.open();
      const fileInput = document.getElementById('excelInput') as HTMLInputElement;
      if (!fileInput || !fileInput.files) {
        throw new Error('File input or files not found.');
      }

      const file: File = fileInput.files[0];
      if (!file) {
        Swal.fire('No file selected.');
        return;
      }

      const reader = new FileReader();
      reader.onload = async (e: any) => {
        try {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetData = workbook.Sheets[workbook.SheetNames[0]];
          const rows: any[] = XLSX.utils.sheet_to_json(sheetData, { header: 1 });

          if (workbook.SheetNames.length !== 1 || workbook.SheetNames[0] !== 'DisplayOrder') {
            Swal.fire('', 'Excel file must contain exactly one sheet named "DisplayOrder".', 'warning');
            this.egretLoader.close();
            return;
          }
          const knownHeaders = ['Id', 'Product Name', 'Category L1', 'Category L2', 'Display Order'];

          // Check if the sheet is empty (has only headers or less)
          if (rows.length === 0) {
            Swal.fire('', 'Excel file is empty.', 'warning');
            this.egretLoader.close();
            return;
          }

          const headerRow: string[] = rows[0];
          for (const header of knownHeaders) {
            if (!headerRow.includes(header)) {
              Swal.fire('', `Missing expected header: ${header}`, 'warning');
              this.egretLoader.close();
              return;
            }
          }
          const idIndex = headerRow.indexOf('Id');
          const productNameIndex = headerRow.indexOf('Product Name');
          const llcIndex = headerRow.indexOf('Category L1');
          const mlcIndex = headerRow.indexOf('Category L2');
          const displayOrderIndex = headerRow.indexOf('Display Order');
          const headersToCheck = headerRow.slice(0, 5);
          const headerSet = new Set(headersToCheck);

          if (headerSet.size !== headersToCheck.length) {
            Swal.fire('', 'Excel file contains duplicate headers in the first five columns.', 'warning');
            this.egretLoader.close();
            return;
          }

          if (headerRow.length > knownHeaders.length) {
            Swal.fire('', 'Excel file contains extra headers beyond the expected columns.', 'warning');
            this.egretLoader.close();
            return;
          }

          const headersMatch = knownHeaders.every(header => headerRow.includes(header));

          if (!headersMatch) {
            Swal.fire('', 'Headers in the Excel file do not match the expected headers.', 'warning');
            this.egretLoader.close();
            return;
          }

          if (rows.length === 1) {
            Swal.fire('', 'Excel file contains only headers, no data rows.', 'warning');
            this.egretLoader.close();
            return;
          }

          // Validate the "ID" column's data for UUIDs
          for (let i = 1; i < rows.length; i++) {
            const row = rows[i];
            const displayOrderValue = row[displayOrderIndex];
            const idValue = row[idIndex];

            if (!idValue || idValue.trim() === '') {
              Swal.fire('', `Row ${i + 1}: Id cannot be empty.`, 'warning');
              this.egretLoader.close();
              return;
            }

            if (!validate(idValue)) {
              Swal.fire('', `Row ${i + 1}: Invalid Data in Id column.`, 'warning');
              this.egretLoader.close();
              return;
            }

            const productNameValue = row[productNameIndex];
            const llcValue = row[llcIndex];
            const mlcValue = row[mlcIndex];

            if (typeof productNameValue !== 'string') {
              Swal.fire(`Row ${i + 1}: Product Name must be a string.`, 'warning');
              this.egretLoader.close();
              return;
            }
            if (typeof llcValue !== 'string') {
              Swal.fire(`Row ${i + 1}: Category L1 must be a string.`, 'warning');
              this.egretLoader.close();
              return;
            }
            if (typeof mlcValue !== 'string') {
              Swal.fire(`Row ${i + 1}: Category L2 must be a string.`, 'warning');
              this.egretLoader.close();
              return;
            }

            if (displayOrderValue !== undefined && displayOrderValue !== '') {
              if (isNaN(displayOrderValue) || typeof displayOrderValue !== 'number') {
                Swal.fire('', `Row ${i + 1}: Display Order must be a number.`, 'warning');
                this.egretLoader.close();
                return;
              }
            }
            if (displayOrderValue !== undefined && displayOrderValue !== '') {
              if (isNaN(displayOrderValue) || typeof displayOrderValue !== 'number' || !Number.isInteger(displayOrderValue)) {
                Swal.fire('', `Row ${i + 1}: Display Order must be a whole number (no decimals).`, 'warning');
                this.egretLoader.close();
                return;
              }
            }


          }
          const updatedExcelData = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'binary',
          });
          const mergedBase64Data = btoa(updatedExcelData);
          const apiResponse: any = await this.fileUploadService.uploadExcelFile(
            file.name.split('.')[0], 'xlsx', mergedBase64Data
          );
          const excelURL = apiResponse.data.url;

          this.egretLoader.close();
          this.dialogRef.close({ excelURL: excelURL, fileName: file.name });
        } catch (error) {
          console.error('Error processing Excel:', error);
          this.egretLoader.close();
        }
      };
      reader.readAsArrayBuffer(file);
      await new Promise((resolve) => (reader.onloadend = resolve));
    } catch (error) {
      console.error('Error uploading Excel:', error);
    } finally {
      this.resetFileInput();
      this.selectedFileName = null;
    }
  }

}
