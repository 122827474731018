@if(hasView){
<ng-container >
<h2 class="card-title m-3" style="color:#4A2981 ;">Manage Users</h2>
<div class="m-3 flex flex-col md:flex-row justify-between">

    <div class="col-md-6">
        <mat-form-field>
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="search" #input>
          </mat-form-field>
    </div>
    <button mat-raised-button color="primary"
    click.delegate="navigateToCreateCouponPage()"
        routerLink="/dashboard/users/create-user">Create User</button>
</div>

<mat-card class="p-0">
    <mat-card-content class="p-0">
        <mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef > ID </mat-header-cell>
                <mat-cell *matCellDef="let row" data-label="id"> {{row._id}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef > Name </mat-header-cell>
                <mat-cell *matCellDef="let row" data-label="name"> {{row.name}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="email">
              <mat-header-cell *matHeaderCellDef > Email Id </mat-header-cell>
              <mat-cell *matCellDef="let row" data-label="email"> {{row.email_id}} </mat-cell>
          </ng-container>
  <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef class="mat-column-actions" > Action </mat-header-cell>
                <mat-cell *matCellDef="let row" class="mat-column-actions">
                    <div class="d-flex">
                      @if(hasEdit){
                        <button mat-raised-button color="primary" [routerLink]="['/dashboard/users/edit-user',row.id]">Edit</button>
                       <!--  <button mat-raised-button class="ms-2" color="primary"
                            (click)="deleteRole(row.id)">Delete</button> -->
                       }
                    </div>


                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="pageSizeOptions"></mat-paginator>

    </mat-card-content>
</mat-card>
</ng-container>
} @else {
  <ng-container>
    <p class="text-center">
      You dont have permission , Please contact administrator
    </p>
  </ng-container>
  }

