export interface ErrorEntity {
  status: number;
  updatedTimeStamp: number;
  id: string;
  messageResult: {
    message: string;
    errors: string[];
    status: number;
  };
  createdDate: string;
  messageBody: string;
  messageType: string;
  createdTimeStamp: number;
}
export interface ErrorSearchFilter {
  startDate: string;
  endDate: string;
  messageType: string;
}
export interface MessageType {
  id: number;
  messageType: string;
}

export var HMQueueMessageTypes = [
  {
    id: 1,
    messageType: 'BrandCreated',
  },
  {
    id: 31,
    messageType: 'BulkImport',
  },
  {
    id: 2,
    messageType: 'CompanyCreated',
  },
  {
    id: 23,
    messageType: 'CreateCoupon',
  },
  {
    id: 25,
    messageType: 'CreatePromotion',
  },
  {
    id: 29,
    messageType: 'CreateSupplier',
  },
  {
    id: 44,
    messageType: 'CreateTasks',
  },
  {
    id: 27,
    messageType: 'CustomerBeat',
  },
  {
    id: 41,
    messageType: 'DeActivateSalesPerson',
  },
  {
    id: 24,
    messageType: 'DeleteCoupon',
  },
  {
    id: 26,
    messageType: 'DeletePromotion',
  },
  {
    id: 9,
    messageType: 'GenerateCreditNote',
  },
  {
    id: 33,
    messageType: 'GenerateDeliveryTrips',
  },
  {
    id: 7,
    messageType: 'GenerateInvoice',
  },
  {
    id: 8,
    messageType: 'GenerateInvoiceByTrips',
  },
  {
    id: 37,
    messageType: 'GenerateOrders',
  },
  {
    id: 34,
    messageType: 'GeneratePickupTrips',
  },
  {
    id: 35,
    messageType: 'GenerateVendorDrops',
  },
  {
    id: 28,
    messageType: 'ImageProcessing',
  },
  {
    id: 36,
    messageType: 'LogisticsEvents',
  },
  {
    id: 40,
    messageType: 'MapSalesPersonToCluster',
  },
  {
    id: 11,
    messageType: 'OrderCancel',
  },
  {
    id: 3,
    messageType: 'OrderCreated',
  },
  {
    id: 4,
    messageType: 'OrderModified',
  },
  {
    id: 5,
    messageType: 'OrderReturned',
  },
  {
    id: 6,
    messageType: 'OrderStatusSync',
  },
  {
    id: 39,
    messageType: 'Performance',
  },
  {
    id: 16,
    messageType: 'PICreatedR',
  },
  {
    id: 15,
    messageType: 'POCreatedR',
  },
  {
    id: 12,
    messageType: 'ProductCreated',
  },
  {
    id: 43,
    messageType: 'RefreshClusters',
  },
  {
    id: 13,
    messageType: 'SKUAdded',
  },
  {
    id: 14,
    messageType: 'SKUUpdated',
  },
  {
    id: 17,
    messageType: 'SPCreated',
  },
  {
    id: 32,
    messageType: 'StockUpdate',
  },
  {
    id: 18,
    messageType: 'SyncInventory',
  },
  {
    id: 10,
    messageType: 'SyncPayment',
  },
  {
    id: 38,
    messageType: 'Targets',
  },
  {
    id: 42,
    messageType: 'UpdateClusterPolygon',
  },
  {
    id: 19,
    messageType: 'UpdateMargin',
  },
  {
    id: 20,
    messageType: 'UpdatePricingRule',
  },
  {
    id: 30,
    messageType: 'UpdateSupplier',
  },
  {
    id: 22,
    messageType: 'VendorGeoMapping',
  },
  {
    id: 21,
    messageType: 'VendorSync',
  },
];
