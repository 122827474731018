import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, map } from 'rxjs';
import { SummaryDictItem } from '../models/bms-reports-model';

@Injectable({
  providedIn: 'root',
})
export class BmsReportsService {
  baseServiceUrl: string = `${environment.logisticsUrl}`;
  logisticsUrl: string = `${environment.logisticsUrl}`;
  messagingService: string = `${environment.messagingService}`;
  coreServiceUrl: string = `${environment.coreServiceUrl}`;

  constructor(public http: HttpClient) {}

  async updateBulkUtr(data: SummaryDictItem[]) {
    return await lastValueFrom(
      this.http.post(`${this.coreServiceUrl}/api/vendor_settlements/update_utr_bulk`, data)
    );
  }
}
