import { CommonModule, JsonPipe } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import Swal from 'sweetalert2';
import { ReplaceCommasPipe } from '../../../shared/pipes/currency-pipe';
import { DownloadType, GateWayType, MarginBusinessType, PaymentStatus, SellerPayoutType, SwalMessageTypes } from '../../../enums/enums';
import { getDateYYYYMMDD } from '../../../utlity/utility';
import { PageId } from '../../../constants/enums';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SellerReportsService } from '../../seller-reports/services/seller-reports.service';
import { MerchantsService } from '../../merchant/services/merchants.service';
import { UploadUtrReportsPopUp } from '../upload-utr-reports/upload-utr-reports-pop-up.component';
import { AppLoaderService } from '../../../shared/app-loader/app-loader.service';
import saveAs from 'file-saver';
import { OrderService } from '../../orders/service/order.service';

@Component({
  selector: 'app-insights-reports',
  standalone: true,

  imports: [
    MatFormFieldModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatButtonModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    MatSelectModule,
    MatCardModule,
    MatTableModule,
    ReplaceCommasPipe,
  ],
  templateUrl: './insights-reports.component.html',
  styleUrl: './insights-reports.component.scss',
})
export class InsightsReportsComponent extends BaseListComponent {
  gatewayTypes: any = Object.values(GateWayType).filter(
    (value) => typeof value === 'number'
  );
  paymentStatuses: any = Object.values(PaymentStatus).filter(
    (value) => typeof value === 'number'
  );

  sellerPayoutTypes: any = Object.values(SellerPayoutType).filter(
    (value) => typeof value === 'number'
  );

  marginBusinessTypes: any = Object.values(MarginBusinessType).filter(
    (value) => typeof value === 'number'
  );


  DownloadType = DownloadType;
  form: FormGroup;
  dataSource: any;
  merchantId: any;
  maxDate: Date = new Date();
  constructor(
    private dialog: MatDialog,
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    private sellerReportService: SellerReportsService,
    private merchantService: MerchantsService,
    private loader: AppLoaderService,
    private orderService: OrderService

  ) {
    super(auth, router, route);

    this.form = this.fb.group({
      crFromDate: '',
      crToDate: '',
      prFromDate: '',
      prToDate: '',
      asFromDate: '',
      asToDate: '',
      provider: '',
      settlementStatus: '',
      utrNumber: [null],
      selectSeller: '',
      srFromDate: [null],
      srToDate: [null],
      status: [null],
      ptStartDate: '',
      ptEndDate: '',
      smrFromDate: '',
      smrToDate: '',
      sellerPayoutStartDate: '',
      sellerPayoutEndDate: '',
      providerPaymentStatus: 1,
      marginBusinessType: 5,
      marginStartDate: '',
      marginEndDate:''
    });
  }

  override async ngOnInit() {
    await super.ngOnInit(PageId.bms_insights);
    await this.getVendorId();
  }

  async getVendorId() {
    const body = {
      id: '',
      name: '',
      mobileNumber: '',
      city: '',
      businessType: 5,
      pageNumber: 1,
      pageSize: 10,
    };
    const response = await this.merchantService.getMerchants(body);
    if (response && response['merchantList']) {
      this.merchantId = response['merchantList'][0];
    }
    return [];
  }

  async download(type: DownloadType) {
    try {
      this.loader.open();
      if (type === DownloadType.AdminCollectionReconciliation) {
        const fromDate = this.form.value?.crFromDate
          ? getDateYYYYMMDD(new Date(this.form.value.crFromDate))
          : '';
        const toDate = this.form.value?.crToDate
          ? getDateYYYYMMDD(new Date(this.form.value.crToDate))
          : '';
        console.log('form date', fromDate);
        console.log('to date', toDate);
        const body = {
          reportType: DownloadType.AdminCollectionReconciliation,
          adminCollectionReconciliation: {
            startDate: fromDate,
            endDate: toDate,
          },
        };
        console.log('body is the ', body);
        const res = await this.sellerReportService.downloadRegisterReports(body);
        Swal.fire(
          'SUCCESS',
          'AdminCollectionReconciliation downloaded successfully',
          'success'
        );
      }
      if (type === DownloadType.AdminProviderReconciliation) {
        const fromDate = this.form.value?.prFromDate
          ? getDateYYYYMMDD(new Date(this.form.value.prFromDate))
          : '';
        const toDate = this.form.value?.prToDate
          ? getDateYYYYMMDD(new Date(this.form.value.prToDate))
          : '';
        console.log('form date', fromDate);
        console.log('to date', toDate);
        const provider = this.form?.get('provider')?.value;
        const status = this.form?.get('status')?.value;
        const utrNumber = this.form.get('utrNumber')?.value;
        console.log('utrNumber', utrNumber);

        const body = {
          reportType: DownloadType.AdminProviderReconciliation,
          adminProviderReconciliation: {
            startDate: fromDate,
            endDate: toDate,
            provider: provider,
            status: status,
            utrNumber: utrNumber,
          },
        };
        console.log(body, 'body.......');

        const res = await this.sellerReportService.downloadRegisterReports(body);
        Swal.fire(
          'SUCCESS',
          'Admin Provider Reconciliation downloaded successfully',
          'success'
        );
      }

      if (type === DownloadType.AdminSellerPayout) {

        const fromDate = this.form.value?.sellerPayoutStartDate
          ? getDateYYYYMMDD(new Date(this.form.value.sellerPayoutStartDate))
          : '';
        const toDate = this.form.value?.sellerPayoutEndDate
          ? getDateYYYYMMDD(new Date(this.form.value.sellerPayoutEndDate))
          : '';

        const providerPaymentStatus = this.form?.get('providerPaymentStatus')?.value;
        if (providerPaymentStatus == SellerPayoutType.All || providerPaymentStatus == SellerPayoutType['Pending Date']) {
          if (!fromDate || !toDate) {
            this.showMessage('Please Select Date.', SwalMessageTypes.Warning);
            return;
          }

          if (new Date(fromDate) > new Date(toDate)) {
            this.showMessage('Please give valid dates', SwalMessageTypes.Warning);
            return;
          }
        }

        if (providerPaymentStatus == SellerPayoutType['Pending Payments'] && fromDate != "" && toDate != "") {
          if (new Date(fromDate) > new Date(toDate)) {
            this.showMessage('Please give valid dates', SwalMessageTypes.Warning);
            return;
          }
        }
        const body = {
          reportType: DownloadType.AdminSellerPayout,
          adminSellerPayout: {
            startDate: fromDate,
            endDate: toDate,
            requestType: providerPaymentStatus
          }
        };

        const res = await this.sellerReportService.downloadRegisterReports(body);
        Swal.fire(
          'SUCCESS',
          'Admin Seller Payout downloaded successfully',
          'success'
        );
      }

      if (type === DownloadType.ProvidersTransactionReport) {
        const fromDate = this.form.value?.ptStartDate
          ? getDateYYYYMMDD(new Date(this.form.value.ptStartDate))
          : '';
        const toDate = this.form.value?.ptEndDate
          ? getDateYYYYMMDD(new Date(this.form.value.ptEndDate))
          : '';

        if (fromDate == "" && toDate == "") {
          this.showMessage('Please Select Date.', SwalMessageTypes.Warning);

          return;
        }

        if (new Date(fromDate) > new Date(toDate)) {
          this.showMessage('Please give valid dates', SwalMessageTypes.Warning);

          return;
        }

        const body = {
          reportType: DownloadType.ProvidersTransactionReport,
          providersTransactionReport: {
            startDate: fromDate,
            endDate: toDate
          }
        };
        console.log('body.......', body);
        const res = await this.sellerReportService.downloadRegisterReports(body);
        Swal.fire(
          'SUCCESS',
          'Providers Transaction Report downloaded successfully',
          'success'
        );
      }


      if (type === DownloadType.Margin) {

        const fromDate = this.form.value?.marginStartDate
          ? getDateYYYYMMDD(new Date(this.form.value.marginStartDate))
          : '';
        const toDate = this.form.value?.marginEndDate
          ? getDateYYYYMMDD(new Date(this.form.value.marginEndDate))
          : '';

        const businessType = this.form?.get('marginBusinessType')?.value;
          if (!fromDate || !toDate) {
            this.showMessage('Please Select Date.', SwalMessageTypes.Warning);
            return;
          }

          if (new Date(fromDate) > new Date(toDate)) {
            this.showMessage('Please give valid dates', SwalMessageTypes.Warning);
            return;
          }

        const body = {
          reportType: DownloadType.Margin,
          skuMargin: {
            startDate: fromDate,
            endDate: toDate,
            businessType: businessType
          }
        };
        const fileNameMap: any = {
          5: 'Qwipo',
          4: 'JIT Seller',
          3: 'Marketplace Seller'
        };
        const fileName = fileNameMap[businessType] || '';
        const res = await this.sellerReportService.downloadMarginReports(body);
        saveAs(res, fileName + ' Margin.xlsx');
        Swal.fire(
          'SUCCESS',
          `${fileName} report downloaded successfully`,
          'success'
        );
      }

    } catch (error) {
      this.loader.close();
      Swal.fire(
        'warning',
        'something went wrong',
        'warning'
      );
    } finally {
      this.loader.close();
    }
  }

  async downloadToExcel() {
    try {
      const { smrFromDate, smrToDate } = this.form.value;
      const fromDate = smrFromDate ? getDateYYYYMMDD(new Date(smrFromDate)) : '';
      const toDate = smrToDate ? getDateYYYYMMDD(new Date(smrToDate)) : '';

      if (!fromDate || !toDate) {
        this.showMessage('Please Select Date.', SwalMessageTypes.Warning);
        return;
      }

      if (new Date(fromDate) > new Date(toDate)) {
        this.showMessage('Please give valid dates', SwalMessageTypes.Warning);
        return;
      }
      const result = await Swal.fire({
        title: 'Would you like to download the Excel file?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      });

      if (!result.isConfirmed) {
        return;
      }
      const body = {
        startDate: fromDate,
        endDate: toDate
      }
      const response = await this.orderService.downloadOrderModificationReport(body);
      const filename = "Order Items Modification Report.xlsx"
      saveAs(response, filename);
      Swal.fire('', 'Downloaded order items modification report Successfully', 'success');
    } catch (error) {
      console.error('Error in downloadToExcel:', error);
      Swal.fire(
        'warning',
        'something went wrong',
        'warning'
      );
    } finally {
      this.loader.close();
    }
  }

  async clearFilters() {
    this.form = this.fb.group({
      smrFromDate: '',
      smrToDate: '',
    });

  }


  updateUtr() {
    const dialogRef = this.dialog.open(UploadUtrReportsPopUp, {
      width: "50%",
      height: "40%",
      data: {
        title: 'Upload Excel',
      }
    });

    dialogRef.afterClosed().subscribe(async (response: any) => {
      if (response) {
        Swal.fire("Success", " Successfully Uploaded", "success");
      }
    });
  }

  marginBusinessType(type: number): string {
    return MarginBusinessType[type];
  }

  sellerPayoutStatus(type: number): string {
    return SellerPayoutType[type];
  }
  getProviderName(type: number): string {
    return GateWayType[type];
  }
  getPaymentStatusName(type: number): string {
    return PaymentStatus[type];
  }
  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }
}
