<ng-container class="border">
  <div class="col-md-12 d-flex">
    <div class="col-md-6"></div>
    <div class="col-md-6">
      <mat-icon (click)="closeDialog()" class="float-right m-3 closeicon"
        >close</mat-icon
      >
    </div>
  </div>
  <div class="col-md-12">
    <form [formGroup]="searchForm">
      <section id="content">
        <div class="container">
          <!-- <mat-card>
          <mat-card-content> -->
          <div class="row">
            <div class="col-md-8">
              <mat-form-field class="full-width">
                <mat-label>{{ data.fieldName }}</mat-label>
                <!-- <input
                  matInput
                  type="string"
                  formControlName="fieldName"
                /> -->
                <mat-select formControlName="fieldName">
                  <mat-option *ngFor="let item of extractedData" [value]="{ id: item.id, name: item.name }" >
                  {{ item.name }}
                </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div style="text-align: center; padding-top: 10px">
              <button mat-raised-button color="primary" (click)="submit()">
                OK
              </button>
            </div>
          </div>
          <!-- </mat-card-content>
        </mat-card> -->
        </div>
      </section>
    </form>
  </div>
</ng-container>
