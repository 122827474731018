<div class="container-fluid p-2">
  <div class="icon">
    <mat-icon (click)="close()" class="float-right m-3 closeicon">close</mat-icon>
  </div>
  <mat-card class="p-3">
    <div class="row">
      <div class="col-md-12 table-wrapper">
        <table mat-table [dataSource]="dataSource">

          <ng-container matColumnDef="No">
            <th mat-header-cell *matHeaderCellDef> No</th>
            <td mat-cell *matCellDef="let element"> 
              <mat-checkbox [(ngModel)]="element.isSelected"></mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="item">
            <th mat-header-cell *matHeaderCellDef>Item</th>
            <td mat-cell *matCellDef="let element">{{ element.sku_name }} </td>
          </ng-container>
          <ng-container matColumnDef="requestedUom">
            <th mat-header-cell *matHeaderCellDef> Requested UOM</th>
            <td mat-cell *matCellDef="let element"> {{ getUomType(element.uom_type) }} 
            </td>
          </ng-container>
          <ng-container matColumnDef="requestedUomQty">
            <th mat-header-cell *matHeaderCellDef> Requested UOM Qty</th>
            <td mat-cell *matCellDef="let element"> {{element.uom_requested_qty}}
              
            </td>
          </ng-container>
          <ng-container matColumnDef="actualUomQty">
            <th mat-header-cell *matHeaderCellDef> Actual UOM Qty</th>
            <td mat-cell *matCellDef="let element"> {{element.uom_actual_qty}}</td> 
          </ng-container>
          <ng-container matColumnDef="requestedQtyPcs">
            <th mat-header-cell *matHeaderCellDef> Requested Qty In Pcs</th>
            <td mat-cell *matCellDef="let element"> {{element.requested_in_pcs}}   
            </td>
          </ng-container>
          <ng-container matColumnDef="actualQtyPcs">
            <th mat-header-cell *matHeaderCellDef> Actual Qty In Pcs</th>
            <td mat-cell *matCellDef="let element"> {{element.actual_in_pcs}}</td>
              
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
      <div style="display: flex;  justify-content: center;">
        <label *ngIf="isAllSelected" class="error-message">Please check the all records</label>
      </div>
      <div *ngIf="dataSource.length === 0" class=" error-message text-center">
        Requested Qty and Actual Qty of All Items are same
      </div>
      <div class="text-center mt-2 flex-item">
        <button mat-raised-button color="primary" class="text-center ml-w" (click)="completeGrn()">
          Proceed
        </button>
      </div>
    </div>
  </mat-card>
</div>
