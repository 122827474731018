import { Component } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ProductService } from '../../services/product.service';
import { Location } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { SkuDetailsComponent } from './skuDetails/skuDetails.component';
import { MatButtonModule } from '@angular/material/button';
import { CommonService } from '../../../../services/common.service';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
@Component({
  selector: 'app-skuEdit',
  standalone: true,
  imports: [MatTabsModule, MatCardModule,
    MatIconModule,
    SkuDetailsComponent, MatButtonModule, RouterModule, SkuDetailsComponent],
  templateUrl: './skuEdit.component.html',
  styleUrl: './skuEdit.component.scss',
})
export class SkuEditComponent {
  skuid = ""
  productId = ""
  productDetails: any;
  docData: any
  skuData: any
  comment: any
  constructor(public location: Location,
    public productService: ProductService,
    public commonService: CommonService,
    public router: Router, public route: ActivatedRoute) {
    this.getAll()

  }
  getAll() {
    if (this.route.snapshot.children[0].params['docId']) {
      this.productService.getSingleApprovalDoc(this.route.snapshot.children[0].params['docId']).subscribe((res: any) => {

        const prefix = 'Your request is rejected :';
        if (res.comment.startsWith(prefix)) {
          // Remove the prefix if it exists
          const cleanedComment = res.comment.replace(prefix, '').trim(); // Use trim() to remove any extra spaces
          this.comment = cleanedComment;
        } else {
          // Use the comment as is
          this.comment = res.comment;
        }
      })
    }

  }
  back() {
    this.location.back();
  }

}
