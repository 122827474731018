<div class="container">
  @if(hasView){
  <ng-container class="container">
    <div class="heading-block">
      <div class="row">
        <div class="col-md-6">
          <h2 class="card-title m-3">Errors Logs</h2>
        </div>
        <div class="col-md-6 d-flex justify-content-end  p-3">

        </div>
      </div>
    </div>

    <div class="search-block">
      <form [formGroup]="form">
        <section id="content">
          <div class="card">
            <div class="card-body card-padding">
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <input matInput formControlName="startDate" placeholder="Start Date" [matDatepicker]="appDatepicker"
                      [readonly]="true" />
                    <mat-datepicker-toggle matSuffix [for]="appDatepicker"></mat-datepicker-toggle>
                  </mat-form-field>
                  <mat-datepicker #appDatepicker></mat-datepicker>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <input matInput formControlName="endDate" placeholder="End Date" [matDatepicker]="appDatepickerEnd"
                      [readonly]="true" />
                    <mat-datepicker-toggle matSuffix [for]="appDatepickerEnd"></mat-datepicker-toggle>
                  </mat-form-field>
                  <mat-datepicker #appDatepickerEnd></mat-datepicker>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Message Type</mat-label>
                    <mat-select formControlName="messageType">
                      @for (messageType of messageTypes; track messageType) {
                      <mat-option [value]="messageType.messageType">{{
                        messageType.messageType
                        }}</mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="row">
                  <div class="col-md-6 mt-2 d-flex justify-content-end">
                    <button mat-raised-button color="primary" class="ml" (click)="searchTasks()">
                      Search
                    </button>
                  </div>
                  <div class="col-md-6 mt-2">
                    <button mat-raised-button color="primary" class="ml-10" (click)="clearFilters()">
                      Clear Filters
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>

    <div class="table-block">
      <div class="mt-3 flex flex-col md:flex-row justify-between">
        <mat-card class="p-0">
          <mat-card-content class="p-0">
            <mat-sidenav-container fxFlex="0 1 auto">
              <mat-sidenav-content>
                <div class="table-container">
                  <mat-table [dataSource]="errorLogsList" matSort>
                    <ng-container matColumnDef="id">
                      <mat-header-cell *matHeaderCellDef class="header-center">Message Id</mat-header-cell>
                      <mat-cell *matCellDef="let row" data-label="id">
                        {{ row.id }}
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="createdDate">
                      <mat-header-cell *matHeaderCellDef>Error Log Date</mat-header-cell>
                      <mat-cell *matCellDef="let row" data-label="createdDate">
                        {{ row.createdDate }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="messageType">
                      <mat-header-cell *matHeaderCellDef>Message Type</mat-header-cell>
                      <mat-cell *matCellDef="let row" data-label="messageType">
                        {{ row.messageType }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="view">
                      <mat-header-cell *matHeaderCellDef class="mat-column-actions">View</mat-header-cell>
                      <mat-cell *matCellDef="let row" class="mat-column-actions">
                        <div class="d-flex">
                          <button mat-icon-button (click)="editTrip(row)">
                            <mat-icon>visibility</mat-icon>
                          </button>
                        </div>
                      </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                  </mat-table>
                </div>
              </mat-sidenav-content>
            </mat-sidenav-container>
            <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
              (page)="onPageChange($event)"></mat-paginator>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </ng-container>
  } @else {
  <ng-container>
    <p class="text-center">
      You don't have permission, please contact administrator.
    </p>
  </ng-container>
  }
</div>