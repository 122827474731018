import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Auth } from '@angular/fire/auth';
import { AuthService } from '../../../services/auth.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AttributeService {
  auth: Auth = inject(Auth);
  constructor(public http: HttpClient,public authService:AuthService) {}



  async add(data:any){
    return lastValueFrom(this.http.post(`${environment.backendUrl}/attribute_types`,data))
  }

  delete(id:string){
    return lastValueFrom(this.http.delete(`${environment.backendUrl}/attribute_types/${id}`))
  }
  getSingle(id:string){
    return lastValueFrom(this.http.get(`${environment.backendUrl}/attribute_types/${id}`))
  }
  async update(id:string,data:any){
    return lastValueFrom(this.http.patch(`${environment.backendUrl}/attribute_types/${id}`,data))
  }

  getAll(queryObj:any){
    return this.http.get(`${environment.backendUrl}/attribute_types?${this.objectToQueryString(queryObj)}`)
  }
  objectToQueryString(obj: any): string {
    let params = new HttpParams();
  
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if(obj[key]!=null){
          params = params.append(key, obj[key]);
        }
      }
    }
  
    return params.toString();
  }
}
