import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink, RouterModule } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { Location } from '@angular/common';
import { ProductService } from '../services/product.service';
import { lastValueFrom } from 'rxjs';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { DetailsComponent } from './details/details.component';
import { CommonService } from '../../../services/common.service';
import { MatIconModule } from '@angular/material/icon';
@Component({
  selector: 'app-product-add-edit',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    RouterLink,
    ReactiveFormsModule,
    MatSelectModule,
    MatTabsModule,
    DetailsComponent,
    RouterModule,
    MatIconModule
  ],
  templateUrl: './product-add-edit.component.html',
  styleUrl: './product-add-edit.component.scss',
})
export class ProductAddEditComponent {



  productForm: any;
  id: any;
  companyList:any=[]
  brandsList:any=[]
  categoriesList:any=[]
  comment:any=""
  constructor(
    public fb: FormBuilder,
    public route: ActivatedRoute,
    public router: Router,
    public productService: ProductService,
    public location: Location,
    public commonService: CommonService
  ) {

  }
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      console.log(params)
      this.id=params['id']
      this.productService.getSingleApprovalDoc(this.id).subscribe((docData:any)=>{
        this.comment=docData?.comment
      })
    })
  }



  back() {
    this.router.navigateByUrl('/dashboard/product/skus/draft')
  }





}
