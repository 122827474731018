import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  FormBuilder,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { getDateFormat } from '../../../utlity/utility';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IAreaDialogData } from '../../master-geo/services/master-geo-model';
import { LookUpTypeService } from '../../../services/lookup-type.service';
import { AuthService } from '../../../services/auth.service';
import { PurchaseReceiptService } from '../service/purchasereceipt.service';
import { ConfigService } from '../../config/services/config.service';
import { debounceTime, distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs/operators';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { PageId } from '../../../constants/enums';


@Component({
  selector: 'add-supplier',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatButtonModule,
    RouterOutlet,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatCardModule,
    MatIconModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    CommonModule,
    MatTableModule,
    RouterLink,
    ReactiveFormsModule,
  ],

  templateUrl: './add-supplier.component.html',
  styleUrl: './add-supplier.component.scss',
})
export class AddSupplierComponent extends BaseListComponent {
  dataSource: any;
  supplierSearchResults: any[] = [];
  searchPerformed = false;
  public suppliersList: any[] = [];
  isSkuSelected: boolean = false;
  supplierSearchControl = new FormControl();
  selectedSupplier: any
  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddSupplierComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IAreaDialogData,
    private lookupService: LookUpTypeService,
    private purchaseReceiptService: PurchaseReceiptService,
    public authService: AuthService,
    private configService: ConfigService,
  ) {
    super(auth, router, route, PageId.seller_pr);
    this.dataSource = this.fb.group({
      selectedSupplier: "",
    });

  }
  override async ngOnInit() {
    await super.ngOnInit()
    this.setupSkuSearch()
  }


  setupSkuSearch() {
    this.supplierSearchControl.valueChanges
      .pipe(
        debounceTime(300), // Wait for 300 milliseconds after the last keystroke
        distinctUntilChanged(), // Only emit distinct values
        filter(value => value.length >= 3), // Filter out search terms less than 3 characters
        switchMap(value => this.searchSupplier(value)) // Perform the search and switch to the latest observable
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((results: any[]) => {
        this.supplierSearchResults = results;
        this.searchPerformed = true;
      });

  }
  async searchSupplier(searchTerm: string) {
    const searchText = searchTerm.trim();
    const response = await this.purchaseReceiptService.supplierSearch(searchText, this.vendorId)
    console.log("response", response);
    return response
  }

  // onSupplierSelectionChange(event: MatSelectChange){
  //   this.selectedSupplier = event.value
  //     }

  async createPR() {
    this.isSkuSelected = false
    this.selectedSupplier = this.dataSource.value.selectedSupplier
    if (this.selectedSupplier.id) {
      const supplierId = this.selectedSupplier.id
      const todayDate = new Date();
      const date = getDateFormat(todayDate)
      const body = this.mapToPr(supplierId, date)
      const res = await this.purchaseReceiptService.createPurchaseReceipts(body)

      this.dialogRef.close({
      });
      this.router.navigateByUrl(`/dashboard/pocreation/edit-purchase-order/${res.receipt_id}`);

    }
    else {
      this.isSkuSelected = true
    }


    return

  }

  mapToPr(supplier: string, date: string) {
    return {
      receipt_id: "",
      internal_receipt_number: "",
      invoice_reference_number: "",
      purchase_date: date,
      received_date: "",
      supplier_id: supplier,
      status: 1,
      tax_deduction_type: 0,
      tds_percent: 0,
      tds_value: 0,
      supplier_gst: this.selectedSupplier.gstNumber,
      comments: "",
      created_by: this.vendorId,
      grn_by: "",
      approval_by: "",
      purchase_bill: "",
      other_bills: ""

    }
  }

}
