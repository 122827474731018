import { Component, ViewChild } from '@angular/core';
import {
  MerchantEntity,
  MerchantListSearch,
} from '../../models/merchants.model';
import { MerchantsService } from '../../services/merchants.service';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSidenavModule } from '@angular/material/sidenav';

import Swal from 'sweetalert2';
import {
  MerchantBusinessType,
  SupplierStatus,
  SwalMessageTypes,
} from '../../../../enums/enums';
import { AppLoaderService } from '../../../../shared/app-loader/app-loader.service';
import { AuthService } from '../../../../services/auth.service';
import { BaseListComponent } from '../../../../shared/core/base.list.component';
import { PageId } from '../../../../constants/enums';

@Component({
  selector: 'app-merchants-list',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    RouterLink,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSidenavModule,
  ],
  templateUrl: './merchants-list.component.html',
  styleUrls: ['./merchants-list.component.scss'],
})
export class MerchantListComponent extends BaseListComponent {
  singularTypes = Object.values(SupplierStatus).filter(
    (value) => typeof value === 'number'
  );
  merchants: MerchantEntity[] = [];
  readonly DEFAULT_PAGE_SIZE = 25;
  totalMerchants: number = 0;
  form: FormGroup;
  merchantListSearchInput: MerchantListSearch = this.initializeSearchInput();
  businessTypes: { type: string, value: number }[] = [
    { type: "Distributor", value: 1 },
    { type: "Manufacturer", value: 2 },
    { type: "Wholesaler", value: 3 },
    { type: "JitVendor", value: 4 },
    { type: "Inventory", value: 5 },
  ];

  displayedColumns = [
    'businessName',
    'mobileNumber',
    'businessType',
    'address',
    'state',
    'city',
    'status',
    'setting',
    'edit',
    'geoMapping',
  ];

  dataSource!: MatTableDataSource<MerchantEntity>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    route: ActivatedRoute,
    router: Router,
    auth: AuthService,
    public readonly merchantService: MerchantsService,
    private loader: AppLoaderService,
    private fb: FormBuilder
  ) {
    super(auth, router, route, PageId.bms_vendors);
    this.form = this.fb.group({
      id: '',
      name: '',
      mobileNumber: '',
      city: '',
    });
  }

  override async ngOnInit() {
    super.ngOnInit();
    await this.getMerchants();
  }


  async goToSettings(row: any) {
    if (row) {
      this.router.navigate(['/dashboard/settings/vendor-list'], {
        state: { merchantDetails: row },
      });
    }
  }
  async getMerchants() {
    try {
      this.loader.open();
      const response = await this.merchantService.getMerchants(this.merchantListSearchInput);
      if (response && response['merchantList'] && response['merchantList'].length > 0) {
        this.dataSource = response['merchantList'];
        this.dataSource = response['merchantList'].sort((a: { createdAt: string | number | Date; }, b: { createdAt: string | number | Date; }) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        this.loader.close();
        this.merchants = response['merchantList'];
        this.totalMerchants = response["totalMerchants"];
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } else {
        this.dataSource = response[''];
        this.loader.close();
        Swal.fire({
          icon: 'info',
          text: 'No data found.'
        });
      }


    } catch (error) {
      this.loader.close();
      this.showMessage(
        'Failed to Fetch Merchants. Please try again later.',
        SwalMessageTypes.Error
      );
    }
  }

  async deleteMerchant(row: any) {
    try {
      const result = await Swal.fire({
        title: 'Do you want to Delete Merchant?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      });

      if (!result.isConfirmed) {
        return;
      }
      await this.merchantService.deleteMerchant(row.id);
      await this.clearFilters();
      this.showMessage(
        'Merchant Deleted Successfully',
        SwalMessageTypes.Success
      );
    } catch (error) {
      console.error('Error occurred while creating merchant:');
      this.showMessage(
        'Failed to Delete Merchant. Please try again later.',
        SwalMessageTypes.Error
      );
    }
  }

  editMerchant(row: any) {
    this.router.navigateByUrl(`/dashboard/merchant/edit-merchant/${row.id}`);
  }

  private initializeSearchInput(): MerchantListSearch {
    return {
      id: '',
      name: '',
      mobileNumber: '',
      city: '',
      pageNumber: 1,
      pageSize: 25,
    };
  }

  override async onPageChange(event: any) {
    await super.onPageChange(event);
    const { id, name, city, mobileNumber } = this.form.value;
    this.merchantListSearchInput = this.createMerchantListSearchInput(id, name, city, mobileNumber)
    await this.getMerchants();
  }

  async searchTasks() {
    const { id, name, city, mobileNumber } = this.form.value;
    this.pageNumber = 1;
    this.pageSize = this.DEFAULT_PAGE_SIZE;
    this.paginator.pageIndex = 0;
    if (id || name || city || mobileNumber !== undefined) {
      this.merchantListSearchInput = this.createMerchantListSearchInput(id, name, city, mobileNumber);
      await this.getMerchants();
    }
  }

  private createMerchantListSearchInput(
    id: string,
    name: string,
    city: string,
    mobileNumber: string
  ): MerchantListSearch {
    return {
      id: id ? id.trim() : '',
      name: name ? name.trim() : '',
      mobileNumber: mobileNumber ? (mobileNumber).toString() : '',
      city: city ? city.trim() : '',
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
    };
  }

  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  async clearFilters() {
    this.searchForm();
    this.merchantListSearchInput = this.initializeSearchInput();
    await this.getMerchants();
  }

  private searchForm() {
    this.form = this.fb.group({
      id: '',
      name: '',
      mobileNumber: null,
      city: '',
    });
  }

  getBusinessTypeName(value: number): string {
    const businessType = this.businessTypes.find(
      (type) => type.value === value
    );
    return businessType ? businessType.type : 'Unknown';
  }
  addGeoMapping(row: any) {
    const inputObj = {
      [MerchantBusinessType.Distributor]: `/dashboard/vendor-geo-mapping/add-vendor-geo-mapping/${row.id}`,
      [MerchantBusinessType.JitVendor]: `/dashboard/config/area-config/${row.id}`,
      [MerchantBusinessType.Inventory]: `/dashboard/config/area-config/${row.id}`,
      [MerchantBusinessType.Manufacturer]: `/dashboard/config/area-config/${row.id}`,
      [MerchantBusinessType.Wholesaler]: `/dashboard/config/area-config/${row.id}`,
    };
    const businessTypeKey = row.business_type as keyof typeof inputObj;

    this.router.navigateByUrl(inputObj[businessTypeKey]);
  }
  trimSpaces(event: any) {
    const inputValue: string = event.target.value;
    const trimmedValue: string = inputValue.replace(/\s/g, '');
    event.target.value = trimmedValue;
  }

}
