@if(hasView){
<ng-container>
  <div class="heading-block">
    <div class="row">
      <div class="col-md-6">
        <h2 class="card-title m-3"> Customers</h2>
      </div>
      <div class="col-md-6">
        @if(hasExport){
        <button mat-raised-button color="primary" class="topButtonSectionbtn" (click)="downloadExcel()">
          Download
        </button>
        }
      </div>
    </div>
  </div>
  <div class="search-block">
    <form [formGroup]="form">
      <section id="content">
        <div class="card">
          <div class="card-body card-padding">
            <div class="row">
              <div class="col-md-3">
                <mat-form-field class="full-width">
                  <input matInput type="string" formControlName="customerId" name="customerId" placeholder="Id" />
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field class="full-width">
                  <input matInput type="string" formControlName="name" name="name" placeholder="Name" />
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field class="full-width">
                  <input matInput type="number" formControlName="mobileNumber" name="mobileNumber"
                    placeholder="Mobile Number" [maxlength]="10" />
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field class="full-width">
                  <mat-label>Sales Person</mat-label>
                  <mat-select formControlName="salespersonId" (selectionChange)="getSelectedSalesPerson()" multiple>
                    <mat-option *ngFor="let salesPerson of salesPersonList" [value]="salesPerson.id">
                      {{ salesPerson.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-md-3">
                <mat-form-field class="full-width">
                  <mat-label>Business Types</mat-label>
                  <mat-select formControlName="businessTypeId" (selectionChange)="getCustomerBusinessTypes()" multiple>
                    <mat-option *ngFor="let type of customerBusinessTypes" [value]="type.id">
                      {{ type.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-md-3">
                <mat-form-field class="full-width">
                  <mat-label>Status</mat-label>
                  <mat-select formControlName="status" (selectionChange)="getCustomerStatus()" multiple>
                    <mat-option *ngFor="let salesPerson of customerStatus" [value]="salesPerson.id">
                      {{ salesPerson.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-md-3">
                <mat-form-field class="full-width">
                  <input matInput formControlName="startDate" placeholder="startDate" [matDatepicker]="appDatepicker" [readonly]="true"/>
                  <mat-datepicker-toggle matSuffix [for]="appDatepicker"></mat-datepicker-toggle>
                </mat-form-field>
                <mat-datepicker #appDatepicker></mat-datepicker>
              </div>
              <div class="col-md-3">
                <mat-form-field class="full-width">
                  <input matInput formControlName="endDate" placeholder="endDate" [matDatepicker]="appDatepicker1" [readonly]="true"/>
                  <mat-datepicker-toggle matSuffix [for]="appDatepicker1"></mat-datepicker-toggle>
                </mat-form-field>
                <mat-datepicker #appDatepicker1></mat-datepicker>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12" style="text-align: center;">
                <button mat-raised-button color="primary" class="text-center ml" (click)="searchTasks()">
                  Search
                </button>
                <button mat-raised-button color="primary" class="text-center ml-w" (click)="clearFilters()"
                  style="margin-left: 5px;">
                  Clear Filters
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  </div>

  <div class="table-block">
    <div class="m-3 flex flex-col md:flex-row justify-between">
      <mat-card class="p-0">
        <mat-card-content class="p-0">
          <mat-sidenav-container fxFlex="0 1 auto">
            <mat-sidenav-content>
              <div class="table-container">
                <mat-table [dataSource]="dataSource" matSort>
                  <ng-container matColumnDef="customerId">
                    <mat-header-cell *matHeaderCellDef>
                      Customer Id
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="customerId">
                      {{ row.id }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>
                      Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="name">
                      {{ row.name }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="businessName">
                    <mat-header-cell *matHeaderCellDef>
                      Business Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="businessName">
                      {{ row.businessName }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="mobileNumber">
                    <mat-header-cell *matHeaderCellDef>
                      Mobile Number
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="mobileNumber">
                      {{ row.mobileNumber }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="businessStatus">
                    <mat-header-cell *matHeaderCellDef>
                      Business Status
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="businessStatus">
                      {{ row.businessTagStatus }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="businessType">
                    <mat-header-cell *matHeaderCellDef>
                      Business Type
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="businessType">
                      {{ row.businessTypeId }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef>
                      Customer Status
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="status">
                      {{ row.status }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="baName">
                    <mat-header-cell *matHeaderCellDef>
                      Salesperson Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="baName">
                      {{ row.baName }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="baMobileNumber">
                    <mat-header-cell *matHeaderCellDef>
                      Salesperson Number
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="baMobileNumber">
                      {{ row.baMobileNumber }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="area">
                    <mat-header-cell *matHeaderCellDef>
                      Cluster
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="area">
                      {{ row.area }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="createdDate">
                    <mat-header-cell *matHeaderCellDef>
                      Register Date
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="createdDate">
                      {{ row.registeredDate }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="edit">
                    <mat-header-cell *matHeaderCellDef class="mat-column-actions">
                      Edit
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="mat-column-actions">
                      <div class="d-flex">
                        @if(hasEdit){
                        <button mat-icon-button (click)="editCustomer(row)">
                          <mat-icon>edit</mat-icon>
                        </button>
                        }
                      </div>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                </mat-table>
              </div>
            </mat-sidenav-content>
          </mat-sidenav-container>
          <mat-paginator [length]="totalCustomers" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="onPageChange($event)"></mat-paginator>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</ng-container>
} @else {
<ng-container>
  <p class="text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
}
