import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  OnChanges,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { VehicleEntity, VehicleListSearch } from '../../models/vehicles.model';
import { VehiclesService } from '../../services/vehicles.service';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSidenavModule } from '@angular/material/sidenav';

import Swal from 'sweetalert2';
import { AppLoaderService } from '../../../../../shared/app-loader/app-loader.service';
import { AuthService } from '../../../../../services/auth.service';
import {
  SwalMessageTypes,
  SupplierStatus,
  ContractMode,
  VehicleModel,
  VehicleTonnage,
} from '../../../../../enums/enums';
import { BaseListComponent } from '../../../../../shared/core/base.list.component';
import { PageId } from '../../../../../constants/enums';

@Component({
  selector: 'app-vehicles-list',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    RouterLink,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSidenavModule,
  ],
  templateUrl: './vehicles-list.component.html',
  styleUrls: ['./vehicles-list.component.scss'],
})
export class VehicleListComponent extends BaseListComponent {
  singularTypes = Object.values(SupplierStatus).filter(
    (value) => typeof value === 'number'
  );

  vehicles: VehicleEntity[] = [];
  readonly DEFAULT_PAGE_SIZE = 25;
  override pageNumber: number = 1;
  // override pageSize = this.DEFAULT_PAGE_SIZE;
  totalVehicles: number = 0;

  vehicleListSearchInput: VehicleListSearch = this.initializeSearchInput();
  vehicleStatus = Object.values(SupplierStatus).filter(
    (value) => typeof value === 'number'
  );
  vehicleContractMode = Object.values(ContractMode).filter(
    (value) => typeof value === 'number'
  );
  vehicleTonnageOptions: { tonnage: string; value: number }[] = [
    { tonnage: '2 kg', value: 1 },
    { tonnage: '5 kg', value: 2 },
    { tonnage: '10 kg', value: 3 },
    { tonnage: '2 Ton', value: 4 },
    { tonnage: '4 Ton', value: 5 },
    { tonnage: '6 Ton', value: 6 },
    { tonnage: '6 Ton And Above', value: 7 },
  ];
  vehicleModes: { type: string; value: number }[] = [
    { type: '2 wheeler', value: 2 },
    { type: '3 wheeler', value: 3 },
    { type: '4 wheeler', value: 4 },
  ];
  vehicleType: { [key: number]: string } = {
    [VehicleModel.TwoWheeler]: '2 Wheeler',
    [VehicleModel.ThreeWheeler]: '3 Wheeler',
    [VehicleModel.FourWheeler]: '4 Wheeler',
  };
  vehicleTonnage: { [key: number]: string } = {
    [VehicleTonnage.TwoKg]: '2 Kg',
    [VehicleTonnage.FiveKg]: '5 Kg',
    [VehicleTonnage.TenKg]: '10 Kg',
    [VehicleTonnage.TwoTon]: '2 Ton',
    [VehicleTonnage.FourTon]: '4 Ton',
    [VehicleTonnage.SixTon]: '6 Ton',
    [VehicleTonnage.SixTonAndAbove]: '6 and above Tons',
  };

  displayedColumns = [
    'vehicleNumber',
    'vehicleModel',
    'vehicleTonnage',
    'contractMode',
    'status',
    'edit',
    'delete',
  ];

  dataSource!: MatTableDataSource<VehicleEntity>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  Object: any;
  form: any;

  constructor(
    auth: AuthService,
    router: Router,
    private fb: FormBuilder,
    route: ActivatedRoute,
    public readonly vehicleService: VehiclesService,
    private loader: AppLoaderService
  ) {
    super(auth, router, route, PageId.logistics_vehicles);
    this.form = this.fb.group({
      vehicleType: null,
      tonnage: null,
      contractMode: null,
      status: null,
    });
  }

  override async ngOnInit() {
    await super.ngOnInit();
    await this.getVehicles();
  }

  getVehicleModelName(vehicleModelNumber: number): string {
    return this.vehicleType[vehicleModelNumber] || 'Unknown Vehicle Model';
  }

  getVehicleTonnageName(vehicleTonnageNumber: number): string {
    return (
      this.vehicleTonnage[vehicleTonnageNumber] || 'Unknown Vehicle Tonnage'
    );
  }

  async getVehicles() {
    try {
      this.loader.open();

      const response = await this.vehicleService.getVehicles(
        this.vehicleListSearchInput
      );
      if (
        response &&
        response['vehicleList'] &&
        response['vehicleList'].length > 0
      ) {
        this.dataSource = response['vehicleList'];
        this.dataSource = response['vehicleList'].sort(
          (
            a: { createdAt: string | number | Date },
            b: { createdAt: string | number | Date }
          ) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        this.vehicles = response['vehicleList'];
        this.totalVehicles = response['totalVehicles'];
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loader.close();
      } else {
        this.dataSource = response[''];
        this.loader.close();
        Swal.fire({
          icon: 'info',
          text: 'No vehicle data found.',
        });
      }
    } catch (error) {
      this.loader.close();
      this.showMessage(
        'Failed to Fetch Vehicles. Please try again later.',
        SwalMessageTypes.Error
      );
    }
  }

  override async onPageChange(event: any) {
    await super.onPageChange(event);
    const { vehicleType, tonnage, contractMode, status } = this.form.value;
    this.vehicleListSearchInput = this.createVehicleListSearchInput(
      vehicleType,
      tonnage,
      contractMode,
      status
    );
    await this.getVehicles();
  }

  async deleteVehicle(row: any) {
    try {
      const result = await Swal.fire({
        title: 'Do you want to Delete Vehicle?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      });

      if (!result.isConfirmed) {
        return;
      }
      await this.vehicleService.deleteVehicle(row.id);
      await this.clearFilters();
      this.showMessage(
        'Vehicle deleted Successfully',
        SwalMessageTypes.Success
      );
    } catch (error) {
      console.error('Error occurred while creating vehicle:');
      this.showMessage(
        'Failed to Delete Vehicle. Please try again later.',
        SwalMessageTypes.Error
      );
    }
  }

  editVehicle(row: any) {
    console.log('row.idrow.idrow.id::', row.id);
    this.router.navigateByUrl(`/dashboard/logistics/edit-vehicle/${row.id}`);
  }

  private initializeSearchInput(): VehicleListSearch {
    return {
      vehicleType: null,
      tonnage: null,
      contractMode: null,
      status: null,
      pageNumber: 1,
      pageSize: 10,
    };
  }

  async searchTasks() {
    const { vehicleType, tonnage, contractMode, status } = this.form.value;
    this.pageNumber = 1;
    this.pageSize = this.DEFAULT_PAGE_SIZE;
    this.paginator.pageIndex = 0;
    if (vehicleType || tonnage || contractMode || status !== undefined) {
      this.vehicleListSearchInput = this.createVehicleListSearchInput(
        vehicleType,
        tonnage,
        contractMode,
        status
      );
      await this.getVehicles();
    }
  }

  private createVehicleListSearchInput(
    vehicleType: number,
    tonnage: number,
    contractMode: number,
    status: number
  ): VehicleListSearch {
    return {
      vehicleType: vehicleType || null,
      tonnage: tonnage || null,
      contractMode: contractMode || null,
      status: status == 0 ? 0 : !status ? null : status == 1 ? 1 : null,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
    };
  }

  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  async clearFilters() {
    this.searchForm();
    this.vehicleListSearchInput = this.initializeSearchInput();
    await this.getVehicles();
  }

  private searchForm() {
    this.form = this.fb.group({
      vehicleType: null,
      tonnage: null,
      contractMode: null,
      status: null,
    });
  }
  getVehicleContractModeText(value: number): string {
    switch (value) {
      case ContractMode.Adhoc:
        return 'Adhoc';
      case ContractMode.Fixed:
        return 'Fixed';
      default:
        return 'Unknown Type';
    }
  }

  getStatusName(status: number): string {
    return SupplierStatus[status];
  }
}
