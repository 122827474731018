import { CommonModule } from '@angular/common';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { RouterOutlet, RouterLink } from '@angular/router';
import Swal from 'sweetalert2';
import {
  isEmptyValueNonZero,
  isNonNegativeValue,
} from '../../../../../utlity/utility';
import { ExcelExportService } from '../../../../../services/excel.service';
import * as XLSX from 'xlsx';
import { PricingService } from '../../../../pricing/services/pricing.service';
import { UpdateMarginES } from '../../../../../entities/pricing';

interface marginImportData {
  sku_id: string;
  selling_price: number;
  maximum_retail_price: number;
  margin: number;
  vendor_id: string;
  newMargin: number;
}
interface MarginApiInput {
  sku_id: string;
  selling_price: number;
  maximum_retail_price: number;
  margin: number;
  vendor_id: string;
}
export class errorExcel {
  sku_id: string = '';
  field: string[] = [];
}

@Component({
  selector: 'app-filter-popup',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatButtonModule,
    RouterOutlet,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatCardModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    CommonModule,
    RouterLink,
    ReactiveFormsModule,
    MatIconModule,
  ],
  templateUrl: './marginimportpop.component.html',
  styleUrl: './marginimportpop.component.scss',
})
export class MarginImportPop {
  sku_id(sku_id: any) {
    throw new Error('Method not implemented.');
  }
  variantValues: any = {};
  variantTypes: any = [];
  dataSource: any;
  selectedFile: any;
  selectedFileName: string | null = null;
  skusRowsWithError: marginImportData[] = [];
  emptyCheck: errorExcel[] = [];
  vendorProducts: any = [];
  notMatchingVendorSku: any = [];
  isExcelFieldEmpty: boolean = false;
  isNegativeValue: boolean = false;
  isButtonDisabled: boolean = true;
  isNewVendorSkuFound: boolean = false;
  skusFromExcel: Array<marginImportData> = [];
  @ViewChild('fileInput') fileInput?: ElementRef<HTMLInputElement>;
  constructor(
    public dialogRef: MatDialogRef<marginImportData>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    private pricingService: PricingService,
    private excelService: ExcelExportService,
    public fb: FormBuilder
  ) {}
  onFileSelect(event: any) {
    this.selectedFile = event.target.files[0];

    this.selectedFileName = this.selectedFile ? this.selectedFile.name : null;
    this.marginimport();
  }

  async marginimport() {
    try {
      if (!this.selectedFile || this.selectedFile.length === 0) {
        Swal.fire('', 'Please Upload Excel File.', 'warning');
        return;
      }

      const headerRow = await this.excelService.readExcelHeader(
        this.selectedFile
      );

      if (!this.validateHeaderColumns(headerRow)) {
        Swal.fire(
          'Fields should not be empty!',
          `Please check the downloaded Excel file to ensure its format aligns with that sheet before uploading`,
          'warning'
        );
        // this.downloadProductExcelFormat();

        return;
      }

      this.skusRowsWithError = [];
      this.skusFromExcel = [];
      const binarystr = await this.excelService.readExcelData(
        this.selectedFile
      );

      this.skusFromExcel = binarystr.map((row) => {
        return {
          sku_id: row['SKU ID'],
          selling_price: row['SELLING PRICE'],
          maximum_retail_price: row['MRP'],
          margin: row['MARGIN'] || 0,
          newMargin: row['NEW MARGIN'] || 0,
          vendor_id: this.data,
        };
      });
      console.log('skues from excel', this.skusFromExcel);

      if (this.skusFromExcel.length <= 0) {
        Swal.fire('', 'Excel should not be empty', 'warning');
        return;
      }

      const duplicateSkuIds = this.findDuplicateSkuIds(this.skusFromExcel);
      if (duplicateSkuIds.length > 0) {
        this.exportToExcel(duplicateSkuIds, 'DuplicateSKus', 'DuplicateSKUs');
        Swal.fire(
          'Duplicate SKUs',
          'Uploaded Excel contains duplicate SKUs. Please refer to the Excel, Please try again later.',
          'error'
        );
        // Swal.fire('Warning!', `<span style="color:red"> ${duplicateSkuIds}</span> \nSKU ID's repeating Please Check`, 'warning');
        return;
      }
      if (!this.validateEmptyColumns(this.skusFromExcel)) {
        this.exportToExcel(this.emptyCheck, 'Emptycolumns', 'Emptycolumns');

        Swal.fire(
          'Empty Columns',
          'Uploaded Excel contains Empty fields. Please refer to the Excel, Please try again later.',
          'error'
        );
        this.isExcelFieldEmpty = true;
        this.clearFile();
        return;
      }
      if (!this.isNumberNegativeValue(this.skusFromExcel)) {
        this.exportToExcel(
          this.emptyCheck,
          'NegativeValues',
          'Negative Values'
        );
        Swal.fire(
          'Negative Values',
          'Uploaded Excel contains negative values. Please refer to the Excel, Please try again later.',
          'error'
        );
        this.isExcelFieldEmpty = true;
        this.isNegativeValue = true;
        this.clearFile();

        return;
      }
      this.vendorProducts = await this.pricingService.getVendorSkus(this.data,1,5000)  || [];

      this.notMatchingVendorSku = this.validateVendorProducts();

      if (this.notMatchingVendorSku.length > 0) {
        Swal.fire(
          'Not Matching Values',
          'Uploaded Excel contains not matching vendor skus. Please refer to the Excel, Please try again later.',
          'error'
        );
        this.isNewVendorSkuFound = true;
        this.clearFile();
        return;
      }
      this.clearFile();
      this.isButtonDisabled = false;

      console.log('excel file is  good');
    } catch (error) {
      console.log(error);
    }
  }
  private validateHeaderColumns(headerRow: string[]): boolean {
    const requiredColumns = [
      'SKU ID',
      'SELLING PRICE',
      'MRP',
      'MARGIN',
      'NEW MARGIN',
    ];

    for (const column of requiredColumns) {
      const foundColumn = headerRow.find(
        (header) => header.trim().toLowerCase() === column.trim().toLowerCase()
      );
      if (!foundColumn) {
        return false;
      }
    }

    return true;
  }

  private findDuplicateSkuIds(skus: marginImportData[]): string[] {
    const skuIdSet = new Set<string>();
    const duplicates = [];

    for (const sku of skus) {
      if (skuIdSet.has(sku.sku_id)) {
        duplicates.push(sku.sku_id);
      } else {
        skuIdSet.add(sku.sku_id);
      }
    }

    return duplicates;
  }
  private validateVendorProducts() {
    if (this.vendorProducts === null || this.vendorProducts.length === 0) {
      return this.skusFromExcel;
    }
    const arr2SkuIds = new Set(
      this.vendorProducts.map((item: { skus_id: any }) => item.skus_id)
    );

    const notMatchedItems = this.skusFromExcel.filter(
      (item) => !arr2SkuIds.has(item.sku_id)
    );

    return notMatchedItems;
  }

  clearFile() {
    this.selectedFile = null;
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }

  private validateEmptyColumns(skuList: Array<any>) {
    const requiredFields = [
      'sku_id',
      'selling_price',
      'maximum_retail_price',
      'margin',
      'newMargin',
    ];

    for (const sku of skuList) {
      const missingFields = requiredFields.filter((field) =>
        isEmptyValueNonZero(sku[field])
      );

      if (missingFields.length > 0) {
        this.emptyCheck.push({ sku_id: sku.skuid, field: missingFields });
        return;
      }
    }
    return this.emptyCheck.length === 0;
  }
  private isNumberNegativeValue(skuList: Array<any>) {
    return this.validateSkuList(skuList, isNonNegativeValue);
  }
  private validateSkuList(
    skuList: Array<any>,
    validationFn: (data: any) => boolean
  ) {
    const requiredFields = ['sku_id', 'margin'];
    for (const sku of skuList) {
      const missingFields = requiredFields.filter((field) =>
        validationFn(sku[field])
      );
      if (missingFields.length > 0) {
        this.emptyCheck.push({ sku_id: sku.sku_id, field: missingFields });
      }
    }

    return this.emptyCheck.length === 0;
  }
  exportToExcel(
    data: any[],
    sheetName: string,
    fileName: string = 'exported-data'
  ) {
    const workBook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workBook, worksheet, sheetName);

    const outputFileName = `${fileName}.xlsx`;
    XLSX.writeFile(workBook, outputFileName);
  }

  private getChangedMargins(margins: any) {
    const changedObjects = margins.filter(
      (item: any) => item.margin !== item.newMargin
    );
    return changedObjects;
  }

  private mapToMarginUpdateCD(skus: marginImportData[]): MarginApiInput[] {
    const bmsStock = skus.map((sku: marginImportData) => {
      return {
        sku_id: sku.sku_id,
        selling_price: sku.selling_price,
        maximum_retail_price: sku.maximum_retail_price,
        margin: sku.newMargin,
        vendor_id: sku.vendor_id,
      };
    });

    return bmsStock;
  }

  private mapToMarginUpdateES(skus: MarginApiInput[]): UpdateMarginES[] {
    const bmsStock = skus.map((sku: MarginApiInput) => {
      return {
        vendor_id: sku.vendor_id,
        sku_id: sku.sku_id,
        pricing: {
          margin: sku.margin,
        },
      };
    });

    return bmsStock;
  }

  //
  async saveMargins() {
    const modifiedMargins = this.getChangedMargins(this.skusFromExcel);
    const mappedData:MarginApiInput[] = this.mapToMarginUpdateCD(modifiedMargins);
    await this.pricingService.updateMarginCD(mappedData);
    const mapToEsInput:UpdateMarginES[] =this.mapToMarginUpdateES(mappedData);
    await this.pricingService.updateMarginES(mapToEsInput);

      this.dialogRef.close();
  }
}
