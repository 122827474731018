@if(hasView){
<section id="content" *ngIf="orderDetails">
  <div class="container">
    <div class="heading-block">
      <div class="row">
        <div class="col-md-6">
          <mat-icon class="backicon" (click)="goBack()">arrow_back</mat-icon>
        </div>
        <div class="col-md-6">
          <button mat-raised-button color="primary" class="button" [matMenuTriggerFor]="actionOptionsMenu">
            <span class="mat-button-wrapper">
              Action
              <mat-icon class="icon">arrow_drop_down</mat-icon>
            </span>
          </button>
          <mat-menu #actionOptionsMenu="matMenu" class="export-dropdown">
            <button class="dropdown-list" mat-menu-item *ngFor="let option of getActionOptions()"
              (click)="onActionOptionClick(option)">
              {{ option }}
            </button>
          </mat-menu>
        </div>
        <div class="col-md-6 text-right"></div>
      </div>
    </div>
    <div class="card">
      <mat-card>
        <div class="card-body card-padding">
          <div class="row row-cols-12 mb-3">
            <div class="col-md-6">
              <div class="col-md-6">
                <span class="header">
                  <h2 class="title">Customer Information:</h2>
                </span>
              </div>
              <div class="col-md-12 heading-container">
                <label class="heading">Business Name :</label>
                <label class="content">{{orderCustomerDetails.business_name}}</label>
              </div>
              <div class="col-md-12 heading-container">
                <label class="heading">Customer Name :</label>
                <label class="content">{{orderCustomerDetails.name}}</label>
              </div>
              <div class="col-md-12 heading-container">
                <label class="heading">Address :</label>
                <label class="content">{{orderCustomerDetails.address?.street}} <br>PIN-
                  {{orderCustomerDetails.address?.pincode}} </label>

              </div>

              <div class="col-md-12 heading-container">
                <label class="heading">Mobile Number:</label>
                <label class="content">{{orderCustomerDetails.mobile_number}}</label>
              </div>
              <div class="col-md-12 heading-container">
                <label class="heading">GST Number :</label>
                <label class="content">{{orderCustomerDetails.gst_number}}</label>
              </div>
              <div class="col-md-6">
                <span class="header">
                  <h2 class="title">Seller Information:</h2>
                </span>
              </div>
              <div class="col-md-12 heading-container">
                <label class="heading">Seller :</label>
                <label class="content">{{orderMerchantDetails.name}}</label>
              </div>
              <div class="col-md-12 heading-container">
                <label class="heading">Mobile Number :</label>
                <label class="content">{{orderMerchantDetails.mobile_number}}</label>
              </div>
              <div class="col-md-12 heading-container">
                <label class="heading">Address :</label>
                <label class="content">{{orderMerchantDetails.address?.street}} <br>PIN-
                  {{orderMerchantDetails.address?.pincode}} </label>
              </div>
              <div class="col-md-12 heading-container">
                <label class="heading">Seller GST Number :</label>
                <label class="content">{{orderMerchantDetails.gst_number}}</label>
              </div>
            </div>

            <div class="col-md-6">
              <div class="col-md-6">
                <span class="header">
                  <h2 class="title">Order Information:</h2>
                </span>
              </div>
              <div class="col-md-12 heading-container">
                <label class="heading">Order Id :</label>
                <label class="content">{{orderDetails.order_number}}</label>
              </div>


              <div class="col-md-12 heading-container">
                <label class="heading">Order Date :</label>
                <label class="content">{{orderDetails.order_date}}</label>
              </div>
              <div class="col-md-12 heading-container">
                <label class="heading">Delivery Slot :</label>
                <label class="content">9:00 am - 9:30 pm</label>
              </div>
              <div class="col-md-12 heading-container">
                <label class="heading">Delivery Date:</label>
                <label class="content">{{orderDetails.expected_delivery_date}}</label>
              </div>
              <div class="col-md-12 heading-container">
                <label class="heading">Deliver Address :</label>
                <label class="content">{{orderCustomerDetails.address?.street}} <br>PIN-
                  {{orderCustomerDetails.address?.pincode}} </label>
              </div>
              <!-- <div class="col-md-12 heading-container">
                <label class="heading">Coupon Id :</label>
                <label class="content">{{couponDetails.id}}</label>
              </div> -->
              <div class="col-md-12 heading-container">
                <label class="heading">Coupon Name :</label>
                <label class="content">{{couponDetails.description}}</label>
              </div>
              <div class="col-md-12 heading-container">
                <label class="heading">Coupon Amount :</label>
                <label class="content">{{ (+couponDetails?.discount_amount) | replaceCommas}}</label>
              </div>
              <div class="col-md-12 heading-container">
                <label class="heading">Actual Amount :</label>
                <label class="content">{{ (+orderDetails?.item_total) | replaceCommas}}
                </label>
              </div>
              <div class="col-md-12 heading-container">
                <label class="heading">Final Amount :</label>
                <label class="content">{{ (+orderDetails?.total_amount) | replaceCommas}}
                </label>
              </div>
              <div class="col-md-12 heading-container">
                <label class="heading">Order Status :</label>
                <label class="content">{{getOrderStatus(orderDetails.order_status_id) }}</label>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="card">
      <mat-card>
        <div class="card-body card-padding">
          <div class="col-md-12">
            <div class="col-md-6">
              <span class="header">
                <h2 class="title">Order Products:</h2>
              </span>
            </div>
            <div class="productIds" *ngIf="orderProducts.length > 0">
              <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="view">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> </mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="image_url">
                    <img class="square" style="height: 170px; width: 120px;" [src]="row?.image_url"
                      alt="Promotion Image">
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="description">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> SKU Name</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="sku_name"> {{row.sku_name}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="mrp">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> MRP</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="sku_name"> {{row.mrp | replaceCommas}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="quantity">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Qty</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="quantity"> {{row.quantity}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="perPcsRate">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Per Pcs Rate</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="selling_price"> {{row.selling_price.toFixed(2)}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="saleAmount">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Sale Amount</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="selling_price"> {{row.net_amount | replaceCommas}}
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumnsProduct"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsProduct;"></mat-row>
              </mat-table>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="card">
      <mat-card>
        <div class="card-body card-padding">
          <div class="col-md-12">
            <div class="col-md-6">
              <span class="header">
                <h2 class="title">Tax Details :</h2>
              </span>
            </div>
            <div class="tax" *ngIf="orderProducts.length > 0">
              <mat-table [dataSource]="taxSlabs" matSort>
                <ng-container matColumnDef="gstTaxSlab">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> GST Tax Slab</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="gstTaxSlab"> {{row.gstTaxSlab}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="taxableAmount">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Taxable Amount</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="taxableAmount"> {{row.taxableAmount | replaceCommas}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="cgst">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> CGST</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="cgst"> {{row.cgst.toFixed(4)}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="sgst">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> SGST</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="sgst"> {{row.sgst.toFixed(4)}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="cess">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Cess</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="cess"> {{row.cess.toFixed(4)}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="tcs/tds">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> TCS/TDS</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="tcs/tds"> {{row.tcsTds.toFixed(4)}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="totalBillAmount">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Total Bill Amount</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="totalBillAmount"> {{row.totalBillAmount | replaceCommas}}
                  </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumnsTax"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsTax;"></mat-row>
              </mat-table>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
    <!-- <div class="card"> -->
    <!-- <mat-card>
        <div class="card-body card-padding">
          <div class="col-md-12">
            <div class="col-md-6">
              <span class="header">
                <h2 class="title"> Collection Summary :</h2>
              </span>
            </div>
            <div class="tax" *ngIf="orderProducts.length > 0">
              <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="saleAmount">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Sale Amount</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="saleAmount"> {{row.sku_name}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="returnValue">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Return Value</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="returnValue"> {{row.mrp}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="cash">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Cash</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="cash"> {{row.quantity}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="bnpl">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> BNPL</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="bnpl"> {{row.selling_price.toFixed(2)}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="digitalPayment">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Digital Payment</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="digitalPayment"> {{row.net_amount.toFixed(2)}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="balance">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Balance</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="balance"> {{row.net_amount.toFixed(2)}}
                  </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumnsCollection"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsCollection;"></mat-row>
              </mat-table>
            </div>
          </div>
        </div>
      </mat-card>
    </div> -->
    <!-- <div class="card">
      <mat-card>
        <div class="card-body card-padding">
          <div class="col-md-12">
            <div class="col-md-6">
              <span class="header">
                <h2 class="title"> Settlement Summary :</h2>
              </span>
            </div>
            <div class="Settlement" *ngIf="orderProducts.length > 0">
              <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="baseAmount">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Base Amount</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="baseAmount"> {{row.sku_name}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="totalTax">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Total Tax</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="totalTax"> {{row.mrp}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="saleAmount">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Sale Amount</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="saleAmount"> {{row.quantity}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="couponAmount">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Coupon Amount</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="couponAmount"> {{row.selling_price.toFixed(2)}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="deliveryFee">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Delivery Fee</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="deliveryFee"> {{row.net_amount.toFixed(2)}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="qwipoCommission">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Qwipo Commission</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="qwipoCommission"> {{row.net_amount.toFixed(2)}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="tcs">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> TCS</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="tcs"> {{row.net_amount.toFixed(2)}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="tds">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> TDS</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="tds"> {{row.net_amount.toFixed(2)}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="expectedReceivable">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Expected Receivable</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="expectedReceivable"> {{row.net_amount.toFixed(2)}}
                  </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumnsSettlemet"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsSettlemet;"></mat-row>
              </mat-table>
            </div>
          </div>
        </div>
      </mat-card>
    </div> -->
  </div>
</section>
} @else {
<ng-container>
  <p class="text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
}