import {
  SupplierForm,
  SupplierEntity,
  ImageDialog,
  PropertyValidation,
  validateName,
  MultiPropertyValidation,
  ValidationResult,
} from '../../models/supplier-model';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  ContractMode,
  LogisticsSupplierType,
  LookUpType,
  SupplierStatus,
  SupplierType,
  SwalMessageTypes,
  states,
} from '../../../../../enums/enums';
import { SupplierService } from '../../services/supplier.service';
import { State } from '../../models/supplier-model';
import Swal from 'sweetalert2';
import { AppLoaderService } from '../../../../../shared/app-loader/app-loader.service';
import { UploadImageDialogComponent } from '../upload-image-dialog/upload-image-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../../../../services/auth.service';
import {
  applyMaxLengthValidation,
  hasSpecialCharacters,
  nameValidate,
  supplierListSearchInput,
  validateGST,
  validateMaxLength,
  validateMaxText,
  validateNegativeValues,
  validateNoNegativeValues,
  validateNumbersOnly,
  validatePinCode,
  validateText,
} from '../../../../../utlity/utility';
import imageCompression from 'browser-image-compression';
import { LookUpTypeService } from '../../../../../services/lookup-type.service';
import { BaseListComponent } from '../../../../../shared/core/base.list.component';
import { PageId } from '../../../../../constants/enums';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'edit-logistics-supplier',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    RouterLink,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatRadioModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatCheckboxModule,
  ],
  templateUrl: './edit-logistics-supplier.component.html',
  styleUrl: './edit-logistics-supplier.component.scss',
})
export class EditLogisticsSupplierComponent extends BaseListComponent {
  dataSource: any;
  aadharCopy: string = '';
  panCopy: string = '';
  gstCopy: string = '';
  id: string = '';
  uploadedFiles: any = {};
  existingSupplier: any = [];
  public locations: any[] = [];
  states: State[] = states;
  supplierStatus = Object.values(SupplierStatus).filter(
    (value) => typeof value === 'number'
  );
  supplierTypes = Object.values(LogisticsSupplierType).filter(
    (value) => typeof value === 'number'
  );
  supplierContractMode = Object.values(ContractMode).filter(
    (value) => typeof value === 'number'
  );
  lookUpTypeSearchInput: LookUpType = this.initializeLookUpTypeSearchInput();

  constructor(
    auth: AuthService,
    router: Router,
    private fb: FormBuilder,
    route: ActivatedRoute,
    private supplierService: SupplierService,
    private loader: AppLoaderService,
    private lookupService: LookUpTypeService,
    private dialog: MatDialog
  ) {
    super(auth, router, route, PageId.logistics_suppliers);
    this.dataSource = this.fb.group({
      name: [null, Validators.required],
      mobileNumber: [null, Validators.required],
      residence: [null, Validators.required],
      area: [''],
      landmark: [''],
      state: [null, Validators.required],
      city: [null, Validators.required],
      pinCode: [null, Validators.required],
      latitude: [''],
      longitude: [''],
      aadharNumber: '',
      aadharCopy: '',
      panNumber: '',
      panCopy: '',
      gstNumber: '',
      gstCopy: '',
      location: [null, Validators.required],
      supplierType: [null, Validators.required],
      contractMode: [null, Validators.required],
      status: [null, Validators.required],
      tcsPercentage: [null],
    });
  }

  override async ngOnInit() {
    await super.ngOnInit();
    this.route.params
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (params) => {
        this.id = params['id'];
        await this.getSupplierById();
        this.fixMaxLength();
      });
    await this.getLocation();
  }

  async getLocation() {
    const response = await this.lookupService.getLookUpTypes(
      this.lookUpTypeSearchInput
    );
    this.locations = response.data[0].data;
  }

  async getSupplierById() {
    try {
      this.loader.open();
      const response = await this.supplierService.getSupplierById(this.id);
      console.log('response.data', response.data);
      this.dataSource = this.mapToEditInput(response.data);
    } catch (error) {
      this.showMessage(
        'Failed to Get supplier. Please try again later.',
        SwalMessageTypes.Error
      );
    } finally {
      this.loader.close();
    }
  }

  async updateSupplier() {
    this.loader.open();
    try {
      if (this.dataSource.invalid) {
        console.log("this.dataSource.invalid:",this.dataSource.value);
        this.showMessage(
          'Please fill all required fields.',
          SwalMessageTypes.Warning
        );

        return;
      }
      await this.validateInput();
      await this.uploadFiles();
      await this.updateSupplierById();
    } finally {
      this.loader.close();
    }
  }

  async uploadFiles() {
    const documentTypes = ['aadhar', 'pan', 'gst'];
    const urlEndpoints = ['supplier_aadhaar', 'supplier_pan', 'supplier_gst'];

    await Promise.all(
      documentTypes.map(async (documentType, index) => {
        const urlEndpoint = urlEndpoints[index];

        if (this.uploadedFiles.hasOwnProperty(documentType)) {
          const requestImageBody = this.uploadedFiles[documentType];
          await this.uploadAndSetUrl(
            requestImageBody,
            documentType,
            urlEndpoint
          );
        }
      })
    );
  }

  async updateSupplierById() {
    const supplierEntity = this.mapUpdateSupplier(this.dataSource.value);

    const missingFields = this.checkMissingFields(supplierEntity);
    if (missingFields.length > 0) {
      this.showMessage(
        `Please fill both ${missingFields.join(' and ')} not only one.`,
        SwalMessageTypes.Warning
      );
      this.loader.close();
      throw new Error(`Missing required fields: ${missingFields.join(', ')}`);
    }

    try {
      const response = await this.supplierService.updateSupplier(
        supplierEntity,
        this.id
      );
      this.showMessage(
        'Supplier Updated successfully',
        SwalMessageTypes.Success
      );
      this.router.navigateByUrl('/dashboard/logistics/supplier-list');
    } catch (error) {
      console.error('Error occurred while creating supplier:', error);
      this.showMessage(
        'Failed to create supplier. Please try again later.',
        SwalMessageTypes.Error
      );
    }
  }

  async onFileChange(event: any, documentType: string) {
    if (event.target.files) {
      const file = event.target.files[0];
      const filename = file.name;
      const reader = new FileReader();
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      reader.onload = async (event) => {
        const result = event?.target?.result;

        if (typeof result === 'string') {
          const base64String: string = await this.compressAndConvertToBase64(
            file,
            options
          );
          this.uploadedFiles[documentType] = {
            fileName: filename,
            fileExtension: this.getFileExtension(filename),
            fileData: base64String,
          };
        } else if (result instanceof ArrayBuffer) {
          console.error('Unsupported file type');
          // Handle this case if needed
        }
      };

      reader.readAsDataURL(file);
    }
  }

  async compressAndConvertToBase64(file: File, options: any): Promise<string> {
    try {
      const compressedFile = await imageCompression(file, options);
      const base64String: string = await imageCompression.getDataUrlFromFile(
        compressedFile
      );
      return base64String.split(',')[1];
    } catch (error) {
      console.error('Error compressing image:', error);
      throw error;
    }
  }

  openPopUp(imageUrl: string) {
    const dialogRef = this.dialog.open(UploadImageDialogComponent, {
      width: '50%',
      height: '40%',
      data: {
        imageUrl: imageUrl,
      } as ImageDialog,
    });
    dialogRef.afterClosed().subscribe(async (response: any) => {
      if (response) {
      }
    });
  }

  // PRIVATE FUNCTIONS

  async uploadAndSetUrl(
    requestImageBody: any,
    documentType: string,
    urlEndpoint: string
  ) {
    try {
      const res: any = await this.supplierService.imageUpload(
        requestImageBody,
        urlEndpoint
      );
      switch (documentType) {
        case 'aadhar':
          this.aadharCopy = res.data.url;
          break;
        case 'pan':
          this.panCopy = res.data.url;
          break;
        case 'gst':
          this.gstCopy = res.data.url;
          break;
        default:
          console.error('Invalid document type');
          break;
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  }
  getFileExtension(filename: any) {
    return filename.split('.').pop();
  }

  private mapUpdateSupplier(supplier: SupplierForm): SupplierEntity {
    return {
      id: this.id,
      mobileNumber: supplier?.mobileNumber,
      name: supplier?.name,
      address: {
        residence: supplier?.residence || '',
        area: supplier?.area || '',
        landmark: supplier?.landmark || '',
        city: supplier?.city,
        state: supplier?.state,
        pinCode: supplier?.pinCode,
        country: 'INDIA',
        latitude: supplier?.latitude?.toString(),
        longitude: supplier?.longitude?.toString(),
      },
      aadharNumber: supplier?.aadharNumber,
      aadharCopy: this.aadharCopy || supplier.aadharCopy || '',
      panNumber: supplier?.panNumber,
      panCopy: this.panCopy || supplier.panCopy || '',
      gstNumber: supplier?.gstNumber,
      gstCopy: this.gstCopy || supplier.gstCopy || '',
      location: supplier?.location,
      supplierType: supplier?.supplierType,
      tcsPercentage: supplier?.tcsPercentage,
      contractMode: supplier?.contractMode,
      status: supplier?.status,
    };
  }

  private mapToEditInput(supplier: SupplierEntity): FormGroup {
    return this.fb.group({
      id: new FormControl(supplier.id || '', Validators.required),
      name: new FormControl(supplier.name || '', Validators.required),
      mobileNumber: new FormControl(
        supplier.mobileNumber || '',
        Validators.required
      ),
      residence: new FormControl(
        supplier.address.residence || '',
        Validators.required
      ),
      area: new FormControl(supplier.address.area || ''),
      landmark: new FormControl(
        supplier.address?.landmark || ''
      ),
      state: new FormControl(
        supplier.address?.state || '',
        Validators.required
      ),
      city: new FormControl(supplier.address?.city || '', Validators.required),
      pinCode: new FormControl(
        supplier.address?.pinCode || '',
        Validators.required
      ),
      latitude: new FormControl(
        supplier.address?.latitude || ''
      ),
      longitude: new FormControl(
        supplier.address?.longitude || ''
      ),
      aadharNumber: new FormControl(supplier.aadharNumber || ''),
      aadharCopy: new FormControl(supplier.aadharCopy || ''),
      panNumber: new FormControl(supplier.panNumber || ''),
      panCopy: new FormControl(supplier.panCopy || ''),
      gstNumber: new FormControl(supplier.gstNumber || ''),
      gstCopy: new FormControl(supplier.gstCopy || ''),
      location: new FormControl(supplier.location || ''),
      supplierType: new FormControl(
        supplier.supplierType || '',
        Validators.required
      ),
      contractMode: new FormControl(
        supplier.contractMode || '',
        Validators.required
      ),
      status: new FormControl(supplier.status, Validators.required),
      tcsPercentage: new FormControl(
        supplier.tcsPercentage || ''
      ),
    });
  }

  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  private checkMissingFields(supplierEntity: any) {
    const missingFields: any = [];

    const checkField = (
      fieldCopy: any,
      fieldNumber: any,
      fieldName: string
    ) => {
      if (
        fieldNumber !== null &&
        ((fieldCopy !== '' && fieldNumber === '') ||
          (fieldCopy === '' && fieldNumber !== ''))
      ) {
        missingFields.push(`${fieldName} Number And ${fieldName} Image`);
      }
    };

    checkField(
      supplierEntity.aadharCopy,
      supplierEntity.aadharNumber,
      'Aadhar'
    );
    checkField(supplierEntity.panCopy, supplierEntity.panNumber, 'Pan');
    checkField(supplierEntity.gstCopy, supplierEntity.gstNumber, 'GST');

    return missingFields;
  }

  toUppercase(event: any) {
    event.target.value = event.target.value.toUpperCase();
  }

  // private functions validations

  private fixMaxLength() {
    applyMaxLengthValidation(this.dataSource, [
      { property: 'mobileNumber', maxLength: 10 },
      { property: 'pinCode', maxLength: 6 },
      { property: 'aadharNumber', maxLength: 12 },
      { property: 'panNumber', maxLength: 10 },
      { property: 'gstNumber', maxLength: 15 },
    ]);
  }

  checkSpecialCharacters(data: any): boolean {
    const inputValues = [];
    if (data.aadharNumber !== '') {
      inputValues.push(data.aadharNumber);
    }
    if (data.panNumber !== '') {
      inputValues.push(data.panNumber);
    }
    if (data.gstNumber !== '') {
      inputValues.push(data.gstNumber);
    }
    return inputValues.length > 0 ? hasSpecialCharacters(inputValues) : false;
  }

  validateMaxLength(data: any) {
    const validations: MultiPropertyValidation[] = [
      { field: 'mobileNumber', property: data.mobileNumber, maxLength: 10 },
      { field: 'pinCode', property: data.pinCode, maxLength: 6 },
    ];
    if (this.dataSource.value.aadharNumber == null) {
      data.aadharNumber = '';
    }

    if (data.aadharNumber !== '') {
      validations.push({
        field: 'aadharNumber',
        property: data.aadharNumber,
        maxLength: 12,
      });
    }
    if (data.panNumber !== '') {
      validations.push({
        field: 'panNumber',
        property: data.panNumber,
        maxLength: 10,
      });
    }
    if (data.gstNumber !== '') {
      validations.push({
        field: 'gstNumber',
        property: data.gstNumber,
        maxLength: 15,
      });
    }

    return validateMaxLength(validations);
  }

  private validateNegativeValues(data: any): ValidationResult {
    const numericInputs = [
      { field: 'mobileNumber', value: data.mobileNumber },
      { field: 'pinCode', value: data.pinCode }
    ];

    const validation = validateNoNegativeValues(
      numericInputs.map((input) => input)
    );
    return {
      isValid: validation.isValid,
      failedFields: validation.failedFields,
    };
  }

  private validateText(data: SupplierForm): validateName {
    const textInputs = [
      { value: data.name, field: 'Name' },
      { value: data.city, field: 'City' },
    ];

    return nameValidate(textInputs.map((input) => input)) || { isValid: true };
  }

  async validateInput() {
    const result = this.validateMaxLength(this.dataSource.value);

    if (!result.isValid) {
      const errorMessage = this.createMaxLengthMsg(result.failedFields || []);
      this.showMessage(errorMessage, SwalMessageTypes.Warning);
      throw new Error('validateMaxLength.');
    }

    const validationResult = this.validateText(this.dataSource.value);

    if (validationResult.failedField) {
      const errorMessage = this.buildErrorMessage(validationResult.failedField);
      this.showMessage(errorMessage, SwalMessageTypes.Warning);
      throw new Error('validateText.');
    }

    const validateNegativeValues = this.validateNegativeValues(
      this.dataSource.value
    );

    if (!validateNegativeValues.isValid) {
      const message = this.generateErrorMessage(
        validateNegativeValues.failedFields
      );
      this.showMessage(message, SwalMessageTypes.Warning);
      throw new Error('validateNegativeValues.');
    }

    const { gstNumber } = this.dataSource.value;

    if (gstNumber && !validateGST(gstNumber)) {
      this.showMessage(
        'GST Number is not correct. Please provide a proper GST number.',
        SwalMessageTypes.Warning
      );
      throw new Error('validateGST.');
    }

    if (!validatePinCode(this.dataSource.value.pinCode)) {
      this.showMessage(
        'PinCode is not correct. Please provide a proper PinCode  number.',
        SwalMessageTypes.Warning
      );
      throw new Error('validateGST.');
    }

    if (this.checkSpecialCharacters(this.dataSource.value)) {
      this.showMessage(
        'Aadhar,Pan and GST Should not have special characters. Please remove special characters.',
        SwalMessageTypes.Warning
      );
      throw new Error('validateText');
    }

    const response = await this.supplierService.getSuppliers(
      supplierListSearchInput()
    );
    this.existingSupplier = response['supplierList'];

    if (this.checkIsMobileNumberExists(this.dataSource.value.mobileNumber)) {
      this.showMessage(
        'Mobile Number Already Exists.',
        SwalMessageTypes.Warning
      );
      this.loader.close();
      throw new Error('Mobile number already exists.');
    }
  }
  private checkIsMobileNumberExists(mobileNumber: any) {
    const filteredData = this.existingSupplier.filter(
      (item: any) => item.id !== this.id
    );
    return filteredData.some(
      (supplier: any) =>
        supplier.mobileNumber.toString() === mobileNumber.toString()
    );
  }
  private buildErrorMessage(failedField: string): string {
    return `The ${failedField} is not valid. Please correct the ${failedField} by removing special characters.`;
  }

  private createMaxLengthMsg(
    failedFields: { field: string; failedField: string }[]
  ): string {
    const errorMessages: { [key: string]: string } = {
      mobileNumber: 'Mobile (10 digits)',
      pinCode: 'Pin Code (6 digits)',
      aadharNumber: 'Aadhar (12 digits)',
      panNumber: 'Pan (10 characters)',
      gstNumber: 'Gst (15 characters)',
    };

    const failedFieldNames = failedFields.map((failedField) => {
      return errorMessages[failedField.field];
    });

    return (
      'The input lengths for ' +
      failedFieldNames.join(', ') +
      ' do not match. Please ensure you enter the correct lengths for each field before proceeding.'
    );
  }

  generateErrorMessage(
    failedFields: { field: string; failedField: string }[] | undefined
  ): string {
    let message = 'The following fields contain invalid numeric values:';
    if (failedFields) {
      failedFields.forEach((failedField) => {
        message += ` ${failedField.field} (${failedField.failedField}),`;
      });
      message = message.slice(0, -1) + '.';
    }
    return message;
  }

  private initializeLookUpTypeSearchInput(): LookUpType {
    return {
      tableName: 'locations',
      lookupType: 'locations',
      idColumnName: 'id',
      valueColumnName: 'name',
    };
  }
}
