import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { ProductService } from '../services/product.service';
import { first } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { CommonService } from '../../../services/common.service';
import { MatIconModule } from '@angular/material/icon';
import { Location } from '@angular/common';
import { ReplaceCommasPipe } from "../../../shared/pipes/currency-pipe";
@Component({
    selector: 'app-preview-product',
    standalone: true,
    templateUrl: './preview-product.component.html',
    styleUrl: './preview-product.component.scss',
    imports: [MatButtonModule, MatIconModule, MatCardModule, ReplaceCommasPipe]
})
export class PreviewProductComponent implements OnInit {
id:any;
productDetail:any;
previewDetail:any
  constructor(
    public dialog: MatDialog,
    public location:Location,
    public route: ActivatedRoute,
    public productService: ProductService,
    public router: Router,
    public authService: AuthService,
    public commonService: CommonService
  ){

  }
ngOnInit():void{
  this.route.params.subscribe((params) => {
    this.id = params['id'];
    console.log(this.id)
  })
  this.getAll()
}
back() {
  this.location.back();
}
getAll(){
  this.commonService.showLoder()
  this.productService.getSingleApprovalDoc(this.id).pipe(first()).subscribe((res:any)=>{
   this.productDetail = res.payload
   this.productService.getDraftProductPreviewDetails(res.payload).subscribe((res:any)=>{
    this.previewDetail=res.data
    console.log(this.previewDetail)
   })
    this.commonService.hideLoder()
  },err=>{
    this.commonService.showToaster("error",err.message)
    this.commonService.hideLoder()
  })
}
  publishProduct() {
    this,this.commonService.showLoder()
    this.productService.getSingleApprovalDoc(this.id).pipe(first()).subscribe(async (res: any) => {
      if (this.authService.isVendor()) {
        res.payload.vendor_id=this.authService.getUserId();
        this.productService.reSubmitRequestForApproval(res.id, res.payload)
        this.commonService.showToaster("success",'Product has been send for approval')
        this.router.navigateByUrl('/dashboard/my-requests');
      } else {
        await this.productService.approveRequest(res).then(async (res) => {
          this.commonService.showToaster("success",'Product has been published successfully')
          this.router.navigateByUrl('/dashboard/product/published');
        },(err:any)=>{
          console.log(err)
          this.commonService.showToaster("error",err.error.message)
          this.commonService.hideLoder()
        })

      }
      this.commonService.hideLoder()
    },err=>{
      this.commonService.showToaster("error",err.error.message)
      this.commonService.hideLoder()
    })

  }
  prev() {
    this.router.navigateByUrl(`/dashboard/product/edit/${this.id}`);
  }
}
