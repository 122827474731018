@if(hasView){
<div class="container mt-4">
  <div class="card">
    <h2 class="p-3">Planning</h2>
    <div class="card-body">
      <div class="table-block">
        <form [formGroup]="form">
          <div class="col-md-6">
            <mat-form-field class="full-width">
              <input matInput formControlName="startDate" placeholder="Select Date" [matDatepicker]="appDatepicker1"
                [readonly]="true" [min]="minDate" />
              <mat-datepicker-toggle matSuffix [for]="appDatepicker1"></mat-datepicker-toggle>
            </mat-form-field>
            <mat-datepicker #appDatepicker1></mat-datepicker>
          </div>
          <div class="col-md-6">
            (For Generating Trips you need to select date)
          </div>
        </form>
        <h2 class="mt-5">Planning List</h2>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <ng-container matColumnDef="checkbox">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox [checked]="selectAllCheckbox" (change)="toggleSelectAll()" color="primary"></mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox [checked]="checkboxStates[row.typeId]" (change)="toggleCheckbox(row)" color="primary">
              </mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>Hop Type</th>
            <td mat-cell *matCellDef="let row">{{row.type}}</td>
          </ng-container>

          <ng-container matColumnDef="ordersCount">
            <th mat-header-cell *matHeaderCellDef>Orders</th>
            <td mat-cell *matCellDef="let row">{{row.ordersCount}}</td>
          </ng-container>

          <ng-container matColumnDef="customersCount">
            <th mat-header-cell *matHeaderCellDef>Unique Retailers</th>
            <td mat-cell *matCellDef="let row">{{row.customersCount}}</td>
          </ng-container>

          <ng-container matColumnDef="weight">
            <th mat-header-cell *matHeaderCellDef>Weight(Kgs)</th>
            <td mat-cell *matCellDef="let element"> {{element.weight | number:'1.2-2'}} </td>
          </ng-container>

          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef>Value</th>
            <td mat-cell *matCellDef="let element"> {{element.value}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;">
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
<div>
  <div class="container">
    <div class="row mt-4">
      <div class="col-md-6 offset-md-3 text-center">
        <button *ngIf="hasView" mat-raised-button color="primary" (click)="preview()">Preview</button>
      </div>
    </div>
  </div>
</div>
} @else {
<ng-container>
  <p class="text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
}
