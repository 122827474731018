import { config } from "../config";

export const environment = {
  env: "dev",
  fileUploadBaseUrl: config.fileUrl,
  backendUrl: config.backendUrl,
  pricingApiUrl: config.pricingApiUrl,
  coreServiceUrl: config.coreServiceUrl,
  geoService: config.geoUrl,
  logisticsUrl: config.logisticsUrl,
  inventoryUrl: config.inventoryUrl,
  cloudFunctionsUrl: config.cloudFunctionsUrl,
  ssoUrl: config.ssoUrl,
  messagingService: config.messagingService,
  eSApiUrl: config.bmsESApiUrl,
  cacheServiceApiUrl: config.cacheServiceApiUrl,
  ordersServiceUrl: config.ordersServiceUrl,
  firebase: config.firebaseConfig,
  suggestionsApi:config.suggestionsApi,
  authServiceApiUrl: config.authServiceApiUrl

};
