import { Component } from '@angular/core';
import { BaseListComponent } from '../../../../shared/core/base.list.component';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { PageId } from '../../../../constants/enums';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { NumberInput } from '@angular/cdk/coercion';
import { ErrorLogService } from '../../error-service/error-service';
import { ErrorEntity, ErrorSearchFilter, HMQueueMessageTypes, MessageType } from '../../model/model';
import { getDateYYYYMMDD } from '../../../../utlity/utility';
import Swal from 'sweetalert2';
import { SwalMessageTypes } from '../../../../enums/enums';
import { AppLoaderService } from '../../../../shared/app-loader/app-loader.service';
import { MatDialog } from '@angular/material/dialog';
import { ViewDialogComponent } from '../view-dialog/view-dialog.component';

@Component({
  selector: 'app-error-log-list',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    RouterLink,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatMenuModule,
  ],
  templateUrl: './error-log-list.component.html',
  styleUrl: './error-log-list.component.scss'
})
export class ErrorLogListComponent extends BaseListComponent {
clearFilters() {
throw new Error('Method not implemented.');
}
  errorLogsList: ErrorEntity[] = [];
  messageTypes:MessageType[]=HMQueueMessageTypes
  endDate: string = '';
  startDate: string = '';
  form!: FormGroup<any>;
  errorSearch: ErrorSearchFilter = this.initializeSearchInput();
  displayedColumns: string[] = [
   'id', 'createdDate',  'messageType','view'
  ];

  constructor(
    router: Router,
    auth: AuthService,
    route: ActivatedRoute,
    private fb: FormBuilder,
    private errorLogService: ErrorLogService,
    private loader: AppLoaderService,
    private dialog: MatDialog
  ){
    super(auth, router, route, PageId.bms_error_logs);
    this.form = this.fb.group({
      startDate: [new Date(), [Validators.required]],
      endDate: [new Date(), [Validators.required]],
      messageType: ''
    });
  }

  override async ngOnInit() {
    await super.ngOnInit();
    this.startDate = getDateYYYYMMDD(new Date());
    this.endDate = getDateYYYYMMDD(new Date());
    // this.errorLogsList = await this.errorLogService.getErrorLOgs();
    //console.log("this.errorLogsList :-----", this.errorLogsList)
    await this.getErrorLogs();
  }

  private initializeSearchInput(): ErrorSearchFilter {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
      messageType: ''
    };
  }

  async searchTasks() {
    if (this.form.invalid) {
      this.showMessage('Please Select Date.', SwalMessageTypes.Warning);

      return;
    }
    this.startDate = getDateYYYYMMDD(new Date(this.form.value.startDate));
    this.endDate = getDateYYYYMMDD(new Date(this.form.value.endDate));
    if (new Date(this.startDate) > new Date(this.endDate)) {
      this.showMessage('Please give valid dates', SwalMessageTypes.Warning);

      return;
    }
    this.getErrorLogs();
  }

  private createErrorLogFilter() {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
      messageType: this.form.value.messageType||''
    };
  }

  async getErrorLogs() {
    try {
      this.loader.open();
      this.errorSearch = this.createErrorLogFilter();
      this.errorLogsList = await this.errorLogService.getErrorLOgsByFilters(this.errorSearch);
    } catch (error) {
      console.log("error",error)
      this.loader.close();
      this.showMessage(
        'Failed to Fetch Error Logs. Please try again later.',
        SwalMessageTypes.Error
      );
    } finally {
      this.loader.close();
    }
  }
  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

   editTrip(row: ErrorEntity): void {
    this.dialog.open(ViewDialogComponent, {
      data: row
    });}

}
