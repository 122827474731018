import { AfterViewChecked, AfterViewInit, Component, OnDestroy, OnInit, OnChanges, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ReturnsReportEntity, ReturnsReportListSearch } from '../../models/returns-report.model';
import { ReturnsReportService } from '../../services/returns-report.service';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSidenavModule } from '@angular/material/sidenav';

import Swal from 'sweetalert2';
import { AppLoaderService } from '../../../../../shared/app-loader/app-loader.service';
import { AuthService } from '../../../../../services/auth.service';
import { SwalMessageTypes, SupplierStatus } from '../../../../../enums/enums';
import { BaseListComponent } from '../../../../../shared/core/base.list.component';
import { PageId } from '../../../../../constants/enums';


@Component({
  selector: 'app-returns-report-list',
  standalone: true,
  imports: [MatCardModule, MatButtonModule, MatTableModule, RouterLink, MatInputModule, MatFormFieldModule, ReactiveFormsModule,
    MatSelectModule, MatIconModule, CommonModule, FormsModule, MatDatepickerModule, MatRadioModule, MatNativeDateModule, MatPaginatorModule, MatSidenavModule],
  templateUrl: './returns-report-list.component.html',
  styleUrls: ['./returns-report-list.component.scss']
})
export class ReturnsReportListComponent extends BaseListComponent {
  singularTypes = Object.values(SupplierStatus).filter(value => typeof value === 'number');
  returnsReports: ReturnsReportEntity[] = [];
  readonly DEFAULT_PAGE_SIZE = 25;
  totalReturnsReports: number = 0;
  form: FormGroup;
  returnsReportListSearchInput: ReturnsReportListSearch = this.initializeSearchInput();

  displayedColumns = [
    'tripName',
    'vendorName',
    'tripNumber',
    'date',
    'productName',
    'case',
    'pcs',
    'totalQty',
  ];

  dataSource!: MatTableDataSource<ReturnsReportEntity>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    route: ActivatedRoute,
    router: Router,
    auth: AuthService,
    private fb: FormBuilder,
    private loader: AppLoaderService,
    public readonly returnsReportService: ReturnsReportService
  ) {

    super(auth, router, route, PageId.logistics_reports);
    this.form = this.fb.group({
      tripName: "",
      startDate: "",
      endDate: "",
      vendorName: "",
      tripNumber: "",
    });
  }

  override async ngOnInit() {
    await super.ngOnInit();
    // await this.getReturnsReports();
  }


  downloadReports() {
    console.log("Method not implemented.")
  }
  async getReturnsReports() {
    try {
      this.loader.open();

      const response = await this.returnsReportService.getReturnsReports(this.returnsReportListSearchInput);
      this.dataSource = response['returnsreportList'];
      this.returnsReports = response['returnsreportList'];
      this.totalReturnsReports = response["totalReturnsReports "];
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;


    } catch (error) {
      this.loader.close();
      // this.showMessage('Failed to Fetch ReturnsReports . Please try again later.', SwalMessageTypes.Error);
    }
  }


  private initializeSearchInput(): ReturnsReportListSearch {
    return {
      tripName: '',
      startDate: '',
      endDate: '',
      vendorName: '',
      tripNumber: '',
      pageNumber: 1,
      pageSize: 10
    };
  }

  override async onPageChange(event: any) {
    await this.onPageChange(event);
    const { returnsreportName, type, status } = this.form.value;
    this.returnsReportListSearchInput = this.createReturnsReportListSearchInput(returnsreportName, type, status)
    await this.getReturnsReports();
  }

  async searchTasks() {
    const { returnsreportName, type, status } = this.form.value;
    this.pageNumber = 1;
    this.pageSize = this.DEFAULT_PAGE_SIZE;
    this.paginator.pageIndex = 0;
    if (returnsreportName || type || status !== undefined) {
      this.returnsReportListSearchInput = this.createReturnsReportListSearchInput(returnsreportName, type, status);
      await this.getReturnsReports();

    }
  }

  private createReturnsReportListSearchInput(returnsreportName: string, type: any, status: any): ReturnsReportListSearch {
    // CHANGE INPUTS BASED ON YOUR REQUIREMENTS
    // searchText: supplierName || "",
    // type: type || null,
    // status: status == 0 ? 0 : !status ? null : status == 1 ? 1 : null,
    return {
      tripName: '',
      startDate: '',
      endDate: '',
      vendorName: '',
      tripNumber: '',
      pageNumber: this.pageNumber,
      pageSize: this.pageSize
    };
  }


  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  async clearFilters() {
    this.searchForm();
    this.returnsReportListSearchInput = this.initializeSearchInput();
    await this.getReturnsReports();
  }

  private searchForm() {
    this.form = this.fb.group({
      tripName: "",
      startDate: "",
      endDate: "",
      vendorName: "",
      tripNumber: "",
    });
  }

}
