@if(hasView){
<div class="container-fluid p-2">
  <mat-card class="p-3">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">
          <h3 class="title">Cluster List</h3>
        </div>
        <div class="col-md-6 text-end">
          @if(hasCreate){
          <button mat-raised-button (click)="openPopUp()" color="primary">
            Add Cluster
          </button>
          }
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <ng-container matColumnDef="areaName">
            <th mat-header-cell *matHeaderCellDef>Cluster Name</th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
          </ng-container>
          <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef>Location</th>
            <td mat-cell *matCellDef="let element">
              {{ element.location.value }}
            </td>
          </ng-container>
          <ng-container matColumnDef="polygon">
            <th mat-header-cell *matHeaderCellDef> Update polygon</th>
            <td mat-cell *matCellDef="let element">
              <button
                mat-icon-button
                (click)="updateClusterCoordinates(element)"
              >
                <mat-icon>backup</mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef>Delete</th>
            <td mat-cell *matCellDef="let element">
              @if(hasDelete){
              <button mat-icon-button color="warn" (click)="delete(element.id)">
                <mat-icon>delete</mat-icon>
              </button>
              }
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
    <mat-paginator
      [length]="totalLocations"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
      (page)="onPageChange($event)"
    ></mat-paginator>
  </mat-card>
</div>
} @else {
<ng-container>
  <p class="text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
} 
