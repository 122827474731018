import { Component, Inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CompanyService } from '../../company/services/company.service';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
  selector: 'app-brand-filter-dialog',
  standalone: true,
  imports: [
    ReactiveFormsModule,MatButtonModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIconModule,
    NgSelectModule
  ],
  templateUrl: './brand-filter-dialog.component.html',
  styleUrl: './brand-filter-dialog.component.scss'
})
export class BrandFilterDialogComponent {
  filterForm:any;
  companyList:any =[];

  constructor(
    public dialogRef: MatDialogRef<BrandFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb:FormBuilder,
    public companyService: CompanyService

  ) {
    this.filterForm = this.fb.group({
      name: [null],
      companies_id: [null],
      display_name: [null],
      page: [1],
      limit: [5]
    })
  }
  ngOnInit(): void {
    this.filterForm.patchValue(this.data);
    this.getAll()
  }
  getAll(){
    this.companyService.getAll().subscribe((res:any) =>{
      this.companyList =res.data
    })
  }
  close(){
    this.dialogRef.close()
  }
  reset(){
    this.filterForm.patchValue(
      {
      name: null,
      companies_id: null,
      display_name: null,
      page: 1,
      limit: 5
      }
    )
    this.dialogRef.close(this.filterForm.value)
  }
  submit(){
    let data =   {
    name: this.filterForm.value.name,
    companies_id: this.filterForm.value.companies_id,
    display_name: this.filterForm.value.display_name,
    page: 1,
    limit: 5
    }
    this.dialogRef.close(data)
  }
}
