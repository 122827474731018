<div class="heading-block">
  <div class="row">
    <div class="col-md-6">
      <mat-icon class="backicon" [routerLink]="['/dashboard/merchant']"
        >arrow_back</mat-icon
      >
    </div>
    <div class="col-md-6 text-right"></div>
  </div>
</div>
<div class="col-md-12 mt-3">
  <mat-tab-group>
    <mat-tab label="Basic Info">
      <app-info></app-info>
    </mat-tab>
    <mat-tab label="Settings">
      <app-vendor-settings></app-vendor-settings>
    </mat-tab>

    <mat-tab label="Margin">
      <app-margin></app-margin>
    </mat-tab>
    <mat-tab label="Company Mapping">
      <app-companies-list></app-companies-list>
    </mat-tab>
  </mat-tab-group>
</div>
<router-outlet></router-outlet>
