import { BusinessType } from './../customers/models/customers.model';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { ActivatedRoute, NavigationEnd, Params, Router, RouterLink, RouterOutlet } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { MerchantBusinessType } from '../../enums/enums';
import { BaseListComponent } from '../../shared/core/base.list.component';
import { PageId } from '../../constants/enums';
import { filter, takeUntil } from 'rxjs';
@Component({
  selector: 'app-order',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatButtonModule,
    RouterOutlet,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatCardModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    CommonModule,
    MatTableModule,
    RouterLink,
    ReactiveFormsModule,
  ],
  templateUrl: './order.component.html',
  styleUrl: './order.component.scss',
})
export class OrderComponent extends BaseListComponent {
  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
  ) {
    super(auth, router, route, PageId.seller_orders);
  }

  override async ngOnInit() {
    super.ngOnInit();
    // Getting an issue with below code can't navigate to the order details instead navigating home page
    // if (this.hasBMSAdmin || this.hasLogisticsAdmin) {
    //   let routeUrl = '/dashboard/home';
    //   this.router.navigateByUrl(routeUrl);
    //   return;
    // }
    this.router.events.pipe(takeUntil(this.unsubscribe$))
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          const currentUrl = event["urlAfterRedirects"];
          if (currentUrl.includes("refresh=1")) {
            this.resolveRoute();
          }
        }
      });
    this.resolveRoute();
  }
  private resolveRoute() {
    if (this.vendorId) {
      let routeUrl = '/dashboard/order/vendor-orders';
      if (this.BusinessType == MerchantBusinessType.JitVendor) {
        routeUrl = '/dashboard/order/nauka-orders';
      }
      this.router.navigateByUrl(routeUrl);
    }
  }

}
