import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { UsersService } from "../../../services/users.service";
import { AppLoaderService } from '../../../shared/app-loader/app-loader.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { PageId } from '../../../constants/enums';
@Component({
  selector: 'app-manage-users-list',
  standalone: true,
  imports: [
    RouterLink,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatSelectModule,
    FormsModule,
    CommonModule],
  templateUrl: './manage-users-list.component.html',
  styleUrls: ['./manage-users-list.component.scss'],

})
export class ManageUsersListComponent extends BaseListComponent{
  permissionObj: any;
  permissionSubscription!: Subscription;
  roles:any = [];
  public dataSource!: MatTableDataSource<any>;
  public displayedColumns: string[] = ["name","email", "actions"];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  constructor(
    private usersService: UsersService,
    private egretLoader: AppLoaderService,
    auth: AuthService,
    route: ActivatedRoute,
    router: Router,
  ) {
    super(auth, router, route, PageId.bms_users);
  }

  override async ngOnInit() {
    await super.ngOnInit();
    this.getRoles();
  }
  getRoles() {
    this.egretLoader.open();
     this.usersService.getAllUsers().subscribe(res => {
      this.dataSource = new MatTableDataSource(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.egretLoader.close();
    })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if(this.dataSource.filteredData.length === 0){
      Swal.fire({
        title: '',
        text: 'No records found',
        icon: 'warning'
      })
    }

  }

}

