<div class="d-flex justify-content-between">
  <h1 mat-dialog-title>Pending Approval Details</h1>
  <button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  @if (type=='add-sku') {
  <app-view-sku [data]="data"></app-view-sku>
  }
  @if (type=='update-sku') {
  <app-view-sku [data]="data"></app-view-sku>
  }
  @if (type=='add-sku-to-catalog') {
  <app-view-sku [data]="data"></app-view-sku>
  }
  @if (type=='update-sku-to-catalog') {
  <app-view-sku [data]="data"></app-view-sku>
  }
  @if (type=='add-company') {
  <app-add-company [data]="data"></app-add-company>
  }
  @if (type=='add-variant-type') {
  <app-add-variant-type [data]="data"></app-add-variant-type>
  }
  @if (type=='add-variant-value') {
  <app-add-variant-value [data]="data"></app-add-variant-value>
  }
  @if (type=='add-brand') {
  <app-add-brand [data]="data"></app-add-brand>
  }
  @if (type=='add-category') {
  <app-add-category [data]="data"></app-add-category>
  }
  @if (type=='add-attribute') {
  <app-add-attribute [data]="data"></app-add-attribute>
  }
  @if (type=='add-product') {

  <app-add-product [data]="data"></app-add-product>
  }
</div>
<div mat-dialog-actions>

  <div class="d-flex justify-content-end w-100">
    <mat-form-field class="w-100">
      <mat-label>Comment</mat-label>
      <input matInput [(ngModel)]="comment">
    </mat-form-field>
  </div>
  <div class="d-flex justify-content-end w-100">
    <button mat-raised-button color="warn" [disabled]="!comment" (click)="onReject()">Reject</button>
    <button mat-raised-button color="primary" (click)="approve()">Approve</button>
    @if(data.type=='add-sku' || data.type=='update-sku' || data.type=='add-sku-to-catalog' || data.type=='update-sku-to-catalog'){
      <button mat-raised-button color="primary" (click)="editAndApprove()">Edit & Approve</button>
    }

  </div>
</div>