import { BaseListComponent } from './../../../shared/core/base.list.component';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { AttributeService } from '../services/attribute.service';
import { AuthService } from '../../../services/auth.service';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { FormBuilder } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AttributeFilterDialogComponent } from '../attribute-filter-dialog/attribute-filter-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductService } from '../../product/services/product.service';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmDialogComponent } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.component';
import { CommonService } from '../../../services/common.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { MatSortHeader, MatSortModule } from '@angular/material/sort';
import { SwalMessageTypes } from '../../../enums/enums';
import Swal from 'sweetalert2';
import { PageId } from '../../../constants/enums';
import { takeUntil } from 'rxjs';
@Component({
  selector: 'app-attribute-list',
  standalone: true,
  imports: [CommonModule,
    MatIconModule, MatTooltipModule, MatSortModule,
    MatCardModule, MatButtonModule, MatTableModule, RouterLink, MatPaginatorModule, MatProgressSpinnerModule],
  templateUrl: './attribute-list.component.html',
  styleUrl: './attribute-list.component.scss',
})
export class AttributeListComponent extends BaseListComponent implements OnInit {
  dataSource: any = [];
  displayedColumns: string[] = ['attributes.name', 'action'];
  queryForm: any
  meta: any
  loading = true
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('fileInput') fileInput!: ElementRef;

  constructor(
    router: Router,
    auth: AuthService,
    route: ActivatedRoute,
    public fb: FormBuilder,
    public dialog: MatDialog,
    public productService: ProductService,
    public authService: AuthService,
    public commonService: CommonService,
    public attributeService: AttributeService,) {
    super(auth, router, route, PageId.bms_attribute);
    this.queryForm = this.fb.group({
      name: [null],
      page: [1],
      limit: [5],
      sort_order: [],
      sort_by: []
    })
  }

  override async ngOnInit() {
    await super.ngOnInit();

    this.queryForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: any) => {
        this.navigateWithParams(value);
        this.getAllAttribute();
      });
    if (this.route.snapshot.queryParams) {
      this.queryForm.patchValue(this.route.snapshot.queryParams);
    } else {
      this.getAllAttribute();
    }
  }
  isDataAvailable(): boolean {
    return this.dataSource && this.dataSource?.data?.length > 0;
  }
  getAllAttribute() {
    this.loading = true;
    this.dataSource = new MatTableDataSource([]);
    this.attributeService.getAll(this.queryForm.value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.meta = res.meta;
        this.loading = false;
        this.dataSource = new MatTableDataSource(res.data);
        this.goToCorrectPage();
      });
  }
  goToCorrectPage() {
    if (this.paginator) {
      this.paginator.pageIndex = this.queryForm.value.page - 1;
      // Trigger a manual page change event so that the MatPaginator updates accordingly
      // this.paginator._changePageSize(this.paginator.pageSize);
    }
  }
  async delete(id: any) {
    /*   const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data:  {title : 'Delete Confirmation', message:"Are you sure you want to delete?"},
        panelClass: 'custom-dialog-class'
      });
      dialogRef.afterClosed().subscribe(async result => {
        if(result == 'yes'){
      try {
        await this.attributeService.delete(id);
        this.commonService.showToaster("success",'Deleted Successfully')
        this.ngOnInit()
      } catch (err:any) {
        console.log(err);
        this.commonService.showToaster("error",err.error.message)
      }
    }
  });
   */
    try {
      const result = await Swal.fire({
        title: 'Do you want to Delete Attribute?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      });

      if (!result.isConfirmed) {
        return;
      }
      await this.attributeService.delete(id);
      // this.ngOnInit()
      this.showMessage(
        'Attribute Deleted Successfully',
        SwalMessageTypes.Success
      );
    } catch (error: any) {
      this.showMessage(
        error.error.message,
        SwalMessageTypes.Error
      );
    }
  }
  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }
  onPaginationChange(event: any) {
    this.queryForm.patchValue({
      page: event.pageIndex + 1,
      limit: event.pageSize
    })
  }

  navigateWithParams(queryParams: any) {
    Object.keys(queryParams).forEach(key => {
      if (!queryParams[key] || queryParams[key] == 'null') {
        delete queryParams[key]
      }
    })
    // Navigate to the current route with the specified query parameters
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams
    });
  }
  openFilter() {
    const dialogRef = this.dialog.open(AttributeFilterDialogComponent, {
      data: this.queryForm.value,
      panelClass: 'custom-dialog-class'
    });

    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe$)).subscribe((result) => {
      if (result) {
        this.queryForm.patchValue(result);
      }
    });
  }
  clearFileInput() {
    setTimeout(() => {
      this.fileInput.nativeElement.value = '';
    }, 3000);
  }
  upload(event: any) {
    let selectedFile = event.target.files[0] as File;
    const formData = new FormData();
    formData.append('csvFile', selectedFile);
    formData.append('type', 'attribute_types');
    this.importConfirm(formData, selectedFile.name)

  }
  async importConfirm(formData: any, name: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { title: 'Confirmation', message: `Are you sure you want to upload ${name} file?` },
      panelClass: 'custom-dialog-class'
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe$)).subscribe(async result => {
      if (result == 'yes') {
        this.productService.uploadData(formData).pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
          this.queryForm.patchValue({
            page: 1,
            limit: 5
          })
          this.getAllAttribute()
          this.commonService.showToaster("success", 'Uploaded Successfully')
        },
          (error) => {
            const dataKey = Object.keys(error.error.data)[0];
            const mergedMessage = `${error.error.message} ${dataKey} ${error.error.data[dataKey]}`;
            this.commonService.showToaster("error", mergedMessage)

          })
      }
    });
  }
  export() {
    this.commonService.showLoder();
    let query = {
      type: 'attribute_types',
      name: this.queryForm.value.name
    }
    this.productService.exportData(query).pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(res);
      link.download = `attribute_${(new Date()).toISOString()}.csv`;
      link.click();
      this.commonService.hideLoder();
    }, (err: any) => {
      console.log(err)
      this.commonService.hideLoder();
      this.commonService.showToaster("error", err.error.message);
    })
  }

  downloadSample() {
    const excelUrl = 'assets/csv_samples/attribute_types.csv';

    // Fetch the file
    fetch(excelUrl)
      .then(response => response.blob())
      .then(blob => {
        // Create a link element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'attribute_types.csv'; // Set the file name here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(error => console.error('Error downloading Excel file:', error));

  }
  announceSortChange(event: any) {
    this.queryForm.patchValue({
      page: 1,
      limit: 5,
      sort_order: event.direction,
      sort_by: 'name'
    });
  }
}
