import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import {
  ExpenseType,
  LookUpType,
  PurchaseOrderStatus,
  UOM,
} from '../../../enums/enums';
import { AuthService } from '../../../services/auth.service';
import { PurchaseReceiptService } from '../service/purchasereceipt.service';
import { ConfigService } from '../../config/services/config.service';
import { LookUpTypeService } from '../../../services/lookup-type.service';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { PageId } from '../../../constants/enums';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ReplaceCommasPipe } from '../../../shared/pipes/currency-pipe';

@Component({
  selector: 'supplier-payout',
  standalone: true,
  templateUrl: './supplier-payout.component.html',
  styleUrl: './supplier-payout.component.scss',
  imports: [
    MatFormFieldModule,
    MatButtonModule,
    RouterOutlet,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatCardModule,
    MatIconModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    CommonModule,
    MatTableModule,
    RouterLink,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    ReplaceCommasPipe,
  ],
})
export class SupplierPayoutComponent extends BaseListComponent {
  displayedColumns: string[] = [
    'No',
    'supplier',
    'expense_type',
    'rate',
    'payout',
  ];
  page_id = 'bms_area_edit';
  permission: any = false;
  dataSource: any[] = [];
  prId: string = '';
  expensePayouts: any[] = [];
  finalPayouts: Array<any> = [];
  prPayout: any;
  isAllSelected: boolean = false;
  public suppliersList: any[] = [];
  isItemPayoutZero: boolean = false;
  payOutZeroItems: string = '';
  loading: boolean = true;
  procurementExpenses: Array<any> = [];
  supplierExpenses: Array<any> = [];
  procurementSupplierId: string = '';
  itemList: any = [];
  supplierNames: LookUpType = this.initializeSuppliers();
  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<SupplierPayoutComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public authService: AuthService,
    private configService: ConfigService,
    private lookupService: LookUpTypeService,
    public purchaseReceiptService: PurchaseReceiptService
  ) {
    super(auth, router, route, PageId.seller_pr);
  }

  override async ngOnInit() {
    await super.ngOnInit();
    this.loading = false;
    this.getSuppliers();
    this.prId = this.data;
    if (await this.getPurchaseReceiptItems()) {
      return;
    }
    await this.getPurchaseReceipt();
    await this.getPurchaseReceiptExpenses();
  }

  async getPurchaseReceiptItems(): Promise<boolean> {
    this.itemList = await this.purchaseReceiptService.getPurchaseReceiptItems(
      this.prId
    );
    if (this.itemList.length > 0) {
      const data = this.itemList.filter((i: any) => i.invoiced_amount == 0);
      if (data.length > 0) {
        this.isItemPayoutZero = true;
        this.payOutZeroItems = data
          .map((item: any) => item.sku_name)
          .join(', ');
        return true;
      }
    }
    return false;
  }
  async getPurchaseReceipt() {
    const res = await this.purchaseReceiptService.getPurchaseReceiptById(
      this.prId
    );
    this.procurementSupplierId = res.supplier_id;
    this.prPayout = this.mapToPrPayout(res);
    console.log('purchase receiptis ', res);
    this.finalPayouts.push(this.prPayout);
    this.loading = false;
  }
  mapToPrPayout(res: any) {
    return {
      supplier: res.supplier_id,
      payout: +res.payout,
    };
  }

  async getPurchaseReceiptExpenses() {
    let tds_amount = 0;
    this.itemList.forEach((item: any) => {
      tds_amount += +item.tds_amount;
    });
    tds_amount = parseFloat(tds_amount.toFixed(4));
    const res = await this.purchaseReceiptService.getPrExpenses(this.prId);
    this.expensePayouts = this.mapToExpensePayout(res);
    this.procurementExpenses = this.expensePayouts.filter(
      (expense) => expense.supplier === this.procurementSupplierId
    );
    let totalPayout = 0;
    let LoadingByBuyerCharges = 0;
    let purchaseCreditCharges = 0;
    for (let e = 0; e < this.procurementExpenses.length; e++) {
      const expense = this.procurementExpenses[e];
      const { expense_type, payout } = expense;

      switch (expense_type) {
        case ExpenseType.LoadingByBuyer:
          LoadingByBuyerCharges = payout;
          break;
        case ExpenseType.PurchaseCredit:
          purchaseCreditCharges = payout;
          break;
        default:
          totalPayout += payout;
          break;
      }
    }
    const netPayable =
      this.prPayout.payout - totalPayout + LoadingByBuyerCharges - tds_amount;

    const netPayableObj = this.mapTonetpayable(netPayable);

    this.finalPayouts = this.finalPayouts.concat(this.procurementExpenses);
    this.finalPayouts.push(netPayableObj);
    this.supplierExpenses = this.expensePayouts.filter(
      (expense) => expense.supplier != this.procurementSupplierId
    );
    this.finalPayouts = this.finalPayouts.concat(this.supplierExpenses);
    this.dataSource = this.finalPayouts;
    console.log('data source is the', this.dataSource);
  }

  mapTonetpayable(netPayable: number) {
    return {
      expense_type: 'netPayable',
      payout: netPayable,
    };
  }
  mapToExpensePayout(res: Array<any>) {
    return res.map((expense) => {
      return {
        supplier: expense.supplier_id,
        payout: expense.payout,
        expense_type: expense.expense_type,
        rate: expense?.discount_percent
          ? `${expense.discount_percent}%`
          : expense.total_value,
      };
    });
  }
  async approve() {
    try {
      this.loading = true;
      const body = this.mapToReqApproveBody();
      this.isAllSelected = false;
      const checkedItems = this.dataSource.filter(
        (element) => element.isSelected
      );

      if (checkedItems.length === this.dataSource.length) {
        const res = await this.purchaseReceiptService.requestForApproval(
          body,
          this.prId
        );
        this.loading = false;
        this.dialogRef.close();

        Swal.fire('successs', 'Successfully Requested for approval', 'success');
      } else {
        this.isAllSelected = true;
        this.loading = false;
        console.log('Not all items are selected');
      }
    } catch (error) {
      this.loading = false;
      Swal.fire(
        'Warning',
        'Something went wrong Please try again later',
        'warning'
      );
    }
  }
  mapToReqApproveBody() {
    return {
      approved_user_id: this.vendorId,
    };
  }

  getUomType(type: number): string {
    return UOM[type];
  }
  getSupplierName(supplierId: string) {
    for (const supplier of this.suppliersList) {
      if (supplier.id == supplierId) {
        return supplier.value;
      }
    }
    return null;
  }
  async getSuppliers() {
    const response = await this.lookupService.getLookUpTypes(
      this.supplierNames
    );
    this.suppliersList = response.data[0].data;
  }
  private initializeSuppliers(): LookUpType {
    return {
      tableName: 'suppliers',
      lookupType: 'suppliers',
      idColumnName: 'Id',
      valueColumnName: 'Name',
    };
  }

  getExpenseType(status: number): string {
    return ExpenseType[status];
  }
}
