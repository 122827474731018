import { Injectable } from '@angular/core';
import { ReturnsReportEntity, ReturnsReportListSearch, createReturnsReportResponse } from '../models/returns-report.model';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReturnsReportService {
  baseServiceUrl: string = `${environment.logisticsUrl}`;

  constructor(
    private readonly http: HttpClient) {
  }


  async createReturnsReport(data: ReturnsReportEntity) {

    let response = await lastValueFrom(this.http.post<{ res: createReturnsReportResponse }>(`${this.baseServiceUrl}/api/returnsreport/create`, data));

    return response.res
  }

  async getReturnsReports(body: ReturnsReportListSearch): Promise<any> {
    const res = await lastValueFrom(this.http.post<any>(`${this.baseServiceUrl}/api/returnsreport/get_by_filters`, body));

    if (res && res.data) {
      return {
        returnsreportList: res.data.rows,
        totalReturnsReports: res.data.count
      }
    }

  }

  async getReturnsReportById(id: string): Promise<any> {
    const res = await lastValueFrom(this.http.get<any>(`${this.baseServiceUrl}/api/returnsreport/get_by_id/${id}`));
    return res;
  }

  async updateReturnsReport(returnsreportEntity: ReturnsReportEntity, returnsreportid: string) {
    let { id, ...returnsreportUpdatedEntity } = returnsreportEntity;
    let response = await lastValueFrom(this.http.post<{ res: createReturnsReportResponse }>(
      `${this.baseServiceUrl}/api/returnsreport/update/${returnsreportid}`,
      returnsreportUpdatedEntity
    ));
    return response.res
  }

  async deleteReturnsReport(id: string) {
    return await lastValueFrom(this.http.delete(`${this.baseServiceUrl}/api/returnsreport/delete/${id}`));
  }


}
