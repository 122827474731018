<ng-container *ngIf="hasView">
  <h2 class="card-title m-3" style="color:#4A2981 ;">Coupons and Discounts</h2>
  <div class="row" style="width:99%">
    <div class="m-3 flex flex-col md:flex-row justify-between " style="margin-bottom: 20px;">
      <mat-checkbox (change)="toggleActive($event)" color="primary" style="float: left;">Show
        Inactive</mat-checkbox>
      <div class="md:w-[100px] lg:w-1/6 xl:w-2/6"></div>
      <button mat-raised-button color="primary" *ngIf="hasCreate" [routerLink]="['/dashboard/coupon/add']"
        style="float: right;">Create Coupon</button>
    </div>
  </div>

  <div class="m-20 coupenlist">
    <mat-card class="p-0 m-20">
      <mat-card-content class="p-0">
        <mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef> ID </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="id"> {{row._id}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="name"> {{row.name}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="startDate">
            <mat-header-cell *matHeaderCellDef> Created Date </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="startDate"> {{row.startDate }} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="endDate">
            <mat-header-cell *matHeaderCellDef> Valid Until </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="endDate"> {{row.endDate}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="code">
            <mat-header-cell *matHeaderCellDef> Code </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="code"> {{row.displayName}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
            <mat-cell *matCellDef="let row" [style.color]="row.color" data-label="color">
              <mat-chip  [color]="row.active ? 'primary' : 'warn'" class="mat-sm-chip">
                {{row.active ? 'Active' : 'Inactive'}}
              </mat-chip>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="mat-column-actions"> Action </mat-header-cell>
            <mat-cell *matCellDef="let row" class="mat-column-actions">
              <div class="d-flex">
                <button *ngIf="hasEdit" mat-raised-button color="primary"
                  (click)="changeStatusCoupon(row)">Mark
                  as
                  {{row.active?'Inactive':'Active'}}</button>

                <button *ngIf="hasView" mat-raised-button class="ms-2" color="primary"
                  [routerLink]="['/dashboard/coupon/detail',row.id]">Details</button>
              </div>

            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="pageSizeOptions"></mat-paginator>

      </mat-card-content>
    </mat-card>
  </div>



</ng-container>
<ng-container *ngIf="!hasView">
  <p class="text-center">You dont have permission , Please contact administrator</p>
</ng-container>
