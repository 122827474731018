import { Component } from '@angular/core';
import { FormBuilder, Validators, ReactiveFormsModule, FormsModule, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { IncentiveSlabsService } from '../../services/incentiveslabs.service';
import { IncentiveSlabEntity, IncentiveSlabForm } from '../../models/incentiveslabs.model';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AppLoaderService } from '../../../../shared/app-loader/app-loader.service';
import { AuthService } from '../../../../services/auth.service';
import { SwalMessageTypes, SupplierStatus } from '../../../../enums/enums';
import Swal from 'sweetalert2';
import { BaseListComponent } from '../../../../shared/core/base.list.component';
import { PageId } from '../../../../constants/enums';

@Component({
  selector: 'app-edit-incentiveslab',
  standalone: true,
  imports: [MatCardModule, MatButtonModule, MatTableModule, RouterLink, MatInputModule,
    MatFormFieldModule, ReactiveFormsModule, MatSelectModule, MatIconModule,
    CommonModule, FormsModule, MatDatepickerModule, MatRadioModule, MatNativeDateModule,
    MatPaginatorModule, MatSidenavModule, MatDatepickerModule, MatCheckboxModule],
  templateUrl: './edit-incentiveslab.component.html',
  styleUrls: ['./edit-incentiveslab.component.scss']
})
export class EditIncentiveSlabComponent extends BaseListComponent{
  singularTypes = Object.values(SupplierStatus).filter(value => typeof value === 'number');
  dataSource: any;
  id: string = "";
  incentiveSlabEntity!: IncentiveSlabEntity;

  constructor(private fb: FormBuilder,
    router: Router,
    route: ActivatedRoute,
    private incentiveSlabsService: IncentiveSlabsService,
    private loader: AppLoaderService,
    auth: AuthService) {
      super(auth, router, route, PageId.bms_promotions);

  }

  override async ngOnInit() {
    await super.ngOnInit();
    this.route.params.subscribe(async (params) => {
      this.id = params['id'];
      await this.getIncentiveSlabById();

    });
    this.editForms();
  }

  editForms() {
    this.dataSource = this.fb.group({
      incentiveSlabId: [null, [Validators.required]],
      incentiveSlabName: [null, [Validators.required]],
      visitsCountMin: [null, [Validators.required]],
      visitsCountMax: [null, [Validators.required]],
      dayRevenuePercentMin: [null, [Validators.required]],
      dayRevenuePercentMax: [null, [Validators.required]],
      dayTasksPercentMin: [null, [Validators.required]],
      dayTasksPercentMax: [null, [Validators.required]],
      buyingCountPercentMin: [null, [Validators.required]],
      buyingCountPercentMax: [null, [Validators.required]],
      nonBuyingCountPercentMin: [null, [Validators.required]],
      nonBuyingCountPercentMax: [null, [Validators.required]],
      incentiveBuyingOrder: [null, [Validators.required]],
      incentiveNonBuyingOrder: [null, [Validators.required]],
      petrolAllowance: [null, [Validators.required]],
      isActive: [null, [Validators.required]],
    });
  }
  async getIncentiveSlabById() {
    try {
      this.loader.open();
      const response = await this.incentiveSlabsService.getIncentiveSlabById(this.id);
      this.dataSource = await this.mapToEditInput(response.data);
    } catch (error) {
      this.showMessage('Failed to Get incentive slab. Please try again later.', SwalMessageTypes.Error);
    } finally {
      this.loader.close();

    }
  }


  async updateIncentiveSlab() {
    this.loader.open();

    try {
      if (this.dataSource.invalid) {
        this.showMessage('Please fill all required fields.', SwalMessageTypes.Warning);

        return
      }

      if(this.hasOnlyEmptySpaces(this.dataSource.value.incentiveSlabName)){
        this.showMessage(
          `You entered only empty space`,
          SwalMessageTypes.Warning
        );

        return ;
      }

      if(this.dataSource.value.visitsCountMin>this.dataSource.value.visitsCountMax){
        this.showMessage(
          `min qty should be less than max qty`,
          SwalMessageTypes.Warning
        );

        return ;
      }

      const negativeFields = this.validateNegativeValues(this.dataSource.value);

      if (negativeFields.length) {
        this.showMessage(
          `${negativeFields} ,have negative values Please Check`,
          SwalMessageTypes.Warning
        );

        return ;
      }

      await this.updateIncentiveSlabById();
    } catch (error) {
      console.error('Error occurred during incentive slab creation:', error);
      this.showMessage('Failed to create incentive slab. Please try again later.', SwalMessageTypes.Error);
    } finally {
      this.loader.close();
    }
  }

  private hasOnlyEmptySpaces(str:string) {
    return /^\s*$/.test(str);
  }

  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  private mapIncentiveSlab(incentiveSlab: IncentiveSlabForm): IncentiveSlabEntity {
    const form = incentiveSlab;
    return {
      incentiveSlabId: form?.incentiveSlabId,
      incentiveSlabName: form?.incentiveSlabName,
      visitsCountMin: form?.visitsCountMin,
      visitsCountMax: form?.visitsCountMax,
      dayRevenuePercentMin: form?.dayRevenuePercentMin,
      dayRevenuePercentMax: form?.dayRevenuePercentMax,
      dayTasksPercentMin: form?.dayTasksPercentMin,
      dayTasksPercentMax: form?.dayTasksPercentMax,
      buyingCountPercentMin: form?.buyingCountPercentMin,
      buyingCountPercentMax: form?.buyingCountPercentMax,
      nonBuyingCountPercentMin: form?.nonBuyingCountPercentMin,
      nonBuyingCountPercentMax: form?.nonBuyingCountPercentMax,
      incentiveBuyingOrder: +form?.incentiveBuyingOrder,
      incentiveNonBuyingOrder: +form?.incentiveNonBuyingOrder,
      petrolAllowance: form?.petrolAllowance,
      isActive: form?.isActive == true ? 1 : 0,
      createdDate:this.dataSource.value.createdDate,
      updatedDate:new Date(),
      createdBy: 'Sales Admin',
      updatedBy: 'Sales Admin',
    }
  }

  async updateIncentiveSlabById() {
    const incentiveSlabEntity = this.mapIncentiveSlab(this.dataSource.value);
    try {
      const response = await this.incentiveSlabsService.updateIncentiveSlab(incentiveSlabEntity, this.id);
      this.showMessage('IncentiveSlab updated successfully', SwalMessageTypes.Success);
      this.router.navigateByUrl('/dashboard/incentives/incentives-list');
    } catch (error) {
      console.error('Error occurred while creating incentive slab:', error);
      this.showMessage('Failed to create incentive slab. Please try again later.', SwalMessageTypes.Error);
    }
  }


  private mapToEditInput(incentiveSlab: IncentiveSlabEntity): FormGroup {
    const entity = incentiveSlab;
    return this.fb.group({
      incentiveSlabId: new FormControl(entity.incentiveSlabId || "",Validators.required),
      incentiveSlabName: new FormControl(entity.incentiveSlabName || "",Validators.required),
      visitsCountMin: new FormControl(entity.visitsCountMin || "",Validators.required),
      visitsCountMax: new FormControl(entity.visitsCountMax || "",Validators.required),
      dayRevenuePercentMin: new FormControl(entity.dayRevenuePercentMin || "",Validators.required),
      dayRevenuePercentMax: new FormControl(entity.dayRevenuePercentMax || "",Validators.required),
      dayTasksPercentMin: new FormControl(entity.dayTasksPercentMin || "",Validators.required),
      dayTasksPercentMax: new FormControl(entity.dayTasksPercentMax || "",Validators.required),
      buyingCountPercentMin: new FormControl(entity.buyingCountPercentMin || "",Validators.required),
      buyingCountPercentMax: new FormControl(entity.buyingCountPercentMax || "",Validators.required),
      nonBuyingCountPercentMin: new FormControl(entity.nonBuyingCountPercentMin || "",Validators.required),
      nonBuyingCountPercentMax: new FormControl(entity.nonBuyingCountPercentMax || "",Validators.required),
      incentiveBuyingOrder: new FormControl(entity.incentiveBuyingOrder || "",Validators.required),
      incentiveNonBuyingOrder: new FormControl(entity.incentiveNonBuyingOrder || "",Validators.required),
      petrolAllowance: new FormControl(entity.petrolAllowance || "",Validators.required),
      isActive: new FormControl(entity.isActive || ""),
      createdDate:new FormControl(entity.createdDate || "",Validators.required)
    });
  }

  private validateNegativeValues(data: IncentiveSlabForm): string[] {
    const numericInputs = [
      { name: 'Visits Count Min', value: data.visitsCountMin },
      { name: 'Visits Count Max', value: data.visitsCountMax },
      { name: 'Day Tasks Percent Min', value: data.dayTasksPercentMin },
      { name: 'Petrol Allowance', value: data.petrolAllowance },
      { name: 'Day Revenue Percent Min', value: data.dayRevenuePercentMin },
      { name: 'Day Revenue Percent Max', value: data.dayRevenuePercentMax },
      { name: 'Day Tasks Percent Max', value: data.dayTasksPercentMax },
      { name: 'Buying Count Percent Min', value: data.buyingCountPercentMin },
      { name: 'Buying Count Percent Max', value: data.buyingCountPercentMax },
      {
        name: 'Non Buying Count Percent Min',
        value: data.nonBuyingCountPercentMin,
      },
      {
        name: 'Non Buying Count Percent Max',
        value: +data.nonBuyingCountPercentMax,
      },
      { name: 'Incentive Buying Order', value: +data.incentiveBuyingOrder },
      {
        name: 'Incentive Non Buying Order',
        value: +data.incentiveNonBuyingOrder,
      },
    ];

    const negativeFields = numericInputs
      .filter((input) => input.value < 0)
      .map((input) => input.name);

    return negativeFields;
  }
}
