import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ProductService } from '../services/product.service';
import { AuthService } from '../../../services/auth.service';
import { FormBuilder } from '@angular/forms';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProductFilterDialogComponent } from '../product-filter-dialog/product-filter-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmDialogComponent } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.component';
import { CommonService } from '../../../services/common.service';
import { CommonModule } from '@angular/common';
import { MatSortHeader, MatSortModule } from '@angular/material/sort';
import Swal from 'sweetalert2';
import { SwalMessageTypes } from '../../../enums/enums';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { PageId } from '../../../constants/enums';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';

@Component({
  selector: 'app-vendor-sku-list',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatSortModule, MatCardModule, MatButtonModule, MatTableModule, RouterLink, MatPaginatorModule, MatProgressSpinnerModule],
  templateUrl: './vendor-sku-list.component.html',
  styleUrl: './vendor-sku-list.component.scss',
})
export class VendorSkuListComponent extends BaseListComponent implements OnInit {
  dataSource: any = [];
  displayedColumns: string[] = ['name', 'brands_id', 'companies_id', 'categories_id', 'status', 'action'];
  draftProducts: any = [];
  meta: any;
  loading = true;
  filterForm: any;
  defaultStatus: string[] = ['active']
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public commonService: CommonService,
    public productService: ProductService) {
    super(auth, router, route, PageId.seller_my_sku); // Call the constructor of BaseListComponent

  }

  goToCorrectPage() {
    const pageIndex = this.filterForm.value.page - 1;
    // if (this.paginator) {
    //   this.paginator.pageIndex = pageIndex;
    //   this.paginator._changePageSize(this.paginator.pageSize);
    // }
  }



  override async ngOnInit() {
    await super.ngOnInit();

    this.filterForm = this.fb.group({
      name: [null],
      companies_id: [null],
      brands_id: [null],
      categories_id: [null],
      status: this.defaultStatus,
      page: [1],
      limit: [this.pageSize],
      sort_order: [],
      sort_by: []
    })

    this.filterForm.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$) // Unsubscribe when the component is destroyed
      )
      .subscribe((value: any) => {
        // console.log(value,"inside")
        this.navigateWithParams(value)
        this.getAllSkus();

      })
    if (this.route.snapshot.queryParams) {
      this.filterForm.patchValue(this.route.snapshot.queryParams)
    } else {
      this.getAllSkus();
    }

  }

  isDataAvailable(): boolean {
    return this.dataSource && this.dataSource?.data?.length > 0;
  }
  getAllSkus() {
    this.loading = true;
    this.dataSource = new MatTableDataSource([]);


    this.productService.getAllVendorSkus(this.filterForm.value)
      .pipe(
        takeUntil(this.unsubscribe$) // Unsubscribe when the component is destroyed
      )
      .subscribe((res: any) => {
        this.meta = res.meta
        this.dataSource = new MatTableDataSource(res.data);
        console.log(" this.dataSource:::", this.dataSource);
        this.goToCorrectPage()
        this.loading = false
      });
  }



  navigateWithParams(queryParams: any) {
    Object.keys(queryParams).forEach(key => {
      if (!queryParams[key] || queryParams[key] == 'null') {
        delete queryParams[key]
      }
    })
    // Navigate to the current route with the specified query parameters
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams
    });
  }

  openFilter() {

    if (typeof this.filterForm.value.status === 'string') {
      this.filterForm.value.status = [this.filterForm.value.status]
    }
    const dialogRef = this.dialog.open(ProductFilterDialogComponent, {
      data: this.filterForm.value,
      panelClass: 'custom-dialog-class-p'
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.filterForm.patchValue(result)
        this.goToCorrectPage()
      }
    });
  }
  announceSortChange(event: any) {
    this.filterForm.patchValue({
      page: 1,
      limit: this.pageSize,
      sort_order: event.direction,
      sort_by: event.active
    })
  }

  changeStatus(id: any, status: any, type: any) {
    this.productService.updateVendorSkuStatus(id, status).then(res => {
      this.getAllSkus()
      this.showMessage(
        `${type} Successfully`,
        SwalMessageTypes.Success
      );
    })
  }
  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  onPaginationChange(event: any) {
    this.filterForm.patchValue({
      page: event.pageIndex + 1,
      limit: event.pageSize
    })
  }

}
