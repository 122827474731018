import { Component } from '@angular/core';
import { FormBuilder, Validators, ReactiveFormsModule, FormsModule, ValidatorFn, AbstractControl, FormControl, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { LocationsService } from '../../services/locations.service';
import { LocationEntity, LocationListSearch, LocationForm, PropertyValidation } from '../../models/locations.model';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AppLoaderService } from '../../../../../shared/app-loader/app-loader.service';
import { AuthService } from '../../../../../services/auth.service';
import { SwalMessageTypes, SupplierStatus, states } from '../../../../../enums/enums';
import Swal from 'sweetalert2';
import uuid4 from 'uuid4';
import { applyMaxLengthValidation, containsOnlyAlphanumeric, hasSpecialCharacters, supplierListSearchInput, validateGST, validateMaxText, validateNegativeValues, validatePinCode, validateSpecialCharacters, validateText } from '../../../../../utlity/utility';
import { State } from '../../../supplier/models/supplier-model';
import { BaseListComponent } from '../../../../../shared/core/base.list.component';
import { PageId } from '../../../../../constants/enums';

@Component({
  selector: 'app-create-location',
  standalone: true,
  imports: [MatCardModule, MatButtonModule, MatTableModule, RouterLink, MatInputModule,
    MatFormFieldModule, ReactiveFormsModule, MatSelectModule, MatIconModule,
    CommonModule, FormsModule, MatDatepickerModule, MatRadioModule, MatNativeDateModule,
    MatPaginatorModule, MatSidenavModule, MatDatepickerModule, MatCheckboxModule],
  templateUrl: './create-location.component.html',
  styleUrls: ['./create-location.component.scss']
})
export class CreateLocationComponent extends BaseListComponent {
  singularTypes = Object.values(SupplierStatus).filter(value => typeof value === 'number');
  dataSource: any;
  locationEntity!: LocationEntity;
  states: State[] = states;

  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    private locationsService: LocationsService,
    private loader: AppLoaderService,
  ) {
    super(auth, router, route, PageId.logistics_location); // Call the constructor of BaseListComponent
  }
  override async ngOnInit() {
    super.ngOnInit();
    this.dataSource = this.fb.group({
      name: [null, [Validators.required, this.alphabeticOnlyValidator()]],
      warehouseName: [null, [Validators.required, this.alphabeticOnlyValidator()]],
      area: [null, [Validators.required, this.alphabeticOnlyValidator()]],
      state: [null, [Validators.required]],
      pincode: [null, [Validators.required]],
      city: [null, [Validators.required, this.alphabeticOnlyValidator()]],
      country: [null, [Validators.required, this.alphabeticOnlyValidator()]],
      latitude: [null, [Validators.required]],
      longitude: [null, [Validators.required]],
    });
  }


  async saveLocation() {
    this.loader.open();
    try {
      if (this.dataSource.invalid) {
        this.showMessage('Please fill all required fields with valid data.', SwalMessageTypes.Warning);

        return
      }
      await this.createLocation();
    } catch (error) {
      console.error('Error occurred during location creation:', error);
      this.showMessage('Failed to create location. Please try again later.', SwalMessageTypes.Error);
    } finally {
      this.loader.close();
    }
  }


  // noWhitespaceValidator(control: { value: string; }) {
  //   if (control.value === null || control.value === undefined) {
  //     return null;
  //   }
  //   const trimmedValue = control.value.trim();
  //   if (trimmedValue.length === 0) {
  //     return { 'whitespace': true };
  //   }
  //   control.value = trimmedValue;
  //   return null;
  // }

  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }


  private mapLocation(location: LocationForm, locationId: string): LocationEntity {
    const mapForm = location
    return {
      id: locationId,
      name: location.name.trim(),
      warehouseName: mapForm?.warehouseName.trim(),
      warehouseId: locationId, //Both ids are same(id & warehouseId)
      area: mapForm?.area.trim(),
      state: mapForm?.state,
      pincode: mapForm?.pincode,
      city: mapForm?.city.trim(),
      country: mapForm?.country.trim(),
      latitude: mapForm?.latitude,
      longitude: mapForm?.longitude
    }
  }


  alphabeticOnlyValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (!value) {
        return null;
      }
      const containsOnlySpaces = value.trim().length === 0;
      const alphabeticRegex = /^[a-zA-Z\s]*$/;
      const containsOnlyAlphabetic = alphabeticRegex.test(value);
      if (!containsOnlyAlphabetic || containsOnlySpaces) {
        return { alphabeticOnly: true };
      }
      return null;
    };
  }
  async createLocation() {
    const UUID = uuid4();
    const locationEntity = this.mapLocation(this.dataSource.value, UUID);
    const response = await this.locationsService.getAllLocations();
    console.log("Response:", response);
    if (response.success && Array.isArray(response.data)) {
      const existingLocations = response.data;
      const duplicateLocation = existingLocations.find((location: { name: string; }) => location.name === locationEntity.name);
      if (duplicateLocation) {
        return this.showMessage('Location with the same name already exists.', SwalMessageTypes.Warning);
      }
    } else {
      console.error('Error: Invalid response format.', response);
      this.showMessage('Failed to fetch existing locations. Please try again later.', SwalMessageTypes.Error);
    }
    try {
      if (this.validLocationData(locationEntity)) {
        const response = await this.locationsService.createLocation(locationEntity);
        this.loader.close();
        this.showMessage('Location Created successfully', SwalMessageTypes.Success);
        this.router.navigateByUrl('/dashboard/logistics/location-list');
      }
    } catch (error) {
      console.error('Error occurred while creating location:', error);
      this.showMessage('Failed to create location. Please try again later.', SwalMessageTypes.Error);
    }
  }


  private validLocationData(locationEntity: LocationEntity): boolean {


    if (locationEntity.name && containsOnlyAlphanumeric(locationEntity.name)) {

      this.showMessage('Please enter valid name .', SwalMessageTypes.Warning);

      return false;
    }
    if (!locationEntity.warehouseName || containsOnlyAlphanumeric(locationEntity.warehouseName)) {

      this.showMessage('Please enter valid warehouse name .', SwalMessageTypes.Warning);

      return false;
    }
    if (!locationEntity.area || containsOnlyAlphanumeric(locationEntity.area)) {

      this.showMessage('Please enter valid area .', SwalMessageTypes.Warning);

      return false;
    }
    if (!locationEntity.state || containsOnlyAlphanumeric(locationEntity.state)) {

      this.showMessage('Please enter valid state .', SwalMessageTypes.Warning);

      return false;
    }
    if (!locationEntity.pincode || !validatePinCode(+locationEntity.pincode)) {

      this.showMessage('Please enter valid pincode .', SwalMessageTypes.Warning);

      return false;
    }
    if (!locationEntity.city || containsOnlyAlphanumeric(locationEntity.city)) {

      this.showMessage('Please enter valid city .', SwalMessageTypes.Warning);

      return false;
    }
    if (!locationEntity.country || containsOnlyAlphanumeric(locationEntity.country)) {

      this.showMessage('Please enter valid country .', SwalMessageTypes.Warning);

      return false;
    }
    if (!locationEntity.latitude || +locationEntity.latitude < 0) {

      this.showMessage('Please enter valid latitude .', SwalMessageTypes.Warning);

      return false;
    }
    if (!locationEntity.longitude || +locationEntity.longitude < 0) {

      this.showMessage('Please enter valid longitude .', SwalMessageTypes.Warning);

      return false;
    }

    return true;

  }
}
