@if(hasEdit){
<ng-container>
  <div class="table-block">
    <form [formGroup]="dataSource">
      <section id="content">
        <div class="card">
          <div class="card-body card-padding">
            <div class="row">
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Customer Id</mat-label>
                  <input [readonly]="true"  matInput type="string" formControlName="customerId" name="customerId"
                    placeholder="Customer id" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Name</mat-label>
                  <input matInput type="string" formControlName="name" name="name" placeholder="Name" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Email</mat-label>
                  <input matInput type="string" formControlName="email" name="email" placeholder="Email" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Status</mat-label>
                  <mat-select formControlName="status" [(value)]="dataSource.value.status">
                    <mat-option *ngFor="let type of supplierStatus" [value]="type">
                      {{ type === 0 ? "InActive" : "Active" }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Registered Date</mat-label>
                  <input [readonly]="true"  matInput type="string" formControlName="createdDate" name="createdDate"
                    placeholder="Registered Date" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Mobile Number</mat-label>
                  <input [readonly]="true"  matInput type="string" formControlName="mobileNumber" name="mobileNumber"
                    placeholder="Mobile Number" />
                </mat-form-field>
              </div>

              <!----   <div class="col-md-6">
          <mat-form-field class="full-width">
            <mat-label>Source</mat-label>
            <input matInput type="string" formControlName="source" name="source" placeholder="Source"/>
          </mat-form-field>
        </div>---->
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Business Name</mat-label>
                  <input matInput type="string" formControlName="businessName" name="businessName"
                    placeholder="Business Name" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Business Type</mat-label>
                  <mat-select formControlName="businessTypeId" placeholder="Business Type">
                    <mat-option value="">--Select--</mat-option>
                    <mat-option *ngFor="let key of businessTypeKeys()" [value]="key">{{ businessTypeNames[key]
                      }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <!-- @if (dataSource.value.userTypeId != 7) { -->
              <div class="col-md-6" *ngIf="dataSource.value.userTypeId === 1">
                <mat-form-field class="full-width">
                  <mat-label>Salesperson Name</mat-label>
                  <input matInput type="string" formControlName="salespersonName" name="salespersonName"
                    placeholder="Salesperson Name" [readonly]="true"/>
                </mat-form-field>
              </div>
              <div class="col-md-6" *ngIf="dataSource.value.userTypeId === 1">
                <mat-form-field class="full-width">
                  <mat-label>Salesperson Mobile Number</mat-label>
                  <input matInput type="string" formControlName="salespersonMobileNumber" name="salespersonMobileNumber"
                    placeholder="Salesperson Mobile Number" [readonly]="true"/>
                </mat-form-field>
              </div>
            <!-- } -->
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Business Status Tag</mat-label>
                  <mat-select formControlName="businessTagStatus" placeholder="Business Tag Status">
                    <mat-option *ngFor="let key of getCustomerStatusKeys()" [value]="key">
                      {{ getCustomerStatusNames()[key] }}
                    </mat-option>
                  </mat-select>
                  <!-- <input
                        matInput
                        type="string"
                        formControlName="businessTagStatus"
                        name="BusinessStatusTag"
                        placeholder="Business Status Tag"
                      /> -->
                </mat-form-field>
              </div>

              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>GST Number</mat-label>
                  <input matInput type="string" formControlName="gstNumber" name="gstNumber" placeholder="GstNumber" />
                </mat-form-field>
              </div>



              <div class="col-md-6">
                <div class="row">
                  <mat-label>Upload Gst Certificate Url</mat-label>
                </div>
                <div class="row">
                  <div class="imgSupplier imgpop-up" *ngIf="dataSource.value.gstCertificateUrl">
                    <img [src]="dataSource.value.gstCertificateUrl" alt="Gst Certificate Url image"
                      (click)="openPopUp(dataSource.value.gstCertificateUrl)" />
                  </div>
                  <div class="imageupload">
                    <input formControlName="gstCertificateUrl" class="form-control"
                      (change)="onFileChange($event, 'gstCertificateUrl')" type="file" />
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="row">
                  <mat-label>Upload Photo Id Url</mat-label>
                </div>
                <div class="row">
                  <div class="imgSupplier" *ngIf="dataSource.value.photoIdUrl">
                    <img [src]="dataSource.value.photoIdUrl" class="imgSupplier imgpop-up" alt="Photo Id Url image"
                      (click)="openPopUp(dataSource.value.photoIdUrl)" />
                  </div>
                  <div class="imageupload">
                    <input formControlName="photoIdUrl" class="form-control"
                      (change)="onFileChange($event, 'photoIdUrl')" type="file" />
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="row">
                  <mat-label>Upload Shop Url</mat-label>
                </div>

                <div class="row">
                  <div class="imgSupplier" *ngIf="dataSource.value.shopUrl">
                    <img [src]="dataSource.value.shopUrl" class="imgSupplier imgpop-up" alt="Shop Url image"
                      (click)="openPopUp(dataSource.value.shopUrl)" />
                  </div>
                  <div class="imageupload">
                    <input formControlName="shopUrl" class="form-control" (change)="onFileChange($event, 'shopUrl')"
                      type="file" />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <mat-label>Upload Registration Document Url</mat-label>
                </div>

                <div class="row">
                  <div class="imgSupplier" *ngIf="dataSource.value.registrationDocumentUrl">
                    <img [src]="dataSource.value.registrationDocumentUrl" class="imgSupplier imgpop-up"
                      alt="Registration Document Url image" (click)="
                            openPopUp(dataSource.value.registrationDocumentUrl)
                          " />
                  </div>
                  <div class="imageupload">
                    <input formControlName="registrationDocumentUrl" class="form-control"
                      (change)="onFileChange($event, 'registrationDocumentUrl')" type="file" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="buttonSection">
                  <!-- @if(permission?.edit){ -->
                  <button mat-raised-button color="primary" class="text-center ml" (click)="updateCustomer()">
                    Update
                  </button>
                  <!-- } -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  </div>
</ng-container>
} @else {
<ng-container>
  <p class="text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
}
