<ng-container>
  <div class="p-3">


    <div class="row">
      <div class="col-md-6">
        <h2 class="card-title m-3 text-violet">Search</h2>
      </div>
      <div class="col-md-6">
        <mat-icon (click)="dialogRef.close()" class="float-right m-3 closeicon">
          close
        </mat-icon>
      </div>
    </div>

    <div class="mat-elevation-z8">
      <form [formGroup]="dataSource" class="m-3 formcss">
        <div class="table-container p-2">
          <div class="row filters">
            <div class="row">

              <div class="col-md-12">
                <div class="form-group">
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput formControlName="skuTerm" name="skuTerm" placeholder="SKU Name">
                  </mat-form-field>
                </div>
              </div>
              <!-- <div class="col-md-6">
                <mat-form-field class="full-width">
                  <input matInput name="companyId" placeholder="Company Name" (input)="onInputChange($event)">
                </mat-form-field>
                <mat-select multiple formControlName="company" (selectionChange)="getSelectedCompanies()">
                  <mat-option *ngFor="let selectCompany of companies" [value]="selectCompany">
                    {{ selectCompany.name }}
                  </mat-option>
                </mat-select>
              </div> -->
              <div class="col-md-12">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Status</mat-label>
                  <mat-select formControlName="status" (selectionChange)="getStatus()" multiple>
                    <mat-option *ngFor="let skuStatus of statuses" [value]="skuStatus.value">
                      {{ skuStatus.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>


            </div>
            <div class="col-md-12 custom-flex">
              <div class="row">
                <label class="errorMessage" *ngIf="isAllFieldsEmpty">Search with any One Field.</label>
                <label class="errorMessage" *ngIf="isSearchWithMultiFields">Search with Either SKU Name or Company
                  Names.</label>
              </div>
              <div class="text-center mt-100 flex-item ml-10 container">
                <button mat-raised-button color="primary" (click)="search()" class="text-center ml">
                  Search
                </button>
              </div>
              <!-- <div class="text-center mt-2 flex-item">
              </div> -->
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>