<!-- <div class="container"> -->
@if(hasView){
<ng-container class="container">
  <div class="heading-block">
    <div class="row">
      <div class="col-md-6">
        <h2 class="card-title m-3">Trip List</h2>
      </div>
      <div class="col-md-6">
        @if(hasExport){
        <!-- <button
          mat-raised-button
          color="primary"
          class="topButtonSectionbtn btn-width"
          [matMenuTriggerFor]="generateOptionsMenu"
        >
          Generate
          <mat-icon>arrow_drop_down</mat-icon>
        </button> -->
        <button mat-raised-button *ngIf="hasExport" color="primary" class="topButtonSectionbtn btn-width"
          [matMenuTriggerFor]="downloadOptionsMenu">
          Download
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
        }
      </div>
    </div>
  </div>

  <!-- <mat-menu #generateOptionsMenu="matMenu" class="btn-width">
    <button
      mat-menu-item
      *ngFor="let option of generateOptions"
      (click)="onGenerateOptionClick(option)"
    >
      {{ option }}
    </button>
  </mat-menu> -->

  <mat-menu #downloadOptionsMenu="matMenu" class="btn-width">
    <button mat-menu-item *ngFor="let option of downloadOptions" (click)="onDownloadOptionClick(option)">
      {{ option }}
    </button>
  </mat-menu>

  <div class="search-block">
    <form [formGroup]="form">
      <section id="content">
        <div class="card">
          <div class="card-body card-padding">
            <div class="row">
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <input matInput formControlName="startDate" placeholder="Start Date" [matDatepicker]="appDatepicker"
                    [readonly]="true" />

                  <mat-datepicker-toggle matSuffix [for]="appDatepicker"></mat-datepicker-toggle>
                </mat-form-field>

                <mat-datepicker #appDatepicker></mat-datepicker>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <input matInput formControlName="endDate" placeholder="End Date" [matDatepicker]="appDatepickerEnd"
                    [readonly]="true" />

                  <mat-datepicker-toggle matSuffix [for]="appDatepickerEnd"></mat-datepicker-toggle>
                </mat-form-field>

                <mat-datepicker #appDatepickerEnd></mat-datepicker>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Trip Name</mat-label>
                  <input matInput type="string" formControlName="tripName" name="tripName" placeholder="Trip Name" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>DE Number</mat-label>
                  <input matInput type="string" formControlName="deNumber" name="deNumber" placeholder="DE Number"
                    [maxLength]="10" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Status</mat-label>
                  <mat-select formControlName="status">
                    <mat-option *ngFor="let status of tripStatus" [value]="status">
                      {{ getStatusName(status) }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="row">
                <div class="col-md-6 mt-2 d-flex justify-content-end">
                  <button mat-raised-button color="primary" class="ml" (click)="searchTasks()">
                    Search
                  </button>
                </div>
                <div class="col-md-6 mt-2">
                  <button mat-raised-button color="primary" class="ml-10" (click)="clearFilters()">
                    Clear Filters
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  </div>

  <div class="table-block">
    <div class="m-3 flex flex-col md:flex-row justify-between">
      <mat-card class="p-0">
        <mat-card-content class="p-0">
          <mat-sidenav-container fxFlex="0 1 auto">
            <mat-sidenav-content>
              <div class="table-container">
                <mat-table [dataSource]="dataSource" matSort>


                  <ng-container matColumnDef="createdDate">
                    <mat-header-cell *matHeaderCellDef>Trip Created Date </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="createdDate">
                      {{ row.createdDate}}
                    </mat-cell>
                  </ng-container>


                  <ng-container matColumnDef="date">
                    <mat-header-cell *matHeaderCellDef class="header-center"> Date </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="date">
                      {{ row.date}}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="tripNumber">
                    <mat-header-cell *matHeaderCellDef>
                      Trip Number
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="tripNumber">
                      {{ row.tripNumber }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="tripName">
                    <mat-header-cell *matHeaderCellDef>
                      Trip Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="tripName">
                      {{ row.tripName }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="sourceName">
                    <mat-header-cell *matHeaderCellDef>
                      Trip Source
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="sourceName">
                      {{ row.sourceName }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="hops">
                    <mat-header-cell *matHeaderCellDef> Hops </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="hops" class="cell-right">
                      {{ row.hops }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="weight">
                    <mat-header-cell *matHeaderCellDef>
                      Weight (kg's)
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="weight">
                      {{ row.weight }} Kg's
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="deName">
                    <mat-header-cell *matHeaderCellDef>
                      DE Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="deName">
                      {{ row.deName }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="deNumber">
                    <mat-header-cell *matHeaderCellDef>
                      DE Number
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="deNumber">
                      {{ row.deNumber }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="kms">
                    <mat-header-cell *matHeaderCellDef> KMS </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="kms">
                      {{ row.kms || 0 }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef>
                      Status
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="status">
                      {{ getPlanningStatusText(row.status) }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="startTime">
                    <mat-header-cell *matHeaderCellDef>
                      Start Time
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="startTime">
                      {{ row.startTime }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="endTime">
                    <mat-header-cell *matHeaderCellDef>
                      End Time
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="endTime">
                      {{ row.endTime }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="view">
                    <mat-header-cell *matHeaderCellDef class="mat-column-actions">
                      View
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="mat-column-actions">
                      <div class="d-flex">
                        @if(hasView){
                        <button mat-icon-button (click)="editTrip(row)">
                          <mat-icon>visibility</mat-icon>
                        </button>
                        }
                      </div>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                </mat-table>
              </div>
            </mat-sidenav-content>
          </mat-sidenav-container>
          <mat-paginator [length]="totalTrips" [pageSize]="pageSize" [pageSizeOptions]=pageSizeOptions
            (page)="onPageChange($event)"></mat-paginator>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</ng-container>
} @else {
<ng-container>
  <p class="text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
}
<!-- </div> -->
