export const DB_PATH = {
  CUSTOMERS: 'Customers',
  PROMOTIONS: 'Promotions',
  VIWITO_MERCHANTS: 'ViwitoMerchants',
  MASTER_POLYGON: 'MasterPolygon',
  MERCHANT: 'Merchant',
  COUPONS: 'Coupons',
  GLOBAL_COUPONS: 'GlobalCoupons',
  DEALER_COUPONS: 'DealerCoupons',
  CUSTOMER_SPECIAL_COUPONS: 'CustomerCoupons',
  B2B_AREA_COORDINATES: 'b2bAreaCoordinates',
  APP_SETTINGS: 'AppSettings',
  CUSTOMER: 'Customer',
  DEALER: 'Dealer',
  USER_ACTIVITIES: 'UserActivities',
  MESSAGE_HANDLER_FOR_CLOUD_FUNCTIONS: 'MessageHandlerForCloudFunctions',
  LOGISTIC_CLUSTERS: 'LogisticClusters',
  BULK_IMPORT: 'BulkImport',
  SALES_PERSON: 'SalesPerson',
  BMS_HM_Queue_Log: 'BMSHMQueueLog',
  DEEP_LINKS: 'DeepLinks',
};
