import { SupplierEntity, SupplierListSearch } from '../../models/logisticsupplier-model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SuppliersService } from '../../services/logisticsupplier.service';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ContractMode, LookUpType, SupplierStatus, SupplierType, SwalMessageTypes } from '../../../../enums/enums';
import Swal from 'sweetalert2';
import { AppLoaderService } from '../../../../shared/app-loader/app-loader.service';
import { AuthService } from '../../../../services/auth.service';
import { BaseListComponent } from '../../../../shared/core/base.list.component';
import { PageId } from '../../../../constants/enums';


@Component({
  selector: 'app-supplier-list',
  standalone: true,
  imports: [MatCardModule, MatButtonModule, MatTableModule, RouterLink, MatInputModule, MatFormFieldModule, ReactiveFormsModule,
    MatSelectModule, MatIconModule, CommonModule, FormsModule, MatDatepickerModule, MatRadioModule, MatNativeDateModule, MatPaginatorModule, MatSidenavModule],
  templateUrl: './supplier-list.component.html',
  styleUrl: './supplier-list.component.scss',
})
export class SupplierListComponent extends BaseListComponent {
  readonly DEFAULT_PAGE_SIZE = 25;
  //pageNumber: number = 1;
  // pageSize = this.DEFAULT_PAGE_SIZE;
  dataSource!: MatTableDataSource<SupplierEntity>;
  supplierServiceObservable: any;
  suppliers: SupplierEntity[] = [];
  totalSuppliers: number = 0;
  form: FormGroup;
  //  vendorId: any;
  supplierStatus: any = Object.values(SupplierStatus).filter(value => typeof value === 'number');
  designationTypes = Object.values(SupplierType).filter(value => typeof value === 'number');
  supplierListSearchInput: SupplierListSearch = this.initializeSearchInput();
  dataLength: number = 0;
  displayedColumns: string[] = ["name", "mobileNumber", "supplierType",
    "contractMode", "status",
    "edit"
    // "delete"
  ];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  supplierTypes: SupplierType[] = Object.values(SupplierType)
    .filter(value => typeof value === 'number') as SupplierType[];

  supplierTypeNames: { [key: number]: string } = {
    [SupplierType.Manpower]: 'Manpower',
    [SupplierType.Transporter]: 'Transporter',
    [SupplierType.Procurement]: 'Procurement'
  };

  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    private supplierService: SuppliersService,
    private loader: AppLoaderService,) {
    super(auth, router, route, PageId.seller_suppliers);
    this.form = this.fb.group({
      supplierName: "",
      type: null,
      status: null
    });
  }

  override async ngOnInit() {
    await super.ngOnInit();
    await this.getSuppliers();

  }

  private async getSuppliers() {
    try {
      this.loader.open();
      this.supplierListSearchInput.vendorId = this.vendorId;
      const response = await this.supplierService.getSuppliers(this.supplierListSearchInput);
      console.log("suppliers are", response);

      if (response && response['supplierList'] && response['supplierList'].length > 0) {
        this.dataSource = response['supplierList'];
        this.suppliers = response.supplierList;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.totalSuppliers = response.totalSuppliers;
        this.loader.close();

      }
      else {
        this.totalSuppliers = 0;
        // this.dataSource.paginator = this.paginator;
        this.dataSource = response[''];
        this.loader.close();
        Swal.fire({
          icon: 'info',
          text: 'No suppliers found.'
        });
      }
    } catch (error) {
      this.loader.close();
      this.showMessage('Failed to Fetch Suppliers. Please try again later.', SwalMessageTypes.Error);
    }
  }

  editSupplier(supplier: any) {
    this.router.navigateByUrl(`/dashboard/supplier/edit-supplier/${supplier.id}`)
  }

  async deleteSupplier(row: any) {
    try {
      const result = await Swal.fire({
        title: 'Do you want to Delete Supplier?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      });

      if (!result.isConfirmed) {
        return;
      }
      await this.supplierService.deleteSupplier(row.id);
      await this.clearFilters();
      this.showMessage('supplier deleted sucessfully', SwalMessageTypes.Success);
    } catch (error) {
      console.error('Error occurred while deleting supplier:');
      this.showMessage('Failed to Delete Supplier. Please try again later.', SwalMessageTypes.Error);
    }
  }

  override async onPageChange(event: any) {
    await super.onPageChange(event);
    const { supplierName, type, status } = this.form.value;
    this.supplierListSearchInput = this.createSupplierListSearchInput(supplierName, type, status)
    await this.getSuppliers();
  }

  async searchTasks() {
    const { supplierName, type, status } = this.form.value;
    this.pageNumber = this.pageNumber;
    this.pageSize = this.pageSize
    if (supplierName || type || status !== undefined) {
      this.supplierListSearchInput = this.createSupplierListSearchInput(supplierName, type, status);
      await this.getSuppliers();
    }

  }

  async clearFilters() {
    this.searchForm();
    this.supplierListSearchInput = this.initializeSearchInput();
    await this.getSuppliers();
  }

  getSupplierStatusText(value: number): string {
    switch (value) {
      case (SupplierStatus.Active):
        return 'Active';
      case SupplierStatus.InActive:
        return 'InActive';
      default:
        return 'Unknown Type';
    }
  }

  getSupplierContractModeText(value: number): string {
    switch (value) {
      case (ContractMode.Adhoc):
        return 'Adhoc';
      case ContractMode.Fixed:
        return 'Fixed';
      default:
        return 'Unknown Type';
    }
  }
  getSupplierTypeModeText(value: number): string {
    switch (value) {
      case (SupplierType.Manpower):
        return 'Manpower';
      case SupplierType.Transporter:
        return 'Transporter';
      case SupplierType.Procurement:
        return 'Procurement';
      default:
        return 'Unknown Type';
    }
  }

  // private functions

  private initializeSearchInput(): SupplierListSearch {
    return {
      searchText: "",
      vendorId: "",
      type: null,
      status: null,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize
    };
  }

  private createSupplierListSearchInput(supplierName: string, type: any, status: any): SupplierListSearch {
    return {
      searchText: supplierName || "",
      vendorId: this.vendorId || "",
      type: type || null,
      status: status == 0 ? 0 : !status ? null : status == 1 ? 1 : null,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize
    };
  }
  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }
  private searchForm() {
    this.form = this.fb.group({
      supplierName: "",
      type: null,
      status: null
    });
  }
  getStatusName(status: number): string {
    return SupplierStatus[status];
  }


}
