import { Component, Input, OnInit } from '@angular/core';
import { CategoryService } from '../../../../pages/category/services/category.service';
import { StatusComponent } from '../../../status/status.component';
import { TypePipe } from '../../../pipes/type.pipe';

@Component({
  selector: 'app-add-variant-type',
  standalone: true,
  imports: [StatusComponent, TypePipe],
  templateUrl: './add-variant-type.component.html',
  styleUrl: './add-variant-type.component.scss'
})
export class AddVariantTypeComponent implements OnInit {
  @Input() data: any;
  categoryName: any;
  constructor(public categoryService: CategoryService) { }
  ngOnInit() {
    if (this.data?.payload?.categories_id) {
      this.getVarient();
    }
  }

  async getVarient() {
    try {
      const id = this.data?.payload?.categories_id;
      let data: any = await this.categoryService.getSingle(id);
      this.categoryName = data.data[0]?.attributes?.browser_path;
    }
    catch (err: any) {

    }
  }
}
