import { date } from '@rxweb/reactive-form-validators';
import { Component, ViewChild } from '@angular/core';
import {
  SalesPersonPerformance,
  TargetsListSearch,
} from '../../models/targets.model';
import { TargetsService } from '../../services/targets.service';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSidenavModule } from '@angular/material/sidenav';

import Swal from 'sweetalert2';
import { AppLoaderService } from '../../../../shared/app-loader/app-loader.service';
import { AuthService } from '../../../../services/auth.service';
import { SwalMessageTypes, SupplierStatus } from '../../../../enums/enums';
import { getDateYYYYMMDD } from '../../../../utlity/utility';
import * as XLSX from 'xlsx';
import { BaseListComponent } from '../../../../shared/core/base.list.component';
import { PageId } from '../../../../constants/enums';

@Component({
  selector: 'app-targets-list',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    RouterLink,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSidenavModule,
  ],
  templateUrl: './targets-list.component.html',
  styleUrls: ['./targets-list.component.scss'],
})
export class TargetsListComponent extends BaseListComponent {
  singularTypes = Object.values(SupplierStatus).filter(
    (value) => typeof value === 'number'
  );

  targets: any;
  totalTargets: number = 0;
  form: FormGroup;
  targetsListSearchInput: TargetsListSearch = this.initializeSearchInput();
  selectedDate: string = '';
  salesPersonList: any[] = [];
  selectedSalesPersonIds: string[] = [];
  isDataFound: boolean = false;
  displayedColumns = [
    'baName',
    'area',
    'tlName',
    'tasksCountTarget',
    'buyingCountTarget',
    'nonBuyingCountTarget',
    'dayRevenueTarget',
    'dayTaskRevenueTargetPercentage',
    'dayTasksRevenueTarget',
    'edit',
  ];

  dataSource!: MatTableDataSource<SalesPersonPerformance[]>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    public readonly targetsService: TargetsService,
    route: ActivatedRoute,
    router: Router,
    private loader: AppLoaderService,
    auth: AuthService,
    private fb: FormBuilder
  ) {

    super(auth, router, route, PageId.bms_targets);
    this.form = this.fb.group({
      date: [new Date(), [Validators.required]],
      salespersonId: null,
    });
  }

  override async ngOnInit() {
    await super.ngOnInit();
    this.selectedDate = getDateYYYYMMDD(new Date());
    this.salesPersonList = await this.targetsService.getSalesPersons();
    this.getTargets(false);
  }

  async getTargets(clearFilter: boolean) {
    try {
      this.loader.open();

      this.targetsListSearchInput = this.createTargetsListSearchInput(
        this.selectedDate
      );

      const response = await this.targetsService.getTargets(
        this.targetsListSearchInput
      );
      if (response.length > 0) {
        this.isDataFound = true;
        const res = this.mapSalesPersonInfo(response);
        this.targets = res;
        this.dataSource = new MatTableDataSource<any>(res);
        this.totalTargets = response.length;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } else {
        if (!clearFilter) {
          this.isDataFound = false;
          this.loader.close();
          Swal.fire('Warning!', 'No Targets Found', 'warning');
        }
      }
    } catch (error) {
      this.loader.close();
      this.showMessage(
        'Failed to Fetch Targets. Please try again later.',
        SwalMessageTypes.Error
      );
    } finally {
      this.loader.close();
    }
  }

  private mapSalesPersonInfo(response: SalesPersonPerformance[]) {
    const updatedRes = response.map((resItem) => {
      const salesPersonId = resItem.salesPersonId;

      const matchedSalesperson = this.salesPersonList.find(
        (person) => person.id === salesPersonId
      );

      if (matchedSalesperson) {
        return {
          ...resItem,
          baName: matchedSalesperson.name,
          area: matchedSalesperson.areaName,
          tlName: matchedSalesperson.salesLeadName,
        };
      } else {
        return { ...resItem, baName: '', area: '', tlName: '' };
      }
    });

    return updatedRes.filter((item) => Object.keys(item).length > 0);
  }

  searchTasks() {
    if (this.form.invalid) {
      this.showMessage('Please Select Date.', SwalMessageTypes.Warning);

      return;
    }
    this.selectedDate = getDateYYYYMMDD(new Date(this.form.value.date));
    this.getTargets(false);
  }

  getSelectedSalesPerson() {
    this.selectedSalesPersonIds = this.form.get('salespersonId')?.value || [];
    //this.selectedSalesPersonIds = this.salesPersonList.filter((salesperson) => salespersonIds.includes(salesperson.id));
  }

  editTargets(row: any) {

    if (this.selectedDate != getDateYYYYMMDD(new Date())) {
      this.showMessage('You cant edit previous day targets', SwalMessageTypes.Warning);

      return;
    }

    const id = row.salesPersonId;
    this.router.navigateByUrl(
      `/dashboard/targets/edit-targets/${id}/${this.selectedDate}`
    );
  }

  private initializeSearchInput(): TargetsListSearch {
    return {
      salespersonIds: [],
      goalType: 'target',
      startDate: '',
      endDate: '',
    };
  }

  private createTargetsListSearchInput(date: string): TargetsListSearch {
    return {
      salespersonIds: this.selectedSalesPersonIds,
      goalType: 'filterDates',
      startDate: date,
      endDate: date,
    };
  }

  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  async clearFilters() {
    this.targetsListSearchInput = this.initializeSearchInput();
    this.selectedSalesPersonIds = [];
    this.searchForm()
    this.selectedDate = getDateYYYYMMDD(new Date());
    this.getTargets(true);
  }


  private searchForm() {
    this.form = this.fb.group({
      date: [new Date(), [Validators.required]],
      salespersonId: null,
    });
  }

  async updateTargets() {
    try {
      const salesPersons = this.salesPersonList.filter(s => s.isActive == true);
      this.loader.open();
      let salesPersonsTasks = [];
      const date = getDateYYYYMMDD(new Date());//'2024-07-03'
      for (const salesperson of salesPersons) {
        const count = await this.targetsService.getActiveSalesPerson(salesperson.id, date);
        salesPersonsTasks.push({
          salespersonId: salesperson.id,
          taskCount: count,
          name: salesperson.name
        })
      }

      const payload = {
        performanceDate: date,
        salespersons: salesPersonsTasks
      }

      this.loader.close();
      const res = await this.targetsService.refreshTargets(payload);

      this.showMessage('Targets Refresh successfully', SwalMessageTypes.Success);
    } catch (error) {
      this.loader.close();
    }
  }

  private isSalesPersonResponseEmpty(data: any) {
    if (typeof data === 'undefined') {
      return true;
    }

    return Object.keys(data).every(key => data[key].length === 0);

  }

  generateExcel() {

    let isDataNotEmpty = !this.isSalesPersonResponseEmpty(this.targets);

    if (!isDataNotEmpty) {
      this.showMessage('There is no data to download', 'warning');
      return;
    }

    const data: any = {
      performance: this.targets.map((item: any) => ({
        "Ba Name": item.baName,
        "Area": item.area,
        "Tl Name": item.tlName,
        "Assigned Tasks": item.tasksCountTarget,
        "Buying Target": item.buyingCountTarget,
        "Non Buying Target": item.nonBuyingCountTarget,
        "Day Revenue Target": item.dayRevenueTarget,
        "Day Tasks Revenue Target": item.dayTasksRevenueTarget,
        "Task Revenue Target Percentage": item.dayTaskRevenueTargetPercentage,

      }))
    };


    const workbook = XLSX.utils.book_new();
    Object.keys(data).forEach((key) => {
      const sheet = XLSX.utils.json_to_sheet(data[key]);
      XLSX.utils.book_append_sheet(workbook, sheet, key);
    });
    XLSX.writeFile(workbook, 'Sales Person Performance Report.xlsx');
  }


}
