import { VendorSettingsComponent } from './vendor-settings/vendor-settings.component';
import { InfoComponent } from './info/info.component';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterOutlet } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { MarginComponent } from './margin/margin.component';
import { CompanyListComponent } from './margin/companies-list/companies-list.component';
import { CreateShopSettingComponent } from '../../shopsetting/components/create-shopsetting/create-shopsetting.component';
import { MatIconModule } from '@angular/material/icon';



@Component({
  selector: 'vendor-list',
  standalone: true,
  templateUrl: './vendor-list.component.html',
  styleUrl: './vendor-list.component.scss',
  imports: [
    RouterOutlet, MatTabsModule,
    InfoComponent,
    MarginComponent,
    CommonModule,
    VendorSettingsComponent,
    CreateShopSettingComponent,
    CompanyListComponent,
    MatIconModule,
    RouterLink
  ]
})
export class VendorListComponent {
  ngOnInit(): void { }

}
