import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { RouterOutlet, RouterLink } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-filter-popup',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatButtonModule,
    RouterOutlet,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatCardModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    CommonModule,
    RouterLink,
    ReactiveFormsModule,
    MatIconModule,
  ],
  templateUrl: './marginEdit.component.html',
  styleUrl: './marginEdit.component.scss',
})
export class MarginEditComponent {
  dataSource: any;
  constructor(
    public dialogRef: MatDialogRef<MarginEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: FormBuilder
  ) {
    this.dataSource = this.fb.group({
      margin: [this.data.updateMarginInput, Validators.required],
    });
  }
  updateMargin() {
    if(this.dataSource.value.margin==null){
      Swal.fire('', 'Please Enter Value', 'warning');

      return
    }

    this.dialogRef.close({ updatedMarginValue: this.dataSource.value.margin });
  }
}
