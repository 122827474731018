import { updateDoc } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { lastValueFrom, Observable } from 'rxjs';
import { DB_PATH } from '../../../constants/db-path';
import { FirebaseDbService } from '../../../services/firebase-db.service';

@Injectable({
  providedIn: 'root',
})
export class SettingService {
  constructor(
    public http: HttpClient,
    private firebaseDbService: FirebaseDbService
  ) { }

  async getMerchant(search: string) {
    return lastValueFrom(
      this.http.get(
        `${environment.coreServiceUrl}/api/Vendor/search_vendor/${search}`
      )
    );
  }

  async updateSkuMargin(data: any) {
    // /api/pricing_list/bulk
    return await lastValueFrom(
      this.http.put(`${environment.pricingApiUrl}/pricing_list/margin/bulk`, [data])
    );
  }

  async exportMarginProductsByFilters(vendorId: string) {
    const observable: Observable<Blob> = this.http.get(
      `${environment.backendUrl}/download_pricing_list/${vendorId}`,
      { responseType: 'blob' }
    );

    try {
      const result = await lastValueFrom(observable);
      return result;
    } catch (error) {
      console.error('Error fetching orders:', error);
      throw error; // You can rethrow the error or handle it as needed
    }
  }
}
