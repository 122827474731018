import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { AdminConfigEntity } from '../../../../enums/enums';

@Injectable({
  providedIn: 'root'
})
export class MarginsService {
  baseServiceUrl: string = `${environment.coreServiceUrl}`;
  cacheServiceApiUrl: string = `${environment.cacheServiceApiUrl}`;
  constructor(
    private readonly http: HttpClient) {
  }


  async getAdminSettingByVendorId(vendorId: string): Promise<any> {
    const res = await lastValueFrom(this.http.get<any>(`${this.baseServiceUrl}/api/vendor_settings/get_admin_config/${vendorId}`));
    return res.data;
  }

  async createAdminSetting(data: AdminConfigEntity) {
    let response = await lastValueFrom(this.http.post<any>(`${this.baseServiceUrl}/api/vendor_settings/create_admin_config`, data));
    return response
  }
  async createAdminSettingInCache(data: AdminConfigEntity) {
    let response = await lastValueFrom(this.http.put<any>(`${this.cacheServiceApiUrl}/api/vendor_settings/update/admin_config/${data.vendorId}`, data.adminConfig));
    return response
  }

}
