@if(hasView){
<ng-container>
  <div class="table-block">
    <div class="m-3 flex flex-col md:flex-row justify-between">
      <mat-card class="p-0">
        <mat-card-content class="p-0">
          <mat-sidenav-container fxFlex="0 1 auto">
            <mat-sidenav-content>
              <form [formGroup]="form">
                <div class="table-container">
                  <div class="row" style="padding: 12px">
                    <div class="col-md-8 mx-auto">
                      <div class="d-flex">
                        <div class="search-box">
                          <input type="text" class="search-input" placeholder="Search product by name"
                            formControlName="name" />
                          <mat-icon class="mb-0 search-icon">search</mat-icon>
                        </div>
                        <button type="button" class="btn btn-search ms-3" (click)="reset()">
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </mat-sidenav-content>
          </mat-sidenav-container>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="table-block">
    <div class="m-3 flex flex-col md:flex-row justify-between">
      <mat-card class="p-0">
        <mat-card-content class="p-0">
          <mat-sidenav-container fxFlex="0 1 auto">
            <mat-sidenav-content>
              <div class="table-container">
                <mat-table [dataSource]="items" matSort>
                  <ng-container matColumnDef="productName">
                    <mat-header-cell *matHeaderCellDef>
                      Product Name</mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="productName">
                      {{ row.itemName }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="sellerName">
                    <mat-header-cell *matHeaderCellDef>
                      Seller Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="sellerName">
                      {{ row.vendorBusinessName }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="cases">
                    <mat-header-cell *matHeaderCellDef> Cases </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="cases">
                      {{ row.cases }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="pcs">
                    <mat-header-cell *matHeaderCellDef> Pcs </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="pcs">
                      {{ row.pieces }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="totalQuantity">
                    <mat-header-cell *matHeaderCellDef>
                      Total Quantity
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="totalQuantity">
                      {{ row.quantity }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="invoicedQty">
                    <mat-header-cell *matHeaderCellDef>Invoiced Quantity
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="invoicedQty">
                      {{ row.invoicedQty }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="saleValue">
                    <mat-header-cell *matHeaderCellDef>
                      Sale Value
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="saleValue">
                      {{ row.saleValue  | replaceCommas }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="edit">
                    <mat-header-cell *matHeaderCellDef class="mat-column-actions">
                      Edit
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="mat-column-actions">
                      <div class="d-flex">
                        <!-- hasEdit && currentHop?.point_type == 3  row.vendorBusinessTypeId == 5-->
                        <!-- If Inventory vendor only Give Edit Option and if point type is HOP -->
                        @if((hasEdit && currentHop?.point_type == 3 && currentTrip?.status === 1) &&
                        (row.vendorBusinessTypeId == 5 || currentTrip?.source == 3))
                        {
                        <button mat-icon-button (click)="editProduct(row)">
                          <mat-icon>edit</mat-icon>
                        </button>
                        }
                      </div>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                </mat-table>
              </div>
            </mat-sidenav-content>
          </mat-sidenav-container>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</ng-container>
} @else {
<ng-container>
  <p class="text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
}
