<div class="d-flex justify-content-between">
  <h1 mat-dialog-title>{{data?.title}}</h1>
<!--   <button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button> -->
</div>
<div mat-dialog-content>
<p>{{data?.message}}</p>
</div>
<div mat-dialog-actions>
  <div class="d-flex justify-content-end w-100">
    <button mat-raised-button color="warn" (click)="onReject()">No</button>
    <button mat-raised-button color="primary" (click)="approve()">Yes</button>
  </div>
</div>