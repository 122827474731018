import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { VarientTypeService } from '../services/varientType.service';
import { AuthService } from '../../../services/auth.service';
import { FormBuilder } from '@angular/forms';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { VarientTypeFilterDialogComponent } from '../varient-type-filter-dialog/varient-type-filter-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ProductService } from '../../product/services/product.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmDialogComponent } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.component';
import { CommonService } from '../../../services/common.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { MatSortHeader, MatSortModule } from '@angular/material/sort';
import Swal from 'sweetalert2';
import { SwalMessageTypes } from '../../../enums/enums';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { PageId } from '../../../constants/enums';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-varientType-list',
  standalone: true,
  imports: [CommonModule, MatSortModule, MatIconModule, MatTooltipModule, MatCardModule, MatButtonModule, MatTableModule, RouterLink, MatPaginatorModule, MatProgressSpinnerModule],
  templateUrl: './varientType-list.component.html',
  styleUrl: './varientType-list.component.scss',
})
export class VarientTypeListComponent extends BaseListComponent implements OnInit {
  dataSource: any = [];
  displayedColumns: string[] = ['attributes.name', 'attributes.parent_categories.name', 'attributes.group_name', 'attributes.value_type', 'action'];
  queryForm: any
  meta: any
  loading = true
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('fileInput') fileInput!: ElementRef;
  sorting: any = {
    "attributes.name": "name",
    "attributes.parent_categories.name": "categories_id",
  }
  constructor(router: Router,
    auth: AuthService, route: ActivatedRoute, public fb: FormBuilder,
    public dialog: MatDialog,
    public commonService: CommonService, public productService: ProductService,
    public varientTypeService: VarientTypeService,) {
    super(auth, router, route, PageId.bms_variant);
    this.queryForm = this.fb.group({
      name: [null],
      value_type: [null],
      group_name: [null],
      categories_id: [null],
      page: [1],
      limit: [5],
      sort_order: [],
      sort_by: []
    });
  }

  override async ngOnInit() {
    await super.ngOnInit();


    this.queryForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: any) => {
        this.navigateWithParams(value);
        this.getAllVarient();
      })
    if (this.route.snapshot.queryParams) {
      this.queryForm.patchValue(this.route.snapshot.queryParams);
    } else {
      this.getAllVarient();
    }
  }
  getAllVarient() {
    this.loading = true;
    this.dataSource = new MatTableDataSource([]);
    this.varientTypeService.getAll(this.queryForm.value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.meta = res.meta;
        this.loading = false;
        this.dataSource = new MatTableDataSource(res.data);
        this.goToCorrectPage();
      });
  }
  goToCorrectPage() {
    if (this.paginator) {
      this.paginator.pageIndex = this.queryForm.value.page - 1;
      // Trigger a manual page change event so that the MatPaginator updates accordingly
      // this.paginator._changePageSize(this.paginator.pageSize);
    }
  }
  async delete(id: any) {
    /*  const dialogRef = this.dialog.open(ConfirmDialogComponent, {
       data:  {title : 'Delete Confirmation', message:"Are you sure you want to delete?"},
       panelClass: 'custom-dialog-class'
     });
     dialogRef.afterClosed().subscribe(async result => {
       if(result == 'yes'){
     try {
       await this.varientTypeService.delete(id);
       this.commonService.showToaster("success",'Deleted Successfully')
       this.ngOnInit()
     } catch (err:any) {
       console.log(err);
       this.commonService.showToaster("error",err.error.message)
     }
   }
 }); */
    try {
      const result = await Swal.fire({
        title: 'Do you want to Delete Varient Type?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      });

      if (!result.isConfirmed) {
        return;
      }
      await this.varientTypeService.delete(id);
      this.showMessage(
        'Varient Type Deleted Successfully',
        SwalMessageTypes.Success
      );
    } catch (error: any) {
      this.showMessage(
        error.error.message,
        SwalMessageTypes.Error
      );
    }
  }
  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }
  onPaginationChange(event: any) {
    this.queryForm.patchValue({
      page: event.pageIndex + 1,
      limit: event.pageSize
    });
  }

  navigateWithParams(queryParams: any) {
    Object.keys(queryParams).forEach(key => {
      if (!queryParams[key] || queryParams[key] == 'null') {
        delete queryParams[key];
      }
    })
    // Navigate to the current route with the specified query parameters
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams
    });
  }
  openFilter() {
    const dialogRef = this.dialog.open(VarientTypeFilterDialogComponent, {
      data: this.queryForm.value,
      panelClass: 'custom-dialog-class'
    });

    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe$)).subscribe((result) => {
      if (result) {
        this.queryForm.patchValue(result);
        this.goToCorrectPage();
      }
    });
  }
  clearFileInput() {
    setTimeout(() => {
      this.fileInput.nativeElement.value = '';
    }, 3000);
  }
  upload(event: any) {
    let selectedFile = event.target.files[0] as File;
    const formData = new FormData();
    formData.append('csvFile', selectedFile);
    formData.append('type', 'variant_types');
    this.importConfirm(formData, selectedFile.name);
  }
  async importConfirm(formData: any, name: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { title: 'Confirmation', message: `Are you sure you want to upload ${name} file?` },
      panelClass: 'custom-dialog-class'
    });
    dialogRef.afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async result => {
        if (result == 'yes') {
          this.productService.uploadData(formData).pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
            this.queryForm.patchValue({
              page: 1,
              limit: 5
            })
            this.getAllVarient();
            this.commonService.showToaster("success", 'Uploaded Successfully')
          },
            (error) => {
              const dataKey = Object.keys(error.error.data)[0];
              const mergedMessage = `${error.error.message} ${dataKey} ${error.error.data[dataKey]}`;
              this.commonService.showToaster("error", mergedMessage);

            })
        }
      });
  }
  export() {
    this.commonService.showLoder();
    let query = {
      type: 'variant_types',
      name: this.queryForm.value.name,
      categories_id: this.queryForm.value.categories_id
    };
    this.productService.exportData(query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(res => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(res);
        link.download = `variant_types_${(new Date()).toISOString()}.csv`;
        link.click();
        this.commonService.hideLoder();
      }, (err: any) => {
        this.commonService.hideLoder()
        this.commonService.showToaster("error", err.error.message)
      })
  }
  downloadSample() {
    const excelUrl = 'assets/csv_samples/variant_types.csv';

    // Fetch the file
    fetch(excelUrl)
      .then(response => response.blob())
      .then(blob => {
        // Create a link element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'variant_types.csv'; // Set the file name here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(error => console.error('Error downloading Excel file:', error));

  }
  announceSortChange(event: any) {
    this.queryForm.patchValue({
      page: 1,
      limit: 5,
      sort_order: event.direction,
      sort_by: this.sorting[event.active]
    })
  }
  isDataAvailable(): boolean {
    return this.dataSource && this.dataSource?.data?.length > 0;
  }
}
