import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { Firestore, collection, collectionData } from '@angular/fire/firestore';
import { AuthService } from './services/auth.service';
import { CategorySelectorComponent } from './shared/category-selector/category-selector.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonService } from './services/common.service';
import { AppLoaderService } from './shared/app-loader/app-loader.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule, RouterOutlet, 
    CategorySelectorComponent, ReactiveFormsModule,
    HttpClientModule],
  providers: [AppLoaderService],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  firestore: Firestore = inject(Firestore);
  authService: AuthService = inject(AuthService);
  control = new FormControl();
  constructor(public commonService: CommonService) {

  }
}
