import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { ConfigService } from '../../../config/services/config.service';
import { PurchaseReceiptService } from '../../service/purchasereceipt.service';
import { CommonModule } from '@angular/common';
import {
  PurchaseOrderStatus,
  SwalMessageTypes,
  TaxDeductionType,
  UOM,
} from '../../../../enums/enums';
import { addItem } from '../../models/purchasereceipt-model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-po-product-edit',
  standalone: true,
  imports: [
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatCardModule,
    MatTableModule,
    RouterLink,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
  ],
  templateUrl: './po-product-edit.component.html',
  styleUrl: './po-product-edit.component.scss',
})
export class PoProductEditComponent {
  form: FormGroup;
  prId: string = '';
  rowId: string = '';
  skuDetails: any;
  isReadOnly: boolean = false;
  res: any;
  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private configService: ConfigService,
    private purchaseReceiptService: PurchaseReceiptService,
    public fb: FormBuilder
  ) {
    this.form = this.fb.group({
      skuName: '',
      buyinguom: '',
      upc: '',
      uomQty: null,
      requestedInvoiceAmount: null,
      pcs: null,
      invoiceAmount: null,
      taxableAmount: null,
      sgst: null,
      cgst: null,
      igst: null,
      cess: null,
      tcs_tds: null,
      totalBillValue: null,
      weight: null,
      mrp: null,
      hsn: null,
      itemTaxType: null,
      taxRate: null,
      cessRate: null,
      actualUomQty: null,
      actualPcs: null,
    });
  }
  Uom: { name: string; value: UOM }[] = [
    { name: 'Case', value: UOM.Case },
    { name: 'PCs', value: UOM.PCs },
    { name: 'Bag', value: UOM.Box },
  ];

  taxDeductionType: { name: string; value: TaxDeductionType }[] = [
    { name: 'Inclusive', value: TaxDeductionType.Inclusive },
    { name: 'Exclusive', value: TaxDeductionType.Exclusive },
    { name: 'None', value: TaxDeductionType.None },
  ];

  async ngOnInit() {
    this.route.params.subscribe(async (params) => {
      this.prId = params['id'];
      this.rowId = params['rowId'];
    });
    await this.loadData();
  }
  async loadData() {
    this.res = await this.purchaseReceiptService.getPurchaseReceiptById(
      this.prId
    );
    this.isReadOnly = this.checkIfReadOnly();
    this.getPrSku(this.rowId);
  }
  async getPrSku(id: string) {
    this.skuDetails = await this.purchaseReceiptService.getPrSkuDetails(id);
    console.log('sku details are', this.skuDetails);

    this.form = this.mapToSkuDetails(this.skuDetails);
  }

  mapToSkuDetails(response: any): FormGroup {
    return this.fb.group({
      skuName: new FormControl(response?.sku_name || ''),
      buyinguom: new FormControl(response?.uom_type || ''),
      upc: new FormControl(response?.upc || ''),
      uomQty: new FormControl(response?.uom_requested_qty || ''),
      purchaseRate: new FormControl(response?.rate_per_pc),
      pcs: new FormControl(response?.requested_in_pcs),
      invoiceAmount: new FormControl(response?.invoiced_amount),
      requestedInvoiceAmount: new FormControl(
        response?.requested_invoice_amount
      ),
      taxableAmount: new FormControl(response?.taxable_amount),
      sgst: new FormControl(response?.sgst_amount || 0),
      cgst: new FormControl(response?.cgst_amount || 0),
      igst: new FormControl(response?.igst_amount || 0),
      cess: new FormControl(response?.cess_amount || 0),
      tcs_tds: new FormControl(response?.tcs_amount || 0),
      actualUomQty: new FormControl(response?.uom_actual_qty || 0),
      actualPcs: new FormControl(response?.actual_in_pcs || 0),
      totalBillValue: new FormControl(response?.final_bill_value),
      weight: new FormControl(response?.weight),
      mrp: new FormControl(response?.mrp),
      itemTaxType: new FormControl(response?.tax_type),
      taxRate: new FormControl(response?.gst),
      cessRate: new FormControl(response?.cess),
      hsn: new FormControl(response?.hsn),
    });
  }

  goBack() {
    this.router.navigateByUrl(
      `/dashboard/pocreation/edit-purchase-order/${this.prId}`
    );
  }
  async calculatePcs(event: Event, fieldName: string): Promise<void> {
    console.log('calculatePcs::fieldName::', fieldName);

    const uomQty = this.form.get('uomQty')?.value;
    const upc = this.form.get('upc')?.value;
    const uom = this.form.get('buyinguom')?.value;
    let pcs: number = 0;

    if (fieldName === 'uomQty' || fieldName === 'upc') {
      if (fieldName === 'uomQty') {
        pcs = fieldName === 'uomQty' ? uomQty * upc : upc * uomQty;
      }

      if (uom == UOM.PCs) {
        this.form.patchValue({ pcs: uomQty });
      }
      if ([UOM.Box, UOM.Case].includes(uom)) {
        this.form.patchValue({ pcs });
      }
    }
  }

  resetUomQty() {
    const uomQty = this.form.get('uomQty')?.value;

    if (uomQty !== null) {
      console.log(uomQty);

      this.form.patchValue({ uomQty: 0 });
      this.form.patchValue({ pcs: 0 });
    }
  }
  async saveSku() {
    if (!this.validations()) {
      return;
    }
    const skuDetails = this.form.value;
    console.log('sku details', skuDetails);

    const skuUpdateDetails = this.mapToSku(skuDetails);

    const res = await this.purchaseReceiptService.updateSkuDetails(
      this.rowId,
      skuUpdateDetails
    );
    this.router.navigateByUrl(
      `/dashboard/pocreation/edit-purchase-order/${this.prId}`
    );
    console.log('update sku details are', skuDetails);
    console.log('res', res);
  }

  private validations(): boolean {
    const negativeFields = this.validateNegativeValues(this.form.value);

    if (negativeFields.length) {
      this.showMessage(
        `${negativeFields} ,have negative values Please Check`,
        SwalMessageTypes.Warning
      );

      return false;
    }
    if (this.hasDecimal(this.form.value.uomQty)) {
      this.showMessage(
        `UOM Qty should not have decimal value`,
        SwalMessageTypes.Warning
      );
      return false;
    }

    if (this.hasDecimal(this.form.value.upc)) {
      this.showMessage(
        `UPC should not have decimal value`,
        SwalMessageTypes.Warning
      );
      return false;
    }
    if (this.form.value.uomQty <= 0) {
      this.showMessage(
        `UOM Quantity should be greater than zero`,
        SwalMessageTypes.Warning
      );
      return false;
    }
    return true;
  }
  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  private validateNegativeValues(data: addItem): string[] {
    const numericInputs = [
      { name: 'MRP', value: data.mrp },
      { name: 'UPC', value: data.upc },
      { name: 'UOM Qty', value: data.uomQty },
      { name: 'Invoice Amount', value: data.invoiceAmount },
    ];

    const negativeFields = numericInputs
      .filter((input) => input.value < 0)
      .map((input) => input.name);

    return negativeFields;
  }
  hasDecimal(number: number) {
    return /\./.test(number.toString());
  }
  mapToSku(product: any) {
    return {
      id: this.rowId,
      receipt_id: this.prId,
      sku_name: product.skuName,
      upc: product.upc,
      uom_type: product.buyinguom,
      uom_requested_qty: product.uomQty,
      requested_in_pcs: product.pcs,
      mrp: product.mrp,
      tax_type: product.itemTaxType,
      gst: product.gst,
      cess: product.cess_amount,
      requested_invoice_amount: product.requestedInvoiceAmount,
      invoiced_amount: product.invoiceAmount,
      final_bill_value: product.totalBillValue,
      weight: product.selectedSku?.packing_section?.weight,
    };
  }
  checkIfReadOnly(): boolean {
    const status = this.res.status;
    console.log('status', status);
    switch (status) {
      case PurchaseOrderStatus.Created:
        return false;
      case PurchaseOrderStatus.RequestedForGRN:
        return true;
      case PurchaseOrderStatus.GRNCompleted:
      case PurchaseOrderStatus.RequestedForApproval:
      case PurchaseOrderStatus.Approved:
        return true;
      default:
        return false;
    }
  }
}
