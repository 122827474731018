import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import Swal from 'sweetalert2';
import { searchSkuVendorInput } from '../../../../../entities/pricing';

export interface DialogData {
  skuName: string;
  title: string;
  searchSkuVendorInput: searchSkuVendorInput;
}

@Component({
  selector: 'Margin',
  standalone: true,
  imports: [
    MatAutocompleteModule,
    MatDialogModule,
    MatFormFieldModule,
    MatButtonModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatCardModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    MatIconModule,
  ],

  templateUrl: './marginpopup.component.html',
  styleUrl: './marginpopup.component.scss',
})
export class MarginpopUpComponent {
  dataSource: any;

  constructor(
    public dialogRef: MatDialogRef<MarginpopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public fb: FormBuilder
  ) {
    this.dataSource = this.fb.group({
      // brandId:"",
      skuName:data?.skuName,
      // companyId:"",
      // categoryId:""
    })
  }

  ngOnInit() {}

  private hasSearchFields(): boolean {
    const { brandId, skuName, companyId, categoryId } = this.dataSource.value;

    return (
      // brandId.length > 0 ||
      skuName
      // companyId.length > 0 ||
      // categoryId.length > 0
    );
  }

  search() {
    if (!this.dataSource.get('skuName').value) {
      Swal.fire('', 'SKU Name is required', 'warning');

      return;
    }
    this.dialogRef.close(this.dataSource.value);
  }
  clearFilters(){
    this.dataSource = this.fb.group({
      // brandId:"",
      skuName:"",
      // companyId:"",
      // categoryId:""
    });
    this.dialogRef.close(this.dataSource.value);
  }
}
