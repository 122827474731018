@if(hasEdit){
  
<ng-container>
  <div class="row">
    <div class="col-md-6">
      <h2 class="card-title m-3 text-violet">Add Cluster</h2>
    </div>
    <div class="col-md-6 d-flex justify-content-end">
      <mat-icon (click)="dialogRef.close()" class="m-3 closeicon"
        >close</mat-icon
      >
    </div>
  </div>
  <div class="row">
    <form [formGroup]="dataSource" class="m-3">
      <section id="content">
        <div class="container">
          <mat-card>
            <mat-card-content>
              <div class="row">
                <div>
                  <div class="row">
                    <div class="col-md-6">
                      <mat-form-field class="full-width">
                        <mat-label>Location</mat-label>
                        <mat-select formControlName="location">
                          <mat-option
                            *ngFor="let item of location"
                            [value]="item"
                          >
                            {{ item.value }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-md-6">
                      <mat-form-field class="full-width">
                        <input
                          matInput
                          formControlName="name"
                          name="name"
                          placeholder="Cluster Name"
                        />
                      </mat-form-field>
                    </div>
                  </div>
                  <label class="errorMessage" *ngIf="isNameExists"
                    >The name already exists. Please choose a different
                    name.</label
                  >
                  <label class="errorMessage" *ngIf="isNameValid"
                    >Name is not Valid</label
                  >

                  <input
                    type="file"
                    accept=".geojson"
                    (change)="onSelectFile($event)"
                    class="m-10 choosefile"
                    id="fileInput"
                  />
                  @if(hasCreate){
                  <button
                    mat-raised-button
                    color="primary"
                    (click)="addNewArea()"
                  >
                    Save
                  </button>
                  }
                  <!-- <label class="errorMessage" *ngIf="isGeoJsonNotSingle"
                    >Upload Only one geo Polygon At a time.</label
                  > -->
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </section>
    </form>
  </div>
</ng-container>
} @else {
<ng-container>
  <p class="text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
} 
