import { Component, ViewChild, OnDestroy } from '@angular/core';
import {
  RouterOutlet,
  RouterLink,
  ActivatedRoute,
  Router,
} from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { AreaEditComponent } from '../area-edit/area-edit.component';
import { MatDialog } from '@angular/material/dialog';
import {
  IAreaDialogData,
  MasterCluster,
} from '../../master-geo/services/master-geo-model';
import { PolygonService } from '../../../services/polygon.service';
import { MasterGeoService } from '../../master-geo/services/master-geo.service';
import { AppLoaderService } from '../../../shared/app-loader/app-loader.service';
import Swal from 'sweetalert2';
import { generateClusterId, generateLogisticsClusterId } from '../../../utlity/utility';
import { UpdateCoordinatesComponent } from '../update-coordinates/update_coordinates.component';
import { AuthService } from '../../../services/auth.service';
import { MatSort } from '@angular/material/sort';
import { SwalMessageTypes } from '../../../enums/enums';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { PageId } from '../../../constants/enums';

@Component({
  selector: 'app-area-list',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatButtonModule,
    RouterOutlet,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatCardModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    RouterLink,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    MatTableModule,
    MatTableModule,
    MatSidenavModule,
    MatPaginatorModule,
    CommonModule,

    RouterLink,
    ReactiveFormsModule,
  ],

  templateUrl: './area-list.component.html',
  styleUrl: './area-list.component.scss',
})
export class AreaListComponent extends BaseListComponent {
  displayedColumns: string[] = ['areaName', 'location', 'polygon', 'delete'];
  dataSource: Array<MasterCluster> = [];
  readonly DEFAULT_PAGE_SIZE = 25;
  totalLocations: number = 0;

  constructor(
    auth: AuthService,
    router: Router,
    private fb: FormBuilder,
    route: ActivatedRoute,
    private dialog: MatDialog,
    private polygonService: PolygonService,
    private masterGeoService: MasterGeoService,
    private readonly egretLoader: AppLoaderService
  ) {
    super(auth, router, route, PageId.logistics_clusters); // Call the constructor of BaseListComponent
  }

  override async ngOnInit() {
    await super.ngOnInit();
    await this.getClusters();
  }

  async getClusters() {
    try {
      this.egretLoader.open();
      const clusters = await this.masterGeoService.getAllClusters();

      if (clusters) {
        this.dataSource = clusters;
        this.totalLocations = clusters.length;
      }

      this.egretLoader.close();
    } catch (error) {
      this.handleError(error);
    }
  }

  override async onPageChange(event: any): Promise<void> {
    await super.onPageChange(event); // Call the onPageChange method of the base class
    await this.getClusters();

    // Add custom logic here
  }

  async delete(id: string) {
    try {
      const result = await Swal.fire({
        title: 'Do you want to Delete Cluster?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      });

      if (!result.isConfirmed) {
        return;
      }

      await this.masterGeoService.deleteCluster(id);
      this.showMessage(
        'Cluster Deleted Successfully',
        SwalMessageTypes.Success
      );
      this.getClusters();
    } catch (error) {
      console.error('Error occurred while deleting cluster:', error);
      this.showMessage(
        'Failed to delete cluster. Please try again later.',
        SwalMessageTypes.Error
      );
    }
  }

  private showMessage(message: string, type: SwalMessageTypes) {
    Swal.fire({
      title: message,
      icon: type,
    });
  }

  openPopUp(cluster = null) {
    const dialogRef = this.dialog.open(AreaEditComponent, {
      width: '50%',
      maxWidth: '100vw',
      maxHeight: '80vh',
      data: {
        title: 'Add Area',
        cluster: cluster,
        currentClusterSearchKeys: this.dataSource.map((c) => ({
          searchKey: c.searchKey,
          locationId: c.location.id
        })),
      } as IAreaDialogData,
    });

    dialogRef.afterClosed().subscribe(async (response: any) => {
      const newCluster: MasterCluster = response.cluster || {};

      if (
        newCluster &&
        newCluster.coordinates &&
        newCluster.coordinates.length > 0
      ) {
        const coordinates = newCluster.coordinates.map((item) => [
          item.latitude,
          item.longitude,
        ]);
        const overlappingClusters: string[] = this.checkAreaOverlap(
          coordinates,
          this.dataSource
        );

        if (overlappingClusters.length > 0) {
          this.showWarningMessage(
            'Warning!',
            `<span style="color: green">${overlappingClusters.join(
              ','
            )}</span> Areas Coordinates Matching with <span style="color: red">${newCluster.name
            }</span>`
          );
          return;
        }
      }

      try {
        newCluster.id = generateLogisticsClusterId()
        // this.egretLoader.open();
        await this.createAndRefreshCluster(newCluster);
        this.egretLoader.close();
        this.showSuccessMessage('Added Cluster Successfully');
      } catch (error) {
        this.handleError(error);
      } finally {
        this.egretLoader.close();
      }
    });
  }

  private async createAndRefreshCluster(newCluster: MasterCluster) {
    await this.masterGeoService.createCluster(newCluster);
    await this.getClusters();
  }

  private showSuccessMessage(message: string) {
    this.egretLoader.close();
    Swal.fire('success', message, 'success');
  }

  private handleError(error: any) {
    this.egretLoader.close();
    Swal.fire('warning', 'Something Went Wrong', 'warning');
    console.log('updateClusterCoordinates Error ::: ', error);
  }

  private showInfoMessage(title: string, message: string) {
    Swal.fire('info', message, 'info');
  }

  private showWarningMessage(title: string, message: string) {
    Swal.fire('warning', message, 'warning');
  }

  private checkAreaOverlap(
    coordinates: number[][],
    existingClusters: MasterCluster[]
  ) {
    const overlappingClusterNames: string[] = [];

    for (const existingCluster of existingClusters) {
      if (existingCluster.coordinates !== null) {
        const transformedData =
          existingCluster.coordinates.map((item) => [
            item.latitude,
            item.longitude,
          ]) || [];
        if (this.arePolygonsOverlapping(coordinates, transformedData)) {
          overlappingClusterNames.push(existingCluster.name);
        } else {
          console.log('not overlap', existingCluster.name);
        }
      }
    }

    return overlappingClusterNames;
  }

  private arePolygonsOverlapping(
    coordinates1: number[][],
    coordinates2: number[][]
  ) {
    // Implement your polygon overlap detection logic here
    return false; // Placeholder return, replace with actual logic
  }

  updateClusterCoordinates(cluster: MasterCluster) {
    const dialogRef = this.dialog.open(UpdateCoordinatesComponent, {
      width: '40%',
      disableClose: true,
      data: {
        title: 'Update Coordinates Name',
        cluster: JSON.parse(JSON.stringify(cluster)),
        updatedCoordinates: null,
      } as any,
    });

    dialogRef.afterClosed().subscribe(async (response: any) => {
      try {
        if (response === undefined) {
          return;
        }

        const updatedCluster: MasterCluster = { ...response.cluster } || {};
        const updatedCoordinates: { latitude: number; longitude: number }[] =
          response.updatedCoordinates || {};

        if (updatedCoordinates) {
          const dbClusters: MasterCluster =
            await this.masterGeoService.getCluster(updatedCluster.id);
          if (dbClusters.coordinates !== null) {
            const dbClusterCoordinates = dbClusters.coordinates.map((item) => [
              item.latitude,
              item.longitude,
            ]);
            const coordinates = updatedCoordinates.map((item) => [
              item.latitude,
              item.longitude,
            ]);
            const isSamePolygon: boolean = this.polygonService.arePolygonsSame(
              coordinates,
              dbClusterCoordinates
            );
            if (isSamePolygon) {
              this.showInfoMessage(
                'No Updates Found',
                'No Coordinates are found updated'
              );
              return;
            }
          }

          const filteredData = this.dataSource.filter(
            (item) => item.id !== updatedCluster.id
          );
          const coordinates =
            updatedCoordinates.map((item) => [item.latitude, item.longitude]) ||
            [];
          const overLappedClusters: string[] = this.checkAreaOverlap(
            coordinates,
            filteredData
          );
          if (overLappedClusters.length > 0) {
            this.showWarningMessage(
              'Warning!',
              `<span style="color: green">${overLappedClusters.join(
                ','
              )}</span> Areas Coordinates Matching with <span style="color: red">${cluster.name
              }</span>`
            );
            return;
          }

          updatedCluster.coordinates = JSON.parse(
            JSON.stringify(updatedCoordinates)
          );
          await this.handleClusterUpdate(updatedCluster, dbClusters);
        }
      } catch (error) {
        this.handleError(error);
      }
    });
  }

  private async handleClusterUpdate(
    newCluster: MasterCluster,
    dbCluster: MasterCluster
  ) {
    try {
      this.egretLoader.open();
      const updateObj = {
        coordinates: newCluster.coordinates,
      };
      await this.masterGeoService.updateCluster(newCluster.id, updateObj);
      this.egretLoader.close();
      this.showSuccessMessage('Json Uploaded Successfully');
    } catch (error) {
      this.handleError(error);
    }
  }
}
