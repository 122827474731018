import { Component, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSidenavModule } from '@angular/material/sidenav';
import Swal from 'sweetalert2';
import { BusinessTypes, CustomerStatus, SwalMessageTypes } from '../../../../enums/enums';
import { CustomerEntity, CustomerListSearch } from '../../models/customers.model';
import { CustomersService } from '../../services/customers.service';
import { AppLoaderService } from '../../../../shared/app-loader/app-loader.service';
import { AuthService } from '../../../../services/auth.service';
import { BaseListComponent } from '../../../../shared/core/base.list.component';
import { PageId } from '../../../../constants/enums';
import { getDateYYYYMMDD } from '../../../../utlity/utility';

@Component({
  selector: 'app-customers-list',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    RouterLink,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSidenavModule
  ],

  templateUrl: './customers-list.component.html',
  styleUrl: './customers-list.component.scss',
})
export class CustomerListComponent extends BaseListComponent {
  form: FormGroup;
  readonly DEFAULT_PAGE_SIZE = 25;
  customers: CustomerEntity[] = [];
  totalCustomers: number = 0;
  customerListSearchInput: CustomerListSearch = this.initializeSearchInput();
  startDate: string = "";
  endDate: string = "";
  selectedSalesPersonIds: string[] = [];
  selectedCustomerStatus: number[] = [];
  selectedBusinessTypes: number[] = [];
  customerStatus = CustomerStatus;
  customerBusinessTypes = BusinessTypes;


  displayedColumns = [
    'customerId',
    'name',
    'businessName',
    'mobileNumber',
    "businessStatus",
    "businessType",
    "status",
    "baName",
    "baMobileNumber",
    "area",
    "createdDate",
    'edit'
  ];
  salesPersonList: any[] = [];
  salesPersonDropDown: any[] = [];
  dataSource!: any;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    router: Router,
    auth: AuthService,
    route: ActivatedRoute,
    public customerService: CustomersService,
    private loader: AppLoaderService,
    private fb: FormBuilder
  ) {
    super(auth, router, route, PageId.bms_customers)
    this.form = this.fb.group({
      customerId: [null],
      name: [null],
      mobileNumber: [null],
      salespersonId: null,
      status: null,
      businessTypeId: null,
      startDate: "",
      endDate: "",
    });
  }

  override async ngOnInit() {
    await super.ngOnInit();
    this.salesPersonList = await this.customerService.getSalesPersons();
    this.salesPersonDropDown = this.mapDropDown(this.salesPersonList);

    await this.getCustomers();
  }

  private mapSalesPersonInfo(response: any[]) {
    const updatedRes = response.map((resItem) => {
      const salesPersonId = resItem.salespersonId;

      const matchedSalesperson = this.salesPersonList.find(
        (person) => person.id === salesPersonId
      );

      if (matchedSalesperson) {
        return {
          ...resItem,
          baName: matchedSalesperson.name,
          baMobileNumber: matchedSalesperson.mobileNumber,
          area: matchedSalesperson.areaName,
          tlName: matchedSalesperson.salesLeadName,
        };
      } else {
        return { ...resItem, baName: '', area: '', tlName: '', baMobileNumber: '' };
      }
    });
    return updatedRes.filter((item) => Object.keys(item).length > 0);
  }

  async getCustomers() {
    try {
      this.loader.open();
      const response = await this.customerService.getCustomersByFilters(this.customerListSearchInput);
      const res = this.mapSalesPersonInfo(response['customerList']);
      this.dataSource = res;
      this.totalCustomers = response["totalCustomers"];
      if (this.totalCustomers > 0) {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loader.close();
      } else {
        this.loader.close();
        Swal.fire('Warning!', 'No Customers Found', 'warning');
      }
    } catch (error) {
      this.loader.close();
      this.showMessage('Failed to Fetch  Customers. Please try again later.', SwalMessageTypes.Error);
    }
  }

  editCustomer(row: any) {
    this.router.navigateByUrl(`/dashboard/customers/customers-details/${row.id}`)
  }


  override async onPageChange(event: any) {
    await super.onPageChange(event);
    const { name, customerId, mobileNumber } = this.form.value;
    this.customerListSearchInput = this.createCustomerListSearchInput(name, customerId, mobileNumber)
    await this.getCustomers();
  }

  async searchTasks() {
    if (this.form.invalid) {
      this.showMessage('Please Select Date.', SwalMessageTypes.Warning);

      return;
    }

    this.startDate = getDateYYYYMMDD(new Date(this.form.value.startDate));
    this.endDate = getDateYYYYMMDD(new Date(this.form.value.endDate));
    if (new Date(this.startDate) > new Date(this.endDate)) {
      this.showMessage('Please give valid dates', SwalMessageTypes.Warning);

      return;
    }

    const { name, customerId, mobileNumber } = this.form.value;
    this.pageNumber = 1;
    this.pageSize = this.DEFAULT_PAGE_SIZE;
    this.paginator.pageIndex = 0;
    this.customerListSearchInput = this.createCustomerListSearchInput(name?.trim(), customerId?.trim(), mobileNumber);
    await this.getCustomers();
  }

  private initializeSearchInput(): CustomerListSearch {
    return {
      name: '',
      id: '',
      mobile_number: '',
      businessTypeIds: [],
      status: [],
      salesPersonIds: [],
      startDate: '',
      endDate: '',
      page_number: 1,
      page_size: 25,
    };
  }

  private createCustomerListSearchInput(name: string, customerId: string, mobileNumber: string): CustomerListSearch {
    return {
      name: name || '',
      id: customerId || "",
      mobile_number: mobileNumber?.toString() || "",
      businessTypeIds: this.selectedBusinessTypes,
      status: this.selectedCustomerStatus,
      salesPersonIds: this.selectedSalesPersonIds,
      startDate: this.form.value.startDate == "" ? "" : getDateYYYYMMDD(this.form.value.startDate),
      endDate: this.form.value.endDate == "" ? "" : getDateYYYYMMDD(this.form.value.endDate),
      page_number: this.pageNumber,
      page_size: this.pageSize
    };
  }

  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  async clearFilters() {
    this.clearForm();
    this.selectedCustomerStatus = [];
    this.selectedBusinessTypes = [];
    this.selectedSalesPersonIds = [];
    this.customerListSearchInput = this.initializeSearchInput();
    await this.getCustomers();
  }

  private clearForm() {
    this.form = this.fb.group({
      customerId: [null],
      name: [null],
      mobileNumber: [null],
      salespersonId: null,
      status: null,
      businessTypeId: null,
      startDate: "",
      endDate: "",
      page_number: 1,
      page_size: 10,
    });
  }

  getSelectedSalesPerson() {
    this.selectedSalesPersonIds = this.form.get('salespersonId')?.value || [];
  }

  getCustomerStatus() {
    this.selectedCustomerStatus = this.form.get('status')?.value || [];
  }

  getCustomerBusinessTypes() {
    this.selectedBusinessTypes = this.form.get('businessTypeId')?.value || [];
  }


  //
  private mapDropDown(salesPerson: any[]) {
    return salesPerson.map((s) => ({
      id: s?.id,
      name: s?.name
    }))
  }

  downloadExcel() {

    if (this.form.invalid) {
      this.showMessage('Please Select Date.', SwalMessageTypes.Warning);

      return;
    }
    this.startDate = getDateYYYYMMDD(new Date(this.form.value.startDate));
    this.endDate = getDateYYYYMMDD(new Date(this.form.value.endDate));
    if (new Date(this.startDate) > new Date(this.endDate)) {
      this.showMessage('Please give valid dates', SwalMessageTypes.Warning);

      return;
    }
    const { name, customerId, mobileNumber } = this.form.value;
    if (name || customerId || mobileNumber !== undefined) {
      this.customerListSearchInput = this.createCustomerListSearchInput(name, customerId, mobileNumber);
    }


    this.exportToExcel()
  }

  async exportToExcel() {
    try {
      // const result = await Swal.fire({
      //   title: 'Do you want to proceed?',
      //   icon: 'question',
      //   showCancelButton: true,
      //   confirmButtonText: 'Yes',
      //   cancelButtonText: 'No',
      // });

      // if (!result.isConfirmed) {
      //   return;
      // }
      this.loader.open();
      const blobData = await this.customerService.exportCustomersByFilters(this.customerListSearchInput)
      const blob = new Blob([blobData], { type: 'application/xlsx' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'CustomersReport.xlsx';
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      this.loader.close();
    } catch (error) {
      console.error('Error in downloadToExcel:', error);
      this.loader.close();
    }
  }


}
