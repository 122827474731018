import { Component, OnDestroy } from '@angular/core';
import { ProductService } from '../product/services/product.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { CommonService } from '../../services/common.service';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { TypePipe } from '../../shared/pipes/type.pipe';
import { MatDialog } from '@angular/material/dialog';
import { VendorNotificationsFilterDialogComponent } from './vendor-notifications-filter-dialog/vendor-notifications-filter-dialog.component';
import { AuthService } from '../../services/auth.service';
import { first } from 'rxjs';

@Component({
  selector: 'app-vendor-notifications',
  standalone: true,
  imports: [TypePipe, MatIconModule, CommonModule, MatCardModule, MatButtonModule, RouterModule],
  templateUrl: './vendor-notifications.component.html',
  styleUrl: './vendor-notifications.component.scss'
})
export class VendorNotificationsComponent implements OnDestroy {
  notifications: any = []
  filterList: any = []
  subscription: any
  checkQueryParam: boolean = false;
  constructor(public productService: ProductService,
    public dialog: MatDialog, public route: ActivatedRoute,
    public commonService: CommonService, public authService: AuthService) {
    this.commonService.showLoder()
    this.subscription = this.productService.getVendorNotifications().subscribe(res => {
      this.notifications = res
      this.filterList = res;
      if (this.route.snapshot.queryParams["name"]) {
        this.checkQueryParam = true;
        this.filterData(this.route.snapshot.queryParams["name"],this.route.snapshot.queryParams["type"])
      }
      this.authService.updateNotificationsLastRead()
      this.commonService.hideLoder()
    },
      (error: any) => {
        this.commonService.showToaster("error", error.message)
        this.commonService.hideLoder()
      })
  }
  async deleteNotify(id: any) {
    this.commonService.showLoder()
    try {
      await this.productService.deleteNotification(id)
      this.commonService.hideLoder()
    }
    catch (err: any) {
      this.commonService.showToaster("error", err.error.message)
      this.commonService.hideLoder()
    }
  }
  openFilter() {
    let routedata: any = { name: '',type:'' }
    if (this.route.snapshot.queryParams) {
      routedata = this.route.snapshot.queryParams
    }
    const dialogRef = this.dialog.open(VendorNotificationsFilterDialogComponent, {
      data: routedata,
      panelClass: 'custom-dialog-class'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.filterData(result?.name,result?.type)
      }
    });
  }
  filterData(resultname: any,resultype:any) {
    let convertedString:any;
    if(resultype){
      convertedString = resultype.toLowerCase().replace(/\s+/g, '-');

    }
    if (resultname || resultype) {
      this.checkQueryParam = true;

      if(resultname){
      this.filterList = this.notifications.filter((item: any) =>
        item?.message ? item?.message?.toLowerCase().includes(resultname?.toLowerCase()) : item?.payload?.sku?.name?.toLowerCase().includes(resultname.toLowerCase())
      );
      if(resultname && resultype){
        this.filterList = this.filterList.filter((item: any) =>
          item?.payload?.type?.toLowerCase().includes(convertedString?.toLowerCase()) 
        );
        }
    }
    else{
    
      this.filterList =  this.notifications.filter((item:any) =>
    item?.payload?.type?.toLowerCase().includes(convertedString.toLowerCase()) 
    );
    }
    }
    else {
      this.checkQueryParam = false;
      this.filterList = this.notifications
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }
}
