import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ProductService } from '../services/product.service';
import { AuthService } from '../../../services/auth.service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { SkuDetailsComponent } from '../../../shared/dialogs/sku-details/sku-details.component';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmDialogComponent } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.component';
import { CommonService } from '../../../services/common.service';
import { StatusComponent } from '../../../shared/status/status.component';
import { TypePipe } from '../../../shared/pipes/type.pipe';
import { CommonModule, Location } from '@angular/common';
import Swal from 'sweetalert2';
import { SwalMessageTypes } from '../../../enums/enums';

@Component({
  selector: 'app-manage-product-skus',
  standalone: true,
  imports: [StatusComponent,CommonModule, TypePipe, MatIconModule, RouterModule, MatDialogModule, MatButtonModule, MatTableModule, MatCardModule],
  templateUrl: './manage-product-skus.component.html',
  styleUrl: './manage-product-skus.component.scss'
})
export class ManageProductSkusComponent {
  productId: any
  productDetail: any = null
  draftSkUDocs: any = []
  masterSkus: any = [];
  displayedColumns: string[] = ['name','status', 'action'];
  loading = true
  constructor(public route: ActivatedRoute,
    public router:Router,
    public commonService: CommonService,
    public location: Location,
    public productService: ProductService, public authService: AuthService, public dialog: MatDialog) {
    this.commonService.showLoder()
    this.route.params.subscribe(res => {
      this.productId = res['id']
       setTimeout(() => {
        this.getData()
       }, 2000);
    })
  }

  back() {
    this.location.back();
  }
  getData() {

    this, this.commonService.showLoder()
    this.productService.getMasterSkuFromProduct(this.productId).subscribe((res: any) => {
      this.masterSkus = res.data
      this.productDetail=res.data[0].attributes.product
      this.commonService.hideLoder()
    }, err => {
      this.commonService.showToaster("error", err.error.message)
      this.commonService.hideLoder()
    })
    this.productService.getDraftSkuDocsForAdmin(this.productId).subscribe(res=>{
      this.draftSkUDocs=res
    })

  }



  addSku() {
    this.router.navigate([`/dashboard/product/sku/add-sku/add`],{queryParams:this.productDetail});
  }

  editSku(skuid:any) {
    this.router.navigate([`/dashboard/product/sku/edit/update-sku/${skuid}`]);
  }

  editDraft(id:any) {
    this.router.navigate([`/dashboard/product/sku/draft-edit/${id}`]);
  }






 async discardPendingDoc(id: any) {

  /*   const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { title: 'Delete Confirmation', message: "Are you sure you want to delete?" },
      panelClass: 'custom-dialog-class'
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result == 'yes') {
        try {
          await this.productService.discardPendingDoc(id)
          this.commonService.showToaster("success", 'Deleted Successfully')
        }
        catch (err: any) {
          this.commonService.showToaster("error", err.error.message)
        }
      }
    }); */

    try {
      const result = await Swal.fire({
        title: 'Do you want to Delete?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      });

      if (!result.isConfirmed) {
        return;
      }
      await this.productService.discardPendingDoc(id)
      this.showMessage(
        'Deleted Successfully',
        SwalMessageTypes.Success
      );
    } catch (error:any) {
      this.showMessage(
        error.error.message,
        SwalMessageTypes.Error
      );
    }
  }
  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }
  isItemInDraft(sku_id:any){
    return this.draftSkUDocs.find((item:any) => {
      return item.payload.sku.id==sku_id
    });
  }

  changeStatus(id:any,status:any,type:any){
    this.productService.updateMasterSkuStatus(id,status).then(res=>{
      this.getData()
      this.showMessage(
        `${type} Successfully`,
        SwalMessageTypes.Success
      );
    })
  }











}
