@if(hasView){
<ng-container>
  <div class="container-fluid p-2">
    <mat-card class="p-3">
      <form [formGroup]="companyForm">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 d-flex mb-3">
              <div class="me-3">
                <mat-icon role="button" (click)="back()">keyboard_backspace</mat-icon>
              </div>
              <h3 class="title">{{ id ? "Update" : "Add" }} Company</h3>
            </div>
          </div>
          @if (comment) {
          <div class="row">
            <div class="alert alert-warning" role="alert">
              {{comment}}
            </div>
          </div>
          }
          <div class="row">
            <div class="col-md-6">
              <mat-form-field class="example-full-width w-100">
                <mat-label>Name</mat-label>
                <input matInput formControlName="name" />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="example-full-width w-100">
                <mat-label>Display Name</mat-label>
                <input matInput formControlName="display_name" />
              </mat-form-field>
            </div>
            <div class="col-md-6 ">
              <mat-label>Upload Image<span class="text-danger">*</span></mat-label>
              <input id="fileInput" class="form-control" (change)="onFileChange($event)" type="file" />
              @if(previewImage){
              <img [src]="previewImage" style="width: 100px; height: 100px" />
              <mat-icon (click)="removeImage()">close</mat-icon>

              }
            </div>
          </div>
          <div class="row my-3">
            <div class="col-md-12 text-center">
              <button [disabled]="!companyForm.valid" mat-raised-button (click)="submit()" color="primary">
                {{ id ? "Update" : "Save" }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </mat-card>
  </div>
</ng-container>
}