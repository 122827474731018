@if (hasCreate || hasEdit) {
<ng-container>
<div class="container-fluid p-2">
  <mat-card class="p-3">
    <form [formGroup]="attributeForm">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 d-flex mb-3">
            <div class="me-3">
              <mat-icon role="button" (click)="back()">keyboard_backspace</mat-icon>
             </div>
            <h3 class="title">{{ id ? "Update" : "Add" }} Attribute</h3>
          </div>
        </div>
        @if (comment) {
          <div class="row">
            <div class="alert alert-warning" role="alert">
              {{comment}}
            </div>
          </div>
        }
        <div class="row">
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <mat-label>Name</mat-label>
              <input matInput formControlName="name" />
            </mat-form-field>
          </div>
        </div>
        <div class="row my-3">
          <div class="col-md-12 text-center">
            <button [disabled]="!attributeForm.valid" mat-raised-button (click)="submit()" color="primary">
              {{ id ? "Update" : "Save" }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </mat-card>
</div>
</ng-container>
}
@else {
  <p class="text-center my-5">You dont have permission to access this</p>
}
