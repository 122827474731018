import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
    providedIn: 'root',
})
export class ExcelExportService {
    constructor() { }

    // Export data to Excel with a specified sheet name
    exportToExcel(data: any[], sheetName: string, fileName: string = 'exported-data') {
        const workBook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(workBook, worksheet, sheetName);

        const outputFileName = `${fileName}.xlsx`;
        XLSX.writeFile(workBook, outputFileName);
    }

    // Download a sample upload file with given data and sheet name
    downloadSampleUploadFile(fileName: string, excelFileFormat: any) {
        this.exportToExcel(excelFileFormat, fileName);
    }

    // Export data to Excel with custom options
    exportToExcelWithOptions(
        data: any[],
        options: { sheetName: string; fileName: string; header: string[] }
    ) {
        const workBook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(data, { header: options.header });
        XLSX.utils.book_append_sheet(workBook, worksheet, options.sheetName);

        const outputFileName = `${options.fileName}.xlsx`;
        XLSX.writeFile(workBook, outputFileName);
    }

    // Export data to multiple sheets in a single Excel file
    exportToMultipleSheets(sheetData: { sheetName: string; data: any[] }[], fileName: string = 'exported-data') {
        const workBook = XLSX.utils.book_new();

        sheetData.forEach((sheetInfo) => {
            const { sheetName, data } = sheetInfo;
            const worksheet = XLSX.utils.json_to_sheet(data);
            XLSX.utils.book_append_sheet(workBook, worksheet, sheetName);
        });

        const outputFileName = `${fileName}.xlsx`;
        XLSX.writeFile(workBook, outputFileName);
    }

    async readExcelData(file: File): Promise<any[]> {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();

                reader.onload = (e: any) => {
                    const binarystr = new Uint8Array(e.target.result);
                    const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'array', raw: true, cellFormula: false });
                    const wsname = wb.SheetNames[0];
                    const data: any[] = XLSX.utils.sheet_to_json(wb.Sheets[wsname]);

                    resolve(data);
                };

                reader.readAsArrayBuffer(file);
            } catch (error) {
                reject(error);
            }
        });
    }

    async readExcelDataMultipleSheets(file: File): Promise<{ [sheetName: string]: any[] }> {
      return new Promise((resolve, reject) => {
          try {
              const reader = new FileReader();

              reader.onload = (e: any) => {
                  const binarystr = new Uint8Array(e.target.result);
                  const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'array', raw: true, cellFormula: false });
                  const sheets: { [sheetName: string]: any[] } = {};

                  wb.SheetNames.forEach((sheetName) => {
                      const data: any[] = XLSX.utils.sheet_to_json(wb.Sheets[sheetName]);
                      sheets[sheetName] = data;
                  });

                  resolve(sheets);
              };

              reader.readAsArrayBuffer(file);
          } catch (error) {
              reject(error);
          }
      });
  }

  //   async readExcelDataMultipleSheets(files: File[]): Promise<any[][]> {
  //     const promises: Promise<any[]>[] = [];

  //     for (const file of files) {
  //         promises.push(
  //             new Promise((resolve, reject) => {
  //                 try {
  //                     const reader = new FileReader();

  //                     reader.onload = (e: any) => {
  //                         const binarystr = new Uint8Array(e.target.result);
  //                         const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'array', raw: true, cellFormula: false });
  //                         const wsname = wb.SheetNames[0];
  //                         const data: any[] = XLSX.utils.sheet_to_json(wb.Sheets[wsname]);

  //                         resolve(data);
  //                     };

  //                     reader.readAsArrayBuffer(file);
  //                 } catch (error) {
  //                     reject(error);
  //                 }
  //             })
  //         );
  //     }

  //     return Promise.all(promises);
  // }

    async readExcelHeader(file: File): Promise<string[]> {
        return new Promise<string[]>((resolve, reject) => {
            const reader: FileReader = new FileReader();
            reader.onload = (e: any) => {
                const workbook = XLSX.read(e.target.result, { type: 'binary' });
                const headerRow: any = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1 })[0];
                resolve(headerRow);
            };
            reader.onerror = (error) => reject(error);
            reader.readAsBinaryString(file);
        });
    }

    async readExcelSheet(file: File): Promise<any[]> {
      return new Promise<any[]>((resolve, reject) => {
          const reader: FileReader = new FileReader();
          reader.onload = (e: any) => {
              const workbook = XLSX.read(e.target.result, { type: 'binary' });
              const sheet = workbook.Sheets['Price']; // Replace 'Price' with the desired sheet name
              const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
              resolve(sheetData);
          };
          reader.onerror = (error) => reject(error);
          reader.readAsBinaryString(file);
      });
  }

}
