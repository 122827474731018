import { Component, Input } from '@angular/core';
import { CommonService } from '../../../../services/common.service';
import { CategoryService } from '../../../../pages/category/services/category.service';
import { TypePipe } from '../../../pipes/type.pipe';
import { StatusComponent } from '../../../status/status.component';

@Component({
  selector: 'app-add-category',
  standalone: true,
  imports: [StatusComponent, TypePipe],
  templateUrl: './add-category.component.html',
  styleUrl: './add-category.component.scss'
})
export class AddCategoryComponent {
  @Input() data: any;
  categoryName: any = "";
  constructor(public commonService: CommonService, public categoryService: CategoryService) {
    this.commonService.showLoder();
    setTimeout(() => {
      this.getAll();
    }, 1000);
  }

  async getAll() {
    this.commonService.showLoder();
    try {
      if (this.data?.payload?.parent_categories_id) {
        let data: any = await this.categoryService.getSingle(this.data?.payload?.parent_categories_id);
        this.categoryName = data.data[0].attributes.browser_path;
      } else {
        this.categoryName = "Root";
      }
      this.commonService.hideLoder();
    }
    catch (err: any) {
      this.commonService.hideLoder();
      this.commonService.showToaster('error', err.error.message);
    }
  }
}
