@if(hasView){
<ng-container>
  <div class="row">
    <div class="row m-2">
      <div class="col-md-9">
        <h2 class="card-title m-3">Price List</h2>
      </div>
      <div class="col-md-12">
        <button mat-raised-button type="button" class="m-2" (click)="openDialog()" style="float: right">
          <mat-icon>search</mat-icon>
        </button>
        <button mat-raised-button color="primary" class="ml-10" (click)="clearFilters()"
          style="float: right;margin-top: 10px;margin-left: 10px;">
          Clear Filters
        </button>
        <button mat-raised-button color="primary" class="ml-10" (click)="downloadPricing()"
          style="float: right;margin-top: 10px; margin-left: 10px;">
          Download
        </button>
      </div>
    </div>

    <div class="row">
      <div *ngIf="searchItems.length > 0">
        <div *ngFor="let item of searchItems" class="badge-container">
          <ng-container *ngIf="item.text !== 'vendorId'">
            <span class="badge badge-pill badge-success badge-owen">
              <span class="badge-text">{{ keyLabelMap[item.text] }} :</span>
              <span class="divider">-</span>
              <span class="search-text">{{ item.searchText }}</span>
              <span class="close badge-container1" (click)="removeSearchItem(item.id, item.text)">
                <mat-icon class="matCloseIcon">close</mat-icon>
              </span>
            </span>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="table-block">
    <div class="m-3 flex flex-col md:flex-row justify-between">
      <mat-card class="p-0">
        <mat-card-content class="p-0">
          <mat-sidenav-container fxFlex="0 1 auto">
            <mat-sidenav-content>
              <div class="table-container">
                <div class="row">
                  <div class="table-container">
                    <mat-table [dataSource]="dataSource" matSort>
                      <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef>
                          SKU Name</mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="name">
                          {{ row.name }}
                        </mat-cell>
                      </ng-container>



                      <ng-container matColumnDef="brand">
                        <mat-header-cell *matHeaderCellDef>
                          Brand</mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="brand">
                          {{ row.brand_name }}
                        </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="mrp">
                        <mat-header-cell *matHeaderCellDef> MRP </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="mrp">
                          {{ row.maximum_retail_price | replaceCommas }}
                        </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="sellingPrice">
                        <mat-header-cell *matHeaderCellDef>
                          Selling Price
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="sellingPrice">
                          {{ row.selling_price | replaceCommas }}
                        </mat-cell>
                      </ng-container>




                      <ng-container matColumnDef="pricingRule">
                        <mat-header-cell *matHeaderCellDef>Pricing Rules
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="pricingRule">
                          {{ row.pricing_rules_count }}
                        </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="stock">
                        <mat-header-cell *matHeaderCellDef> Stock </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="stock">
                          {{ row.available_stock }}
                        </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="saleInProgress">
                        <mat-header-cell *matHeaderCellDef> Sale In Progress </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="saleInProgress">
                          {{ row.sale_in_progress }}
                        </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="stockAvailableToCustomer">
                        <mat-header-cell *matHeaderCellDef> Available To Customer </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="stockAvailableToCustomer">
                          {{row.available_stock - row.sale_in_progress }}
                        </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="stockAvailableToCustomer">
                          {{ row.status == 'active' ? 'Active' : 'In-Active' }}
                          <!-- Active -->
                        </mat-cell>
                      </ng-container>



                      <ng-container matColumnDef="priceAction">
                        <mat-header-cell *matHeaderCellDef>Edit</mat-header-cell>
                        <mat-cell *matCellDef="let row">
                          <!-- <div class="button-container"  *ngIf="hasPricing"> -->
                          <!-- *ngIf="showPriceButton" -->
                          <div class="button-container">
                            <button mat-raised-button type="button" (click)="navigateToPriceDetails(row)"
                              color="primary">
                              <mat-icon class="me-0">edit</mat-icon>

                            </button>
                          </div>
                          <!-- *ngIf="showStockButton" -->
                          <div class="button-container">
                            <button mat-raised-button type="button" (click)="navigateToUpdateDetails(row.vendor_sku_id)"
                              color="primary">
                              SKU Details
                            </button>
                          </div>
                        </mat-cell>
                      </ng-container>
                      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                    </mat-table>
                  </div>

                  <mat-paginator [length]="totalPages" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                    (page)="onPageChange($event)"></mat-paginator>
                </div>
              </div>
            </mat-sidenav-content>
          </mat-sidenav-container>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</ng-container>
} @else {
<ng-container>
  <p class="text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
}
