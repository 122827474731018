import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { ReactiveFormsModule, FormsModule, FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { Address } from '../../models/customers.model';
import { SwalMessageTypes } from '../../../../enums/enums';
import { CustomersService } from '../../services/customers.service';
import Swal from 'sweetalert2';
import { AppLoaderService } from '../../../../shared/app-loader/app-loader.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { states } from '../../../../enums/enums';
import { State } from '../../../supplier/models/logisticsupplier-model';


@Component({
  selector: 'app-address-update',
  standalone: true,
  imports: [
    MatCardModule, MatButtonModule, MatTableModule, RouterLink, MatInputModule, MatFormFieldModule, ReactiveFormsModule,
    MatSelectModule, MatIconModule, CommonModule, FormsModule, MatDatepickerModule, MatRadioModule, MatNativeDateModule, MatPaginatorModule, MatSidenavModule,
    MatTableModule,
    MatButtonModule
  ],
  templateUrl: './address-update.component.html',
  styleUrl: './address-update.component.scss'
})
export class AddressUpdateComponent {

  dataSource: any;
  addressId: string = '';
  customerId: string = '';
  states: State[] = states;

  constructor(
    private fb: FormBuilder,
    public router: Router,
    public route: ActivatedRoute,
    private customersService: CustomersService,
    public auth: AuthService,
    public dialogRef: MatDialogRef<AddressUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.addressId = data.addressId;
    this.customerId = data.customerId;
  }

  ngOnInit() {
    this.editAddressForms();
    this.getAddressById()
  }


  async getAddressById() {
    try {
      const response = await this.customersService.getAddressById(this.addressId);
      this.dataSource = this.mapToEditInput(response);
      this.dataSource.patchValue({
        status: response.status
      });

    } catch (error) {
      this.showMessage('Failed to Get customer. Please try again later.', SwalMessageTypes.Error);
    } finally {

    }
  }


  editAddressForms() {
    this.dataSource = this.fb.group({
      street: [null, [Validators.required]],
      landmark: [null, [Validators.required]],
      area_code: [null, [Validators.required]],
      city: [null, [Validators.required]],
      state: [null, [Validators.required]],
      pincode: [null, [Validators.required]],
      latitude: [null, [Validators.required]],
      longitude: [null, [Validators.required]],
      is_default: [null, [Validators.required]],
    });
  }


  private mapToEditInput(addressEntity: any): any {

    return this.fb.group({
      street: new FormControl(addressEntity.street || ""),
      landmark: new FormControl(addressEntity.landmark || ""),
      area_code: new FormControl(addressEntity.area_code || ""),
      city: new FormControl(addressEntity.city || ""),
      state: new FormControl(addressEntity.state || ""),
      country: 'INDIA',
      latitude: new FormControl(+addressEntity.latitude || 0),
      longitude: new FormControl(+addressEntity.longitude || 0),
      pincode: new FormControl(+addressEntity.pincode || 0),
      is_default: new FormControl(+addressEntity.is_default || 0),
    })

  }

  async updateAddressById() {
    const addressEntity = this.mapToEditInput(this.dataSource.value);
    try {
      const response = await this.customersService.updateAddress(addressEntity.value, this.addressId);
      if (response) {
        this.showMessage('Address updated successfully', SwalMessageTypes.Success);
        this.dialogRef.close();
      } else {
        this.showMessage('Address not updated successfully', SwalMessageTypes.Warning);
        this.dialogRef.close();
      }

    } catch (error) {
      console.error('Error occurred while updating address:', error);
      this.showMessage('Failed to Update Address. Please try again later.', SwalMessageTypes.Error);
    }
  }



  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

}
