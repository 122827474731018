<h3 class="my-3 text-center">BMS</h3>
<div class="icon-menu-list mt-5 flex flex-row justify-center">
  <div class="icon-menu-item" (click)="toggleMenu()" routerLinkActive="active" [routerLink]="item.link"
    [routerLinkActiveOptions]="{exact: false}" [queryParams]="item.queryParams"  *ngFor="let item of menuItems">
    <ng-container *ngIf="item.show">
      <a mat-icon-button *ngIf="item.expand == false" class="nav-link d-flex w-100">
        <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">{{item.icon}}</mat-icon>
        <span class="item-name">{{ item.name }}</span>
      </a>
      <a mat-icon-button *ngIf="item.expand == true" (click)="toggleSubmenu(item.id)" class="nav-link d-flex">
        <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">{{item.icon}}</mat-icon>
        <span class="item-name d-flex">{{ item.name }}
          <mat-icon class="sidenav-mat-icon dropDown" *ngIf="showFiller[item.id]">arrow_drop_up</mat-icon>
          <mat-icon class="sidenav-mat-icon dropDown" *ngIf="!showFiller[item.id]">arrow_drop_down</mat-icon>
        </span>
      </a>
      <ng-container *ngIf="showFiller[item.id] && item.sub && item.show">
        <div class="nav-submenu ms-2 menu-level-2">
          <ng-container *ngFor="let sub of item.sub">
            <a mat-icon-button *ngIf="sub.show && sub.expand == false" class="nav-link d-flex"
              [routerLink]="sub.link">
              <mat-icon *ngIf="sub.icon" class="sidenav-mat-icon">{{ sub.icon }}</mat-icon>
              <span class="item-name no-wrap"> {{ sub.name }} </span>
            </a>


            <!-- -----------------------------third level menu------------------------------------ -->


            <a mat-icon-button *ngIf="sub.expand == true" (click)="toggleSubmenu(sub.id)" class="nav-link d-flex">
              <mat-icon *ngIf="sub.icon" class="sidenav-mat-icon">{{sub.icon}}</mat-icon>
              <span class="item-name d-flex min-width-15rem">{{ sub.name }}
                <mat-icon class="sidenav-mat-icon dropDown-sub-two" *ngIf="showFiller[sub.id]">arrow_drop_up</mat-icon>
                <mat-icon class="sidenav-mat-icon dropDown-sub-two" *ngIf="!showFiller[sub.id]">arrow_drop_down</mat-icon>
              </span>
            </a>
            <ng-container *ngIf="showFiller[sub.id] && sub.sub_two_level && sub.show">
              <div class="nav-submenu ms-2 menu-level-3">
                <ng-container *ngFor="let sub_two of sub.sub_two_level">
                  <a mat-icon-button *ngIf="sub_two.show" class="nav-link d-flex"
                    [routerLink]="sub_two.link">
                    <mat-icon *ngIf="sub_two.icon" class="sidenav-mat-icon">{{ sub_two.icon }}</mat-icon>
                    <span class="item-name no-wrap"> {{ sub_two.name }} </span>
                  </a>
                </ng-container>
              </div>
            </ng-container>


            <!-------------------------------- End third level menu ----------------------------------->

          </ng-container>
        </div>
      </ng-container>
      <!-- <ng-container *ngIf="showFiller">
      <div class="nav-submenu ms-2">
        <a
          mat-icon-button
          *ngFor="let sub of item.submenu"
          class="nav-link d-flex"
        >
          <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">
            {{ item.icon }}</mat-icon
          >
          <span class="item-name"> {{ sub.name }} </span>
        </a>
      </div>
    </ng-container> -->
    </ng-container>

  </div>
</div>
