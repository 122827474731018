<div class="container">
  <ng-container>
    <div class="row">
      <div class="col-md-6">
        <h2 class="card-title m-3 text-violet">Upload Json</h2>
      </div>
      <div class="col-md-6 d-flex justify-content-end">
        <mat-icon (click)="dialogRef.close()" class=" m-3 closeicon">close</mat-icon>
      </div>
    </div>
    <div class="row">

      <form [formGroup]="dataSource" class="m-3">
        <section id="content">
          <div class="container">
            <mat-card>
              <mat-card-content>
                <div class="row">
                  <div>

                    <!-- <label class="errorMessage" *ngIf="isNameExists">The name already exists. Please
                    choose a different name.</label>
                  <label class="errorMessage" *ngIf="isNameValid">Name is not Valid. Please
                    correct Name(Remove Extra Spaces and Special Characters).</label> -->

                    <input type="file" accept=".geojson" (change)="onSelectFile($event)" class="m-10 choosefile"
                      id="fileInput" />

                    <button mat-raised-button color="primary" (click)="addNewArea()">Save</button>

                    <!-- <label class="errorMessage" *ngIf="isGeoJsonNotSingle">Upload Only one geo Polygon At a
                    time.</label> -->

                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </section>
      </form>
    </div>
  </ng-container>
</div>