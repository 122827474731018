@if(hasView){
<ng-container>
  <div class="table-block">
    <form [formGroup]="dataSource">
      <section id="content">
        <div class="card">
          <div class="card-body card-padding">
            <h2 class="card-title m-3">Fee & Discount</h2>
            <div class="row">
              <div class="col-md-3">
                <mat-form-field class="full-width">
                  <mat-label>Platform Fees Value*</mat-label>
                  <input matInput type="number" formControlName="platformFees" name="platformFees"
                    placeholder="Platform Fees" />
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field class="full-width">
                  <mat-label>Marketing Fee Value*</mat-label>
                  <input matInput type="number" formControlName="marketingFee" name="Marketing Fee"
                    placeholder="Marketing Fee" />
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field class="full-width">
                  <mat-label>Discount Value*</mat-label>
                  <input matInput type="number" formControlName="discount" name="discount" placeholder="Discount" />
                </mat-form-field>
              </div>

            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body card-padding">
            <h2 class="card-title m-3">Margins</h2>
            <div class="row">
              <div class="col-md-3">
                <mat-form-field class="full-width">
                  <mat-label>Global Margin Percentage*</mat-label>
                  <input matInput type="number" formControlName="globalMargin" name="globalMargin"
                    placeholder="global Margin" />
                </mat-form-field>
              </div>
              <div class="col-md-9">
                <a mat-raised-button class="m-2 float-right" (click)=" openPopUp(categoryMarginValues)" color="primary">
                  Add Category Margin
                </a>
              </div>

              <mat-sidenav-container fxFlex="0 1 auto" *ngIf="categoryMarginValues.length>0">
                <mat-sidenav-content>
                  <div class="table-container">
                    <mat-table [dataSource]="categoryMarginDataSource" matSort>

                      <ng-container matColumnDef="categoryName">
                        <mat-header-cell *matHeaderCellDef>
                          Category Name
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="categoryName">
                          {{row.categoryName}}
                        </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="categoryMargin">
                        <mat-header-cell *matHeaderCellDef>
                          Category Percentage
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="categoryMargin">
                          {{row.categoryMargin}}
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="delete">
                        <mat-header-cell *matHeaderCellDef class="mat-column-actions">
                          Action
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" class="mat-column-actions">
                          <div class="d-flex">
                            <!-- @if(permission?.delete){ -->
                            <button mat-icon-button (click)="openPopUp(categoryMarginValues,row,true)">
                              <mat-icon color="edit">edit</mat-icon>
                            </button>
                            <button mat-icon-button (click)="deleteCategoryMargin(row)">
                              <mat-icon color="warn">delete</mat-icon>
                            </button>

                            <!-- } -->
                          </div>
                        </mat-cell>
                      </ng-container>
                      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                    </mat-table>
                  </div>
                </mat-sidenav-content>
              </mat-sidenav-container>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body card-padding">
            <h2 class="card-title m-3">Providers</h2>
            <div class="row">
              <div class="col-md-12">
                <a mat-raised-button class="m-2 float-right" (click)=" openProviderPopUp(providers)" color="primary">
                  Add Provider
                </a>
              </div>

              <mat-sidenav-container fxFlex="0 1 auto" *ngIf="providers.length>0">
                <mat-sidenav-content>
                  <div class="table-container">
                    <mat-table [dataSource]="providerDataSource" matSort>

                      <ng-container matColumnDef="providerName">
                        <mat-header-cell *matHeaderCellDef>
                          Provider Name
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="providerName">
                          {{getProviderNameByEnum(row.provider)}}
                        </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="settlementDays">
                        <mat-header-cell *matHeaderCellDef>
                          Settlement Days
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="settlementDays">
                          {{row.settlementDays}}
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="chargeType">
                        <mat-header-cell *matHeaderCellDef>
                          Charge Type
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="chargeType">
                          {{getCalculationTypeByEnum(row.chargeType)}}
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="chargeValue">
                        <mat-header-cell *matHeaderCellDef>
                          Value
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="chargeValue">
                          {{row.chargeValue}}
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="delete">
                        <mat-header-cell *matHeaderCellDef class="mat-column-actions">
                          Delete
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" class="mat-column-actions">
                          <div class="d-flex">
                            @if(hasDelete){
                            <button mat-icon-button (click)="deleteProvider(row)">
                              <mat-icon color="warn">delete</mat-icon>
                            </button>
                            }
                          </div>
                        </mat-cell>
                      </ng-container>
                      <mat-header-row *matHeaderRowDef="displayedProviderColumns"></mat-header-row>
                      <mat-row *matRowDef="let row; columns: displayedProviderColumns"></mat-row>
                    </mat-table>
                  </div>
                </mat-sidenav-content>
              </mat-sidenav-container>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body card-padding">
            <h2 class="card-title m-3">Shipping Charges</h2>
            <div class="row">
              <div class="col-md-12">
                <a mat-raised-button class="m-2 float-right" (click)=" openShippingPopUp(shippingCharges)"
                  color="primary">
                  Add Shipping Charges
                </a>
              </div>

              <mat-sidenav-container fxFlex="0 1 auto" *ngIf="shippingCharges.length>0">
                <mat-sidenav-content>
                  <div class="table-container">
                    <mat-table [dataSource]="shippingChargesDataSource" matSort>

                      <ng-container matColumnDef="categoryName">
                        <mat-header-cell *matHeaderCellDef>
                          Category Name
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="categoryName">
                          {{row.categoryName}}
                        </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="chargeBase">
                        <mat-header-cell *matHeaderCellDef>
                          Charge Base
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="chargeBase">
                          {{getChargeBaseByEnum(row.chargeBase)}}
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="chargeType">
                        <mat-header-cell *matHeaderCellDef>
                          Charge Type
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="chargeType">
                          {{getShippingChargeTypeByEnum(row.chargeType)}}
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="chargeValue">
                        <mat-header-cell *matHeaderCellDef>
                          Charge Value
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="chargeValue">
                          {{row.chargeValue | number: '1.0-2'}}
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="delete">
                        <mat-header-cell *matHeaderCellDef class="mat-column-actions">
                          Delete
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" class="mat-column-actions">
                          <div class="d-flex">
                            @if(hasDelete){
                            <button mat-icon-button (click)="deleteShippingCharge(row)">
                              <mat-icon color="warn">delete</mat-icon>
                            </button>
                            }
                          </div>
                        </mat-cell>
                      </ng-container>
                      <mat-header-row *matHeaderRowDef="displayedShippingChargesColumns"></mat-header-row>
                      <mat-row *matRowDef="let row; columns: displayedShippingChargesColumns"></mat-row>
                    </mat-table>
                  </div>
                </mat-sidenav-content>
              </mat-sidenav-container>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body card-padding">
            <h2 class="card-title m-3">Fullfilment</h2>
            <div class="row">
              <div class="col-md-3">
                <div class="col-md-11">
                  <mat-form-field class="full-width">
                    <mat-label>Select Fulfillment Provider</mat-label>
                    <mat-select formControlName="fulfillment" (selectionChange)="onChangeFulfillment($event)">
                      @for (fulfillmentType of fulfillments; track fulfillmentType) {
                      <mat-option [value]="fulfillmentType.fulfillmentId">{{
                        fulfillmentType.fulfillmentProvider
                        }}</mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-3" *ngIf="isQwipoFulfillmentType">
                <div class="col-md-11">
                  <mat-form-field class="full-width">
                    <mat-label>Transit Mode</mat-label>
                    <mat-select formControlName="transitType">
                      @for (transitType of transitTypes; track transitType) {
                      <mat-option [value]="transitType.transitTypeId">{{
                        transitType.transitTypeName
                        }}</mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-3" *ngIf="isQwipoFulfillmentType">
                <div class="col-md-11">
                  <mat-form-field class="full-width">
                    <mat-label>Region</mat-label>
                    <mat-select formControlName="region">
                      <mat-option *ngFor="let item of locations" [value]="item.id">
                        {{ item.value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body card-padding">
            <h2 class="card-title m-3">Vendor Mapping</h2>
            <div class="row">
              <div class="col-md-4">
                <mat-form-field class="full-width">
                  <mat-label>API Url</mat-label>
                  <input matInput type="text" formControlName="apiUrl" name="apiUrl" placeholder="API Url" />
                </mat-form-field>
              </div>
              <div class="col-md-2">
                <mat-form-field class="full-width">
                  <mat-label>API Key</mat-label>
                  <input matInput type="text" formControlName="apiKey" name="apiKey" placeholder="API Key" />
                </mat-form-field>
              </div>
              <div class="col-md-2">
                <mat-form-field class="full-width">
                  <mat-label>API Secret</mat-label>
                  <input matInput type="text" formControlName="apiSecret" name="apiSecret" placeholder="API Secret" />
                </mat-form-field>
              </div>


              <!-- <div class="col-md-2">
                <mat-form-field class="full-width">
                  <mat-label>Select Business Type</mat-label>
                  <mat-select formControlName="businessTypeId">
                    @for (merchantBusinessType of merchantBusinessTypes; track merchantBusinessType) {
                    <mat-option [value]="merchantBusinessType.businessTypeId">{{
                      merchantBusinessType.businessTypeName
                      }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div> -->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-9">
            <!--<h2 class="card-title m-3">Margin works</h2>-->
          </div>
          <div class="col-md-12 text-center" *ngIf="hasEdit">
            <button mat-raised-button type="button" class="m-2" color="primary" (click)="saveVendorMarginSetting()">
              Save
            </button>
          </div>
        </div>
      </section>
    </form>
  </div>
</ng-container>
} @else {
<ng-container>
  <p class="text-center">
    You don't have permission , Please contact administrator
  </p>
</ng-container>
}
