import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlanningService {
  baseServiceUrl: string = `${environment.logisticsUrl}`;
  logisticsUrl: string = `${environment.logisticsUrl}`;
  messagingService: string = `${environment.messagingService}`;

  constructor(
    public http: HttpClient
  ) {
  }


  async getResourceById(id: string): Promise<any> {
    try {
      const res = await lastValueFrom(this.http.get(`${this.logisticsUrl}/api/resource/get_by_id/${id}`));
      return res
    } catch (error) {
      throw error
    }


  }



  async getPlanning(locationId: string): Promise<any> {
    const res = await lastValueFrom(this.http.get<any>(`${this.baseServiceUrl}/planning/summary/${locationId}`));

    return res
  }

  async getSummaryByCluster(body: any): Promise<any> {
    const res = await lastValueFrom(this.http.post<any>(`${this.baseServiceUrl}/planning/summary_by_clusters`, body));

    return res
  }


  async postMessageToMessageQueue(messageType: string, body: {}) {
    try {
      const messageQueueUrl = `${this.messagingService}/messagequeue/post`;

      return lastValueFrom(this.http.post(messageQueueUrl, { messageType: messageType, input: JSON.stringify(body) }).pipe(map((response) => {
        console.log("postMessageToMessageQueue::::", response );
        return response;
      }))
      )
    } catch (error) {

      return false
    }
  }

}





