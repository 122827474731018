import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RouterLink } from '@angular/router';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CommonModule } from '@angular/common';
import Swal from 'sweetalert2';
import { ICategoryMarginData } from '../../services/category-margin-model';
import { applyMaxLengthValidation, validateNoNegativeValues } from '../../../../../utlity/utility';
import { SwalMessageTypes } from '../../../../../enums/enums';
import { ValidationResult } from '../../../../supplier/models/logisticsupplier-model';

interface checkNegativeValues {
  categoryMargin: number;
}
import { CategoryService } from '../../../../category/services/category.service';

@Component({
  selector: 'app-add-category-margin-popup',
  standalone: true,
  imports: [MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    RouterLink,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    MatTableModule,
    MatTableModule,
    MatSidenavModule,
    MatPaginatorModule,
    CommonModule],
  templateUrl: './add-category-margin-popup.component.html',
  styleUrl: './add-category-margin-popup.component.scss'
})
export class AddCategoryMarginPopupComponent {
  dataSource: any;
  categories: Array<any> = [];
  constructor(public dialogRef: MatDialogRef<AddCategoryMarginPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ICategoryMarginData,
    private fb: FormBuilder,private categoryService: CategoryService) {
    //this.categories = getCategories();
    const queryObj = { level: 1, sort_by: "display_order", sort_order: "asc" };
    this.categoryService.getAll(queryObj).subscribe(async (res: any) => {
      this.categories = res.data;
    });
    this.dataSource = this.fb.group({
      id: [''],
      category: [data.isEdit==true?data.editData.categoryName:null, [Validators.required]],
      categoryMargin: [data.isEdit==true?data.editData.categoryMargin:null, [Validators.required]],
    });
    this.fixMaxLength()
  }
  addCategoryMargin() {
    if (this.dataSource.invalid) {
      this.showMessage('Please fill all required fields.', SwalMessageTypes.Warning);

      return
    }
    const category = this.dataSource.get("category")?.value || "";
    const categoryMargin = this.dataSource.value.categoryMargin;
    const checkNegative={
      categoryMargin
    }
    const validateNegativeValues = this.validateNegativeValues(checkNegative);
  if (!validateNegativeValues.isValid) {
    const message = this.generateErrorMessage(validateNegativeValues.failedFields);
    this.showMessage(message, SwalMessageTypes.Warning);

    return
  }
    const catMarginObj = {
       categoryId: this.data.isEdit == true ? this.data.editData.categoryId : category.id,
       categoryName: this.data.isEdit == true ? this.data.editData.categoryName : category?.attributes?.name,
       categoryMargin: categoryMargin
       };
    const found = this.data.savedCategories.find((obj) => {
      return obj.categoryId === category.id;
    });
    if (found) {
      this.clearCategoryData();
      Swal.fire('', "This category margin is already added!", 'warning');
      return;
    }
    this.dialogRef.close({
      popupResult: catMarginObj,
    });
    this.clearCategoryData();
  }

  clearCategoryData() {
    this.dataSource.get('category')?.reset();
    this.dataSource.get('categoryMargin')?.reset();
  }

  private validateNegativeValues(data: checkNegativeValues): ValidationResult {
    const numericInputs = [
      { field: 'categoryMargin', value: data.categoryMargin },
    ];

    const validation = validateNoNegativeValues(
      numericInputs.map((input) => input)
    );
    return {
      isValid: validation.isValid,
      failedFields: validation.failedFields,
    };
  }
  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  private fixMaxLength() {
    applyMaxLengthValidation(this.dataSource, [
      { property: "categoryMargin", maxLength: 5 },
    ]);
  }

  private generateErrorMessage(failedFields: { field: string; failedField: string }[] | undefined): string {
    let message = 'The following fields contain invalid numeric values:';
    if (failedFields) {
      failedFields.forEach(failedField => {
        message += `${failedField.field} (${failedField.failedField}),`;
      });
      message = message.slice(0, -1) + '.';
    }

    return message;
  }


}
