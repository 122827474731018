import {
  MerchantBusinessType,
  SupplierType,
  states,
} from './../../../../enums/enums';
import { Component } from '@angular/core';
import {
  FormBuilder,
  Validators,
  ReactiveFormsModule,
  FormsModule,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MerchantsService } from '../../services/merchants.service';
import {
  MerchantEntity,
  MerchantForm,
  PropertyValidation,
} from '../../models/merchants.model';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import Swal from 'sweetalert2';
import { AppLoaderService } from '../../../../shared/app-loader/app-loader.service';
import { AuthService } from '../../../../services/auth.service';
import { SupplierStatus, SwalMessageTypes } from '../../../../enums/enums';
import {
  applyMaxLengthValidation,
  containsMail,
  containsOnlyAlphanumeric,
  guid,
  hasSpecialCharacters,
  isTextValid,
  uuidv4,
  validLongitude,
  validateGST,
  validateMaxText,
  validateMobileNumber,
  validateNegativeValues,
  validateNumbersOnly,
  validatePanNumber,
  validatePinCode,
} from '../../../../utlity/utility';
import { State } from '../../../supplier/models/logisticsupplier-model';
import { SuppliersService } from '../../../supplier/services/logisticsupplier.service';
import { BaseListComponent } from '../../../../shared/core/base.list.component';
import { PageId } from '../../../../constants/enums';

@Component({
  selector: 'app-create-merchant',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    RouterLink,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatCheckboxModule,
  ],
  templateUrl: './create-merchant.component.html',
  styleUrls: ['./create-merchant.component.scss'],
})
export class CreateMerchantComponent extends BaseListComponent {
  singularTypes = Object.values(SupplierStatus).filter(
    (value) => typeof value === 'number'
  );
  states: State[] = states;

  businessTypeOptions: { name: string; value: MerchantBusinessType }[] = [
    { name: 'Distributor', value: MerchantBusinessType.Distributor },
    { name: 'Manufacturer', value: MerchantBusinessType.Manufacturer },
    { name: 'Wholesaler', value: MerchantBusinessType.Wholesaler },
    { name: 'JitVendor', value: MerchantBusinessType.JitVendor },
    { name: 'Inventory', value: MerchantBusinessType.Inventory },
  ];

  dataSource: any;
  merchantEntity!: MerchantEntity;
  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    private merchantsService: MerchantsService,
    private loader: AppLoaderService,
    private supplierService: SuppliersService
  ) {
    super(auth, router, route, PageId.bms_vendors);
    this.dataSource = this.fb.group({
      businessName: [null, [Validators.required]],
      name: [null, [Validators.required]],
      email: [null, [Validators.required]],
      mobileNumber: [null, [Validators.required]],
      gstNumber: [null, [Validators.required]],
      panNumber: [null, [Validators.required]],
      businessType: [null, [Validators.required]],
      password: [null, [Validators.required]],
      address: [null, [Validators.required]],
      area: [null, [Validators.required]],
      state: [null, [Validators.required]],
      city: [null, [Validators.required]],
      pincode: [null, [Validators.required]],
      latitude: [null, [Validators.required]],
      longitude: [null, [Validators.required]],
    });
    this.fixMaxLength();
  }

  override async ngOnInit() {
    super.ngOnInit();
  }
  async saveMerchant() {
    this.loader.open();

    try {
      if (this.dataSource.invalid) {
        this.showMessage(
          'Please fill the required fields',
          SwalMessageTypes.Warning
        );

        return;
      }

      await this.createMerchant();
    } catch (error) {
      console.error('Error occurred during merchant creation:', error);
    } finally {
      this.loader.close();
    }
  }

  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  private mapMerchantObj(userObj: any): any {
    let docId = guid();
    return {
      name: userObj.name,
      email_id: userObj.email,
      mobile_number: userObj.mobileNumber,
      password: userObj.password,
      customId: docId,
      vendor_id: docId,
      roles: [],
      user_type: null,
      status: '',
      extended_permissions: {},
    };
  }

  private mapMerchant(merchant: MerchantForm): MerchantEntity {
    const mapForm = merchant;
    return {
      id: mapForm?.id,
      business_name: mapForm?.businessName,
      name: mapForm?.name,
      email: mapForm?.email,
      mobile_number: mapForm?.mobileNumber,
      gst_number: mapForm?.gstNumber,
      pan_number: mapForm?.panNumber,
      business_type: mapForm?.businessType,
      password: mapForm?.password,
      area: mapForm?.area,
      address: mapForm?.address,
      state: mapForm?.state,
      city: mapForm?.city,
      pincode: mapForm?.pincode,
      latitude: mapForm?.latitude,
      longitude: mapForm?.longitude,
      status: mapForm?.status || 0,
    };
  }

  async createMerchant(): Promise<any> {
    try {
      let merchant = this.dataSource.value;
      const userWithSameEmail =
        await this.merchantsService.getAuthUserByEmailId(merchant.email.trim());
      if (userWithSameEmail) {
        Swal.fire({
          title: '',
          text: 'User already exists with given mailId, please use another mailId',
          icon: 'warning',
        });
        this.loader.close();
        return true;
      }
      const firebaseMerchant = this.mapMerchantObj(merchant);

      if (this.validMerchant(merchant)) {
        const response: boolean =
          await this.merchantsService.getMerchantByMobileNumber(
            firebaseMerchant.mobile_number.toString()
          );
        if (!response) {
          let res = await this.merchantsService.addUsers(firebaseMerchant);
          merchant.id = res;
          console.log(':::merchant.id:::', merchant.id);
          const merchantEntity = this.mapMerchant(this.dataSource.value);
          merchantEntity.pan_number =
            merchantEntity?.pan_number.toLocaleUpperCase();
          merchantEntity.gst_number =
            merchantEntity?.gst_number.toLocaleUpperCase();
          await this.merchantsService.createMerchant(merchantEntity);

          if (MerchantBusinessType.JitVendor == merchantEntity.business_type) {
            const input = {
              businessType: 5,
              pageNumber: 1,
              pageSize: 10,
            };
            const inventoryVendor = await this.merchantsService.getMerchants(
              input
            );
            const vendorId = inventoryVendor['merchantList'][0].id || '';
            const supplierEntity = this.mapToSupplierEntity(
              merchantEntity,
              vendorId
            );

            const response = await this.supplierService.createSupplier(
              supplierEntity
            );
          }

          // merchant is not saving in the PG
          // const response = await this.merchantsService.addVendor({ refId: merchantEntity.id, name: merchantEntity.name, mobileNumber: merchantEntity.mobileNumber, businessName: merchantEntity.businessName });
          this.showMessage(
            'Vendor created successfully',
            SwalMessageTypes.Success
          );
          this.router.navigateByUrl('/dashboard/merchant');
        } else {
          this.showMessage(
            'Vendor already exists with given mobile number, please give another mobile number',
            SwalMessageTypes.Warning
          );
        }
      }
    } catch (error) {
      console.error('Error occurred while creating Vendor:', error);
      this.showMessage(
        'Failed to create Vendor. Please try again later.',
        SwalMessageTypes.Error
      );
    }
  }

  private mapToSupplierEntity(merchant: MerchantEntity, vendorId: string) {
    return {
      id: merchant?.id,
      name: merchant?.business_name,
      mobileNumber: merchant?.mobile_number.toString(),
      vendorId: vendorId,
      address: {
        longitude: merchant?.longitude.toString(),
        latitude: merchant?.longitude.toString(),
        country: 'INDIA',
        pinCode: merchant?.pincode,
        state: merchant?.state,
        city: merchant?.city,
        landmark: '',
        area: merchant?.area,
        residence: merchant?.address,
      },
      gstNumber: merchant.gst_number,
      panNumber: merchant.pan_number,
      supplierType: SupplierType.Procurement,
      tcsPercentage: 0.1,
      contractMode: 1,
      status: 1,
    };
  }

  private validMerchant(merchant: any): boolean {
    if (
      !merchant.businessName ||
      containsOnlyAlphanumeric(merchant.businessName)
    ) {
      this.showMessage(
        'Please enter valid business name .',
        SwalMessageTypes.Warning
      );

      return false;
    }

    if (!merchant.name || containsOnlyAlphanumeric(merchant.name)) {
      this.showMessage('Please enter valid name .', SwalMessageTypes.Warning);

      return false;
    }

    if (!containsMail(merchant.email)) {
      this.showMessage('Please give valid Email Id.', SwalMessageTypes.Error);
      return false;
    }

    if (
      !merchant.mobileNumber ||
      !validateMobileNumber(+merchant.mobileNumber)
    ) {
      this.showMessage(
        'Please enter valid Mobile number .',
        SwalMessageTypes.Warning
      );

      return false;
    }

    if (!merchant.gstNumber || !validateGST(merchant.gstNumber)) {
      this.showMessage(
        'Please enter valid GST number .',
        SwalMessageTypes.Warning
      );

      return false;
    }

    if (!merchant.panNumber || !validatePanNumber(merchant.panNumber)) {
      this.showMessage(
        'Please enter valid PAN number .',
        SwalMessageTypes.Warning
      );

      return false;
    }

    if (!merchant.businessType) {
      this.showMessage(
        'Please select business type .',
        SwalMessageTypes.Warning
      );

      return false;
    }

    if (!merchant.password) {
      this.showMessage('Please enter password .', SwalMessageTypes.Warning);

      return false;
    }

    if (!merchant.address) {
      this.showMessage(
        'Please enter valid address .',
        SwalMessageTypes.Warning
      );

      return false;
    }

    if (!merchant.area || containsOnlyAlphanumeric(merchant.area)) {
      this.showMessage('Please enter valid area .', SwalMessageTypes.Warning);

      return false;
    }
    if (!merchant.city || !isTextValid(merchant.city)) {
      this.showMessage('Please enter valid city .', SwalMessageTypes.Warning);

      return false;
    }

    if (!merchant.state) {
      this.showMessage('Please enter valid state .', SwalMessageTypes.Warning);

      return false;
    }

    if (!merchant.pincode || !validatePinCode(+merchant.pincode)) {
      this.showMessage(
        'Please enter valid pincode .',
        SwalMessageTypes.Warning
      );

      return false;
    }

    if (!merchant.latitude || !validLongitude(merchant.latitude.toString())) {
      this.showMessage(
        'Please enter valid latitude .',
        SwalMessageTypes.Warning
      );

      return false;
    }
    if (!merchant.longitude || !validLongitude(merchant.longitude.toString())) {
      this.showMessage(
        'Please enter valid longitude .',
        SwalMessageTypes.Warning
      );

      return false;
    }

    return true;
  }

  private fixMaxLength() {
    applyMaxLengthValidation(this.dataSource, [
      { property: 'mobileNumber', maxLength: 10 },
      { property: 'pincode', maxLength: 6 },
    ]);
  }

  private validateNegativeValues(data: MerchantForm): boolean {
    const numericInputs = [
      data.mobileNumber,
      data.businessType,
      data.pincode,
      data.latitude,
      data.longitude,
      data.mobileNumber,
    ];

    return validateNegativeValues(numericInputs);
  }

  private checkSpecialCharacters(data: any): boolean {
    const inputValues = [];
    inputValues.push(data.businessName);
    inputValues.push(data.name);
    inputValues.push(data.mobileNumber);
    inputValues.push(data.panNumber);
    inputValues.push(data.businessType);

    return inputValues.length > 0 ? hasSpecialCharacters(inputValues) : false;
  }
  async onFileChange(event: any, documentType: string) { }
}
