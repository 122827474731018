<div class="panel panel-default">
  <div class="panel-body">
    <div class="card-body scroll-55-rem">
      <div class="row">
        <div class="col-md-6">
        </div>
        <div class="col-md-6">
          <mat-icon (click)="dialogRef.close()" class="float-right m-3 closeicon">close</mat-icon>
        </div>
      </div>
      <div class="col-md-12">
        <div class="image-container">
          <img class="butch-img1" alt="" [src]="data.imageUrl" />
        </div>
      </div>
    </div>
  </div>
</div>