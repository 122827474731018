@if(hasView){
<ng-container>
  <div class="heading-block">
    <div class="row">
      <div class="col-md-6">
        <span class="header">
          <h2 class="card-title m-3">Store Setting</h2>
        </span>
      </div>
      <div class="col-md-6 text-right"></div>
    </div>
  </div>

  <div class="table-block">
    <form [formGroup]="dataSource">
      <section id="content">
        <div class="card">
          <div class="card-body card-padding">
            <div class="row">
              <div class="col-md-6">
                <mat-label>Shop Opening Time *</mat-label>
                <div class="row d-flex">
                  <div class="col-md-6">
                    <mat-form-field class="full-width">
                      <mat-label>Hour</mat-label>
                      <mat-select formControlName="shopOpeningHour">
                        @for (hour of hours; track hour) {
                        <mat-option [value]="hour">{{
                          hour
                          }}</mat-option>
                        }
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <mat-form-field class="full-width">
                      <mat-label>Minute</mat-label>
                      <mat-select formControlName="shopOpeningMinute">
                        @for (minute of minutes; track minute) {
                        <mat-option [value]="minute">{{
                          minute
                          }}</mat-option>
                        }
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <mat-label>Shop Closing Time *</mat-label>
                <div class="row d-flex">
                  <div class="col-md-6">
                    <mat-form-field class="full-width">
                      <mat-label>Hour</mat-label>
                      <mat-select formControlName="shopClosingHour">
                        @for (hour of hours; track hour) {
                        <mat-option [value]="hour">{{
                          hour
                          }}</mat-option>
                        }
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <mat-form-field class="full-width">
                      <mat-label>Minute</mat-label>
                      <mat-select formControlName="shopClosingMinute">
                        @for (minute of minutes; track minute) {
                        <mat-option [value]="minute">{{
                          minute
                          }}</mat-option>
                        }
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>


              <div class="col-md-4">
                <mat-form-field class="full-width">
                  <mat-label>Weekends</mat-label>
                  <mat-select formControlName="weekends" multiple>
                    <mat-option *ngFor="let type of weekDays; let i = index" [value]="i">
                      {{ type }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-2">
                <mat-checkbox formControlName="shopClosed" color="primary">Shop Closed
                </mat-checkbox>
              </div>
              <div class="col-md-6 ">
                <mat-label>Upload Image *</mat-label>
                <input id="fileInput" class="form-control" (change)="onFileChange($event)" type="file" />
                @if(previewImage){
                <img [src]="previewImage" style="width: 100px; height: 100px" />
                <mat-icon (click)="removeImage()">close</mat-icon>

                }
              </div>
              <div class="col-md-12 d-flex">
                <div class="col-md-4">
                  <mat-form-field class="full-width">
                    <input matInput formControlName="holidays" placeholder="Holiday Dates"
                      [matDatepicker]="appDatepicker" [readonly]="true" [min]="yesterday"/>

                    <mat-datepicker-toggle matSuffix [for]="appDatepicker"></mat-datepicker-toggle>
                  </mat-form-field>

                  <mat-datepicker #appDatepicker></mat-datepicker>
                </div>
                <div class="col-md-3">
                  <a mat-raised-button class="m-2 float-right" (click)="addHoliday()" color="primary">
                    Add Holiday
                  </a>
                </div>
              </div>
              <div class="col-md-12">

                <mat-form-field class="example-chip-list">
                  <mat-label>Holidays</mat-label>
                  <mat-chip-grid #chipGrid aria-label="Enter fruits" >
                    @for (holiday of holidayList; track holiday) {
                    <mat-chip-row (removed)="remove(holiday)" [editable]="false" (edited)="edit(holiday, $event)"
                      [aria-description]="'press enter to edit ' + holiday">
                      {{holiday}}
                      <button matChipRemove [attr.aria-label]="'remove ' + holiday">
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </mat-chip-row>
                    }
                    <input placeholder="Holidays" [matChipInputFor]="chipGrid"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                      (matChipInputTokenEnd)="add($event)" [readonly]="true"/>
                  </mat-chip-grid>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="buttonSection">
                @if(hasCreate){
                <button mat-raised-button color="primary" class="text-center ml" (click)="saveShopSetting()">
                  Save
                </button>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  </div>
</ng-container>
} @else {
<ng-container>
  <p class="text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
}
