import { Component, inject } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ProductService } from '../../../services/product.service';
import { CommonModule, Location } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import uuid4 from 'uuid4';
import { MatIconModule } from '@angular/material/icon';
import { CommonService } from '../../../../../services/common.service';
import {
  NumericValueType,
  RxReactiveFormsModule,
  RxwebValidators,
} from '@rxweb/reactive-form-validators';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AuthService } from '../../../../../services/auth.service';
import {
  Subject,
  debounceTime,
  distinctUntilChanged,
  first,
  takeUntil,
} from 'rxjs';
import {
  MatChipEditedEvent,
  MatChipInputEvent,
  MatChipsModule,
} from '@angular/material/chips';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GroupNamePipe } from '../../../../../shared/pipes/groupname.pipe';
import Swal from 'sweetalert2';
import { SwalMessageTypes } from '../../../../../enums/enums';
import { BaseListComponent } from '../../../../../shared/core/base.list.component';
import { PageId } from '../../../../../constants/enums';
import { environment } from '../../../../../../environments/environment';
import { hsnCodeValidator } from '../../../../../shared/validators/hsn.validator';
@Component({
  selector: 'app-skuDetails',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    MatCardModule,
    MatSelectModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    RxReactiveFormsModule,
    MatAutocompleteModule,
    CommonModule,
    MatChipsModule,
    GroupNamePipe,
  ],
  templateUrl: './skuDetails.component.html',
  styleUrl: './skuDetails.component.scss',
})
export class SkuDetailsComponent extends BaseListComponent {
  generatingSuggestions = false;
  skuForm: any;
  id: any;
  companyList: any = [];
  brandsList: any = [];
  categoriesList: any = [];
  countryList: any = [];
  tax_category_list: any = [];
  attributeOptions: any = [];
  variant_type_options: any = [];
  addOnBlur = true;
  productDisplayDetails: any = {};
  variant_value_options: any = {};
  selectedSuggestion = '';
  skuNameSuggestionOptions: any = [];
  cancelPreviousCalls = new Subject();
  operation_type = '';
  isAnyImageEdited = false;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  announcer = inject(LiveAnnouncer);
  productDetails!: {
    id: string;
    name: string;
    brand_id: string;
    company_id: string;
    category_id: string;
  };

  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    public productService: ProductService,
    public location: Location,
    public commonService: CommonService,
    public http: HttpClient
  ) {
    super(auth, router, route, PageId.seller_add_sku);
    this.initFormDefaultValues();
  }

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
    this.operation_type = this.route.snapshot.params['type'];
    this.getAllSkuattributes();
    this.getAllCountries();
    this.getAllTaxCategories();
    console.log(
      'SkuDetailsComponent this.auth.isVendor() ::: ',
      this.auth.isVendor()
    );
    console.log('SkuDetailsComponent this.vendorId ::: ', this.vendorId);

    if (this.auth.isVendor()) {
      this.skuForm.patchValue({ vendor_id: this.vendorId });
    }

    this.skuForm.get('product')
      ?.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: any) => {
        this.productDetails = {
          id: value?.id,
          name: value?.name,
          brand_id: value?.brands_id,
          company_id: value?.companies_id,
          category_id: value?.categories_id
        };
        this.getProductDetail(value);
        this.getAllVarientTypes(value.categories_id);
      });

    // if new
    if (this.route.snapshot.params['id']) {
      this.patchValueFromPublishedData();
      if (
        this.operation_type === 'add-sku-to-catalog' ||
        this.operation_type === 'update-sku-to-catalog'
      ) {
        this.skuForm.patchValue({ vendor_only: true });
      }
    } else {
      // this.addAttributeItem()
      if (this.route.snapshot.params['docId']) {
        this.patchValueFromDraftDoc();
      } else {
        this.skuForm.patchValue({ product: this.route.snapshot.queryParams });
        this.handleTaxCategoryChange();
        if (
          this.operation_type == 'add-sku-to-catalog' ||
          this.operation_type == 'update-sku-to-catalog'
        ) {
          this.skuForm.patchValue({ vendor_only: true });
        }
      }
    }
    this.skuForm
      ?.get('sku')
      ?.get('sku_packing_sections')
      ?.get('min_order_qty')
      ?.valueChanges.subscribe((res: any) => {
        this.skuForm
          ?.get('sku')
          ?.get('sku_packing_sections')
          ?.get('max_order_qty')
          ?.updateValueAndValidity();
      });
    this.skuForm
      .get('sku')
      ?.get('selected_variant_values')
      ?.valueChanges.subscribe((value: any) => {
        for (const key of value) {
          if (
            key.variant_group_name == 'package_type' &&
            key.variant_types_id
          ) {
            console.log(this.variant_type_options);
            let result = this.variant_type_options[
              key.variant_group_name
            ]?.find((ele: any) => {
              return key.variant_types_id == ele.id;
            });
            this.skuForm.patchValue({
              sku: {
                sku_packing_sections: {
                  product_package_type: result?.attributes?.name,
                },
              },
            });
          }
          if (key.variant_group_name == 'uom' && key.variant_types_id) {
            let result = this.variant_type_options[
              key.variant_group_name
            ]?.find((ele: any) => {
              return key.variant_types_id == ele.id;
            });
            this.skuForm.patchValue({
              sku: {
                sku_packing_sections: {
                  uom: result?.attributes?.name,
                },
              },
            });
          }
        }
      });
  }

  navigateToUpdateCategory(){
    this.router.navigate(['/dashboard/product/add'], {
      state: { productDetails: this.productDetails},
    });
  }

  get tax_category_name() {
    let result = this.tax_category_list.find(
      (ele: any) =>
        this.skuForm
          .get('sku')
          ?.get('sku_tax_details')
          ?.get('tax_categories_id')?.value == ele.id
    );
    return result?.attributes?.name ?? '';
  }

  handleTaxCategoryChange() {
    this.skuForm
      .get('sku')
      ?.get('sku_tax_details')
      ?.get('tax_categories_id')
      .valueChanges.subscribe((value: any) => {
        if (this.tax_category_name == 'Taxable supply') {
          this.skuForm.patchValue({
            sku: {
              sku_tax_details: {
                tax_percentage: 0,
                reason: '',
                cess_rate: 0,
              },
            },
          });
        } else if (this.tax_category_name == 'Non-Taxable Supply') {
          this.skuForm.patchValue({
            sku: {
              sku_tax_details: {
                tax_percentage: 0,
                reason: 'Nil Rated',
                cess_rate: 0,
              },
            },
          });
        } else {
          this.skuForm.patchValue({
            sku: {
              sku_tax_details: {
                tax_percentage: 0,
                reason: '',
                cess_rate: 0,
              },
            },
          });
        }
      });
  }

  private initFormDefaultValues() {
    this.skuForm = this.fb.group({
      vendor_id: [''],
      vendor_only: [false, [Validators.required]], // if passed true then only vendorSku related tables would be updated
      product: this.fb.group({
        id: [uuid4(), [Validators.required]],
        name: [''],
        companies_id: [null],
        brands_id: [null],
        categories_id: [null],
      }),
      sku: this.fb.group({
        id: [uuid4(), [Validators.required]],
        name: ['', [Validators.required]],
        meta_title: [''],
        // selling_units: [[], this.arrayNotEmptyValidator()],
        selling_units: [[]],
        status: ['active'],
        short_description: ['', [RxwebValidators.maxLength({ value: 50 })]],
        long_description: ['', [RxwebValidators.maxLength({ value: 100 })]],
        print_name: ['',RxwebValidators.maxLength({ value: 25 })],
        short_code: [
          '',
          [
            RxwebValidators.alphaNumeric(),
            RxwebValidators.maxLength({ value: 12 }),
          ],
        ],
        barcode: [
          '',
          [
            RxwebValidators.alphaNumeric(),
            RxwebValidators.minLength({ value: 8 }),
            RxwebValidators.maxLength({ value: 8 }),
          ],
        ],
        sku_tax_details: this.fb.group({
          id: [uuid4()],
          countries_id: ['', [Validators.required]],
          tax_percentage: [0],
          tax_categories_id: [0],
          hsn_code: ['', [
            Validators.required,
            RxwebValidators.numeric(),
            Validators.pattern(/^\d{6}$|^\d{8}$/)
            // RxwebValidators.minLength({ value: 6 }),
            // RxwebValidators.maxLength({ value: 8 })
          ]],
          // hsn_code: ['', [Validators.required, hsnCodeValidator()]],

          cess_rate: [''],
          reason: [''],
        }),
        sku_packing_sections: this.fb.group({
          id: [uuid4()],
          inner_pack: [''],
          uom: [''],
          upc: [''],
          weight_per_unit: this.fb.group({
            kg: [
              null,
              [
                Validators.required,
                RxwebValidators.numeric({
                  acceptValue: NumericValueType.PositiveNumber,
                  allowDecimal: true,
                }),
              ],
            ],
            grams: [0],
          }),
          mrp: [
            0,
            [
              Validators.required,
              this.onlyDigitsAndDecimalValidator(),
              RxwebValidators.numeric({
                acceptValue: NumericValueType.PositiveNumber,
                allowDecimal: true,
              }),
            ],
          ],
          dimensions: this.fb.group({
            length: [0],
            width: [0],
            height: [0],
          }),

          min_order_qty: [
            null,
            [
              RxwebValidators.numeric({
                acceptValue: NumericValueType.PositiveNumber,
                allowDecimal: true,
              }),
            ],
          ],
          max_order_qty: [
            null,
            [
              this.validateMaxOrderQty(),
              RxwebValidators.numeric({
                acceptValue: NumericValueType.PositiveNumber,
                allowDecimal: true,
              }),
            ],
          ],
          display_order: [null],
          pickup_order: [null],
          product_package_type: [''],
        }),
        sku_attributes: this.fb.array([]),
        sku_images: this.fb.group({
          id: [uuid4()],
          optional_image1: [''],
          optional_image2: [''],
          optional_image3: [''],
          optional_image4: [''],
          permanent_image: ['', Validators.required],
          options: this.fb.group({
            permanent_image_options: this.fb.group({ background: ['no'] }),
            optional_image1_options: this.fb.group({ background: ['no'] }),
            optional_image2_options: this.fb.group({ background: ['no'] }),
            optional_image3_options: this.fb.group({ background: ['no'] }),
            optional_image4_options: this.fb.group({ background: ['no'] }),
          }),
        }),
        selected_variant_values: this.fb.array([]),
      }),
    });
  }

  get showBarcodeError() {
    return this.skuForm.get('sku').get('barcode').errors;
  }

  private onlyDigitsAndDecimalValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      // Regex pattern to match digits and optional decimal point
      const pattern = /^\d*\.?\d*$/;

      if (value && !pattern.test(value)) {
        return { onlyDigitsAndDecimal: true };
      }
      return null;
    };
  }

  private validateMaxOrderQty() {
    return (control: any) => {
      const minOrderQty = this.skuForm
        ?.get('sku')
        ?.get('sku_packing_sections')
        ?.get('min_order_qty')?.value;
      const maxOrderQty = control.value;

      // Check if both fields are filled
      if (minOrderQty !== null && maxOrderQty !== null) {
        // Check if maxOrderQty is greater than or equal to minOrderQty
        if (maxOrderQty < minOrderQty) {
          return { maxOrderQtyInvalid: true }; // Return an error if maxOrderQty is less than minOrderQty
        }
      }
      return null; // Return null if validation passes
    };
  }

  private arrayNotEmptyValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const arrayValue = control.value;
      return Array.isArray(arrayValue) && arrayValue.length === 0
        ? { arrayNotEmpty: true }
        : null;
    };
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      const sellingUnits = this.skuForm.get('sku').value.selling_units || [];
      const updatedSellingUnits = [...sellingUnits, value];
      this.skuForm
        .get('sku')
        .patchValue({ selling_units: updatedSellingUnits });
    }

    // Clear the input value
    if (event.chipInput) {
      event.chipInput.clear();
    }
  }

  removeunit(unit: any): void {
    const sellingUnits = this.skuForm.get('sku').value.selling_units;
    const index = sellingUnits.indexOf(unit);

    if (index >= 0) {
      const updatedUnits = [
        ...sellingUnits.slice(0, index),
        ...sellingUnits.slice(index + 1),
      ];
      this.skuForm.get('sku').patchValue({ selling_units: updatedUnits });
      this.announcer.announce(`Removed ${unit}`);
    }
  }

  getProductDetail(value: any) {
    this.productService
      .getCategoryById(value.categories_id)
      .then((res: any) => {
        this.productDisplayDetails['category'] =
          res?.data[0].attributes.browser_path;
      });
    this.productService.getCompanyById(value.companies_id).then((res: any) => {
      this.productDisplayDetails['company'] = res?.data[0].attributes.name;
    });
    this.productService.getBrandById(value.brands_id).then((res: any) => {
      this.productDisplayDetails['brand'] = res?.data[0].attributes.name;
    });
  }

  async patchValueFromDraftDoc() {
    this.commonService.showLoder();
    this.productService
      .getSingleApprovalDoc(this.route.snapshot.params['docId'])
      .subscribe(
        (response: any) => {
          console.log(response);
          let res = response.payload;
          this.operation_type = response.type;
          if (res.sku.sku_attributes) {
            this.skuattributes.clear();
            res.sku.sku_attributes.forEach((item: any) => {
              this.addAttributeItem();
            });
          } else {
            this.skuattributes.clear();
            // this.addAttributeItem()
          }

          this.createSelectedVariantFormStructureFromData(
            res.sku.selected_variant_values
          );

          this.skuForm.patchValue(res);
          this.handleTaxCategoryChange();
          this.commonService.hideLoder();
        },
        (error: any) => {
          this.commonService.showToaster('error', error.message);
          this.commonService.hideLoder();
        }
      );
  }

  async patchValueFromPublishedData() {
    if (
      this.operation_type == 'add-sku' ||
      this.operation_type == 'update-sku' ||
      this.operation_type == 'add-sku-to-catalog'
    ) {
      this.commonService.showLoder();
      this.productService
        .getSingleMasterSkuData(this.route.snapshot.params['id'])
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (response: any) => {
            let res = {
              ...response.data[0].attributes,
              ...{ id: response.data[0].id },
            };
            if (res.sku_attributes) {
              this.skuattributes.clear();
              res.sku_attributes.forEach((item: any) => {
                this.addAttributeItem();
              });
            } else {
              this.skuattributes.clear();
              // this.addAttributeItem()
            }

            this.createSelectedVariantFormStructureFromData(
              res.selected_variant_values
            );
            if (this.operation_type == 'add-sku-to-catalog') {
              let skuid = res.id;
              let product_id = res.product.id;
              let temp = this.removeIds(res);
              temp.id = skuid;
              temp.product.id = product_id;
              res = temp;
            }

            this.skuForm.patchValue({ sku: res, product: res.product });
            this.handleTaxCategoryChange();
            this.commonService.hideLoder();
          },
          (error: any) => {
            this.commonService.showToaster('error', error.message);
            this.commonService.hideLoder();
          }
        );
    }
    if (this.operation_type == 'update-sku-to-catalog') {
      this.commonService.showLoder();
      this.productService
        .getSingleVendorSkuData(this.route.snapshot.params['id'])
        .subscribe(
          (response: any) => {
            let res = {
              ...response.data[0].attributes,
              ...{ id: response.data[0].id },
            };
            if (res.sku_attributes) {
              this.skuattributes.clear();
              res.sku_attributes.forEach((item: any) => {
                this.addAttributeItem();
              });
            } else {
              this.skuattributes.clear();
              // this.addAttributeItem()
            }

            this.createSelectedVariantFormStructureFromData(
              res.selected_variant_values
            );

            res.id = res.skus_id;
            this.skuForm.patchValue({ sku: res, product: res.product });
            this.handleTaxCategoryChange();

            this.commonService.hideLoder();
          },
          (error: any) => {
            this.commonService.showToaster('error', error.message);
            this.commonService.hideLoder();
          }
        );
    }
  }

  createSelectedVariantFormStructureFromData(selected_variant_values: any) {
    if (selected_variant_values) {
      this.selected_variant_values.clear();
      selected_variant_values.forEach((item: any) => {
        this.addSelectedVariantItem();
      });
    } else {
      this.skuattributes.clear();
    }
  }

  async submit() {
    try {
      this.commonService.showLoder();

      if (!this.skuForm) {
        return;
      }

      const docId = this.route.snapshot.params['docId'];
      const formData = this.skuForm.value;

      if (docId) {
        await this.productService.updatePendingApprovalDoc(docId, formData);
      } else {
        await this.productService.addToPendingApproval(
          this.operation_type,
          formData
        );
      }
      this.router.navigateByUrl('/dashboard/product/skus/draft');
    } catch (error: any) {
      console.error(error);
      this.commonService.showToaster('error', error.error.message);
    } finally {
      this.commonService.hideLoder();
    }
  }

  async publish() {
    if (!this.skuForm.value.product.name) {
      this.skuForm
        .get('product')
        .get('name')
        .patchValue(this.skuForm.value.sku.name);
    }
    this.commonService.showLoder();
    let docId;
    if (this.route.snapshot.params['docId']) {
      docId = this.route.snapshot.params['docId'];
      await this.productService.updatePendingApprovalDoc(
        this.route.snapshot.params['docId'],
        this.skuForm.value
      );
    } else {
      docId = await this.productService.addToPendingApproval(
        this.operation_type,
        this.skuForm.value
      );
    }
    this.productService
      .getSingleApprovalDoc(docId)
      .pipe(first())
      .subscribe(
        (res) => {
          this.productService
            .approveRequest(res)
            .then((res) => {
              if (this.auth.isVendor()) {
                this.router.navigateByUrl('/dashboard/product/vendor_skus');
              }
              if (this.auth.isAdmin()) {
                this.router.navigateByUrl(
                  '/dashboard/product/skus/master_skus'
                );
              }
              this.commonService.hideLoder();
              this.showMessage(
                `Published Successfully`,
                SwalMessageTypes.Success
              );
            })
            .catch((err) => {
              this.commonService.hideLoder();
              this.commonService.showToaster(
                'error',
                'Some Error Occoured',
                'Error'
              );
            });
        },
        (err) => {
          this.commonService.hideLoder();
          this.commonService.showToaster(
            'error',
            'Some Error Occoured',
            'Error'
          );
        }
      );
  }
  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }
  async requestApproval() {
    this.commonService.showLoder();
    let docId: any;
    if (this.route.snapshot.params['docId']) {
      docId = this.route.snapshot.params['docId'];
      await this.productService.updatePendingApprovalDoc(
        this.route.snapshot.params['docId'],
        this.skuForm.value
      );
    } else {
      docId = await this.productService.addToPendingApproval(
        this.operation_type,
        this.skuForm.value
      );
    }
    this.productService
      .getSingleApprovalDoc(docId)
      .pipe(first())
      .subscribe((res: any) => {
        this.productService.reSubmitRequestForApproval(docId, res.payload);
        this.commonService.hideLoder();
        this.router.navigateByUrl('/dashboard/my-requests');
      });
  }

  back() {
    this.location.back();
  }

  getAllCountries() {
    this.productService
      .getAllCountries()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.countryList = res.data;
      });
  }

  getAllTaxCategories() {
    this.productService
      .getAllTaxCategories()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.tax_category_list = res.data;
      });
  }

  get skuattributes() {
    return this.skuForm.get('sku')?.get('sku_attributes') as any;
  }

  get selected_variant_values() {
    return this.skuForm.get('sku')?.get('selected_variant_values') as any;
  }

  get sku_images() {
    return this.skuForm.get('sku')?.get('sku_images')?.value as any;
  }

  addAttributeItem() {
    const newItem = this.fb.group({
      id: [uuid4(), Validators.required],
      key: ['', Validators.required],
      value: ['', Validators.required],
    });
    this.skuattributes.push(newItem);
  }

  removeItem(index: number) {
    this.skuattributes.removeAt(index);
  }

  removeSelectedVariantItem(index: number) {
    this.selected_variant_values.removeAt(index);
  }
  async deleteSkuVariant(id: any, index: number) {
    try {
      const result = await Swal.fire({
        title: 'Do you want to Delete Variant Type?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      });

      if (!result.isConfirmed) {
        return;
      }
      await this.productService.deleteSkuVariantValue(id);
      this.showMessage(
        'Attribute Deleted Successfully',
        SwalMessageTypes.Success
      );
      this.selected_variant_values.removeAt(index);
    } catch (error: any) {
      this.showMessage(
        error.error.message,
        SwalMessageTypes.Error
      );
    }
  }

  getAllSkuattributes() {
    this.productService
      .getAllAttributes()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.attributeOptions = res.data;
      });
  }

  download(url: any) {
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = 'image.jpg';
    downloadLink.target = '_blank';
    downloadLink.click();
  }

  remove(fromcontrolname: any) {
    this.skuForm.get('sku')?.get('sku_images')?.get(fromcontrolname)?.reset();
  }

  getSplitString(string: any) {
    const parts = string.split('/');
    const abcString = parts[1];
    return abcString;
  }

  getFile(event: any, fromcontrolname: any) {
    this.commonService.showLoder();
    let file = event.target.files[0];
    this.isAnyImageEdited = true;
    if (file) {
      let base64String: any;
      let width: any;
      let height: any;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const img: any = new Image();
        img.src = e?.target?.result;
        img.onload = function () {
          // Get the dimensions of the image
          width = this.width;
          height = this.height;

          // Display the dimensions
          console.log('Width:', width);
          console.log('Height:', height);
        };
        setTimeout(() => {
          if (width >= 600 && height >= 300) {
            console.log('if:');
            let base64String1: any = e?.target?.result;
            const parts = base64String1.split(',');
            base64String = parts[1];
            let body = {
              fileName: file.name,
              fileExtension: this.getSplitString(file.type),
              fileData: base64String,
            };
            this.productService.upload(body).subscribe(async (res: any) => {
              this.skuForm
                .get('sku')
                ?.get('sku_images')
                ?.get(fromcontrolname)
                ?.setValue(res.data.url);
              this.commonService.hideLoder();
            });
          } else {
            console.log('else:');
            this.commonService.hideLoder();
            this.commonService.showToaster(
              'error',
              'Uploaded file should be minimum 600X300 size.'
            );
          }
        }, 2000);
      };
    } else {
      this.commonService.hideLoder();
    }
  }

  addSelectedVariantItem(group?: any) {
    let newItem = this.fb.group({
      id: [uuid4(), Validators.required],
      variant_group_name: [group ?? 'other', Validators.required],
      variant_types_id: [null, Validators.required],
      variant_value: [null, Validators.required],
    });
    this.selected_variant_values.push(newItem);
  }

  getAllVarientTypes(categories_id: any) {
    this.productService.getCategoryById(categories_id).then((catRes: any) => {
      this.productService
        .getAllVariantTypesByCategory(
          catRes.data[0].attributes.categories_id_path.split('>')[0]
        )
        .subscribe((res: any) => {
          this.variant_type_options = {};
          res.data.forEach((element: any) => {
            if (element.attributes.group_name) {
              if (!this.variant_type_options[element.attributes.group_name]) {
                this.variant_type_options[element.attributes.group_name] = [];
              }
              this.variant_type_options[element.attributes.group_name].push(
                element
              );
            } else {
              if (!this.variant_type_options['other']) {
                this.variant_type_options['other'] = [];
              }
              this.variant_type_options['other'].push(element);
            }
            if (element.attributes.value_type == 'dropdown') {
              this.productService
                .getAllVariantValues(element.id)
                .subscribe((options: any) => {
                  this.variant_value_options[element.id] = options.data;
                });
            }
          });
          let groups = Object.keys(this.variant_type_options);
          if (groups.indexOf('other') >= 0) {
            groups.splice(groups.indexOf('other'), 1);
          }
          groups.forEach((group) => {
            if (
              !this.selected_variant_values.value.find(
                (item: any) => item.variant_group_name == group
              )
            ) {
              this.addSelectedVariantItem(group);
            }
          });
        });
    });
  }

  shouldDisplayOption(option: any, control: any) {
    let existing: any[] = [];
    this.selected_variant_values?.controls?.forEach((item: any) => {
      if (item?.value?.variant_types_id) {
        existing.push(item.value.variant_types_id);
      }
    });
    return (
      !existing.includes(option?.id) ||
      control?.value?.variant_types_id == option.id
    );
  }

  removeIds(obj: any) {
    const newObj = JSON.parse(JSON.stringify(obj)); // Create a deep copy of the object
    this.removeIdsRecursive(newObj);
    return newObj;
  }

  removeIdsRecursive(obj: any) {
    if (Array.isArray(obj)) {
      obj.forEach((item, index) => {
        if (typeof item === 'object' && item !== null) {
          this.removeIdsRecursive(item); // Recursively call removeIdsRecursive for nested arrays
        }
        if (obj[index] && obj[index].hasOwnProperty('id')) {
          delete obj[index]['id']; // Delete the 'id' field except the root id
        }
      });
    } else if (typeof obj === 'object' && obj !== null) {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (typeof obj[key] === 'object' && obj[key] !== null) {
            this.removeIdsRecursive(obj[key]); // Recursively call removeIdsRecursive for nested objects
          }
          if (key === 'id' && Object.keys(obj).indexOf('id') !== -1) {
            delete obj[key]; // Delete the 'id' field except the root id
          }
        }
      }
    }
  }

  getValueTypeForVariant(group: any, variant_types_id: any) {
    return this.variant_type_options[group]?.find((item: any) => {
      return item.id == variant_types_id;
    })?.attributes?.value_type;
  }

  onSuggestionSelected(event: any) {
    console.log(event);
    this.skuForm.get('sku').patchValue({ name: event.value });
    console.log(this.selectedSuggestion);
  }

  fetchNameSuggestions() {
    this.commonService.showLoder();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer UkoM63cGV2',
    });
    let query_string = this.skuForm.value.sku.name + ' -';
    // this.selected_variant_values.value.forEach((element: any) => {
    //   query_string += ` ${this.getVariantTypeNameFromId(
    //     element.variant_types_id
    //   )} - ${element.variant_value}`;
    // });

    query_string += this.selected_variant_values.value.map((element: any) => {
      return `${element.variant_value} ${this.getVariantTypeNameFromId(element.variant_types_id)}`;
    }).join(', ');

    // query_string = query_string.replace(/,$/, '');
    query_string = query_string.replace(/,\s*$/, '').replace(/Offer\s*$/, '');

    this.cancelPreviousCalls.next(true);
    this.generatingSuggestions = true;
    this.selectedSuggestion = '';
    this.http
      .post<any>(
        environment.suggestionsApi,
        { name: query_string },
        { headers: headers }
      )
      .pipe(
        debounceTime(2000),
        distinctUntilChanged(),
        takeUntil(this.cancelPreviousCalls)
      )
      .subscribe(
        (res) => {
          if (res.suggestions && res.suggestions.length > 0) {
            this.skuForm
              .get('product')
              .get('name')
              .patchValue(res.suggestions[0].product_suggestion);
            this.skuNameSuggestionOptions = res.suggestions[0].sku_suggestions;
          } else {
            this.skuForm
              .get('product')
              .get('name')
              .patchValue(this.skuForm.value.sku.name);
            this.skuNameSuggestionOptions = [query_string];
          }
          this.generatingSuggestions = false;
          this.commonService.hideLoder();
        },
        (err) => {
          this.skuForm
            .get('product')
            .get('name')
            .patchValue(this.skuForm.value.sku.name);
          this.skuNameSuggestionOptions = [query_string];
          this.generatingSuggestions = false;
          this.commonService.hideLoder();
        }
      );
  }

  getVariantTypeNameFromId(id: any) {
    let name = '';
    Object.keys(this.variant_type_options).forEach((key: any) => {
      this.variant_type_options[key].forEach((element: any) => {
        if (element.id == id) {
          name = element.attributes.name;
        }
      });
    });
    return name;
  }

  isPublishEnable() {
    const isFormInvalid = !this.skuForm.valid;
    const isAdmin = this.auth.isAdmin();

    if (this.operation_type === 'add-sku') {
      return isFormInvalid || (!this.selectedSuggestion && !isAdmin);
    } else {
      return isFormInvalid;
    }
  }


  // isPublishEnable() {
  //   if (this.operation_type == 'add-sku') {
  //     return !this.skuForm.valid ||  !this.selectedSuggestion;
  //   } else {
  //     return !this.skuForm.valid;
  //   }
  // }

  isMoreVariantTypeOptionAvailable(): boolean {
    const otherOptionsLength = this.variant_type_options['other']
      ? this.variant_type_options['other'].length
      : 0;
    const totalOptionsLength =
      Object.keys(this.variant_type_options).length - 1 + otherOptionsLength;
    return totalOptionsLength > this.selected_variant_values.value.length;
  }

  // restrictDecimal(event: Event): void {
  //   const input = event.target as HTMLInputElement;
  //   input.value = input.value.replace('.', '');
  // }

  isSendRequestForApprovalEnable() {
    if (this.operation_type == 'add-sku') {
      return !this.skuForm.valid || !this.selectedSuggestion;
    } else {
      return !this.skuForm.valid;
    }
  }

  isPublishVisible() {
    if (this.hasBMSAdmin) {
      return true;
    } else {
      if (this.operation_type == 'add-sku') {
        return false;
      } else {
        return !this.isAnyImageEdited;
      }
    }
  }

  isSendRequestForApprovalVisible() {
    if (this.operation_type == 'add-sku') {
      return !this.hasBMSAdmin;
    } else {
      return this.hasSellerAdmin && this.isAnyImageEdited;
    }
  }
}
