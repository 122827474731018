<div class="container-fluid ">
  <mat-card class="p-3 my-2">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 d-flex">
          <div class="me-3">
            <mat-icon role="button" (click)="back()">keyboard_backspace</mat-icon>
           </div>
          <h3 class="title">Product Details</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <label>Product Name</label>
          <p> {{previewDetail?.name}}</p>
        </div>
        <div class="col-md-4">
          <label>Brand Name</label>
          <p> {{previewDetail?.brands_name}}</p>
        </div>
        <div class="col-md-4">
          <label>Company Name</label>
          <p> {{previewDetail?.companies_name}}</p>
        </div>
        <div class="col-md-4">
          <label>Categories Name</label>
          <p> {{previewDetail?.categories_name}}</p>
        </div>
        <div class="col-md-4">
          <label>Product Description</label>
          <p> {{previewDetail?.description}}</p>
        </div>
        <div class="col-md-12">
          <label>Product Variants</label>
          @for (item of previewDetail?.product_variants; track item.id) {
          <div>
            {{item.variant_types_name}}
          </div>
          }
        </div>

      </div>
      <h4>SKU</h4>
      @for (item of previewDetail?.skus; track item.id) {

      <div class="row">
        <div class="col-md-4">
          <label>Name</label>
          <div>
            {{item.name}}
          </div>
        </div>
        <div class="col-md-4">
          <label>Barcode</label>
          <div>
            {{item.barcode}}
          </div>
        </div>
        <div class="col-md-4">
          <label>Short Description</label>
          <div>
            {{item.short_description}}
          </div>
        </div>
        <div class="col-md-4">
          <label>Meta Title</label>
          <div>
            {{item.meta_title}}
          </div>
        </div>
        <div class="col-md-4">
          <label>Short Code</label>
          <div>
            {{item.short_code}}
          </div>
        </div>
        <div class="col-md-4">
          <label>Print Name</label>
          <div>
            {{item.print_name}}
          </div>
        </div>
        <div class="col-md-12">
          <label>Long Description</label>
          <div>
            {{item.long_description}}
          </div>
        </div>
      </div>

      @if (item?.selected_variant_values) {
      <h4>Selected Variant Values</h4>
      @for (item1 of item.selected_variant_values; track item1.id) {
      <div class="row">
        <div class="col-md-6">
          <label>Variant Type</label>
          <div>
            {{item1.variant_types_name}}
          </div>
        </div>
        <div class="col-md-6">
          <label>Variant Value</label>
          <div>
            {{item1.variant_values_name}}
          </div>
        </div>
      </div>
      }
      }
      @if (item?.sku_attributes) {
      <h4>SKU Attributes</h4>
      @for (item1 of item.sku_attributes; track item1.id) {
      <div class="row">
        <div class="col-md-6">
          <label>Key</label>
          <div>
            {{item1.key}}
          </div>
        </div>
        <div class="col-md-6">
          <label>Value</label>
          <div>
            {{item1.value}}
          </div>
        </div>
      </div>
      }
      }
      @if (item?.sku_packing_sections) {
      <h4>SKU Packing Sections</h4>
      <div class="row">
        <div class="col-md-3">
          <label>Height Dimensions</label>
          <div>
            {{item.sku_packing_sections?.dimensions?.height}}
          </div>
        </div>
        <div class="col-md-3">
          <label>Length Dimensions</label>
          <div>
            {{item.sku_packing_sections?.dimensions?.length}}
          </div>
        </div>
        <div class="col-md-3">
          <label>Width Dimensions</label>
          <div>
            {{item.sku_packing_sections?.dimensions?.width}}
          </div>
        </div>
        <div class="col-md-3">
          <label>Grams Weight</label>
          <div>
            {{item.sku_packing_sections?.weight_per_unit?.grams}}
          </div>
        </div>
        <div class="col-md-3">
          <label>Kg Weight</label>
          <div>
            {{item.sku_packing_sections?.weight_per_unit?.kg}}
          </div>
        </div>

        <div class="col-md-3">
          <label>Inner Pack</label>
          <div>
            {{item.sku_packing_sections?.inner_pack}}
          </div>
        </div>
        <div class="col-md-3">
          <label>Min Order Qty</label>
          <div>
            {{item.sku_packing_sections?.min_order_qty}}
          </div>
        </div>
        <div class="col-md-3">
          <label>MRP</label>
          <div>
            {{item.sku_packing_sections?.mrp | replaceCommas}}
          </div>
        </div>
        <div class="col-md-3">
          <label>Product Package Type</label>
          <div>
            {{item.sku_packing_sections?.product_package_type}}
          </div>
        </div>
        <div class="col-md-3">
          <label>uom</label>
          <div>
            {{item.sku_packing_sections?.uom}}
          </div>
        </div>
        <div class="col-md-3">
          <label>upc</label>
          <div>
            {{item.sku_packing_sections?.upc}}
          </div>
        </div>
      </div>
      }

      @if (item?.sku_tax_details) {
      <h4>SKU Tax Details</h4>
      <div class="row">
        <div class="col-md-3">
          <label>Countries</label>
          <div>
            {{item?.sku_tax_details?.countires_name}}
          </div>
        </div>
        <div class="col-md-3">
          <label>HSN Code</label>
          <div>
            {{item?.sku_tax_details?.hsn_code}}
          </div>
        </div>
        <div class="col-md-3">
          <label>Tax Categories</label>
          <div>
            {{item?.sku_tax_details?.tax_categories_name}}
          </div>
        </div>
        <div class="col-md-3">
          <label>Tax Percentage</label>
          <div>
            {{item?.sku_tax_details?.tax_percentage}}
          </div>
        </div>
      </div>
      }
      <!-- </div> -->
      <hr>
      }
    </div>
  </mat-card>
  <div class="row my-3">
    <div class="col-md-12 text-center">
      <button class="mx-2" type="button" 
      mat-raised-button color="primary" 
      (click)="prev()">
        Edit
      </button>
      <button (click)="publishProduct()" [disabled]="!previewDetail?.skus || previewDetail?.skus?.length==0" class="mx-2"
        mat-raised-button color="primary">
        {{this.authService.isVendor()?'Send for approval':'Publish'}}

      </button>
    </div>
  </div>
</div>