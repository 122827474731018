import { Injectable } from '@angular/core';
import { Observable, Subject, lastValueFrom, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class VendorGeoMappingService {
  baseServiceUrl: string = `${environment.coreServiceUrl}`;
  geoServiceUrl: string = `${environment.geoService}`;
  fileServicesApiUrl: string = `${environment.fileUploadBaseUrl}`;
  constructor(public http: HttpClient) {}

  async getVendors(mobileNumber: number): Promise<any> {
    const body = await this.mapToBody(mobileNumber);
    console.log('body', body);

    const res = await lastValueFrom(
      this.http.post<any>(
        `${this.baseServiceUrl}/api/vendor/get_by_filters`,
        body
      )
    );

    if (res && res.data) {
      console.log('vendors list', res.data.rows);

      return res.data.rows;
    }
  }
  async mapToBody(mobileNumber: number) {
    return {
      id: '',
      name: '',
      mobileNumber: mobileNumber.toString(),
      city: '',
      pageNumber: 1,
      pageSize: 10,
    };
  }

  async getMerchantById(id: string): Promise<any> {
    const res = await lastValueFrom(
      this.http.get<any>(`${this.baseServiceUrl}/api/vendor/${id}`)
    );

    return res.data;
  }
  async getLocations(merchantId: string) {
    try {
      const res = await lastValueFrom(
        this.http.get<any>(`${this.geoServiceUrl}/vendor/${merchantId}/lcm`)
      );

      let locationsDetails = res.data || [];
      return this.mapLocations(locationsDetails);
    } catch (error) {
      throw error;
    }
  }

  async getMerchantId(merchantRefId: any) {
    try {
      const res = await lastValueFrom(
        this.http.get<any>(`${this.geoServiceUrl}/vendor/vid/${merchantRefId}/`)
      );

      let merchantId: any = res.data.vendorId;

      return merchantId;
    } catch (error) {
      throw error;
    }
  }

  async locationGeoJson(locationId: any) {
    const merchantId = await lastValueFrom(
      this.http.get<any>(
        `${this.geoServiceUrl}/location/${locationId}/download`
      )
    );
    return merchantId;
  }

  async geoJsonByVendor(merchantId: any) {
    const geoJson = await lastValueFrom(
      this.http.get<any>(
        `${this.geoServiceUrl}/location/download/${merchantId}`
      )
    );

    return geoJson;
  }

  async saveGeoJson(merchantId: any, geoJson: any) {
    try {
      const res = await lastValueFrom(
        this.http.post<any>(
          `${this.geoServiceUrl}/vendor/${merchantId}/upload`,
          geoJson
        )
      );

      return res;
    } catch (error) {
      throw error;
    }
  }

  async getCompanies(vendorId: string, mappedCompanies: string[] = []) {
    console.log('mappedCompanies ::: ', mappedCompanies);
    const pageNumber = 1;
    const pageSize = 500;
    const res = await lastValueFrom(
      this.http.get<any>(
        `${this.baseServiceUrl}/api/vendor_company/${vendorId}?page_number=${pageNumber}&page_size=${pageSize}`
      )
    );
    console.log('res.data.rows ::: ', res.data.rows);

    return res.data.rows
      .map((company: any) => {
        return {
          ...company,
          isSelected: false,
        };
      })
      .filter((c: any) => !(mappedCompanies.includes(c.company_id)));
  }

  async mapToCompanyBody(vendorId: any) {
    return {
      vendorId: vendorId,
      pageNumber: 1,
      pageSize: 100,
    };
  }

  private mapLocations(locations: any) {
    let formattedLocations: Array<any> = [];
    for (const location of locations) {
      if (location.locationName != '') {
        let locationName: string = (location && location.locationName) || '',
          locationId: number = (location && location.locationId) || 0,
          companyNames: Array<any> = [],
          companyIds: Array<any> = [];
        companyNames.push(
          location.companies.map(
            (data: any) => (data && data.companyName) || ''
          )
        );
        companyIds.push(
          location.companies.map(
            (data: any) => (data && data.companyRefId) || ''
          )
        );
        let reqObj = {
          locationId: locationId,
          locationName: locationName,
          companyNames: companyNames,
          companyIds: companyIds,
          strCompanies: JSON.stringify(companyNames),
        };
        formattedLocations.push(reqObj);
      }
    }
    return formattedLocations || [];
  }

  async mapCompanies(companiesList: any) {
    const res = await lastValueFrom(
      this.http.post<any>(
        `${this.geoServiceUrl}/location/map_companies`,
        companiesList
      )
    );
    return res;
  }

  async unmapCompanies(companiesList: any) {
    try {
      const res = await lastValueFrom(
        this.http.delete<any>(`${this.geoServiceUrl}/location/map_companies`, {
          body: companiesList,
        })
      );
      return res;
    } catch (error) {
      throw error;
    }
  }
  async updateLocation(locationId: any, vendorId: any, geoJson: any) {
    try {
      const res = await lastValueFrom(
        this.http.post<any>(
          `${this.geoServiceUrl}/location/${vendorId}/${locationId}`,
          geoJson
        )
      );

      return res;
    } catch (error) {
      throw error;
    }
  }
}
