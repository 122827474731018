import { Component } from '@angular/core';
import { PlanningEntity } from '../../models/trip.model';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { FormBuilder } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';

import Swal from 'sweetalert2';
import { AppLoaderService } from '../../../../../shared/app-loader/app-loader.service';
import { AuthService } from '../../../../../services/auth.service';
import {
  TripStatus,
  PointMode,
  PointType,
  HopStatus,
  TripSource,
} from '../../../../../enums/enums';
import { TripService } from '../../services/trip.service';
import { takeUntil } from 'rxjs';
import { TripDataService } from '../../services/trip-data.service';
import { BaseListComponent } from '../../../../../shared/core/base.list.component';
import { PageId } from '../../../../../constants/enums';
import { AppStateService } from '../../../../../services/appstate.service';
import { MappedTrip } from '../../../../../entities/trip-info';
import { DB_HOP } from '../../../../../entities/hop-db.model';
import { MoveHopComponent } from '../hop-details/move-hop/move-hop.component';
import { MatDialog } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-hop-list',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    RouterLink,
    MatIconModule,
    CommonModule,
    MatSidenavModule,
    MatCheckboxModule,
  ],
  templateUrl: './hop-list.component.html',
  styleUrls: ['./hop-list.component.scss'],
})
export class HopListComponent extends BaseListComponent {
  dataSource: DB_HOP[] = [];
  tripId!: string;
  currentTrip: MappedTrip | null = null;
  selectAllCheckbox: boolean = false;
  selectedHopes: any[] = [];
  checkboxStates: { [key: string]: boolean } = {};
  displayedColumns = [
    'checkbox',
    'hope',
    'type',
    'destinationName',
    'number',
    'address',
    'tonnage',
    'kms',
    'status',
    'locationLink',
    'action',
  ];
  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    public readonly tripService: TripService,
    private loader: AppLoaderService,
    private appState: AppStateService,
    private dialog: MatDialog
  ) {
    super(auth, router, route, PageId.logistics_trips);
    this.appState.selectedTrip$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((trip) => {
        this.currentTrip = trip;
      });
  }

  override async ngOnInit() {
    super.ngOnInit();
    this.route.params
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (params) => {
        this.tripId = params['id'];
        if (this.tripId) {
          await this.getTripHopes(this.tripId);
        }
      });

    if (!this.currentTrip) {
      const localStorageTrip: string | null =
        localStorage.getItem('tripDetails');
      const trip = localStorageTrip && JSON.parse(localStorageTrip);
      this.appState.setSelectedTrip(trip);
      this.currentTrip = trip;
    }
  }
  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }
  getStatusName(status: number | string): string {
    return typeof status === 'number' ? HopStatus[status] : status;
  }
  getPlanningStatusText(value: number): string {
    switch (value) {
      case HopStatus.NotStarted:
        return 'Not Started';
      case HopStatus.Completed:
        return 'Completed';
      case HopStatus.None:
        return 'None';
      default:
        return 'Unknown Status';
    }
  }

  async getTripHopes(id: string) {
    try {
      // this.loader.open();
      const res: DB_HOP[] = await this.tripService.getTripHope(id);
      this.dataSource =
        this.currentTrip?.source === TripSource.Vendor
          ? res.filter((h) => h.point_type === PointType.WAREHOUSE)
          : res;
      console.log('data source is the', this.dataSource);

      // this.loader.close();
    } catch (error) {
      Swal.fire(
        'Warning',
        'Failed to get hops! please try again later',
        'warning'
      );
    }
  }

  getPointModeLabel(mode: number): string {
    const hopModeLabel = {
      [PointMode.PICKUP]: 'Pickup',
      [PointMode.DELIVERY]: 'Delivery',
      [PointMode.RETURN]: 'Return',
      [PointMode.NONE]: 'None',
    };
    return hopModeLabel[mode as PointMode];
  }

  getConcatenateLabel(mode: number): string {
    const hopModeLabel = {
      [PointMode.PICKUP]: 'From',
      [PointMode.DELIVERY]: 'To',
      [PointMode.RETURN]: 'To',
      [PointMode.NONE]: 'None',
    };
    return hopModeLabel[mode as PointMode];
  }

  getPointTypeLabel(mode: number): string {
    const hopModeLabel = {
      [PointType.VENDOR]: 'Seller',
      [PointType.CUSTOMER]: 'Retailer',
      [PointType.WAREHOUSE]: 'WareHouse',
      [PointType.NONE]: 'None',
    };
    return hopModeLabel[mode as PointType];
  }

  async delete(hop: DB_HOP) {
    if (
      this.currentTrip?.status == TripStatus.InProgress ||
      hop.point_type == PointType.WAREHOUSE
    ) {
      this.showMessage(
        "You can't delete the hop that is part of an in-progress trip / Warehouse hop",
        'warning'
      );

      return;
    }

    const result = await Swal.fire({
      title: 'Are You Sure You Want to Delete ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    });

    if (!result.isConfirmed) {
      //add alert
      return;
    }
    const res = await this.tripService.deleteHop(hop.hop_id);
    await this.getTripHopes(this.tripId); // TODO don't have to fetch data again
    if (res.success) {
      Swal.fire(
        'Success',
        hop.point_business_name + ' Deleted successfully',
        'success'
      );
    } else {
      Swal.fire('Warning!', 'Failed to move hop', 'warning');
    }
  }
  moveHop() {
    const dialogRef = this.dialog.open(MoveHopComponent, {
      width: '30%',
      height: '40%',
      data: {
        title: 'Move Hop',
        tripId: this.tripId || '',
      },
    });

    dialogRef.afterClosed().subscribe(async (trip: any) => {
      const tripId = trip.trip.tripId;
      const hopIds = this.selectedHopes;
      const body = this.mapToMoveHops(tripId, hopIds);
      console.log('body is the ', body);
      const res = await this.tripService.moveHopBulk(body);
      await this.getTripHopes(this.tripId);
      if (res.success) {
        Swal.fire(
          'Success',
          'Hops moved to ' + tripId + ' successfully',
          'success'
        );
      } else {
        Swal.fire('Warning!', 'Failed to move hops', 'warning');
      }
    });
  }
  mapToMoveHops(tripId: string, hopIds: any) {
    return {
      tripId: tripId,
      hopIds: hopIds,
    };
  }

  toggleSelectAll() {
    this.selectAllCheckbox = !this.selectAllCheckbox;
    this.selectedHopes = []; // Reset selectedHopes array

    for (const row of this.dataSource) {
      if (row.point_type !== 3) {
        this.checkboxStates[row.hop_id] = this.selectAllCheckbox;

        if (this.selectAllCheckbox) {
          this.selectedHopes.push(row.hop_id);
        }
      } else {
        this.checkboxStates[row.hop_id] = false; // Ensure hidden checkboxes are not selected
      }
    }

    console.log('selected hops are', this.selectedHopes);
  }

  toggleCheckbox(hop: any) {
    this.checkboxStates[hop.hop_id] = !this.checkboxStates[hop.hop_id];

    if (this.checkboxStates[hop.hop_id]) {
      this.selectedHopes.push(hop.hop_id);
      hop.isSelected = true;
    } else {
      const selectedIndex = this.selectedHopes.findIndex(
        (sp) => sp === hop.hop_id
      );
      if (selectedIndex !== -1) {
        this.selectedHopes.splice(selectedIndex, 1);
        hop.isSelected = false;
      }
    }
    const allSelected = this.dataSource.every((item: any) => item.isSelected);
    this.selectAllCheckbox = allSelected;
    console.log('selected hop is the', this.selectedHopes);
  }
  navigateToHop(row: DB_HOP) {
    this.appState.setSelectedHop(row);
    this.router.navigateByUrl(`/dashboard/logistics/hop-details/${row.hop_id}`);
  }
  formatDate(dateString: string): string {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  }
  async navigateToMap(row: DB_HOP) {
    console.log('row is the ', row);
    const latitude = row.point_lat;
    const longitude = row.point_lng;
    await this.redirectToGoogleMaps(latitude, longitude);
  }
  redirectToGoogleMaps(latitude: any, longitude: any) {
    var googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;

    // Redirect to the Google Maps URL
    window.open(googleMapsUrl, '_blank');
  }
}
