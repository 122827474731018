@if(hasView || hasBMSAdmin){
<div class="container-fluid p-2">
  <mat-card class="p-3">
    <form [formGroup]="filterForm">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-8 mx-auto text-center">
            <h2 class="title">Find your product in Qwipo's catalog</h2>
          </div>
          <div class="col-md-8 mx-auto text-center">
            <div class="d-flex justify-content-center">
              <div class="search-box">
                <input type="text" class="search-input" placeholder="Example: Britannia Treat, Cow HMT, Bambino ver"
                  formControlName="name">
                <mat-icon class="mb-0 search-icon">search</mat-icon>

              </div>

              <button type="button" class="btn btn-search ms-3" (click)="reset()">
                Reset
              </button>
            </div>
          </div>
         
        </div>
        <div class="row my-3">
          @if (isDataAvailable()) {
          <div class="col-md-6">
            <!--           <h3 class="title">Find your product in Qwipo's catalog</h3>
 -->
          </div>
          <div class="col-md-6 text-end">
            <!--  <button mat-raised-button (click)="openFilter()" color="primary" class="me-2 filter-btn">
            <div class="icon-dot"
              [ngClass]="{'active-filter': !queryForm.value.name && !queryForm.value.categories_id && !queryForm.value.companies_id && !queryForm.value.brands_id}">
            </div>
            <mat-icon class="me-0">filter_list</mat-icon>
          </button> -->
            <!-- @if(permission?.create){
          <button mat-raised-button [routerLink]="['/dashboard/product/add']" routerLinkActive="router-link-active"
            color="primary">
            <mat-icon>add</mat-icon> Add
          </button>
          } -->
            <!-- @if(permission?.create){
                <button
                mat-raised-button
                [routerLink]="['/dashboard/product/add']"
                routerLinkActive="router-link-active"
                color="primary"
              >
                Add Product
              </button>
              } -->
          </div>
          }
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col-md-12 table-wrapper">
        @if (isDataAvailable()) {
        <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)"
          class="mat-elevation-z8">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let element">{{ element.attributes.name }}</td>
          </ng-container>
          <ng-container matColumnDef="brands_id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Brand Name</th>
            <td mat-cell *matCellDef="let element">{{ element.attributes?.brands_name }}</td>
          </ng-container>
          <ng-container matColumnDef="companies_id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Companies Name</th>
            <td mat-cell *matCellDef="let element">{{ element.attributes?.companies_name }}</td>
          </ng-container>
          <ng-container matColumnDef="categories_id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Categories Name</th>
            <td mat-cell *matCellDef="let element">{{ element.attributes?.categories_name }}</td>
          </ng-container>
          <ng-container matColumnDef="sku_count">
            <th mat-header-cell *matHeaderCellDef>SKU Count</th>
            <td mat-cell *matCellDef="let element">{{ element.attributes?.skus ? element.attributes?.skus.length : 0}}
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let element">
              <div class="d-flex ">
                @if(this.hasBMSAdmin){
                <button mat-raised-button class="ms-2" color="primary"
                  [routerLink]="['/dashboard/product/manage-master-sku',element.id]">
                  <mat-icon class="me-0">keyboard_arrow_right</mat-icon>
                </button>
                }
                @if(this.hasSellerAdmin){
                <button mat-raised-button class="ms-2" color="primary"
                  [routerLink]="['/dashboard/product/manage-vendor-sku',element.id]">
                  <mat-icon class="me-0">keyboard_arrow_right</mat-icon>

                </button>
                }

              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        </table>}
        <div class="d-flex justify-content-center my-2">
          @if (loading) {
          <mat-spinner [diameter]="30"></mat-spinner>
          }
        </div>
        <div [ngClass]="{'d-none': !isDataAvailable()}">
          <mat-paginator #paginator [length]="meta?.totalCount" [pageSize]="filterForm?.value?.limit"
            [pageSizeOptions]="pageSizeOptions" (page)="onPaginationChange($event)" aria-label="Select page">
          </mat-paginator>
        </div>
      </div>
      <div class="d-flex justify-content-center my-2">
        @if (!isDataAvailable() && !loading && searchTextLength>2) {
        <div class="text-center">
          <p class="no-data-found-text my-2">Product you are searching is not available. Please add new SKU.</p>
          <br>
          <button mat-raised-button [routerLink]="['/dashboard/product/add']" routerLinkActive="router-link-active"
            color="primary" *ngIf="hasCreate || hasBMSAdmin">
            <mat-icon>add</mat-icon> Add New
          </button>
        </div>
        }
      </div>
    </div>
  </mat-card>
</div>
}

