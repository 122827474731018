<ng-container *ngIf="hasView">
  <div class="heading-block">
    <div class="row">
      <div class="col-md-6">
        <h2 class="card-title m-3">Deep Links</h2>
      </div>
      <div class="col-md-6 text-right">
        @if(hasCreate){
        <button mat-raised-button color="primary" [routerLink]="['/dashboard/deep-links/deep-links-add']"
          class="topButtonSectionbtn">
          Add Deep Link
        </button>
        }
      </div>
    </div>
  </div>
  <div class="table-block">
    <div class="m-3 flex flex-col md:flex-row justify-between">
      <mat-card class="p-0">
        <mat-card-content class="p-0">
          <mat-sidenav-container fxFlex="0 1 auto">
            <mat-sidenav-content>
              <div class="table-container">
                <mat-table [dataSource]="dataSource" matSort>
                  <ng-container matColumnDef="deepLinkType">
                    <mat-header-cell *matHeaderCellDef>
                      Deep link Type
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="deepLinkType">
                      {{row.data.Name}}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="date">
                    <mat-header-cell *matHeaderCellDef>
                      Created Date
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="title">
                      {{getDate(row.createdAt)}}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="title">
                    <mat-header-cell *matHeaderCellDef>
                      Title
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="title">
                      {{ row.title }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="link">
                    <mat-header-cell *matHeaderCellDef>
                      Link
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="link">
                      {{ row.link }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef>
                      Description
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="description">
                      {{ row.description }}
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                </mat-table>
              </div>
            </mat-sidenav-content>
          </mat-sidenav-container>
          <mat-paginator [length]="totalDeeplink" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="onPageChange($event)"></mat-paginator>
        </mat-card-content>
      </mat-card>
    </div>

  </div>


</ng-container>
<ng-container *ngIf="!hasView">
  <p class="text-center">You dont have permission , Please contact administrator</p>
</ng-container>