import { Component, Input } from '@angular/core';
import { CategoryService } from '../../pages/category/services/category.service';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Observable, map, tap } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-category-selector',
  standalone: true,
  imports: [MatButtonModule, MatCardModule,
    MatSelectModule, MatFormFieldModule, ReactiveFormsModule, CommonModule, FormsModule],
  templateUrl: './category-selector.component.html',
  styleUrl: './category-selector.component.scss'
})
export class CategorySelectorComponent {
  @Input() control: any;
  categoryControl = new FormControl()
  showDropdown = true
  categoryMap: Map<string, any> = new Map(); // Map to store category names
  levelFirst: any;
  levelFirstFiltered: any;
  levelSecond: any;
  levelSecondFiltered: any;
  rootC: any;
  selectedType: any = 'root';
  searchCategoryLevel1: string = '';
  searchCategoryLevel2: string = '';
  constructor(private categoryService: CategoryService) { }

  ngOnInit() {
    this.fetchCategories();
    this.control.valueChanges.subscribe((value: any) => {
      if (value) {
        this.categoryService.getSingle(value).then((res: any) => {
          if (res?.data?.length == 1) {
            this.categoryMap.set(value, res.data[0]);
          }
        })
      }
    })
    this.categoryControl.valueChanges.subscribe((value: any) => {
      if (value) {
        this.fetchCategories(value);
      }
    })

  }

  getLevelFirst(id: any) {
    this.selectedType = 'root'
    this.levelFirst = []
    this.levelFirstFiltered = []
    this.levelSecond = []
    this.levelSecondFiltered = []
    this.control.patchValue(null)
    this.categoryControl.patchValue(id)

    //this.fetchCategories(id)
  }
  getLevelSecond(id: any) {
    this.selectedType = 'category'
    this.levelSecond = []
    this.levelSecondFiltered = []
    this.control.patchValue(null)
    this.categoryControl.patchValue(id)
    // this.fetchCategories(id)
  }
  getLevelThird(id: any) {
    this.selectedType = 'subcategory'
    this.control.patchValue(null)
    this.categoryControl.patchValue(id)
    //this.fetchCategories(id)
  }
  checkActiveCategory(id: any, type: any) {
    let value;
    if (type == 'category' && this.levelFirst.length > 0 && this.selectedType == 'category') {
      value = this.levelFirst.find((obj: any) => obj.id === this.categoryControl.value);
      if (value?.attributes?.categories_id_path.includes(id)) {
        return true
      }
      else {
        return false
      }
    }
    else {
      return false
    }
  }
  checkActive(id: any, type: any) {
    let value;
    if (type == 'root' && this.categoryControl.value && this.selectedType == 'root') {
      value = this.rootC.find((obj: any) => obj.id === this.categoryControl.value);
      if (value.attributes.categories_id_path.includes(id)) {
        return true
      }
      else {
        return false
      }
    }
    else if (this.levelFirst?.length > 0 && this.selectedType == 'category') {
      value = this.levelFirst.find((obj: any) => obj.id === this.categoryControl.value);
      if (value?.attributes?.categories_id_path.includes(id)) {
        return true
      }
      else {
        return false
      }
    }
    else if (this.levelSecond?.length > 0 && this.selectedType == 'subcategory') {
      value = this.levelSecond.find((obj: any) => obj.id === this.categoryControl.value);
      if (value.attributes.categories_id_path.includes(id)) {
        return true
      }
      else {
        return false
      }
    }
    else {
      return false
    }
  }

  fetchCategories(parentCategoryId = null) {
    // parentCategoryId = this.categoryControl.value
    const queryObj = parentCategoryId ? { parent_categories_id: parentCategoryId, sort_by: "display_order", sort_order: "asc" } : { root: true, sort_by: "display_order", sort_order: "asc" };
    this.categoryService.getAll(queryObj).pipe(
      map((response: any) => {
        const categories = response.data;
        categories.forEach((category: any) => {
          this.categoryMap.set(category.id, category);
        });
        return categories;
      })
    )
      .subscribe(res => {
        if (parentCategoryId) {
          if (this.selectedType == 'root') {
            this.levelFirst = res;
            this.levelFirstFiltered = res;
            if (res.length == 0) {
              this.control.patchValue(this.categoryControl.value)
            }
          }
          else if (this.selectedType == 'category') {
            this.levelSecond = res;
            this.levelSecondFiltered = res;
            if (res.length == 0) {
              this.control.patchValue(this.categoryControl.value)
            }
          }
          else if (this.selectedType == 'subcategory') {
            if (res.length == 0) {
              this.control.patchValue(this.categoryControl.value)
            }
          }
        }
        if (!parentCategoryId) {
          this.rootC = res;
          this.getLevelFirst(this.rootC[0].id)
        }
        if (res.length == 0 && parentCategoryId) {
          this.control.patchValue(parentCategoryId)
        }
        //this.categoryControl.patchValue(null,{ emitEvent: false })
      })
  }


  changeCategoryLevelFirst(event: Event) {
    const searchText = (event.target as HTMLInputElement).value;
    if (searchText && searchText.length > 0) {
      console.log('Input value changed:', searchText);
      this.levelFirstFiltered = this.levelFirst.filter(
        (category: any) => category.attributes.name.toLowerCase().includes(searchText.toLowerCase())
      );
    } else {
      this.levelFirstFiltered = this.levelFirst;
    }
  }
  changeCategoryLevelSecond(event: Event) {
    const searchText = (event.target as HTMLInputElement).value;
    if (searchText && searchText.length > 0) {
      console.log('Input value changed:', searchText);
      this.levelSecondFiltered = this.levelSecond.filter(
        (category: any) => category.attributes.name.toLowerCase().includes(searchText.toLowerCase())
      );
    } else {
      this.levelSecondFiltered = this.levelSecond;
    }
  }

  getCategoryName(categoryId: string): string {
    return this.categoryMap.get(categoryId)?.attributes?.name || 'Unknown Category';
  }

  getBrowsePath(categoryId: string) {
    return this.categoryMap.get(categoryId)?.attributes?.browser_path || 'Unknown Path';
  }




}
