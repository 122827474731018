import { Injectable } from '@angular/core';
import { FirebaseDbService } from '../../../services/firebase-db.service';
import { HttpClient } from '@angular/common/http';
import { FirestoreService } from '../../../services/firestore.service';
import { DB_PATH } from '../../../constants/db-path';
import { environment } from '../../../../environments/environment';
import axios from 'axios';
import { WhereFilterOp } from 'firebase/firestore';
import { lastValueFrom } from 'rxjs';

interface BulkImportEntity {
  jobId: string;
  vendorEmail: string;
  status: string;
  vendorId: string;
  vendorName: string;
  createdDate: string;
  jobType: string;
  fileName: string;
  jobName: string;
  fileUrl: string;
  timeStamp:number;
}
@Injectable({
    providedIn: 'root',
})



export class BulkImportService {
    constructor(
        public firestoreService: FirestoreService,
        private firebaseDbService: FirebaseDbService,
        private http: HttpClient
    ) { }

    mapResponse(response: BulkImportEntity[]) {
        return response.map((data: BulkImportEntity) => {

            return {
                jobId: data.jobId,
                vendorEmail: data.vendorEmail,
                status: data.status,
                vendorId: data.vendorId,
                vendorName: data.vendorName,
                createdDate: data.createdDate,
                jobType: data.jobType,
                fileName: data.fileName,
                jobName: data.jobName,
                fileUrl: data.fileUrl,
                timeStamp:data?.timeStamp || 1577015520,//gave some random date id for not have timesTamp attribute value
            };
        }).sort((a,b)=>b.timeStamp-a.timeStamp);


    }

    async getAllBulkImportData(id: string): Promise<BulkImportEntity[]> {
        const filters = [
            { fieldName: 'vendorId', fieldValue: id, operator: '==' as WhereFilterOp }
        ];

        const response = await this.firestoreService.queryCollectionByFilters(DB_PATH.BULK_IMPORT, filters);

        const result = this.mapResponse(response);
        return result;
    }

    async downloadPriceAndStockExcel(id: string) {
        const url = `${environment.coreServiceUrl}/api/pricing/stock/spreadsheet/${id}`;

        const response = await lastValueFrom(this.http.get(url, {responseType: 'blob'}));

        return response
    }

    async downloadPricingExcel(id: string) {
        const url = `${environment.coreServiceUrl}/api/pricing/spreadsheet/${id}`;

        const response = await lastValueFrom(this.http.get(url, {responseType: 'blob'}));

        return response
    }

    async downloadStockExcel(id: string) {
        const url = `${environment.coreServiceUrl}/api/inventory/spreadsheet/${id}`;

        const response = await lastValueFrom(this.http.get(url, {responseType: 'blob'}));

        return response
    }

}
