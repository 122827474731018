<div class="row">
  <div class="col-md-4 mt-2">
    <mat-card class="p-3 custom-cr">
      <h3 class="mb-3 text-primary">Super Category</h3>
      <ul class="fixedheight3rem">
        @for (category of rootC; track $index;) {
        <li (click)="getLevelFirst(category.id)" [ngClass]="{'active': checkActive(category.id,'root')}">
          {{ category.attributes.name }}
        </li>
        }
      </ul>
    </mat-card>
  </div>
  <div class="col-md-4 mt-2">
    @if(levelFirst?.length > 0){
    <mat-card class="p-3 custom-cr">
      <h3 class="mb-3 text-primary">
        Category
      </h3>
      <input type="text" class="category-search" (keyup)="changeCategoryLevelFirst($event)"
        placeholder="Search Category" />
      <ul class="fixedheight3rem">
        @for (category of levelFirstFiltered ; track $index) {
        <li (click)="getLevelSecond(category.id)" [ngClass]="{'active': checkActive(category.id,'category')}">
          {{ category.attributes.name }}
        </li>
        }
      </ul>
    </mat-card>
    }
  </div>
  <div class="col-md-4 mt-2">
    @if(levelSecond?.length > 0){
    <mat-card class="p-3 custom-cr">
      <h3 class="mb-3 text-primary"> Sub Category</h3>
      <input type="text" class="category-search" (keyup)="changeCategoryLevelSecond($event)"
        placeholder="Search Sub Category" />
      <ul class="fixedheight3rem">
        @for (category of levelSecondFiltered; track $index) {
        <li (click)="getLevelThird(category.id)" [ngClass]="{'active': checkActive(category.id,'subcategory')}">
          {{ category.attributes.name }}
        </li>
        }
      </ul>
    </mat-card>
    }
  </div>
  <!-- </div> -->
