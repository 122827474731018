@if(hasView){
<ng-container>
  <div class="container">
    <div class="row" *ngIf="dataSource">
      <div class="row m-2">
        <div class="col-md-6">
          <!--<h2 class="card-title m-3">Margin works</h2>-->
        </div>

        <div class="col-md-6 btndev">
          <!-- *ngIf="dataSource.value.status == 'Completed'"  -->
          @if(currentTrip?.status === 1 && currentHop?.point_type !=3 ){
          <button mat-raised-button type="button" class="m-2" color="primary" (click)="moveHop()">
            Move Hop
          </button>
          }

          <!-- If Trip Status is Not start or in progress and trip source should be vendor then only complete button should available  -->
          @if((currentTrip?.status == 1 || currentTrip?.status == 2) && currentTrip?.source == 3){
          <button mat-raised-button type="button" class="m-2" color="primary" (click)="completeHop()">
            Complete
          </button>
          }
        </div>
      </div>
    </div>
    <div class="search-block">
      <form [formGroup]="dataSource">
        <section id="content">
          <div class="card">
            <div class="card-body card-padding">
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Hop Id</mat-label>
                    <input matInput type="string" formControlName="hopId" name="hopId" placeholder="Hop Id"
                      [readonly]="true" />
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Hop Date</mat-label>
                    <input matInput type="string" formControlName="hopDate" name="hopDate" placeholder="Hop Date"
                      [readonly]="true" />
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Business Name</mat-label>
                    <input matInput type="string" formControlName="pointBusinessName" name="pointBusinessName"
                      placeholder="Business Name" [readonly]="true" />
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Mobile Number</mat-label>
                    <input matInput type="string" formControlName="pointMobileNumber" name="pointMobileNumber"
                      placeholder="Mobile Number" [readonly]="true" />
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Status</mat-label>
                    <input matInput type="string" formControlName="status" name="status" placeholder="Status"
                      [readonly]="true" />
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
    <div class="search-block">
      <section id="content">
        <div class="card">
          <div class="card-body card-padding">
            <div class="row">
              <div class="col-md-6 col-xs-12">
                <div class="card">
                  <div class="card-body card-padding" style="height: 186px;">

                    <div class="col-md-3" style="font-size: 21px; font-weight: bold">
                      Hop Details
                    </div>
                    <div class="row1 pt-20">
                      <div>{{ getPointName(dataSource.value.pointMode) }}</div>
                      <div class="pl-2">: 1</div>
                    </div>
                    <div class="row1 pt-10">
                      <div>Weight</div>
                      <div class="pl-2">: {{ dataSource.value.totalWeight }} kgs</div>
                    </div>
                    <div class="row1 pt-10">
                      <div>Products</div>
                      <div class="pl-2">: {{ dataSource.value.totalHopItems }}</div>
                    </div>
                    <div class="row1 pt-10">
                      <ng-container *ngIf="dataSource.value.pointType == 3 && currentTrip?.source!=3">
                        <div>OTP</div>
                        <div class="pl-2">: {{ dataSource.value.otp }}</div>
                      </ng-container>
                    </div>
                  </div>
                </div>

              </div>
              <div class="col-md-6 col-xs-12">
                <div class="card">
                  <div class="card-body card-padding" style="height: 186px;">

                    <div class="col-md-3" style="font-size: 21px; font-weight: bold">
                      Collections
                    </div>
                    <div class="row1 pt-20" *ngIf="dataSource.value.summary?.grossProductAmount?.isVisible">
                      <div>Gross Amount</div>
                      <div>: {{ (dataSource.value.summary.grossProductAmount.amount || 0) | replaceCommas }}
                      </div>
                    </div>
                    <div class="row1 pt-10" *ngIf="dataSource.value.summary?.returnProductsAmount?.isVisible">

                      <div>Customer Returns</div>
                      <div>: {{(dataSource.value.summary.returnProductsAmount.amount ||0 ) |replaceCommas}}</div>
                    </div>
                    <div class="row1 pt-10" *ngIf="dataSource.value.summary?.customerCod?.isVisible">
                      <div>Customer COD Collections</div>
                      <div>: {{ (dataSource.value.summary.customerCod.amount || 0) | replaceCommas }}
                      </div>
                    </div>

                    <div class="row1 pt-10" *ngIf="dataSource.value.summary?.customerOnlinePayment?.isVisible">

                      <div>Customer Online Payments</div>
                      <div>: {{(dataSource.value.summary.customerOnlinePayment.amount ||0 ) |replaceCommas}}</div>
                    </div>

                    <div class="row1 pt-10" *ngIf="dataSource.value.pointType == 2 && dataSource.value.pointMode == 2">

                      <div>Balance</div>
                      <div>: {{( balance ||0 ) |replaceCommas}}</div>
                    </div>

                  </div>
                </div>


                <!-- <div class="card"
                  *ngIf="(dataSource.value.pointMode != 1 && dataSource.value.pointType != 3) || (dataSource.value.pointMode != 3 && dataSource.value.pointType != 3)">
                  <div class="card-body card-padding" style="height: 186px;">

                    <div class="col-md-3" style="font-size: 21px; font-weight: bold">
                      Collections
                    </div>
                    <div class="row1" >
                      <div>Dispatch Value</div>
                      <div>: {{ (dataSource.value.dispatchValue || 0) | replaceCommas }}
                      </div>
                      <div>Cash</div>
                      <div>: {{(dataSource.value.cashCollections ||0 ) |replaceCommas}}</div>
                    </div>
                    <div class="row1" >
                      <div>Online</div>
                      <div>: {{(dataSource.value.onlineCollections || 0) | replaceCommas}}</div>
                      <div>Returns</div>
                      <div>: {{dataSource.value.returnsCollections}}</div>
                    </div>
                    <div class="row1" >
                      <div>Balance</div>
                      <div>: {{(balance || 0) | replaceCommas}}</div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</ng-container>
} @else {
<ng-container>
  <p class="text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
}