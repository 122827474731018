<section id="content" *ngIf="couponDetails">
  <div class="container">
    <div class="card">
      <div class="heading-block">
        <div class="row">
          <div class="col-md-6">
            <mat-icon class="backicon" [routerLink]="['/dashboard/coupon']">arrow_back</mat-icon>
            <span class="header">
              <h2 class="card-title m-2">Coupon Details</h2>
            </span>
          </div>
          <div class="col-md-6 text-right"></div>
        </div>
      </div>
      <div class="card-body card-padding">
        <div class="row">
          <div class="col-sm-12">
            <div class="dl-horizontal">
              <label class="col-sm-3 heading">Coupon Name :</label>
              <p>{{couponDetails.name}}</p>
            </div>

            <div class="dl-horizontal">
              <label class="col-sm-3 heading">Scheme Display Name :</label>
              <p>{{couponDetails.displayName}}</p>
            </div>
            <div class="dl-horizontal">
              <label class="col-sm-3 heading">Description :</label>
              <p style="width: calc(100% - 300px);">{{couponDetails.description}}</p>
            </div>


            <div class="dl-horizontal">
              <label class="col-sm-3 heading">Minimum order value :</label>
              <p>Rs. {{couponDetails.minValue}}</p>
            </div>
            <div class="dl-horizontal">
              <label class="col-sm-3 heading">Maximum order value :</label>
              <p>Rs. {{couponDetails.maxValue}}</p>
            </div>
            <div class="dl-horizontal">
              <label class="col-sm-3 heading">Discount Percent :</label>
              <p>{{couponDetails.discountPercent}} %</p>
            </div>
            <div class="dl-horizontal">
              <label class="col-sm-3 heading">Status :</label>
              <p>{{couponDetails.active ? 'Active':'Inactive'}}</p>
            </div>
            <div class="dl-horizontal">
              <label class="col-sm-3 heading">Start Date :</label>
              <p>{{couponDetails.startDate}} </p>
            </div>
            <div class="dl-horizontal">
              <label class="col-sm-3 heading">End Date :</label>
              <p>{{couponDetails.endDate}} </p>
            </div>
            <div class="dl-horizontal">
              <label class="col-sm-3 heading">Category :</label>
              <p>
                <label *ngFor="let category of couponDetails.categorySelection;  let i=index">{{(i
                  == 0)?'':', '}}{{category.name}}
                </label>
              </p>
            </div>
            <div class="dl-horizontal">
              <label class="col-sm-3 heading">Business Types :</label>
              <p>
                <label *ngFor="let businessType of couponDetails.businessTypeSelection; let i=index">{{(i
                  == 0)?'':','}} {{businessType.businessType}}

                </label>
              </p>
            </div>

            <div>
              <label class="col-sm-3 heading">Products :</label>
              <div class="productIds" *ngIf="couponDetails.productIds.length > 0">
                <mat-table [dataSource]="couponDetails.productIds" matSort>
                  <!-- <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Product ID
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="id"> {{row.id}} </mat-cell>
                  </ng-container> -->
                  <ng-container matColumnDef="skuId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> SKU ID</mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="skuId"> {{row.skuId}} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="productName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Product Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="productName"> {{row.productName}}
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="displayedColumnsProduct"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumnsProduct;"></mat-row>
                </mat-table>
              </div>
            </div>
            <div>
              <label class="col-sm-3 heading">Customers :</label>
              <div class="productIds" *ngIf="couponDetails.customerIds.length > 0">
                <mat-table [dataSource]="couponDetails.customerIds" matSort>
                  <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Customer ID
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="id"> {{row.id}} </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="businessName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Business Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="businessName"> {{row.businessName}}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="mobileNumber">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Mobile Number
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="mobileNumber"> {{row.mobileNumber}}
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="displayedColumnsCategory"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumnsCategory;"></mat-row>
                </mat-table>
                &nbsp;
              </div>
            </div>
            <!-- <div class="dl-horizontal">
              <label class="col-sm-3 heading">Days Since Last Purchase :</label>
              <p><label>{{couponDetails.lastPurchasedDays}}</label> </p>
            </div> -->
            <!-- <div>
              <label class="col-sm-3 heading">Minimum Category wise Percentage (%) :</label>
              <div class="productIds" *ngIf="couponDetails.minimumCategoryWisePercent.length > 0">
                <mat-table [dataSource]="couponDetails.minimumCategoryWisePercent" matSort>
                  <ng-container matColumnDef="categoryName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Category Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="categoryName"> {{row.categoryName}}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="minimumPercent">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Minimum % </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="minimumPercent">
                      {{row.minimumPercent}} </mat-cell>
                  </ng-container>
                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
              </div>
            </div> -->
          </div>
        </div>
        <br>
        <br>
      </div>
    </div>
  </div>
</section>
