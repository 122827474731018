@if(hasView){
<ng-container>
  <div class="table-block">
    <div class="m-3 flex flex-col md:flex-row justify-between">
      <mat-card class="p-0">
        <mat-card-content class="p-0">
          <mat-sidenav-container fxFlex="0 1 auto">
            <mat-sidenav-content>
              <div class="table-container">
                <mat-table [dataSource]="dataSource" matSort>
                  <ng-container matColumnDef="orderDate">
                    <mat-header-cell *matHeaderCellDef> Order Date </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="orderDate">
                      {{ row.createdDate }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="orderNumber">
                    <mat-header-cell *matHeaderCellDef>
                      Order Number
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="orderNumber">
                      {{ row.orderNumber }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="orderAmount">
                    <mat-header-cell *matHeaderCellDef>
                      Item Amount
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="orderAmount">
                      {{ (row.orderAmount || 0) | replaceCommas }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="orderCouponDiscountAmount">
                    <mat-header-cell *matHeaderCellDef>
                      Discount Amount
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="orderCouponDiscountAmount">
                      {{ (row.orderCouponDiscountAmount || 0) | replaceCommas }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="netAmount">
                    <mat-header-cell *matHeaderCellDef>
                      Net Amount
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="netAmount">
                      {{ ((row.orderAmount -row.orderCouponDiscountAmount)|| 0) | replaceCommas }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="orderStatus">
                    <mat-header-cell *matHeaderCellDef> Order Status </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="orderStatus">
                      {{ row.orderStatusLabel}}
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                </mat-table>
              </div>
            </mat-sidenav-content>
          </mat-sidenav-container>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</ng-container>
} @else {
<ng-container>
  <p class="text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
}
