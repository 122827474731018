import { EditIncentiveSlabComponent } from './pages/incentiveslab/components/edit-incentiveslab/edit-incentiveslab.component';
import { EditMerchantComponent } from './pages/merchant/components/edit-merchant/edit-merchant.component';
import { PricingListComponent } from './pages/pricing/pricing-list/pricing-list.component';
import { Routes } from '@angular/router';
import { DashboardComponent } from './layouts/dashboard/dashboard.component';
import { HomeComponent } from './pages/home/home.component';
import { CompanyComponent } from './pages/company/company.component';
import { CompanyListComponent } from './pages/company/company-list/company-list.component';
import { CompanyAddEditComponent } from './pages/company/company-add-edit/company-add-edit.component';
import { AuthComponent } from './auth/auth.component';
import { BrandComponent } from './pages/brand/brand.component';
import { BrandListComponent } from './pages/brand/brand-list/brand-list.component';
import { BrandAddEditComponent } from './pages/brand/brand-add-edit/brand-add-edit.component';
import { CategoryComponent } from './pages/category/category.component';
import { CategoryListComponent } from './pages/category/category-list/category-list.component';
import { CategoryAddEditComponent } from './pages/category/category-add-edit/category-add-edit.component';
import { VarientTypeComponent } from './pages/varientType/varientType.component';
import { VarientTypeListComponent } from './pages/varientType/varientType-list/varientType-list.component';
import { VarientTypeAddEditComponent } from './pages/varientType/varientType-add-edit/varientType-add-edit.component';
import { VarientValueComponent } from './pages/varientValue/varientValue.component';
import { VarientValueListComponent } from './pages/varientValue/varientValue-list/varientValue-list.component';
import { VarientValueAddEditComponent } from './pages/varientValue/varientValue-add-edit/varientValue-add-edit.component';
import { ProductComponent } from './pages/product/product.component';
import { ProductAddEditComponent } from './pages/product/product-add-edit/product-add-edit.component';
import { DetailsComponent } from './pages/product/product-add-edit/details/details.component';
import { AttributeComponent } from './pages/attribute/attribute.component';
import { AttributeListComponent } from './pages/attribute/attribute-list/attribute-list.component';
import { AttributeAddEditComponent } from './pages/attribute/attribute-add-edit/attribute-add-edit.component';
import { SkuEditComponent } from './pages/product/product-add-edit/skuEdit/skuEdit.component';
import { SkuDetailsComponent } from './pages/product/product-add-edit/skuEdit/skuDetails/skuDetails.component';
import { PublishedProductsComponent } from './pages/product/published-products/published-products.component';
import { PendingApprovalsComponent } from './pages/pending-approvals/pending-approvals.component';
import { VendorApprovalRequestsComponent } from './pages/vendor-approval-requests/vendor-approval-requests.component';
import { AdminNotificationsComponent } from './pages/admin-notifications/admin-notifications.component';
import { VendorNotificationsComponent } from './pages/vendor-notifications/vendor-notifications.component';
import { PreviewProductComponent } from './pages/product/preview-product/preview-product.component';
import { PreviewPublishProductComponent } from './pages/product/preview-publish-product/preview-publish-product.component';
import { AreaMappingComponent } from './pages/geoMapping/area-mapping/area-mapping.component';
import { CompanyMappingComponent } from './pages/geoMapping/company-mapping/company-mapping.component';
import { GeoMappingComponent } from './pages/geoMapping/geo-mapping.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { PricingDetailsComponent } from './pages/pricing/pricing-details/pricing-details.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { VendorSearchComponent } from './pages/settings/vendor-search/vendor-search.component';
import { VendorListComponent } from './pages/settings/vendor-list/vendor-list.component';
import { MarginComponent } from './pages/settings/vendor-list/margin/margin.component';
import { MasterGeoComponent } from './pages/master-geo/master-geo.component';
import { MasterGeoListComponent } from './pages/master-geo/master-geo-list/master-geo-list.component';
import { AddGeoComponent } from './pages/master-geo/add-geo/add-geo.component';
import { ConfigComponent } from './pages/config/config.component';
import { AreaConfigComponent } from './pages/config/area-config/area-config.component';
import { VendorConfigComponent } from './pages/config/vendor-config/vendor-config.component';
import { CouponComponent } from './pages/coupon/coupon.component';
import { CouponListComponent } from './pages/coupon/coupon-list/coupon-list.component';
import { CouponAddComponent } from './pages/coupon/coupon-add/coupon-add.component';
import { CouponDetailsComponent } from './pages/coupon/coupon-details/coupon-details.component';
import { LogisticsComponent } from './pages/logistics/logistics.component';
import { SupplierComponent } from './pages/logistics/supplier/supplier.component';
import { LogisticsSupplierListComponent } from './pages/logistics/supplier/components/supplier-list/logistics-supplier-list.component';
import { AreasComponent } from './pages/areas/areas.component';
import { AreaEditComponent } from './pages/areas/area-edit/area-edit.component';
import { AreaListComponent } from './pages/areas/area-list/area-list.component';
import { LogisticSupplierComponent } from './pages/supplier/logisticsupplier.component';
import { LocationListComponent } from './pages/logistics/location/components/locations-list/locations-list.component';
import { CreateLocationComponent } from './pages/logistics/location/components/create-location/create-location.component';
import { EditLocationComponent } from './pages/logistics/location/components/edit-location/edit-location.component';
import { ResourceListComponent } from './pages/logistics/resource/components/resources-list/resources-list.component';
import { CreateResourceComponent } from './pages/logistics/resource/components/create-resource/create-resource.component';
import { EditResourceComponent } from './pages/logistics/resource/components/edit-resource/edit-resource.component';
import { VehicleListComponent } from './pages/logistics/vehicle/components/vehicles-list/vehicles-list.component';
import { CreateVehicleComponent } from './pages/logistics/vehicle/components/create-vehicle/create-vehicle.component';
import { EditVehicleComponent } from './pages/logistics/vehicle/components/edit-vehicle/edit-vehicle.component';
import { CreateMerchantComponent } from './pages/merchant/components/create-merchant/create-merchant.component';
import { MerchantListComponent } from './pages/merchant/components/merchants-list/merchants-list.component';
import { MerchantComponent } from './pages/merchant/merchant.component';
import { ShopSettingComponent } from './pages/shopsetting/shopsetting.component';
import { CreateShopSettingComponent } from './pages/shopsetting/components/create-shopsetting/create-shopsetting.component';
import { PromotionComponent } from './pages/promotion-kpi-trendingsku/promotion.component';
import { PromotionListComponent } from './pages/promotion-kpi-trendingsku/components/promotions-list/promotions-list.component';
import { EditPromotionComponent } from './pages/promotion-kpi-trendingsku/components/edit-promotion/edit-promotion.component';
import { CreatePromotionComponent } from './pages/promotion-kpi-trendingsku/components/create-promotion/create-promotion.component';
import { skuDetails } from './entities/pricing';
import { ManageProductSkusComponent } from './pages/product/manage-product-skus/manage-product-skus.component';
import { ManageVendorSkusComponent } from './pages/product/manage-vendor-skus/manage-vendor-skus.component';
import { MasterSkuListComponent } from './pages/product/master-sku-list/master-sku-list.component';
import { VendorSkuListComponent } from './pages/product/vendor-sku-list/vendor-sku-list.component';
import { DraftSkuListComponent } from './pages/product/draft-sku-list/draft-sku-list.component';
import { SkuListComponent } from './pages/product/sku-list/sku-list.component';
import { PendingApprovalTabComponent } from './pages/product/pending-approval-tab/pending-approval-tab.component';
import { SupplierListComponent } from './pages/supplier/components/supplier-list/supplier-list.component';
import { EditLogisticsSupplierComponent } from './pages/logistics/supplier/components/edit-supplier/edit-logistics-supplier.component';
import { CreateSupplierComponent } from './pages/supplier/components/create-supplier/create-supplier.component';
import { CreateLogisticsSupplierComponent } from './pages/logistics/supplier/components/create-supplier/create-logistics-supplier.component';
import { EditSupplierComponent } from './pages/supplier/components/edit-logisticsupplier/edit-supplier.component';
import { PromotionDetailsComponent } from './pages/promotion-kpi-trendingsku/components/promotion-details/promotion-details.component';
import { VendorGeoMappingSearchComponent } from './pages/vendor-geo-mapping/components/vendor-geo-mapping-search/vendor-geo-mapping-search.component';
import { AddVendorGeoMappingComponent } from './pages/vendor-geo-mapping/components/add-vendor-geo-mapping/add-vendor-geo-mapping.component';
import { VendorGeoMappingComponent } from './pages/vendor-geo-mapping/vendor-geo-mapping.component';
import { UploadLocationComponent } from './pages/vendor-geo-mapping/components/upload-location/upload-location.component';
import { AddCompanyComponent } from './pages/vendor-geo-mapping/components/add-company/add-company.component';
import { CustomerComponent } from './pages/customers/customer.component';
import { CustomerListComponent } from './pages/customers/components/customers-list/customers-list.component';
import { PlanningListComponent } from './pages/logistics/planning/planning-list/planning-list.component';
import { CustomersDetailsComponent } from './pages/customers/components/customers-details/customers-details.component';
import { AddressUpdateComponent } from './pages/customers/components/address-update/address-update.component';
import { OrderComponent } from './pages/order/order.component';
import { OrderListComponent } from './pages/order/order-list/order-list.component';
import { BulkImportComponent } from './pages/bulk-import/bulk-import.component';
import { TripListComponent } from './pages/logistics/trip/components/trip-list/trip-list.component';
import { TripSummaryListComponent } from './pages/logistics/trip/components/trip-summarys-list/trip-summarys-list.component';
import { HopListComponent } from './pages/logistics/trip/components/hop-list/hop-list.component';
import { TripDetailsComponent } from './pages/logistics/trip/components/trip-details/trip-details.component';
import { HopDetailsComponent } from './pages/logistics/trip/components/hop-details/hop-details.component';
import { ProductEditComponent } from './pages/logistics/trip/components/hop-details/product-edit/product-edit.component';
import { ReturnsReportListComponent } from './pages/logistics/returns-report/components/returns-report-list/returns-report-list.component';
import { NaukaOrdersComponent } from './pages/order/nauka-orders/nauka-orders.component';
import { OrderDetailsComponent } from './pages/order/order-details/order-details.component';
import { ManageUsersComponent } from './pages/manage-users/manage-users.component';
import { ManageUsersListComponent } from './pages/manage-users/manage-users-list/manage-users-list.component';
import { CreateEditManageUsersComponent } from './pages/manage-users/create-edit-manage-users/create-edit-manage-users.component';
import { ManageRolesComponent } from './pages/manage-roles/manage-roles.component';
import { RolesListComponent } from './pages/manage-roles/roles-list/roles-list.component';
import { CreateEditRolesComponent } from './pages/manage-roles/create-edit-roles/create-edit-roles.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { OrdersListComponent } from './pages/orders/order-list/order-list.component';
import { POCreationComponent } from './pages/po-creation/po-creation.component';
import { PurchaseorderListComponent } from './pages/po-creation/purchase-order-list/purchaseorder-list.component';
import { CreatePurchaseOrderComponent } from './pages/po-creation/create-purchase-order/create-purchase-order.component';
import { AddProductComponent } from './pages/po-creation/add-product/add-product.component';
import { AddExpensesComponent } from './pages/po-creation/add-expenses/add-expenses.component';
import { PurchaseOrderEditComponent } from './pages/po-creation/purchase-order-edit/purchase-order-edit.component';
import { PoProductEditComponent } from './pages/po-creation/add-product/product-edit/po-product-edit.component';
import { EditExpensesComponent } from './pages/po-creation/edit-expenses/edit-expenses.component';
import { CreateIncentiveSlabComponent } from './pages/incentiveslab/components/create-incentiveslab/create-incentiveslab.component';
import { IncentiveSlabComponent } from './pages/incentiveslab/incentiveslab.component';
import { IncentiveSlabListComponent } from './pages/incentiveslab/components/incentiveslabs-list/incentiveslabs-list.component';
import { SalesPersonPerformanceComponent } from './pages/salespersonperformance/salespersonperformance.component';
import { SalesPersonPerformanceListComponent } from './pages/salespersonperformance/components/salesperonperformance-list/salesperonperformance-list.component';
import { TargetsComponent } from './pages/targets/targets.component';
import { TargetsListComponent } from './pages/targets/components/targets-list/targets-list.component';
import { EditTargetsComponent } from './pages/targets/components/edit-targets/edit-targets.component';
import { LogisticsReportsComponent } from './pages/logistics/reports/logistics-reports/logistics-reports.component';
import { BmsReportsComponent } from './pages/bms-reports/bms-reports.component';
import { InsightsReportsComponent } from './pages/bms-reports/insights-reports/insights-reports.component';
import { SellerReportsComponent } from './pages/seller-reports/seller-reports.component';
import { DownloadReportsComponent } from './pages/seller-reports/download-reports/download-reports.component';
import { ErrorsComponent } from './pages/errors/errors.component';
import { ErrorLogListComponent } from './pages/errors/components/error-log-list/error-log-list.component';
import { DeepLinksComponent } from './pages/deep-links/deep-links.component';
import { DeepLinksListComponent } from './pages/deep-links/deep-links-list/deep-links-list.component';
import { DeepLinksAddComponent } from './pages/deep-links/deep-links-add/deep-links-add.component';

export const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'admin-notifications', component: AdminNotificationsComponent },
      { path: 'vendor-notifications', component: VendorNotificationsComponent },

      { path: 'pending-approval', component: PendingApprovalsComponent },
      { path: 'my-requests', component: VendorApprovalRequestsComponent },
      {
        path: 'company',
        component: CompanyComponent,
        children: [
          { path: '', component: CompanyListComponent },
          { path: 'add', component: CompanyAddEditComponent },
          { path: 'edit/:id', component: CompanyAddEditComponent },
        ],
      },
      {
        path: 'attribute',
        component: AttributeComponent,
        children: [
          { path: '', component: AttributeListComponent },
          { path: 'add', component: AttributeAddEditComponent },
          { path: 'edit/:id', component: AttributeAddEditComponent },
        ],
      },
      {
        path: 'brand',
        component: BrandComponent,
        children: [
          { path: '', component: BrandListComponent },
          { path: 'add', component: BrandAddEditComponent },
          { path: 'edit/:id', component: BrandAddEditComponent },
        ],
      },
      // {
      //   path: 'coupon',
      //   component: CouponComponent,
      //   children: [
      //     { path: '', component: CouponListComponent },
      //     { path: 'add', component: CouponAddComponent },
      //     { path: 'edit/:id', component: CouponDetailsComponent },
      //   ],
      // },
      {
        path: 'category',
        component: CategoryComponent,
        children: [
          { path: '', component: CategoryListComponent },
          { path: 'add', component: CategoryAddEditComponent },
          { path: 'edit/:id', component: CategoryAddEditComponent },
        ],
      },
      {
        path: 'coupon',
        component: CouponComponent,
        children: [
          { path: '', component: CouponListComponent },
          { path: 'add', component: CouponAddComponent },
          { path: 'detail/:id', component: CouponDetailsComponent },
        ],
      },
      {
        path: 'variantType',
        component: VarientTypeComponent,
        children: [
          { path: '', component: VarientTypeListComponent },
          { path: 'add', component: VarientTypeAddEditComponent },
          { path: 'edit/:id', component: VarientTypeAddEditComponent },
        ],
      },
      {
        path: 'variantValue',
        component: VarientValueComponent,
        children: [
          { path: ':variant_types_id', component: VarientValueListComponent },
          {
            path: ':variant_types_id/add',
            component: VarientValueAddEditComponent,
          },
          {
            path: ':variant_types_id/edit/:id',
            component: VarientValueAddEditComponent,
          },
        ],
      },
      { path: 'skus/draft', component: DraftSkuListComponent },
      {
        path: 'product/sku',
        component: SkuEditComponent,
        children: [
          { path: 'edit/:type/:id', component: SkuDetailsComponent },
          { path: 'draft-edit/:docId', component: SkuDetailsComponent },
          { path: ':type/add', component: SkuDetailsComponent },
        ],
      },
      {
        path: 'product',
        component: ProductComponent,
        children: [
          {
            path: 'skus',
            component: SkuListComponent,
            children: [
              { path: 'master_skus', component: MasterSkuListComponent },
              { path: 'draft', component: DraftSkuListComponent },
              { path: 'pending-tab', component: PendingApprovalTabComponent },
            ],
          },
          { path: 'vendor_skus', component: VendorSkuListComponent },
          { path: 'published', component: PublishedProductsComponent },
          {
            path: 'add',
            component: ProductAddEditComponent,
            children: [{ path: '', component: DetailsComponent }],
          },
          {
            path: 'preview-product/:id',
            component: PreviewProductComponent,
          },
          {
            path: 'preview-publish-product/:id',
            component: PreviewPublishProductComponent,
          },
          {
            path: 'manage-vendor-sku/:id',
            component: ManageVendorSkusComponent,
          },
          {
            path: 'manage-master-sku/:id',
            component: ManageProductSkusComponent,
          },
        ],
      },
      {
        path: 'promotion',
        component: PromotionComponent,
        children: [
          { path: '', component: PromotionListComponent },
          { path: 'create-promotion', component: CreatePromotionComponent },
          { path: 'detail/:id', component: PromotionDetailsComponent },
        ],
      },
      {
        path: 'pocreation',
        component: POCreationComponent,
        children: [
          { path: '', component: PurchaseorderListComponent },
          {
            path: 'createpurchaseorder/:id',
            component: CreatePurchaseOrderComponent,
          },
          {
            path: 'edit-purchase-order/:id',
            component: PurchaseOrderEditComponent,
          },
          { path: 'addproduct/:id', component: AddProductComponent },
          {
            path: 'addexpenses/:id/:supplierId',
            component: AddExpensesComponent,
          },
          {
            path: 'purchaseorderedit/:id',
            component: PurchaseOrderEditComponent,
          },
          {
            path: 'producteditComponent/:id/:rowId',
            component: PoProductEditComponent,
          },
          {
            path: 'edit-expenses/:id/:expenseId',
            component: EditExpensesComponent,
          },
        ],
      },
      {
        path: 'reports',
        component: BmsReportsComponent,
        children: [
          {
            path: 'insights-reports',
            component: InsightsReportsComponent,
          },
        ],
      },
      {
        path: 'seller-reports',
        component: SellerReportsComponent,
        children: [
          {
            path: 'download-reports',
            component: DownloadReportsComponent,
          },
        ],
      },
      {
        path: 'areas',
        component: AreasComponent,
        children: [
          { path: '', component: AreaListComponent },
          { path: 'area-list', component: AreaListComponent },
          { path: 'area-edit', component: AreaEditComponent },
        ],
      },
      { path: 'orders-list', component: OrdersListComponent },

      {
        path: 'customers',
        component: CustomerComponent,
        children: [
          { path: '', component: CustomerListComponent },
          {
            path: 'customers-details/:id',
            component: CustomersDetailsComponent,
          },
          { path: 'address-update/:id', component: AddressUpdateComponent },
        ],
      },
      {
        path: 'error-log',
        component: ErrorsComponent,
        children: [{ path: '', component: ErrorLogListComponent }],
      },
      // {path:'errors',component:ErrorsComponent},
      {
        path: 'pricing',
        component: PricingComponent,
        children: [
          { path: '', component: PricingListComponent },
          { path: 'pricing-details', component: PricingDetailsComponent },
        ],
      },
      {
        path: 'settings',
        component: SettingsComponent,
        children: [
          { path: '', component: VendorSearchComponent },
          { path: 'vendor-search', component: VendorSearchComponent },
          { path: 'vendor-list', component: VendorListComponent },
          { path: 'margin', component: MarginComponent },
        ],
      },
      {
        path: 'shop-setting',
        component: CreateShopSettingComponent,
      },
      {
        path: 'config',
        component: ConfigComponent,
        children: [
          { path: 'area-config', component: AreaConfigComponent },
          { path: 'area-config/:id', component: AreaConfigComponent },
          { path: 'vendor-config', component: VendorConfigComponent },
        ],
      },
      {
        path: 'supplier',
        component: LogisticSupplierComponent,
        children: [
          { path: 'supplier-list', component: SupplierListComponent },
          { path: 'create-supplier', component: CreateSupplierComponent },
          { path: 'edit-supplier/:id', component: EditSupplierComponent },
        ],
      },
      {
        path: 'logistics',
        component: LogisticsComponent,
        children: [
          { path: '', component: SupplierComponent },
          { path: 'supplier-list', component: LogisticsSupplierListComponent },
          {
            path: 'create-supplier',
            component: CreateLogisticsSupplierComponent,
          },
          {
            path: 'edit-supplier/:id',
            component: EditLogisticsSupplierComponent,
          },
          { path: 'location-list', component: LocationListComponent },
          { path: 'create-location', component: CreateLocationComponent },
          { path: 'edit-location/:id', component: EditLocationComponent },
          { path: 'resource-list', component: ResourceListComponent },
          { path: 'create-resource', component: CreateResourceComponent },
          { path: 'edit-resource/:id', component: EditResourceComponent },
          { path: 'vehicle-list', component: VehicleListComponent },
          { path: 'create-vehicle', component: CreateVehicleComponent },
          { path: 'edit-vehicle/:id', component: EditVehicleComponent },
          { path: 'planning-list', component: PlanningListComponent },
          { path: 'trip-list', component: TripListComponent },
          { path: 'trip-summary/:id', component: TripSummaryListComponent },
          { path: 'product-edit', component: ProductEditComponent },
          { path: 'hop-list', component: HopListComponent },
          { path: 'trip-details/:id', component: TripDetailsComponent },
          { path: 'hop-details/:id', component: HopDetailsComponent },
          { path: 'logistic-reports', component: LogisticsReportsComponent },

          {
            path: 'returns-report-list',
            component: ReturnsReportListComponent,
          },
        ],
      },
      {
        path: 'merchant',
        component: MerchantComponent,
        children: [
          { path: '', component: MerchantListComponent },
          { path: 'create-merchant', component: CreateMerchantComponent },
          { path: 'edit-merchant/:id', component: EditMerchantComponent },
        ],
      },
      {
        path: 'master-geo-list',
        component: MasterGeoListComponent,
        children: [
          { path: 'master-geo-list', component: MasterGeoListComponent },
          { path: 'add-geo', component: AddGeoComponent },
        ],
      },
      {
        path: 'vendor-geo-mapping',
        component: VendorGeoMappingComponent,
        children: [
          { path: '', component: VendorGeoMappingSearchComponent },
          {
            path: 'add-vendor-geo-mapping/:id',
            component: AddVendorGeoMappingComponent,
          },
          { path: 'upload-location', component: UploadLocationComponent },
        ],
      },
      {
        path: 'order',
        component: OrderComponent,
        children: [
          { path: 'vendor-orders', component: OrderListComponent },
          { path: 'nauka-orders', component: NaukaOrdersComponent },
          // { path: 'order-detail', component: OrderDetailsComponent },
        ],
      },
      {
        path: 'vendor-order',
        component: OrderDetailsComponent,
        children: [{ path: 'order-detail', component: OrderDetailsComponent }],
      },
      {
        path: 'bulk-import',
        component: BulkImportComponent,
      },
      {
        path: 'roles',
        component: ManageRolesComponent,
        children: [
          { path: '', component: RolesListComponent },
          { path: 'create-role', component: CreateEditRolesComponent },
          { path: 'edit-role/:id', component: CreateEditRolesComponent },
        ],
      },
      {
        path: 'users',
        component: ManageUsersComponent,
        children: [
          { path: '', component: ManageUsersListComponent },
          { path: 'create-user', component: CreateEditManageUsersComponent },
          { path: 'edit-user/:id', component: CreateEditManageUsersComponent },
        ],
      },

      {
        path: 'incentives',
        component: IncentiveSlabComponent,
        children: [
          {
            path: 'incentives-list',
            component: IncentiveSlabListComponent,
          },
          {
            path: 'create-incentive',
            component: CreateIncentiveSlabComponent,
          },
          {
            path: 'edit-incentive/:id',
            component: EditIncentiveSlabComponent,
          },
        ],
      },
      {
        path: 'performance',
        component: SalesPersonPerformanceComponent,
        children: [
          {
            path: 'performance-list',
            component: SalesPersonPerformanceListComponent,
          },
        ],
      },
      {
        path: 'targets',
        component: TargetsComponent,
        children: [
          { path: 'targets-list', component: TargetsListComponent },
          // {path: 'edit-targets/:id', component: EditTargetsComponent},
          { path: 'edit-targets/:id/:date', component: EditTargetsComponent },

          //
        ],
      },

      { path: '', redirectTo: 'home', pathMatch: 'full' },
      /*  { path: '**', redirectTo: '/dashboard/product/published', pathMatch: 'full' }, */
      {
        path: 'deep-links',
        component: DeepLinksComponent,
        children: [
          { path: '', component: DeepLinksListComponent },
          { path: 'deep-links-add', component: DeepLinksAddComponent },
        ],
      },
    ],
  },

  { path: 'signin', component: AuthComponent },
  { path: '', redirectTo: '/dashboard/order', pathMatch: 'full' },
  { path: '**', redirectTo: '/dashboard/product/published', pathMatch: 'full' },
];
