import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  FormBuilder,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
  takeUntil,
} from 'rxjs';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { getDateFormat, getDeeplinksType, guid } from '../../../utlity/utility';
import Swal from 'sweetalert2';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { AuthService } from '../../../services/auth.service';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { PageId } from '../../../constants/enums';
import {
  DeepLinkType,
  LookUpType,
  SwalMessageTypes,
} from '../../../enums/enums';
import { PromotionsService } from '../../promotion-kpi-trendingsku/services/promotions.service';
import { DeepLinksService } from '../services/deep-links.service';
import { LookUpTypeService } from '../../../services/lookup-type.service';
import { AppLoaderService } from '../../../shared/app-loader/app-loader.service';

export interface CategoryWiseInput {
  minimumCategory: any | null;
  minimumCategoryDiscountPercent: number;
}
@Component({
  selector: 'app-deep-links-add',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    RouterLink,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatNativeDateModule,
  ],
  templateUrl: './deep-links-add.component.html',
  styleUrl: './deep-links-add.component.scss',
})
export class DeepLinksAddComponent extends BaseListComponent {
  dataSource: any;
  deepLinkTypeList: Array<any> = [];
  selectedDeeplinkType: number = 1;
  promotionRes: any = [];
  SearchResults: any[] = [];
  brandsResults: any[] = [];
  vendorResults: any[] = [];
  skuList: Array<any> = [];
  searchPerformed = false;
  merchantList: Array<any> = [];
  merchantSearchList: Array<any> = [];
  deepLinkId: string = '';
  categoryControl = new FormControl();
  brandControl = new FormControl();
  vendorControl = new FormControl();
  skuControl = new FormControl();
  merchantListSearchInput: LookUpType = this.initializeLookUpTypeSearchInput();
  promotionListSearchInput: any;
  constructor(
    private fb: FormBuilder,
    router: Router,
    route: ActivatedRoute,
    auth: AuthService,
    private loader: AppLoaderService,
    private deepLinksService: DeepLinksService,
    private lookupService: LookUpTypeService,
    public readonly promotionService: PromotionsService
  ) {
    super(auth, router, route, PageId.deep_links);
    this.dataSource = this.fb.group({
      deepLinkType: ['', [Validators.required]],
      promotionSelect: [],
      promoKpiSelect: [],
      selectCategory: [],
      selectBrand: [],
      selectVendor: [],
      selectSku: [],
      title: '',
      description: '',
    });
  }

  override async ngOnInit() {
    await super.ngOnInit();
    await this.setupBrandSearch();
    await this.setupCategorySearch();
    await this.getMerchants();
    this.deepLinkTypeList = getDeeplinksType();
    this.deepLinkId = guid();
  }

  async onDeeplinkTypeChange(event: any) {
    this.selectedDeeplinkType = event.value;

    switch (this.selectedDeeplinkType) {
      case 1:
        await this.getPromotions(this.selectedDeeplinkType);
        break;
      case 2:
        await this.getPromotions(this.selectedDeeplinkType);
        break;
      case 3:
        await this.setupCategorySearch();
        break;
      case 4:
        await this.setupBrandSearch();
        break;
      case 5:
        await this.getMerchants();
        break;
      case 6:
        await this.setupSkuSearch();
        break;
    }
  }
  async getPromotions(deeplinkid: number) {
    const promotionBody = this.initializeSearchInput(deeplinkid);
    const response = await this.promotionService.getPromotions(promotionBody);
    this.promotionRes = response['promotionList'];
    this.dataSource = this.initializeForm();
  }
  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }
  private initializeSearchInput(deeplinkId: number) {
    this.promotionListSearchInput = {
      promoType: deeplinkId,
      vendorId: [],
      status: true,
      pageNumber: this.pageNumber,
      pageSize: 1000,
    };
    return this.promotionListSearchInput;
  }

  async setupCategorySearch() {
    this.categoryControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        filter((value) => value && value.length >= 3),
        switchMap((value) => this.searchCategory(value))
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((results: any[]) => {
        this.SearchResults = results;
        this.searchPerformed = true;
      });
    this.dataSource = this.initializeForm();
  }
  async searchCategory(searchTerm: string) {
    const searchText = searchTerm.trim();
    const response = await this.deepLinksService.getCategories(searchText);
    return response.data;
  }
  async setupBrandSearch() {
    this.brandControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        filter((value) => value.length >= 3),
        switchMap((value) => this.searchBrand(value))
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((results: any[]) => {
        this.brandsResults = results;
        // this.searchPerformed = true;
      });
    this.dataSource = this.initializeForm();
  }
  async searchBrand(searchTerm: string) {
    const searchText = searchTerm.trim();
    const response = await this.deepLinksService.getBrands(searchText);
    return response.data;
  }
  async getMerchants() {
    const response = await this.lookupService.getLookUpTypes(
      this.merchantListSearchInput
    );
    this.merchantList = response.data[0].data;
    this.merchantSearchList = response.data[0].data;
    this.dataSource = this.initializeForm();
  }

  private initializeLookUpTypeSearchInput(): LookUpType {
    return {
      tableName: 'vendor',
      lookupType: 'vendor',
      idColumnName: 'id',
      valueColumnName: 'business_name',
    };
  }
  onKey(value: any) {
    const searchKeyword = value.target.value || '';
    this.merchantSearchList = this.search(searchKeyword);
  }
  search(value: string) {
    let filter = value.toLowerCase();
    return this.merchantList.filter((option) =>
      option.value.toLowerCase().includes(filter)
    );
  }
  async setupSkuSearch() {
    this.skuControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        filter((value) => value.length >= 3),
        switchMap((value) => this.searchSku(value))
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((results: any[]) => {
        this.skuList = results;
        // this.searchPerformed = true;
      });
    this.dataSource = this.initializeForm();
  }
  async searchSku(searchTerm: string) {
    const searchText = searchTerm.trim();
    const response = await this.deepLinksService.getSkus(searchText);

    return response.data;
  }
  async saveDeeplink() {
    this.loader.open();
    try {
      if (this.dataSource.invalid) {
        this.showMessage(
          'Please fill all required fields.',
          SwalMessageTypes.Warning
        );
        return;
      }
      const deepLink = this.dataSource.value;
      const deepLinkType = deepLink.deepLinkType;
      const deepLinkName = this.getDeepLinkType(deepLinkType);
      let message = '';
      let selectedIds: any = [];
      switch (deepLinkType) {
        case 1:
          selectedIds = [deepLink.promotionSelect];
          message = 'Promotion';
          break;
        case 2:
          selectedIds = [deepLink.promoKpiSelect];
          message = 'Promotion KPI';
          break;
        case 3:
          selectedIds = deepLink.selectCategory ? deepLink.selectCategory : [];
          message = 'Category';
          break;
        case 4:
          selectedIds = deepLink.selectBrand ? deepLink.selectBrand : [];
          message = 'Brands';
          break;
        case 5:
          selectedIds = deepLink.selectVendor ? deepLink.selectVendor : [];
          message = 'Vendor';
          break;
        case 6:
          selectedIds = deepLink.selectSku ? deepLink.selectSku : [];
          message = 'Sku';
          break;
      }
      if (selectedIds.includes(null) || selectedIds.length === 0) {
        this.showMessage(
          `Please select ${message} Ids.`,
          SwalMessageTypes.Warning
        );
        return;
      }
      const body = {
        type: deepLinkType,
        ids: selectedIds,
        socialMetaTagInfo: {
          socialDescription: deepLink.description,
          socialTitle: deepLink.title,
        },
      };
      const res = await this.deepLinksService.createDeepLink(body);
      let generatedLInk = res.data;
      this.router.navigateByUrl(`/dashboard/deep-links`);
      if (res.success) {
        this.showMessage(
          'Deep link Created Successfully',
          SwalMessageTypes.Success
        );
        const currentDate = new Date();
        const firebaseBody = {
          id: guid(),
          Link: generatedLInk,
          Title: deepLink.title,
          Description: deepLink.description,
          Type: deepLinkType,
          Data: {
            Name: deepLinkName,
            Id: selectedIds,
          },
          Created_at: currentDate,
        };

        const res = this.deepLinksService.insertDeepLink(firebaseBody);
      }
    } catch (error) {
      console.error('Error occurred during deep link creation:', error);
      this.showMessage('Deep link Create Failed', SwalMessageTypes.Warning);
    } finally {
      this.loader.close();
    }
  }
  getDeepLinkType(status: number): string {
    return DeepLinkType[status];
  }
  private initializeForm() {
    return this.fb.group({
      deepLinkType: this.dataSource.value.deepLinkType,
      promotionSelect: [],
      promoKpiSelect: [],
      selectCategory: [],
      selectBrand: [],
      selectVendor: [],
      selectSku: [],
      title: '',
      description: '',
    });
  }
}
