import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { LookUpTypeService } from '../../../services/lookup-type.service';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../../services/auth.service';
import { OrderService } from '../../../services/orderService.service';
import { ConfigService } from '../../config/services/config.service';
import { PurchaseReceiptService } from '../service/purchasereceipt.service';
import {
  SwalMessageTypes,
  TaxDeductionType,
  UOM,
  VehicleModel,
} from '../../../enums/enums';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  filter,
} from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { SkuEditComponent } from '../../product/product-add-edit/skuEdit/skuEdit.component';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { PageId } from '../../../constants/enums';
import Swal from 'sweetalert2';
import { addItem } from '../models/purchasereceipt-model';

@Component({
  selector: 'app-add-product',
  standalone: true,
  imports: [
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatCardModule,
    MatTableModule,
    RouterLink,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
  ],
  templateUrl: './add-product.component.html',
  styleUrl: './add-product.component.scss',
})
export class AddProductComponent extends BaseListComponent {
  form: FormGroup;
  skuList: any;
  selectedSku: any;
  prId: string = '';
  skuDetails: any;
  pr: any;
  skuSearchControl = new FormControl();
  skuSearchResults: any[] = [];
  searchPerformed = false;
  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    private lookupService: LookUpTypeService,
    private authService: AuthService,
    private configService: ConfigService,
    private purchaseReceiptService: PurchaseReceiptService
  ) {
    super(auth, router, route, PageId.seller_pr);
    this.form = this.fb.group({
      skuName: null,
      buyinguom: [null, Validators.required],
      upc: '',
      weight: '',
      uomQty: [null, Validators.required],
      requestedInvoiceAmount: null,
      pcs: null,
      invoiceAmount: null,
      taxableAmount: null,
      sgst: null,
      cgst: null,
      igst: null,
      cess: null,
      tcs_tds: null,
      hsn: null,
      totalBillValue: null,
      mrp: null,
      itemTaxType: null,
      taxRate: null,
      cessRate: null,
      selectedSku: [null, Validators.required],
    });
  }
  Uom: { name: string; value: UOM }[] = [
    { name: 'Case', value: UOM.Case },
    { name: 'PCs', value: UOM.PCs },
    { name: 'Box', value: UOM.Box },
  ];
  Taxtype: { name: string; value: TaxDeductionType }[] = [
    { name: 'Inclusive', value: TaxDeductionType.Inclusive },
    { name: 'Exclusive', value: TaxDeductionType.Exclusive },
    { name: 'None', value: TaxDeductionType.None },
  ];

  override async ngOnInit() {
    await super.ngOnInit();
    this.route.params.subscribe(async (params) => {
      this.prId = params['id'];
    });
    this.setupSkuSearch();
  }

  private setupSkuSearch() {
    this.skuSearchControl.valueChanges
      .pipe(
        debounceTime(300), // Wait for 300 milliseconds after the last keystroke
        distinctUntilChanged(), // Only emit distinct values
        filter((value) => value.length >= 3), // Filter out search terms less than 3 characters
        switchMap((value) => this.searchSku(value)) // Perform the search and switch to the latest observable
      )
      .subscribe((results: any[]) => {
        this.skuSearchResults = results;
        this.searchPerformed = true;
      });
  }
  async searchSku(searchTerm: string) {
    const response = await this.purchaseReceiptService.vendorSkuSearch(
      searchTerm,
      this.vendorId
    );
    console.log('response');
    return response;
  }

  onInputKeydown(event: KeyboardEvent) {
    // Prevent default action for spacebar key press
    if (event.key === ' ' || event.code === 'Space') {
      event.stopPropagation();
    }
  }

  onSkuSelectionChange(event: MatSelectChange) {
    this.selectedSku = event.value;
    console.log('selected sku is', this.selectedSku);
    this.mapToSkuDetails(this.selectedSku);
  }
  getTaxName(status: number): string {
    return TaxDeductionType[status];
  }

  mapToSkuDetails(response: any) {
    const upcValue = response?.packing_section?.upc || 0;
    let buyingUOMValue;

    if (upcValue === 0) {
      buyingUOMValue = UOM.PCs;
    }
    this.form.patchValue({
      skuName: response.sku_name,
      buyinguom: buyingUOMValue,
      upc: upcValue,
      uomQty: null,
      purchaseRate: null,
      pcs: null,
      invoiceAmount: null,
      taxableAmount: null,
      sgst: null,
      cgst: null,
      igst: null,
      weight: response.packing_section.weight,
      cess: null,
      hsn: response.tax.hsn_code,
      tcs_tds: null,
      totalBillValue: null,
      mrp: response.pricing?.mrp,
      itemTaxType: response.tax.tax_category,
      taxRate: response.tax?.gst_percentage,
      cessRate: response.tax?.cess_percentage,
    });
  }

  async calculatePcs(event: Event, fieldName: string): Promise<void> {
    console.log('calculatePcs::fieldName::', fieldName);

    const uomQty = this.form.get('uomQty')?.value;
    const upc = this.form.get('upc')?.value;
    const uom = this.form.get('buyinguom')?.value;
    let pcs: number = 0;

    if (fieldName === 'uomQty' || fieldName === 'upc') {
      if (fieldName === 'uomQty') {
        pcs = fieldName === 'uomQty' ? uomQty * upc : upc * uomQty;
      }

      if (uom == UOM.PCs) {
        this.form.patchValue({ pcs: uomQty });
      }
      if ([UOM.Box, UOM.Case].includes(uom)) {
        this.form.patchValue({ pcs });
      }
    }
  }

  goBack() {
    this.router.navigateByUrl(
      `/dashboard/pocreation/edit-purchase-order/${this.prId}`
    );
  }

  resetUomQty() {
    const uomQty = this.form.get('uomQty')?.value;

    if (uomQty !== null) {
      console.log(uomQty);

      this.form.patchValue({ uomQty: 0 });
      this.form.patchValue({ pcs: 0 });
    }
  }

  async saveSku() {
    if (this.form.invalid) {
      Swal.fire('warning', 'Please enter the all mandatory fields', 'warning');

      return;
    }
    if (!this.validations()) {
      return;
    }
    const product = this.form.value;
    const skuDetails = this.mapToSku(product);
    const res = await this.purchaseReceiptService.createPurchaseReceiptItem(
      skuDetails
    );
    this.router.navigateByUrl(
      `/dashboard/pocreation/edit-purchase-order/${this.prId}`
    );
    console.log('product information is', skuDetails);
    console.log('resssss', res.data);
  }

  private validations(): boolean {
    const negativeFields = this.validateNegativeValues(this.form.value);

    if (negativeFields.length) {
      this.showMessage(
        `${negativeFields} ,have negative values Please Check`,
        SwalMessageTypes.Warning
      );

      return false;
    }
    if (this.hasDecimal(this.form.value.uomQty)) {
      this.showMessage(
        `UOM Qty should not have decimal value`,
        SwalMessageTypes.Warning
      );
      return false;
    }

    if (this.hasDecimal(this.form.value.upc)) {
      this.showMessage(
        `UPC should not have decimal value`,
        SwalMessageTypes.Warning
      );
      return false;
    }
    if (this.form.value.uomQty <= 0) {
      this.showMessage(
        `UOM Quantity should be greater than zero`,
        SwalMessageTypes.Warning
      );
      return false;
    }
    return true;
  }
  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  private validateNegativeValues(data: addItem): string[] {
    const numericInputs = [
      { name: 'MRP', value: data.mrp },
      { name: 'UPC', value: data.upc },
      { name: 'UOM Qty', value: data.uomQty },
      { name: 'Invoice Amount', value: data.invoiceAmount },
    ];

    const negativeFields = numericInputs
      .filter((input) => input.value < 0)
      .map((input) => input.name);

    return negativeFields;
  }
  hasDecimal(number: number) {
    return /\./.test(number.toString());
  }

  mapToSku(product: any) {
    return {
      receipt_id: this.prId,
      sku_id: product.selectedSku.sku_id,
      sku_name: product.skuName,
      upc: product.upc || 0,
      uom_type: product.buyinguom,
      uom_requested_qty: product.uomQty || 0,
      uom_actual_qty: 0,
      requested_in_pcs: product.pcs || 0,
      actual_in_pcs: 0,
      mrp: product.mrp || 0,
      tax_type: product.selectedSku.tax.tax_category,
      gst: product.selectedSku.tax.gst_percentage,
      cess: product.selectedSku.tax.cess_percentage,
      tax_amount: 0,
      taxable_amount: 0,
      tcs_percent: 0,
      tcs_amount: 0,
      cess_amount: 0,
      net_payable: 0,
      igst_amount: 0,
      cgst_amount: 0,
      sgst_amount: 0,
      invoiced_amount: product.invoiceAmount || 0,
      final_bill_value: product.invoiceAmount || 0,
      requested_invoice_amount: product.requestedInvoiceAmount || 0,
      weight: product.selectedSku.packing_section.weight,
      rate_per_pc: 0,
      unloading_charges: 0,
      loading_charges: 0,
      brokerage: 0,
      discount: 0,
      transport_charges: 0,
      purchase_credit: 0,
      loadingbybuyer_charges: 0,
      landing_cost: 0,
      hsn: product.selectedSku.tax.hsn_code,
    };
  }
}
