import { AfterViewChecked, AfterViewInit, Component, OnDestroy, OnInit, OnChanges, ViewChild, ChangeDetectorRef, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AuthService } from '../../../../../../services/auth.service';
import { SwalMessageTypes, SupplierStatus, TripStatus } from '../../../../../../enums/enums';
// import { PlanningsService } from '../../services/trip.service';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';

import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BaseListComponent } from '../../../../../../shared/core/base.list.component';
import { PageId } from '../../../../../../constants/enums';
import Swal from 'sweetalert2';




@Component({
  selector: 'app-complete-hop',
  standalone: true,
  imports: [MatCardModule, MatButtonModule, MatTableModule, RouterLink, MatInputModule, MatFormFieldModule, ReactiveFormsModule,
    MatSelectModule, MatIconModule, CommonModule, FormsModule, MatDatepickerModule, MatRadioModule, MatNativeDateModule,
    MatPaginatorModule, MatSidenavModule, MatMenuModule],
  templateUrl: './complete-hop.component.html',
  styleUrls: ['./complete-hop.component.scss']
})
export class CompleteHopComponent extends BaseListComponent {
  tripStatus = Object.values(TripStatus).filter(value => typeof value === 'number');
  dataSource: any;
  hops: any;
  isOtpMatched: boolean = false;
  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CompleteHopComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(auth, router, route, PageId.logistics_trips);
    this.auth.permission$.subscribe(res => {

    })
    this.dataSource = this.fb.group({
      otp: [null]
    });
  }

  override async ngOnInit() {
    await super.ngOnInit();
  }
  save() {
    const otpOne = this.data.otp;
    const otpTwo = this.dataSource.value.otp;

    if (!otpTwo) {
      Swal.fire('', "Fill OTP number", 'warning');
      return;
    }

    if (otpOne == otpTwo) {
      this.isOtpMatched = true;
      this.dialogRef.close({
        otp: this.dataSource.value
      });
    } else {
      Swal.fire('', "Enter valid OTP number", 'warning');
    }
  }

  close() {
    this.dialogRef.close();
    return;
  }
}
