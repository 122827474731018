import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ProductService } from '../services/product.service';
import { AuthService } from '../../../services/auth.service';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProductFilterDialogComponent } from '../product-filter-dialog/product-filter-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmDialogComponent } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.component';
import { CommonService } from '../../../services/common.service';
import { CommonModule } from '@angular/common';
import { MatSortHeader, MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { CategoryService } from '../../category/services/category.service';
import { BrandService } from '../../brand/services/brand.service';
import { CompanyService } from '../../company/services/company.service';
import { NgSelectModule } from '@ng-select/ng-select';
import Swal from 'sweetalert2';
import { SwalMessageTypes } from '../../../enums/enums';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { PageId } from '../../../constants/enums';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-published-products',
  standalone: true,
  imports: [CommonModule, MatIconModule,
    MatSelectModule,
    MatFormFieldModule, NgSelectModule,
    ReactiveFormsModule, MatSortModule, MatCardModule, MatButtonModule, MatTableModule, RouterLink, MatPaginatorModule, MatProgressSpinnerModule],
  templateUrl: './published-products.component.html',
  styleUrl: './published-products.component.scss',
})
export class PublishedProductsComponent extends BaseListComponent implements OnInit {
  dataSource: any = [];
  displayedColumns: string[] = ['name', 'brands_id', 'companies_id', 'categories_id', 'sku_count', 'action'];
  permission: any = false
  draftProducts: any = []
  /*   filterForm: any */
  meta: any
  loading = false;
  filterForm: any;
  searchTextLength: number = 0;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public commonService: CommonService,
    public productService: ProductService) {
    super(auth, router, route, PageId.seller_add_sku);
    this.filterForm = this.fb.group({
      name: [null],
      companies_id: [null],
      brands_id: [null],
      categories_id: [null],
      page: [1],
      limit: [this.pageSize],
      sort_order: [],
      sort_by: []
    });
  }

  goToCorrectPage() {
    if (this.paginator) {
      this.paginator.pageIndex = this.filterForm.value.page - 1;
      // Trigger a manual page change event so that the MatPaginator updates accordingly
      // this.paginator._changePageSize(this.paginator.pageSize);
    }
  }


  override async ngOnInit() {
    await super.ngOnInit();
    // this.getAll();
    this.filterForm.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((value: any) => {
      if (value.name && value.name.length > 2) {
        this.searchTextLength = value.name.length;
        this.navigateWithParams(value);
        this.getAllProducts();
      } else {
        this.dataSource.data = [];
      }
    });

    if (this.route.snapshot.queryParams) {
      this.filterForm.patchValue(this.route.snapshot.queryParams);
    } else {
      //  this.getAllProducts();
    }
  }

  reset() {
    this.filterForm.patchValue(
      {
        name: null,
        companies_id: null,
        brands_id: null,
        categories_id: null,
        page: 1,
        limit: this.pageSize
      }
    );
    this.searchTextLength = 0;
  }



  isDataAvailable(): boolean {
    this.searchTextLength = this.filterForm.value.name ? this.filterForm.value.name.length : 0;
    return this.dataSource && this.dataSource?.data?.length > 0;
  }
  getAllProducts() {
    this.loading = true;
    this.dataSource = new MatTableDataSource([]);


    this.productService.getAllPublishedProducts(this.filterForm.value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((published: any) => {
        this.productService.getAllDraftProduct()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((drafts: any) => {
          this.draftProducts = drafts
          this.meta = published.meta
          let list = published.data.filter((item: any) => {
            return drafts.map((item: any) => { return item.payload.id }).indexOf(item.id) == -1
          })
          this.loading = false;
          this.dataSource = new MatTableDataSource(list);
          this.goToCorrectPage();

        })
      });
  }



  async makeCopyAndEdit(element: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { title: 'Confirmation', message: "Are you sure you want to edit? it will shift product to draft product." },
      panelClass: 'custom-dialog-class'
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe$)).subscribe(async result => {
      if (result == 'yes') {
        element.attributes.id = element.id
        let id: any = await this.productService.addToPendingApproval("update-product", element.attributes)
        this.router.navigateByUrl(`/dashboard/product/edit/${id}`)
      }

    });
  }


  async deleteProduct(id: any) {
    /*  const dialogRef = this.dialog.open(ConfirmDialogComponent, {
       data: { title: 'Delete Confirmation', message: "Are you sure you want to delete?" },
       panelClass: 'custom-dialog-class'
     });
     dialogRef.afterClosed().subscribe(async result => {
       if (result == 'yes') {
         this.productService.deletePublishedProduct(id).subscribe(res => {
           this.getAllProducts()
           this.commonService.showToaster("success", 'Deleted Successfully')
         }, err => {
           this.commonService.showToaster("error", err.error.message)
         })
       }
 
     }); */
    try {
      const result = await Swal.fire({
        title: 'Do you want to Delete Product?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      });

      if (!result.isConfirmed) {
        return;
      }
      this.productService.deletePublishedProduct(id).subscribe(res => {
        this.getAllProducts()
        this.showMessage(
          'Product Deleted Successfully',
          SwalMessageTypes.Success
        );
      }, err => {
        this.showMessage(
          err.error.message,
          SwalMessageTypes.Error
        );
        // this.commonService.showToaster("error", err.error.message)
      })

    } catch (error) {
      /* this.showMessage(
        'Failed to Delete Product. Please try again later.',
        SwalMessageTypes.Error
      ); */
    }
  }
  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }
  onPaginationChange(event: any) {
    this.filterForm.patchValue({
      page: event.pageIndex + 1,
      limit: event.pageSize
    })
  }

  navigateWithParams(queryParams: any) {
    Object.keys(queryParams).forEach(key => {
      if (!queryParams[key] || queryParams[key] == 'null') {
        delete queryParams[key]
      }
    })
    // Navigate to the current route with the specified query parameters
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams
    });
  }

  openFilter() {
    const dialogRef = this.dialog.open(ProductFilterDialogComponent, {
      data: this.filterForm.value,
      panelClass: 'custom-dialog-class-p'
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      if (result) {
        this.filterForm.patchValue(result)
        this.goToCorrectPage()
      }
    });
  }
  announceSortChange(event: any) {
    console.log(event, "event")
    this.filterForm.patchValue({
      page: 1,
      limit: 5,
      sort_order: event.direction,
      sort_by: event.active
    })
  }
}
