@if(hasView){
<ng-container>
  <div class="heading-block">
    <div class="row">
      <div class="col-md-6">
        <h2 class="card-title m-3">Sales person Performance</h2>
      </div>
      <div class="col-md-6 text-right">
        @if(hasCreate){
        <button
        mat-raised-button
        color="primary"
        class="topButtonSectionbtn" (click)="generateExcel()">
        Download
      </button>
        }
      </div>
    </div>
  </div>
  <div class="search-block">
    <form [formGroup]="form">
      <section id="content">
        <div class="card">
          <div class="card-body card-padding">
            <div class="row">
              <div class="col-md-3">
                <mat-form-field class="full-width">
                  <input matInput formControlName="startDate" placeholder="startDate" [matDatepicker]="appDatepicker" />
                  <mat-datepicker-toggle matSuffix [for]="appDatepicker"></mat-datepicker-toggle>
                </mat-form-field>
                <mat-datepicker #appDatepicker></mat-datepicker>
              </div>
              <div class="col-md-3">
                <mat-form-field class="full-width">
                  <input matInput formControlName="endDate" placeholder="endDate" [matDatepicker]="appDatepicker1" />
                  <mat-datepicker-toggle matSuffix [for]="appDatepicker1"></mat-datepicker-toggle>
                </mat-form-field>
                <mat-datepicker #appDatepicker1></mat-datepicker>
              </div>
              <div class="col-md-3">
                <mat-form-field class="full-width">
                  <mat-label>Sales Person</mat-label>
                  <mat-select formControlName="salespersonId" (selectionChange)="getSelectedSalesPerson()" multiple>
                    <mat-option *ngFor="let salesPerson of salesPersonList" [value]="salesPerson.id">
                      {{ salesPerson.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-3 searchbtns">
                <div class="text-left mt-2">
                  <button mat-raised-button color="primary" class="text-center ml" (click)="searchTasks()">
                    Search
                  </button>
                </div>
                <div class="text-right mt-2 clearfiltrtbtn">
                  <button mat-raised-button color="primary" class="text-center ml-w" (click)="clearFilters()">
                    Clear Filters
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </form>
  </div>

  <div class="table-block" *ngIf="isDataFound">
    <div class="m-3 flex flex-col md:flex-row justify-between">
      <mat-card class="p-0">
        <mat-card-content class="p-0">
          <mat-sidenav-container fxFlex="0 1 auto">
            <mat-sidenav-content>
              <div class="table-container">
                <mat-table [dataSource]="dataSource" matSort>
                  <ng-container matColumnDef="baName">
                    <mat-header-cell *matHeaderCellDef>
                      Sales Person Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="baName">
                      {{ row.baName }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="area">
                    <mat-header-cell *matHeaderCellDef>
                      Area Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="area">
                      {{ row.area }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="tlName">
                    <mat-header-cell *matHeaderCellDef>
                      Sales Lead Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="tlName">
                      {{ row.tlName }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="tasksCountTarget">
                    <mat-header-cell *matHeaderCellDef>
                      Assigned Tasks
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="tasksCountTarget">
                      {{ row.tasksCountTarget }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="tasksCountAchieved">
                    <mat-header-cell *matHeaderCellDef>
                      Visits Count
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="tasksCountAchieved">
                      {{ row.tasksCountAchieved }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="buyingCountTarget">
                    <mat-header-cell *matHeaderCellDef>
                      Buying Target
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="buyingCountTarget">
                      {{ row.buyingCountTarget }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="buyingCountAchieved">
                    <mat-header-cell *matHeaderCellDef>
                      Buying Target Achieved
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="buyingCountAchieved">
                      {{ row.buyingCountAchieved }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="buyingRevenueAchieved">
                    <mat-header-cell *matHeaderCellDef>
                      Buying Revenue Achieved
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="buyingRevenueAchieved">
                      {{ row.buyingRevenueAchieved }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="nonBuyingCountTarget">
                    <mat-header-cell *matHeaderCellDef>
                      Non Buying Target
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="nonBuyingCountTarget">
                      {{ row.nonBuyingCountTarget }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="nonBuyingCountAchieved">
                    <mat-header-cell *matHeaderCellDef>
                      Non Buying Target Achieved
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="nonBuyingCountAchieved">
                      {{ row.nonBuyingCountAchieved }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="dayRevenueTarget">
                    <mat-header-cell *matHeaderCellDef>
                      Day Revenue Target
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="dayRevenueTarget">
                      {{ row.dayRevenueTarget }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="dayRevenueAchieved">
                    <mat-header-cell *matHeaderCellDef>
                      Day Revenue Achieved
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="dayRevenueAchieved">
                      {{ row.dayRevenueAchieved }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="dayTasksRevenueTarget">
                    <mat-header-cell *matHeaderCellDef>
                      Day Tasks Revenue Target
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="dayTasksRevenueTarget">
                      {{ row.dayTasksRevenueTarget }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="dayTasksRevenueAchieved">
                    <mat-header-cell *matHeaderCellDef>
                      Day Tasks Revenue Achieved
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="dayTasksRevenueAchieved">
                      {{ row.dayTasksRevenueAchieved }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="petrolAllowance">
                    <mat-header-cell *matHeaderCellDef>
                      Petrol Allowance
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="petrolAllowance">
                      {{ row.petrolAllowance }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="incentiveAllowance">
                    <mat-header-cell *matHeaderCellDef>
                      Incentive Allowance
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="incentiveAllowance">
                      {{ row.incentiveAllowance }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="attendanceEligibleCount">
                    <mat-header-cell *matHeaderCellDef>
                      Attendance Eligible
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="attendanceEligibleCount">
                      {{ row.attendanceEligibleCount }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="exception">
                    <mat-header-cell *matHeaderCellDef class="mat-column-actions">
                      Exception
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="mat-column-actions">
                      <div class="d-flex" *ngIf="hasEdit">
                        <button mat-raised-button color="primary" class="text-center ml" (click)="editSalesPersonPerformance(row,1,row.attendanceAllowance)">
                          Attendance
                        </button>
                        <button mat-raised-button color="primary" class="text-center mlbtn" (click)="editSalesPersonPerformance(row,2,row.petrolAllowance)">
                          Petrol
                        </button>
                        <button mat-raised-button color="primary" class="text-center ml" (click)="editSalesPersonPerformance(row,3,row.incentiveAllowance)">
                          Incentive
                        </button>
                      </div>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                </mat-table>
              </div>
            </mat-sidenav-content>
          </mat-sidenav-container>
          <mat-paginator  [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions" (page)="onPageChange($event)"></mat-paginator>
        </mat-card-content>
      </mat-card>
    </div>

  </div>
  <div class="" *ngIf="!isDataFound">
    <h2 class="noData">No Data Found</h2>
</div>

</ng-container>
} @else {
<ng-container>
  <p class="text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
}
