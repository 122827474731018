@if(hasView){
<ng-container>
  <div class="heading-block">
    <div class="row">
      <div class="col-md-6">
        <h2 class="card-title m-3">Companies</h2>
      </div>
      <!-- <div class="col-md-6 text-right">
        @if(permission?.create){
        <button mat-raised-button color="primary" class="topButtonSectionbtn">
          Add Company
        </button>
        }
      </div> -->
    </div>
  </div>
  <div class="search-block">
    <form [formGroup]="form">
      <section id="content">
        <div class="card">
          <div class="card-body card-padding">
            <div class="row">
              <div class="col-md-6" *ngIf="!showSelectedTable">
                <mat-form-field class="example-full-width">
                  <input matInput formControlName="companySearch" placeholder="Search by company name" />
                  <mat-error *ngIf="
                      form.controls['companySearch'].invalid &&
                      (form.controls['companySearch'].dirty ||
                        form.controls['companySearch'].touched)
                    ">
                    Please enter a company name.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3" *ngIf="!showSelectedTable">
                <button mat-raised-button color="primary" class="text-center ml-w" (click)="clearFilters()">
                  Clear Filters
                </button>
              </div>
              <div class="col-md-3">
                <button mat-raised-button color="primary" (click)="toggleCompaniesView()">
                  {{
                  showSelectedTable
                  ? "Go to Master Companies"
                  : "Go to Vendor Companies"
                  }}
                </button>
                <!-- <button
                  mat-raised-button
                  color="primary"
                  class="text-center ml-w"
                  (click)="showSelectedCompaniesTable()"
                >
                  Show Vendor Companies
                </button> -->
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  </div>

  <div class="table-block" [hidden]="showSelectedTable">
    <div class="m-3 flex flex-col md:flex-row justify-between">
      <mat-card class="p-0">
        <mat-card-content class="p-0">
          <mat-sidenav-container fxFlex="0 1 auto">
            <mat-sidenav-content>
              <div class="table-container">
                <mat-table [dataSource]="dataSource" matSort>
                  <ng-container matColumnDef="checkbox">
                    <mat-header-cell *matHeaderCellDef>
                      Select
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      <mat-checkbox color="primary" (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)"></mat-checkbox>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="id">
                      {{ row.id }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="name">
                      {{ row.name }}
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

                </mat-table>
              </div>

              <mat-paginator #allCompaniesPaginator [length]="totalCompanies" [pageSize]="pageSize"
                [pageSizeOptions]="[10, 25, 100, 250]" (page)="onPageChange($event)"></mat-paginator>
            </mat-sidenav-content>
          </mat-sidenav-container>
          <div class="d-flex justify-between mt-3 saveBtn">
            <button mat-raised-button color="primary" (click)="saveChanges()">
              Save Changes
            </button>
          </div>

        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div [hidden]="!showSelectedTable" class="table-block">
    <div class="m-3 flex flex-col md:flex-row justify-between">
      <mat-card class="p-0">
        <mat-card-content class="p-0">
          <mat-sidenav-container fxFlex="0 1 auto">
            <mat-sidenav-content>
              <div class="table-container">
                <mat-table [dataSource]="selectedCompaniesDataSource" matSort>
                  <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="id">
                      {{ row.company_id }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="name">
                      {{ row.company_name }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="delete">
                    <mat-header-cell *matHeaderCellDef>
                      Delete
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="delete">
                      @if(hasDelete){
                      <button mat-icon-button (click)="deleteCompany(row)">
                        <mat-icon color="warn">delete</mat-icon>
                      </button>
                      }
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *matHeaderRowDef="selectedDisplayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: selectedDisplayedColumns"></mat-row>
                </mat-table>
              </div>
              <mat-paginator #vendorCompaniesPaginator [length]="vendorCompanies" [pageSize]="pageS"
                [pageSizeOptions]="[25,50, 100, 250]" (page)="onPage($event)"></mat-paginator>
            </mat-sidenav-content>
          </mat-sidenav-container>
        </mat-card-content>

      </mat-card>
    </div>
  </div>
</ng-container>
} @else {
<ng-container>
  <p class="text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
}
