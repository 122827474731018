@if(hasCreate){
<div class="heading-block">
  <div class="row">
    <div class="col-md-6">
      <mat-icon class="backicon" [routerLink]="['/dashboard/coupon']">arrow_back</mat-icon>
      <span class="header">
        <h2 class="card-title m-2">Create Coupon</h2>
      </span>
    </div>
    <div class="col-md-6 text-right"></div>
  </div>
</div>
<form [formGroup]="form">
  <section id="content">
    <div class="container">

      <div class="card">
        <div class="card-body card-padding">
          <div class="row">
            <div class="col-md-6">
              <mat-label>Coupon Name</mat-label>
              <mat-icon class="required-star" aria-hidden="false" aria-label="Required" matSuffix>
                *
              </mat-icon>
              <mat-form-field class="full-width">
                <input matInput formControlName="name" name="name" placeholder="Coupon Name" />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-label>Scheme Display Name</mat-label>
              <mat-icon class="required-star" aria-hidden="false" aria-label="Required" matSuffix>
                *
              </mat-icon>
              <mat-form-field class="full-width">
                <input matInput formControlName="displayName" name="displayName" placeholder="Scheme Display Name" />
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-label>Description</mat-label>
              <mat-icon class="required-star" aria-hidden="false" aria-label="Required" matSuffix>
                *
              </mat-icon>
              <mat-form-field class="full-width">
                <textarea matInput rows="5" formControlName="description" placeholder="Description" maxlength="500">
                </textarea>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-label>Min Value</mat-label>
              <mat-icon class="required-star" aria-hidden="false" aria-label="Required" matSuffix>
                *
              </mat-icon>
              <mat-form-field class="full-width">
                <input matInput type="number" formControlName="minValue" name="minValue" placeholder="Min Value" min="0"
                  maxlength="10" />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-label>Max Value</mat-label>
              <mat-icon class="required-star" aria-hidden="false" aria-label="Required" matSuffix>
                *
              </mat-icon>
              <mat-form-field class="full-width">
                <input matInput type="number" formControlName="maxValue" name="maxValue" placeholder="Max Value" min="0"
                  maxlength="10" />
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <mat-label>Discount Percent</mat-label>
              <mat-icon class="required-star" aria-hidden="false" aria-label="Required" matSuffix>
                *
              </mat-icon>
              <mat-form-field class="full-width">
                <input matInput type="number" formControlName="discountPercent" name="discountPercent" min="0"
                  maxlength="5" />
                <mat-icon matSuffix>%</mat-icon>
              </mat-form-field>
            </div>
            <div class="col-md-6 my-auto">
              <Label>Status</Label>
              <mat-icon class="required-star" aria-hidden="false" aria-label="Required" matSuffix>
                *
              </mat-icon>
              <mat-radio-group name="active" formControlName="active">
                <mat-radio-button name="active" [value]="1" class="mx-12">Active</mat-radio-button>
                <mat-radio-button name="active" [value]="0">Inactive</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="col-md-6">
              <mat-label>Start Date</mat-label>
              <mat-icon class="required-star" aria-hidden="false" aria-label="Required" matSuffix>
                *
              </mat-icon>
              <mat-form-field class="full-width">
                <input matInput formControlName="startDate" placeholder="Start Date" [matDatepicker]="appDatepicker"
                  [readonly]="true" />

                <mat-datepicker-toggle matSuffix [for]="appDatepicker"></mat-datepicker-toggle>
              </mat-form-field>

              <mat-datepicker #appDatepicker></mat-datepicker>
            </div>
            <div class="col-md-6">
              <mat-label>End Date</mat-label>
              <mat-icon class="required-star" aria-hidden="false" aria-label="Required" matSuffix>
                *
              </mat-icon>
              <mat-form-field class="full-width">
                <input matInput formControlName="endDate" placeholder="End Date" [matDatepicker]="appDatepickerEnd"
                  [readonly]="true" />

                <mat-datepicker-toggle matSuffix [for]="appDatepickerEnd"></mat-datepicker-toggle>
              </mat-form-field>

              <mat-datepicker #appDatepickerEnd></mat-datepicker>
            </div>

            <div class="col-md-6">
              <mat-form-field class="full-width">
                <mat-label>Category</mat-label>
                <mat-select formControlName="categorySelection" multiple>
                  @for (category of categories; track category) {
                  <mat-option [value]="category">{{
                    category?.name
                    }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <mat-label>Business Types Selection</mat-label>
                <mat-select formControlName="businessTypeSelection" multiple>
                  <mat-option *ngFor="let item of businessTypes" [value]="item">{{ item.businessType }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-6 bl">
              <mat-form-field class="full-width">
                <input matInput [value]="productNames" placeholder="Product IDs" disabled style="color: black;"/>
              </mat-form-field>

              <div class="d-flex">
                <button mat-raised-button color="primary" (click)="openProductUploadModal()">
                  Upload Excel
                </button>
                <button mat-raised-button class="ms-2" color="primary" (click)="downloadExcelFormat('ProductFormat')">
                  Download Format
                </button>
              </div>
            </div>

            <div class="col-md-6" style="display:none">
              <mat-form-field class="full-width">
                <input matInput [value]="customerIds" placeholder="Customer Ids" disabled />
              </mat-form-field>

              <div class="d-flex">
                <button mat-raised-button color="primary" (click)="openCustomerUploadModal()">
                  Upload Excel
                </button>
                <button mat-raised-button class="ms-2" color="primary" (click)="downloadExcelFormat('CustomerFormat')">
                  Download Format
                </button>
              </div>
            </div>


            <!-- hide minimum and days since last purchase -->
            <div style="display:none" class="col-md-12">
              <div class="col-md-6">
                <mat-label>Minimum Category wise %</mat-label>
                <mat-form-field class="full-width">
                  <mat-label>Category</mat-label>
                  <mat-select [(ngModel)]="categoryWiseInput.minimumCategory" [ngModelOptions]="{ standalone: true }">
                    @for (category of categories; track category) {
                    <mat-option [value]="category">{{
                      category?.name
                      }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-label>Minimum Category</mat-label>
                <mat-form-field class="full-width">
                  <input matInput type="number" [(ngModel)]="categoryWiseInput.minimumCategoryDiscountPercent"
                    name="MinimumCategory" [ngModelOptions]="{ standalone: true }" placeholder="%" min="0"
                    maxlength="4" />
                </mat-form-field>

              </div>
              <div class="col-md-2 my-auto">
                <button mat-raised-button color="primary" id="#" (click)="addCategoryWise()">
                  Add
                </button>
              </div>
              <div class="col-md-12">
                <table class="table" *ngIf="
                  this.form.get('minimumCategoryWisePercent')?.value.length > 0
                ">
                  <thead>
                    <tr>
                      <th class="heading">Category Name</th>
                      <th class="heading">Minimum Percent</th>
                      <th class="heading">Action</th>
                    </tr>
                  </thead>
                  <tbody *ngFor="
                    let categoryWisePercent of this.form.get(
                      'minimumCategoryWisePercent'
                    )?.value;
                    let i = index
                  " style="font-size: 13px">
                    <tr>
                      <td>{{ categoryWisePercent.categoryName }}</td>
                      <td>{{ categoryWisePercent.minimumPercent }}</td>
                      <td>
                        <mat-icon color="warn" (click)="removeCategoryWisePercentFromList(i)">delete</mat-icon>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-6 mt-3">
                <mat-label>Days Since Last Purchase</mat-label>
                <mat-form-field class="full-width">
                  <input matInput type="number" formControlName="lastPurchasedDays"
                    placeholder="Days Since Last Purchase" min="0" maxlength="5" />
                </mat-form-field>
              </div>
            </div>

            <div class="col-md-12 m-3 text-center">
              <button mat-raised-button color="primary" (click)="saveCoupon()">
                Save
              </button>
            </div>
          </div>
          <br />
          <br />
        </div>
      </div>
    </div>
  </section>
</form>
}
