import { Component, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import {
  ExpenseType,
  LookUpType,
  PurchaseOrderStatus,
  TaxDeductionType,
  UOM,
} from '../../../enums/enums';
import { LookUpTypeService } from '../../../services/lookup-type.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PurchaseReceiptService } from '../service/purchasereceipt.service';
import imageCompression from 'browser-image-compression';

//import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-createpurchase-order',
  standalone: true,
  imports: [
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatCardModule,
    MatTableModule,
    RouterLink,
    MatMenuModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatOptionModule,
  ],
  templateUrl: './create-purchase-order.component.html',
  styleUrl: './create-purchase-order.component.scss',
})
export class CreatePurchaseOrderComponent {
  form: FormGroup;
  id: string = '';
  supplierId: string = '';
  displayedColumns: string[] = [
    'product',
    'uom',
    'mrp',
    'tax',
    'perpccost',
    'netpayable',
    'requestedQty',
    'actualQty',
    'action',
  ];
  dataSource1: any = [];
  dataSource2: any = [];
  uploadedFiles: any = {};
  purchaseBill: string = '';
  otherBills: string = '';
  res:any
  supplierNames: LookUpType = this.initializeSuppliers();
  purchaseOrderStatus: any = Object.values(PurchaseOrderStatus).filter(
    (value) => typeof value === 'number'
  );
  expensesType: any = Object.values(ExpenseType).filter(
    (value) => typeof value === 'number'
  );
  public suppliersList: any[] = [];
  displayedColumnss: String[] = [
    'expensestype',
    'totalvalue',
    'supplier',
    'action',
  ];
  displayedCreatePoColumns: string[] = [
    'gstTaxSlab',
    'taxableAmount',
    'cgst',
    'sgst',
    'cess',
    'tcstds',
    'totalBillAmount',
  ];
  dataSource = [
    {
      gstTaxSlab: 'sona Masoori 26 kg',
      taxableAmount: 'Bag',
      cgst: '1200',
      sgst: 'GST 5%',
      cess: '10',
      tcstds: '1000',
      totalBillAmount: '10,500',
    },
  ];
  constructor(
    private router: Router,
    private lookupService: LookUpTypeService,
    public fb: FormBuilder,
    public purchaseReceiptService: PurchaseReceiptService,
    public route: ActivatedRoute
  ) {
    this.form = this.fb.group({
      supplierName: '',
      status: null,
      productTaxType: '',
      createdDate: '',
      purchaseBill: '',
      otherBills: '',
      invoiceRfNo: '',
      supplierGstn: '',
      tdsPercent: '',
      tdsValue: '',
    });
  }
  status: { name: string; value: PurchaseOrderStatus }[] = [
    { name: 'Created', value: PurchaseOrderStatus.Created },
    { name: 'Requested For GRN', value: PurchaseOrderStatus.RequestedForGRN },
    { name: 'GRN Completed', value: PurchaseOrderStatus.GRNCompleted },
    {
      name: 'Requested For Approval',
      value: PurchaseOrderStatus.RequestedForApproval,
    },
    { name: 'Approved', value: PurchaseOrderStatus.Approved },
  ];

  taxes: { name: string; value: TaxDeductionType }[] = [
    { name: 'Inclusive', value: TaxDeductionType.Inclusive },
    { name: ' Exclusive', value: TaxDeductionType.Exclusive },
    { name: 'None', value: TaxDeductionType.None}
  ];

  async ngOnInit() {
    await this.getSuppliers();
    this.route.params.subscribe(async (params) => {
      this.id = params['id'];
      console.log('resource id is', this.id);
    });
    await this.getPurchaseReceipt(this.id);
    await this.getPurchaseReceiptSkus(this.id);
    await this.getExpenses(this.id);
  }

  async getPurchaseReceipt(id: string) {
    this.res = await this.purchaseReceiptService.getPurchaseReceiptById(id);
    this.supplierId = this.res.supplier_id;
    this.form = this.mapToEditInput(this.res);
  }
  async getSuppliers() {
    const response = await this.lookupService.getLookUpTypes(
      this.supplierNames
    );
    this.suppliersList = response.data[0].data;
    console.log('supliers list', this.suppliersList);
  }

  getSupplierNameByID(supplierId: string) {
    console.log('getSupplierNameByID::', supplierId);
    for (const supplier of this.suppliersList) {
      if (supplier.id == supplierId) {
        console.log('getSupplierNameByID::supplier.value', supplier);
        return supplier.value;
      }
    }
    return null;
  }

  private initializeSuppliers(): LookUpType {
    return {
      tableName: 'suppliers',
      lookupType: 'suppliers',
      idColumnName: 'Id',
      valueColumnName: 'Name',
    };
  }

  async getPurchaseReceiptSkus(prId: string) {
    const res = await this.purchaseReceiptService.getPurchaseReceiptItems(prId);
    this.dataSource1 = res;
    console.log('purchase receipt items arre ',res);
    return res
  }

  async getExpenses(id: string) {
    const res = await this.purchaseReceiptService.getPrExpenses(id);
    this.dataSource2 = res;
    console.log('purchase receipt expenses are', res);
    return res
  }
  navigateToAddProduct() {
    this.router.navigateByUrl(`/dashboard/pocreation/addproduct/${this.id}`);
  }

  navigateToAddExpenses() {
    this.router.navigateByUrl(
      `/dashboard/pocreation/addexpenses/${this.id}/${this.supplierId}`
    );
  }

  navigateBackToList() {
    this.router.navigate(['/dashboard/pocreation/createpurchaseorder']);
  }

  productEditComponent(id: string) {
    this.router.navigateByUrl(
      `/dashboard/pocreation/producteditComponent/${this.id}/${id}`
    );
  }

  expensesEditComponent(id: string) {
    this.router.navigateByUrl(
      `/dashboard/pocreation/edit-expenses/${this.id}/${id}`
    );
  }
  async onFileChange(event: any, documentType: string) {
    if (event.target.files) {
      const file = event.target.files[0];
      const filename = file.name;
      const reader = new FileReader();
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      reader.onload = async (event) => {
        const result = event?.target?.result;

        if (typeof result === 'string') {
          const base64String: string = await this.compressAndConvertToBase64(
            file,
            options
          );
          this.uploadedFiles[documentType] = {
            fileName: filename,
            fileExtension: this.getFileExtension(filename),
            fileData: base64String,
          };
        } else if (result instanceof ArrayBuffer) {
          console.error('Unsupported file type');
          // Handle this case if needed
        }
      };

      reader.readAsDataURL(file);
    }
  }
  async compressAndConvertToBase64(file: File, options: any): Promise<string> {
    try {
      const compressedFile = await imageCompression(file, options);
      const base64String: string = await imageCompression.getDataUrlFromFile(
        compressedFile
      );
      return base64String.split(',')[1];
    } catch (error) {
      console.error('Error compressing image:', error);
      throw error;
    }
  }

  private getFileExtension(filename: any) {
    return filename.split('.').pop();
  }
  async uploadFiles() {
    const documentTypes = ['purchase-bill', 'other-bills'];
    const urlEndpoints = ['purchase_bill', 'other_bills'];
    await Promise.all(
      documentTypes.map(async (documentType, index) => {
        const urlEndpoint = urlEndpoints[index];
        if (this.uploadedFiles.hasOwnProperty(documentType)) {
          const requestImageBody = this.uploadedFiles[documentType];
          await this.uploadAndSetUrl(
            requestImageBody,
            documentType,
            urlEndpoint
          );
        }
      })
    );
  }
  private async uploadAndSetUrl(
    requestImageBody: any,
    documentType: string,
    urlEndpoint: string
  ) {
    console.log("upload");

    try {
      const res: any = await this.purchaseReceiptService.billsUpload(
        requestImageBody,
        urlEndpoint
      );
      console.log('res :', res);
      switch (documentType) {
        case 'purchase-bill':
          this.purchaseBill = res.data.url;
          console.log('this.purchaseBill :', this.purchaseBill);
          break;
        case 'other-bills':
          this.otherBills = res.data.url;
          break;
        default:
          console.error('Invalid document type');
          break;
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  }
  async deleteExpense(id: string) {
    console.log('expense detail,s', id);
    const res = await this.purchaseReceiptService.deleteExpense(id);
    await this.getExpenses(this.id);
  }
  async deleteSku(id: string) {
    console.log('sku details areee', id);
    const res = await this.purchaseReceiptService.deletePrSkus(id);
    await this.getPurchaseReceiptSkus(this.id);
  }

  getStatusName(status: number): string {
    return PurchaseOrderStatus[status];
  }
  getTaxName(status:number):string{
    return TaxDeductionType[status]
  }
  getExpenseType(status: number): string {
    return ExpenseType[status];
  }
  getUomType(type:number):string{
    return UOM[type];
  }
  mapToEditInput(res: any): FormGroup {
    const suppliername = new FormControl(
      this.getSupplierNameByID(res.supplier_id) || ''
    );
    return this.fb.group({
      supplierName: suppliername,
      status: new FormControl(res.status || ''),
      productTaxType: new FormControl(res.tax_deduction_type || ''),
      createdDate: new FormControl(res.purchase_date || ''),
      purchaseBill: this.purchaseBill || '',
      otherBills: this.otherBills || '',
      invoiceRfNo: new FormControl(res.invoice_reference_number || ''),
      supplierGstn: new FormControl(res.supplier_gst || ''),
      tdsPercent: new FormControl(res.tds_percent || ''),
      tdsValue: new FormControl(res.tds_value || ''),
    });
  }

  async save() {
    const prDetails = this.form.value;
    await this.uploadFiles();
   const skus = await this.getPurchaseReceiptSkus(this.id);
    const expenses = await this.getExpenses(this.id);
    const finalPrObject = this.mapToObject(prDetails,skus,expenses)
    // const result  = await this.purchaseReceiptService.calculateValues(finalPrObject)
    console.log("final pr object is ", finalPrObject);
    

  }

  mapToObject(pr:any,skus:any,expenses:any){
    return{

        receiptId  :  this.id ,
        internalReceiptNumber  :  "" ,
        invoiceReferenceNumber  :  pr.invoiceRfNo,
        purchaseDate  :  pr.createdDate ,
        receivedDate  : pr.createdDate ,
        supplierId  : this.res.supplier_id,
        status  : pr.status,
        taxDeductionType  : pr.productTaxType,
        tcsPercent : 0,
        tcsValue : 0,
        tdsPercent  : pr.tdsPercent,
        tdsValue  : pr.tdsValue,
        comments  :  "hello"  ,
        createdBy  :  "" ,
        grnBy  :  "" ,
        approvalBy  :  "" ,
       files : [],
       items: skus,
       expenses: expenses,

    }
  }
}
