@if(hasView){
<div class="container-fluid p-2">
  <mat-card class="p-3">
    <div class="container-fluid">
      <div class="row">
     <form [formGroup]="queryForm" >
        <div class="col-md-12 my-2">
          <div class="d-flex">

          </div>
         <!--  <input type="text"
              class="form-control w-100"
              placeholder="Search sku by name"
              formControlName="name"> -->
              <div class="custom-search">
                <mat-icon class="mb-0 search-icon">search</mat-icon>

                <input type="text" class="form-control" placeholder="Search SKU by name"
                  formControlName="name">

              </div>
        </div></form>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 table-wrapper">
        <table mat-table [dataSource]="dataSource"
          class="mat-elevation-z8">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">{{ element.payload.sku.name }}</td>
          </ng-container>
          <ng-container matColumnDef="updated">
            <th mat-header-cell *matHeaderCellDef>Last Updated At</th>
            <td mat-cell *matCellDef="let element">{{ element.lastUpdatedAt.toDate() | date:'short' }}</td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element">{{ element?.payload?.sku?.status | titlecase }}</td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let element">
              <div class="d-flex ">
                @if(hasEdit){
                <button mat-raised-button class="ms-2" color="primary"
                [routerLink]="['/dashboard/product/sku/draft-edit',element.id]">
                <mat-icon class="me-0">edit</mat-icon>

              </button>
            }
            @if(hasDelete){
              <button mat-raised-button color="primary" class="mx-2" (click)="discardPendingDoc(element.id)"><mat-icon
                class="me-0">delete</mat-icon></button>
              }
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        </table>
        <div class="d-flex justify-content-center my-2">
          @if (loading) {
          <mat-spinner [diameter]="30"></mat-spinner>
          }
        </div>
        <div class="d-flex justify-content-center my-2">
          @if (dataSource?.data?.length==0 && !loading) {
          <p class="no-data-found-text">No record found</p>
          }
        </div>

      </div>
    </div>
  </mat-card>
</div>
}
@else {
<p class="text-center my-5">You dont have permission to access this</p>
}

