import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { Observable, from, throwError, BehaviorSubject, firstValueFrom } from 'rxjs';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { inject } from '@angular/core';

let refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
let isRefreshing = false;
let isGettingToken = false; 
export const TokenInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);

  if (req.headers.has('No-Auth')) {
    const newHeaders = req.headers.delete('No-Auth');
    const authReq = req.clone({ headers: newHeaders });
    return next(authReq);
  }

  return from(getAuthToken(authService)).pipe(
    switchMap(authToken => {
      authToken = authToken || authService.apiAuthToken || '';
      // console.log('from authtoken', authToken);
      if (!authToken) {
        // If no token is available, throw an error to prevent recursive calls
        console.warn('No initial auth token available, attempting retrieval');
        
        //return throwError(() => new Error('No auth token available'));
      }
      return handleRequestWithToken(req, next, authToken);
    }),
    catchError((err: any) => handleError(err, req, next, authService))
  );

  async function getAuthToken(authService: AuthService): Promise<string | null> {
    if (isGettingToken) {
      return await firstValueFrom(refreshTokenSubject);
    }
    
    isGettingToken = true;
    let authToken = await firstValueFrom(authService.authAPIToken$);
    
    // if (!authToken) {
    //   const userId = authService.UserId;
    //   if (!userId) {
    //     return null;
    //   }
  
    //   try {
    //     authToken = await authService.getAPIToken(userId);
    //     console.log('got the authtoken',authToken)
    //   } catch (error) {
    //     console.error('Error retrieving API token:', error);
    //     return null;
    //   }
    // }
  
    isGettingToken = false;
    return authToken;
  }
  function handleRequestWithToken(req: HttpRequest<any>, next: HttpHandlerFn, token: string): Observable<HttpEvent<any>> {
    const authReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
    return next(authReq).pipe(
      catchError((err: any) => throwError(() => err))
    );
  }

  function handleError(err: any, req: HttpRequest<any>, next: HttpHandlerFn, authService: AuthService): Observable<HttpEvent<any>> {
    if (err instanceof HttpErrorResponse && err.status === 401) {
      console.error('Unauthorized request:', err);

      if (!isRefreshing) {
        isRefreshing = true;
        refreshTokenSubject.next(null);

        const refreshToken = authService.apiAuthToken;

        return from(authService.refreshAPIToken(refreshToken)).pipe(
          switchMap(newToken => {
            if (!newToken) {
              // If refreshing the token fails, handle the error properly
              console.error('Unable to refresh token');
              return throwError(() => new Error('Unable to refresh token'));
            }

            isRefreshing = false;
            refreshTokenSubject.next(newToken);
            return handleRequestWithToken(req, next, newToken);
          }),
          catchError(refreshError => {
            isRefreshing = false;
            console.error('Refresh token failed:', refreshError);
            return throwError(() => refreshError);
          })
        );
      } else {
        return refreshTokenSubject.pipe(
          filter(token => token !== null),
          take(1),
          switchMap(token => handleRequestWithToken(req, next, token!))
        );
      }
    } else {
      console.error('HTTP error:', err);
      return throwError(() => err);
    }
  }
};
