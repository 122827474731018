import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterLink, RouterOutlet } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { HopSummaryComponent } from './hop-summary/hop-summary.component';
import { OrderViewComponent } from './order-view/order-view.component';
import { ProductViewComponent } from './product-view/product-view.component';
import { Location } from '@angular/common';
@Component({
  selector: 'hop-details',
  standalone: true,
  templateUrl: './hop-details.component.html',
  styleUrl: './hop-details.component.scss',
  imports: [
    RouterOutlet,
    MatTabsModule,
    CommonModule,
    MatIconModule,
    RouterLink,
    HopSummaryComponent,
    OrderViewComponent,
    ProductViewComponent,
  ],
})
export class HopDetailsComponent {
  id: any;
  dataSource: any;
  constructor(
    private readonly route: ActivatedRoute,
    private location: Location

  ) { }
  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      this.id = params['id'];
    });
  }

  goBack() {
    this.location.back();
  }
}
