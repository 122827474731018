import { Component } from '@angular/core';
import { FormBuilder, Validators, ReactiveFormsModule, FormsModule, FormGroup, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { LocationsService } from '../../services/locations.service';
import { LocationEntity, LocationForm, PropertyValidation } from '../../models/locations.model';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AppLoaderService } from '../../../../../shared/app-loader/app-loader.service';
import { AuthService } from '../../../../../services/auth.service';
import { SwalMessageTypes, SupplierStatus, states } from '../../../../../enums/enums';
import Swal from 'sweetalert2';
import { applyMaxLengthValidation, hasSpecialCharacters, supplierListSearchInput, validateGST, validateMaxText, validateNegativeValues, containsOnlyAlphanumeric, validateText, validatePinCode } from '../../../../../utlity/utility';
import { State } from '../../../supplier/models/supplier-model';
import { BaseListComponent } from '../../../../../shared/core/base.list.component';
import { PageId } from '../../../../../constants/enums';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-edit-location',
  standalone: true,
  imports: [MatCardModule, MatButtonModule, MatTableModule, RouterLink, MatInputModule,
    MatFormFieldModule, ReactiveFormsModule, MatSelectModule, MatIconModule,
    CommonModule, FormsModule, MatDatepickerModule, MatRadioModule, MatNativeDateModule,
    MatPaginatorModule, MatSidenavModule, MatDatepickerModule, MatCheckboxModule],
  templateUrl: './edit-location.component.html',
  styleUrls: ['./edit-location.component.scss']
})
export class EditLocationComponent extends BaseListComponent {
  singularTypes = Object.values(SupplierStatus).filter(value => typeof value === 'number')
  dataSource: any;
  id: string = "";
  locationEntity!: LocationEntity;
  states: State[] = states;

  constructor(
    auth: AuthService,
    router: Router,
    private fb: FormBuilder,
    route: ActivatedRoute,
    public readonly locationService: LocationsService,
    private loader: AppLoaderService,
   ) {
      super(auth, router, route, PageId.logistics_location);
    this.dataSource = this.fb.group({
      name: [null, [Validators.required, this.alphabeticValidator()]],
      warehouseName: [null, [Validators.required, this.alphabeticValidator()]],
      area: [null, [Validators.required]],
      state: [null, [Validators.required]],
      pincode: [null, [Validators.required]],
      city: [null, [Validators.required, this.alphabeticValidator()]],
      country: [null, [Validators.required, this.alphabeticValidator()]],
      latitude: [null, [Validators.required]],
      longitude: [null, [Validators.required]],
    });
  }

  override async ngOnInit() {
  await super.ngOnInit();
    this.route.params
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(async (params) => {
      this.id = params['id'];
    });
    await this.getLocationById();
    this.alphabeticValidator()

  }

  async getLocationById() {
    try {
      this.loader.open();
      const response = await this.locationService.getLocationById(this.id);
      this.dataSource = this.mapToEditInput(response.data[0]);
    } catch (error) {
      this.showMessage('Please fill all required fields with valid data.', SwalMessageTypes.Error);
    } finally {
      this.loader.close();

    }
  }

  async updateLocation() {
    this.loader.open();

    try {
      if (this.dataSource.invalid) {
        this.showMessage('Please fill all required fields & valid data.', SwalMessageTypes.Warning);

        return
      }
      await this.updateLocationById();
    } catch (error) {
      console.error('Error occurred during location creation:', error);
      this.showMessage('"Please fill all required fields with valid data.', SwalMessageTypes.Error);
    } finally {
      this.loader.close();
    }
  }

  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  alphabeticValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const alphabeticRegex = /^[a-zA-Z\s]*$/; // Regular expression to match alphabetic characters and spaces
      const valid = alphabeticRegex.test(control.value);
      return valid ? null : { 'alphabetic': true }; // Return validation error if the value contains non-alphabetic characters
    };
  }

  private mapLocation(location: LocationForm): LocationEntity {
    const form = location;
    return {
      id: form?.id,
      name: form?.name,
      warehouseName: form?.warehouseName,
      area: form?.area,
      state: form?.state,
      pincode: form?.pincode,
      city: form?.city,
      country: form?.country,
      latitude: form?.latitude,
      longitude: form?.longitude
    }
  }

  async updateLocationById() {
    const locationEntity = this.mapLocation(this.dataSource.value);

    try {
      console.log("this.dataSource", this.dataSource);
      if (this.validLocationData(locationEntity)) {
        console.log("1")
        const response = await this.locationService.updateLocation(locationEntity, this.id);
        this.showMessage('Location updated successfully', SwalMessageTypes.Success);
        this.router.navigateByUrl('/dashboard/logistics/location-list');
      }

    } catch (error) {
      console.error('Error occurred while creating location:', error);
      this.showMessage('Please fill all required fields .', SwalMessageTypes.Error);
    }
  }



  private validLocationData(locationEntity: LocationEntity): boolean {


    if (!locationEntity.name || containsOnlyAlphanumeric(locationEntity.name)) {

      this.showMessage('Please enter valid name .', SwalMessageTypes.Warning);

      return false;
    }
    if (!locationEntity.warehouseName || containsOnlyAlphanumeric(locationEntity.warehouseName)) {

      this.showMessage('Please enter valid warehouse name .', SwalMessageTypes.Warning);

      return false;
    }
    if (!locationEntity.area || containsOnlyAlphanumeric(locationEntity.area)) {

      this.showMessage('Please enter valid area .', SwalMessageTypes.Warning);

      return false;
    }
    if (!locationEntity.state || containsOnlyAlphanumeric(locationEntity.state)) {

      this.showMessage('Please enter valid state .', SwalMessageTypes.Warning);

      return false;
    }

    if (!locationEntity.pincode || !validatePinCode(+locationEntity.pincode)) {

      this.showMessage('Please enter valid pincode .', SwalMessageTypes.Warning);

      return false;
    }

    if (!locationEntity.city || containsOnlyAlphanumeric(locationEntity.city)) {

      this.showMessage('Please enter valid city .', SwalMessageTypes.Warning);

      return false;
    }
    if (!locationEntity.country || containsOnlyAlphanumeric(locationEntity.country)) {

      this.showMessage('Please enter valid country .', SwalMessageTypes.Warning);

      return false;
    }
    if (!locationEntity.latitude || +locationEntity.latitude < 0) {

      this.showMessage('Please enter valid latitude .', SwalMessageTypes.Warning);

      return false;
    }
    if (!locationEntity.longitude || +locationEntity.longitude < 0) {

      this.showMessage('Please enter valid longitude .', SwalMessageTypes.Warning);

      return false;
    }

    return true;

  }


  private mapToEditInput(location: LocationEntity): FormGroup {
    const entity = location;
    return this.fb.group({
      id: new FormControl(entity.id || ""),
      name: new FormControl(entity.name || ""),
      warehouseName: new FormControl(entity.warehouseName || ""),
      area: new FormControl(entity.area || ""),
      state: new FormControl(entity.state || ""),
      pincode: new FormControl(entity.pincode || 0),
      city: new FormControl(entity.city || ""),
      country: new FormControl(entity.country || ""),
      latitude: new FormControl(entity.latitude || 0),
      longitude: new FormControl(entity.longitude || 0)
    });
  }


}
