import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { CompanyListComponent } from '../../../company/company-list/company-list.component';
import { InfoComponent } from '../../../settings/vendor-list/info/info.component';
import { MarginComponent } from '../../../settings/vendor-list/margin/margin.component';
import { VendorSettingsComponent } from '../../../settings/vendor-list/vendor-settings/vendor-settings.component';
import { CreateShopSettingComponent } from '../../../shopsetting/components/create-shopsetting/create-shopsetting.component';
import { AddressInformationComponent } from "./address-information/address-information.component";
import { ProfileInformationComponent } from "./profile-information/profile-information.component";
import { BaseListComponent } from '../../../../shared/core/base.list.component';
import { AuthService } from '../../../../services/auth.service';
import { PageId } from '../../../../constants/enums';

@Component({
  selector: 'app-customers-details',
  standalone: true,
  templateUrl: './customers-details.component.html',
  styleUrl: './customers-details.component.scss',
  imports: [RouterOutlet, MatTabsModule,
    InfoComponent,
    MarginComponent,
    CommonModule,
    VendorSettingsComponent,
    CreateShopSettingComponent,
    CompanyListComponent,
    MatIconModule,
    RouterLink,
    AddressInformationComponent,
    ProfileInformationComponent]
})
export class CustomersDetailsComponent extends BaseListComponent {
  id: any;
  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
  ) {
    super(auth, router, route, PageId.bms_customers);
   }

  override async ngOnInit() {
    await super.ngOnInit();

  }
}
