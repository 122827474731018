import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { AttributeService } from '../services/attribute.service';
import { lastValueFrom, takeUntil } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { AuthService } from '../../../services/auth.service';
import { ProductService } from '../../product/services/product.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from '../../../services/common.service';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { PageId } from '../../../constants/enums';

@Component({
  selector: 'app-attribute-add-edit',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    RouterLink,
    ReactiveFormsModule,
    MatIconModule
  ],
  templateUrl: './attribute-add-edit.component.html',
  styleUrl: './attribute-add-edit.component.scss',
})
export class AttributeAddEditComponent extends BaseListComponent {
  attributeForm: any;
  id: any;
  comment:any=""
  constructor(
     auth:AuthService,
     route: ActivatedRoute,
     router: Router,
    public fb: FormBuilder,
    public attributeService: AttributeService,
    public location: Location,
    public productService:ProductService,
    public commonService: CommonService
  ) {
    super(auth, router, route, PageId.bms_attribute);
    this.attributeForm = this.fb.group({
      name: ['',[Validators.required]],
    });
  }
  override async  ngOnInit() {
    await super.ngOnInit();
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.id = params['id'];
      if (this.id) {
        this.patchValue();
      }
    });
  }
  async patchValue() {
    this.commonService.showLoder()
    if(this.hasBMSAdmin){
      let res: any = (await this.attributeService.getSingle(this.id) as any).data[0].attributes
    this.attributeForm.patchValue(res);
    }
    if(this.hasSellerAdmin){
      this.productService.getSingleApprovalDoc(this.id).pipe(takeUntil(this.unsubscribe$)).subscribe((docData:any)=>{
        let res: any = docData.payload
        this.attributeForm.patchValue(res);
        this.comment=docData?.comment
      },
      (error:any)=>{
        this.commonService.showToaster("error",error.message)
        this.commonService.hideLoder()
      })
    }
    this.commonService.hideLoder()
  }
  async submit() {
    this.commonService.showLoder()
    try {

      try {
        if (this.id) {
          if(this.hasBMSAdmin){
            await this.attributeService.update(this.id, this.attributeForm.value);
            this.commonService.showToaster("success",'Updated Successfully')
            this.router.navigateByUrl('/dashboard/attribute');
          }
          if(this.hasSellerAdmin){
            this.productService.reSubmitRequestForApproval(this.id,this.attributeForm.value)
           this.commonService.showToaster("success",'Your request is submitted for approval')
           this.router.navigateByUrl('/dashboard/my-requests');
          }
        } else {
          if(this.hasBMSAdmin){
            await this.attributeService.add(this.attributeForm.value);
            this.commonService.showToaster("success",'Added Successfully')
            this.router.navigateByUrl('/dashboard/attribute');
          }
          if(this.hasSellerAdmin){
            this.productService.addToPendingApproval("add-attribute",this.attributeForm.value,"pending-approval")
           this.commonService.showToaster("success",'Your request is submitted for approval')
           this.router.navigateByUrl('/dashboard/my-requests');
          }

        }
        this.commonService.hideLoder()
        this.attributeForm.reset();

      } catch (err:any) {
        this.commonService.showToaster("error",err.error.message)
        this.commonService.hideLoder()
      }
    } catch (err:any) {
      this.commonService.showToaster("error",err.error.message)
      this.commonService.hideLoder()
    }
  }

  back() {
    this.location.back();
  }

}
