import { AppUrlType, LookUpType, PromotionType } from './../../../../enums/enums';
import { Component } from '@angular/core';
import { FormBuilder, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { PromotionsService } from '../../services/promotions.service';
import { PromotionEntity, PromotionListSearch, PromotionForm, PropertyValidation, MultiPropertyValidation, ValidationResult } from '../../models/promotions.model';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe, CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AppLoaderService } from '../../../../shared/app-loader/app-loader.service';
import { AuthService } from '../../../../services/auth.service';
import { SwalMessageTypes, SupplierStatus } from '../../../../enums/enums';
import Swal from 'sweetalert2';
import { YYYYMMDD, applyMaxLengthValidation, getCategories, getEnumKeyValueList, getPromotionType, guid, hasSpecialCharacters, isEmptyValue, isValidUrl, supplierListSearchInput, uuidv4, validateGST, validateMaxLength, validateMaxText, validateNegativeValues, validateNoNegativeValues, validateSpecialCharacters, validateText } from '../../../../utlity/utility';
import { BrandService } from '../../../brand/services/brand.service';
import { CategoryService } from '../../../category/services/category.service';
import { MerchantsService } from '../../../merchant/services/merchants.service';
import { LookUpTypeService } from '../../../../services/lookup-type.service';
import imageCompression from 'browser-image-compression';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Observable, map, startWith } from 'rxjs';
import { ExcelExportService } from '../../../../services/excel.service';
import { UploadSkuDialogComponent } from '../upload-sku-dialog/upload-sku-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { BaseListComponent } from '../../../../shared/core/base.list.component';
import { PageId } from '../../../../constants/enums';
@Component({
  selector: 'app-create-promotion',
  standalone: true,
  imports: [MatCardModule, MatButtonModule, MatTableModule, RouterLink, MatInputModule,
    MatFormFieldModule, ReactiveFormsModule, MatSelectModule, MatIconModule,
    CommonModule, FormsModule, MatDatepickerModule, MatRadioModule, MatNativeDateModule,
    MatPaginatorModule, MatSidenavModule, MatDatepickerModule, MatCheckboxModule, MatAutocompleteModule, AsyncPipe],
  templateUrl: './create-promotion.component.html',
  styleUrls: ['./create-promotion.component.scss']
})
export class CreatePromotionComponent extends BaseListComponent {
  singularTypes = Object.values(SupplierStatus).filter(value => typeof value === 'number');
  selectedPromoType: number = 1;
  permission: any = false;
  dataSource: any;
  promotionEntity!: PromotionEntity;
  promotionTypeList: Array<any> = [];
  categoryList: any = [];
  brandList: any = [];
  skuList: Array<any> = [];
  merchantListSearchInput: LookUpType = this.initializeLookUpTypeSearchInput();
  merchantList: Array<any> = [];
  merchantSearchList: Array<any> = [];
  uploadedFiles: any = {};
  image_url: string = "";
  promoId: string = "";
  displayedColumns: any = ['skuId'];
  yesterday = new Date();
  previewImage: any;
  appUrlTypeList: Array<any> = [];
  constructor(private fb: FormBuilder,
    router: Router,
    private promotionsService: PromotionsService,
    private loader: AppLoaderService,
    auth: AuthService,
    route: ActivatedRoute,
    public brandService: BrandService,
    public categoryService: CategoryService,
    public readonly merchantService: MerchantsService,
    private lookupService: LookUpTypeService,
    private excelService: ExcelExportService,
    private dialog: MatDialog) {
    super(auth, router, route, PageId.bms_promotions);

    this.dataSource = this.fb.group({
      id: [null],
      promoType: [1, [Validators.required]],
      promoName: [null, [Validators.required]],
      promoImageUrl: [null],
      vendorSelected: [null],
      vendorSearch: [null],
      categorySearch: [null],
      brandSearch: [null],
      categories: [],
      brands: [null],
      expiryDate: [null, [Validators.required]],
      displayName: [null, [Validators.required]],
      sequence: [null],
      status: [true, [Validators.required]],
      appUrl: [0, [Validators.required]],
      webUrl: [null]
    });
    this.yesterday.setDate(this.yesterday.getDate() - 0);
  }

  override async ngOnInit() {
    await super.ngOnInit();

    this.promotionTypeList = getPromotionType();
    this.appUrlTypeList = getEnumKeyValueList(AppUrlType);
    // const queryObj = { level: 1, sort_by: "display_order", sort_order: "asc" };
    // this.categoryService.getAll(queryObj).subscribe((res: any) => {
    //   this.categoryList = res.data
    // })
    // this.brandService.getAll().subscribe((res: any) => {
    //   this.brandList = res.data
    // })
    await this.getMerchants();
    this.promoId = guid();
  }

  onKey(value: any) {
    const searchKeyword = value.target.value || "";
    this.merchantSearchList = this.search(searchKeyword);
  }
  async onChangeVendor(value: any) {
    const vendorSelected = this.dataSource.get("vendorSelected")?.value || "";
    if (isEmptyValue(vendorSelected)) {
      this.showMessage('Please select the vendor first!', SwalMessageTypes.Warning);
      return;
    }
    try {
      this.loader.open();
      this.dataSource.get('categories')?.reset();
      this.dataSource.get('brands')?.reset();
      const searchKeyword = "";
      this.categoryList = await this.searchCategory(vendorSelected, searchKeyword);
      this.brandList = await this.searchBrand(vendorSelected, searchKeyword);
      this.loader.close();
    } catch (error) {
      this.loader.close();
    }

  }


  search(value: string) {
    let filter = value.toLowerCase();
    return this.merchantList.filter(option => option.value.toLowerCase().includes(filter));
  }
  async searchCategory(vendorId: string, searchKeyword: string) {
    let searchTextLowerCase = searchKeyword.toLowerCase();
    return this.promotionsService.getCategoriesByVendor(vendorId, searchTextLowerCase, "1");
  }
  async searchBrand(vendorId: string, searchKeyword: string) {
    let searchTextLowerCase = searchKeyword.toLowerCase();
    return this.promotionsService.getBrandByVendor(vendorId, searchTextLowerCase);
  }
  downloadExcelFormat(formate: string) {
    const sampleData: Array<any> = this.getSampleExcelExportData(formate)
    this.excelService.downloadSampleUploadFile(formate, sampleData);
  }
  private getSampleExcelExportData(formate: string) {
    return [
      {
        "Sku Id": ""
      },
    ];
  }
  openUploadSkuDialog() {
    const dialogRef = this.dialog.open(UploadSkuDialogComponent, {
      width: '40%',
      height: '60%',
      data: { control: "" },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result?.skusFromExcel) {
        this.skuList = result?.skusFromExcel;
      }
    });
  }
  async getMerchants() {
    try {
      const response = await this.lookupService.getLookUpTypes(this.merchantListSearchInput);
      this.merchantList = response.data[0].data;
      this.merchantSearchList = response.data[0].data;
    } catch (error) {
      this.loader.close();
      this.showMessage('Failed to Fetch Merchants. Please try again later.', SwalMessageTypes.Error);
    }
  }
  private initializeLookUpTypeSearchInput(): LookUpType {
    return {
      tableName: "vendor",
      lookupType: "vendor",
      idColumnName: "id",
      valueColumnName: "business_name"
    };
  }
  async onFileChange(event: any, documentType: string) {
    if (event.target.files) {
      const file = event.target.files[0];
      const filename = file.name;
      const reader = new FileReader();
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      reader.onload = async (event) => {
        const result = event?.target?.result;
        this.previewImage = result;
        if (typeof result === 'string') {
          const base64String: string = await this.compressAndConvertToBase64(
            file,
            options
          );
          this.uploadedFiles[documentType] = {
            fileName: this.promoId,
            fileExtension: this.getFileExtension(filename),
            fileData: base64String,
          };
        } else if (result instanceof ArrayBuffer) {
          console.error('Unsupported file type');
          // Handle this case if needed
        }
      };

      reader.readAsDataURL(file);
    }
  }
  async compressAndConvertToBase64(file: File, options: any): Promise<string> {
    try {
      const compressedFile = await imageCompression(file, options);
      const base64String: string = await imageCompression.getDataUrlFromFile(
        compressedFile
      );
      return base64String.split(',')[1];
    } catch (error) {
      console.error('Error compressing image:', error);
      throw error;
    }
  }
  private getFileExtension(filename: any) {
    return filename.split('.').pop();
  }
  async savePromotion() {
    this.loader.open();
    try {
      if (this.dataSource.invalid) {
        this.showMessage('Please fill all required fields.', SwalMessageTypes.Warning);
        return
      }
      const promoType = this.dataSource.get("promoType")?.value || 0;
      const promoName = this.dataSource.get("promoName")?.value || "";
      if (promoType == PromotionType.Banner && isEmptyValue(promoName)) {
        this.showMessage('Please enter Promotion Name.', SwalMessageTypes.Warning);
        return
      }
      const promoImage = this.uploadedFiles["promo_image"]?.fileName || "";
      if (isEmptyValue(promoImage)) {
        this.showMessage('Please upload image.', SwalMessageTypes.Warning);
        return
      }
      const webUrl = this.dataSource.get("webUrl")?.value || "";
      if (!isEmptyValue(webUrl) && !isValidUrl(webUrl)) {
        this.showMessage('Please enter valid web URL!', SwalMessageTypes.Warning);
        return
      }

      if (this.dataSource.value.vendorSelected == null) {
        this.showMessage('Please Select any Vendor!', SwalMessageTypes.Warning);
        return
      }

      // if (this.dataSource.value.categories == null) {
      //   this.showMessage('Please Select any categories!', SwalMessageTypes.Warning);
      //   return
      // }
      // if (this.dataSource.value.brands.length == null) {
      //   this.showMessage('Please Select any brands!', SwalMessageTypes.Warning);
      //   return
      // }


      //await this.validateInput();
      await this.uploadFiles();
      await this.createPromotion();
    } catch (error) {
      console.error('Error occurred during promotion creation:', error);
      this.showMessage('Promotion Create Failed', SwalMessageTypes.Warning);
    } finally {
      this.loader.close();
    }
  }

  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }


  private mapPromotion(promotion: PromotionForm): PromotionEntity {
    const mapForm = promotion
    const skuList = this.skuList.map(sku => { return sku.skuId });
    return {
      id: this.promoId,
      promoType: mapForm?.promoType || 1,
      promoName: mapForm?.promoName || "",
      promoImageUrl: this.image_url ? this.image_url : "",
      vendorId: mapForm?.vendorSelected ? mapForm?.vendorSelected : "",
      categories: JSON.stringify(mapForm?.categories ? mapForm?.categories : []),
      brands: JSON.stringify(mapForm?.brands ? mapForm?.brands : []),
      skus: JSON.stringify(skuList.length > 0 ? skuList : []),
      expiryDate: YYYYMMDD(new Date(mapForm?.expiryDate)),
      displayName: mapForm?.displayName || "",
      sequence: mapForm?.sequence || 0,
      status: mapForm?.status || true,
      appUrl: mapForm?.appUrl || 0,
      webUrl: mapForm?.webUrl || ""
    }
  }

  async createPromotion() {
    const promotionEntity = this.mapPromotion(this.dataSource.value);
    console.log("promotionEntity::", promotionEntity);
    try {
      const response = await this.promotionsService.createPromotion(promotionEntity);
      if (response) {
        this.showMessage('Promotion created successfully', SwalMessageTypes.Success);
        this.router.navigateByUrl('/dashboard/promotion');
      } else {
        this.showMessage('Something Went Wrong while creating promotion', SwalMessageTypes.Warning);
        return;
      }
    } catch (error) {
      // console.error('Error occurred while creating promotion:', error);
      // this.showMessage('Promotion Not Created!', SwalMessageTypes.Error);
      this.showMessage('Something Went Wrong while creating promotion', SwalMessageTypes.Warning);
      return;
    }
  }

  // private Functions
  async validateInput() {
    const result = this.validateMaxLength(this.dataSource.value);
    if (!result.isValid) {
      const errorMessage = this.createMaxLengthMsg(result.failedFields || []);
      this.showMessage(errorMessage, SwalMessageTypes.Warning);
      throw new Error('validateMaxLength.');
    }
    const validateNegativeValues = this.validateNegativeValues(
      this.dataSource.value
    );
    if (!validateNegativeValues.isValid) {
      const message = this.generateErrorMessage(
        validateNegativeValues.failedFields
      );
      this.showMessage(message, SwalMessageTypes.Warning);
      throw new Error('validateNegativeValues.');
    }
  }
  private validateMaxLength(data: any) {
    const validations: MultiPropertyValidation[] = [
      { field: 'promoName', property: data.promoName, maxLength: 250 },
      { field: 'displayName', property: data.displayName, maxLength: 250 },
    ];
    return validateMaxLength(validations);
  }
  private createMaxLengthMsg(
    failedFields: { field: string; failedField: string }[]
  ): string {
    const errorMessages: { [key: string]: string } = {
      promoName: 'Promotion Name (250 characters)',
      displayName: 'Display Name (250 characters)'
    };

    const failedFieldNames = failedFields.map((failedField) => {
      return errorMessages[failedField.field];
    });

    return (
      'The input lengths for ' +
      failedFieldNames.join(', ') +
      ' do not match. Please ensure you enter the correct lengths for each field before proceeding.'
    );
  }
  private validateNegativeValues(data: any): ValidationResult {
    const numericInputs = [
      { field: 'sequence', value: data.sequence }
    ];

    const validation = validateNoNegativeValues(
      numericInputs.map((input) => input)
    );
    return {
      isValid: validation.isValid,
      failedFields: validation.failedFields,
    };
  }

  private async uploadAndSetUrl(
    requestImageBody: any
  ) {
    console.log("upload");

    try {
      const res: any = await this.promotionsService.uploadPromotionImageDocs(
        requestImageBody.fileName,
        requestImageBody.fileData,
        requestImageBody.fileExtension
      );
      console.log('res :', res);
      this.image_url = res.data.url;
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  }
  async onPromoTypeChange(event: any) {
    this.selectedPromoType = event.value || 1;
    if (this.selectedPromoType == 2) {
      await this.getInventoryMerchants();
    } else {
      await this.getMerchants();
    }

  }


  async getInventoryMerchants() {
    try {
      const response = await this.promotionsService.getInventoryVendor();
      this.merchantList = response;
      this.merchantSearchList = response;
    } catch (error) {
      this.loader.close();
      this.showMessage('Failed to Fetch Merchants. Please try again later.', SwalMessageTypes.Error);
    }
  }



  private generateErrorMessage(
    failedFields: { field: string; failedField: string }[] | undefined
  ): string {
    let message = 'The following fields contain invalid numeric values:';
    if (failedFields) {
      failedFields.forEach((failedField) => {
        message += ` ${failedField.field} (${failedField.failedField}),`;
      });
      message = message.slice(0, -1) + '.';
    }
    return message;
  }
  async uploadFiles() {
    console.log("upload ifle");

    const documentTypes = ['promo_image'];
    const urlEndpoints = ['promo_image_url'];

    await Promise.all(
      documentTypes.map(async (documentType, index) => {
        const urlEndpoint = urlEndpoints[index];
        if (this.uploadedFiles.hasOwnProperty(documentType)) {
          const requestImageBody = this.uploadedFiles[documentType];
          await this.uploadAndSetUrl(
            requestImageBody
          );
        }
      })
    );
  }
}
