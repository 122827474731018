@if(value == 'approved'){<span class="text-success">
  Approved
</span>
}
@if(value == 'draft'){<span class="text-dark">
  Draft
</span>
}
@if(value == 'pending-approval'){<span class="text-warning">
  Pending Approval
</span>
}
@if(value == 'rejected'){<span class="text-danger">
  Rejected
</span>
}