import { CommonModule, JsonPipe } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import Swal from 'sweetalert2';
import { DownloadType, WarehouseItemStatus } from '../../../../enums/enums';
import { getDateYYYYMMDD } from '../../../../utlity/utility';
import { SellerReportsService } from '../../../seller-reports/services/seller-reports.service';
import { BaseListComponent } from '../../../../shared/core/base.list.component';
import { AuthService } from '../../../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PageId } from '../../../../constants/enums';
import { MerchantsService } from '../../../merchant/services/merchants.service';

@Component({
  selector: 'app-logistics-reports',
  standalone: true,

  imports: [
    MatFormFieldModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatButtonModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    MatSelectModule,
    MatCardModule,
  ],
  templateUrl: './logistics-reports.component.html',
  styleUrl: './logistics-reports.component.scss',
})
export class LogisticsReportsComponent extends BaseListComponent {
  DownloadType = DownloadType;
  form: FormGroup;
  warehouseTypes: WarehouseItemStatus[] = Object.values(
    WarehouseItemStatus
  ).filter((value) => typeof value === 'number') as WarehouseItemStatus[];
  warehouseItemStatus: { [key: number]: string } = {
    [WarehouseItemStatus.Available]: 'Available',
    [WarehouseItemStatus.Planned]: 'Planned',
    [WarehouseItemStatus.InTransit]: 'InTransit',
    [WarehouseItemStatus.Delivered]: 'Delivered',
  };
  constructor(private dialog: MatDialog,
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    private sellerReportService: SellerReportsService,
  ) {
    super(auth, router, route);
    this.form = this.fb.group({
      collectionFromDate: '',
      collectionToDate: '',
      crFromDate: '',
      crToDate: '',
      slReturnedDate: '',
      status: null,
      prStatus: '',
      prSeller: '',
      prReceivedDate: '',
      prReturnedDate: '',
    });
  }

  override async ngOnInit() {
    await super.ngOnInit(PageId.logistics_reports);

  }
  clearFilters(type: DownloadType) {
    if (type === DownloadType.LogisticsCollections) {
      this.form.patchValue({
        collectionFromDate: '',
        collectionToDate: '',
      });
    }
    if (type === DownloadType.LogisticsCustomerReturns) {
      this.form.patchValue({
        crFromDate: '',
        crToDate: '',
      });
    }
    if (type === DownloadType.LogisticsSellerReturns) {
      this.form.patchValue({
        slReturnedDate: '',
        status: null,
      });
    }
  }

  async download(type: DownloadType) {
    if (type === DownloadType.LogisticsCollections) {
      const fromDate = this.form.value?.collectionFromDate ? getDateYYYYMMDD(new Date(this.form.value.collectionFromDate)) : '';
      const toDate = this.form.value?.collectionToDate ? getDateYYYYMMDD(new Date(this.form.value.collectionToDate)) : '';
      if (!fromDate || !toDate) {
        Swal.fire('', 'Please enter both the From date and To date', 'warning');
        return;
      }
      const body = {
        reportType: DownloadType.LogisticsCollections,
        logisticsCollections: {
          startDate: fromDate,
          endDate: toDate
        }
      };
      await this.sellerReportService.downloadRegisterReports(body);
      Swal.fire("SUCCESS", "Logistics Collections downloaded successfully", "success")
    }
    if (type === DownloadType.LogisticsCustomerReturns) {
      const fromDate = this.form.value?.crFromDate ? getDateYYYYMMDD(new Date(this.form.value.crFromDate)) : '';
      const toDate = this.form.value?.crToDate ? getDateYYYYMMDD(new Date(this.form.value.crToDate)) : '';
      if (!fromDate || !toDate) {
        Swal.fire('', 'Please enter both the From date and To date', 'warning');
        return;
      }
      const body = {
        reportType: DownloadType.LogisticsCustomerReturns,
        logisticsCustomerReturns: {
          startDate: fromDate,
          endDate: toDate,
        }
      };
      const res = await this.sellerReportService.downloadRegisterReports(body);
      Swal.fire("SUCCESS", "Logistics Customer Returns downloaded successfully", "success")
    }
    if (type === DownloadType.LogisticsSellerReturns) {
      const fromDate = this.form.value?.slReturnedDate ? getDateYYYYMMDD(new Date(this.form.value.slReturnedDate)) : '';
      const status = this.form?.get('status')?.value;
      if (!fromDate || !status) {
        Swal.fire('', 'Please enter the Date and Status', 'warning');
        return;
      }
      const body = {
        reportType: DownloadType.LogisticsSellerReturns,
        logisticsSellerReturns: {
          date: fromDate,
          status: status
        }
      };
      const res = await this.sellerReportService.downloadRegisterReports(body);
      Swal.fire("SUCCESS", "Logistics Seller Returns downloaded successfully", "success")
    }
    //TODO Purchase Returns
    // if(type === DownloadType.LogisticsPurchaseReturns){
    //   const fromDate = this.form.value?.prReturnedDate?getDateYYYYMMDD(new Date(this.form.value.prReturnedDate)):'';
    //   const toDate = this.form.value?.prReceivedDate?getDateYYYYMMDD(new Date(this.form.value.prReceivedDate)):'';
    //   const body = {
    //     reportType: DownloadType.LogisticsPurchaseReturns,
    //     logisticsPurchaseReturns: {
    //       returnedDate: fromDate,
    //       receivedDate: toDate,
    //       status:  1
    //     }
    //   };
    // const res = await this.sellerReportService.downloadRegisterReports(body);
    // Swal.fire("warning","Please implement the function to download the Purchase Returns","warning")
    // }
  }

}
