import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'type',
  standalone: true
})
export class TypePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;
    let updatedText = value.replace(/-/g, ' ');
    return updatedText.toUpperCase()
   // return value.replace(/(?:^|\s|-)[a-z]/g, match => match.toUpperCase());
  }

}
