import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NgSelectModule, } from '@ng-select/ng-select';
import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PlanningService } from '../services/planning.service';
import { MESSAGE_QUEUE_TYPE } from '../../../../constants/message-queue-types';
import { MatNativeDateModule } from '@angular/material/core';
import { LogisticsStatus } from '../../../../enums/enums';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { BaseListComponent } from '../../../../shared/core/base.list.component';
import { PageId } from '../../../../constants/enums';
import { CommonModule } from '@angular/common';


interface Data {
  date: string;
  planningSummary: any[];
  vendorLocationId: string
  planningTypes: number[]
}
@Component({
  selector: 'app-preview-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIconModule,
    MatCheckboxModule,
    NgSelectModule,
    MatTableModule,
    MatCheckboxModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatNativeDateModule

  ],
  templateUrl: './preview-dialog.component.html',
  styleUrl: './preview-dialog.component.scss'
})



export class PreviewDialogComponent extends BaseListComponent {
  @Output() dialogClosed = new EventEmitter<any>();

  dataSource: any;
  displayedColumns: string[] = ['cluster', 'ordersCount', 'customerCount', 'weight', 'value'];
  selectedDate: string = '';
  generateBtn: boolean = false;
  planningTypes: number[] = [];

  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private planningService: PlanningService,
    @Inject(MAT_DIALOG_DATA) public data: Data
  ) {
    super(auth, router, route, PageId.logistics_planning);
  }


  override async ngOnInit() {
    await super.ngOnInit();
    this.selectedDate = this.data.date;
    this.dataSource = this.data.planningSummary;
    this.planningTypes = this.data?.planningTypes
    console.log(" this.planningTypes ", this.planningTypes)
  }


  closeDialog() {

    this.dialog.closeAll();
  }


  async generateTrips() {
    try {
      const body = {
        locationId: this.data.vendorLocationId,
        planningTypes: this.planningTypes,
        tripDate: this.selectedDate,
      }
      const messageQueueResponse = await this.planningService.postMessageToMessageQueue(MESSAGE_QUEUE_TYPE.GENERATE_TRIPS, body);
      if (messageQueueResponse) {
        Swal.fire({
          title: "Generated Trips Successfully",
          text: "It will take few minutes to generate the trips.",
          icon: 'success',
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.closeDialog();
            this.router.navigateByUrl('/dashboard/logistics/trip-list');
          };
        });

      } else {
        Swal.fire("Something went wrong while generating trips")
      }
    } catch (error) {
      Swal.fire("Something went wrong while generating trips")
    }


  }


}

