import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatChipsModule } from '@angular/material/chips';
import { AuthService } from '../../../services/auth.service';
import { Coupon } from '../../../entities/coupon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { CouponService } from '../../../services/coupon.service';
import { MatSort } from '@angular/material/sort';
import Swal from 'sweetalert2';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormBuilder, FormsModule } from '@angular/forms';
import { MatCommonModule } from '@angular/material/core';
import { CommonModule } from '@angular/common';
import { ConfigService } from '../../config/services/config.service';
import { MESSAGE_QUEUE_TYPE } from '../../../constants/message-queue-types';
import { AppLoaderService } from '../../../shared/app-loader/app-loader.service';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { PageId } from '../../../constants/enums';
@Component({
  selector: 'app-coupon-list',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    RouterLink,
    MatCheckboxModule,
    FormsModule,
    MatChipsModule,
    MatCommonModule,
    MatPaginatorModule,
    CommonModule
  ],
  templateUrl: './coupon-list.component.html',
  styleUrl: './coupon-list.component.scss',
})
export class CouponListComponent extends BaseListComponent {
  permissionObj: any;
  coupons: Coupon[] = [];
  status: number = 1;
  public dataSource!: MatTableDataSource<Coupon>;
  public displayedColumns: string[] = ["name", "startDate", "endDate", "code", "status", "actions"];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private couponService: CouponService,
    private configService: ConfigService,
    private loader: AppLoaderService,
  ) {
    super(auth, router, route, PageId.seller_coupons);
  }

  override async ngOnInit() {
    await super.ngOnInit();
    this.getCoupons();
  }


  async getCoupons() {
    try {
      this.loader.open();
      const coupons = await this.couponService.getCouponsByFilter(this.vendorId, this.status);
      if (coupons) {
        const sortedCoupons = coupons.sort((a, b) => {
          if (a.timeStamp !== undefined && b.timeStamp !== undefined) {
            return b.timeStamp - a.timeStamp;
          } else if (a.timeStamp === undefined && b.timeStamp === undefined) {
            return 0;
          } else if (a.timeStamp === undefined) {
            return 1;
          } else {
            return -1;
          }
        });
        this.dataSource = new MatTableDataSource<Coupon>(sortedCoupons); // Use sortedCoupons
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      this.loader.close();
    } catch (error) {
      console.error("Error fetching coupons:", error);
      this.loader.close();
    }
  }


  toggleActive(event: any) {
    const status = event.checked;
    this.status = status ? 0 : 1;
    this.getCoupons();
  }

  async changeStatusCoupon(coupon: Coupon) {
    const statusLabelReverse = coupon.active ? "Inactive" : "Active";
    const statusLabelVal = coupon.active ? 0 : 1;

    try {
      const result = await Swal.fire({
        title: "Change Coupon Status",
        text: `Are you sure to make this coupon ${statusLabelReverse}?`,
        icon: "question",
        confirmButtonText: "Yes, Proceed!",
        showCancelButton: true
      });

      if (result.isConfirmed) {
        if (statusLabelVal == 1 && !this.isValidCouponDate(coupon)) {
          Swal.fire('', "You can not activate expired coupon!.", 'warning');
          return;
        }
        coupon.active = statusLabelVal;
        this.loader.open();
        await this.couponService.changeCouponStatus(coupon);
        if (statusLabelVal) {
          await this.sendCreateCouponMessageToBmsMsgQueue(coupon);
        } else {
          await this.sendDeleteCouponMessageToBmsMsgQueue(coupon.id);
        }
        this.loader.close();
        this.getCoupons();
      }
    } catch (error) {
      console.error("changeCouponStatus error:", error);
      this.loader.close();
    }
  }
  private isValidCouponDate(coupon: Coupon) {
    const isValid = (new Date(coupon.endDate)) > (new Date()) || (new Date(coupon.endDate)).toDateString() == (new Date()).toDateString();
    return isValid;
  }
  private async sendCreateCouponMessageToBmsMsgQueue(coupon: any) {
    const messageQueueResponse = await this.configService.postMessageToMessageQueue(MESSAGE_QUEUE_TYPE.CREATE_COUPON, coupon);
    return messageQueueResponse;
  }
  private async sendDeleteCouponMessageToBmsMsgQueue(couponId: string) {
    const message = {
      id: couponId
    }
    const messageQueueResponse = await this.configService.postMessageToMessageQueue(MESSAGE_QUEUE_TYPE.DELETE_COUPON, message);
    return messageQueueResponse;
  }
}

