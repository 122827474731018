import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MappedTrip } from '../../../../entities/trip-info';

@Injectable({
  providedIn: 'root',
})
export class TripDataService {
  private rowDataSubject = new BehaviorSubject<MappedTrip | null>(null);
  rowData$ = this.rowDataSubject.asObservable();

  setRowData(data: MappedTrip): void {
    this.rowDataSubject.next(data);
  }
}
