import { Component, Input } from '@angular/core';
import { TypePipe } from '../../../pipes/type.pipe';
import { StatusComponent } from '../../../status/status.component';

@Component({
  selector: 'app-add-company',
  standalone: true,
  imports: [StatusComponent,TypePipe],
  templateUrl: './add-company.component.html',
  styleUrl: './add-company.component.scss'
})
export class AddCompanyComponent {
  @Input() data:any;
}
