import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-bms-reports',
  standalone: true,
  imports: [ RouterOutlet],
  templateUrl: './bms-reports.component.html',
  styleUrl: './bms-reports.component.scss'
})
export class BmsReportsComponent {

}
