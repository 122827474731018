import { Injectable } from '@angular/core';
import { Observable, Subject, lastValueFrom, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {
  Item,
  PR,
  PrList,
  PurchaseDataResponse,
  PurchaseReceiptSearch,
} from '../models/purchasereceipt-model';
import {
  DistributeCharges,
  ExpenseType,
  PurchaseOrderStatus,
  TaxDeductionType,
  UOM,
} from '../../../enums/enums';

// import { DistributeCharges, ExpenseType, TaxDeductionType, UOM } from '../../../enums/enums';

@Injectable({
  providedIn: 'root',
})
export class PurchaseReceiptService {
  logisticsUrl: string = `${environment.logisticsUrl}`;
  fileServicesApiUrl: string = `${environment.fileUploadBaseUrl}`;
  inventoryUrl: string = `${environment.inventoryUrl}`;
  // inventoryUrl: string = `http://localhost:4008`;
  esApiUrl: string = `${environment.eSApiUrl}`;
  lookUpServiceUrl: string = `${environment.coreServiceUrl}`;

  constructor(public http: HttpClient) {}
  async getPurchaseReceipts(body: any): Promise<any> {
    try {
      const res = await lastValueFrom(
        this.http.post<{ data: PurchaseDataResponse }>(
          `${this.inventoryUrl}/api/purchase-receipt/get_by_filters`,
          body
        )
      );

      if (res && res.data) {
        const data: PurchaseDataResponse = {
          count: res.data.count,
          totalRecords: res.data.totalRecords,
          rows: this.mapToData(res.data.rows),
        };

        return data;
      }

      return null;
    } catch (error) {
      console.error('Error fetching purchase receipts:', error);
      return null;
    }
  }
  mapToData(data: PrList[]) {
    return data
      .map((row: PrList) => {
        return {
          id: row.id,
          receipt_id: row.receipt_id,
          internal_receipt_number: row.internal_receipt_number,
          invoice_reference_number: row.invoice_reference_number,
          purchase_date: row.purchase_date,
          received_date: row.received_date,
          supplier_id: row.supplier_id,
          status: row.status,
          tax_deduction_type: row.tax_deduction_type,
          tcs_percent: row.tcs_percent,
          tcs_value: row.tcs_value,
          tds_percent: row.tds_percent,
          tds_value: row.tds_value,
          comments: row.comments,
          created_by: row.created_by,
          grn_by: row.grn_by,
          approval_by: row.approval_by,
          purchase_bill: row.purchase_bill,
          other_bills: row.other_bills,
          createdAt: row.createdAt,
          updatedAt: this.getCurrentTimestamp(row.updatedAt),
          supplier_gst: row.supplier_gst,
          payout: row.payout,
        };
      })
      .sort((a, b) => +a.updatedAt - +b.updatedAt);
  }
  private getCurrentTimestamp(date: string) {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(d.getDate()).padStart(2, '0');
    const hours = String(d.getHours()).padStart(2, '0');
    const minutes = String(d.getMinutes()).padStart(2, '0');
    const seconds = String(d.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  async createPurchaseReceipts(body: any): Promise<any> {
    const res = await lastValueFrom(
      this.http.post<any>(`${this.inventoryUrl}/api/purchase-receipt`, body)
    );
    if (res && res.data) {
      return res.data[0];
    }
  }
  async getPurchaseReceiptById(id: string): Promise<any> {
    const res = await lastValueFrom(
      this.http.get<any>(`${this.inventoryUrl}/api/purchase-receipt/${id}`)
    );
    return res.data;
  }
  async vendorSkuSearch(searchtext: string, vendorId: string): Promise<any> {
    const body = this.mapToBody(searchtext, vendorId);

    const res = await lastValueFrom(
      this.http.post<any>(`${this.esApiUrl}/vendor_skus/item_search`, body)
    );
    return res;
  }

  mapToBody(searchtext: string, vendorId: string) {
    return {
      vendorId: vendorId,
      searchText: searchtext,
    };
  }
  async supplierSearch(searchtext: string, vendorId: string): Promise<any> {
    const body = this.mapToSearchBody(searchtext, vendorId);
    const res = await lastValueFrom(
      this.http.post<any>(
        `${this.inventoryUrl}/api/supplier/get_supplier_names`,
        body
      )
    );
    return res.data;
  }
  mapToSearchBody(searchtext: string, vendorId: string) {
    return {
      vendorId: vendorId,
      searchText: searchtext,
    };
  }
  async createPurchaseReceiptItem(body: any): Promise<any> {
    // TODO need to change the end point
    const res = await lastValueFrom(
      this.http.post<any>(
        `${this.inventoryUrl}/api/purchase-receipt-items`,
        body
      )
    );
    return res;
  }

  async getPurchaseReceiptItems(prId: string): Promise<any> {
    // TODO need to change the end point
    const res = await lastValueFrom(
      this.http.get<any>(
        `${this.inventoryUrl}/api/purchase-receipt-items/receipt_id/${prId}`
      )
    );

    return res.data && this.mapItems(res.data);
  }

  async createPurchaseReceiptExpenses(body: any): Promise<any> {
    const res = await lastValueFrom(
      this.http.post<any>(`${this.inventoryUrl}/api/purchase-expense`, body)
    );
    return res;
  }
  async getPrExpenses(prId: string): Promise<any> {
    const res = await lastValueFrom(
      this.http.get<any>(
        `${this.inventoryUrl}/api/purchase-expense/receipt_id/${prId}`
      )
    );
    return res.data && this.mapExpense(res.data);
  }
  async getPrSkuDetails(id: string): Promise<any> {
    const res = await lastValueFrom(
      this.http.get<any>(
        `${this.inventoryUrl}/api/purchase-receipt-items/${id}`
      )
    );
    return res.data;
  }
  async updateSkuDetails(id: string, body: any): Promise<any> {
    const res = await lastValueFrom(
      this.http.post<any>(
        `${this.inventoryUrl}/api/purchase-receipt-items/${id}`,
        body
      )
    );
    return res;
  }

  async getExpenseDetails(id: string): Promise<any> {
    const res = await lastValueFrom(
      this.http.get<any>(`${this.inventoryUrl}/api/purchase-expense/${id}`)
    );
    return res.data;
  }
  async updateExpenseDetails(id: string, body: any): Promise<any> {
    const res = await lastValueFrom(
      this.http.put<any>(
        `${this.inventoryUrl}/api/purchase-expense/${id}`,
        body
      )
    );
    return res;
  }
  async deleteExpense(id: string): Promise<any> {
    const res = await lastValueFrom(
      this.http.delete<any>(`${this.inventoryUrl}/api/purchase-expense/${id}`)
    );
    return res;
  }
  async deletePrSkus(id: string): Promise<any> {
    const res = await lastValueFrom(
      this.http.delete<any>(
        `${this.inventoryUrl}/api/purchase-receipt-items/${id}`
      )
    );
    return res;
  }

  async billsUpload(
    body: { fileName: string; fileExtension: string; fileData: any },
    urlEndpoint: string
  ) {
    let response = await lastValueFrom(
      this.http.put<any>(
        `${this.fileServicesApiUrl}/documents/${urlEndpoint}`,
        body
      )
    );

    return response;
  }

  async updatePr(pr: PR) {
    const res = await lastValueFrom(
      this.http.put<any>(
        `${this.inventoryUrl}/api/purchase-receipt/${pr.receipt_id}`,
        pr
      )
    );
    return res;
  }

  async updatePrAttributes(body: any, receiptId: string) {
    console.log('api body is', body);

    const res = await lastValueFrom(
      this.http.put<any>(
        `${this.inventoryUrl}/api/purchase-receipt/update_attribute/${receiptId}`,
        body
      )
    );
    return res;
  }
  async requestForApproval(body: any, receiptId: string) {
    try {
      const res = await lastValueFrom(
        this.http.put<any>(
          `${this.inventoryUrl}/api/purchase-receipt/request_approval/${receiptId}`,
          body
        )
      );
      return res;
    } catch (error) {
      throw error;
    }
  }
  async clonePr(id: string) {
    const body = this.maptoClone(id);
    const res = await lastValueFrom(
      this.http.post<any>(
        `${this.inventoryUrl}/api/purchase-receipt/clone`,
        body
      )
    );
    return res;
  }
  maptoClone(id: string) {
    return {
      receipt_id: id,
    };
  }

  private getActualsRequestedPcs(pr: any, item: any) {
    let pcs = item.actual_in_pcs || 0;
    if (
      pr.status === PurchaseOrderStatus.Created ||
      pr.status === PurchaseOrderStatus.RequestedForGRN
    ) {
      pcs = item.requested_in_pcs || 0;
    }

    return pcs;
  }
  async calculateValues(pr: PR, vendor: any) {
    let brokerCharges = 0,
      unloadingCharges = 0,
      loadingCharges = 0,
      discountCharges = 0,
      transportCharges = 0;
    let loadingByBuyerCharges = 0;
    let brokerageItem = pr.expenses.find(
      (expense) => expense.expense_type === ExpenseType.Brokerage
    );

    if (brokerageItem) {
      brokerCharges = brokerageItem.total_value;
    }
    let unloadingChargingItem = pr.expenses.find(
      (expense) => expense.expense_type === ExpenseType.Unloading
    );
    if (unloadingChargingItem) {
      unloadingCharges = unloadingChargingItem.total_value;
    }
    let loadingChargingItem = pr.expenses.find(
      (expense) => expense.expense_type === ExpenseType.Loading
    );

    if (loadingChargingItem) {
      loadingCharges = loadingChargingItem.total_value;
    }
    let loadingByBuyerChargingItem = pr.expenses.find(
      (expense) => expense.expense_type === ExpenseType.LoadingByBuyer
    );
    if (loadingByBuyerChargingItem) {
      loadingByBuyerCharges = loadingByBuyerChargingItem.total_value;
    }

    let transportItem = pr.expenses.find(
      (expense) => expense.expense_type === ExpenseType.Transport
    );
    if (transportItem) {
      transportCharges = transportItem.total_value;
    }

    let purchaseCreditItem = pr.expenses.find(
      (expense) => expense.expense_type === ExpenseType.PurchaseCredit
    );
    if (purchaseCreditItem) {
      purchaseCreditItem.payout = purchaseCreditItem.total_value;
    }
    let totalActualInvoiceAmount = 0;
    let totalTaxableAmount = 0;
    let totalQtyInPCs = 0;
    pr.items.forEach((item) => {
      totalQtyInPCs += this.getActualsRequestedPcs(pr, item) || 0;
    });

    pr.items.forEach((item) => {
      const item_in_pcs = this.getActualsRequestedPcs(pr, item) || 0;
      item.invoiced_amount = +(
        (item.requested_invoice_amount / item.requested_in_pcs) *
        item_in_pcs
      ).toFixed(4);
      item.taxable_amount = this.getTaxableAmount(
        pr.tax_deduction_type,
        item.invoiced_amount,
        item.gst,
        item.cess
      );
      totalActualInvoiceAmount += item.invoiced_amount;
      totalTaxableAmount += item.taxable_amount;
      item.brokerage = 0;
      item.actual_in_pcs = item.actual_in_pcs || 0;
      item.unloading_charges = item.unloading_charges || 0;
      item.loading_charges = item.loading_charges || 0;
      item.transport_charges = item.transport_charges || 0;
      item.loadingbybuyer_charges = item.loadingbybuyer_charges || 0;

      item.tax_amount = this.getTaxAmount(
        //  item.tax_type,
        pr.tax_deduction_type,
        item.invoiced_amount,
        item.gst,
        item.cess
      );
      item.cess_amount = item.taxable_amount * (item.cess / 100);
      item.tds_amount = +(item.taxable_amount * (pr.tds_percent / 100)).toFixed(
        4
      );
      item.final_bill_value = item.tax_amount + item.taxable_amount;
      item.rate_per_pc = parseFloat(
        (item.final_bill_value / item_in_pcs).toFixed(4)
      );
      if (
        isNaN(item.rate_per_pc) ||
        item.rate_per_pc === Infinity ||
        item.rate_per_pc === null
      ) {
        item.rate_per_pc = 0; // Set rate_per_pc to 0
      }

      item.rate_per_pc = item.rate_per_pc || 0;

      if (
        brokerageItem &&
        brokerageItem.distribute_based_on.toString() ===
          DistributeCharges.Weight
      ) {
        item.brokerage = item.weight * item_in_pcs * brokerCharges;
      }
      if (
        brokerageItem &&
        brokerageItem.distribute_based_on.toString() ===
          DistributeCharges.Quantity
      ) {
        item.brokerage = item_in_pcs * brokerCharges;
      }
      if (
        unloadingChargingItem &&
        unloadingChargingItem.distribute_based_on.toString() ===
          DistributeCharges.Weight
      ) {
        item.unloading_charges = item.weight * item_in_pcs * unloadingCharges;
      }
      if (
        unloadingChargingItem &&
        unloadingChargingItem.distribute_based_on.toString() ===
          DistributeCharges.Quantity
      ) {
        item.unloading_charges = item_in_pcs * unloadingCharges;
      }
      if (
        loadingChargingItem &&
        loadingChargingItem.distribute_based_on.toString() ===
          DistributeCharges.Weight
      ) {
        item.loading_charges = item.weight * item_in_pcs * loadingCharges;
      }
      if (
        loadingChargingItem &&
        loadingChargingItem.distribute_based_on.toString() ===
          DistributeCharges.Quantity
      ) {
        item.loading_charges = item_in_pcs * loadingCharges;
      }
      if (
        loadingByBuyerChargingItem &&
        loadingByBuyerChargingItem.distribute_based_on.toString() ===
          DistributeCharges.Weight
      ) {
        item.loadingbybuyer_charges =
          item.weight * item_in_pcs * loadingByBuyerCharges;
      }
      if (
        loadingByBuyerChargingItem &&
        loadingByBuyerChargingItem.distribute_based_on.toString() ===
          DistributeCharges.Quantity
      ) {
        item.loadingbybuyer_charges = item_in_pcs * loadingByBuyerCharges;
      }

      if (
        transportItem &&
        transportItem.distribute_based_on.toString() ===
          DistributeCharges.Weight
      ) {
        item.transport_charges =
          item.weight * item_in_pcs * transportCharges || 0;
      }
      if (
        transportItem &&
        transportItem.distribute_based_on.toString() ===
          DistributeCharges.Quantity
      ) {
        item.transport_charges =
          +((transportCharges / totalQtyInPCs) * item_in_pcs).toFixed(4) || 0;
      }
    });

    // if (transportItem) {
    //   transportCharges = transportItem.total_value;
    //   transportItem.payout = transportItem.total_value;
    //   transportItem.tds_percent = parseFloat(
    //     (transportItem.total_value / 100).toFixed(4)
    //   );
    // }

    const discountItem = pr.expenses.find(
      (expense) => expense.expense_type === ExpenseType.Discount
    );
    if (discountItem) {
      if (discountItem.discount_percent) {
        discountItem.total_value =
          +((discountItem.discount_percent / 100) * totalTaxableAmount).toFixed(
            4
          ) || 0;
      }
      discountCharges = discountItem.total_value || 0;
      discountItem.payout = discountItem.total_value || 0;
    }

    // const transportChargesPerPc = transportCharges / totalQtyInPCs;
    let discountsPerPc = discountCharges / totalQtyInPCs;

    if (
      isNaN(discountsPerPc) ||
      discountsPerPc === Infinity ||
      discountsPerPc === null
    ) {
      discountsPerPc = 0;
    }
    let brokeragePayout = 0,
      loadingPayout = 0,
      unloadingPayout = 0,
      prPayout = 0,
      transportPayout = 0;
    let loadingByBuyerPayout = 0;

    pr.items.forEach((item) => {
      const item_in_pcs = this.getActualsRequestedPcs(pr, item) || 0;

      // item.transport_charges =
      //   parseFloat((transportChargesPerPc * item.actual_in_pcs).toFixed(4)) ||
      //   0;
      item.discount = item_in_pcs * discountsPerPc;
      item.landing_cost = parseFloat(
        (
          item.rate_per_pc -
          item.loading_charges / item_in_pcs +
          item.loadingbybuyer_charges / item_in_pcs +
          item.unloading_charges / item_in_pcs +
          item.brokerage / item_in_pcs +
          item.transport_charges / item_in_pcs -
          item.discount / item_in_pcs
        ).toFixed(4)
      );
      if (
        isNaN(item.landing_cost) ||
        item.landing_cost === Infinity ||
        item.landing_cost === null
      ) {
        item.landing_cost = 0; // Set landing_cost to 0
      }
      brokeragePayout += item.brokerage;
      loadingPayout += item.loading_charges;
      unloadingPayout += item.unloading_charges;
      transportPayout += item.transport_charges;
      loadingByBuyerPayout += item.loadingbybuyer_charges;

      prPayout += item.taxable_amount + item.tax_amount;
      //  + item.cess_amount

      const supplier_gst = pr.supplier_gst;
      const vendor_gst = vendor.gst_number;
      const gstAmountsResult = this.getGSTAmount(
        supplier_gst,
        vendor_gst,
        pr.tax_deduction_type,
        item.invoiced_amount,
        item.gst,
        item.cess
      );
      item.cgst_amount = gstAmountsResult.cgst;
      item.igst_amount = gstAmountsResult.igst;
      item.sgst_amount = gstAmountsResult.sgst;
      //item level tax amounts
    });

    if (brokerageItem) {
      brokerageItem.payout = brokeragePayout || 0;
      brokerageItem.tds_value =
        parseFloat(
          ((brokeragePayout * brokerageItem.tds_percent) / 100).toFixed(4)
        ) || 0;
    }
    if (loadingChargingItem) {
      loadingChargingItem.payout = loadingPayout || 0;
      loadingChargingItem.tds_value =
        parseFloat(
          ((loadingPayout * loadingChargingItem.tds_percent) / 100).toFixed(4)
        ) || 0;
    }
    if (unloadingChargingItem) {
      unloadingChargingItem.payout = unloadingPayout || 0;
      unloadingChargingItem.tds_value =
        +((unloadingPayout * unloadingChargingItem.tds_percent) / 100).toFixed(
          4
        ) || 0;
    }
    if (loadingByBuyerChargingItem) {
      loadingByBuyerChargingItem.payout = loadingByBuyerPayout || 0;
      loadingByBuyerChargingItem.tds_value = +(
        (loadingByBuyerPayout * loadingByBuyerChargingItem.tds_percent) /
        100
      ).toFixed(4);
    }
    if (transportItem) {
      transportItem.payout = transportPayout || 0;
      transportItem.tds_value =
        +((transportPayout * transportItem.tds_percent) / 100).toFixed(4) || 0;
    }
    pr.payout = prPayout;
    const res = await this.updatePr(pr);
    return res;
  }

  private getGSTType(supplier_gst: string, vendor_gst: string) {
    if (supplier_gst && vendor_gst) {
      const supplierStateCode = parseInt(supplier_gst.substring(0, 2));
      const vendorStateCode = parseInt(vendor_gst.substring(0, 2));
      if (supplierStateCode === vendorStateCode) {
        return 'IN-STATE'; // Same state, SGST is applicable
      } else {
        return 'OUT-STATE'; // Different states, IGST is applicable
      }
    }
    return 'IN-STATE';
  }

  getGSTAmount(
    supplierGSTNumber: string,
    vendorGSTNumber: string,
    taxDeductionType: TaxDeductionType,
    invoicedAmount: number,
    gst: number,
    cess: number
  ): any {
    const taxableAmount = this.getTaxableAmount(
      taxDeductionType,
      invoicedAmount,
      gst,
      cess
    );
    const gstType = this.getGSTType(supplierGSTNumber, vendorGSTNumber);
    let csgt = 0,
      sgst = 0,
      igst = 0,
      taxAmount = 0;

    taxAmount = +(taxableAmount * (gst / 100)).toFixed(4);
    let result = {
      cgst: 0,
      sgst: 0,
      igst: taxAmount,
    };
    if (gstType === 'IN-STATE') {
      result = {
        cgst: taxAmount / 2,
        sgst: taxAmount / 2,
        igst: 0,
      };
    }
    return result;
  }

  getTaxAmount(
    taxDeductionType: TaxDeductionType,
    invoicedAmount: number,
    gst: number,
    cess: number
  ): number {
    if (taxDeductionType === TaxDeductionType.Inclusive) {
      const totalTaxRate = gst + cess;
      return parseFloat(
        (invoicedAmount - invoicedAmount / (1 + totalTaxRate / 100)).toFixed(4)
      );
    } else if (taxDeductionType === TaxDeductionType.Exclusive) {
      const totalTaxRate = gst + cess;
      return parseFloat((invoicedAmount * (totalTaxRate / 100)).toFixed(4));
    } else {
      return 0;
    }
  }

  getTaxableAmount(
    taxDeductionType: TaxDeductionType,
    invoicedAmount: number,
    gst: number,
    cess: number
  ): number {
    if (taxDeductionType === TaxDeductionType.Inclusive) {
      const totalTaxRate = gst + cess;
      return parseFloat((invoicedAmount / (1 + totalTaxRate / 100)).toFixed(4));
    } else if (taxDeductionType === TaxDeductionType.Exclusive) {
      return invoicedAmount;
    } else {
      return 0;
    }
  }

  private mapExpense(expenses: Array<any>) {
    return expenses.map((exp) => {
      return { ...exp, ...this.parseNumericFieldsOfExp(exp) };
    });
  }

  private mapItems(items: Array<any>) {
    return items.map((item) => {
      return { ...item, ...this.parseNumericFieldsOfItem(item) };
    });
  }

  private parseNumericFieldsOfItem(obj: any): any {
    const numericFields = [
      'upc',
      'uom_requested_qty',
      'uom_actual_qty',
      'requested_in_pcs',
      'actual_in_pcs',
      'mrp',
      'gst',
      'cess',
      'pc_cost',
      'tax_amount',
      'taxable_amount',
      'tcs_amount',
      'cess_amount',
      'net_payable',
      'invoiced_amount',
      'requested_invoice_amount',
      'final_bill_value',
      'weight',
      'rate_per_pc',
      'unloading_charges',
      'loading_charges',
      'brokerage',
      'discount',
      'transport_charges',
      'purchase_credit',
      'landing_cost',
      'sgst_amount',
      'cgst_amount',
      'igst_amount',
    ];

    const parsedObj: any = { ...obj };

    numericFields.forEach((field) => {
      if (typeof parsedObj[field] === 'string') {
        parsedObj[field] = +parsedObj[field];
      }
    });

    return parsedObj;
  }

  private parseNumericFieldsOfExp(obj: any): any {
    const numericFields = [
      'tds_value',
      'tds_percent',
      'payout',
      'charge_type',
      'distribute_based_on',
      'total_value',
      'expense_type',
    ];

    const parsedObj: any = { ...obj };

    numericFields.forEach((field) => {
      if (typeof parsedObj[field] === 'string') {
        parsedObj[field] = +parsedObj[field];
      }
    });

    return parsedObj;
  }
}

// async calculateValuesTemp(pr: PR, vendor: any) {
//   let brokerCharges = 0,
//     unloadingCharges = 0,
//     loadingCharges = 0,
//     discountCharges = 0,
//     transportCharges = 0;
//   let brokerageItem = pr.expenses.find(
//     (expense) => expense.expense_type === ExpenseType.Brokerage
//   );

//   if (brokerageItem) {
//     brokerCharges = brokerageItem.total_value;
//   }
//   let unloadingChargingItem = pr.expenses.find(
//     (expense) => expense.expense_type === ExpenseType.Unloading
//   );
//   if (unloadingChargingItem) {
//     unloadingCharges = unloadingChargingItem.total_value;
//   }
//   let loadingChargingItem = pr.expenses.find(
//     (expense) => expense.expense_type === ExpenseType.Loading
//   );

//   if (loadingChargingItem) {
//     loadingCharges = loadingChargingItem.total_value;
//   }

//   let transportItem = pr.expenses.find(
//     (expense) => expense.expense_type === ExpenseType.Transport
//   );
//   if (transportItem) {
//     transportCharges = transportItem.total_value;
//   }

//   let totalActualInvoiceAmount = 0;
//   let totalQtyInPCs = 0;
//   pr.items.forEach((item) => {
//     totalQtyInPCs += item.actual_in_pcs || 0;
//   });

//   pr.items.forEach((item) => {
//     item.invoiced_amount = +(
//       (item.requested_invoice_amount / item.requested_in_pcs) *
//       item.actual_in_pcs
//     ).toFixed(4);
//     totalActualInvoiceAmount += item.invoiced_amount;
//     item.brokerage = 0;
//     item.actual_in_pcs = item.actual_in_pcs || 0;
//     item.unloading_charges = item.unloading_charges || 0;
//     item.loading_charges = item.loading_charges || 0;
//     item.transport_charges = item.transport_charges || 0;

//     item.tax_amount = this.getTaxAmount(
//       //  item.tax_type,
//       pr.tax_deduction_type,
//       item.invoiced_amount,
//       item.gst,
//       item.cess
//     );

//     item.taxable_amount = this.getTaxableAmount(
//       pr.tax_deduction_type,
//       item.invoiced_amount,
//       item.gst,
//       item.cess
//     );
//     item.cess_amount = item.taxable_amount * (item.cess / 100);
//     item.tds_amount = +(item.taxable_amount * (pr.tds_percent / 100)).toFixed(
//       4
//     );
//     item.final_bill_value =
//       item.tax_amount + item.taxable_amount + item.tds_amount;
//     item.rate_per_pc = parseFloat(
//       (item.final_bill_value / item.actual_in_pcs).toFixed(4)
//     );
//     if (
//       isNaN(item.rate_per_pc) ||
//       item.rate_per_pc === Infinity ||
//       item.rate_per_pc === null
//     ) {
//       item.rate_per_pc = 0; // Set rate_per_pc to 0
//     }

//     item.rate_per_pc = item.rate_per_pc || 0;

//     if (
//       brokerageItem &&
//       brokerageItem.distribute_based_on.toString() ===
//         DistributeCharges.Weight
//     ) {
//       item.brokerage = item.weight * item.actual_in_pcs * brokerCharges;
//     }
//     if (
//       brokerageItem &&
//       brokerageItem.distribute_based_on.toString() ===
//         DistributeCharges.Quantity
//     ) {
//       item.brokerage = item.actual_in_pcs * brokerCharges;
//     }
//     if (
//       unloadingChargingItem &&
//       unloadingChargingItem.distribute_based_on.toString() ===
//         DistributeCharges.Weight
//     ) {
//       item.unloading_charges =
//         item.weight * item.actual_in_pcs * unloadingCharges;
//     }
//     if (
//       unloadingChargingItem &&
//       unloadingChargingItem.distribute_based_on.toString() ===
//         DistributeCharges.Quantity
//     ) {
//       item.unloading_charges = item.actual_in_pcs * unloadingCharges;
//     }
//     if (
//       loadingChargingItem &&
//       loadingChargingItem.distribute_based_on.toString() ===
//         DistributeCharges.Weight
//     ) {
//       item.loading_charges =
//         item.weight * item.actual_in_pcs * loadingCharges;
//     }
//     if (
//       loadingChargingItem &&
//       loadingChargingItem.distribute_based_on.toString() ===
//         DistributeCharges.Quantity
//     ) {
//       item.loading_charges = item.actual_in_pcs * loadingCharges;
//     }

//     if (
//       transportItem &&
//       transportItem.distribute_based_on.toString() ===
//         DistributeCharges.Weight
//     ) {
//       item.transport_charges =
//         item.weight * item.actual_in_pcs * transportCharges || 0;
//     }
//     if (
//       transportItem &&
//       transportItem.distribute_based_on.toString() ===
//         DistributeCharges.Quantity
//     ) {
//       item.transport_charges =
//         +((transportCharges / totalQtyInPCs) * item.actual_in_pcs).toFixed(
//           4
//         ) || 0;
//     }
//   });

//   // if (transportItem) {
//   //   transportCharges = transportItem.total_value;
//   //   transportItem.payout = transportItem.total_value;
//   //   transportItem.tds_percent = parseFloat(
//   //     (transportItem.total_value / 100).toFixed(4)
//   //   );
//   // }

//   const discountItem = pr.expenses.find(
//     (expense) => expense.expense_type === ExpenseType.Discount
//   );
//   if (discountItem) {
//     if (discountItem.discount_percent) {
//       discountItem.total_value =
//         +(
//           (discountItem.discount_percent / 100) *
//           totalActualInvoiceAmount
//         ).toFixed(4) || 0;
//     }
//     discountCharges = discountItem.total_value || 0;
//     discountItem.payout = discountItem.total_value || 0;
//   }

//   // const transportChargesPerPc = transportCharges / totalQtyInPCs;
//   let discountsPerPc = discountCharges / totalQtyInPCs;

//   if (
//     isNaN(discountsPerPc) ||
//     discountsPerPc === Infinity ||
//     discountsPerPc === null
//   ) {
//     discountsPerPc = 0;
//   }
//   let brokeragePayout = 0,
//     loadingPayout = 0,
//     unloadingPayout = 0,
//     prPayout = 0,
//     transportPayout = 0;

//   pr.items.forEach((item) => {
//     // item.transport_charges =
//     //   parseFloat((transportChargesPerPc * item.actual_in_pcs).toFixed(4)) ||
//     //   0;
//     item.discount = discountsPerPc;
//     item.landing_cost = parseFloat(
//       (
//         item.rate_per_pc -
//         item.loading_charges / item.actual_in_pcs +
//         item.unloading_charges / item.actual_in_pcs +
//         item.brokerage / item.actual_in_pcs +
//         item.transport_charges / item.actual_in_pcs -
//         item.discount
//       ).toFixed(4)
//     );
//     if (
//       isNaN(item.landing_cost) ||
//       item.landing_cost === Infinity ||
//       item.landing_cost === null
//     ) {
//       item.landing_cost = 0; // Set landing_cost to 0
//     }
//     brokeragePayout += item.brokerage;
//     loadingPayout += item.loading_charges;
//     unloadingPayout += item.unloading_charges;
//     transportPayout += item.transport_charges;

//     prPayout +=
//       item.taxable_amount +
//       item.tax_amount +
//       //  + item.cess_amount
//       item.tds_amount;
//     const supplier_gst = pr.supplier_gst;
//     const vendor_gst = vendor.gst_number;
//     const gstAmountsResult = this.getGSTAmount(
//       supplier_gst,
//       vendor_gst,
//       pr.tax_deduction_type,
//       item.invoiced_amount,
//       item.gst,
//       item.cess
//     );
//     item.cgst_amount = gstAmountsResult.cgst;
//     item.igst_amount = gstAmountsResult.igst;
//     item.sgst_amount = gstAmountsResult.sgst;
//     //item level tax amounts
//   });

//   if (brokerageItem) {
//     brokerageItem.payout = brokeragePayout || 0;
//     brokerageItem.tds_value =
//       parseFloat(
//         ((brokeragePayout * brokerageItem.tds_percent) / 100).toFixed(4)
//       ) || 0;
//   }
//   if (loadingChargingItem) {
//     loadingChargingItem.payout = loadingPayout || 0;
//     loadingChargingItem.tds_value =
//       parseFloat(
//         ((loadingPayout * loadingChargingItem.tds_percent) / 100).toFixed(4)
//       ) || 0;
//   }
//   if (unloadingChargingItem) {
//     unloadingChargingItem.payout = unloadingPayout || 0;
//     unloadingChargingItem.tds_value =
//       +((unloadingPayout * unloadingChargingItem.tds_percent) / 100).toFixed(
//         4
//       ) || 0;
//   }
//   if (transportItem) {
//     transportItem.payout = transportPayout || 0;
//     transportItem.tds_value =
//       +((transportPayout * transportItem.tds_percent) / 100).toFixed(4) || 0;
//   }
//   pr.payout = prPayout;
//   const res = await this.updatePr(pr);
//   return res;
// }
