import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { RouterOutlet, RouterLink } from '@angular/router';
import { PricingRuleInput, SkuPricingRule } from '../../../entities/pricing';
import {
  containsOnlyNumbers,
  getDiscountTypeValue,
} from '../../../utlity/utility';
import Swal from 'sweetalert2';
import { PricingService } from '../services/pricing.service';
import uuid4 from 'uuid4';
import { PricingRulePriceType, PricingRuleStatus, PricingRuleType } from '../../../enums/enums';
import { AppLoaderService } from '../../../shared/app-loader/app-loader.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

interface IPriceRuleDialogData {
  priceRuleInput: PricingRuleInput;
  existingPricingRules: SkuPricingRule[];
  skuDetails: any;
  isUpdate: boolean;
}

@Component({
  selector: 'app-filter-popup',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatButtonModule,
    RouterOutlet,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatCardModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    CommonModule,
    RouterLink,
    ReactiveFormsModule,
    MatIconModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './pricing-edit.component.html',
  styleUrl: './pricing-edit.component.scss',
})
export class PricingEditPopupComponent {
  dataSource: any;
  isSlabExist: boolean = false;
  isFieldsEmpty: boolean = false;
  shouldBeNumbers: boolean = false;
  skuDetails: any;
  isUpdate: boolean = false;
  loading: boolean = true;
  constructor(
    public dialogRef: MatDialogRef<PricingEditPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IPriceRuleDialogData,
    public fb: FormBuilder,
    private pricingService: PricingService,

  ) {
    this.loading = false;
    this.isUpdate = data.isUpdate;
    this.skuDetails = data.skuDetails;

    if (data && data.priceRuleInput) {
      this.dataSource = this.initializePriceRuleDetails(data.priceRuleInput);
    } else {
      this.dataSource = this.initializePriceRuleDetails({});
    }
  }

  priseRuleValidation() {
    this.isSlabExist = false;
    this.isFieldsEmpty = false;
    this.loading = false;
    const discountTypeControl = this.dataSource.get('pricing_type');
    const selectedValue = discountTypeControl.value;
    this.dataSource.discountType = getDiscountTypeValue(selectedValue);

    if (!this.hasSearchFields()) {
      this.isFieldsEmpty = true;

      return false;
    }
    if (
      !containsOnlyNumbers(
        this.dataSource.value.min_quantity,
        this.dataSource.value.max_quantity,
        this.dataSource.value.pricing_value
      )
    ) {
      this.shouldBeNumbers = true;

      return false;
    }

    if (
      +this.dataSource.value.min_quantity > +this.dataSource.value.max_quantity
    ) {
      Swal.fire('', 'Give proper min quantity and max quantity', 'warning');

      return false;
    }

    if (
      +this.dataSource.value.min_quantity == +this.dataSource.value.max_quantity
    ) {
      Swal.fire(
        '',
        'Min Quantity and Max Quantity Values Cannot be Same',
        'warning'
      );
      this.loading = false;
      return false;
    }

    if (+this.dataSource.value.min_quantity % 1 !== 0) {
      'warning'
      Swal.fire('', 'Min Qty should not be decimal values',);
      this.loading = false;
      return false;
    }

    if (+this.dataSource.value.max_quantity % 1 !== 0) {
      Swal.fire('', 'Max Qty should not be decimal values',);
      this.loading = false;
      return false;
    }

    if (!this.isUpdate) {
      this.isSlabExist = this.compareQuantities(this.dataSource.value);
      if (this.isSlabExist) {
        this.loading = false;
        return false;
      }
    }
    return true;
  }

  save() {
    this.loading = true;
    if (
      this.skuDetails.value.maximum_retail_price == 0 &&
      this.skuDetails.value.selling_price == 0
    ) {
      Swal.fire('', 'You cannot add a Pricing Rule without specifying both the MRP and the Selling Price', 'warning');
      this.loading = false;
      return;
    }

    // if (
    //   this.skuDetails.value.selling_price <= this.dataSource.value.pricing_value
    // ) {
    //   Swal.fire(
    //     '',
    //     'The discount value cannot be greater than or equal to the selling price',
    //     'warning'
    //   );
    //   this.loading=false;

    //   return;
    // }

    if (this.priseRuleValidation()) {
      this.savePraisingRule();
    }
  }

  async savePraisingRule() {
    try {
      const entity = this.isUpdate
        ? this.mapToSlabForUpdateEntity(
          this.dataSource.value,
          this.skuDetails.value
        )
        : this.mapToSlabEntity(this.dataSource.value, this.skuDetails.value);

      if (+entity.pricing_rate <= 0) {
        Swal.fire(
          '',
          'The value should be greater than or equal to 0',
          'warning'
        );
        this.loading = false;

        return;
      }

      if (
        this.skuDetails.value.selling_price < +entity.pricing_rate
      ) {
        Swal.fire(
          '',
          'The discount value cannot be greater than the selling price',
          'warning'
        );
        this.loading = false;

        return;
      }
      const mappedEntity = this.mapToEntity(entity)
      if (this.data.existingPricingRules.length == 0) {
        await this.pricingService.insertPricingRule(mappedEntity);
        this.loading = false;
        this.dialogRef.close(1);
        Swal.fire('', 'Price Updated Successfully ', 'success');
        return;
      }


      if (this.validateQuantityOverlap(mappedEntity.min_quantity, mappedEntity.max_quantity, mappedEntity.id)) {
        const response = this.isUpdate
          ? await this.pricingService.updatePricingRule(mappedEntity)
          : await this.pricingService.insertPricingRule(mappedEntity);
        this.loading = false;

        this.dialogRef.close(1);
        Swal.fire('', 'Price Updated Successfully ', 'success');
      } else {
        this.loading = false;
        Swal.fire('', 'Slabs are Overlapping Please Check', 'warning');
        return
      }
    } catch (error) {
      this.loading = false;
      Swal.fire('', 'Something went Wrong ', 'warning');
      console.log('Error at savePraisingRule:');
    }
  }

  private validateQuantityOverlap(minQty: number, maxQty: number, id: string): boolean {
    const pricingRules: SkuPricingRule[] = this.data.existingPricingRules;
    for (const rule of pricingRules) {
      if (rule.id === id) {
        continue;
      }

      if ((minQty >= rule.min_quantity && minQty <= rule.max_quantity) ||
        (maxQty >= rule.min_quantity && maxQty <= rule.max_quantity) ||
        (minQty <= rule.min_quantity && maxQty >= rule.max_quantity)) {

        return false;
      }
    }
    return true;
  }

  private mapToEntity(entity: any) {
    return {
      id: entity.id,
      vendor_id: entity.vendor_id,
      sku_id: entity.sku_id,
      status: entity.status,
      type: entity.type,
      pricing_type: entity.pricing_type,
      pricing_value: entity.pricing_value,
      min_quantity: entity.min_quantity,
      max_quantity: entity.max_quantity,
    };
  }

  private mapToSlabForUpdateEntity(slabDetails: any, skuDetails: any) {
    return {
      id: slabDetails.id,
      vendor_id: skuDetails.vendor_id,
      sku_id: skuDetails.skus_id,
      status: PricingRuleStatus.Active,
      type: PricingRuleType.Selling,
      pricing_type: slabDetails.pricing_type,
      pricing_value: slabDetails.pricing_value,
      pricing_rate: this.calculateValue(+slabDetails.pricing_type, +slabDetails.pricing_value, +this.skuDetails.value.selling_price),
    };
  }

  private mapToSlabEntity(slabDetails: any, skuDetails: any) {
    return {
      id: uuid4(),
      vendor_id: skuDetails.vendor_id,
      sku_id: skuDetails.skus_id,
      status: PricingRuleStatus.Active,
      min_quantity: slabDetails.min_quantity,
      max_quantity: slabDetails.max_quantity,
      type: PricingRuleType.Selling,
      pricing_type: slabDetails.pricing_type,
      pricing_value: slabDetails.pricing_value,
      pricing_rate: this.calculateValue(+slabDetails.pricing_type, +slabDetails.pricing_value, +this.skuDetails.value.selling_price),

    };
  }

  private calculateValue(rule: number, value: number, sellingPrice: number) {
    if (rule == PricingRulePriceType.Rate) {
      return value.toFixed(2)
    } else if (rule == PricingRulePriceType.DiscountAmount) {
      return (sellingPrice - value).toFixed(2)
    } else if (rule == PricingRulePriceType.DiscountPercentage) {
      return ((sellingPrice) - ((sellingPrice / 100) * value)).toFixed(2)
    }
    return 0;
  }





  private compareQuantities(newInput: any) {
    if (this.data.existingPricingRules.length === 0) {
      return false;
    }
    if (this.data.existingPricingRules.length === 1) {
      return false;
    }
    const minQuantityNew = parseInt(newInput.min_quantity);
    const maxQuantityNew = parseInt(newInput.max_quantity);

    const maxExisting = this.data.existingPricingRules.reduce(
      (max, itemArray) => {
        const item = itemArray;
        return Math.max(max, item.max_quantity);
      },
      Number.NEGATIVE_INFINITY
    );

    return minQuantityNew <= maxExisting && maxQuantityNew >= minQuantityNew;
  }

  private initializePriceRuleDetails(priceRuleDetails: any): FormGroup {
    return this.fb.group({
      id: new FormControl(priceRuleDetails.id || 0),
      min_quantity: new FormControl(priceRuleDetails.min_quantity || 0),
      max_quantity: new FormControl(priceRuleDetails.max_quantity || 0),
      pricing_type: new FormControl(priceRuleDetails.pricing_type || 0),
      pricing_value: new FormControl(priceRuleDetails.pricing_value || 0),
    });
  }

  private hasSearchFields(): boolean {
    const {
      min_quantity,
      max_quantity,
      pricing_type,
      pricing_value,
    } = this.dataSource.value;

    return (
      min_quantity && max_quantity && pricing_type !== 0 && pricing_value !== 0
    );
  }
}
