import { Component, Inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CompanyService } from '../../company/services/company.service';
import { CategoryService } from '../services/category.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgSelectModule } from '@ng-select/ng-select';
@Component({
  selector: 'app-category-filter-dialog',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIconModule,
    MatCheckboxModule,
    NgSelectModule,
  ],
  templateUrl: './category-filter-dialog.component.html',
  styleUrl: './category-filter-dialog.component.scss',
})
export class CategoryFilterDialogComponent {
  filterForm: any;
  categoryList: any = [];
  constructor(
    public dialogRef: MatDialogRef<CategoryFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: FormBuilder,
    public categoryService: CategoryService
  ) {
    this.filterForm = this.fb.group({
      name: [null],
      parent_categories_id: [null],
      root: [null],
      display_name: [null],
      page: [1],
      limit: [5],
    });
  }
  ngOnInit(): void {
    this.filterForm.patchValue(this.data);
    this.getAll();
  }
  getAll() {
    this.categoryService.getAll().subscribe((res: any) => {
      this.categoryList = res.data;
    });
  }
  close() {
    this.dialogRef.close();
  }
  reset() {
    this.filterForm.patchValue({
      name: null,
      parent_categories_id: null,
      root: null,
      display_name: null,
      page: 1,
      limit: 5,
    });
    this.dialogRef.close(this.filterForm.value);
  }
  submit() {
    let data = {
      name: this.filterForm.value.name,
      parent_categories_id: this.filterForm.value.parent_categories_id,
      root: this.filterForm.value.root,
      display_name: this.filterForm.value.display_name,
      page: 1,
      limit: 5,
    };
    console.log('data:::::::::', data);
    // this.dialogRef.close(data);
  }
}
