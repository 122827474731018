import { Component, Inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IAllowanceModel } from '../../models/salesperonperformance.model';
import { CommonModule } from '@angular/common';
import { SwalMessageTypes } from '../../../../enums/enums';
import Swal from 'sweetalert2';
import { BaseListComponent } from '../../../../shared/core/base.list.component';
import { AuthService } from '../../../../services/auth.service';
import { PageId } from '../../../../constants/enums';

@Component({
  selector: 'app-edit-salespersonperformance',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIconModule,
    CommonModule,
  ],
  templateUrl: './edit-salespersonperformance.component.html',
  styleUrls: ['./edit-salespersonperformance.component.scss'],
})
export class EditSalesPeronPerformance extends BaseListComponent {
  filterForm: any;
  vendorList: any;
  dataSource: any;
  allowanceType: string = '';
  isAttendance: boolean = false;

  reasons: string[] = [
    'Health Reasons',
    'Personal Emergencies',
    'Transportation Challenges',
    'Weather-Related Challenges',
    'Family Obligations',
    'Scheduled Appointments',
    'Authorized Leave',
    'Professional Development Opportunities',
    'Legal Requirements',
    'Remote Work Arrangements',
    'Community Involvement',
    'Mental Health and Well-being',
    'Unforeseen Circumstances',
    'No charge on the mobile',
    'Other',
  ];
  constructor(
    public dialogRef: MatDialogRef<EditSalesPeronPerformance>,
    @Inject(MAT_DIALOG_DATA) public data: IAllowanceModel,
    router: Router,
    auth: AuthService,
    public fb: FormBuilder,
    route: ActivatedRoute
  ) {
    super(auth, router, route, PageId.bms_performance);
    this.allowanceType = this.getType(data.allowanceType);
    this.isAttendance = data.allowanceType == 1 ? true : false;
    this.filterForm = this.fb.group({
      allowance: [data.amount],
      exception: [null, [Validators.required]],
    });
  }
  override async ngOnInit() {
    await super.ngOnInit();
    this.filterForm.patchValue(this.data);
  }
  close() {
    this.dialogRef.close();
  }
  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }
  submit() {



    if (this.filterForm.invalid) {
      this.showMessage('Please Fill All Fields.', SwalMessageTypes.Warning);
      return;
    }

    if (!this.isAttendance) {

      if (this.filterForm.value.allowance==null) {
        this.showMessage(
          `Please Enter Amount`,
          SwalMessageTypes.Warning
        );
        return
      }


      const negativeFields = this.validateNegativeValues(this.filterForm.value);

      if (negativeFields.length) {
        this.showMessage(
          `${negativeFields} ,have negative values Please Check`,
          SwalMessageTypes.Warning
        );

        return;
      }
    }

    let data: IAllowanceModel = {
      allowanceType: this.data.allowanceType,
      performanceDate: this.data.performanceDate,
      salesPersonId: this.data.salesPersonId,
      amount: this.filterForm.value.allowance,
      reason: this.filterForm.value.exception,
    };
    this.dialogRef.close(data);
  }

  private validateNegativeValues(data: any): string[] {
    const numericInputs = [{ name: 'Allowance', value: data.allowance }];

    const negativeFields = numericInputs
      .filter((input) => input.value < 0)
      .map((input) => input.name);

    return negativeFields;
  }

  getType(type: number) {
    switch (type) {
      case 1:
        return 'Attendance';
      case 2:
        return 'Petrol';
      case 3:
        return 'Incentive';
      default:
        return 'null';
    }
  }
}
