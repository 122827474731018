@if(hasView){
<div class="container-fluid p-2">
    <mat-card class="p-3 my-2">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
                @if(this.hasBMSAdmin){
                <h3 class="title">Approvals</h3>}

            </div>
            <div class="col-md-6 text-end">
              <button
              mat-raised-button
              (click)="openFilter()"
              color="primary"
              class="me-2"
            ><div class="icon-dot" [ngClass]="{'active-filter': !checkQueryParam}"></div>
            <mat-icon class="me-0">filter_list</mat-icon>
            </button>
          </div>
            </div>
            <div class="row">
              <div class="col-md-12 table-wrapper">
                <table class="table">
                    <thead>
                        <tr>
                          <th>Vendor Email</th>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Updated At</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        @for (item of filterList; track $index) {
                            @if(item.type=='add-sku'){
                                <tr>
                                  <td>{{item?.vendor_name}}</td>
                                    <td>{{item.payload?.sku?.name}}</td>

                                    <td>{{item.type | type}}</td>
                                    <td><app-status [value]="item.status" ></app-status></td>
                                    <td>{{item.lastUpdatedAt.toDate() | date:'short'}}</td>
                                    <td>
                                        <!-- <button color="primary" mat-raised-button (click)="approve(item)">Approval</button> -->
                                        <button color="warn" mat-raised-button (click)="view(item)" class="mx-2"><mat-icon class="me-0">remove_red_eye</mat-icon></button>
                                    </td>
                                </tr>
                              }
                              @if(item.type=='update-sku'){
                                <tr>
                                  <td>{{item?.vendor_name}}</td>
                                    <td>{{item.payload?.sku?.name}}</td>

                                    <td>{{item.type | type}}</td>
                                    <td><app-status [value]="item.status" ></app-status></td>
                                    <td>{{item.lastUpdatedAt.toDate() | date:'short'}}</td>
                                    <td>
                                        <!-- <button color="primary" mat-raised-button (click)="approve(item)">Approval</button> -->
                                        <button color="warn" mat-raised-button (click)="view(item)" class="mx-2"><mat-icon class="me-0">remove_red_eye</mat-icon></button>
                                    </td>
                                </tr>
                              }
                              @if(item.type=='add-sku-to-catalog'){
                                <tr>
                                  <td>{{item?.vendor_name}}</td>
                                    <td>{{item.payload?.sku?.name}}</td>

                                    <td>{{item.type | type}}</td>
                                    <td><app-status [value]="item.status" ></app-status></td>
                                    <td>{{item.lastUpdatedAt.toDate() | date:'short'}}</td>
                                    <td>
                                        <!-- <button color="primary" mat-raised-button (click)="approve(item)">Approval</button> -->
                                        <button color="warn" mat-raised-button (click)="view(item)" class="mx-2"><mat-icon class="me-0">remove_red_eye</mat-icon></button>
                                    </td>
                                </tr>
                              }
                              @if(item.type=='update-sku-to-catalog'){
                                <tr>
                                  <td>{{item?.vendor_name}}</td>
                                    <td>{{item.payload?.sku?.name}}</td>

                                    <td>{{item.type | type}}</td>
                                    <td><app-status [value]="item.status" ></app-status></td>
                                    <td>{{item.lastUpdatedAt.toDate() | date:'short'}}</td>
                                    <td>
                                        <!-- <button color="primary" mat-raised-button (click)="approve(item)">Approval</button> -->
                                        <button color="warn" mat-raised-button (click)="view(item)" class="mx-2"><mat-icon class="me-0">remove_red_eye</mat-icon></button>
                                    </td>
                                </tr>
                              }
                              @if(item.type=='add-company'){
                                <tr>
                                  <td>{{item?.vendor_name}}</td>
                                    <td>{{item.payload?.name}}</td>

                                    <td>{{item.type | type}}</td>
                                    <td><app-status [value]="item.status" ></app-status></td>
                                    <td>{{item.lastUpdatedAt.toDate() | date:'short'}}</td>
                                    <td>
                                        <!-- <button color="primary" mat-raised-button (click)="approve(item)">Approval</button> -->
                                        <button color="warn" mat-raised-button (click)="view(item)" class="mx-2"><mat-icon class="me-0">remove_red_eye</mat-icon></button>
                                    </td>
                                </tr>
                              }
                              @if(item.type=='add-brand'){
                                <tr>
                                  <td>{{item?.vendor_name}}</td>
                                    <td>{{item.payload?.name}}</td>

                                    <td>{{item.type | type}}</td>
                                    <td><app-status [value]="item.status" ></app-status></td>
                                    <td>{{item.lastUpdatedAt.toDate() | date:'short'}}</td>
                                    <td>
                                        <!-- <button color="primary" mat-raised-button (click)="approve(item)">Approval</button> -->
                                        <button color="warn" mat-raised-button (click)="view(item)" class="mx-2"><mat-icon class="me-0">remove_red_eye</mat-icon></button>
                                    </td>
                                </tr>
                              }
                              @if(item.type=='add-attribute'){
                                <tr>
                                  <td>{{item?.vendor_name}}</td>
                                    <td>{{item.payload?.name}}</td>

                                    <td>{{item.type | type}}</td>
                                    <td><app-status [value]="item.status" ></app-status></td>
                                    <td>{{item.lastUpdatedAt.toDate() | date:'short'}}</td>
                                    <td>
                                        <!-- <button color="primary" mat-raised-button (click)="approve(item)">Approval</button> -->
                                        <button color="warn" mat-raised-button (click)="view(item)" class="mx-2"><mat-icon class="me-0">remove_red_eye</mat-icon></button>
                                    </td>
                                </tr>
                              }
                              @if(item.type=='add-variant-type'){
                                <tr>
                                  <td>{{item?.vendor_name}}</td>
                                    <td>{{item.payload?.name}}</td>

                                    <td>{{item.type | type}}</td>
                                    <td><app-status [value]="item.status" ></app-status></td>
                                    <td>{{item.lastUpdatedAt.toDate() | date:'short'}}</td>
                                    <td>
                                        <!-- <button color="primary" mat-raised-button (click)="approve(item)">Approval</button> -->
                                        <button color="warn" mat-raised-button (click)="view(item)" class="mx-2"><mat-icon class="me-0">remove_red_eye</mat-icon></button>
                                    </td>
                                </tr>
                              }
                              @if(item.type=='add-variant-value'){
                                <tr>
                                  <td>{{item?.vendor_name}}</td>
                                    <td>{{item.payload?.name}}</td>

                                    <td>{{item.type | type}}</td>
                                    <td><app-status [value]="item.status" ></app-status></td>
                                    <td>{{item.lastUpdatedAt.toDate() | date:'short'}}</td>
                                    <td>
                                        <!-- <button color="primary" mat-raised-button (click)="approve(item)">Approval</button> -->
                                        <button color="warn" mat-raised-button (click)="view(item)" class="mx-2"><mat-icon class="me-0">remove_red_eye</mat-icon></button>
                                    </td>
                                </tr>
                              }
                              @if(item.type=='add-category'){
                                <tr>
                                  <td>{{item?.vendor_name}}</td>
                                    <td>{{item.payload?.name}}</td>

                                    <td>{{item.type | type}}</td>
                                    <td><app-status [value]="item.status" ></app-status></td>
                                    <td>{{item.lastUpdatedAt.toDate() | date:'short'}}</td>
                                    <td>
                                        <!-- <button color="primary" mat-raised-button (click)="approve(item)">Approval</button> -->
                                        <button color="warn" mat-raised-button (click)="view(item)" class="mx-2"><mat-icon class="me-0">remove_red_eye</mat-icon></button>
                                    </td>
                                </tr>
                              }

                        }
                    </tbody>
                </table>
                <div class="d-flex justify-content-center my-2">
                    @if (loading) {
                      <mat-spinner [diameter]="30"></mat-spinner>
                    }
                  </div>
                <div class="d-flex justify-content-center my-2">
                    @if (!isDataAvailable() && !loading) {
                      <p class="no-data-found-text">No record found</p>
                    }
                  </div>
           </div>
            </div>
          </div>
      </mat-card>
</div>
}
@else {
  <p class="text-center my-5">You dont have permission to access this</p>
}

