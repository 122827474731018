import { Component, Input } from '@angular/core';
import { CommonService } from '../../../../services/common.service';
import { ProductService } from '../../../../pages/product/services/product.service';
import { first } from 'rxjs';
import { StatusComponent } from '../../../status/status.component';
import { TypePipe } from '../../../pipes/type.pipe';

@Component({
  selector: 'app-add-product',
  standalone: true,
  imports: [StatusComponent,TypePipe],
  templateUrl: './add-product.component.html',
  styleUrl: './add-product.component.scss'
})
export class AddProductComponent {
@Input() data:any;
productData:any;
constructor(public commonService: CommonService, public productService: ProductService){
  this.commonService.showLoder()
  setTimeout(() => {
    console.log(this.data,"data")
    this.getAll()
  }, 1000);
 
}
ngOnInit():void{}

getAll(){
  this.commonService.showLoder()
 this.productService.getDraftProductPreviewDetails(this.data.payload).subscribe((res:any)=>{
    this.productData=res.data
    console.log(res,"res")
    this.commonService.hideLoder()
   }, (err:any)=>{
    this.commonService.hideLoder()
    this.commonService.showToaster("error",err.message)

   })
   
}
}
