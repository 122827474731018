<ng-container>
  <div class="container-fluid p-2">
    <mat-card class="p-3">
      <mat-card-content class="p-0">
        <div class="custom-card" *ngIf="dataSource">
          <div class="container">
            <div>
              <p class="p-2" [routerLink]="['/dashboard/merchant']">/Vendor Geo Mapping</p>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="row p-2">
                  <div class="col-sm-4 ft-20">Business Name </div>
                  <div class="col-sm-8 ft-19">: {{dataSource.business_name}}</div>
                </div>
                <div class="row p-2">
                  <div class="col-sm-4 ft-20">Name</div>
                  <div class="col-sm-8 ft-19">: {{ dataSource.name }}</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row p-2">
                  <div class="col-sm-4 ft-20">Mobile Number</div>
                  <div class="col-sm-6 ft-19">: {{ dataSource.mobile_number }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- (click)="downloadAreaWiseCustomers()" -->
        <div class="row custom-flex justify-content-end">
          <!-- <div class="col-md-3 text-center mt-2">
            <button mat-raised-button color="primary ml" class="custom-button">
              DOWNLOAD CUSTOMER DATA
            </button>
          </div> -->
          <div class="col-md-3 text-center mt-2">
            <button mat-raised-button color="primary ml-w" (click)="openPopUp()" class="custom-button">
              UPLOAD LOCATION
            </button>
          </div>
          <div class="col-md-3 text-center mt-2" *ngIf="locationsList.length > 0">
            <button mat-raised-button color="primary ml-w" (click)="downloadGeoJsonVendor()" class="custom-button">
              DOWNLOAD LOCATION
            </button>
          </div>
        </div>

        <ng-container class="mt-2">
          <div class="table-block">
            <div class="m-1 flex flex-col md:flex-row justify-between">
              <mat-card class="p-0" *ngIf="locationsList.length > 0">
                <mat-card-content class="p-0">
                  <mat-sidenav-container fxFlex="0 1 auto">
                    <mat-sidenav-content>
                      <div class="table-container">
                        <mat-table [dataSource]="locationsList" matSort>
                          <ng-container matColumnDef="locations">
                            <mat-header-cell *matHeaderCellDef>
                              Locations
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="locations">
                              {{ row.locationName }}
                            </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="company">
                            <mat-header-cell *matHeaderCellDef>
                              Company
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="company">
                              {{ row.companyNames }}
                            </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="action">
                            <mat-header-cell *matHeaderCellDef>
                              Action
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row">
                              <button mat-raised-button class="button" color="primary"
                                (click)="openPopUpCompany(row.companyIds,0,row.locationId)">
                                <mat-icon class="me-0">add</mat-icon>
                              </button>
                              <button mat-raised-button color="primary" class="button"
                                (click)="openPopUpEditCompany(row.companyIds,1,row.locationId)">
                                <mat-icon class="me-0">edit</mat-icon>
                              </button>

                              <button mat-raised-button color="primary"
                                (click)="downloadLocationGeoJson(row.locationId, row.locationName)">
                                <mat-icon class="me-0">download</mat-icon>
                              </button>
                            </mat-cell>
                          </ng-container>

                          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                        </mat-table>
                      </div>
                    </mat-sidenav-content>
                  </mat-sidenav-container>
                </mat-card-content>
              </mat-card>
            </div>

          </div>
        </ng-container>

      </mat-card-content>
    </mat-card>
  </div>
</ng-container>