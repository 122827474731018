import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Auth } from '@angular/fire/auth';
import { AuthService } from '../../../services/auth.service';
import { lastValueFrom } from 'rxjs';
import { FirebaseDbService } from '../../../services/firebase-db.service';


@Injectable({
  providedIn: 'root'
})
export class InventoryService {
  auth: Auth = inject(Auth);
  constructor(public http: HttpClient, public authService: AuthService, private firebaseDbService: FirebaseDbService) { }

  async insertInventory(data: any) {
    return await lastValueFrom(this.http.post(`${environment.inventoryUrl}/api/inventory/insert`, data))
  }
}
