import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, map } from 'rxjs';
import {
  DownloadIndentSheet,
  DownloadOrdersIndentSheet,
} from '../pages/logistics/trip/models/trip.model';
import {
  IProcessedProduct,
  ModifyOrderByHop,
  OrderReturnApiInput,
  ProductViewProductDetails,
  ProductViewProductDetailsResponse,
  productViewResponse,
} from '../models/order';
import { getItemQtyInCasesPieces } from '../utlity/utility';
import {
  Address,
  Coupon,
  Customer,
  DeviceInfo,
  OrderDetailsEntity,
  OrderItem,
  SalesPerson,
  Vendor,
} from '../pages/order/model/model-entity';
import { MerchantBusinessType, OrderStatus } from '../enums/enums';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  baseServiceUrl: string = `${environment.ordersServiceUrl}`;
  logisticsServiceUrl: string = `${environment.logisticsUrl}`;
  // logisticsServiceUrl: string = " http://localhost:4013";

  constructor(private http: HttpClient) {}
  async getOrdersByVendorId(body: any): Promise<any> {
    try {
      const url: string = `${this.baseServiceUrl}/orders/vendor/get_by_filters`;
      const res = await lastValueFrom(this.http.post<any>(url, body));
      return res;
    } catch (error) {
      console.error('Error fetching lookup types:', error);
    }
  }
  async getOrdersSummaryByVendorId(body: any): Promise<any> {
    try {
      const url: string = `${this.baseServiceUrl}/orders/vendor/get_summery_by_filters`;
      const res = await lastValueFrom(this.http.post<any>(url, body));
      return res;
    } catch (error) {
      console.error('Error fetching lookup types:', error);
    }
  }
  async getOrderItemsByVendorId(body: any): Promise<any> {
    const url: string = `${this.baseServiceUrl}/orders/items/vendor/get_by_filters`;
    try {
      const res = await lastValueFrom(this.http.post<any>(url, body));
      return res;
    } catch (error) {
      throw error;
    }
  }
  async adjustOrderByVendor(body: any, orderId: string): Promise<any> {
    const url: string = `${this.baseServiceUrl}/orders/${orderId}/items_modify`;
    try {
      const res = await lastValueFrom(this.http.post<any>(url, body));
      return res;
    } catch (error) {
      throw error;
    }
  }

  async indentSheet(
    body: DownloadOrdersIndentSheet,
    vendorBusinessType: number = 0
  ): Promise<void> {
    try {
      let url;
      if (vendorBusinessType == MerchantBusinessType.JitVendor) {
        url = `${this.baseServiceUrl}/orders/download_nauka_orders_indent`;
      } else {
        url = `${this.logisticsServiceUrl}/api/trips/orders/download_indent_sheet`;
      }
      const response = await this.http
        .post(`${url}`, body, {
          responseType: 'blob',
          observe: 'response',
        })
        .toPromise();

      if (
        response &&
        response.headers.get('Content-Type') === 'application/pdf'
      ) {
        const contentDisposition = response.headers.get('Content-Disposition');
        const filename = contentDisposition
          ? contentDisposition.split(';')[1].split('=')[1].trim()
          : 'indent_sheet.pdf';

        if (response.body) {
          const blob = new Blob([response.body], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        } else {
          console.error('Error downloading file: Response body is null');
        }
      } else {
        console.error('Error downloading file: Invalid response');
      }
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  }

  async getOrderItemsBySummeryNaukaVendorId(
    body: any
  ): Promise<productViewResponse> {
    const url: string = `${this.baseServiceUrl}/orders/items_summery_Nauka/vendor/get_by_filters`;
    try {
      const response = await lastValueFrom(this.http.post<any>(url, body));
      const mappedEntity = this.mapOrderProductSummery(response.data);
      return {
        totalCount: response.count,
        data: mappedEntity,
        totalRevenue: +response?.totalRevenue.toFixed(2),
        totalWeight: response?.totalWeight && response?.totalWeight.toFixed(2),
      };
    } catch (error) {
      throw error;
    }
  }

  async getOrderItemsBySummeryVendorId(
    body: any
  ): Promise<productViewResponse> {
    const url: string = `${this.baseServiceUrl}/orders/items_summery/vendor/get_by_filters`;
    try {
      const response = await lastValueFrom(this.http.post<any>(url, body));
      const mappedEntity = this.mapOrderProductSummery(response.data);
      return {
        totalCount: response.count,
        data: mappedEntity,
        totalRevenue: Math.round(response?.totalRevenue),
        totalWeight: response?.totalWeight && response?.totalWeight.toFixed(2),
      };
    } catch (error) {
      throw error;
    }
  }

  private mapOrderProductSummery(
    data: ProductViewProductDetailsResponse[]
  ): ProductViewProductDetails[] {
    return data.map((item) => {
      const { pieces, cases } = getItemQtyInCasesPieces(
        +item.upc,
        +item.total_qty
      );
      const orderNumbers = item.ordernumbers
        .filter((order) => order.quantity !== 0)
        .map((order) => ({
          createdAt: new Date(order.created_at).getTime(),
          orderDate: new Date(order.order_date).getTime(),
          orderNumber: order.order_number,
          quantity: order.quantity,
          skuId: item.sku_id,
          order_status_id: order.order_status_id,
          total_weight: order.total_weight,
        }));
      const invoiceQuantities = item.ordernumbers
        .filter((order) => order.quantity > 0)
        .filter((order) => order.order_status_id >= OrderStatus.OrderInvoiced)
        .reduce((sum, order) => sum + order.quantity, 0);

      const availableQty =
        item.total_qty > invoiceQuantities
          ? item.total_qty - invoiceQuantities
          : 0;
      const unitWeight =
        item.ordernumbers[0].total_weight / item.ordernumbers[0].quantity;

      const productDetails: ProductViewProductDetails = {
        skuId: item.sku_id,
        skuName: item.sku_name,
        vendorId: item.vendor_id,
        upc: item.upc,
        sellingPrice: +(+item.selling_price).toFixed(2),
        inventory_selling_price: +((item?.inventory_selling_price ??
          0) as number).toFixed(2),
        mrp: +(+item.mrp).toFixed(2),
        pieces: pieces,
        cases: cases,
        invoiceQty: invoiceQuantities || 0,
        availableQty: availableQty || 0,
        totalQuantity: Math.round(item.total_qty),
        orderNumbers: orderNumbers,
        unitWeight: unitWeight,
      };

      return productDetails;
    });
  }

  async orderDetails(orderNumber: string): Promise<any> {
    const url: string = `${this.baseServiceUrl}/orders/${orderNumber}`;
    try {
      const res = await lastValueFrom(this.http.get<any>(url));
      const mapEntity: OrderDetailsEntity = this.mapOrderDetails(res);

      return mapEntity;
    } catch (error) {
      console.error('Error fetching order details:', error);
    }
  }

  async downloadInvoice(body: any, name: string): Promise<any> {
    const url: string = `${this.baseServiceUrl}/invoice/download_invoices`;
    try {
      const response = await this.http
        .post(url, body, {
          responseType: 'blob',
          observe: 'response',
        })
        .toPromise();

      if (
        response &&
        response.headers.get('Content-Type') === 'application/pdf'
      ) {
        const contentDisposition = response.headers.get('Content-Disposition');
        const filename = contentDisposition
          ? contentDisposition.split(';')[1].split('=')[1].trim()
          : name;

        if (response.body) {
          const blob = new Blob([response.body], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        } else {
          console.error('Error downloading file: Response body is null');
        }
      } else {
        console.error('Error downloading file: Invalid response');
      }
    } catch (error) {
      console.log('Error while downloading the invoice', error);
    }
  }

  private mapOrderDetails(order: OrderDetailsEntity): OrderDetailsEntity {
    const coupon: Coupon = {
      id: order.coupon.id,
      description: order.coupon.description,
      discount_amount: order.coupon.discount_amount,
    };
    const customerAddress: Address = {
      street: order.customer.address.street,
      area_code: order.customer.address.area_code,
      pincode: order.customer.address.pincode,
      latitude: order.customer.address.latitude,
      longitude: order.customer.address.longitude,
      state: order.customer.address.state,
      country: order.customer.address.country,
      city: order.customer.address.city,
    };
    const vendorAddress: Address = {
      street: order.vendor.address.street,
      area_code: order.vendor.address.area_code,
      pincode: order.vendor.address.pincode,
      latitude: order.vendor.address.latitude,
      longitude: order.vendor.address.longitude,
      state: order.vendor.address.state,
      country: order.vendor.address.country,
      city: order.vendor.address.city,
    };
    const customer: Customer = {
      id: order.customer.id,
      name: order.customer.name,
      business_name: order.customer.business_name,
      mobile_number: order.customer.mobile_number,
      email: order.customer.email,
      business_type_id: order.customer.business_type_id,
      gst_number: order.customer.gst_number.length
        ? order.customer.gst_number
        : 'Un-Registered',
      address: customerAddress,
    };
    const vendor: Vendor = {
      id: order.vendor.id,
      name: order.vendor.name,
      business_name: order.vendor.business_name,
      mobile_number: order.vendor.mobile_number,
      business_type_id: order.vendor.business_type_id,
      gst_number: order.vendor.gst_number,
      address: vendorAddress,
    };
    const salesPerson: SalesPerson = {
      id: order.sales_person.id || '',
      name: order.sales_person.name || '',
      mobile_number: order.sales_person.mobile_number || '',
    };
    const primarySalesPerson: SalesPerson = {
      id: order.primary_sales_person.id || '',
      name: order.primary_sales_person.name || '',
      mobile_number: order.primary_sales_person.mobile_number || '',
    };
    const deviceInfo: DeviceInfo = {
      os: order.device_info.os || '',
      app_version: order.device_info.app_version || '',
    };

    const orderItems: OrderItem[] = order.order_items.map((item) => ({
      base_amount: item.base_amount,
      cess: item.cess,
      cess_percentage: item.cess_percentage,
      cgst: item.cgst || 0,
      cgst_percentage: item.cgst_percentage,
      coupon_discount_amount: item.coupon_discount_amount,
      created_at: item.created_at,
      hsn_code: item.hsn_code,
      id: item.id,
      igst: item.igst,
      igst_percentage: item.igst_percentage,
      image_url: item.image_url || '',
      is_nil_exempt: item.is_nil_exempt,
      l_w_h: item.l_w_h,
      llc_id: item.llc_id,
      llc_name: item.llc_name,
      margin: item.margin,
      mlc_id: item.mlc_id,
      mlc_name: item.mlc_name,
      mrp: item.mrp,
      net_amount: Math.round(item.net_amount),
      order_number: item.order_number,
      package_type: item.package_type,
      quantity: item.quantity,
      selling_price: item.selling_price,
      service_fee: item.service_fee,
      sgst: item.sgst,
      sgst_percentage: item.sgst_percentage,
      sku_id: item.sku_id,
      sku_name: item.sku_name,
      slc_id: item.slc_id,
      slc_name: item.slc_name || null,
      stock_uom: item.stock_uom,
      tlc_id: item.tlc_id,
      tlc_name: item.tlc_name,
      total_weight: item.total_weight,
      upc: item.upc,
      updated_at: item.updated_at,
      weight_per_unit: item.weight_per_unit,
    }));

    return {
      order_number: order.order_number,
      order_date: this.formatTimestamp(order.order_date),
      order_status_id: order.order_status_id,
      logistics_status_id: order.logistics_status_id,
      logistics_partner: order.logistics_partner,
      delivery_date:
        this.formatTimestamp(order.delivery_date) || 'Not yet planned',
      expected_delivery_date:
        this.formatTimestamp(order.expected_delivery_date) || '',
      outstanding_amount:
        Math.round(+order.outstanding_amount).toString() || '0',
      item_total: Math.round(+order.item_total).toString() || '0',
      delivery_fee: order.delivery_fee || '0',
      service_fee: order.service_fee || '0',
      total_amount: Math.round(+order.total_amount).toString() || '0',
      rounding_adjustment:
        Math.round(+order.rounding_adjustment).toString() || '0',
      rounded_total: order.rounded_total || '0',
      total_weight: order.total_weight || '0',
      cluster: order.cluster || '',
      location_id: order.location_id || '',
      coupon: coupon,
      customer: customer,
      vendor: vendor,
      sales_person: salesPerson,
      primary_sales_person: primarySalesPerson,
      device_info: deviceInfo,
      order_items: orderItems,
    };
  }

  formatTimestamp(timestamp: string | null): string {
    if (!timestamp) {
      return '';
    }

    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  async updateOrderStatus(
    orderNumber: string,
    body: { status: number }
  ): Promise<any> {
    return await lastValueFrom(
      this.http.put(`${this.baseServiceUrl}/orders/${orderNumber}/status`, body)
    );
  }

  async bulkOrderModify(payLoad: OrderReturnApiInput[]): Promise<boolean> {
    try {
      const fullUrl: string = `${this.baseServiceUrl}/orders/bulk_orders_modify`;
      const response = await lastValueFrom(
        this.http.post<any>(fullUrl, payLoad)
      );

      return response.success;
    } catch (error) {
      console.error('bulkOrderModify error:', error);
      return false;
    }
  }

  async updateHopItemsByOrderItems(
    updateOrder: ModifyOrderByHop[]
  ): Promise<any> {
    try {
      const fullUrl: string = `${this.logisticsServiceUrl}/api/hops/order_items/bulk`;
      const response = await lastValueFrom(
        this.http.put<any>(fullUrl, updateOrder)
      );

      return response;
    } catch (error) {
      console.log('updateHopItems error', error);
      return [];
    }
  }

  async downloadEWayBillJson(body: any): Promise<any> {
    try {
      const response = await lastValueFrom(
        this.http.post<any>(
          `${this.logisticsServiceUrl}/api/trips/download_e_way_bill_json`,
          body
        )
      );
      return response && response.data;
    } catch (error) {}
  }
}
