@if(hasView){
<ng-container>
  <div class="heading-block">
    <div class="row">
      <div class="col-md-6">
        <h2 class="card-title m-3">Resource</h2>
      </div>
      <div class="col-md-6 text-right">
        @if(hasCreate){
        <button
          mat-raised-button
          color="primary"
          [routerLink]="['/dashboard/logistics/create-resource']"
          class="topButtonSectionbtn"
        >
          Add Resource
        </button>
        }
      </div>
    </div>
  </div>
  <div class="search-block">
    <form [formGroup]="form">
      <section id="content">
        <div class="card">
          <div class="card-body card-padding">
            <div class="row">
              <div class="col-md-3">
                <mat-form-field class="full-width">
                  <mat-label>Mobile Number</mat-label>
                  <input
                    matInput
                    formControlName="mobileNumber"
                    name="mobileNumber"
                    type="string"
                    placeholder="Mobile Number"
                    [maxLength]="10"
                  />
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field class="full-width">
                  <mat-label>Designation</mat-label>
                  <mat-select formControlName="extractedData">
                    <mat-option
                      *ngFor="let item of extractedData"
                      [value]="item"
                    >
                      {{ item.value }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field class="full-width">
                  <mat-label>Status</mat-label>
                  <mat-select formControlName="status">
                    <mat-option
                      *ngFor="let status of resourceStatus"
                      [value]="status"
                    >
                      {{ getStatusName(status) }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-3 searchbtns">
                <div class="text-left mt-2">
                  <button
                    mat-raised-button
                    color="primary"
                    class="text-center ml"
                    (click)="searchTasks()"
                  >
                    Search
                  </button>
                </div>
                <div class="text-right mt-2 clearfiltrtbtn">
                  <button
                    mat-raised-button
                    color="primary"
                    class="text-center ml-w"
                    (click)="clearFilters()"
                  >
                    Clear Filters
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  </div>

  <div class="table-block">
    <div class="m-3 flex flex-col md:flex-row justify-between">
      <mat-card class="p-0">
        <mat-card-content class="p-0">
          <mat-sidenav-container fxFlex="0 1 auto">
            <mat-sidenav-content>
              <div class="table-container">
                <mat-table [dataSource]="dataSource" matSort>
                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="name">
                      {{ row.name }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="mobileNumber">
                    <mat-header-cell *matHeaderCellDef>
                      Mobile Number
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="mobileNumber">
                      {{ row.mobileNumber }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="designation">
                    <mat-header-cell *matHeaderCellDef>
                      Designation
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="designation">
                      {{ designationMap[row.designation] }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="contractMode">
                    <mat-header-cell *matHeaderCellDef>
                      Contract Mode
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="contractMode">
                      {{ getSupplierContractModeText(row.contractMode) }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef>
                      Status
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="status">
                      {{ getStatusName(row.status) }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="edit">
                    <mat-header-cell
                      *matHeaderCellDef
                      class="mat-column-actions"
                    >
                      Edit
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="mat-column-actions">
                      <div class="d-flex">
                        @if(hasEdit){
                        <button mat-icon-button (click)="editResource(row)">
                          <mat-icon>edit</mat-icon>
                        </button>
                        }
                      </div>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="delete">
                    <mat-header-cell
                      *matHeaderCellDef
                      class="mat-column-actions"
                    >
                      Delete
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="mat-column-actions">
                      <div class="d-flex">
                        @if(hasDelete){
                        <button mat-icon-button (click)="deleteResource(row)">
                          <mat-icon color="warn">delete</mat-icon>
                        </button>
                        }
                      </div>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row
                    *matHeaderRowDef="displayedColumns"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></mat-row>
                </mat-table>
              </div>
            </mat-sidenav-content>
          </mat-sidenav-container>
          <mat-paginator
            [length]="totalResources"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
            (page)="onPageChange($event)"
          ></mat-paginator>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</ng-container>
} @else {
<ng-container>

</ng-container>
}
