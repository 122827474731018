@if(hasEdit){
<ng-container>
  <div class="heading-block">
    <div class="row">
      <div class="col-md-6">
        <mat-icon class="backicon" [routerLink]="['/dashboard/logistics/supplier-list']">arrow_back</mat-icon>
        <span class="header">
          <h2 class="card-title m-3">Edit Supplier</h2>
        </span>
      </div>
      <div class="col-md-6 text-right"></div>
    </div>
  </div>
  <div class="table-block">
    <div class="mat-elevation-z8">
      <form [formGroup]="dataSource">
        <section id="content">
          <div class="card">
            <div class="card-body card-padding">
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Supplier Name</mat-label>
                    <input matInput formControlName="name" name="name" placeholder="Supplier Name" />
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Mobile Number</mat-label>
                    <input matInput type="number" formControlName="mobileNumber" name="mobileNumber"
                      placeholder="Mobile Number" [maxLength]="10" />
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Address</mat-label>
                    <input matInput formControlName="residence" name="residence " placeholder="Flat No/ Address" />
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Area Name</mat-label>
                    <input matInput formControlName="area" name="area " placeholder="Area Name" />
                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Landmark</mat-label>
                    <input matInput formControlName="landmark" name="landmark " placeholder="Land Mark" />
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>City</mat-label>
                    <input matInput formControlName="city" name="city" placeholder="City" />
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Pin Code</mat-label>
                    <input matInput formControlName="pinCode" name="pinCode" placeholder="Pin Code" type="number"
                      [maxlength]="6" />
                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>select State</mat-label>
                    <mat-select formControlName="state" [(value)]="dataSource.State">
                      @for (state of states; track state) {
                      <mat-option [value]="state.name">{{
                        state.name
                        }}</mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Latitude</mat-label>
                    <input matInput formControlName="latitude" name="latitude" placeholder="Latitude" />
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Longitude</mat-label>
                    <input matInput formControlName="longitude" name="longitude" placeholder="Longitude" />
                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Aadhar Number</mat-label>
                    <input matInput type="number" formControlName="aadharNumber" name="aadharNumber"
                      (input)="toUppercase($event)" placeholder="Aadhar Number" />
                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Pan Number</mat-label>
                    <input matInput formControlName="panNumber" name="panNumber" placeholder="Pan Number"
                      (input)="toUppercase($event)" />
                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>GST Number</mat-label>
                    <input matInput formControlName="gstNumber" name="gstNumber" (input)="toUppercase($event)"
                      placeholder="GST Number" />
                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Supplier Types</mat-label>
                    <mat-select formControlName="supplierType" [(value)]="dataSource.value.supplierType">
                      <mat-option *ngFor="let type of supplierTypes" [value]="type">
                        {{ type === 1 ? "Manpower" : "Transporter" }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>TCS Percentage</mat-label>
                    <input matInput formControlName="tcsPercentage" name="tcsPercentage" placeholder="TCS Percentage"
                      type="number" />
                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Supplier Contract Mode</mat-label>
                    <mat-select formControlName="contractMode" [(value)]="dataSource.value.contractMode">
                      <mat-option *ngFor="let type of supplierContractMode" [value]="type">
                        {{ type === 1 ? "Fixed" : "Adhoc" }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Status</mat-label>
                    <mat-select formControlName="status" [(value)]="dataSource.value.status">
                      <mat-option *ngFor="let type of supplierStatus" [value]="type">
                        {{ type === 0 ? "InActive" : "Active" }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              
              <div class="col-md-6">
                <mat-form-field class="full-width locationinput">
                  <mat-label>Location</mat-label>
                  <mat-select formControlName="location" [(value)]="dataSource.value.location">
                    @for (item of locations; track item) {
                      <mat-option [value]="item.value">{{
                        item.value
                        }}</mat-option>
                      }
                  </mat-select>
                </mat-form-field>
              </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <mat-label>Upload GST</mat-label>
                </div>
                <div class="row">
                  <div class="imgSupplier imgpop-up" *ngIf="dataSource.value.gstCopy">
                    <img [src]="dataSource.value.gstCopy" alt="gst image"
                      (click)="openPopUp(dataSource.value.gstCopy)" />
                  </div>
                  <div class="imageupload">
                    <input formControlName="gstCopy" class="form-control" (change)="onFileChange($event, 'gst')"
                      type="file" />
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="row">
                  <mat-label>Upload Pan</mat-label>
                </div>

                <div class="row">
                  <div class="imgSupplier" *ngIf="dataSource.value.panCopy">
                    <img [src]="dataSource.value.panCopy" class="imgSupplier imgpop-up" alt="Pan image"
                      (click)="openPopUp(dataSource.value.panCopy)" />
                  </div>
                  <div class="imageupload">
                    <input formControlName="panCopy" class="form-control" (change)="onFileChange($event, 'pan')"
                      type="file" />
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="row">
                  <mat-label>Upload Aadhar</mat-label>
                </div>

                <div class="row">
                  <div class="imgSupplier" *ngIf="dataSource.value.aadharCopy">
                    <img [src]="dataSource.value.aadharCopy" class="imgSupplier imgpop-up" alt="Aadhar image"
                      (click)="openPopUp(dataSource.value.aadharCopy)" />
                  </div>
                  <div class="imageupload">
                    <input formControlName="aadharCopy" class="form-control" (change)="onFileChange($event, 'aadhar')"
                      type="file" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="buttonSection">
                  @if(hasEdit){
                  <button mat-raised-button color="primary" class="text-center ml" (click)="updateSupplier()">
                    Update
                  </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
</ng-container>
} @else {
<ng-container>
 
</ng-container>
} 
