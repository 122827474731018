import { get } from '@angular/fire/database';
import { Component, Injectable } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { SettingService } from '../../../settings/services/settings.service';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MerchantBusinessType } from '../../../../enums/enums';
interface VendorInfo {
  id: number;
  name: string;
  email: string;
  mobileNumber: string;
  businessName: string;
  businessTypeId: string;
  businessType: string;
  apiUrl: string;
  isActive: boolean | string;

}

@Component({
  selector: 'app-info',
  standalone: true,
  imports: [
    MatDialogModule,
    RouterModule,
    MatSelectModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    MatTableModule,
    MatSelectModule,
    CommonModule,
    ReactiveFormsModule
  ],
  templateUrl: './info.component.html',
  styleUrl: './info.component.scss',
})

@Injectable({
  providedIn: 'root'
})
export class InfoComponent {
  merchantDetails: any;
  merchantResult: any;
  dataSource: any;
  businessTypeNames: { [key: number]: string } = {
    [MerchantBusinessType.Distributor]: 'Distributor',
    [MerchantBusinessType.Manufacturer]: 'Manufacturer',
    [MerchantBusinessType.Wholesaler]: 'Wholesaler',
    [MerchantBusinessType.JitVendor]: 'JitVendor',
    [MerchantBusinessType.Inventory]: 'Inventory',
    [MerchantBusinessType.None]: 'None'
  };

  constructor(
    private route: ActivatedRoute,
    public settingService: SettingService,
    private fb: FormBuilder,
  ) {
    this.dataSource = this.fb.group({
      id: [null, [Validators.required]],
      name: [null, [Validators.required]],
      mobileNumber: [null, [Validators.required]],
      businessName: [null, [Validators.required]],
      email: [null, Validators.required],
      businessType: [null, Validators.required],
    });

  }

  ngOnInit(): void {
    this.getMerchantDetails();

  }

  getBusinessTypeName(type: number): string {
    if (type === undefined || this.businessTypeNames[type] === undefined) {
      return 'Unknown';
    }
    return this.businessTypeNames[type];
  }
  getMerchantDetails() {
    this.route.paramMap.subscribe((params) => {
      const state = history.state;
      this.merchantDetails = state.merchantDetails;
      this.merchantResult = this.mapToMerchants(this.merchantDetails);
      this.dataSource.patchValue({
        id: this.merchantDetails.id,
        name: this.merchantDetails.name,
        mobileNumber: this.merchantDetails.mobile_number,
        businessName: this.merchantDetails.business_name,
        email: this.merchantDetails.email,
        businessType: this.merchantDetails.business_type
      });
    });

  }

  mapToMerchants(data: any): VendorInfo {
    return {
      id: data.id,
      name: data.name,
      email: data.email,
      mobileNumber: data.mobileNumber,
      businessName: data.businessName,
      businessTypeId: data.businessTypeId,
      businessType: data.businessTypeId,
      apiUrl: data.vrsMappingInfo?.apiUrl || null,
      isActive: data.isActive ? "Active" : "In-Active"
    };
  }


}
