import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { RouterLink, RouterOutlet } from '@angular/router';
import { AddGeoComponent } from '../add-geo/add-geo.component';
import { MatDialog } from '@angular/material/dialog';
import { IAreaDialogData, MasterCluster } from '../services/master-geo-model';
import { generateClusterId } from '../../../utlity/utility';
import { MasterGeoService } from '../services/master-geo.service';
import { MatListModule } from '@angular/material/list';
import Swal from 'sweetalert2';
import { AppLoaderService } from '../../../shared/app-loader/app-loader.service';
import { PolygonService } from '../../../services/polygon.service';
@Component({
  selector: 'master-geo-list',
  standalone: true,
  imports: [
    RouterOutlet,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    RouterLink,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    MatTableModule,
    MatTableModule,
    MatSidenavModule,
    MatPaginatorModule,
    CommonModule,
    MatInputModule,
    MatListModule
  ],

  templateUrl: './master-geo-list.component.html',
  styleUrl: './master-geo-list.component.scss',
})
export class MasterGeoListComponent implements OnInit {
  page_id = "bms_master_geo_list";
  dataSource: MasterCluster[] = [];
  constructor(private dialog: MatDialog,
    private masterGeoService: MasterGeoService,
    private readonly egretLoader: AppLoaderService,
    private polygonService: PolygonService


  ) { }

  async ngOnInit() {
    await this.getMasterAreas();
  }

  async getMasterAreas() {
    try {
      this.egretLoader.open();
      const clusters = await this.masterGeoService.getAllMasterClusters();
      if (clusters) {
        this.egretLoader.close();

        this.dataSource = clusters;
      } else {
        this.egretLoader.close();
        Swal.fire('Warning!', 'No Areas Added', 'warning');

      }
    } catch (error) {
      this.egretLoader.close();
      Swal.fire('Warning!', 'No Areas Added', 'warning');
    }
  }

  openPopUp(cluster = null) {
    const dialogRef = this.dialog.open(AddGeoComponent, {
      width: "50%",
      maxWidth: "100vw",
      maxHeight: "80vh",
      data: {
        title: 'Add Area',
        cluster: cluster,
        currentClusterSearchKeys: this.dataSource.map((c) => c.searchKey),
      } as IAreaDialogData
    });
    dialogRef.afterClosed().subscribe(async (response: any) => {
      if (response) {
        const cluster: MasterCluster = response.cluster || {};
        if (cluster) {
          cluster.id = generateClusterId();


          if (cluster.coordinates && cluster.coordinates.length > 0) {

            const coordinates = cluster?.coordinates?.map(item => [item.latitude, item.longitude]) || [];

            const overLappedClusters: string[] = this.checkAreaOverlap(coordinates, this.dataSource);

            if (overLappedClusters.length > 0) {
              Swal.fire('Warning!', `<span style="color: green">${overLappedClusters.join(',')}</span> Areas Coordinates Matching with <span style="color: red">${cluster.name}</span>`, 'warning');

              return;
            }
          }
          try {

            await this.masterGeoService.createMasterCluster(cluster);
            Swal.fire("Success", "Area Uploaded Successfully", "success")
            await this.getMasterAreas();
          } catch (error) {

            this.egretLoader.close();
            Swal.fire('warning', 'Something Went Wrong', 'warning');
          }
        }
      }
    });
  }




  private checkAreaOverlap(coordinates: number[][], existingClusters: Array<MasterCluster>) {
    const overlappingClusterNames: Array<string> = [];
    for (const existingCluster of existingClusters) {
      if (existingCluster.coordinates != null) {
        const transformedData = existingCluster?.coordinates?.map(item => [item.latitude, item.longitude]) ?? [];
        if (this.polygonService.arePolygonsOverlapping(
          coordinates,
          transformedData
        )) {
          overlappingClusterNames.push(existingCluster.name);
        } else {
          console.log('not overlap', existingCluster.name);
        }
      }
    }
    return overlappingClusterNames;
  }
}
