import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {
  RouterOutlet,
  RouterLink,
  Router,
  ActivatedRoute,
} from '@angular/router';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Output,
} from '@angular/core';
import Swal from 'sweetalert2';
import { InventoryService } from '../../services/inventory.service';
import { uuidv4 } from '../../../../utlity/utility';
import { AuthService } from '../../../../services/auth.service';
import { BaseListComponent } from '../../../../shared/core/base.list.component';
import { PageId } from '../../../../constants/enums';

@Component({
  selector: 'app-filter-popup',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatButtonModule,
    RouterOutlet,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatCardModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    CommonModule,
    RouterLink,
    ReactiveFormsModule,
    MatIconModule,
  ],
  templateUrl: './stockpopup.component.html',
  styleUrl: './stockpopup.component.scss',
})
export class StockPopUp extends BaseListComponent {
  dataSource: any;
  productData: any;

  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private inventoryService: InventoryService
  ) {
    super(auth, router, route, PageId.seller_price_and_stock);
    this.dataSource = this.fb.group({
      availableQuantity: [this.data?.rowData?.available_stock ?? 0, Validators.required],
      damageQuantity: [null, Validators.required],
    });
  }
  override async ngOnInit() {
    await super.ngOnInit();
    this.productData = this.data;
  }

  mapToInventoryObject(input: any, availableQty: number, damageQty: number) {
    return {
      id: uuidv4(),
      sku_id: input?.rowData?.skus_id,
      vendor_id: input?.rowData?.vendor_id,
      quantity:
        availableQty,
      sale_in_progress: 0,
      purchase_in_progress: 0,
      damage: damageQty != 0 ? damageQty : 0,
      created_at: new Date(),
      updated_at: new Date(),
    };
  }

  async submitForm() {
    const availableQuantity = +this.dataSource.value.availableQuantity;
    const damageQuantity = +this.dataSource.value.damageQuantity || 0;

    if (availableQuantity < 0 || damageQuantity < 0) {
      Swal.fire('Warning', 'Negative values are not allowed', 'warning');
      return;
    }
    if (
      isFinite(availableQuantity) &&
      isFinite(damageQuantity) &&
      availableQuantity >= 0 &&
      damageQuantity >= 0
    ) {
      const inventoryObj = this.mapToInventoryObject(
        this.productData,
        availableQuantity,
        damageQuantity
      );
      const inventoryResponse: any =
        await this.inventoryService.insertInventory(inventoryObj);
      if (inventoryResponse.success) {
        const returnObj = {
          rowData: this.productData,
          availableQuantity: availableQuantity,
        };
        Swal.fire('Success', 'Succesfully Updated', 'success');
        this.dialogRef.close(returnObj);
      }
    } else {
      Swal.fire(
        'Error',
        'Please enter valid integer values for available quantity and damage quantity',
        'error'
      );
    }
  }
}
