import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, map } from 'rxjs';
import axios from 'axios';
import { DownloadType, PurchaseFormatType } from '../../../enums/enums';
import { getDateDDMMYYYY, getDateFormat } from '../../../utlity/utility';

@Injectable({
  providedIn: 'root',
})
export class SellerReportsService {
  logisticsUrl: string = `${environment.logisticsUrl}`;
  messagingService: string = `${environment.messagingService}`;
  coreServiceUrl: string = `${environment.coreServiceUrl}`;
  // coreServiceUrl:string = "http://localhost:4201"

  constructor(public http: HttpClient) { }

  async downloadRegisterReports(body: any): Promise<any> {
    const url: string = `${this.coreServiceUrl}/api/download/report`;

    try {
      const response = await lastValueFrom(
        this.http.post(url, body, { responseType: 'blob' })
      );
      console.log('response is the ', response);

      if (
        response.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        const blob = new Blob([response], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        const keys: any[] = Object.values(body);
        let reportName = this.getEnumNameByValue(DownloadType, keys[0]);
        const reportType = keys[0];
        let formatType;
        let formatName = '';
        let date = new Date();
        let formattedDate = getDateDDMMYYYY(date);
        if (keys[1] && typeof keys[1] === 'object' && 'formatType' in keys[1]) {
          formatType = keys[1].formatType;
          formatName =
            this.getEnumNameByValue(PurchaseFormatType, formatType) || '';
        }
        if (reportType == 5) {
          link.download =
            reportName + (formatName + '_' + formattedDate || '') + '.xlsx';
        } else {
          link.download = reportName + '.xlsx';
        }
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        console.error('Unexpected response:', response);
      }
      return response;
    } catch (error) {
      console.error('Error downloading file:', error);
      throw error;
    }
  }

  async downloadMarginReports(body: any) {
    const url = `${this.coreServiceUrl}/api/download/report`;
    const response = await lastValueFrom(this.http.post(url, body, { responseType: 'blob' }));
    return response
  }
  getEnumNameByValue(enumObj: any, value: number): string | undefined {
    return Object.keys(enumObj).find((key) => enumObj[key] === value);
  }
}
