import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ProductService } from '../../../pages/product/services/product.service';
import { FormsModule } from '@angular/forms';
import { CommonService } from '../../../services/common.service';
import { CategoryService } from '../../../pages/category/services/category.service';
import { CompanyService } from '../../../pages/company/services/company.service';
import { AddProductComponent } from './add-product/add-product.component';
import { AddAttributeComponent } from './add-attribute/add-attribute.component';
import { AddVariantValueComponent } from './add-variant-value/add-variant-value.component';
import { AddVariantTypeComponent } from './add-variant-type/add-variant-type.component';
import { AddCompanyComponent } from './add-company/add-company.component';
import { AddCategoryComponent } from './add-category/add-category.component';
import { AddBrandComponent } from './add-brand/add-brand.component';
import { ViewSkuComponent } from './view-sku/view-sku.component';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pending-approval-details',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule, MatInputModule,
    FormsModule,
    AddProductComponent,
    AddAttributeComponent,
    AddVariantValueComponent,
    AddVariantTypeComponent,
    AddCategoryComponent,
    AddCompanyComponent,
    AddBrandComponent,
    ViewSkuComponent
  ],
  templateUrl: './pending-approval-details.component.html',
  styleUrl: './pending-approval-details.component.scss'
})
export class PendingApprovalDetailsComponent implements OnInit {
  type: any;
  comment:any=""
  constructor(
    public dialogRef: MatDialogRef<PendingApprovalDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public productService:ProductService,
    public categoryService:CategoryService,
    public companyService:CompanyService,
    public commonService: CommonService,
    public authService:AuthService,
    public router:Router
  ) { }

  ngOnInit() {
    console.log(this.data)
    this.type = this.data.type;
 
  }

  async onReject() {
    this.commonService.showLoder()
    try{
      this.data.admin_name = this.authService.userData.email_id
     await this.productService.rejectApprovalRequest(this.data,this.comment)
     this.dialogRef.close();
     this.commonService.showToaster("success",'Successfully Rejected')
     this.commonService.hideLoder()
    }
    catch(err:any){
      this.commonService.showToaster("error",err.error.message)
      this.commonService.hideLoder()
    }
  }

  async approve(){
    this.commonService.showLoder()
    try{
      this.data.admin_name = this.authService.userData.email_id
     await this.productService.approveRequest(this.data)
     this.dialogRef.close();
     this.commonService.showToaster("success",'Successfully Approved')
     this.commonService.hideLoder()
    }
    catch(err:any){
      this.commonService.showToaster("error",err.error.message)
      this.commonService.hideLoder()
    }
    
  }

  editAndApprove(){
    this.dialogRef.close();
    this.router.navigateByUrl("/dashboard/product/sku/draft-edit/"+this.data.id)
  }
}
