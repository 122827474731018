<form [formGroup]="filterForm">
  <div class="d-flex align-items-center justify-content-between" >
  <h1 mat-dialog-title>Filter </h1>
  <button mat-button (click)="close()" 
    class="mx-2"  mat-button color="warn">
    <mat-icon >close</mat-icon>
  </button></div>
  <div mat-dialog-content style="min-height: 280px;">
   <div class="container">
    <div class="row">
      <div class="col-md-6">
        <mat-form-field class="example-full-width">
          <mat-label>Search by Name</mat-label>
          <input matInput formControlName="name" />
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <ng-select [items]="categoryList"
        bindValue="id"
        bindLabel="attributes.name"
        placeholder="Search by category"
        formControlName="categories_id"
        appendTo="body"
     >
</ng-select> 
 
      </div>
      <div class="col-md-6">
        <mat-form-field class="example-full-width">
          <mat-label>Search by Type</mat-label>
          <mat-select formControlName="value_type">
            <mat-option [value]="'number'"> Number </mat-option>
            <mat-option [value]="'text'"> Text </mat-option>
            <mat-option [value]="'dropdown'"> Dropdown </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field class="example-full-width">
          <mat-label>Search by Group Name</mat-label>
          <mat-select formControlName="group_name">
            @for (item of groupName; track $index) {
              <mat-option [value]="item.value"> {{item.text}} </mat-option>
             }
           <!--  <mat-option [value]="null"> No Group </mat-option>
            <mat-option [value]="'group1'"> group1 </mat-option>
            <mat-option [value]="'group2'"> group2 </mat-option>
            <mat-option [value]="'group3'"> group3 </mat-option> -->
          </mat-select>
        </mat-form-field>
      </div>
     
    </div>
   </div>
    </div>
  <div mat-dialog-actions class="d-flex justify-content-end  ">
    <button mat-button (click)="reset()" 
    class="mx-2"  mat-raised-button color="warn">Reset</button>
    <button mat-button  cdkFocusInitial [disabled]="filterForm.invalid"
    (click)="submit()"
     mat-raised-button color="primary">Apply</button>
  </div>
  </form>