@if(hasView){
<section id="content" *ngIf="promotionDetails">
  <div class="container">
    <div class="card">
      <div class="heading-block">
        <div class="row">
          <div class="col-md-6">
            <mat-icon class="backicon" [routerLink]="['/dashboard/promotion']">arrow_back</mat-icon>
            <span class="header">
              <h2 class="card-title m-2">Promotion Details</h2>
            </span>
          </div>
          <div class="col-md-6 text-right"></div>
        </div>
      </div>
      <!-- promoImageUrl: string;
  categories: any;
  brands: any; -->
      <div class="card-body card-padding">
        <div class="row row-cols-2 mb-3">
          <div class="col-sm-6">
            <label class="heading">Promotion Type :</label>
            <label class="content">{{promotionTypeName}}</label>
          </div>
          <div class="col-sm-6">
            <label class="heading">Promotion Name :</label>
            <label class="content">{{promotionDetails.promoName}}</label>
          </div>
        </div>
        <div class="row row-cols-2 mb-3">
          <div class="col-sm-6">
            <label class="heading">Display Name :</label>
            <label class="content">{{promotionDetails.displayName}}</label>
          </div>
          <div class="col-sm-6">
            <label class="heading">Status :</label>
            <label class="content">{{status}}</label>
          </div>
        </div>
        <div class="row row-cols-2 mb-3">
          <div class="col-sm-6">
            <label class="heading">Vendor :</label>
            <label class="content">{{promotionDetails.vendorName}}</label>
          </div>

          <div class="col-sm-6">
            <label class="heading">Expiry Date :</label>
            <label class="content">{{promotionDetails.expiryDate | date }} </label>
          </div>
        </div>
        <div class="row row-cols-2 mb-3">
          <div class="col-sm-6">
            <label class="heading">Categories :</label>
            <label class="content">{{categories}} </label>
          </div>
          <div class="col-sm-6">
            <label class="heading">Brands :</label>
            <label class="content">{{brands}} </label>
          </div>

        </div>
        <div class="row row-cols-2 mb-3">
          <div class="col-sm-6">
            <label class="heading">Sequence :</label>
            <label class="content">{{promotionDetails.sequence}} </label>
          </div>
        </div>
        <div class="row row-cols-2 mb-3">
          <div class="col-sm-6">
            <label class="heading">App Url :</label>
            <label class="content">{{appUrlTypeName}} </label>
          </div>
        </div>
        <div class="row row-cols-2 mb-3">
          <div class="col-sm-6">
            <label class="heading">Web Url :</label>
            <label class="content">{{promotionDetails.webUrl}} </label>
          </div>
        </div>
        <div class="row">
          <label class="col-sm-3 heading">SKUs :</label>
          <div class="productIds" *ngIf="promotionDetails.skus">
            <mat-table [dataSource]="dataSource" matSort>
              <ng-container matColumnDef="skuId">
                <mat-header-cell *matHeaderCellDef mat-sort-header> SKU ID</mat-header-cell>
                <mat-cell *matCellDef="let row" data-label="skuId"> {{row.id}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="skuName">
                <mat-header-cell *matHeaderCellDef mat-sort-header> SKU Name</mat-header-cell>
                <mat-cell *matCellDef="let row" data-label="skuName"> {{row.name}} </mat-cell>
              </ng-container>
              <mat-header-row *matHeaderRowDef="displayedColumnsProduct"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumnsProduct;"></mat-row>
            </mat-table>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-11">
            @if(promotionDetails?.promoImageUrl){
              <label class="heading">Promo Image :</label>
            <img [src]="promotionDetails.promoImageUrl" style="max-width: 30rem;" />
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
}
