<div class="container">
  <div class="heading-block">
    <div class="row">
      <div class="col-md-6">
        <mat-icon class="backicon" (click)="goBack()">arrow_back</mat-icon>
      </div>
      <div class="col-md-6 text-right">

      </div>
    </div>
  </div>
  <div class="col-md-12 mt-3">
    <mat-tab-group>
      <mat-tab label="Summary">
        <app-hop-summary></app-hop-summary>
      </mat-tab>
      <mat-tab label="Order View">
        <app-order-view></app-order-view>
      </mat-tab>
      <mat-tab label="Product View">
        <app-product-view></app-product-view>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
<router-outlet></router-outlet>