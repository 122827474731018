import { Injectable } from '@angular/core';
import {
  DownloadEWaySheet,
  DownloadIndentSheet,
  HopItems,
  HopItemsResponse,
  Order,
  OrderModifyEntity,
  PlanningListSearch,
} from '../models/trip.model';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { convertToYYYYMMDD } from '../../../../utlity/utility';
import { TripOrderStatus, TripStatus } from '../../../../enums/enums';
import {
  DBTripInfo,
  MoveHopApiResponse,
  MoveHopResponse,
  MoveHopSummary,
} from '../../../../entities/trip-info';
import { AppStateService } from '../../../../services/appstate.service';
import { OrderModifyBulk, ValidateStockUpdate } from '../../../../entities/order-modify.model';
interface MessageQueueResponse {
  success: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class TripService {
  // baseServiceUrl: string = "http://localhost:4013";
  baseServiceUrl: string = `${environment.logisticsUrl}`;
  ordersServiceUrl: string = `${environment.ordersServiceUrl}`;
  coreServiceUrl: string = `${environment.coreServiceUrl}`;

  constructor(
    private readonly http: HttpClient,
    private appState: AppStateService
  ) {}

  async getTrips(tripSearchInput: PlanningListSearch): Promise<any> {
    const res = await lastValueFrom(
      this.http.get<any>(
        `${this.baseServiceUrl}/api/trips/filter/dates?startDate=${tripSearchInput.start_date}&endDate=${tripSearchInput.end_date}`
      )
    );
    return res;
  }

  async getTripById(id: string): Promise<any> {
    const res = await lastValueFrom(
      this.http.get<any>(`${this.baseServiceUrl}/api/trips/${id}`)
    );
    return res;
  }

  async getTripsFilters(body: PlanningListSearch): Promise<any> {
    const res = await lastValueFrom(
      this.http.post<any>(`${this.baseServiceUrl}/api/trips/filters`, body)
    );
    if (res) {
      return {
        tripsList: res.rows,
        totalTrips: res.total_count,
      };
    }
  }

  async generateEWayBill(body: any): Promise<any> {
    try {
      const response = await lastValueFrom(
        this.http.post(
          `${this.baseServiceUrl}/api/trips/generate_e_way_bill`,
          body
        )
      );
      return response;
    } catch (error) {}
  }

  async generateEInvoice(body: any): Promise<any> {
    try {
      const response = await lastValueFrom(
        this.http.post(
          `${this.baseServiceUrl}/api/trips/generate_e_invoice`,
          body
        )
      );
      return response;
    } catch (error) {}
  }

  async downloadIndentSheet(body: DownloadIndentSheet): Promise<void> {
    try {
      const response = await this.http
        .post(
          `${this.baseServiceUrl}/api/trips/admin/download_indent_sheet`,
          body,
          {
            responseType: 'blob',
            observe: 'response',
          }
        )
        .toPromise();

      if (
        response &&
        response.headers.get('Content-Type') === 'application/pdf'
      ) {
        const contentDisposition = response.headers.get('Content-Disposition');
        const filename = contentDisposition
          ? contentDisposition.split(';')[1].split('=')[1].trim()
          : 'indent_sheet.pdf';

        if (response.body) {
          const blob = new Blob([response.body], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        } else {
          console.error('Error downloading file: Response body is null');
        }
      } else {
        console.error('Error downloading file: Invalid response');
      }
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  }

  async downloadEWaySheet(body: DownloadEWaySheet): Promise<void> {
    try {
      const response = await this.http
        .post(`${this.baseServiceUrl}/api/trips/download_e_way_pdf`, body, {
          responseType: 'blob',
          observe: 'response',
        })
        .toPromise();

      if (
        response &&
        response.headers.get('Content-Type') === 'application/pdf'
      ) {
        const contentDisposition = response.headers.get('Content-Disposition');
        const filename = contentDisposition
          ? contentDisposition.split(';')[1].split('=')[1].trim()
          : `${body.type === 1 ? 'e-invoice_sheet' : 'e-way-bill_sheet'}.pdf`;

        if (response.body) {
          const blob = new Blob([response.body], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        } else {
          console.error('Error downloading file: Response body is null');
        }
      } else {
        console.error('Error downloading file: Invalid response');
      }
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  }

  async downloadRouteSheet(body: any): Promise<void> {
    try {
      const response = await this.http
        .post(
          `${this.baseServiceUrl}/api/trips/admin/download_route_sheet`,
          body,
          {
            responseType: 'blob',
            observe: 'response',
          }
        )
        .toPromise();

      if (
        response &&
        response.headers.get('Content-Type') === 'application/pdf'
      ) {
        const contentDisposition = response.headers.get('Content-Disposition');
        const filename = contentDisposition
          ? contentDisposition.split(';')[1].split('=')[1].trim()
          : 'route_sheet.pdf';

        if (response.body) {
          const blob = new Blob([response.body], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        } else {
          console.error('Error downloading file: Response body is null');
        }
      } else {
        console.error('Error downloading file: Invalid response');
      }
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  }

  getCurrentDateTime() {
    const currentDate = new Date();

    // Format date parts
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    // Format time parts
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');

    // Combine date and time parts
    const dateTimeString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return dateTimeString;
  }

  async downloadInvoices(tripId: string, tripName: string): Promise<void> {
    try {
      const url = `${this.ordersServiceUrl}/invoice/download_invoices_by_trip_id/${tripId}`;
      const response = await this.http
        .get(url, {
          responseType: 'blob',
          observe: 'response',
        })
        .toPromise();

      if (
        response &&
        response.headers.get('Content-Type') === 'application/pdf'
      ) {
        const contentDisposition = response.headers.get('Content-Disposition');
        console.log('contentDisposition :---------', contentDisposition);
        const date = this.getCurrentDateTime();
        console.log('contentDisposition :---------', date);

        const filename = contentDisposition
          ? contentDisposition.split(';')[1].split('=')[1].trim()
          : `${tripName}-invoices-${date}.pdf`;

        if (response.body) {
          const blob = new Blob([response.body], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        } else {
          console.error('Error downloading file: Response body is null');
        }
      } else {
        console.error('Error downloading file: Invalid response');
      }
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  }

  async getTripHope(id: string) {
    const res = await lastValueFrom(
      this.http.get<any>(`${this.baseServiceUrl}/api/trips/${id}/hops`)
    );
    res.sort((a: any, b: any) => +a.stop_number - +b.stop_number);
    return res;
  }

  async getHopDetails(hop_id: string) {
    const res = await lastValueFrom(
      this.http.get<any>(`${this.baseServiceUrl}/api/hops/${hop_id}`)
    );
    return res;
  }
  async getHopItems(hop_id: string) {
    const response: HopItemsResponse[] = await lastValueFrom(
      this.http.get<any>(`${this.baseServiceUrl}/api/hops/${hop_id}/hop_items`)
    );
    const res = this.mapToHopItems(response);
    return res;
  }

  async getMoveHopTrips(date: string): Promise<MoveHopResponse[]> {
    try {
      const response = await lastValueFrom(
        this.http.get<MoveHopApiResponse>(
          `${this.baseServiceUrl}/api/trips/move_hop/trips?date=${date}`
        )
      );

      return this.mapMoveTrips(response.data);
    } catch (error) {
      console.log(' Trip Service GetTripsNameAndIds Error ::: ', error);

      return [];
    }
  }

  private mapMoveTrips(response: MoveHopSummary[]): MoveHopResponse[] {
    return response.map((item: any) => ({
      tripId: item.id,
      tripName: item?.cluster + '_' + item?.sequence || '',
    }));
  }

  async moveHop(tripId: string, hopId: any[]) {
    const response = await lastValueFrom(
      this.http.put<any>(
        `${this.baseServiceUrl}/api/hops/${hopId}/move/${tripId}`,
        null
      )
    );
    return response;
  }
  async moveHopBulk(body: any) {
    const response = await lastValueFrom(
      this.http.put<any>(`${this.baseServiceUrl}/api/hops/move/bulk`, body)
    );
    return response;
  }

  async deleteHop(hopId: string) {
    const response = await lastValueFrom(
      this.http.delete<any>(`${this.baseServiceUrl}/api/hops/${hopId}`)
    );
    return response;
  }

  async completeHop(status: number, hopId: string) {
    const body = {
      status: status,
    };
    const response = await lastValueFrom(
      this.http.put<any>(
        `${this.baseServiceUrl}/api/hops/${hopId}/status`,
        body
      )
    );
    return response;
  }

  async orderModifyBulk(payload: OrderModifyBulk[]) {
    try {
      const response = await lastValueFrom(
        this.http.post<any>(
          `${this.ordersServiceUrl}/orders/bulk_orders_modify`,
          payload
        )
      );

      return response;
    } catch (error) {
      throw error;
    }
  }

  async inventoryUpdateCheck(payload: ValidateStockUpdate) {
    try {
      const response = await lastValueFrom(
        this.http.post<any>(
          `${this.coreServiceUrl}/api/stock/validate_update`,
          payload
        )
      );

      return response;
    } catch (error) {
      console.log('inventory check error ::::: ', error)
      return {
        inventoryQty: 0,
        tripsQty: 0,
        updateRequired: false,
      }
    }
  }

  async orderModify(orderId: string, body: OrderModifyEntity[]) {
    try {
      const response = await lastValueFrom(
        this.http.post<any>(
          `${this.ordersServiceUrl}/orders/${orderId}/items_modify`,
          body
        )
      );

      return response;
    } catch (error) {
      throw error;
    }
  }

  async updateHopItems(
    hopId: string,
    payload: {
      item_id: string;
      order_number: string;
      quantity: number;
      weight: number;
    }[]
  ) {
    try {
      const response = await lastValueFrom(
        this.http.put<any>(
          `${this.baseServiceUrl}/api/hops/${hopId}/hop_items`,
          payload
        )
      );
    } catch (error) {
      throw error;
    }
  }

  private mapToHopItems(response: HopItemsResponse[]): HopItems[] {
    return response.map((hopItem) => {
      const { pieces, cases } = this.getItemQtyInCasesPieces(
        hopItem.upc,
        hopItem.quantity
      );

      const hopOrderStatus = {
        [TripOrderStatus.OrderPlaced]: 'Order Placed',
        [TripOrderStatus.OrderPreparation]: 'Order Preparation',
        [TripOrderStatus.OrderConfirmed]: 'Order Confirmed',
        [TripOrderStatus.ReadyForPickup]: 'Ready For Pickup',
        [TripOrderStatus.OrderInvoiced]: 'Order Invoiced',
        [TripOrderStatus.ReadyForShipment]: 'Ready For Shipment',
        [TripOrderStatus.OutForDelivery]: 'Out For Delivery',
        [TripOrderStatus.Delivered]: 'Delivered',
        [TripOrderStatus.Cancelled]: 'Cancelled',
        [TripOrderStatus.None]: 'None',
      };
      const key = `${hopItem.item_id}***${hopItem.vendor_id}`;
      return {
        id: hopItem.id,
        itemId: hopItem.item_id,
        itemName: hopItem.item_name,
        imageUrl: hopItem.item_image,
        upc: hopItem.upc,
        mrp: hopItem.maximum_retail_price,
        sellingPrice: +hopItem.selling_price,
        orderNumber: hopItem.order_number,
        vendorId: hopItem.vendor_id,
        customerId: hopItem.customer_id,
        quantity: +hopItem.quantity,
        weight: +hopItem.weight,
        unitWeight: +hopItem.weight / +hopItem.quantity,
        pieces: Math.round(pieces),
        cases: Math.round(cases),
        createdTimeStamp: Date.parse(hopItem.created_at),
        createdDate: convertToYYYYMMDD(hopItem.created_at),
        updatedDate: convertToYYYYMMDD(hopItem.updated_at),
        orderStatusLabel:
          hopOrderStatus[+hopItem.order_status_id as TripOrderStatus],
        orderStatusId: +hopItem.order_status_id,
        transitMode: +hopItem.transit_mode,
        fulfillmentType: +hopItem.fulfilment_type,
        returnQty: 0,
        qtyAfterEdit: 0,
        modifiedQty: 0,
        vendorName: hopItem.vendor_name,
        vendorBusinessName: hopItem.vendor_business_name,
        orderDate: hopItem.order_date,
        itemKey: key,
        vendorBusinessTypeId: +hopItem.vendor_business_type_id,
        orderItemDiscountAmount: +hopItem.coupon_discount_amount || 0,
        orderCouponDiscountAmount: +hopItem.order_coupon_discount_amount || 0,
      };
    });
  }

  getItemQtyInCasesPieces(upc: number, orderQty: number) {
    if (+upc === 0) {
      return { pieces: orderQty, cases: 0 };
    }
    const roundedUpc = Math.round(upc);
    const pieces = orderQty % roundedUpc;
    const cases = Math.floor(orderQty / roundedUpc);

    return { pieces, cases };
  }

  async decrementQtyByOrderModify(
    skuId: string,
    vendorId: string,
    qty: number
  ) {
    try {
      const payload = [
        {
          sku_id: skuId,
          vendor_id: vendorId,
          qty: qty,
        },
      ];
      const response = await lastValueFrom(
        this.http.post(
          `${environment.inventoryUrl}/api/inventory/decrement/stock`,
          payload
        )
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  // TODO need to move to common Inventory Service
  // Only use for Inventory Vendor not useful for other vendor
  async updateInventory(skuId: string, vendorId: string, qty: number) {
    try {
      const payload = {
        sku_id: skuId,
        vendor_id: vendorId,
        quantity: qty,
      };
      const response = await lastValueFrom(
        this.http.post(
          `${environment.inventoryUrl}/api/inventory/insert`,
          payload
        )
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async postMessageToMessageQueue(
    messageType: string,
    body: any // Use any if the structure of body can be varied, or define a specific interface if possible
  ): Promise<boolean> {
    try {
      const messageQueueUrl = `${environment.messagingService}/messagequeue/post`;
      const response: MessageQueueResponse = await lastValueFrom(
        this.http.post<MessageQueueResponse>(messageQueueUrl, {
          messageType: messageType,
          input: JSON.stringify(body),
        })
      );

      return response.success;
    } catch (error) {
      throw error;
    }
  }

  async tripOptimization(body: any): Promise<any> {
    const res = await lastValueFrom(
      this.http.post<any>(
        `${this.baseServiceUrl}/api/hops/update_trip_hop_numbers`,
        body
      )
    );
    return res;
  }
}
