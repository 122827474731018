import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { PromotionsService } from '../../promotion-kpi-trendingsku/services/promotions.service';
import { InvoiceType, OrderStatus, PromotionType } from '../../../enums/enums';
import Swal from 'sweetalert2';
import { OrderService } from '../../../services/orderService.service';
import { getEnumNameByValue, isEmptyValue } from '../../../utlity/utility';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { AuthService } from '../../../services/auth.service';
import { PageId } from '../../../constants/enums';
import { MatMenuModule } from '@angular/material/menu';
import { DownloadInvoice, OrderDetailsEntity } from '../model/model-entity';
import { ReplaceCommasPipe } from '../../../shared/pipes/currency-pipe';

@Component({
  selector: 'app-order-details',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    RouterLink,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatTableModule,
    MatMenuModule,
    ReplaceCommasPipe,
  ],
  templateUrl: './order-details.component.html',
  styleUrl: './order-details.component.scss',
})
export class OrderDetailsComponent extends BaseListComponent {
  orderDetails?: any;
  orderCustomerDetails?: any;
  orderMerchantDetails?: any;
  orderProducts?: any;
  orderStatus?: string = '';
  couponDetails?: any;
  dataSource!: MatTableDataSource<any>;
  taxSlabs: any;
  displayedColumnsProduct: string[] = [
    'view',
    'description',
    'mrp',
    'quantity',
    'perPcsRate',
    'saleAmount',
  ];
  displayedColumnsTax: string[] = [
    'gstTaxSlab',
    'taxableAmount',
    'cgst',
    'sgst',
    'cess',
    'tcs/tds',
    'totalBillAmount',
  ];
  displayedColumnsCollection: string[] = [
    'saleAmount',
    'returnValue',
    'cash',
    'bnpl',
    'digitalPayment',
    'balance',
  ];
  displayedColumnsSettlemet: string[] = [
    'baseAmount',
    'totalTax',
    'saleAmount',
    'couponAmount',
    'deliveryFee',
    'qwipoCommission',
    'tcs',
    'tds',
    'expectedReceivable',
  ];
  actionOptions: string[] = [
    'Download Invoice',
    // 'Whole Order Return',
    // 'Cash Entry',
    // 'Delivered',
    'Cancel',
  ];
  fromComponent!: string;
  orderNumber: string = '';
  constructor(
    public orderService: OrderService,
    route: ActivatedRoute,
    router: Router,
    auth: AuthService
  ) {
    super(auth, router, route, PageId.bms_common);
  }

  override async ngOnInit() {
    await super.ngOnInit();
    let orderNumber = '';
    this.route.paramMap.subscribe(async (params) => {
      const orderDetails = history.state.orderDetails;
      this.fromComponent = history.state.from || 'unknown';
      orderNumber = orderDetails?.orderNumber;
    });
    if (!isEmptyValue(orderNumber)) {
      await this.getOrderDetails(orderNumber);
      this.orderNumber = orderNumber
    }
  }

  getActionOptions(): string[] {
    const cancelStatuses = [
      OrderStatus.OrderPlaced,
      OrderStatus.QuotationRequested,
      OrderStatus.OrderConfirmed,
      OrderStatus.ReadyForPickup,
    ];

    const invoiceStatuses = [
      OrderStatus.OrderInvoiced,
      OrderStatus.ReadyForShipment,
      OrderStatus.OutforDelivery,
      OrderStatus.Delivered,
      OrderStatus.NotDelivered,
    ];

    const creditNoteStatuses = [
      OrderStatus.OrderInvoiced,
      OrderStatus.OutforDelivery,
      OrderStatus.Delivered,
    ];

    if (this.orderDetails?.logistics_status_id !== 4 && cancelStatuses.includes(this.orderDetails.order_status_id)) {
      return ['Cancel'];
    }

    if (creditNoteStatuses.includes(this.orderDetails.order_status_id)) {
      if (this.orderDetails?.order_items?.length > 0) {
        return ['Download Invoice', 'Credit Note'];
      }
    }

    if (invoiceStatuses.includes(this.orderDetails.order_status_id)) {
      return ['Download Invoice'];
    }

    return [];
  }

  async getOrderDetails(orderNumber: string) {
    try {
      const orderDetails: OrderDetailsEntity =
        await this.orderService.orderDetails(orderNumber);
      this.orderDetails = orderDetails;

      this.orderCustomerDetails = this.orderDetails?.customer;
      this.orderMerchantDetails = this.orderDetails?.vendor;
      this.orderProducts = this.orderDetails?.order_items;
      this.couponDetails = this.orderDetails?.coupon;
      this.orderStatus =
        getEnumNameByValue(OrderStatus, orderDetails.order_status_id) || '';
      this.dataSource = new MatTableDataSource(this.orderDetails?.order_items);
      this.groupItem();
    } catch (error) {
      console.log('getOrderDetails:- ', error);
      Swal.fire('', 'Some Thing went Wrong.', 'error');
    }
  }

  goBack() {
    if (this.fromComponent === 'vendor-orders') {
      this.router.navigate(['/dashboard/order/vendor-orders']);
    } else if (this.fromComponent === 'order-details') {
      this.router.navigate(['/dashboard/orders-list']);
    } else {
      this.router.navigate(['/']);
    }
  }

  async onActionOptionClick(optionName: string) {
    if (optionName === 'Download Invoice') {
      const filename = 'invoice.pdf';
      await this.downloadInvoice(
        InvoiceType.SaleInvoice,
        this.orderDetails.order_number,
        filename
      );
      Swal.fire({
        title: 'Downloaded Invoice Successfully',
        text: '',
        icon: 'success',
        allowOutsideClick: false,
      });
    }
    if (optionName === 'Cancel') {
      const result = await Swal.fire({
        title: 'Do you want to Cancel Order?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      });

      if (!result.isConfirmed) {
        return;
      }
      const res = await this.updateOrderStatus(this.orderDetails.order_number);
      console.log("res::", res);
      if (res) {

        Swal.fire({
          title: 'Order cancelled Successfully',
          text: '',
          icon: 'success',
          allowOutsideClick: false,
        });
        await this.getOrderDetails(this.orderNumber);

      }

    }
    if (optionName === 'Credit Note') {
      const filename = 'creditNote.pdf';
      console.log("creditNote");
      await this.downloadInvoice(
        InvoiceType.CreditNote,
        this.orderDetails.order_number,
        filename
      );
      Swal.fire({
        title: 'Downloaded Credit Note Successfully',
        text: '',
        icon: 'success',
        allowOutsideClick: false,
      });
    }
  }
  async groupItem() {
    const gstTaxSlabGroups: { [key: string]: any[] } = {};
    const gstTaxSlabItems: any[] = [];
    this.orderProducts.forEach((item: any) => {
      const cgstPercentage = item.cgst_percentage * 2;
      if (!gstTaxSlabGroups[cgstPercentage]) {
        gstTaxSlabGroups[cgstPercentage] = [];
      }
      gstTaxSlabGroups[cgstPercentage].push(item);
    });

    Object.entries(gstTaxSlabGroups).forEach(([cgstPercentage, itemList]) => {
      const taxableAmount = itemList.reduce(
        (total, item) => total + item.base_amount,
        0
      );
      const cgstTotal = itemList.reduce((total, item) => total + item.cgst, 0);
      const sgstTotal = itemList.reduce((total, item) => total + item.sgst, 0);
      const cessTotal = itemList.reduce((total, item) => total + item.cess, 0);
      const totalBillAmount = taxableAmount + cgstTotal + sgstTotal + cessTotal;

      gstTaxSlabItems.push({
        gstTaxSlab: `${cgstPercentage}%`,
        taxableAmount,
        cgst: cgstTotal,
        sgst: sgstTotal,
        cess: cessTotal,
        tcsTds: 0,
        totalBillAmount,
      });
    });
    this.taxSlabs = gstTaxSlabItems;
  }
  async downloadInvoice(type: any, orderNumber: string, filename: string) {
    const body = {
      type: type,
      order_numbers: [orderNumber],
    };
    console.log("body", body);

    await this.orderService.downloadInvoice(body, filename);
  }

  async updateOrderStatus(orderNumber: string) {
    const body = {
      status: OrderStatus.Cancelled,
    };
    return await this.orderService.updateOrderStatus(orderNumber, body);
  }

  getOrderStatus(value: number): string {
    switch (value) {
      case OrderStatus.OrderPlaced:
        return 'Order Placed';
      case OrderStatus.OrderInvoiced:
        return 'Order Invoiced';
      case OrderStatus.ReadyForShipment:
        return 'Ready For Shipment';
      case OrderStatus.OutforDelivery:
        return 'Out For Delivery';
      case OrderStatus.Delivered:
        return 'Delivered';
      case OrderStatus.Cancelled:
        return 'Cancelled';
      //Todo add remaining order statuses
      default:
        return 'Unknown Type';
    }
  }
}
