import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-seller-reports',
  standalone: true,
  imports: [ RouterOutlet],
  templateUrl: './seller-reports.component.html',
  styleUrl: './seller-reports.component.scss'
})
export class SellerReportsComponent {

}
