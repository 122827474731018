import { Component, Inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-pending-approvals-filter-dialog',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIconModule
  ],
  templateUrl: './pending-approvals-filter-dialog.component.html',
  styleUrl: './pending-approvals-filter-dialog.component.scss'
})
export class PendingApprovalsFilterDialogComponent {
  filterForm:any;
  statusList:any=[
    {
    name:'Pending Approval', id:'pending-approval'
  },
  {
    name:'Rejected', id:'rejected'
  },
  {
    name:'Approved', id:'approved'
  },
]
  constructor(
    public dialogRef: MatDialogRef<PendingApprovalsFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public router:Router,public route:ActivatedRoute,
    public fb:FormBuilder
  ) {
    this.filterForm = this.fb.group({
      name: [null],
      type: [null],
      status: [null],
      vendor_name:[null]
    })
  }
  ngOnInit(): void {
    this.filterForm.patchValue(this.data);
    //this.getAll()
  }
  close(){
    this.dialogRef.close()
  }
  reset(){
    this.filterForm.patchValue(
      {
        name: null,
        type: null,
        status: null,
        vendor_name:null
      }
    )
    this.navigateWithParams(this.filterForm.value)
    this.dialogRef.close(this.filterForm.value)
  }
  submit(){
    let data =   {
    name: this.filterForm.value.name,
    type: this.filterForm.value.type,
    status: this.filterForm.value.status,
    vendor_name:this.filterForm.value.vendor_name
    }
    this.navigateWithParams(data)
    this.dialogRef.close(data)
  }
  navigateWithParams(queryParams: any) {
    Object.keys(queryParams).forEach(key=>{
      if(!queryParams[key] || queryParams[key]=='null'){
        delete queryParams[key]
      }
    })
    // Navigate to the current route with the specified query parameters
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams
    });
  }
}
