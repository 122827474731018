import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { PromotionEntity, PromotionListSearch, createPromotionResponse } from '../models/promotions.model';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PromotionsService {
  baseServiceUrl: string = `${environment.logisticsUrl}`;
  coreServiceUrl: string = `${environment.coreServiceUrl}`;
  eSApiUrl: string = `${environment.eSApiUrl}`;

  constructor(
    private readonly http: HttpClient) {
  }


  async createPromotion(data: PromotionEntity): Promise<boolean> {
    try {
      let response = await lastValueFrom(this.http.post<createPromotionResponse>(`${this.coreServiceUrl}/api/promotion/create`, data));
      return response?.success || false
    } catch (error) {
      return false;
    }
  }

  async getPromotions(body: PromotionListSearch): Promise<any> {
    const res = await lastValueFrom(this.http.post<any>(`${this.coreServiceUrl}/api/promotion/get_by_filters`, body));
    if (res && res.data) {
      return {
        promotionList: res.data.rows,
        totalPromotions: res.data.count
      }
    }

  }

  async getPromotionById(id: string): Promise<any> {
    const res = await lastValueFrom(this.http.get<any>(`${this.coreServiceUrl}/api/promotion/get_by_id/${id}`));
    return res;
  }

  async updatePromotion(promotionEntity: PromotionEntity, promotionid: string) {
    let { id, ...promotionUpdatedEntity } = promotionEntity;
    let response = await lastValueFrom(this.http.post<{ res: createPromotionResponse }>(
      `${this.coreServiceUrl}/api/promotion/update/${promotionid}`,
      promotionUpdatedEntity
    ));
    return response.res
  }

  async deletePromotion(id: string) {
    return await lastValueFrom(this.http.delete(`${this.coreServiceUrl}/api/promotion/delete/${id}`));
  }
  async uploadPromotionImageDocs(filename: string, file: any, imageType: string) {
    try {
      const fullUrl = `${environment.fileUploadBaseUrl}/images/promotions`;
      const payLoad: any = {
        "fileName": filename,
        "fileExtension": imageType,
        "fileData": file
      }

      return await this.http.put(fullUrl, payLoad, undefined).toPromise();
    } catch (error) {
      throw error;
    }
  }
  async changePromotionStatus(promotionEntity: PromotionEntity) {
    let { id, ...promotionUpdatedEntity } = promotionEntity;
    let response = await lastValueFrom(this.http.put<{ res: createPromotionResponse }>(
      `${this.coreServiceUrl}/api/promotion/change_status/${promotionEntity.id}`,
      { status: promotionEntity.status }
    ));
    return response.res
  }
  async getCategoriesByVendor(vendorId: string,searchText: string,categoryLevel: string): Promise<any> {
    let body={
      "vendorId": vendorId,
      "searchText": searchText,
      "categoryLevel": categoryLevel
    }
    return await lastValueFrom(this.http.post<any>(`${this.eSApiUrl}/vendor_skus/category_search`, body));
  }
  async getBrandByVendor(vendorId: string,searchText: string): Promise<any> {
    let body={
      "vendorId": vendorId,
      "searchText": searchText
    }
    return await lastValueFrom(this.http.post<any>(`${this.eSApiUrl}/vendor_skus/brand_search`, body));
  }

  async getInventoryVendor(): Promise<any[]> {
    const input = {
        id: "",
        name: "",
        mobileNumber: "",
        city: "",
        businessType: 5,
        pageNumber: 1,
        pageSize: 10
    };
    const res = await lastValueFrom(this.http.post<any>(`${this.coreServiceUrl}/api/vendor/get_by_filters`, input));
    if (res && res.data) {
        const result = [{
            id: res.data.rows[0].id,
            value: res.data.rows[0].name,
        }];
        return result;
    }
    return [];
}




}
