import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import { IShippingData } from '../../services/shipping-model';
import { getCategories, getEnumKeyValueList, isEmptyValue } from '../../../../../utlity/utility';
import { CalculationType, ChargeBase, ShippingChargeType } from '../../../../../enums/enums';
import { CategoryService } from '../../../../category/services/category.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-shipping-popup',
  standalone: true,
  imports: [MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    RouterLink,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    MatTableModule,
    MatSidenavModule,
    MatPaginatorModule,
    CommonModule],
  templateUrl: './add-shipping-popup.component.html',
  styleUrl: './add-shipping-popup.component.scss'
})
export class AddShippingPopupComponent {
  dataSource: any;
  categoryList: Array<any> = [];
  calculationTypes: Array<any> = [];
  chargeBaseList: Array<any> = [];
  constructor(public dialogRef: MatDialogRef<AddShippingPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IShippingData,
    private fb: FormBuilder, public categoryService: CategoryService) {
    const queryObj = { level: 1, sort_by: "display_order", sort_order: "asc" };
    this.categoryService.getAll(queryObj).subscribe((res: any) => {
      this.categoryList = res.data
    })
    this.chargeBaseList = getEnumKeyValueList(ChargeBase);
    this.calculationTypes = getEnumKeyValueList(ShippingChargeType);
    this.dataSource = this.fb.group({
      category: [null, [Validators.required]],
      chargeBase: [null, [Validators.required]],
      chargeType: [null, [Validators.required]],
      chargeValue: [null, [Validators.required]],
    });

  }
  addShipping() {
    const category = this.dataSource.get("category")?.value || "";
    const chargeBase = this.dataSource.get("chargeBase")?.value || "";
    const chargeType = this.dataSource.get("chargeType")?.value || "";
    const chargeValue = this.dataSource.get("chargeValue")?.value || "";
    if (isNaN(chargeValue) || Number(chargeValue) < 0) {
      Swal.fire('', "Charge Value Should not be negative !", 'warning');
      return;
    }
    if (isEmptyValue(category) || isEmptyValue(chargeBase) || isEmptyValue(chargeType) || isEmptyValue(chargeValue)) {
      Swal.fire('', "Please fill all mandatory fields", 'warning');
      return;
    }
    const shippingObj = { category: category, chargeBase: chargeBase, chargeType: chargeType, chargeValue: chargeValue };
    const found = this.data.savedShippings.find((obj) => {
      return obj.category === category;
    });
    if (found) {
      this.clearShippingData();
      var message = "This shipping charge is already added for the " + found.categoryName + " category";
      Swal.fire('', message, 'warning');
      return;
    }
    this.dialogRef.close({
      popupResult: shippingObj,
    });
    this.clearShippingData();
  }

  clearShippingData() {
    this.dataSource.get('category')?.reset();
    this.dataSource.get('chargeBase')?.reset();
    this.dataSource.get('chargeType')?.reset();
    this.dataSource.get('chargeValue')?.reset();
  }
}
