import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Swal from "sweetalert2";
import * as XLSX from 'xlsx';
import { ExcelExportService } from '../../../../services/excel.service';
import { isEmptyValue } from '../../../../utlity/utility';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink, RouterOutlet } from '@angular/router';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';


@Component({
  selector: 'app-upload-customers-dialog',
  templateUrl: './upload-customers-dialog.component.html',
  styleUrls: ['./upload-customers-dialog.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatButtonModule,
    RouterOutlet,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatCardModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    CommonModule,
    MatTableModule,
    RouterLink,
    ReactiveFormsModule,
    MatIconModule
  ],
})
export class UploadCustomersDialogComponent {
  customerFile: File | null = null;
  customerRowsWithError: any[] = [];
  customersFromExcel: any[] = [];
  customerEntities: any[] = [];
  customerErrorMessage: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>,
    private excelService: ExcelExportService
  ) {
    this.customersFromExcel = data.control.value;
  }

  onFileSelect(event: any): void {
    this.customerFile = event.target.files[0] || null;
    this.convertCustomerExcel();
  }
  async convertCustomerExcel(): Promise<void> {

    if (!this.customerFile) {
      Swal.fire("Warning!", "Please Upload Excel File.", "warning");
      return;
    }

    this.resetCustomerData();

    try {
      const excelData = await this.excelService.readExcelData(this.customerFile);
      this.customersFromExcel = excelData.map((row) => {
        return {
          id: row['ID'],
          businessName: row['Business Name'],
          mobileNumber: isEmptyValue(row['Mobile Number']) ? '' : row['Mobile Number'].toString(),
        }
      });


      if (excelData.length <= 0) {
        Swal.fire('', 'Excel should not be empty', 'error')
        // this.commonService.swal('Excel should not be empty', 'error');
        return;
      }

      if (!this.validateCustomerColumns(this.customersFromExcel)) {
        Swal.fire("", "Customer Field should not be empty.", "warning");
        return;
      }

      const customersMobileNumber = this.customersFromExcel.map(customer => customer.mobileNumber.toLowerCase());
      const duplicateCustomer = this.findDuplicateCustomerInExcel(customersMobileNumber);

      if (duplicateCustomer.length > 0) {
        Swal.fire("Warning!", "Please remove the duplicate customerIds.", "warning");
        // this.commonService.swal('Please remove the duplicate customerIds.', 'error');
        this.customerErrorMessage = 'Please remove the following duplicate entries from Excel:';
        return;
      }

      this.customerEntities = this.customersFromExcel;
    } catch (error) {
      console.error('Error reading Excel data:', error);
      Swal.fire('', 'An error occurred while reading the Excel file.', 'error')
      // this.commonService.swal('An error occurred while reading the Excel file.', 'error');
    }
  }


  private resetCustomerData(): void {
    this.customerRowsWithError = [];
    this.customersFromExcel = [];
    this.customerEntities = [];
    this.customerErrorMessage = '';
  }

  private findDuplicateCustomerInExcel(itemList: string[]): string[] {
    const inputList = new Set<string>();
    const duplicates = new Set<string>();

    for (const item of itemList) {
      if (inputList.has(item)) {
        duplicates.add(item);
      } else {
        inputList.add(item);
      }
    }

    return Array.from(duplicates);
  }

  private validateCustomerColumns(customerList: any[]): boolean {
    this.customerErrorMessage = '';
    this.customerRowsWithError = [];

    for (const customer of customerList) {
      if (isEmptyValue(customer.id)) {
        this.customerRowsWithError.push(customer);
        Swal.fire("", "Customer Field should not be empty.", "warning");
        // this.customerErrorMessage = 'Fields should not be empty!\n';
        return false;
      }
    }

    return true;
  }

  setCustomers(): void {
    console.log("this.customersFromExcel :", this.customersFromExcel)
    if (this.customersFromExcel.length > 0) {
      this.dialogRef.close({ customersFromExcel: this.customersFromExcel });
    } else {
      this.dialogRef.close({ customersFromExcel: [] });
    }
  }

  cancelCustomers(): void {
    this.dialogRef.close({ customersFromExcel: [] });
  }
}
