@if(hasView){
<div class="container-fluid p-2">
  <mat-card class="p-3">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">
          <h3 class="title">Bulk Import</h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Request Type</th>
            <td mat-cell *matCellDef="let row">{{ row.name }}</td>
          </ng-container>

          <ng-container matColumnDef="download">
            <th mat-header-cell *matHeaderCellDef>Download</th>
            <td mat-cell *matCellDef="let row">
              <button mat-icon-button (click)="onDownload(row.id)">
                <mat-icon>cloud_download</mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="upload">
            <th mat-header-cell *matHeaderCellDef>Upload</th>
            <td mat-cell *matCellDef="let row">
              <button mat-icon-button (click)="openPopUp(row)">
                <mat-icon>cloud_upload</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </mat-card>
  <mat-card class="p-3">

    <div class="row">
      <div class="col-md-12">
        <table mat-table [dataSource]="bulkImportList" class="mat-elevation-z8">

          <ng-container matColumnDef="jobType">
            <th mat-header-cell *matHeaderCellDef>Job Type</th>
            <td mat-cell *matCellDef="let element">{{ element.jobType }}</td>
          </ng-container>

          <ng-container matColumnDef="fileName">
            <th mat-header-cell *matHeaderCellDef>File Name</th>
            <td mat-cell *matCellDef="let element">{{ element.fileName }}</td>
          </ng-container>

          <ng-container matColumnDef="userName">
            <th mat-header-cell *matHeaderCellDef> User Name</th>
            <td mat-cell *matCellDef="let element">{{ element.vendorEmail }}</td>
          </ng-container>

          <ng-container matColumnDef="creationDate">
            <th mat-header-cell *matHeaderCellDef>Creation Date</th>
            <td mat-cell *matCellDef="let element">{{ element.createdDate
              }}</td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element">{{ element.status }}</td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Uploaded File</th>
            <td mat-cell *matCellDef="let element"><button mat-icon-button
                (click)="downloadUploadedFile(element.fileUrl)">
                <mat-icon>cloud_download</mat-icon>
              </button></td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="listDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: listDisplayedColumns"></tr>
        </table>
      </div>
    </div>
  </mat-card>
</div>
} @else {
<ng-container>
  <p class="text-center">
    You don't have permission , Please contact administrator
  </p>
</ng-container>
}
