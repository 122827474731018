import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import {
  getActionFilters,
  getMainFilters,
  getSupplierNames,
  guid,
} from '../../../utlity/utility';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { LookUpTypeService } from '../../../services/lookup-type.service';
import { CommonModule } from '@angular/common';
import {
  ChargeType,
  DistributeCharges,
  ExpenseType,
  LookUpType,
  SwalMessageTypes,
} from '../../../enums/enums';
import { PurchaseReceiptService } from '../service/purchasereceipt.service';
import Swal from 'sweetalert2';
import { EditExpense, Expense } from '../models/purchasereceipt-model';

@Component({
  selector: 'app-edit-expenses',
  standalone: true,
  imports: [
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatCardModule,
    MatTableModule,
    FormsModule,
    MatOptionModule,
    RouterLink,
    CommonModule,
    ReactiveFormsModule,
  ],
  templateUrl: './edit-expenses.component.html',
  styleUrl: './edit-expenses.component.scss',
})
export class EditExpensesComponent {
  form: FormGroup;
  selectedExpensesType: number | undefined;
  supplier: any;
  prId: string = '';
  expenseId: string = '';
  selectedExpressTypeId: any;
  supplierNames: LookUpType = this.initializeSuppliers();
  public suppliersList: any[] = [];
  expenseType: number = 0;
  ExpenseType = ExpenseType;
  constructor(
    private router: Router,
    private lookupService: LookUpTypeService,
    public fb: FormBuilder,
    public route: ActivatedRoute,
    public purchaseReceiptService: PurchaseReceiptService
  ) {
    this.form = this.fb.group({
      expensesType: [null, Validators.required],
      supplierName: [null, Validators.required],
      tdsPercentage: [null],
      tdsValue: [null],
      rate: [null, Validators.required],
      chargeType: '',
      distributeCharge: [{ value: '', disabled: true }],
      discountPercent: [null],
    });
  }
  expenses: { name: string; value: ExpenseType }[] = [
    { name: 'Loading', value: ExpenseType.Loading },
    { name: ' Unloading', value: ExpenseType.Unloading },
    { name: 'Transport', value: ExpenseType.Transport },
    { name: ' Brokerage', value: ExpenseType.Brokerage },
    { name: 'Discount', value: ExpenseType.Discount },
    { name: 'Purchase Credit', value: ExpenseType.PurchaseCredit },
    { name: 'Loading By Buyer', value: ExpenseType.LoadingByBuyer },
  ];
  charges: { name: string; value: ChargeType }[] = [
    { name: 'Total Value', value: ChargeType.TotalValue },
    { name: ' RatePerKg', value: ChargeType.RatePerKg },
  ];
  distributeCharges: { name: string; value: DistributeCharges }[] = [
    { name: 'Quantity', value: DistributeCharges.Quantity },
    { name: ' Weight', value: DistributeCharges.Weight },
  ];

  async ngOnInit() {
    await this.getSuppliers();
    this.route.params.subscribe(async (params) => {
      this.prId = params['id'];
      this.expenseId = params['expenseId'];
    });
    await this.getPrExpenseDetails(this.expenseId);
    const distributeChargeControl = this.form.get(
      'distributeCharge'
    ) as FormControl;
    const chargeTypeControl = this.form.get('chargeType') as FormControl;
    const expensesTypeControl = this.form.get('expensesType') as FormControl;
    distributeChargeControl.disable();
    chargeTypeControl.disable();
    expensesTypeControl.disable();
  }

  async getPrExpenseDetails(id: string) {
    const res = await this.purchaseReceiptService.getExpenseDetails(id);
    this.expenseType = res.expense_type;
    console.log('expense details are ', res);
    this.mapToExpenseDetails(res);
  }

  mapToExpenseDetails(response: any) {
    this.form.patchValue({
      expensesType: response.expense_type,
      supplierName: this.getSupplierNameByID(response.supplier_id),
      tdsPercentage: response.tds_percent,
      tdsValue: response.tds_value,
      rate: response.total_value,
      chargeType: response.charge_type,
      distributeCharge: response.distribute_based_on,
      discountPercent: response.discount_percent,
    });
  }

  getSupplierNameByID(supplierId: string) {
    console.log('supplier id', supplierId);
    console.log('supplier list is', this.suppliersList);

    for (const supplier of this.suppliersList) {
      if (supplier.id == supplierId) {
        return supplier.value;
      }
    }
    return null;
  }
  async getSuppliers() {
    const response = await this.lookupService.getLookUpTypes(
      this.supplierNames
    );

    this.suppliersList = response.data[0].data;
  }
  async save() {
    if (!this.validations()) {
      return;
    }
    const expense = this.form.value;
    console.log('expense', expense);
    const expense_type = this.form.get('expensesType')?.value;
    const charge_type = this.form.get('chargeType')?.value;
    const distribute_charge = this.form.get('distributeCharge')?.value;
    const expenseDetails = this.mapToExpense(
      expense,
      expense_type,
      distribute_charge,
      charge_type
    );
    const res = await this.purchaseReceiptService.updateExpenseDetails(
      this.expenseId,
      expenseDetails
    );
    console.log('result', res);
    this.router.navigateByUrl(
      `/dashboard/pocreation/edit-purchase-order/${this.prId}`
    );
  }

  mapToExpense(
    expense: any,
    expense_type: number,
    charge_type: number,
    distribute_charge: number
  ) {
    return {
      expense_id: this.expenseId,
      expense_type: expense_type,
      total_value: expense.rate || 0,
      distribute_based_on: distribute_charge,
      charge_type: charge_type,
      tds_percent: expense.tdsPercentage,
      tds_value: expense.tdsValue,
      discount_percent: expense?.discountPercent || 0,
      payout: expense?.payout || 0,
    };
  }
  goBack() {
    this.router.navigateByUrl(
      `/dashboard/pocreation/edit-purchase-order/${this.prId}`
    );
  }

  private validations(): boolean {
    const negativeFields = this.validateNegativeValues(this.form.value);

    if (negativeFields.length) {
      this.showMessage(
        `${negativeFields} ,have negative values Please Check`,
        SwalMessageTypes.Warning
      );

      return false;
    }
    if (this.form.value.expensesType === ExpenseType.Discount) {
      if (
        this.form.value.rate == null &&
        this.form.value.discountPercent == null
      ) {
        this.showMessage(
          `Please give the either Rate or Discount percent`,
          SwalMessageTypes.Warning
        );
        return false;
      }
    } else if (this.form.value.rate <= 0) {
      this.showMessage(
        ` Rate should be greater than zero`,
        SwalMessageTypes.Warning
      );
      return false;
    }
    return true;
  }
  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  private validateNegativeValues(data: EditExpense): string[] {
    const numericInputs = [
      { name: 'Rate', value: data.rate },
      { name: 'Tds Value', value: data.tdsValue },
      { name: 'Tds Percentage', value: data.tdsPercentage },
      { name: 'Discount Percentage', value: data.discountPercent },
    ];

    const negativeFields = numericInputs
      .filter((input) => input.value < 0)
      .map((input) => input.name);

    return negativeFields;
  }
  private initializeSuppliers(): LookUpType {
    return {
      tableName: 'suppliers',
      lookupType: 'suppliers',
      idColumnName: 'Id',
      valueColumnName: 'Name',
    };
  }
}
