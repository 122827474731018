@if(hasView){
<ng-container>
  <div class="heading-block">
    <div class="row">
      <div class="col-md-6">
        <h2 class="card-title m-3">Vendor</h2>
      </div>
      <div class="col-md-6 text-right">

        <button mat-raised-button *ngIf="hasCreate" color="primary" [routerLink]="['/dashboard/merchant/create-merchant']"
          class="topButtonSectionbtn">
          Add Vendor
        </button>

      </div>
    </div>
  </div>
  <div class="search-block">
    <form [formGroup]="form">
      <section id="content">
        <div class="card">
          <div class="card-body card-padding">
            <div class="row">
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Vendor Id</mat-label>
                  <input matInput type="string" formControlName="id" name="id" placeholder="Merchant Id"  (input)="trimSpaces($event)"/>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Business Name</mat-label>
                  <input matInput type="string" formControlName="name" name="name" placeholder="Name" (input)="trimSpaces($event)" />
                </mat-form-field>
              </div>

              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Mobile Number</mat-label>
                  <input matInput type="number" formControlName="mobileNumber" name="mobileNumber"
                    placeholder="Mobile Number" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>City</mat-label>
                  <input matInput type="string" formControlName="city" name="city" placeholder="City"  (input)="trimSpaces($event)" />
                </mat-form-field>
              </div>

              <div class="col-md-6 mt-2 d-flex justify-content-end">
                <button mat-raised-button color="primary" class="ml" (click)="searchTasks()">
                  Search
                </button>
              </div>
              <div class="col-md-6 mt-2">

                <button mat-raised-button color="primary" class="ml-10" (click)="clearFilters()">
                  Clear Filters
                </button>
              </div>

            </div>
          </div>
        </div>
      </section>
    </form>
  </div>

  <div class="table-block">
    <div class="m-3 flex flex-col md:flex-row justify-between">
      <mat-card class="p-0">
        <mat-card-content class="p-0">
          <mat-sidenav-container fxFlex="0 1 auto">
            <mat-sidenav-content>
              <div class="table-container">
                <mat-table [dataSource]="dataSource" matSort>
                  <ng-container matColumnDef="businessName">
                    <mat-header-cell *matHeaderCellDef>
                      Business Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="businessName">
                      {{ row.business_name }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="mobileNumber">
                    <mat-header-cell *matHeaderCellDef>
                      Mobile Number
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="mobileNumber">
                      {{ row.mobile_number }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="businessType">
                    <mat-header-cell *matHeaderCellDef>
                      Business Type
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="businessType">
                      {{ getBusinessTypeName(row.business_type) }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="address">
                    <mat-header-cell *matHeaderCellDef>
                      D-No/Street
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="address">
                      {{ row.address }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="city">
                    <mat-header-cell *matHeaderCellDef> City </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="city">
                      {{ row.city }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="state">
                    <mat-header-cell *matHeaderCellDef> State </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="state">
                      {{ row.state }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef> Status</mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="status">
                      {{ row.status === 0 ? 'In-Active' : 'Active' }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="setting">
                    <mat-header-cell *matHeaderCellDef class="mat-column-actions">
                      Setting
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="mat-column-actions">
                      <div class="d-flex">
                        <button mat-icon-button *ngIf="hasEdit" (click)="goToSettings(row)">
                          <mat-icon>settings-applications</mat-icon>
                        </button>
                      </div>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="edit">
                    <mat-header-cell *matHeaderCellDef class="mat-column-actions">
                      Edit
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="mat-column-actions">
                      <div class="d-flex">
                        <button mat-icon-button *ngIf="hasEdit" (click)="editMerchant(row)">
                          <mat-icon>edit</mat-icon>
                        </button>
                      </div>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="geoMapping">
                    <mat-header-cell *matHeaderCellDef class="mat-column-actions">
                      Geo Mapping
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="mat-column-actions">
                      <div class="d-flex">
                        <button *ngIf="hasEdit" mat-icon-button (click)="addGeoMapping(row)">
                          <mat-icon>cloud_upload</mat-icon>
                        </button>
                      </div>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                </mat-table>
              </div>
            </mat-sidenav-content>
          </mat-sidenav-container>
          <mat-paginator [length]="totalMerchants" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="onPageChange($event)"></mat-paginator>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</ng-container>
} @else {
<ng-container>
  <p class="text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
}
