import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { Component, ViewChild } from '@angular/core';
import { PlanningEntity, PlanningListSearch } from '../../models/trip.model';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSidenavModule } from '@angular/material/sidenav';

import Swal from 'sweetalert2';
import { AppLoaderService } from '../../../../../shared/app-loader/app-loader.service';
import { AuthService } from '../../../../../services/auth.service';
import {
  DownloadPickupSheetType,
  SwalMessageTypes,
  TripSource,
  TripStatus,
} from '../../../../../enums/enums';
import { TripService } from '../../services/trip.service';
import { AppStateService } from '../../../../../services/appstate.service';
import { TripDataService } from '../../services/trip-data.service';
import {
  formatToCustomDate,
  getDateYYYYMMDD,
  showWarningMessage,
} from '../../../../../utlity/utility';
import { BaseListComponent } from '../../../../../shared/core/base.list.component';
import { PageId } from '../../../../../constants/enums';
import { DBTripInfo, MappedTrip } from '../../../../../entities/trip-info';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-trip-list',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    RouterLink,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatMenuModule,
  ],
  templateUrl: './trip-list.component.html',
  styleUrls: ['./trip-list.component.scss'],
})
export class TripListComponent extends BaseListComponent {
  tripStatus = Object.values(TripStatus).filter(
    (value) => typeof value === 'number' && value !== 99
  );
  plannings: PlanningEntity[] = [];
  readonly DEFAULT_PAGE_SIZE = 25;
  totalTrips: number = 0;
  form: FormGroup;
  planningListSearchInput: PlanningListSearch = this.initializeSearchInput();
  startDate?: string;
  endDate?: string;
  trips: any[] = [];

  displayedColumns = [
    'createdDate',
    'date',
    'tripName',
    'sourceName',
    'tripNumber',
    'hops',
    'weight',
    'deName',
    'deNumber',
    'kms',
    'status',
    'startTime',
    'endTime',
    'view',
  ];
  // generateOptions: string[] = ['Invoices', 'E - Invoices', 'E - Way Bill', 'Pickup Indent'];
  downloadOptions: string[] = [
    'Pickup Indent',
    'Route Sheet',
    'Trip List Download',
  ];
  @ViewChild('downloadOptionsMenu') downloadOptionsMenu!: MatMenuTrigger;
  @ViewChild('generateOptionsMenu') generateOptionsMenu!: MatMenuTrigger;
  dataSource: any;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    public readonly tripService: TripService,
    private loader: AppLoaderService,
    public tripDataService: TripDataService,
    private appState: AppStateService
  ) {
    super(auth, router, route, PageId.logistics_trips);
    this.form = this.fb.group({
      tripName: '',
      deNumber: '',
      status: '',
      deName: '',
      startDate: '',
      endDate: '',
    });
  }

  override async ngOnInit() {
    super.ngOnInit();
    await this.getTrips();
    this.startAndEndDates();
    this.initializeSearchInput();
  }

  startAndEndDates() {
    const currentDate = new Date();
    const beforeDay = new Date(currentDate);
    beforeDay.setDate(beforeDay.getDate() - 1);
    this.startDate = beforeDay.toISOString().slice(0, 10);
    const nextDay = new Date(currentDate);
    nextDay.setDate(nextDay.getDate());
    this.endDate = nextDay.toISOString().slice(0, 10);
  }

  async getTrips() {
    try {
      this.loader.open();
      const result = await this.tripService.getTripsFilters(
        this.planningListSearchInput
      );

      const mappedTrips = this.mapTrip(result.tripsList);
      if (mappedTrips.length > 0) {
        this.dataSource = mappedTrips.filter((c) => c.hops > 0);
        this.totalTrips = result['totalTrips'];
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loader.close();
      } else {
        this.loader.close();
        this.showMessage('No records found.', SwalMessageTypes.Warning);
        this.dataSource = [];
      }
    } catch (error) {
      this.loader.close();
      this.showMessage(
        'Failed to Fetch trips. Please try again later.',
        SwalMessageTypes.Warning
      );
    }
  }

  async pickupIndentSheet() {
    const tripIds = this.dataSource?.map((item: { id: any }) => item.id) || [];
    if (tripIds.length === 0) {
      Swal.fire('', 'No data found', 'warning');
      return;
    }
    const res = {
      type: DownloadPickupSheetType.Warehouse,
      tripIds: tripIds,
    };
    await this.tripService.downloadIndentSheet(res);
    Swal.fire('success', 'PickUp Sheet Downloaded Successfully', 'success');
  }

  async routeSheet() {
    const tripIds = this.dataSource?.map((item: { id: any }) => item.id) || [];
    if (tripIds.length === 0) {
      Swal.fire('', 'No data found', 'warning');
      return;
    }
    await this.tripService.downloadRouteSheet(tripIds);
    Swal.fire('success', 'Route Sheet Downloaded Successfully', 'success');
  }

  editTrip(row: MappedTrip) {
    this.appState.setItem('tripListFilters', this.planningListSearchInput);
    localStorage.setItem('tripDetails', JSON.stringify(row));
    this.tripDataService.setRowData(row);
    this.appState.setSelectedTrip(row);
    this.router.navigateByUrl(`/dashboard/logistics/trip-details/${row.id}`);
  }

  timeFormat(time: string | Date | null): string {
    if (!time) return '';
    const date = new Date(time);
    let hours = date.getHours() % 12 || 12;
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const period = date.getHours() < 12 ? 'AM' : 'PM';
    if (hours >= 12) {
      hours = hours - 12;
    }
    const formattedTime = `${hours}:${minutes} ${period}`;
    return formattedTime;
  }

  mapTrip(trips: DBTripInfo[]): any[] {
    const tripSource = {
      [TripSource.Qwipo]: 'Qwipo',
      [TripSource.Vendor]: 'Vendor',
      [TripSource.ThreePL]: 'ThreePL',
      [TripSource.None]: 'None',
    };

    return trips
      .map((trip) => {
        const deliveryCount = +trip.summary?.delivery?.hops_count || 0;
        const returnCount = +trip.summary?.return?.hops_count || 0;
        const pickupCount = +trip.summary?.pickup?.hops_count || 0;
        const hopsCount = deliveryCount > 1 ? deliveryCount : pickupCount;
        const formatWeight = (weight: number) =>
          weight ? Math.round(+weight * 100) / 100 : 0;
        const pickupWeight = formatWeight(trip.summary?.pickup?.total_weight);
        const deliveryWeight = formatWeight(
          trip.summary?.delivery?.total_weight
        );
        const targetDate = trip.target_date;
        const date = new Date(targetDate);
        const year = date.getUTCFullYear();
        const month = date.toLocaleString('default', {
          month: 'long',
          timeZone: 'UTC',
        });
        const day = date.getUTCDate();
        const formattedDate = `${month} ${day}, ${year}`;
        return {
          id: trip.id,
          date: formattedDate,
          createdDate: formatToCustomDate(trip.created_at) || 'undefined',
          tripName:
            (trip.cluster ? trip.cluster + '_' : '') + (trip.sequence || ''),
          tripNumber: trip.name,
          hops: hopsCount,
          weight: trip.total_weight,
          deName: trip.delivery_agent_name || 'Un-Assigned',
          deNumber: trip.delivery_agent_mobile_number || 'N/A',
          kms: 0,
          status: trip.status,
          source: trip.source,
          sourceName: tripSource[trip.source as TripSource],
          startTime: trip.start_date_time
            ? this.timeFormat(trip.start_date_time)
            : 'N/A',
          endTime: trip.end_date_time
            ? this.timeFormat(trip.end_date_time)
            : 'N/A',
          vehicleTonnage: trip.vehicle_tonnage || 0,
        };
      })
      .sort((a, b) => a.tripName.localeCompare(b.tripName));
  }

  openDownloadOptions() {
    this.downloadOptionsMenu.openMenu();
  }
  async onDownloadOptionClick(optionName: string) {
    if (optionName === 'Pickup Indent') {
      console.log('Download ' + optionName);
      await this.pickupIndentSheet();
    }
    if (optionName === 'Route Sheet') {
      console.log('Download ' + optionName);
      await this.routeSheet();
    }
    if (optionName == 'Trip List Download') {
      console.log('Download ' + optionName);
      this.generateExcel();
    }
  }

  private isSalesPersonResponseEmpty(data: any) {
    return Object.keys(data).every((key) => data[key].length === 0);
  }

  generateExcel() {
    let isDataNotEmpty = this.isSalesPersonResponseEmpty(this.dataSource);

    if (isDataNotEmpty) {
      this.showMessage('There is no data to downLoad', 'warning');
      return;
    }

    const data: any = {
      Trips: this.dataSource.map((item: any) => ({
        'Trip Created Date': item.createdDate,
        Date: item.date,
        'Trip Name': item.tripName,
        'Trip Source': item.sourceName,
        'Trip Number': item.tripNumber,
        Hops: item.hops,
        "Weight (kg's)": item.weight,
        'DE Name': item.deName,
        'DE Number': item.deNumber,
        KMS: item.kms,
        Status: this.getPlanningStatusText(item.status),
        'Start Time': item.startTime,
        'End Time': item.endTime,
      })),
    };

    const workbook = XLSX.utils.book_new();
    Object.keys(data).forEach((key) => {
      const sheet = XLSX.utils.json_to_sheet(data[key]);
      XLSX.utils.book_append_sheet(workbook, sheet, key);
    });

    XLSX.writeFile(workbook, 'Trips List.xlsx');
  }

  private initializeSearchInput(): PlanningListSearch {
    this.planningListSearchInput = this.appState.getItem<PlanningListSearch>(
      'tripListFilters'
    ) || {
      trip_status: null,
      agent_mobile: '',
      trip_name: '',
      start_date: getDateYYYYMMDD(new Date()) || '',
      end_date: getDateYYYYMMDD(new Date()) || '',
      page_number: 1,
      page_size: this.pageSize,
    };

    this.form = this.fb.group({
      tripName: this.planningListSearchInput.trip_name,
      deNumber: this.planningListSearchInput.agent_mobile,
      status: this.planningListSearchInput.trip_status,
      deName: '',
      startDate: this.planningListSearchInput.start_date,
      endDate: this.planningListSearchInput.end_date,
    });

    return this.planningListSearchInput;
  }

  override async onPageChange(event: any) {
    await super.onPageChange(event);
    const { startDate, endDate, deNumber, status, tripName } = this.form.value;
    this.planningListSearchInput = this.createPlanningListSearchInput(
      startDate,
      endDate,
      deNumber,
      status,
      tripName
    );
    await this.getTrips();
  }

  async searchTasks() {
    const { startDate, endDate, deNumber, status, tripName } = this.form.value;
    console.log('status:', status);
    this.pageNumber = 1;
    this.pageSize = this.DEFAULT_PAGE_SIZE;
    this.paginator.pageIndex = 0;

    if (startDate > endDate) {
      showWarningMessage('Please select valid Start Date and End Date');
      this.dataSource = [];
      return;
    }

    if (startDate || endDate || deNumber || status || tripName !== undefined) {
      this.planningListSearchInput = this.createPlanningListSearchInput(
        startDate,
        endDate,
        deNumber,
        status,
        tripName
      );
      await this.getTrips();
    }
  }

  private createPlanningListSearchInput(
    startDate: string,
    endDate: string,
    deNumber: string,
    status: number,
    tripName: string
  ): PlanningListSearch {
    const start = startDate || this.startDate || '';
    const end = endDate || this.endDate || '';
    // const firstDate = new Date(start);
    // firstDate.setDate(firstDate.getDate() + 1);
    // const secondDate = new Date(end);
    // secondDate.setDate(secondDate.getDate() + 1);
    // const formattedStart = firstDate.toISOString().slice(0, 10);
    // const formattedEnd = secondDate.toISOString().slice(0, 10);
    return {
      agent_mobile: deNumber || '',
      trip_status: status || null,
      trip_name: tripName || '',
      start_date: getDateYYYYMMDD(new Date(start)), //formattedStart,
      end_date: getDateYYYYMMDD(new Date(end)), //formattedEnd
      page_number: this.pageNumber,
      page_size: this.pageSize,
    };
  }

  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  async clearFilters() {
    this.searchForm();
    this.appState.clearAll();
    this.planningListSearchInput = this.initializeSearchInput();
    await this.getTrips();
  }

  private searchForm() {
    this.form = this.fb.group({
      tripName: '',
      deNumber: '',
      status: '',
      deName: '',
      startDate: '',
      endDate: '',
    });
  }

  getStatusName(status: number | string): string {
    return typeof status === 'number' ? TripStatus[status] : status;
  }

  getPlanningStatusText(value: number): string {
    // console.log("value::", value);
    switch (value) {
      case TripStatus.NotStarted:
        return 'Not Started';
      case TripStatus.InProgress:
        return 'In Progress';
      case TripStatus.Completed:
        return 'Completed';
      default:
        return 'Unknown Status';
    }
  }
}
