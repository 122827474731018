@if(hasCreate){
<ng-container>
  <div class="heading-block">
    <div class="row">
      <div class="col-md-6">
        <mat-icon class="backicon" [routerLink]="['/dashboard/incentives/incentives-list']">arrow_back</mat-icon>
        <span class="header">
          <h2 class="card-title m-3">Create Incentive</h2>
        </span>
      </div>
      <div class="col-md-6 text-right"></div>
    </div>
  </div>

  <div class="table-block">
    <form [formGroup]="dataSource">
      <section id="content">
        <div class="card">
          <div class="card-body card-padding">
            <div class="row">
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Incentive Name </mat-label>
                  <input matInput type="string" formControlName="incentiveSlabName" name="incentiveSlabName"
                    placeholder="Incentive Name" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Petrol Allowance</mat-label>
                  <input matInput type="number" formControlName="petrolAllowance" name="petrolAllowance"
                    placeholder="Petrol Allowance" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Visit Min Count</mat-label>
                  <input matInput type="number" formControlName="visitsCountMin" name="visitsCountMin"
                    placeholder="Visit Min Count" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Visit Max Count</mat-label>
                  <input matInput type="number" formControlName="visitsCountMax" name="visitsCountMax"
                    placeholder="Visit Max Count" />
                </mat-form-field>
              </div>

              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Day Min Revenue %</mat-label>
                  <input matInput type="number" formControlName="dayRevenuePercentMin" name="dayRevenuePercentMin"
                    placeholder="Day Min Revenue %" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Day Max Revenue %</mat-label>
                  <input matInput type="number" formControlName="dayRevenuePercentMax" name="dayRevenuePercentMax"
                    placeholder="Day Max Revenue %" />
                </mat-form-field>
              </div>

              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Task Min Revenue %</mat-label>
                  <input matInput type="number" formControlName="dayTasksPercentMin" name="dayTasksPercentMin"
                    placeholder="Task Min Revenue %" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Task Max Revenue %</mat-label>
                  <input matInput type="number" formControlName="dayTasksPercentMax" name="dayTasksPercentMax"
                    placeholder="Task Max Revenue %" />
                </mat-form-field>
              </div>

              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Buying Min Count %</mat-label>
                  <input matInput type="number" formControlName="buyingCountPercentMin" name="buyingCountPercentMin"
                    placeholder="Buying Min Count %" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Buying Max Count %</mat-label>
                  <input matInput type="number" formControlName="buyingCountPercentMax" name="buyingCountPercentMax"
                    placeholder="Buying Max Count %" />
                </mat-form-field>
              </div>

              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Non Buying Min Count %</mat-label>
                  <input matInput type="number" formControlName="nonBuyingCountPercentMin" name="nonBuyingCountPercentMin"
                    placeholder="Non Buying Min Count %" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Non Buying Max Count %</mat-label>
                  <input matInput type="number" formControlName="nonBuyingCountPercentMax" name="nonBuyingCountPercentMax"
                    placeholder="Non Buying Max Count %" />
                </mat-form-field>
              </div>

              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Incentive Order-Buying</mat-label>
                  <input matInput type="number" formControlName="incentiveBuyingOrder" name="incentiveBuyingOrder"
                    placeholder="Incentive Order-Buying" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Incentive Order-Non Buying</mat-label>
                  <input matInput type="number" formControlName="incentiveNonBuyingOrder" name="incentiveNonBuyingOrder"
                    placeholder="Incentive Order-Non Buying" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                  <mat-checkbox formControlName="isActive">Is Active</mat-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="buttonSection">
                @if(hasCreate){
                <button mat-raised-button color="primary" class="text-center ml" (click)="saveIncentiveSlab()">
                  Save
                </button>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  </div>
</ng-container>
} @else {
<ng-container>
    <p class="text-center">
      You dont have permission , Please contact administrator
    </p>
  </ng-container>
}
