<div class="container-fluid p-2">

<!--   <mat-card class="p-3 my-2">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 d-flex">
          <div class="me-3">
            <mat-icon role="button" (click)="back()">keyboard_backspace</mat-icon>
          </div>
          <h3 class="title">Product Details</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <label>Product Name</label>
          <p> {{productDetail?.name}}</p>
        </div>
        <div class="col-md-3">
          <label>Brand Name</label>
          <p> {{productDetail?.brands_name}}</p>
        </div>
        <div class="col-md-3">
          <label>Company Name</label>
          <p> {{productDetail?.companies_name}}</p>
        </div>
        <div class="col-md-3">
          <label>Categories Name</label>
          <p> {{productDetail?.categories_name}}</p>
        </div>

      </div>
    </div>
  </mat-card> -->
  <mat-card class="p-3 my-2">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 d-flex">
          <div class="me-3">
            <mat-icon role="button" (click)="back()">keyboard_backspace</mat-icon>
          </div>
          <h3 class="title">SKUs</h3>
        </div>
        <div class="col-md-6 text-end">
          <button (click)="addSku()" mat-raised-button color="primary"><mat-icon>add</mat-icon> Add</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 table-wrapper">
          <table class="table">
            <thead>
              <th>Name</th>
              <th>Status</th>
              <th style="width: 15%;">Action</th>
            </thead>
            @for (item of draftSkUDocs; track $index) {
              <tr>
                <td>{{item.payload.sku.name}}</td>
                <td>{{item.status | titlecase}}</td>
                <td>
                  @if(item.status=='rejected'){
                    <button mat-raised-button color="primary" class="mx-2"
                  (click)="editDraft(item.id)"><mat-icon
                    class="me-0">edit</mat-icon></button>
                    <button mat-raised-button color="primary" class="mx-2"
                    (click)="discardPendingDoc(item.id)"><mat-icon
                      class="me-0">delete</mat-icon></button>
                  }
                </td>
              </tr>
            }
            @for (item of vendorSkus; track $index) {
              @if (!isItemInDraft(item.attributes.skus_id)) {
                <tr>
                  <td>{{item?.attributes?.name}}</td>
                  <td>{{item?.attributes?.status | titlecase}}</td>
                  <td>
                    <div class="d-flex">
                    <button mat-raised-button color="primary" class="mx-2"
                    (click)="editCatalogSku(item.id)"><mat-icon
                      class="me-0">edit</mat-icon></button>
                      @if(item.attributes.status=='active'){
                        <button mat-raised-button color="primary" class="mx-2 nowrap" (click)="changeStatus(item.id,'inactive','Inactive')">Mark Inactive</button>
                      }
                      @if(item.attributes.status=='inactive'){
                        <button mat-raised-button color="primary" class="mx-2 nowrap" (click)="changeStatus(item.id,'active','Active')">Mark Active</button>
                      }
                      </div>
                  </td>
                </tr>
              }
            }
            @for (item of masterSkus; track $index) {
              @if (!isItemInDraft(item.id) && !isItemInVendorSkus(item.id) && item.attributes.status!='inactive') {
                <tr>
                  <td>{{item?.attributes?.name}}</td>
                  <td>{{item?.attributes?.status | titlecase}}</td>
                  <td>
                    <button mat-raised-button color="primary" class="mx-2"
                    (click)="addToCatalog(item.id)">Add to catalog</button>
                  </td>
                </tr>
              }
            }
          </table>
        </div>
      </div>
    </div>
  </mat-card>

</div>