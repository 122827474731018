@if(hasEdit){
<ng-container>
  <div class="heading-block">
    <div class="row">
      <div class="col-md-6">
        <mat-icon class="backicon" [routerLink]="['/dashboard/logistics/vehicle-list']">arrow_back</mat-icon>
        <span class="header">
          <h2 class="card-title m-3">Update Vehicle</h2>
        </span>
      </div>
      <div class="col-md-6 text-right"></div>
    </div>
  </div>

  <div class="table-block">
    <form [formGroup]="dataSource">
      <section id="content">
        <div class="card">
          <div class="card-body card-padding">
            <div class="row">
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>vehicle Model</mat-label>
                  <mat-select formControlName="vehicleModel" [(value)]="dataSource.value.vehicleModel">
                    <mat-option *ngFor="let option of vehicleModes" [value]="option.value">
                      {{ option.type }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <!-- <div class="col-md-6">
      <mat-form-field class="full-width">
        <mat-label>Supplier Name</mat-label>
        <input matInput type="string" formControlName="supplierName" name="supplierName" placeholder="Supplier Name"/>
      </mat-form-field>
    </div> -->

              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Supplier Name</mat-label>
                  <mat-select formControlName="supplierName">
                    <mat-option *ngFor="let item of supplierName" [value]="item.id">
                      {{ item.value }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Vehicle Tonnage</mat-label>
                  <mat-select formControlName="vehicleTonnage">
                    <mat-option *ngFor="let option of vehicleTonnageOptions" [value]="option.value">
                      {{ option.tonnage }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Vehicle Number</mat-label>
                  <input matInput type="string" formControlName="vehicleNumber" name="vehicleNumber"
                    placeholder="Vehicle Number" pattern="[a-zA-Z0-9]*" />
                  <mat-error *ngIf="dataSource.get('vehicleNumber').hasError('pattern')">
                    Only numbers and letters are allowed
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Registration Number</mat-label>
                  <input matInput type="string" formControlName="registrationNumber" name="registrationNumber"
                    placeholder="Registration Number" pattern="[a-zA-Z0-9\-]*" />
                </mat-form-field>
                <mat-error *ngIf="dataSource.get('registrationNumber').hasError('pattern')">
                  Only numbers and letters are allowed
                </mat-error>
              </div>

              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>No.Of Days</mat-label>
                  <input matInput type="number" formControlName="noOfDays" name="noOfDays" placeholder="No.Of Days" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Cost</mat-label>
                  <input matInput type="number" formControlName="cost" name="cost" placeholder="Cost"
                    (keydown)="handleKeyPress($event)" min="0" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Contract Mode</mat-label>
                  <mat-select formControlName="contractMode">
                    <mat-option *ngFor="let type of vehicleContractMode" [value]="type">
                      {{ type === 1 ? "Fixed" : "Adhoc" }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Default Driver</mat-label>
                  <mat-select formControlName="defaultDriver" [(value)]="dataSource.id">
                    <mat-option *ngFor="let driverId of driverList" [value]="driverId.id">
                      {{ driverIdToNameMap[driverId.id] }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Status</mat-label>
                  <mat-select formControlName="status">
                    <mat-option *ngFor="let status of vehicleStatus" [value]="status.value">
                      {{ status.type }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width locationinput">
                  <mat-label>Location</mat-label>
                  <mat-select formControlName="location" [(value)]="dataSource.value.location">
                    @for (item of locations; track item) {
                    <mat-option [value]="item.value">{{
                      item.value
                      }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <mat-label>Registration Document</mat-label>
              </div>
              <div class="row">
                <div class="imgSupplier" *ngIf="documentUrl">
                  <img [src]="documentUrl" class="imgSupplier imgpop-up" alt="Registration Document"
                    (click)="openPopUp(documentUrl)" />
                </div>
                <div class="imageupload">
                  <input class="form-control" (change)="onFileChange($event, 'register')" type="file" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="buttonSection">
                @if(hasEdit){
                <button mat-raised-button color="primary" class="text-center ml" (click)="updateVehicle()">
                  Update
                </button>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  </div>
</ng-container>
} @else {
<ng-container>
  <p class="text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
}
