@if(hasCreate){
<ng-container>
  <div class="heading-block">
    <div class="row">
      <div class="col-md-6">
        <mat-icon class="backicon" [routerLink]="['/dashboard/promotion']">arrow_back</mat-icon>
        <span class="header">
          <h2 class="card-title m-3">Create Promotion</h2>
        </span>
      </div>
      <div class="col-md-6 text-right"></div>
    </div>
  </div>

  <div class="table-block">
    <form [formGroup]="dataSource">
      <section id="content">
        <div class="card">
          <div class="card-body card-padding">
            <div class="row">
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Promo Type</mat-label>
                  <mat-select formControlName="promoType" (selectionChange)="onPromoTypeChange($event)">
                    @for (promotionType of promotionTypeList; track $index) {
                    <mat-option [value]="promotionType.promotionTypeId">{{promotionType.promotionType}}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-6" *ngIf="[1,2].includes(selectedPromoType)">
                <mat-form-field class="full-width">
                  <mat-label>Promo Name<span class="text-danger">*</span></mat-label>
                  <input matInput type="string" formControlName="promoName" name="promoName" placeholder="Promo Name"
                    maxlength="250" />
                </mat-form-field>
              </div>
              <div class="col-md-6" *ngIf="[1,2].includes(selectedPromoType)">
                <mat-form-field class="full-width">
                  <mat-label>Vendor</mat-label>
                  <mat-select formControlName="vendorSelected" (selectionChange)="onChangeVendor($event)">
                    <input matInput formControlName="vendorSearch" placeholder="Search Vendor" (keyup)="onKey($event)"
                      class="editableSearch" />
                    <mat-option *ngFor="let merchant of merchantSearchList" [value]="merchant.id">
                      {{ merchant.value }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-6" *ngIf="[1,2].includes(selectedPromoType)">
                <mat-form-field class="full-width">
                  <mat-label>Category</mat-label>
                  <mat-select formControlName="categories" multiple>
                    <mat-option *ngFor="let item of categoryList" [value]="item.id">
                      {{ item.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-6" *ngIf="[1,2].includes(selectedPromoType)">
                <mat-form-field class="full-width">
                  <mat-label>Brand Name</mat-label>
                  <mat-select formControlName="brands" multiple>
                    <mat-option *ngFor="let item of brandList" [value]="item.id">
                      {{ item.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-6" *ngIf="[1,2].includes(selectedPromoType)">
                <mat-form-field class="full-width">
                  <mat-label>Expiry Date<span class="text-danger">*</span></mat-label>
                  <input matInput formControlName="expiryDate" placeholder="Expiry Date" [matDatepicker]="appDatepicker"
                    [readonly]="true" [min]="yesterday" />
                  <mat-datepicker-toggle matSuffix [for]="appDatepicker"></mat-datepicker-toggle>
                  <mat-datepicker #appDatepicker></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-md-6" *ngIf="[1,2].includes(selectedPromoType)">
                <mat-form-field class="full-width">
                  <mat-label>Display Name<span class="text-danger">*</span></mat-label>
                  <input matInput type="string" formControlName="displayName" name="displayName"
                    placeholder="Display Name" />
                </mat-form-field>
              </div>
              <div class="col-md-6" *ngIf="[1,2].includes(selectedPromoType)">
                <mat-form-field class="full-width">
                  <mat-label>Sequence</mat-label>
                  <input matInput type="number" formControlName="sequence" name="sequence" placeholder="Sequence"
                    maxlength="5" />
                </mat-form-field>
              </div>
              <div class="col-md-6" *ngIf="[1,2].includes(selectedPromoType)">
                <mat-form-field class="full-width">
                  <mat-label>App Url</mat-label>
                  <mat-select formControlName="appUrl">
                    @for (item of appUrlTypeList; track $index) {
                    <mat-option [value]="item.value">{{item.key}}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-6" *ngIf="[1,2].includes(selectedPromoType)">
                <mat-form-field class="full-width">
                  <mat-label>Web Url</mat-label>
                  <input matInput type="string" formControlName="webUrl" name="webUrl" placeholder="Web Url" />
                </mat-form-field>
              </div>
              <div class="col-md-6" *ngIf="[1,2].includes(selectedPromoType)">
                <mat-label>Promo Image <span class="text-danger">*</span></mat-label>
                <input formControlName="promoImageUrl" class="form-control"
                  (change)="onFileChange($event, 'promo_image')" type="file" />
                @if(previewImage){
                <img [src]="previewImage" style="width: 100px; height: 100px" />
                }
              </div>

              <div class="col-md-6 " *ngIf="[1,2].includes(selectedPromoType)">
                <mat-label>Upload SKUs</mat-label>
                <div class="d-flex">
                  <button mat-raised-button color="primary" (click)="openUploadSkuDialog()">
                    Upload Excel
                  </button>
                  <button mat-raised-button class="ms-2" color="primary"
                    (click)="downloadExcelFormat('CustomerFormat')">
                    Download Format
                  </button>
                </div>
                <table mat-table [dataSource]="skuList" *ngIf="skuList.length > 0" class="mat-elevation-z8">
                  <ng-container matColumnDef="skuId">
                    <th mat-header-cell *matHeaderCellDef>SKU Id</th>
                    <td mat-cell *matCellDef="let skuObj">{{ skuObj.skuId }}</td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
              </div>

            </div>
            <div class="row">
              <!-- <div class="col-md-12">
                <table mat-table [dataSource]="skuList" class="mat-elevation-z8">
                  <ng-container matColumnDef="skuId">
                    <th mat-header-cell *matHeaderCellDef>SKU Id</th>
                    <td mat-cell *matCellDef="let skuObj">{{ skuObj.skuId }}</td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
              </div> -->
            </div>
            <div class="row">
              <div class="buttonSection mt-3">
                @if(hasCreate){
                <button mat-raised-button color="primary" class="text-center ml" (click)="savePromotion()">
                  Save
                </button>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  </div>
</ng-container>
} @else {
<ng-container>
  <p class="text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
}