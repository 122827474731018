import { Injectable } from '@angular/core';
import {
  GetTargetsResponse,
  SalesPersonPerformance,
  TargetsEntity,
  TargetsListSearch,
  createTargetsResponse,
} from '../models/targets.model';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { DB_PATH } from '../../../constants/db-path';
import { FirestoreService } from '../../../services/firestore.service';
import { QueryFilter, WhereFilterOp } from '../../../enums/enums';

@Injectable({
  providedIn: 'root',
})
export class TargetsService {
  baseServiceUrl: string = `${environment.logisticsUrl}`;
  coseServiceUrl: string = `${environment.coreServiceUrl}`;
  orderServiceUrl: string = `${environment.ordersServiceUrl}`;

  constructor(
    private readonly http: HttpClient,
    public firestoreService: FirestoreService
  ) {}

  async createTargets(data: TargetsEntity) {
    let response = await lastValueFrom(
      this.http.post<{ res: createTargetsResponse }>(
        `${this.baseServiceUrl}/api/targets/coreServiceUrl`,
        data
      )
    );

    return response.res;
  }

  async getTargets(body: TargetsListSearch): Promise<SalesPersonPerformance[]> {
    try {
      const res = await lastValueFrom(
        this.http.post<GetTargetsResponse>(
          `${this.orderServiceUrl}/query/get_salesperson_performance_goals`,
          body
        )
      );

      return res.data;
    } catch (error) {
      console.error('Error fetching targets:', error);
      return [];
    }
  }

  async getSalesPersons(): Promise<any> {
    //   const filters: Array<QueryFilter> = [{
    //     fieldName: "clusterId", fieldValue: isActive, operator: WhereFilterOp.EqualTo
    // }]
    // const response = await this.firestoreService.queryCollectionByFilters(DB_PATH.SALES_PERSON, filters);

    const response = await this.firestoreService.fetchCollection(
      DB_PATH.SALES_PERSON
    );
    return this.mapToSalesPerson(response);
  }

  private mapToSalesPerson(salesPersons: Array<any> = []) {
    // TODO need to define type of sales person

    // const filteredData = salesPersons.filter(obj => obj.isActive && !obj.enableLogOut);

    return salesPersons
      .map((salesPerson) => {
        return {
          name: salesPerson.name || '',
          mobileNumber: salesPerson.mobileNumber || '',
          id: salesPerson.id || '',
          businessName: salesPerson.businessName || '',
          isActive: salesPerson.isActive || false,
          areaName: salesPerson.cluster ? salesPerson.cluster.name || '' : '',
          areaId: salesPerson.cluster ? salesPerson.cluster.id || '' : '',
          area: salesPerson.cluster || {},
          customerCount: salesPerson.customerCount || 0,
          salesLeadId: salesPerson.salesLeadId || '',
          salesLeadName: salesPerson.salesLead
            ? salesPerson.salesLead.name || ''
            : '',
          salesLeadMobileNumber: salesPerson.salesLead
            ? salesPerson.salesLead.mobileNumber || ''
            : '',
          salesManagerId: salesPerson.salesManagerId || '',
          salesManagerName: salesPerson.salesManager
            ? salesPerson.salesManager.name || ''
            : '',
          salesManagerMobileNumber: salesPerson.salesManager
            ? salesPerson.salesManager.mobileNumber || ''
            : '',
          enableLogOut: salesPerson.enableLogOut
            ? salesPerson.enableLogOut || false
            : false,
        };
      })
      .sort((a, b) => b.customerCount - a.customerCount);
  }

  async getTargetsById(id: string, date: string): Promise<any> {
    const res = await lastValueFrom(
      this.http.get<any>(
        `${this.coseServiceUrl}/api/sales-performance/${id}/${date}`
      )
    );
    return res;
  }

  async updateTargets(
    targetsEntity: TargetsEntity,
    salespersonId: string,
    date: string
  ) {
    let response = await lastValueFrom(
      this.http.post<{ res: createTargetsResponse }>(
        `${this.coseServiceUrl}/api/sales-performance/${salespersonId}/${date}`,
        targetsEntity
      )
    );
    return response.res;
  }

  async deleteTargets(id: string) {
    return await lastValueFrom(
      this.http.delete(`${this.baseServiceUrl}/api/targets/delete/${id}`)
    );
  }

  private mapSalesPerson(salesPersons: any) {
    return salesPersons.map((s: { id: any; name: any }) => ({
      id: s.id,
      name: s.name,
    }));
  }

  async getActiveSalesPerson(salesPersonId: string, date: string) {
    const filters = [
      {
        fieldName: 'salesPersonId',
        operator: '==' as WhereFilterOp,
        fieldValue: salesPersonId,
      },
      {
        fieldName: 'taskDate',
        operator: '==' as WhereFilterOp,
        fieldValue: date,
      },
    ];

    const salesPersonTasksCount = await this.firestoreService.queryCollectionCount(
      'Tasks',
      filters
    );

    return salesPersonTasksCount;
  }

  async refreshTargets(payload: any) {
    try {
      let response = await lastValueFrom(
        this.http.post<{ res: createTargetsResponse }>(
          `${this.coseServiceUrl}/api/sales-performance/insert_update_bulk_refresh`,
          payload
        )
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
}
