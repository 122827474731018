<ng-container>
  <div class="m-3">
  <div class="row">
    <div class="d-flex justify-content-end">
      <mat-icon (click)="dialogRef.close()" class=" m-3 closeicon">close</mat-icon>
    </div>
  </div>
  <div class="row">
    <form [formGroup]="dataSource" >
        <div class="container">
          <mat-card>
            <mat-card-content>
              <div class="row">
                <mat-form-field>
                  <mat-label>Supplier</mat-label>
                  <mat-select formControlName="selectedSupplier" >
                    <input matInput [formControl]="supplierSearchControl" placeholder="Enter search text here"
                      class="editableSearch" (keydown.space)="$event.stopPropagation()"/>
                    <mat-option *ngFor="let supplier of supplierSearchResults" [value]="supplier">
                      {{ supplier.name }}
                    </mat-option>
                    <mat-option>
                     <p *ngIf="searchPerformed && supplierSearchResults.length === 0">No Result Found</p>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div style="display: flex;  justify-content: center;">
                <label *ngIf="isSkuSelected" class="error-message">Please Select Supplier to Proceed</label>
              </div>
              <div style="display: flex;  justify-content: center;">

                <div style="margin-right: 10px;">
                  <button mat-raised-button color="primary" class="text-center ml-w"  (click)="createPR()">
                    Proceed
                  </button>
                </div>
                <div style="margin-left: 10px;">
                  <button mat-raised-button color="primary" class="text-center ml-w" (click)="dialogRef.close()">
                    Cancel
                  </button>
                </div>
              </div>

            </mat-card-content>
          </mat-card>
        </div>
      </form>
  </div>
  </div>
</ng-container>
