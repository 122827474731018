import { CalculationType, ChargeBase, FulfillmentType, GateWayType, ShippingChargeType } from './../../../../enums/enums';
import { Component, ViewChild } from '@angular/core';
import {
  MatDialog,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ActivatedRoute, Router, RouterLink, RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { SettingService } from '../../services/settings.service';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { AuthService } from '../../../../services/auth.service';
import { AppLoaderService } from '../../../../shared/app-loader/app-loader.service';
import { LookUpType, SwalMessageTypes } from '../../../../enums/enums';
import { MarginsService } from '../services/margins.service';
import { applyMaxLengthValidation, getEnumNameByValue, getFulfillment, getTransitType, isEmptyValue, isEmptyValueAllowZero, validateNoNegativeValues } from '../../../../utlity/utility';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatNativeDateModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatSort } from '@angular/material/sort';
import { AddCategoryMarginPopupComponent } from '../margin/add-category-margin-popup/add-category-margin-popup.component';
import { ICategoryMarginData } from '../services/category-margin-model';
import { IProviderData } from '../services/provider-model';
import { AddProvidersPopupComponent } from '../margin/add-providers-popup/add-providers-popup.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LookUpTypeService } from '../../../../services/lookup-type.service';
import { ValidationResult } from '../../../supplier/models/logisticsupplier-model';




import { IShippingData } from '../services/shipping-model';
import { AddShippingPopupComponent } from '../margin/add-shipping-popup/add-shipping-popup.component';
import { CategoryService } from '../../../category/services/category.service';
import { BaseListComponent } from '../../../../shared/core/base.list.component';
import { PageId } from '../../../../constants/enums';
interface checkNegativeValues {
  globalMargin: number;
  platformFees: number;
  marketingFee: number;
  discount: number;
}
@Component({
  selector: 'app-vendor-settings',
  standalone: true,
  imports: [
    MatDialogModule,
    RouterModule,
    MatSelectModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    MatTableModule,
    ReactiveFormsModule,
    MatCardModule, MatButtonModule, MatTableModule, RouterLink, MatInputModule, MatFormFieldModule, ReactiveFormsModule,
    MatSelectModule, MatIconModule, CommonModule, FormsModule, MatDatepickerModule,
    MatRadioModule, MatNativeDateModule, MatPaginatorModule, MatSidenavModule, MatCheckboxModule
  ],
  templateUrl: './vendor-settings.component.html',
  styleUrl: './vendor-settings.component.scss',
})

export class VendorSettingsComponent extends BaseListComponent {
  merchantDetails: any;
  merchantResult: any;
  vendorSettings: any;
  margin: number = 0;
  dataSource: FormGroup;
  id: string = "";
  categories: Array<any> = [];
  fulfillments: { fulfillmentProvider: string, fulfillmentId: number }[] = [];
  //merchantBusinessTypes: { businessTypeName: string; businessTypeId: number }[] = [];
  categoryMarginValues: Array<any> = [];
  providers: Array<any> = [];
  shippingCharges: Array<any> = [];
  categoryMarginDataSource: any;
  providerDataSource: any;
  shippingChargesDataSource: any;
  displayedColumns: string[] = ['categoryName', 'categoryMargin', "delete"];
  displayedProviderColumns: string[] = ['providerName', 'settlementDays', 'chargeType', 'chargeValue', "delete"];
  displayedShippingChargesColumns: string[] = ['categoryName', 'chargeBase', 'chargeType', 'chargeValue', "delete"];
  lookUpTypeSearchInput: LookUpType = this.initializeLookUpTypeSearchInput();
  isQwipoFulfillmentType: boolean = false;
  public locations: any[] = [];
  transitTypes: { transitTypeName: string, transitTypeId: number }[] = [];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    public settingService: SettingService,
    private marginsService: MarginsService,
    private categoryService: CategoryService,
    private dialog: MatDialog,
    private lookupService: LookUpTypeService,
  ) {
    super(auth, router, route, PageId.bms_vendors)
    //this.categories = getCategories();
    this.fulfillments = getFulfillment();
    this.transitTypes = getTransitType();
    //this.merchantBusinessTypes = getMerchantBusinessType();
    this.dataSource = this.editForms();

    this.fixMaxLength();
  }


  override async ngOnInit() {
    await super.ngOnInit();
    this.route.params.subscribe(async (params) => {
      this.merchantDetails = history.state.merchantDetails;
    });
    const queryObj = { level: 1, sort_by: "display_order", sort_order: "asc" };
    this.categoryService.getAll(queryObj).subscribe(async (res: any) => {
      this.categories = res.data;
      await this.getLocation();
      this.loadVendorSettingData();
    });
  }
  editForms() {
    return this.fb.group({
      id: [null, [Validators.required]],
      globalMargin: [null, [Validators.required]],
      platformFees: [null, [Validators.required]],
      marketingFee: [null, [Validators.required]],
      discount: [null, [Validators.required]],
      providers: [null, [Validators.required]],
      fulfillment: [null, [Validators.required]],
      transitType: [null],
      region: [null],
      active: [null, [Validators.required]],
      apiSecret: [null],
      apiUrl: [null],
      apiKey: [null],
      //businessTypeId: [null, [Validators.required]]
    });
  }
  async loadVendorSettingData() {
    this.vendorSettings = await this.marginsService.getAdminSettingByVendorId(this.merchantDetails.id);
    if (this.vendorSettings && this.vendorSettings.admin_config) {
      const categoryMargins = this.vendorSettings.admin_config.categoryMargins || [];
      const providers = this.vendorSettings.admin_config.providers || [];
      const mappedCategoryData = categoryMargins.map((item: { categoryId: string; margin: number; }) => {

        const category = this.categories.find(cat => cat.id === item.categoryId);
        return {
          categoryId: item.categoryId,
          categoryMargin: item.margin,
          categoryName: category ? category?.attributes.name : 'Unknown Category'
        };
      });
      const shippingCharges = this.vendorSettings.admin_config.shippingCharges || [];
      const mappedShippingChargesData = shippingCharges.map((item: any) => {
        const category = this.categories.find(cat => cat.id === item.category);
        return {
          category: item.category,
          chargeBase: item.chargeBase,
          chargeType: item.chargeType,
          chargeValue: item.chargeValue,
          categoryName: category ? category?.attributes.name : 'Unknown Category'
        };
      });

      this.dataSource.get('globalMargin')?.setValue(this.vendorSettings.admin_config.globalMargin || 0);
      this.dataSource.get('platformFees')?.setValue(this.vendorSettings.admin_config.platformFees || 0);
      this.dataSource.get('marketingFee')?.setValue(this.vendorSettings.admin_config.marketingFee || 0);
      this.dataSource.get('discount')?.setValue(this.vendorSettings.admin_config.discount || 0);
      const fulfillmentObj = this.vendorSettings.admin_config.fulfillment;
      if (fulfillmentObj && fulfillmentObj.fulfillmentBy) {
        this.dataSource.get('fulfillment')?.setValue(fulfillmentObj.fulfillmentBy || 1);
        if (fulfillmentObj.fulfillmentBy == FulfillmentType.Qwipo) {
          this.isQwipoFulfillmentType = true;
          this.dataSource.get('transitType')?.setValue(fulfillmentObj.transitType || "");
          this.dataSource.get('region')?.setValue(fulfillmentObj.region || "");
        }
      }

      if (this.vendorSettings.admin_config && this.vendorSettings.admin_config.vendorMapping) {
        this.dataSource.get('active')?.setValue(this.vendorSettings.admin_config.vendorMapping.active || false);
        this.dataSource.get('apiSecret')?.setValue(this.vendorSettings.admin_config.vendorMapping.apiSecret || "");
        this.dataSource.get('apiUrl')?.setValue(this.vendorSettings.admin_config.vendorMapping.apiUrl || "");
        this.dataSource.get('apiKey')?.setValue(this.vendorSettings.admin_config.vendorMapping.apiKey || "");
        this.dataSource.get('businessTypeId')?.setValue(this.vendorSettings.admin_config.vendorMapping.businessTypeId || "");
        // const apiSecret = this.dataSource.get('apiSecret')?.value;
        // const apiUrl = this.dataSource.get('apiUrl')?.value;
        // const apiKey = this.dataSource.get('apiKey')?.value;
        // this.dataSource = this.fb.group({
        //   apiUrl: [{ value: apiUrl, disabled: !!apiUrl }, Validators.required],
        //   apiKey: [{ value: apiKey, disabled: !!apiKey }, Validators.required],
        //   apiSecret: [{ value: apiSecret, disabled: !!apiSecret }, Validators.required],
        // });
      }
      if (mappedCategoryData && mappedCategoryData.length > 0) {
        this.categoryMarginValues = mappedCategoryData;
        this.categoryMarginDataSource = new MatTableDataSource(this.categoryMarginValues);
      }
      if (providers && providers.length > 0) {
        this.providers = providers;
        this.providerDataSource = new MatTableDataSource(this.providers);
      }

      if (mappedShippingChargesData && mappedShippingChargesData.length > 0) {
        this.shippingCharges = mappedShippingChargesData;
        this.shippingChargesDataSource = new MatTableDataSource(this.shippingCharges);
      }
    }

  }
  getProviderNameByEnum(enumValue: any) {
    return getEnumNameByValue(GateWayType, enumValue) || "";
  }
  getCalculationTypeByEnum(enumValue: any) {
    return getEnumNameByValue(CalculationType, enumValue) || "";
  }
  getShippingChargeTypeByEnum(enumValue: any) {
    return getEnumNameByValue(ShippingChargeType, enumValue) || "";
  }
  getChargeBaseByEnum(enumValue: any) {
    return getEnumNameByValue(ChargeBase, enumValue) || "";
  }
  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  async deleteCategoryMargin(row: any) {
    const result = await Swal.fire({
      title: 'Do you want to delete this Category Margin?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    });

    if (!result.isConfirmed) {
      return;
    }
    this.categoryMarginValues = this.categoryMarginValues.filter(obj => obj.categoryId !== row.categoryId);
    this.categoryMarginDataSource = new MatTableDataSource(this.categoryMarginValues);
  }
  mapVendorMarginData(platformFees: number, marketingFee: number, discount: number, globalMargin: number, categoryMarginValues: Array<any>, providers: Array<any>, shippingCharges: Array<any>, fulfillment: number, active: boolean, apiSecret: string, apiKey: string, apiUrl: string, transitType: string, region: string) {
    const categoryMargins = categoryMarginValues.map((categoryMarginValue) => {
      return {
        categoryId: categoryMarginValue.categoryId,
        margin: categoryMarginValue.categoryMargin
      }
    });
    const shippingChargesMaping = shippingCharges.map((shippingCharge) => {
      return {
        category: shippingCharge.category,
        chargeBase: shippingCharge.chargeBase,
        chargeType: shippingCharge.chargeType,
        chargeValue: shippingCharge.chargeValue
      }
    });
    let fulfillmentData = {};
    if (fulfillment == FulfillmentType.Qwipo) {
      fulfillmentData = { "fulfillmentBy": fulfillment, "transitType": transitType, "region": region };
    } else {
      fulfillmentData = { "fulfillmentBy": fulfillment, "transitType": 3 }
    }
    const vendorMapping = {
      active,
      apiSecret,
      apiKey,
      apiUrl,
      "selfSalesPersonId": "Sales Team",
      "apiModules": {
        "qwipo": "qwipo.api",
        "viwito": "viwito.api"
      }
    };
    return {
      platformFees,
      marketingFee,
      discount,
      globalMargin,
      categoryMargins: categoryMargins,
      providers: providers,
      shippingCharges: shippingChargesMaping,
      fulfillment: fulfillmentData,
      vendorMapping
    }
  }
  mapVendorSetting(marginData: any) {
    return {
      vendorId: this.merchantDetails.id,
      adminConfig: marginData
    }
  }

  async saveVendorMarginSetting() {
    try {
      const globalMargin = this.dataSource?.get("globalMargin")?.value;
      if (isEmptyValueAllowZero(globalMargin)) {
        Swal.fire('', "Please fill Global Margin!", 'warning');
        return;
      }
      const platformFees = this.dataSource.get("platformFees")?.value;
      if (isEmptyValueAllowZero(platformFees)) {
        Swal.fire('', "Please fill Platform Fees!", 'warning');
        return;
      }
      const marketingFee = this.dataSource.get("marketingFee")?.value;
      if (isEmptyValueAllowZero(marketingFee)) {
        Swal.fire('', "Please fill Global Marketing Fee!", 'warning');
        return;
      }
      const discount = this.dataSource.get("discount")?.value;
      if (isEmptyValueAllowZero(discount)) {
        Swal.fire('', "Please fill discount!", 'warning');
        return;
      }

      const checkNegative = {
        globalMargin,
        platformFees,
        marketingFee,
        discount
      }

      const validateNegativeValues = this.validateNegativeValues(checkNegative);

      if (!validateNegativeValues.isValid) {
        const message = this.generateErrorMessage(validateNegativeValues.failedFields);
        this.showMessage(message, SwalMessageTypes.Warning);

        return
      }

      // const businessTypeId = this.dataSource.get("businessTypeId")?.value || "";
      // if (isEmptyValue(businessTypeId)) {
      //   Swal.fire('', "Please Select Business Type!", 'warning');
      //   return;
      // }

      const fulfillment = this.dataSource.get("fulfillment")?.value || "";
      const transitType = this.dataSource.get("transitType")?.value || "";
      if (fulfillment == FulfillmentType.Qwipo && isEmptyValue(transitType)) {
        Swal.fire('', "Please Select Transit Type!", 'warning');
        return;
      }
      const region = this.dataSource.get("region")?.value || "";
      if (fulfillment == FulfillmentType.Qwipo && isEmptyValue(region)) {
        Swal.fire('', "Please Select Region!", 'warning');
        return;
      }
      const active = this.dataSource.get("active")?.value || false;
      const apiSecret = this.dataSource.get("apiSecret")?.value || "";
      const apiKey = this.dataSource.get("apiKey")?.value || "";
      const apiUrl = this.dataSource.get("apiUrl")?.value ? this.dataSource.get("apiUrl")?.value.trim() : this.dataSource.get("apiUrl")?.value;
      const marginData = this.mapVendorMarginData(platformFees, marketingFee, discount, globalMargin, this.categoryMarginValues, this.providers, this.shippingCharges, fulfillment, active, apiSecret, apiKey, apiUrl, transitType, region);
      const vendorSettings = this.mapVendorSetting(marginData);
      console.log("this.data.value:::", this.dataSource.value)
      const response = await this.marginsService.createAdminSetting(vendorSettings);
      // saving data in redis cache
      // const redisResponse = await this.marginsService.createAdminSettingInCache(vendorSettings);
      Swal.fire('', "Vendor settings updated successfully!", 'success');
    } catch (error) {
      Swal.fire('', "Something went wrong!", 'error');
      console.log("VendorMarginSetting Error : ", error);
    }

  }
  openPopUp(savedCategories: Array<any> = [], editData: any = [], isEdit: boolean = false) {
    const dialogRef = this.dialog.open(AddCategoryMarginPopupComponent, {
      width: "60%",
      height: "50%",
      data: {
        title: 'Add Category Margin',
        savedCategories: savedCategories,
        editData: editData,
        isEdit: isEdit
      } as ICategoryMarginData
    });
    dialogRef.afterClosed().subscribe(async (response: any) => {
      if (response) {
        const popupResult = response.popupResult || {};
        const index = this.categoryMarginValues.findIndex(item => item.categoryId === popupResult.categoryId);

        if (index !== -1) {
          this.categoryMarginValues[index].categoryMargin = popupResult.categoryMargin;
        } else {
          this.categoryMarginValues.push(popupResult);
        }
        this.categoryMarginDataSource = new MatTableDataSource(this.categoryMarginValues);
      }
    });
  }
  openProviderPopUp(savedProviders: Array<any> = []) {
    const dialogRef = this.dialog.open(AddProvidersPopupComponent, {
      width: "50%",
      height: "50%",
      data: {
        title: 'Add Category Margin',
        savedProviders: savedProviders,
      } as IProviderData
    });
    dialogRef.afterClosed().subscribe(async (response: any) => {
      if (response) {
        console.log("providers response- ", response);
        const popupResult = response.popupResult || {};
        this.providers.push(popupResult);
        this.providerDataSource = new MatTableDataSource(this.providers);
      }
    });
  }

  async deleteProvider(row: any) {

    try {
      const result = await Swal.fire({
        title: 'Do you want to delete this provider setting?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      });

      if (!result.isConfirmed) {
        return;
      }
      this.providers = this.providers.filter(obj => obj.provider !== row.provider);
      this.providerDataSource = new MatTableDataSource(this.providers);
      this.showMessage(
        'Provider Deleted Successfully',
        SwalMessageTypes.Success
      );
    } catch (error) {
      console.error('Error occurred while Deleting Provider');
      this.showMessage(
        'Failed to Delete Provider. Please try again later.',
        SwalMessageTypes.Error
      );
    }
  }
  openShippingPopUp(savedShipping: Array<any> = []) {
    const dialogRef = this.dialog.open(AddShippingPopupComponent, {
      width: "50%",
      height: "50%",
      data: {
        title: 'Add Shipping',
        savedShippings: savedShipping,
      } as IShippingData
    });
    dialogRef.afterClosed().subscribe(async (response: any) => {
      if (response) {
        const popupResult = response.popupResult || {};
        const category = this.categories.find(cat => cat.id === popupResult.category);
        this.shippingCharges.push({ ...popupResult, categoryName: category ? category?.attributes?.name : 'Unknown Category' });

        console.log("shipping response- ", this.categories, popupResult, this.shippingCharges);
        this.shippingChargesDataSource = new MatTableDataSource(this.shippingCharges);
      }
    });
  }
  // deleteShippingCharge(row: any) {
  //   this.shippingCharges = this.shippingCharges.filter(obj => obj.category !== row.category);
  //   this.shippingChargesDataSource = new MatTableDataSource(this.shippingCharges);
  // }
  // deleteShippingCharge(row: any): void {
  //   if (row) {

  //       this.shippingCharges = this.shippingCharges.filter(obj => obj.category !== row.category);
  //       this.shippingChargesDataSource = new MatTableDataSource(this.shippingCharges);
  //     }
  // }

  async deleteShippingCharge(row: any) {
    try {
      const result = await Swal.fire({
        title: 'Are you sure you want to delete?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      });

      if (!result.isConfirmed) {
        return;
      }

      if (row) {
        this.shippingCharges = this.shippingCharges.filter(obj => obj.category !== row.category);
        this.shippingChargesDataSource = new MatTableDataSource(this.shippingCharges);
      }

      this.showMessage(
        'Shipping charge deleted successfully',
        SwalMessageTypes.Success
      );
    } catch (error: any) {
      this.showMessage(
        error.error.message,
        SwalMessageTypes.Error
      );
    }
  }



  private initializeLookUpTypeSearchInput(): LookUpType {
    return {
      tableName: "locations",
      lookupType: "locations",
      idColumnName: "id",
      valueColumnName: "name"
    };
  }
  async getLocation() {
    const response = await this.lookupService.getLookUpTypes(this.lookUpTypeSearchInput);
    this.locations = response.data[0].data;
  }
  onChangeFulfillment(event: any) {
    if (event.value == FulfillmentType.Qwipo) {
      this.isQwipoFulfillmentType = true;
    } else {
      this.isQwipoFulfillmentType = false;
    }
  }

  private fixMaxLength() {
    applyMaxLengthValidation(this.dataSource, [
      { property: "globalMargin", maxLength: 5 },
      { property: "platformFees", maxLength: 5 },
      { property: "marketingFee", maxLength: 5 },
      { property: "discount", maxLength: 5 },
    ]);
  }


  private validateNegativeValues(data: checkNegativeValues): ValidationResult {
    const numericInputs = [
      { field: 'globalMargin', value: data.globalMargin },
      { field: 'discount', value: data.discount },
      { field: 'marketingFee', value: data.marketingFee },
      { field: 'platformFees', value: data.platformFees },
    ];

    const validation = validateNoNegativeValues(
      numericInputs.map((input) => input)
    );
    return {
      isValid: validation.isValid,
      failedFields: validation.failedFields,
    };
  }

  private generateErrorMessage(failedFields: { field: string; failedField: string }[] | undefined): string {
    let message = 'The following fields contain invalid numeric values:';
    if (failedFields) {
      failedFields.forEach(failedField => {
        message += ` ${failedField.field} (${failedField.failedField}),`;
      });
      message = message.slice(0, -1) + '.';
    }

    return message;
  }



}
