import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpEventType, HttpParams, HttpResponse } from '@angular/common/http';
import { lastValueFrom, Observable } from 'rxjs';
import { ApiResponse, CompanyListSearch, createCompanyResponse, VendorCompanyEntity } from '../margin/models/companies.model';
export interface VrsMerchantAPIKeys {
  apiKey: string;
  apiSecret: string;
  apiUrl: string;
  selfSalesPersonId: string;
  apiModules: ApiModules;
}
export interface MyResponse {
  message: any[];
}

export interface ApiModules {
  qwipo: string;
  viwito: string
}





@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  baseServiceUrl: string = `${environment.coreServiceUrl}`;
  itemService: string = `${environment.backendUrl}`;


  constructor(
    private readonly http: HttpClient) {
  }

  async getVendorSettingByVendorId(vendorId: string): Promise<any> {
    const res = await lastValueFrom(this.http.get<any>(`${this.baseServiceUrl}/api/vendor_settings/get_vendor_config/${vendorId}`));
    return res.data;
  }

  getAll(): Observable<any> {
    return this.http.get(`${this.itemService}/companies`);
  }

  async createVendorCompany(vendorCompany: VendorCompanyEntity) {

    return this.http.post<any>(`${this.baseServiceUrl}/api/vendor_company/create`, vendorCompany);
  }


  async getVendorCompanies(body: CompanyListSearch): Promise<any> {
    const res = await lastValueFrom(
      this.http.get<any>(`${this.baseServiceUrl}/api/vendor_company/${body.vendorId}?page_number=${body.pageNumber}&page_size=${body.pageSize}`)
    );


    if (res && res.data) {
      return {
        vendorCompanies: res.data.rows,
        totalCount: res.data.count
      }
    }

    return res
  }


  async getCompaniesByFilters(body: CompanyListSearch): Promise<any> {
    const res = await lastValueFrom(
      this.http.get<any>(
        `${this.baseServiceUrl}/api/vendor_company/${body.vendorId}?page_number=${body.pageNumber}&page_size=${body.pageSize}`
      )
    );
    if (res && res.data) {
      return {
        vendorCompanyCount: res.data.count,
        vendorCompanies: res.data.rows
      };
    } else {
      return null
    }
  }

  async deleteCompany(vendorId: string, companyId: string) {
    const url = `${this.baseServiceUrl}/api/vendor_company/${vendorId}/${companyId}`;
    return await lastValueFrom(this.http.delete(url));
  }

}
