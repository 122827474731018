import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import Swal from 'sweetalert2';
import { AuthService } from '../../../../../../services/auth.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseListComponent } from '../../../../../../shared/core/base.list.component';
import { PageId } from '../../../../../../constants/enums';
import { AppStateService } from '../../../../../../services/appstate.service';
import { takeUntil } from 'rxjs';
import { MappedTrip } from '../../../../../../entities/trip-info';
import { integerValidator } from '../../../../../../utlity/utility';

interface ProductForm {
  orderedQty: FormControl<number | null>;
  availableQuantity: FormControl<number | null>;
  damageQuantity: FormControl<number | null>;
}

@Component({
  selector: 'app-product-edit',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    RouterLink,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatIconModule,
    CommonModule,
    FormsModule,
  ],
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss'],
})
export class ProductEditComponent extends BaseListComponent {
  currentTrip: MappedTrip | null = null;
  dataSource: FormGroup<ProductForm>;
  selectedItem!: {
    name: string;
    orderedQty: number;
  };
  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    private appState: AppStateService,
    public dialogRef: MatDialogRef<ProductEditComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      name: string;
      orderedQty: number;
    }
  ) {
    super(auth, router, route, PageId.logistics_trips);
    this.mapToCurrentTrip();
    this.selectedItem = this.data;
    this.dataSource = this.fb.group({
      orderedQty: new FormControl(this.selectedItem.orderedQty || 0), // Initial value from data
      availableQuantity: new FormControl(0, [
        Validators.required,
        integerValidator,
      ]),
      damageQuantity: new FormControl(0, [
        Validators.required,
        integerValidator,
      ]),
    });
  }

  override async ngOnInit() {
    super.ngOnInit();

    // Check if requestedQuantity exists before patching
    if (this.dataSource.controls.orderedQty) {
      this.dataSource.patchValue({
        orderedQty: this.dataSource.value.orderedQty, // Assuming requestedQuantity exists
      });
    }
  }

  private mapToCurrentTrip() {
    this.appState.selectedTrip$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((trip) => {
        if (trip) {
          this.currentTrip = trip;
        } else {
          const localStorageTrip: string | null =
            localStorage.getItem('tripDetails');
          const trip = localStorageTrip && JSON.parse(localStorageTrip);
          this.appState.setSelectedTrip(trip);
          this.currentTrip = trip;
        }
      });
  }

  save() {
    const orderQty = parseFloat(
      this.dataSource.value.orderedQty?.toString() || `0`
    );

    const availableQty = parseFloat(
      this.dataSource.value.availableQuantity?.toString() || `0`
    );
    const damageQty = parseFloat(
      this.dataSource.value.damageQuantity?.toString() || `0`
    );

    if (orderQty <= availableQty) {
      Swal.fire(
        '',
        'Available qty value should be lesser than ordered qty value',
        'warning'
      );

      return;
    }

    this.dialogRef.close({
      availableQty,
      damageQty,
    });
  }
}
