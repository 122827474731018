import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Auth } from '@angular/fire/auth';
import { AuthService } from '../../../services/auth.service';
import { lastValueFrom } from 'rxjs';
import { FirestoreService } from '../../../services/firestore.service';
import { DB_PATH } from '../../../constants/db-path';

@Injectable({
  providedIn: 'root',
})
export class DeepLinksService {
  auth: Auth = inject(Auth);
  constructor(
    public http: HttpClient,
    public authService: AuthService,
    public firestoreService: FirestoreService
  ) {}
  async getCategories(searchKey: string): Promise<any> {
    const res = await lastValueFrom(
      this.http.get<any>(
        `${environment.backendUrl}/categories/?name=${searchKey}&level=1`
      )
    );
    return res;
  }
  async getBrands(searchKey: string): Promise<any> {
    const res = await lastValueFrom(
      this.http.get<any>(`${environment.backendUrl}/brands/?name=${searchKey}`)
    );
    return res;
  }
  async getSkus(searchKey: string): Promise<any> {
    const res = await lastValueFrom(
      this.http.get<any>(`${environment.backendUrl}/skus/?name=${searchKey}`)
    );
    return res;
  }
  async createDeepLink(body: any): Promise<any> {
    const res = await lastValueFrom(
      this.http.post<any>(`${environment.coreServiceUrl}/api/deep_link`, body)
    );
    return res;
  }
  async insertDeepLink(body: any) {
    return await this.firestoreService.insertDocWithId(
      DB_PATH.DEEP_LINKS,
      body.id,
      body
    );
  }
  async getAllDeeplinks(): Promise<any> {
    const response = await this.firestoreService.fetchCollection(
      DB_PATH.DEEP_LINKS
    );
    return this.mapToDeepLinks(response);
  }

  mapToDeepLinks(deepLinks: Array<any> = []) {
    return deepLinks
      .map((deeplink) => {
        return {
          id: deeplink.id || '',
          description: deeplink.Description || '',
          createdAt: deeplink.Created_at,
          link: deeplink.Link,
          type: deeplink.Type,
          title: deeplink.Title,
          data: deeplink.Data,
        };
      })
      .sort((a, b) => b.createdAt - a.createdAt);
  }
}
