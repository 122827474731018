import { Component, ElementRef, HostListener } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { CommonModule, formatDate } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AuthService } from '../../../../services/auth.service';
import {
  applyMaxLengthValidation,
  hasSpecialCharacters,
  isValidGeoJsonFile,
  nameValidate,
  supplierListSearchInput,
  validateGST,
  validateMaxLength,
  validateMaxText,
  validateNegativeValues,
  validateNoNegativeValues,
  validateNumbersOnly,
  validatePinCode,
  validateSpecialCharacters,
  validateText,
} from '../../../../utlity/utility';
import imageCompression from 'browser-image-compression';
import { ConfigService } from '../../../config/services/config.service';
import { MESSAGE_QUEUE_TYPE } from '../../../../constants/message-queue-types';
import { Auth, User, authState } from '@angular/fire/auth';
import { BehaviorSubject } from 'rxjs';
import { VendorGeoMappingService } from '../../services/vendor-geo-mapping.service';
import { MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-upload-location',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    RouterLink,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatCheckboxModule,
  ],
  templateUrl: './upload-location.component.html',
  styleUrl: './upload-location.component.scss',
})
export class UploadLocationComponent {
  page_id = 'bms_create_suppliers';
  permission: any = false;
  dataSource: any;
  selectedFiles: any | null;
  jsonGeoData: any;
  txtJson: string = '';
  id: any;
  userSubject = new BehaviorSubject<User | null>(null);
  displayedColumns = [
    'locations',
    'company',
    'action'
  ]

  constructor(
    public fb: FormBuilder,
    public route: ActivatedRoute,
    public router: Router,
    public auth: AuthService,
    private el: ElementRef,
    public dialogRef: MatDialogRef<UploadLocationComponent>,
    public vendorGeoMappingService: VendorGeoMappingService,

    private auth1: Auth,
    private configService: ConfigService
  ) {
    this.dataSource = this.fb.group({
      coordinates: [null],
    });

  }

  onSelectFile(evnt: any) {
    const file: any = evnt.target.files[0];
    if (!file) {

      Swal.fire('Warning!', 'Please upload the file!', 'warning');
      this.resetFileInput();
      return;
    }
    if (file && !isValidGeoJsonFile(file.name)) {
      Swal.fire('Warning!', 'Invalid File Format!!', 'warning');
      this.resetFileInput();
      return;
    }

    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (event: ProgressEvent) => {
      try {
        this.txtJson = (event.target as FileReader).result?.toString() || '{}';
        const validateJsonFeatures = JSON.parse(this.txtJson);

        this.jsonGeoData = validateJsonFeatures;
        this.selectedFiles = evnt.target.files

        console.log("this.selectedFiles ::::", this.jsonGeoData)

      } catch {
        Swal.fire('', 'Invalid File', 'error');
      }
    };
  }

  private resetFileInput() {
    this.selectedFiles = null;
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }


  addNewArea() {
    if (!this.selectedFiles) {
      Swal.fire('', 'Please add the Area Geo Json!', 'warning')
    }
    this.dialogRef.close({
      geoJson: this.jsonGeoData
    });
  }

}
