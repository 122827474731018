import { IncentiveSlab } from './../../models/incentiveslabs.model';
import { Component, ViewChild } from '@angular/core';
import { IncentiveSlabEntity } from '../../models/incentiveslabs.model';
import { IncentiveSlabsService } from '../../services/incentiveslabs.service';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSidenavModule } from '@angular/material/sidenav';
import Swal from 'sweetalert2';
import { AppLoaderService } from '../../../../shared/app-loader/app-loader.service';
import { AuthService } from '../../../../services/auth.service';
import { SwalMessageTypes, SupplierStatus } from '../../../../enums/enums';
import { BaseListComponent } from '../../../../shared/core/base.list.component';
import { PageId } from '../../../../constants/enums';


@Component({
  selector: 'app-incentiveslabs-list',
  standalone: true,
  imports: [MatCardModule, MatButtonModule, MatTableModule, RouterLink, MatInputModule, MatFormFieldModule, ReactiveFormsModule,
    MatSelectModule, MatIconModule, CommonModule, FormsModule, MatDatepickerModule, MatRadioModule, MatNativeDateModule, MatPaginatorModule, MatSidenavModule],
  templateUrl: './incentiveslabs-list.component.html',
  styleUrls: ['./incentiveslabs-list.component.scss']
})
export class IncentiveSlabListComponent extends BaseListComponent {
  singularTypes = Object.values(SupplierStatus).filter(value => typeof value === 'number');
  incentiveslabs: IncentiveSlabEntity[] = [];
  totalIncentiveSlabs: number = 0;
  form: FormGroup;

  displayedColumns = [
    // 'incentiveSlabId',
    'incentiveSlabName',
    'visitsCountMin',
    'visitsCountMax',
    'dayRevenuePercentMin',
    'dayRevenuePercentMax',
    'dayTasksRevenuePercentMin',
    'dayTasksRevenuePercentMax',
    'buyingCountPercentMin',
    'buyingCountPercentMax',
    'nonBuyingCountPercentMin',
    'nonBuyingCountPercentMax',
    'incentiveBuyingOrder',
    'incentiveNonBuyingOrder',
    'petrolAllowance',
    'status',
    'action',
    // 'editAction'
  ];

  dataSource!: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(public readonly incentiveSlabService: IncentiveSlabsService,
    route: ActivatedRoute,
    router: Router,
    private loader: AppLoaderService,
    auth: AuthService,
    private fb: FormBuilder) {
    super(auth, router, route, PageId.bms_incentives);
    this.form = this.fb.group({
    });
  }

  override async ngOnInit() {
    await super.ngOnInit();
    await this.getIncentiveSlabs();
  }

  async getIncentiveSlabs() {
    try {
      this.loader.open();
      const response = await this.incentiveSlabService.getIncentiveSlabs();
      this.dataSource = new MatTableDataSource<any>(response["incentiveSlabList"]);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } catch (error) {
      this.loader.close();
      this.showMessage('Failed to Fetch IncentiveSlabs. Please try again later.', SwalMessageTypes.Error);
    } finally {
      this.loader.close();
    }
  }
  editIncentiveSlab(row: any) {
    const id = row.incentiveSlabId
    this.router.navigateByUrl(`/dashboard/incentives/edit-incentive/${id}`)
  }

  async deleteIncentiveSlab(row: any){
    try {
      const id = row.incentiveSlabId;
    const result = await Swal.fire({
      title: 'Do you want to Delete Incentive ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    });

    if (!result.isConfirmed) {
      return;
    }

    await this.incentiveSlabService.deleteIncentiveSlab(id);
    await this.getIncentiveSlabs();
    } catch (error) {
      console.error('Error occurred while deleting supplier:');
      this.showMessage('Failed to Delete Incentive. Please try again later.', SwalMessageTypes.Error);
    }
  }


  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }
  // onPageChange(event: any){

  // }

}
