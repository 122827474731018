@if(hasView){
<form [formGroup]="filterForm">
  <div class="d-flex align-items-center justify-content-between">
    <h1 mat-dialog-title>Filter </h1>
    <button mat-button (click)="close()" class="mx-2" mat-button color="warn">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <mat-form-field class="example-full-width" *ngIf="!isAttendance">
            <mat-label>Enter {{allowanceType}} Allowance</mat-label>
            <input type="number" matInput formControlName="allowance" />
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field class="full-width">
            <mat-label>Reason For Exception</mat-label>
            <mat-select formControlName="exception">
              <mat-option *ngFor="let reason of reasons" [value]="reason">{{ reason }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div mat-dialog-actions class="d-flex justify-content-end  ">
    @if(hasEdit){
    <button mat-button cdkFocusInitial (click)="submit()" mat-raised-button color="primary">Sumbit</button>
    }
  </div>
</form>
} @else {
<ng-container>
  <p class="text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
}
