@if(hasCreate){
<ng-container>
  <div class="heading-block">
    <div class="row">
      <div class="col-md-6">
        <mat-icon class="backicon" [routerLink]="['/dashboard/logistics/resource-list']">arrow_back</mat-icon>
        <span class="header">
          <h2 class="card-title m-3">Create Resource</h2>
        </span>
      </div>
      <div class="col-md-6 text-right"></div>
    </div>
  </div>

  <div class="table-block">
    <form [formGroup]="dataSource">
      <section id="content">
        <div class="card">
          <div class="card-body card-padding">
            <div class="row">
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Resource Name*</mat-label>
                  <input matInput type="string" formControlName="name" name="name" placeholder="Resource Name" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Mobile Number*</mat-label>
                  <input matInput type="number" formControlName="mobileNumber" name="mobileNumber"
                    placeholder="Mobile Number" [maxLength]="10" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Email*</mat-label>
                  <input matInput type="email" formControlName="email" name="email" placeholder="Email" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Password*</mat-label>
                  <input matInput type="string" formControlName="password" name="password" placeholder="Password" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Address*</mat-label>
                  <input matInput type="string" formControlName="address" name="address" placeholder="Address" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>City*</mat-label>
                  <input matInput type="string" formControlName="city" name="city" placeholder="City" />
                </mat-form-field>
              </div>

              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Area Name*</mat-label>
                  <input matInput type="string" formControlName="areaName" name="areaName" placeholder="Area Name" />
                </mat-form-field>
              </div>

              <div class="col-md-6">
              <mat-form-field class="full-width">
                <mat-label>LandMark*</mat-label>
                <input matInput type="string" formControlName="landMark" name="landMark" placeholder="LandMark" />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <mat-label>Country*</mat-label>
                <input matInput type="string" formControlName="country" name="country" placeholder="Country" />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <mat-label>State*</mat-label>
                <mat-select formControlName="state">
                  @for (state of states; track state) {
                  <mat-option [value]="state.name">{{
                    state.name
                    }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <mat-label>Pin Code*</mat-label>
                <input matInput type="number" formControlName="pinCode" name="pinCode" placeholder="Pin Code"
                  [maxlength]="6" />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <mat-label>Latitude*</mat-label>
                <input matInput formControlName="latitude" name="latitude" placeholder="Latitude" type="string" />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <mat-label>Longitude*</mat-label>
                <input matInput formControlName="longitude" name="longitude" placeholder="Longitude" type="string" />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <mat-label>Supplier Name*</mat-label>
                <mat-select formControlName="supplierName">
                  <mat-option *ngFor="let item of supplierName" [value]="item.value">
                    {{ item.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <mat-label>Aadhar Number</mat-label>
                <input matInput formControlName="aadharNumber" name="aadharNumber" type="string"
                  placeholder="Aadhar Number" />
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <mat-label>Upload Aadhar</mat-label>
              <input formControlName="aadharCopy" class="form-control" (change)="onFileChange($event, 'aadhar')"
                type="file" />
            </div>

            <div class="col-md-6">
              <mat-form-field class="full-width">
                <mat-label>Pan Number</mat-label>
                <input matInput formControlName="panNumber" name="panNumber" placeholder="Pan Number"
                  (input)="toUppercase($event)" />
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <mat-label>Upload Pan</mat-label>
              <input formControlName="panCopy" class="form-control" (change)="onFileChange($event, 'pan')"
                type="file" />
            </div>

            <div class="col-md-6">
              <mat-form-field class="full-width">
                <mat-label>GST Number</mat-label>
                <input matInput formControlName="gstNumber" name="gstNumber" placeholder="GST Number"
                  (input)="toUppercase($event)" />
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <mat-label>Upload GST</mat-label>
              <input formControlName="gstCopy" class="form-control" (change)="onFileChange($event, 'gst')"
                type="file" />
            </div>

            <div class="col-md-6">
              <mat-form-field class="full-width">
                <mat-label>Supplier Type*</mat-label>
                <mat-select formControlName="supplierType">
                  <mat-option *ngFor="let type of supplierTypes" [value]="type">
                    {{ supplierTypeNames[type] }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <mat-label>Tcs Percentage*</mat-label>
                <input matInput type="string" formControlName="tcsPercentage" name="tcsPercentage"
                  placeholder="Tcs Percentage" />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <mat-label>Contract Mode*</mat-label>
                <mat-select formControlName="contractMode">
                  <mat-option *ngFor="let type of resourceContractMode" [value]="type">
                    {{ type === 1 ? "Fixed" : "Adhoc" }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <mat-label>Designation*</mat-label>
                <mat-select formControlName="designation">
                  <mat-option *ngFor="let option of designation" [value]="option.id">
                    {{ option.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <mat-label>Status*</mat-label>
                <mat-select formControlName="status">
                  <mat-option *ngFor="let type of resourceStatus" [value]="type">
                    {{ type === 0 ? "InActive" : "Active" }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width locationinput">
                <mat-label>Location*</mat-label>
                <mat-select formControlName="location">
                  <mat-option *ngFor="let item of locations" [value]="item.id">
                    {{ item.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="buttonSection">
              @if(hasCreate){
              <button mat-raised-button color="primary" class="text-center ml" (click)="saveResource()">
                Save
              </button>
              }
            </div>
          </div>
        </div>
        </div>

  <!-- </div> -->
  </section>
  </form>
  </div>
</ng-container>
} @else {
<ng-container>
  <p class="text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
}
