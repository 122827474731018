import { Component } from '@angular/core';
import {
  FormBuilder,
  Validators,
  ReactiveFormsModule,
  FormsModule,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { TargetsService } from '../../services/targets.service';
import {
  EditTarget,
  TargetsEntity,
  TargetsForm,
} from '../../models/targets.model';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AppLoaderService } from '../..//../../shared/app-loader/app-loader.service';
import { AuthService } from '../../../../services/auth.service';
import { SwalMessageTypes, SupplierStatus } from '../../../../enums/enums';
import Swal from 'sweetalert2';
import { BaseListComponent } from '../../../../shared/core/base.list.component';
import { PageId } from '../../../../constants/enums';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-edit-targets',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    RouterLink,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatCheckboxModule,
  ],
  templateUrl: './edit-targets.component.html',
  styleUrls: ['./edit-targets.component.scss'],
})
export class EditTargetsComponent extends BaseListComponent {
  singularTypes = Object.values(SupplierStatus).filter(
    (value) => typeof value === 'number'
  );

  dataSource: any;
  id: string = '';
  date: string = '';
  targetsEntity!: TargetsEntity;

  constructor(
    private fb: FormBuilder,
    router: Router,
    route: ActivatedRoute,
    private targetsService: TargetsService,
    private loader: AppLoaderService,
    auth: AuthService
  ) {
    super(auth, router, route, PageId.bms_targets);
  }

  override async ngOnInit() {
    await super.ngOnInit();
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe(async (params) => {
      this.id = params['id'];
      this.date = params['date'];
      await this.getTargetsById();
    });
    this.editForms();
  }

  editForms() {
    this.dataSource = this.fb.group({
      buyingTarget: [null, [Validators.required]],
      nonBuyingTarget: [null, [Validators.required]],
      dayRevenueTarget: [null, [Validators.required]],
      taskRevenueTargetPercentage: [null, [Validators.required]],
    });
  }
  async getTargetsById() {
    try {
      this.loader.open();
      const response = await this.targetsService.getTargetsById(
        this.id,
        this.date
      );
      this.dataSource = await this.mapToEditInput(response.data);
    } catch (error) {
      this.showMessage(
        'Failed to Get targets. Please try again later.',
        SwalMessageTypes.Error
      );
    } finally {
      this.loader.close();
    }
  }

  async updateTargets() {
    this.loader.open();

    try {
      if (this.dataSource.invalid) {
        this.showMessage(
          'Please fill all required fields.',
          SwalMessageTypes.Warning
        );

        return;
      }

      if (!this.validations()) {
        return
      }

      await this.updateTargetsById();
    } catch (error) {
      console.error('Error occurred during targets creation:', error);
      this.showMessage(
        'Failed to create targets. Please try again later.',
        SwalMessageTypes.Error
      );
    } finally {
      this.loader.close();
    }
  }

  hasDecimal(number: number) {
    return /\./.test(number.toString());
  }


  private validations(): boolean {
    const negativeFields = this.validateNegativeValues(this.dataSource.value);

    if (negativeFields.length) {
      this.showMessage(
        `${negativeFields} ,have negative values Please Check`,
        SwalMessageTypes.Warning
      );

      return false;
    }


    if (this.hasDecimal(this.dataSource.value.nonBuyingTarget)) {
      this.showMessage(
        `Non BuyingTarget should not have decimal value`,
        SwalMessageTypes.Warning
      );
      return false
    }

    if (this.hasDecimal(this.dataSource.value.buyingTarget)) {
      this.showMessage(
        `BuyingTarget should not have decimal value`,
        SwalMessageTypes.Warning
      );
      return false
    }

    if (this.hasDecimal(this.dataSource.value.taskRevenueTargetPercentage)) {
      this.showMessage(
        `Task Revenue Target Percentage should not have decimal value`,
        SwalMessageTypes.Warning
      );
      return false
    }

    return true
  }



  private validateNegativeValues(data: EditTarget): string[] {
    const numericInputs = [
      { name: 'Buying Target', value: data.buyingTarget },
      { name: 'Non Buying Target', value: data.nonBuyingTarget },
      { name: 'Day Revenue Target', value: data.dayRevenueTarget },
      {
        name: 'Task Revenue Target Percentage',
        value: data.taskRevenueTargetPercentage,
      },
    ];

    const negativeFields = numericInputs
      .filter((input) => input.value < 0)
      .map((input) => input.name);

    return negativeFields;
  }

  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  private mapTargets(targets: TargetsForm): TargetsEntity {
    const form = targets;
    return {
      buying_count_target: +form.buyingTarget,
      non_buying_count_target: +form.nonBuyingTarget,
      day_revenue_target: +form.dayRevenueTarget,
      day_task_revenue_target_percentage: +form.taskRevenueTargetPercentage,
      day_tasks_revenue_target:(+form.dayRevenueTarget * +form.taskRevenueTargetPercentage)/100
    };
  }

  async updateTargetsById() {
    const targetsEntity = this.mapTargets(this.dataSource.value);
    try {
      const response = await this.targetsService.updateTargets(
        targetsEntity,
        this.id,
        this.date
      );
      this.showMessage(
        'Targets updated successfully',
        SwalMessageTypes.Success
      );
      this.router.navigateByUrl('/dashboard/targets/targets-list');
    } catch (error) {
      console.error('Error occurred while creating targets:', error);
      this.showMessage(
        'Failed to create targets. Please try again later.',
        SwalMessageTypes.Error
      );
    }
  }

  private mapToEditInput(targets: any): FormGroup {
    const entity = targets;
    return this.fb.group({
      buyingTarget: new FormControl(entity.buying_count_target || '', [
        Validators.required,
      ]),
      nonBuyingTarget: new FormControl(entity.non_buying_count_target || '', [
        Validators.required,
      ]),
      dayRevenueTarget: new FormControl(entity.day_revenue_target || '', [
        Validators.required,
      ]),
      taskRevenueTargetPercentage: new FormControl(
        entity.day_task_revenue_target_percentage || '',
        [Validators.required]
      ),
    });
  }
  async validateInput() { }
}
