import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { VarientValueService } from '../services/varientValue.service';
import { MatSelectModule } from '@angular/material/select';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatIconModule } from '@angular/material/icon';
import { AuthService } from '../../../services/auth.service';
import { ProductService } from '../../product/services/product.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from '../../../services/common.service';
@Component({
  selector: 'app-varientValue-add-edit',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    RouterLink,
    ReactiveFormsModule,
    MatSelectModule,
    NgSelectModule,
    MatIconModule
  ],
  templateUrl: './varientValue-add-edit.component.html',
  styleUrl: './varientValue-add-edit.component.scss',
})
export class VarientValueAddEditComponent {
  varientValueForm: any;
  filename: any;
  previewImage: any;
  requestImageBody: any;
  id: any;
  companyList:any=[]
  categoriesOption:any=[]
  variants_id=""
  comment:any=""
  constructor(
    public fb: FormBuilder,
    public route: ActivatedRoute,
    public router: Router,
    public varientValueService: VarientValueService,
    public location: Location,
    public authService:AuthService,
    public productService:ProductService,
    public commonService: CommonService
  ) {
    this.varientValueForm = this.fb.group({
      variants_id: [null,[Validators.required]],
      name: [null,[Validators.required]],
    });
  }
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.variants_id=params['variant_types_id']
      this.varientValueForm.patchValue({variants_id:this.variants_id})
      this.id = params['id'];
      if (this.id) {
        this.patchValue();
      }
    });
  }
  async patchValue() {
    this.commonService.showLoder()
    if(this.authService.isAdmin()){
      let res: any = (await this.varientValueService.getSingle(this.id) as any).data[0].attributes
    this.varientValueForm.patchValue(res);
    this.previewImage = res.image_url;
    }
    if(this.authService.isVendor()){
      this.productService.getSingleApprovalDoc(this.id).subscribe((docData:any)=>{
        let res: any = docData.payload
        this.varientValueForm.patchValue(res);
        this.previewImage = res.image_url;
        this.comment=docData?.comment
      },
      (error:any)=>{
        this.commonService.showToaster("error",error.message)
        this.commonService.hideLoder()
      })

    }
    this.commonService.hideLoder()
  }
  async submit() {
    this.commonService.showLoder()
    try {
      try {
        if (this.id) {
          if(this.authService.isAdmin()){
            await this.varientValueService.update(this.id, this.varientValueForm.value);
            this.commonService.showToaster("success",'Updated Successfully')
            this.router.navigateByUrl(`/dashboard/variantValue/${this.variants_id}`);
          }
          if(this.authService.isVendor()){
            this.productService.reSubmitRequestForApproval(this.id,this.varientValueForm.value)
            this.commonService.showToaster("success",'Your request is submitted for approval')
            this.router.navigateByUrl('/dashboard/my-requests');
          }
        } else {
          if(this.authService.isAdmin()){
            await this.varientValueService.add(this.varientValueForm.value);
            this.commonService.showToaster("success",'Added Successfully')
            this.router.navigateByUrl(`/dashboard/variantValue/${this.variants_id}`);
          }
          if(this.authService.isVendor()){
            this.productService.addToPendingApproval("add-variant-value",this.varientValueForm.value,"pending-approval")
            this.commonService.showToaster("success",'Your request is submitted for approval')
            this.router.navigateByUrl('/dashboard/my-requests');
          }
         
        }
        this.varientValueForm.reset();
        this.commonService.hideLoder()
        
      } catch (err:any) {
        console.log(err);
        this.commonService.showToaster("error",err.error.message)
        this.commonService.hideLoder()
      }
    } catch (err:any) {
      console.log(err);
      this.commonService.showToaster("error",err.error.message)
      this.commonService.hideLoder()
    }
  }

  back() {
    this.location.back();
  }





}
