<ng-container>
  <div class="container-fluid p-2">
    <mat-card class="p-3">
      <div>
        <p class="p-2">/Vendor Geo Mapping</p>
      </div>
      <div class="mat-elevation-z8">
        <div>
          <div class="row filters">
            <form [formGroup]="form">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <mat-form-field appearance="outline" class="w-100">
                      <input input type="number" maxlength="10" matInput placeholder="Mobile Number"
                        formControlName="mobileNumber">
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class="col-md-12 custom-flex">
                <div class="text-center mt-2 flex-item">
                  <button mat-raised-button color="primary" class="text-center ml" (click)="searchMerchant()">
                    Search
                  </button>
                </div>
                <div class="text-center mt-2 flex-item">
                  <button mat-raised-button color="primary" class="text-center ml-w" (click)="resetForm()">
                    Clear Filters
                  </button>
                </div>
              </div>
            </form>

            <ng-container *ngIf='dataSource'>
              <div class="table-block">
                <div class="m-1 flex flex-col md:flex-row justify-between">
                  <mat-card class="p-0">
                    <mat-card-content class="p-0">
                      <mat-sidenav-container fxFlex="0 1 auto">
                        <mat-sidenav-content>
                          <div class="table-container">
                            <mat-table [dataSource]="dataSource" matSort>
                              <ng-container matColumnDef="businessName">
                                <mat-header-cell *matHeaderCellDef>
                                  Business Name
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row" data-label="businessName">
                                  {{ row.businessName }}
                                </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="mobileNumber">
                                <mat-header-cell *matHeaderCellDef>
                                  Mobile Number
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row" data-label="mobileNumber">
                                  {{ row.mobileNumber }}
                                </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="businessType">
                                <mat-header-cell *matHeaderCellDef>
                                  Business Type
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row" data-label="businessType">
                                  {{ getBusinessTypeName(row.businessType) }}
                                </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="address">
                                <mat-header-cell *matHeaderCellDef>
                                  D-No/Street
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row" data-label="address">
                                  {{ row.address }}
                                </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="state">
                                <mat-header-cell *matHeaderCellDef>
                                  State
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row" data-label="state">
                                  {{ row.state }}
                                </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="city">
                                <mat-header-cell *matHeaderCellDef>
                                  City
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row" data-label="city">
                                  {{ row.city }}
                                </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="action">
                                <mat-header-cell *matHeaderCellDef>
                                  Action
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                  <button mat-raised-button
                                    [routerLink]="['/dashboard/vendor-geo-mapping/add-vendor-geo-mapping', row.id]"
                                    color="primary">
                                    <mat-icon class="me-0">edit</mat-icon>
                                  </button>
                                </mat-cell>
                              </ng-container>

                              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                              <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                            </mat-table>
                          </div>
                        </mat-sidenav-content>
                      </mat-sidenav-container>
                    </mat-card-content>
                  </mat-card>
                </div>

              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</ng-container>