import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RouterLink } from '@angular/router';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CommonModule } from '@angular/common';
import Swal from 'sweetalert2';
import { getProviders, isEmptyValue } from '../../../../../utlity/utility';
import { ResourcesService } from '../../../resource/services/resources.service';
import { LookUpType, ResourceStatus, SwalMessageTypes } from '../../../../../enums/enums';
import { ResourceListSearch } from '../../../resource/models/resources.model';
import { LookUpTypeService } from '../../../../../services/lookup-type.service';

@Component({
  selector: 'app-trip-search-popup',
  standalone: true,
  imports: [MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    RouterLink,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    MatTableModule,
    MatTableModule,
    MatSidenavModule,
    MatPaginatorModule,
    CommonModule],
  templateUrl: './trip-search-popup.component.html',
  styleUrl: './trip-search-popup.component.scss'
})
export class TripSearchPopupComponent {

  dataSource: any;
  fieldvalue: string;
  tripId: string;
  searchForm: FormGroup;
  resourceListSearchInput: ResourceListSearch = this.initializeSearchInput();
  vehicleLookType: LookUpType =  this.initializeLookUpTypeSearchInput();
  public vehicles: any[] = [];

  public extractedData: any[] = [];
  constructor(public dialogRef: MatDialogRef<TripSearchPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog,
   private fb: FormBuilder, private resourceService: ResourcesService, private lookupService : LookUpTypeService) {
    console.log("TripSearchPopupComponent::data", data);
    this.fieldvalue= data.fieldValue;
    this.tripId = data.tripId;
    this.searchForm = this.fb.group({
      fieldName: [this.data.fieldValue, [Validators.required]]
    });
  }

  async ngOnInit(): Promise<void> {
  await this.getResource();
  }

  async submit(): Promise<void> {
    const enteredValue = this.searchForm.value.fieldName;

    if(this.data.fieldName === 'DE Name'){
      const body = {
        "deliveryAgentId": enteredValue.id
      }
      await this.resourceService.assignDeliveyAgent(this.tripId, body)
    }
    if(this.data.fieldName === 'Driver Name'){
      const body = {
        "driverId": enteredValue.id
      }
      await this.resourceService.assignDriver(this.tripId, body)
    }
    if(this.data.fieldName === 'Vehicle Number'){
      const body = {
        "vehicleId": enteredValue.id
      }
      await this.resourceService.assignVehicleNumber(this.tripId, body)
    }
    this.dialogRef.close(enteredValue.name);
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  assignListName(dataSource: any){
    this.extractedData = dataSource.map((name: { name: any; id: any }) =>
      {
       return {
         id: name.id,
         name: name.name
       } }
     );
  }

  async getResource(): Promise<any> {
    try {

      if(this.data.fieldName === 'DE Name'){
        this.resourceListSearchInput.designation = 3;
        const response = await this.resourceService.getResources(this.resourceListSearchInput);
        this.dataSource = response['resourceList'];
      this.assignListName(this.dataSource)
      }
      if(this.data.fieldName === 'Driver Name'){
        this.resourceListSearchInput.designation = 4;
        const response = await this.resourceService.getResources(this.resourceListSearchInput);
        this.dataSource = response['resourceList'];
        this.assignListName(this.dataSource)
        }
        if(this.data.fieldName === 'Vehicle Number'){
      this.getVehicles();
          }
    } catch (error) {
      this.showMessage('Failed to Fetch Resources. Please try again later.', SwalMessageTypes.Warning);
    }
  }


  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  async getVehicles(){
    const response = await this.lookupService.getLookUpTypes(this.vehicleLookType);
    this.vehicles = response.data[0].data;
    this.extractedData = this.vehicles.map((vehicleName: {  id: any; value: any; }) =>
      {
       return {
         id: vehicleName.id,
         name: vehicleName.value
       } }
     );
  }

  private initializeSearchInput(): ResourceListSearch {
    return {
      designation: null,
      mobileNumber: '',
      status: ResourceStatus.Active,
      pageNumber: 1,
      pageSize: 100,
    };
  }

  private initializeLookUpTypeSearchInput(): LookUpType {
    return {
      tableName: "vehicle",
      lookupType: "vehicle",
      idColumnName: "id",
      valueColumnName: "vehicle_number"
    };
  }


}
