<div class="container">
  <ng-container>
    <div class="row">
      <div class="col-md-6">
        <h2 class="card-title m-3 text-violet">Upload JSON</h2>
      </div>
      <div class="col-md-6 d-flex justify-content-end">
        <mat-icon (click)="dialogRef.close()" class=" m-3 closeicon">close</mat-icon>
      </div>
    </div>
    <div class="row">
      <section id="content">
        <div class="container">
          <mat-card>
            <mat-card-content>
              <div class="row">
                <div class="col-xs-12">
                  <input type="file" accept=".geojson" value.bind="fileVal" files.bind="selectedFiles"
                    (change)="onSelectFile($event)" class="m-10" id="fileInput" />
                  <button mat-raised-button color="primary" class="ml-20" (click)="updateCoordinates()">
                    Upload Cordinates
                  </button>
                  <label class="errorMessage" *ngIf="isGeoJsonNotSingle">Upload Only one geo Polygon At a
                    time.</label>
                  <label class="errorMessage" *ngIf="isFileSelected">Please Select Json File To Upload</label>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </section>
    </div>
  </ng-container>
</div>