import { inject, Injectable } from '@angular/core';
import {
  ResourceEntity,
  ResourceImageResponse,
  ResourceListSearch,
  createResourceResponse,
} from '../models/resources.model';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import {
  collection,
  collectionData,
  doc,
  Firestore,
  query,
  setDoc,
} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class ResourcesService {
  baseServiceUrl: string = `${environment.logisticsUrl}`;
  // baseServiceUrl: string = "http://localhost:4013";
  logisticsUrl: string = `${environment.logisticsUrl}`;
  fileServicesApiUrl: string = `${environment.fileUploadBaseUrl}`;
  firestore: Firestore = inject(Firestore);
  constructor(public http: HttpClient) {}

  async createResource(data: ResourceEntity) {
    let response = await lastValueFrom(
      this.http.post<{ res: createResourceResponse }>(
        `${this.baseServiceUrl}/api/resource/create`,
        data
      )
    );

    return response.res;
  }

  async getResources(body: ResourceListSearch): Promise<any> {
    const res = await lastValueFrom(
      this.http.post<any>(
        `${this.baseServiceUrl}/api/resource/get_by_filters`,
        body
      )
    );

    if (res && res.data) {
      return {
        resourceList: res.data.rows,
        totalResources: res.data.count,
      };
    }
  }

  async getResourceById(id: string): Promise<any> {
    const res = await lastValueFrom(
      this.http.get<any>(`${this.baseServiceUrl}/api/resource/get_by_id/${id}`)
    );
    return res;
  }

  async updateResource(resourceEntity: ResourceEntity, resourceid: string) {
    let { id, ...resourceUpdatedEntity } = resourceEntity;
    let response = await lastValueFrom(
      this.http.post<{ res: createResourceResponse }>(
        `${this.baseServiceUrl}/api/resource/update/${resourceid}`,
        resourceUpdatedEntity
      )
    );
    return response.res;
  }

  async deleteResource(id: string) {
    return await lastValueFrom(
      this.http.delete(`${this.baseServiceUrl}/api/resource/delete/${id}`)
    );
  }
  async imageUpload(
    body: { fileName: string; fileExtension: string; fileData: any },
    urlEndpoint: string
  ) {
    let response = await lastValueFrom(
      this.http.put<{ res: ResourceImageResponse }>(
        `${this.fileServicesApiUrl}/documents/${urlEndpoint}`,
        body
      )
    );

    return response;
  }

  async assignDeliveyAgent(tripId: string, daId: any) {
    let response = await lastValueFrom(
      this.http.put<any>(
        `${this.baseServiceUrl}/api/trips/${tripId}/delivery_agent`,
        daId
      )
    );
    return response;
  }

  async assignDriver(tripId: string, driverId: any) {
    let response = await lastValueFrom(
      this.http.put<any>(
        `${this.baseServiceUrl}/api/trips/${tripId}/driver`,
        driverId
      )
    );
    return response;
  }

  async assignVehicleNumber(tripId: string, vehicleNumber: any) {
    let response = await lastValueFrom(
      this.http.put<any>(
        `${this.baseServiceUrl}/api/trips/${tripId}/vehicle`,
        vehicleNumber
      )
    );
    return response;
  }
  async addUsers(data: any): Promise<any> {
    data.timestamp = new Date();
    try {
      let uid = '';
      let userData = await this.getAuthUserByEmailId(data.email_id);
      if (!(userData && userData?.response)) {
        const headers = new HttpHeaders().set('No-Auth', 'true');

        let functionCall$ = this.http.post(
          `${environment.cloudFunctionsUrl}/createUser`,
          {
            email: data.email_id,
            password: data.password,
            customId: data.customId,
          },
          { headers }
        );
        const res: any = await lastValueFrom(functionCall$);
        console.log('res :', res);
        uid = res.userRecord?.userRecord?.uid;
      } else {
        uid = userData.response?.uid;
      }

      const aDoccument = doc(this.firestore, `Users/${uid}`);
      delete data.password;
      await setDoc(aDoccument, data);
      return uid;
    } catch (err) {
      console.log(err);
    }
  }
  async getAuthUserByEmailId(emailId: string): Promise<any> {
    try {
      const headers = new HttpHeaders().set('No-Auth', 'true');
      let functionCall$ = this.http.get(
        `${environment.cloudFunctionsUrl}/get_auth_user_by_email_id?emailid=${emailId}`,
        { headers }
      );
      const userResult: any = await lastValueFrom(functionCall$);
      if (userResult && userResult.response) {
        return userResult;
      } else {
        return {};
      }
    } catch {
      return {};
    }
  }

  getAllUsers() {
    const aCollection = collection(this.firestore, 'Users');
    let q = query(aCollection);
    let items$ = collectionData(q, { idField: 'id' });
    return items$;
  }
}
