import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { LookUpType } from "../enums/enums";
import { lastValueFrom } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class LookUpTypeService {
  baseServiceUrl: string = `${environment.coreServiceUrl}`;
  constructor(private http: HttpClient) { }

  async getLookUpTypes(body: LookUpType): Promise<any> {
    try {
      const url = `${this.baseServiceUrl}/lookups/fetch`;
      const res = await lastValueFrom(this.http.post<any>(url, body));
      return res;

    } catch (error) {
      console.error('Error fetching lookup types:', error);
    }

  }
}
