import { Component } from '@angular/core';
import { FormBuilder, Validators, ReactiveFormsModule, FormsModule, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ResourcesService } from '../../services/resources.service';
import { ResourceEntity, ResourceForm, PropertyValidation, MultiPropertyValidation, ValidationResult, validateName } from '../../models/resources.model';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AppLoaderService } from '../../../../../shared/app-loader/app-loader.service';
import { AuthService } from '../../../../../services/auth.service';
import { SwalMessageTypes, SupplierStatus, ContractMode, states, DesignationType, LookUpType, SupplierType, ResourceStatus, ResourceType } from '../../../../../enums/enums';
import Swal from 'sweetalert2';
import { applyMaxLengthValidation, hasSpecialCharacters, nameValidate, supplierListSearchInput, uuidv4, validateGST, validateMaxLength, validateMaxText, validateNegativeValues, validateNoNegativeValues, validatePanNumber, validatePinCode, validateSpecialCharacters, validateText } from '../../../../../utlity/utility';
import { UploadImageDialogComponent } from '../../../supplier/components/upload-image-dialog/upload-image-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ImageDialog, State } from '../../../supplier/models/supplier-model';
import imageCompression from 'browser-image-compression';
import { LookUpTypeService } from '../../../../../services/lookup-type.service';
import { BaseListComponent } from '../../../../../shared/core/base.list.component';
import { PageId } from '../../../../../constants/enums';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-edit-resource',
  standalone: true,
  imports: [MatCardModule, MatButtonModule, MatTableModule, RouterLink, MatInputModule,
    MatFormFieldModule, ReactiveFormsModule, MatSelectModule, MatIconModule,
    CommonModule, FormsModule, MatDatepickerModule, MatRadioModule, MatNativeDateModule,
    MatPaginatorModule, MatSidenavModule, MatDatepickerModule, MatCheckboxModule],
  templateUrl: './edit-resource.component.html',
  styleUrls: ['./edit-resource.component.scss']
})
export class EditResourceComponent  extends BaseListComponent{
  singularTypes = Object.values(SupplierType).filter(value => typeof value === 'number');
  resourceStatus = Object.values(ResourceStatus).filter(value => typeof value === 'number');
  resourceContractMode = Object.values(ContractMode).filter(value => typeof value === 'number');
  uploadedFiles: any = {};
  existingResource: any = [];
  dataSource: any;
  id: string = "";
  resourceEntity!: ResourceEntity;
  lookUpTypeLocation: LookUpType = this.initializeLocation();
  lookUpTypeDesignation: LookUpType = this.initializedesignation();
  lookUpTypeSuppplier: LookUpType = this.initializeSupplier();
  public designation: any[] = [];
  states: State[] = states;
  aadharCopy: string = "";
  panCopy: string = "";
  gstCopy: string = "";
  public locations: any[] = [];
  public suppliers: any[] = [];

  constructor(
    auth: AuthService,
    router: Router,
    private fb: FormBuilder,
    route: ActivatedRoute,
    private resourcesService: ResourcesService,
    private loader: AppLoaderService,
    private dialog: MatDialog,
    private resourceService:ResourcesService,
    private lookupService : LookUpTypeService) {
    super(auth, router,  route,PageId.logistics_resources);
                  this.dataSource = this.fb.group({
                    id: [ null, [Validators.required]],
                    name: [ null, [Validators.required]],
                    email: [null, [Validators.required]],
                    mobileNumber: [ null, [Validators.required]],
                    address: [ null, [Validators.required]],
                    areaName:[null, Validators.required],
                    landMark:[null, Validators.required],
                    country:[null, Validators.required],
                    latitude:[null, Validators.required],
                    longitude:[null, Validators.required],
                    city: [ null, [Validators.required]],
                    state: [ null, [Validators.required]],
                    pinCode: [ null, [Validators.required]],
                    designation: [ null, [Validators.required]],
                    supplierName: [ null, [Validators.required]],
                    panNumber:"",
                    panCopy:"",
                    aadharNumber: "",
                    aadharCopy:"",
                    gstNumber: "",
                    gstCopy:"",
                    supplierType:[null, Validators.required],
                    tcsPercentage:[null, Validators.required],
                    resourceType: [ null, [Validators.required]],
                    location: [ null, [Validators.required]],
                    contractMode: [ null, [Validators.required]],
                    status: [ null, [Validators.required]]
              });
              }

  override async ngOnInit() {
    await super.ngOnInit();
    this.route.params.
    pipe(takeUntil(this.unsubscribe$))
    .subscribe(async (params) => {
      this.id = params['id'];
      await this.getResourceById();
      this.fixMaxLength();
    });
    await this.getDesignation();
    await this.getLocation();
    await this.getSupplier();
  }
  async getLocation() {
    const response = await this.lookupService.getLookUpTypes(this.lookUpTypeLocation);
    this.locations = response.data[0].data;
  }


  async getSupplier() {
    const response = await this.lookupService.getLookUpTypes(this.lookUpTypeSuppplier);
    this.suppliers = response.data[0].data;
  }
  async getDesignation() {
    const response = await this.lookupService.getLookUpTypes(this.lookUpTypeDesignation);
    this.designation = response.data[0].data;
  }

  async getResourceById() {
    try {
      this.loader.open();
      const response = await this.resourcesService.getResourceById(this.id);
      this.dataSource = this.mapToEditInput(response.data);
      this.dataSource.patchValue({
        designation: this.dataSource.value.designation,
        status: this.dataSource.value.status
      });
      console.log("datasourceeeeee", this.dataSource);

    } catch (error) {
      this.showMessage('Failed to Get resource. Please try again later.', SwalMessageTypes.Error);
    } finally {
      this.loader.close();

    }
  }

  async updateResource() {
    this.loader.open();
    try {
      if (this.dataSource.invalid) {
        this.showMessage('Please fill all required fields.', SwalMessageTypes.Warning);

        return
      }
      const countryValue = this.dataSource.get('country').value;

      const validationResult = nameValidate([{ value: countryValue, field: 'country' }]);
      if (!validationResult.isValid) {
        console.log("Invalid country name:", countryValue);
        this.showMessage('Please enter a valid country name.', SwalMessageTypes.Warning);
        return;
      }

      await this.validateInput();
      await this.uploadFiles();
      await this.updateResourceById();
    } catch (error) {
    } finally {
      this.loader.close();
    }
  }

  async uploadFiles() {
    const documentTypes = ['aadhar', 'pan', 'gst'];
    const urlEndpoints = ['supplier_aadhaar', 'supplier_pan', 'supplier_gst'];

    await Promise.all(documentTypes.map(async (documentType, index) => {
      const urlEndpoint = urlEndpoints[index];

      if (this.uploadedFiles.hasOwnProperty(documentType)) {
        const requestImageBody = this.uploadedFiles[documentType];
        await this.uploadAndSetUrl(requestImageBody, documentType, urlEndpoint);
      }
    }));
  }
  async uploadAndSetUrl(requestImageBody: any, documentType: string, urlEndpoint: string) {
    try {
      const res: any = await this.resourceService.imageUpload(requestImageBody, urlEndpoint);
      switch (documentType) {
        case 'aadhar':
          this.aadharCopy = res.data.url;
          break;
        case 'pan':
          this.panCopy = res.data.url;
          break;
        case 'gst':
          this.gstCopy = res.data.url;
          break;
        default:
          console.error('Invalid document type');
          break;
      }

    } catch (error) {
      console.error('Error uploading file:', error);
    }
  }
  getFileExtension(filename: any) {
    return filename.split('.').pop();
  }

  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }
  toUppercase(event: any) {
    event.target.value = event.target.value.toUpperCase();
  }


  async updateResourceById() {
    const resourceEntity = this.mapUpdateResource(this.dataSource.value);
    const missingFields = this.checkMissingFields(resourceEntity);
    if (missingFields.length > 0) {
      this.showMessage(`Please fill both ${missingFields.join(' and ')} not only one.`, SwalMessageTypes.Warning);
      this.loader.close();
      return
    }
    try {
      const response = await this.resourcesService.updateResource(resourceEntity, this.id);
      this.showMessage('Resource Update successfully', SwalMessageTypes.Success);
      this.router.navigateByUrl('/dashboard/logistics/resource-list');
    } catch (error) {
      console.error('Error occurred while creating resource:', error);
      //this.showMessage('Failed to create resource. Please try again later.', SwalMessageTypes.Error);
    }
  }
  private mapUpdateResource(resource: ResourceForm): ResourceEntity {
    const mapForm = resource;
    console.log("mapUpdateResource::",resource);
    return {
      id: mapForm?.id,
      name: mapForm?.name,
      mobileNumber: mapForm?.mobileNumber,
      address: mapForm?.address,
      city: mapForm?.city,
      state: mapForm?.state,
      pinCode: mapForm?.pinCode,
      areaName: mapForm?.areaName,
      landMark: mapForm?.landMark,
      country: mapForm?.country,
      latitude: mapForm?.latitude,
      longitude: mapForm?.longitude,
      panNumber: mapForm?.panNumber,
      panCopy: this.panCopy || mapForm?.panCopy || "",
      aadharNumber: mapForm?.aadharNumber,
      aadharCopy: this.aadharCopy || mapForm?.aadharCopy || "",
      gstNumber: mapForm?.gstNumber,
      gstCopy: this.gstCopy || mapForm?.gstCopy || "",
      designation: mapForm?.designation || 0,
      supplierName: mapForm?.supplierName || "",
      supplierType: mapForm?.supplierType,
      contractMode: mapForm?.contractMode,
      status: mapForm?.status,
      tcsPercentage: mapForm?.tcsPercentage,
      location: mapForm?.location,
    }
  }
  private checkMissingFields(resourceEntity: any) {
    const missingFields: any = [];

    const checkField = ((fieldCopy: any, fieldNumber: any, fieldName: string) => {
      if (fieldNumber !== null && ((fieldCopy !== "" && fieldNumber === "") || (fieldCopy === "" && fieldNumber !== ""))) {
        missingFields.push(`${fieldName} Number And ${fieldName} Image`);
      }
    });

    checkField(resourceEntity.aadharCopy, resourceEntity.aadharNumber, 'Aadhar');
    checkField(resourceEntity.panCopy, resourceEntity.panNumber, 'Pan');
    checkField(resourceEntity.gstCopy, resourceEntity.gstNumber, 'GST');

    return missingFields;
  }
  private mapToEditInput(resource: ResourceEntity): FormGroup {
    const entity = resource;
    console.log("entity", entity);

    return this.fb.group({
      id: new FormControl(entity.id || ""),
      name: new FormControl(entity.name || "", Validators.required),
      mobileNumber: new FormControl(entity.mobileNumber || "", Validators.required),
      email: new FormControl(entity.email || "", Validators.required),
      address: new FormControl(entity.address || "", Validators.required),
      areaName: new FormControl(entity.areaName || "", Validators.required),
      landMark: new FormControl(entity.landMark || "", Validators.required),
      country: new FormControl(entity.country || "", Validators.required),
      longitude: new FormControl(entity.longitude || "", Validators.required),
      latitude: new FormControl(entity.latitude || "", Validators.required),
      city: new FormControl(entity.city || "", Validators.required),
      state: new FormControl(entity.state || "", Validators.required),
      pinCode: new FormControl(entity.pinCode || "", Validators.required),
      supplierName: new FormControl(entity.supplierName || "", Validators.required),
      aadharNumber: new FormControl(entity.aadharNumber || ""),
      aadharCopy: new FormControl(entity.aadharCopy || ""),
      panNumber: new FormControl(entity.panNumber || ""),
      panCopy: new FormControl(entity.panCopy || ""),
      gstNumber: new FormControl(entity.gstNumber || ""),
      gstCopy: new FormControl(entity.gstCopy || ""),
      uploadGst: new FormControl(entity.gstCopy || ""),
      supplierType: new FormControl(entity.supplierType || 0, Validators.required),
      contractMode: new FormControl(entity.contractMode || 0, Validators.required),
      status: new FormControl(entity.status || 0, Validators.required),
      tcsPercentage: new FormControl(entity.tcsPercentage || "", Validators.required),
      designation: new FormControl(entity.designation || null, Validators.required),
      location: new FormControl(entity.location || "")
    });
  }

  private async validateInput() {
    const result = this.validateMaxLength(this.dataSource.value);

    if (!result.isValid) {
      const errorMessage = this.createMaxLengthMsg(result.failedFields || []);
      this.showMessage(errorMessage, SwalMessageTypes.Warning);
      throw new Error('validateMaxLength.');
    }

    const validateNegativeValues = this.validateNegativeValues(this.dataSource.value);

    if (!validateNegativeValues.isValid) {
      const message = this.generateErrorMessage(validateNegativeValues.failedFields);
      this.showMessage(message, SwalMessageTypes.Warning);
      throw new Error('validateNegativeValues.');
    }

    const { pinCode } = this.dataSource.value;

    if (pinCode && !validatePinCode(pinCode)) {
      this.showMessage('PinCode is not correct. Please provide a proper PinCode  number.', SwalMessageTypes.Warning);
      throw new Error('validateGST.');
    }

    const validationResult = this.validateText(this.dataSource.value);

    if (validationResult.failedField) {
      const errorMessage = this.buildErrorMessage(validationResult.failedField);
      this.showMessage(errorMessage, SwalMessageTypes.Warning);

      throw new Error('validateText.');
    }

    // if (this.validateText(this.dataSource.value)) {
    //   this.showMessage('The name or city is not valid. Please correct the name by removing special characters.', SwalMessageTypes.Warning);
    //   throw new Error('validateText');
    // }

    const { gstNumber } = this.dataSource.value;

    if (gstNumber && !validateGST(gstNumber)) {
      this.showMessage('GST Number is not correct. Please provide a proper GST number.', SwalMessageTypes.Warning);

      throw new Error('validateGST.');
    }

    const { panNumber } = this.dataSource.value;

    if (panNumber && !validatePanNumber(panNumber)) {
      this.showMessage('PAN Number is not correct. Please provide a proper PAN number.', SwalMessageTypes.Warning);

      throw new Error('validateGST.');
    }

    if (!validatePinCode(this.dataSource.value.pinCode)) {
      this.showMessage('PinCode is not correct. Please provide a proper PinCode  number.', SwalMessageTypes.Warning);

      throw new Error('validateGST.');
    }

    if (this.checkSpecialCharacters(this.dataSource.value)) {
      this.showMessage('Aadhar,Pan and GST Should not have special characters. Please remove special characters.', SwalMessageTypes.Warning);

      throw new Error('validateText');
    }

    // const response = await this.ResourcesService.getResource(resourceListSearchInput());
    // this.existingResource = response['supplierList'];

    // if (this.checkIsMobileNumberExists(this.dataSource.value.mobileNumber)) {
    //   this.showMessage('Mobile Number Already Exists.', SwalMessageTypes.Warning);
    //   this.loader.close();
    //   throw new Error('Mobile number already exists.');
    // }
  }
  private buildErrorMessage(failedField: string): string {
    return `The ${failedField} is not valid. Please correct the ${failedField} by removing special characters.`;
  }
  private generateErrorMessage(failedFields: { field: string; failedField: string }[] | undefined): string {
    let message = 'The following fields contain invalid numeric values:';
    if (failedFields) {
      failedFields.forEach(failedField => {
        message += ` ${failedField.field} (${failedField.failedField}),`;
      });
      message = message.slice(0, -1) + '.';
    }
    return message;
  }
  validateMaxLength(data: any) {
    console.log("validating max lenght");

    const validations: MultiPropertyValidation[] = [
      { field: 'mobileNumber', property: data.mobileNumber, maxLength: 10 },
      { field: 'pinCode', property: data.pinCode, maxLength: 6 },
    ];
    if (this.dataSource.value.aadharNumber == null) {
      data.aadharNumber = ""
    }

    if (data.aadharNumber !== "") {
      validations.push({ field: 'aadharNumber', property: data.aadharNumber, maxLength: 12 });
    }
    if (data.panNumber !== "") {
      validations.push({ field: 'panNumber', property: data.panNumber, maxLength: 10 });
    }
    if (data.gstNumber !== "") {
      validations.push({ field: 'gstNumber', property: data.gstNumber, maxLength: 15 });
    }

    return validateMaxLength(validations);
  }
  private createMaxLengthMsg(failedFields: { field: string; failedField: string }[]): string {

    const errorMessages: { [key: string]: string } = {
      'mobileNumber': 'Mobile (10 digits)',
      'pinCode': 'Pin Code (6 digits)',
      'aadharNumber': 'Aadhar (12 digits)',
      'panNumber': 'Pan (10 characters)',
      'gstNumber': 'Gst (15 characters)'
    };

    const failedFieldNames = failedFields.map(failedField => {
      return errorMessages[failedField.field];
    });

    return 'The input lengths for ' + failedFieldNames.join(', ') + ' do not match. Please ensure you enter the correct lengths for each field before proceeding.';
  }

  private validateNegativeValues(data: any): ValidationResult {
    console.log("validatenegative values", data);

    const numericInputs = [
      { field: 'mobileNumber', value: data.mobileNumber },
      { field: 'pinCode', value: data.pinCode },
      { field: 'tcsPercentage', value: data.tcsPercentage },
      { field: 'latitude', value: data.latitude },
      { field: 'longitude', value: data.longitude },
    ];

    const validation = validateNoNegativeValues(numericInputs.map(input => input));
    return { isValid: validation.isValid, failedFields: validation.failedFields };
  }

  checkSpecialCharacters(data: any): boolean {
    const inputValues = [];
    if (data.aadharNumber !== "") {
      inputValues.push(data.aadharNumber);
    }
    if (data.panNumber !== "") {
      inputValues.push(data.panNumber);
    }
    if (data.gstNumber !== "") {
      inputValues.push(data.gstNumber);
    }
    return inputValues.length > 0 ? hasSpecialCharacters(inputValues) : false;
  }
  async onFileChange(event: any, documentType: string) {
    if (event.target.files) {
      const file = event.target.files[0];
      const filename = file.name;
      const reader = new FileReader();
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      reader.onload = async (event) => {
        const result = event?.target?.result;

        if (typeof result === 'string') {
          const base64String: string = await this.compressAndConvertToBase64(file, options);
          this.uploadedFiles[documentType] = {
            fileName: filename,
            fileExtension: this.getFileExtension(filename),
            fileData: base64String,
          };
        } else if (result instanceof ArrayBuffer) {
          console.error('Unsupported file type');
          // Handle this case if needed
        }
      };

      reader.readAsDataURL(file);
    }
  }
  async compressAndConvertToBase64(file: File, options: any): Promise<string> {
    try {
      const compressedFile = await imageCompression(file, options);
      const base64String: string = await imageCompression.getDataUrlFromFile(compressedFile);
      return base64String.split(',')[1];
    } catch (error) {
      console.error('Error compressing image:', error);
      throw error;
    }
  }

  openPopUp(imageUrl: string) {
    const dialogRef = this.dialog.open(UploadImageDialogComponent, {
      width: "50%",
      height: "40%",
      data: {
        imageUrl: imageUrl,
      } as ImageDialog
    });
    dialogRef.afterClosed().subscribe(async (response: any) => {
      if (response) {

      }
    });
  }
  private fixMaxLength() {
    applyMaxLengthValidation(this.dataSource, [
      { property: "mobileNumber", maxLength: 10 },
      { property: "pinCode", maxLength: 6 },
      { property: "aadharNumber", maxLength: 12 },
      { property: "panNumber", maxLength: 10 },
      { property: "gstNumber", maxLength: 15 },
    ]);
  }
  private validateText(data: ResourceForm): validateName {
    const textInputs = [
      { value: data.name, field: 'Name' },
      { value: data.city, field: 'City' },
    ];

    return nameValidate(textInputs.map(input => input)) || { isValid: true };
  }

  private initializedesignation(): LookUpType {
    return {
      tableName: "designation",
      lookupType: "designation",
      idColumnName: "id",
      valueColumnName: "designation"
    };
  }

  private initializeLocation(): LookUpType {
    return {
      tableName: "locations",
      lookupType: "locations",
      idColumnName: "id",
      valueColumnName: "name"
    };
  }

  private initializeSupplier(): LookUpType {
    return {
      tableName: "logistic_suppliers",
      lookupType: "logistic_suppliers",
      idColumnName: "Id",
      valueColumnName: "Name"
    };
  }
}
