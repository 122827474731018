<div class="container-fluid p-2">
  <mat-card class="p-3">
    <form [formGroup]="productForm">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 d-flex my-3">
            <div class="me-3">
              <mat-icon role="button" (click)="back()">keyboard_backspace</mat-icon>
            </div>
            <h3 class="title">Select Category, Company & Brand</h3>
          </div>
        </div>

        <div class="row">
          <div class="col-md-9 mt-2">
            <app-category-selector [control]="productForm.get('categories_id')"></app-category-selector>

          </div>
          <div class="col-md-3 mt-3">
            @if(productForm.get('categories_id')?.value){
            <mat-card class="p-3 custom-cr" >
              <h3 class="mb-3 text-primary ">Company & Brand</h3>
              <!-- <div class="col-md-12">
                <mat-form-field class="example-full-width">
                  <mat-label>Name</mat-label>
                  <input matInput formControlName="name" />
                </mat-form-field>
              </div> -->


              <div class="col-md-12 mt-4">
                <!--   <mat-form-field class="example-full-width">
                    <mat-label>Company</mat-label>
                    <mat-select formControlName="companies_id">
                      @for (company of companyList; track company) {
                        <mat-option [value]="company.id">{{company.attributes.name}}</mat-option>
                      }
                    </mat-select>
                  </mat-form-field> -->

                <ng-select [items]="companyList" bindValue="id" bindLabel="attributes.name" placeholder="Select Company"
                  formControlName="companies_id" appendTo="body" class="custom-ng-select" >
                </ng-select>
              </div>
              <div class="col-md-12 mt-4">
                <!--   <mat-form-field class="example-full-width">
                      <mat-label>Brand</mat-label>
                     <mat-select formControlName="brands_id">
                        @for (brand of brandsList; track brand) {
                          <mat-option [value]="brand.id">{{brand.attributes.name}}</mat-option>
                        }
                      </mat-select>
                    </mat-form-field>  -->
                <ng-select [items]="brandsList" bindValue="id" bindLabel="attributes.name" placeholder="Select Brand"
                  formControlName="brands_id" appendTo="body" class="custom-ng-select">
                </ng-select>
              </div>
            </mat-card>
          }
          </div>
        </div>

        <!--    <div class="row">
            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>Name</mat-label>
                <input matInput formControlName="name" />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>Company</mat-label>
                <mat-select formControlName="companies_id">
                  @for (company of companyList; track company) {
                    <mat-option [value]="company.id">{{company.attributes.name}}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="example-full-width">
                  <mat-label>Brand</mat-label>
                  <mat-select formControlName="brands_id">
                    @for (brand of brandsList; track brand) {
                      <mat-option [value]="brand.id">{{brand.attributes.name}}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">
              <app-category-selector [control]="productForm.get('categories_id')"></app-category-selector>
        </div>

          </div> -->


        <div class="row my-3">
          <div class="col-md-12 text-center">
            @if (!button) {
              <button [disabled]="!productForm.valid" mat-raised-button (click)="submit()" color="primary">
              Next
            </button>
          }
            @if (button) {
              <button [disabled]="!productForm.valid" mat-raised-button (click)="updateProduct()" color="primary">
              Update
            </button>
          }
          </div>
        </div>
      </div>
    </form>
  </mat-card>
</div>
