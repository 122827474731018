import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToasterDialogComponent } from '../shared/dialogs/toaster-dialog/toaster-dialog.component';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  loader: boolean = false;
  constructor(public dialog: MatDialog) { }

  showLoder() {
    this.loader = true
  }
  hideLoder() {
    setTimeout(() => {
      this.loader = false
    }, 1500);
  }
  showToaster(type: any, message: any, title?: any) {
    //success, warn, error
    const dialogRef = this.dialog.open(ToasterDialogComponent, {
      data: { type: type, title: title, message: message },
      width: '400px',
      height: '300px'
    });
  }
  getNotify(message: any, data: any) {
    let name = data.payload.sku.name
    if (message == 'you have a new approval request') {
      return `You have received new request for approval of ${name}`
    }
    else if (message == 'Updated request for approval') {
      return `You have received update request for approval of ${name}`
    }
    else if (message == 'your request is approved') {
      return `Your request for the ${name} has been approved`
    }
    else if (message == 'your request is rejected') {
      return `Your request for the ${name} has been rejected`
    }
    else {
      return message;
    }
  }
  async showAlert(title: string, message: string, confirmButtonText: string, type: 'success' | 'error' | 'warning' | 'info' = 'info') {
    return Swal.fire({
      title,
      text: message,
      icon: type,
      showCancelButton: true,
      confirmButtonText: confirmButtonText || "Ok",
    });
  }

}
