import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Observable, lastValueFrom, map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { DownloadOrderModificationRequest, DownloadOrdersList, OrderProductSearchInput, OrderSearchInput, SalesPersonInfo, SalesPersonsOrdersSummary } from '../../../enums/enums';
import { DB_PATH } from '../../../constants/db-path';
import { FirebaseDbService } from '../../../services/firebase-db.service';
import { OrderReturnApiInput } from '../../../models/order';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  baseServiceUrl: string = `${environment.ordersServiceUrl}`;
  // baseServiceUrl: string = 'http://localhost:4014/api';

  constructor(private http: HttpClient) {
  }

  async getOrdersList(body: any): Promise<any> {
    try {
      const url: string = `${this.baseServiceUrl}/query/get_orders_list`;
      const res = await lastValueFrom(this.http.post<any>(url, body));
      if (res && res.data) {
        const result = {
          orderList: res && res.data.rows,
          ordersCount: res && res.data.count
        };
        return result
      }
    } catch (error) {
      console.error('Error fetching lookup types:', error);
    }
  }

  async getSalesPersonsOrdersSummary(body: SalesPersonsOrdersSummary): Promise<any> {
    try {
      const url: string = `${this.baseServiceUrl}/query/get_salesperson_orders_summary`;
      const res = await lastValueFrom(this.http.post<any>(url, body));
      if (res && res.data) {
        const result = {
          orderList: res && res.data.rows,
          ordersCount: res && res.data.count
        };
        console.log("result", result);
        return result
      }
    } catch (error) {
      console.error('Error fetching lookup types:', error);
    }
  }

  async downloadOrderList(body: DownloadOrdersList) {
    const url = `${this.baseServiceUrl}/query/download_orders_list`;

    const response = await lastValueFrom(this.http.post(url, body, { responseType: 'blob' }));

    return response
  }


  async downloadOrderModificationReport(body: DownloadOrderModificationRequest) {
    const url = `${this.baseServiceUrl}/query/download_orders_modified_items_list`;
    const response = await lastValueFrom(this.http.post(url, body, { responseType: 'blob' }));
    return response
  }






}
