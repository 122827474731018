import { Component, ElementRef, HostListener, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { CommonModule, formatDate } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AuthService } from '../../../../services/auth.service';
import { Auth, User, authState } from '@angular/fire/auth';
import { BehaviorSubject } from 'rxjs';
import { VendorGeoMappingService } from '../../services/vendor-geo-mapping.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-company',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    RouterLink,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatCheckboxModule,
  ],
  templateUrl: './add-company.component.html',
  styleUrl: './add-company.component.scss',
})
export class AddCompanyComponent {
  page_id = 'bms_create_suppliers';
  permission: any = false;
  dataSource: any;
  selectedFiles: any | null;
  jsonGeoData: any;
  txtJson: string = '';
  companiesList: Array<any> = [];
  locationsList: Array<any> = [];
  selectedCompaniesList: Array<any> = [];
  isCompanySelect: boolean = false;
  id: any;
  userSubject = new BehaviorSubject<User | null>(null);
  displayedColumns = ['locations', 'company', 'action'];

  constructor(
    public fb: FormBuilder,
    public route: ActivatedRoute,
    public router: Router,
    public auth: AuthService,
    private el: ElementRef,
    public dialogRef: MatDialogRef<AddCompanyComponent>,
    public vendorGeoMappingService: VendorGeoMappingService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  async ngOnInit() {
    const vendorId = this.data.vendorId;
    this.selectedCompaniesList = this.data.companyIds.flat() || [];
    await this.getCompanies(vendorId, this.selectedCompaniesList);
  }

  async getCompanies(vendorId: string, selectedCompaniesList: string[] = []) {
    this.companiesList = await this.vendorGeoMappingService.getCompanies(
      vendorId,
      this.selectedCompaniesList
    );
  }

  saveCompanies() {
    this.isCompanySelect = false;
    let companiesList = this.companiesList.filter((data) => data.isSelected);

    if (companiesList.length == 0) {
      this.isCompanySelect = true;

      return;
    }

    const geoMapData = this.mapLCM(companiesList);

    this.dialogRef.close(geoMapData);
  }

  private mapLCM(companiesList: Array<any>) {
    return {
      vendorId: this.data.vendorRefId,
      locationId: this.data.selectedLocationId,
      companies: companiesList.map((data) => data.company_id),
      vendorTypeId: this.data.vendorTypeId,
    };
  }
}
