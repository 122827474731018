import { MessageType } from './../../errors/model/model';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Auth } from '@angular/fire/auth';
import { AuthService } from '../../../services/auth.service';
import { lastValueFrom, map } from 'rxjs';
import { FirestoreService } from '../../../services/firestore.service';
import { DB_PATH } from '../../../constants/db-path';
import { CreateNotification, NotificationListEntity, NotificationListResponse, NotificationListSearch } from '../models/notification.model';
import { formatDateToDDMMYYYY } from '../../../utlity/utility';
import { MESSAGE_QUEUE_TYPE } from '../../../constants/message-queue-types';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  auth: Auth = inject(Auth);
  coreServiceUrl: string = `${environment.coreServiceUrl}`;
  constructor(
    public http: HttpClient,
    public authService: AuthService,
    public firestoreService: FirestoreService
  ) { }
  async getCategories(searchKey: string): Promise<any> {
    const res = await lastValueFrom(
      this.http.get<any>(
        `${environment.backendUrl}/categories/?name=${searchKey}&level=1`
      )
    );
    return res;
  }
  async getBrands(searchKey: string): Promise<any> {
    const res = await lastValueFrom(
      this.http.get<any>(`${environment.backendUrl}/brands/?name=${searchKey}`)
    );
    return res;
  }
  async getSkus(searchKey: string): Promise<any> {
    const res = await lastValueFrom(
      this.http.get<any>(`${environment.backendUrl}/skus/?name=${searchKey}`)
    );
    return res;
  }
  async createDeepLink(body: any): Promise<any> {
    console.log("body::", body);

    const res = await lastValueFrom(
      this.http.post<any>(`${environment.coreServiceUrl}/api/deep_link`, body)
    );
    console.log("res::", res);
    return res;
  }
  async insertDeepLink(body: any) {
    return await this.firestoreService.insertDocWithId(
      DB_PATH.DEEP_LINKS,
      body.id,
      body
    );
  }
  async getAllDeeplinks(): Promise<any> {
    const response = await this.firestoreService.fetchCollection(
      DB_PATH.DEEP_LINKS
    );
    return this.mapToDeepLinks(response);
  }

  mapToDeepLinks(deepLinks: Array<any> = []) {
    return deepLinks
      .map((deeplink) => {
        return {
          id: deeplink.id || '',
          description: deeplink.Description || '',
          createdAt: deeplink.Created_at,
          link: deeplink.Link,
          type: deeplink.Type,
          title: deeplink.Title,
          data: deeplink.Data,
        };
      })
      .sort((a, b) => b.createdAt - a.createdAt);
  }

  async uploadNotificationImageDocs(filename: string, file: any, imageType: string) {
    try {
      const fullUrl = `${environment.fileUploadBaseUrl}/images/notifications`;
      const payLoad: any = {
        "fileName": filename,
        "fileExtension": imageType,
        "fileData": file
      }

      return await this.http.put(fullUrl, payLoad, undefined).toPromise();
    } catch (error) {
      throw error;
    }
  }


  async delete(notificationId: string) {
    const response = await lastValueFrom(this.http.delete<any>(`${this.coreServiceUrl}/api/notification/delete/${notificationId}`));

    return response
  }


  async getAllNotifications(body: NotificationListSearch): Promise<any> {
    try {
      const res = await lastValueFrom(this.http.post<any>(`${this.coreServiceUrl}/api/notification/get_by_filters`, body));

      if (res && res.data) {
        const mapEntity = this.mapData(res.data.rows);
        return {
          notificationList: mapEntity, // Array of notifications
          totalNotifications: res.data.count, // Total count
        };
      }
    } catch (error) {
      console.error('Failed to fetch notifications:', error);
      return {
        notificationList: [], // Return empty list on failure
        totalNotifications: 0, // Return zero total count
      };
    }
  }


  private mapData(data: NotificationListResponse[]): NotificationListEntity[] {
    return data.map((c) => ({
      id: c.id,
      title: c.title,
      description: c.description,
      link: c.link,
      sentCount: c.sentCount,
      status: c.status,
      expiryDate: c.expiryDate,
      createdAt: c.createdAt,
      createdDate: formatDateToDDMMYYYY(c.createdAt)
    })).sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());;
  }

  async insertNotifications(body: CreateNotification) {

    const response = await lastValueFrom(this.http.post<any>(`${this.coreServiceUrl}/api/notification/create`, body));

    return response


  }




  async postMessageToMessageQueue(obj: any) {
    try {

      const messageQueueUrl = `${environment.messagingService}/messagequeue/post`;
      const body = {
        messageType: MESSAGE_QUEUE_TYPE.NOTIFICATIONS_PROVIDER,
        input: JSON.stringify(obj),
      }
      console.log("body:::", body);

      return lastValueFrom(
        this.http
          .post(messageQueueUrl, body)
          .pipe(
            map((response) => {
              return response;
            })
          )
      );
    } catch (error) {
      throw error;
    }
  }

}
