export interface CustomerEntity {
  id: string;
  name: string;
  email: string;
  createdDate?: number;
  mobileNumber: string;
  isActive?: boolean;
  source?: string;
  businessName: string;
  // businessType: string;
  businessTagStatus: string;
  gstNumber: string;
  address: Address;
  landmark?: string;
  areaCode?: string;
  state?: string;
  street?: string;
  status: number;
  city?: string;
  businessTypeId: number;
  gstCertificateUrl: string;
  registrationDocumentUrl: string;
  profilePicUrl: string;
  photoIdUrl?: string;
  shopUrl: string;
  latitude?: number;
  longitude?: number;
  settingsAreaName?: string;
  registeredDate?: string;
  pincode?: number;
  country?: string;
}

export interface Address {
  area: string;
  street: string;
  landmark: string;
  city: string;
  state: string;
  pinCode: number;
  country: string;
  latitude: number;
  longitude: number;
}

export interface CustomerListSearch {
  id: string;
  name: string;
  mobile_number: string;
  page_number: number;
  page_size: number;
  businessTypeIds: number[];
  salesPersonIds: string[];
  status: number[];
  startDate: string;
  endDate: string;
}

export interface createCustomerResponse {
  success: boolean;
  message: string;
  data: any;
}

export interface CustomerForm {
  country: string;
  customerId: string;
  name: string;
  email: string;
  createdDate: number;
  mobileNumber: string;
  isActive: boolean;
  source: string;
  latitude: number;
  longitude: number;
  businessName: string;
  businessTypeId: number;
  businessTagStatus: string;
  gstNumber: string;
  address: Address;
  landmark: string;
  state: string;
  city: string;
  residence: string;
  areaCode: string;
  gstCertificateUrl: string;
  registrationDocumentUrl: string;
  profilePicUrl: string;
  photoIdUrl: string;
  shopUrl: string;
  status: number;
  settingsAreaName: string;
  registeredDate: string;
  pinCode: number;
}

export enum BusinessType {
  Restaurant = 1,
  Kirana = 2,
  Caterers = 3,
  TiffinCenters = 4,
  PG_Hostel = 5,
  Institutional = 6,
  Bakery = 7,
  SweetHouse = 8,
  VegetableShops = 9,
  Tier2Customer = 10,
  Medicals = 11,
  BulkCategory = 12,
  Chemist = 13,
  Cosmetic = 14,
  Supermarket = 15,
  Wholesale = 16,
  RiceTraders = 17,
  OilTraders = 18,
  Others = 19,
}

export enum CustomerStatus {
  Lost = 1,
  LostNotInterested = 2,
  BusinessShutdown = 3,
  InternalUser = 4,
  ActiveUser = 5,
  TemporaryShutdown = 6,
  Duplicate = 7,
  DistributorSales = 8,
}

export interface PropertyValidation {
  property: string;
  maxLength: number;
}

export interface CustomerImageResponse {
  success: boolean;
  message: null | string;
  data: {
    details: string;
    url: string;
  };
}

export interface CustomerListResponse {
  id: string;
  name: string;
  business_name: string;
  mobile_number: string;
  business_tag_status: number;
  business_type_id: number;
  registered_date: Date ;
  email: string;
  gst_number: string;
  shop_url: string;
  profile_pic_url: string;
  gst_certificate_url: string;
  registration_document_url: string;
  photo_id_url: string;
  user_type_id: number;
  status: number;
  push_notification_token: string;
  created_at: string;
  updated_at: Date;
  salesperson_id: string;
}
export interface CustomerListEntity {
  id: string;
  name: string;
  businessName: string;
  mobileNumber: string;
  businessTagStatus: string;
  businessTypeId: string;
  registeredDate: string;
  email: string;
  gstNumber: string;
  shopUrl: string;
  profilePicUrl: string;
  gstCertificateUrl: string;
  registrationDocumentUrl: string;
  photoIdUrl: string;
  userTypeId: number;
  status: string;
  pushNotificationToken: string;
  createdDate: string;
  updatedDate: Date;
  salespersonId: string;
}
