<ng-container>
  <div class="p-3">
    <div>
      <p class="p-2">/vendor-search</p>
    </div>
    <div class="mat-elevation-z8">
      <div class="table-container p-2">
        <div class="row filters">
          <div class="row">
            <div class="col-md-2">
            </div>
            <form [formGroup]="searchForm">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <mat-form-field appearance="outline" class="w-100">
                      <input  type="text" matInput placeholder="Vendor Name" formControlName="nameLowerCase">

                    </mat-form-field>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <mat-form-field appearance="outline" class="w-100">
                      <input type="number" matInput placeholder="Mobile Number" formControlName="mobileNumber">
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-md-12 custom-flex">
                  <div class="text-center mt-2 flex-item">
                    <button mat-raised-button color="primary" class="text-center ml" (click)="searchMerchant()">
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<mat-table [dataSource]="searchedMerchants" matSort>

  <!-- Define columns -->
  <ng-container matColumnDef="id">
    <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
    <mat-cell *matCellDef="let row"> {{ row.id }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
    <mat-cell *matCellDef="let row"> {{ row.name }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="mobileNumber">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Mobile Number </mat-header-cell>
    <mat-cell *matCellDef="let row"> {{ row.mobileNumber }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="businessName">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Business Name </mat-header-cell>
    <mat-cell *matCellDef="let row"> {{ row.businessName }} </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="navigateToVendorList(row.id)"></mat-row>
</mat-table>
