export const PageId = {
  seller_orders: 'seller_orders',
  seller_add_sku: 'seller_add_sku',
  seller_my_sku: 'seller_my_sku',
  bms_manage_sku: 'bms_manage_sku',
  seller_my_requests: 'seller_my_requests',
  bms_approvals: 'bms_approvals',
  seller_price_and_stock: 'seller_price_and_stock',
  bms_company: 'bms_company',
  bms_brand: 'bms_brand',
  bms_category: 'bms_category',
  bms_variant: 'bms_variant',
  bms_attribute: 'bms_attribute',
  seller_pr: 'seller_pr',
  seller_suppliers: 'seller_suppliers',
  seller_bulk_import: 'seller_bulk_import',
  seller_trips: 'seller_trips',
  seller_download: 'seller_download',
  seller_settlements: 'seller_settlements',
  seller_shop_settings: 'seller_shop_settings',
  seller_coupons: 'seller_coupons',
  bms_customers: 'bms_customers',
  bms_orders: 'bms_orders',
  bms_vendors: 'bms_vendors',
  bms_payments: 'bms_payments',
  insights: 'insights',
  bms_promotions: 'bms_promotions',
  bms_incentives: 'bms_incentives',
  bms_performance: 'bms_performance',
  bms_targets: 'bms_targets',
  bms_users: 'bms_users',
  bms_roles: 'bms_roles',
  bms_insights: 'bms_insights',
  bms_error_logs: 'bms_error_logs',
  logistics_planning: 'logistics_planning',
  logistics_trips: 'logistics_trips',
  logistics_reports: 'logistics_reports',
  logistics_location: 'logistics_location',
  logistics_clusters: 'logistics_clusters',
  logistics_vehicles: 'logistics_vehicles',
  logistics_resources: 'logistics_resources',
  logistics_suppliers: 'logistics_suppliers',
  bms_master_geo: 'bms_master_geo',
  bms_common: 'bms_common',
  deep_links: 'deep_links',
};

export const Permissions = {
  View: 'view',
  Create: 'create',
  Edit: 'edit',
  Delete: 'delete',
  Import: 'import',
  Export: 'export',
};
