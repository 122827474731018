import { Component, Inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CategoryService } from '../../category/services/category.service';
import { VarientTypeService } from '../../varientType/services/varientType.service';

@Component({
  selector: 'app-varient-value-filter-dialog',
  standalone: true,
  imports: [ ReactiveFormsModule,MatButtonModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIconModule],
  templateUrl: './varient-value-filter-dialog.component.html',
  styleUrl: './varient-value-filter-dialog.component.scss'
})
export class VarientValueFilterDialogComponent {
  filterForm:any;
  varientList:any =[];

  constructor(
    public dialogRef: MatDialogRef<VarientValueFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb:FormBuilder,
    public varientService: VarientTypeService

  ) {
    this.filterForm = this.fb.group({
      name: [null],
      page: [1],
      limit: [5]
    })
  }
  ngOnInit(): void {
    this.filterForm.patchValue(this.data);
    this.getAll()
  }
  getAll(){
    this.varientService.getAll().subscribe((res:any) =>{
      this.varientList =res.data
    })
  }
  close(){
    this.dialogRef.close()
  }
  reset(){
    this.filterForm.patchValue(
      {
        name: null,
        page: 1,
        limit: 5
      }
    )
    this.dialogRef.close(this.filterForm.value)
  }
  submit(){
    let data =   {
    name: this.filterForm.value.name,
     page: 1,
    limit: 5
    }
    this.dialogRef.close(data)
  }
}
