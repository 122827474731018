<div class="container-fluid p-2">
  <mat-card class="p-3">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">
          <h3 class="title">Notifications</h3>
        </div>
        <div class="col-md-6 text-end">
          <button
          mat-raised-button
          (click)="openFilter()"
          color="primary"
          class="me-2 filter-btn mb-2"
        ><div class="icon-dot" [ngClass]="{'active-filter': !checkQueryParam}"></div>
        <mat-icon class="me-0">filter_list</mat-icon>
        </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 table-wrapper">
          <table class="table">
              <thead>
                  <tr>
                    <th>Type</th>
                      <th>Notification</th>
                      
                      <th>Created At</th>
                      <th>Action</th>
                  </tr>
              </thead>
              <tbody>
                @for (item of filterList; track $index) {
                <tr>
                  <td>{{item?.payload?.type | type}}</td>
                  <td>{{item?.message}}</td>
                  
                  
                  <td>{{item?.timestamp.toDate() | date: 'medium'}}</td>
                  <td>
                    <button [routerLink]="['/dashboard/my-requests']" 
                    routerLinkActive="router-link-active"  
                    (click)="deleteNotify(item.id)"
                    color="primary" mat-raised-button>
                    <mat-icon class="me-0">remove_red_eye</mat-icon></button>

                  </td>
              </tr>
            }
                </tbody>
                </table>
                </div>
       <!--  @for (item of filterList; track $index) {
        <div class="col-md-12">
          <mat-card class="my-2 p-3 position-realtive">
            <div class="close-card-btn">
              <mat-icon (click)="deleteNotify(item.id)"  >close</mat-icon>
            </div>
            <div class="d-flex align-items-center justify-content-between  ">
              <div>
                <h5>{{item?.payload?.admin_name}}</h5>
                <h2>{{item?.message}}</h2>
                <p>{{item?.payload?.type | type}}</p>
                <p>{{item?.timestamp.toDate() | date: 'medium'}}</p>
              </div>
              <button [routerLink]="['/dashboard/my-requests']" 
              routerLinkActive="router-link-active"  
              (click)="deleteNotify(item.id)"
              color="primary" mat-raised-button>
              <mat-icon class="me-0">remove_red_eye</mat-icon></button>
            </div>
          </mat-card>
        </div>} -->
      </div>
    </div>
  </mat-card>
</div>