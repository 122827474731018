@if(hasView){
<div class="container-fluid p-2">
  <mat-card class="p-3">
    <form [formGroup]="filterForm">

      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-sm-3">
                <mat-form-field class="full-width">
                  <input matInput formControlName="startDate" placeholder="Start Date"
                    [matDatepicker]="appDatepickerStart" [readonly]="true" [max]="today" />
                  <mat-datepicker-toggle matSuffix [for]="appDatepickerStart"></mat-datepicker-toggle>
                </mat-form-field>
                <mat-datepicker #appDatepickerStart></mat-datepicker>
              </div>
              <div class="col-sm-3">
                <mat-form-field class="full-width">
                  <input matInput formControlName="endDate" placeholder="End Date" [matDatepicker]="appDatepickerEnd"
                    [readonly]="true" [max]="today" />
                  <mat-datepicker-toggle matSuffix [for]="appDatepickerEnd"></mat-datepicker-toggle>
                </mat-form-field>
                <mat-datepicker #appDatepickerEnd></mat-datepicker>
              </div>
              <div class="col-sm-6 searchbtns d-flex">
                <div class="col-sm-3 text-left mt-2">
                  <button mat-raised-button color="primary" class="text-center ml" (click)="searchOrders()">
                    Search
                  </button>
                </div>

                <div class="col-sm-3 text-right mt-2 clearfiltrtbtn">
                  <button mat-raised-button color="primary" class="text-center ml-w" (click)="clearFilters()">
                    Clear Filters
                  </button>
                </div>


                <div class="col-md-3 text-right mt-2 mx-auto">
                  @if(hasExport){
                  <button mat-raised-button color="primary" class="topButtonSectionbtn btn-width"
                    [matMenuTriggerFor]="downloadOptionsMenu">
                    Download
                    <mat-icon>arrow_drop_down</mat-icon>
                  </button>
                  }
                  <mat-menu #downloadOptionsMenu="matMenu" class="export-dropdown">
                    <button class="dropdown-list" mat-menu-item *ngFor="let option of downloadOptions"
                      (click)="onDownloadOptionClick(option)">
                      {{ option }}
                    </button>
                  </mat-menu>
                </div>



              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 mx-auto">
            <div class="d-flex">
              <div class="search-box">
                <input type="text" class="search-input" placeholder="Search product by name" formControlName="name">
                <mat-icon class="mb-0 search-icon">search</mat-icon>
              </div>
              <button type="button" class="btn btn-search ms-3" (click)="reset()">
                Reset
              </button>
            </div>
          </div>

        </div>
      </div>

    </form>
  </mat-card>
</div>
<div class="row">
  <mat-card>
    <mat-card-content>
      <div class="row row-cols-2 mb-3">
        <div class="col-md-11">
          <h2 class="card-title text-violet">Product summary</h2>
        </div>
        <div class="col-sm-6">
          <label class="heading">Revenue :</label>
          <label class="content">{{totalOrderAmount | replaceCommas}}</label>
        </div>
        <div class="col-sm-6">
          <label class="heading">Weight(In Kg) :</label>
          <label class="content">{{totalOrderWeight | number}}</label>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<div class="row" *ngIf="dataSource.length > 0">
  <div class="table-block">
    <div class="m-3 flex flex-col md:flex-row justify-between">
      <mat-card class="p-0">
        <mat-card-content class="p-0">
          <div class="table-container">
            <mat-table [dataSource]="dataSource" matSort>
              <ng-container matColumnDef="skuName">
                <mat-header-cell *matHeaderCellDef>
                  Product Name
                </mat-header-cell>
                <mat-cell *matCellDef="let row" data-label="skuName">
                  {{ row.skuName }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="mrp">
                <mat-header-cell *matHeaderCellDef>
                  Mrp
                </mat-header-cell>
                <mat-cell *matCellDef="let row" data-label="mrp">
                  {{ row.mrp }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="inventory_selling_price">
                <mat-header-cell *matHeaderCellDef>
                  Selling Price
                </mat-header-cell>
                <mat-cell *matCellDef="let row" data-label="inventory_selling_price">
                  {{ row.inventory_selling_price }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="totalQuantity">
                <mat-header-cell *matHeaderCellDef>
                  Ordered Quantity
                </mat-header-cell>
                <mat-cell *matCellDef="let row" data-label="totalQuantity">
                  {{ row.totalQuantity }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="invoiceQty">
                <mat-header-cell *matHeaderCellDef>
                  Invoice Quantity
                </mat-header-cell>
                <mat-cell *matCellDef="let row" data-label="invoiceQty">
                  {{ row.invoiceQty }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="availableQty">
                <mat-header-cell *matHeaderCellDef>
                  Available Quantity
                </mat-header-cell>
                <mat-cell *matCellDef="let row" data-label="availableQty">
                  {{ row.availableQty }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="cases">
                <mat-header-cell *matHeaderCellDef>
                  Cases
                </mat-header-cell>
                <mat-cell *matCellDef="let row" data-label="cases">
                  {{ row.cases }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="pieces">
                <mat-header-cell *matHeaderCellDef>
                  Pieces
                </mat-header-cell>
                <mat-cell *matCellDef="let row" data-label="pieces">
                  {{ row.pieces }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef class="mat-column-actions"> Action </mat-header-cell>
                <mat-cell *matCellDef="let row" class="mat-column-actions">
                  <div class="d-flex">
                    <button *ngIf="hasEdit" mat-raised-button color="primary" (click)="openEditPopup(row)">Edit</button>
                  </div>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
          </div>
          <div class="d-flex justify-content-center my-2" *ngIf="dataLength==0">
            <p class="no-data-found-text">No record found</p>
          </div>

          <mat-paginator [length]="dataLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="onPageChange($event)"></mat-paginator>
        </mat-card-content>
      </mat-card>

    </div>

  </div>
</div>
<div class="row" *ngIf="dataSource.length == 0">
  <mat-card-content>
    <div class="d-flex justify-content-center my-2">
      <p class="no-data-found-text">No products found</p>
    </div>
  </mat-card-content>
  <mat-card>
  </mat-card>
</div>
}
