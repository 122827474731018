import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RouterLink } from '@angular/router';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CommonModule } from '@angular/common';
import Swal from 'sweetalert2';
import { getEnumKeyValueList, getProviders, isEmptyValue } from '../../../../../utlity/utility';
import { IProviderData } from '../../services/provider-model';
import { CalculationType, GateWayType, SwalMessageTypes } from '../../../../../enums/enums';

export interface checkNegative {
  settlementDays:number;
  chargeValue: number;
}

@Component({
  selector: 'app-add-providers-popup',
  standalone: true,
  imports: [MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    RouterLink,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    MatTableModule,
    MatTableModule,
    MatSidenavModule,
    MatPaginatorModule,
    CommonModule],
  templateUrl: './add-providers-popup.component.html',
  styleUrl: './add-providers-popup.component.scss'
})
export class AddProvidersPopupComponent {
  dataSource: any;
  providers: Array<any> = [];
  calculationTypes: Array<any> = [];
  constructor(public dialogRef: MatDialogRef<AddProvidersPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IProviderData,
    private fb: FormBuilder,) {
    //this.providers = getProviders();
    this.providers = getEnumKeyValueList(GateWayType).filter(provider => provider.value !== GateWayType.None);;
    this.calculationTypes = getEnumKeyValueList(CalculationType);
    this.dataSource = this.fb.group({
      providerName: [null, [Validators.required]],
      settlementDays: [null, [Validators.required]],
      chargeType: [null, [Validators.required]],
      chargeValue: [null, [Validators.required]],
    });

  }


  private validateNegativeValues(data: checkNegative): string[] {
    const numericInputs = [
      { name: 'settlement Days', value: data.settlementDays },
      { name: 'charge Value', value: data.chargeValue },
    ];

    const negativeFields = numericInputs
      .filter((input) => input.value < 0)
      .map((input) => input.name);

    return negativeFields;
  }
  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire("", message, SwalMessageTypes);
  }

  private validation():boolean{

    const negativeFields = this.validateNegativeValues(this.dataSource.value);

    if (negativeFields.length) {
      this.showMessage(
        `${negativeFields} ,have negative values Please Check`,
        SwalMessageTypes.Warning
      );

      return false;
    }
    if(!this.isInteger(this.dataSource.value.settlementDays)){

      this.showMessage(
        `settlement days can not have decimal values Please Check`,
        SwalMessageTypes.Warning
      );
      return false
    }


    return true
  }
  isInteger(value:number) {
    return Number.isInteger(value);
  }

  addProvider() {



    if (this.dataSource.invalid) {
      this.showMessage(
        "Please fill all required fields.",
        SwalMessageTypes.Warning
      );
      return;
    }
    if (!this.validation()) {
      return
    }




    const provider = this.dataSource.get("providerName")?.value || "";
    const settlementDays = this.dataSource.get("settlementDays")?.value || "";
    const chargeType = this.dataSource.get("chargeType")?.value || "";
    const chargeValue = this.dataSource.get("chargeValue")?.value || "";
    if (isEmptyValue(provider) || isEmptyValue(settlementDays) || isEmptyValue(chargeType) || isEmptyValue(chargeValue)) {
      Swal.fire('', "Please fill all mendatory fields!", 'warning');
      return;
    }
    const providerObj = { provider: provider, settlementDays: settlementDays, chargeType: chargeType, chargeValue: chargeValue };
    const found = this.data.savedProviders.find((obj) => {
      return obj.provider === provider;
    });
    if (found) {
      this.clearCategoryData();
      Swal.fire('', "This provider is already added!", 'warning');
      return;
    }
    this.dialogRef.close({
      popupResult: providerObj,
    });
    this.clearCategoryData();
  }

  clearCategoryData() {
    this.dataSource.get('providerName')?.reset();
    this.dataSource.get('settlementDays')?.reset();
    this.dataSource.get('chargeType')?.reset();
    this.dataSource.get('chargeValue')?.reset();
  }
}
