@if (hasEdit) {
<ng-container>
  <div class="table-block m-5">
    <div class="card">
      <div class="card-body card-padding">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

          <ng-container matColumnDef="sno">
            <th mat-header-cell *matHeaderCellDef>S.No</th>
            <td mat-cell *matCellDef="let row; let i = index">{{ i + 1 }}</td>
          </ng-container>

          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef>Address</th>
            <td mat-cell *matCellDef="let row">{{ row.formattedAddress}}</td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let row">
              <button mat-icon-button (click)="openDialog(row.id)">
                <mat-icon>edit</mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef class="text-center">Status</th>
            <td mat-cell *matCellDef="let row" class="text-center"
              [ngClass]="{'active': row.status === 1, 'inactive': row.status !== 1}">
              <button mat-raised-button (click)="updateAddressStatus(row)">{{ row.status === 1 ? 'Approved' : 'Waiting
                For
                Approval' }}</button>
            </td>
          </ng-container>

          <ng-container matColumnDef="defaultAddress">
            <th mat-header-cell *matHeaderCellDef class="text-center">Default</th>
            <td mat-cell *matCellDef="let row" class="text-center"
              [ngClass]="{'active': row.is_default === 1, 'inactive': row.is_default !== 1}">
              {{ row.is_default === 1 ? 'Active' : 'In-Active' }}
            </td>
          </ng-container>




          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </div>
</ng-container>
}