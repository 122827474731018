import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CommonModule } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';
import {
  PriceUpdateEntity,
  PricingRuleInput,
  SkuPricingRule,
  StockDetails,
  VendorSkuPricing,
  skuDetails,
  vendorDetails,
} from '../../../entities/pricing';
import { PricingListType, PricingRule, PricingRulePriceType, PricingRuleStatus, SwalMessageTypes } from '../../../enums/enums';
import { PricingEditPopupComponent } from '../pricing-Edit/pricing-edit.component';
import { PricingDataService } from '../services/pricing-data.service';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { PricingService } from '../services/pricing.service';
import Swal from 'sweetalert2';
import { containsOnlyNumbers, uuidv4, validateNoNegativeValues } from '../../../utlity/utility';
import uuid4 from 'uuid4';
import { ConfigService } from '../../config/services/config.service';
import { AppLoaderService } from '../../../shared/app-loader/app-loader.service';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { AuthService } from '../../../services/auth.service';
import { PageId } from '../../../constants/enums';
import { InventoryService } from '../services/inventory.service';
import { ProductService } from '../../product/services/product.service';
import { json } from '@rxweb/reactive-form-validators';
import { ValidationResult } from '../../supplier/models/logisticsupplier-model';

interface PriceUpdate {
  vendorDetails: vendorDetails;
  skuDetails: VendorSkuPricing;
  isUpdate: boolean;
}

@Component({
  selector: 'pricing-details',
  standalone: true,
  templateUrl: './pricing-details.component.html',
  styleUrls: ['./pricing-details.component.scss'],
  animations: [
    trigger('animate', [
      transition('* => *', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 })),
      ]),
    ]),
  ],
  imports: [
    MatFormFieldModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    RouterLink,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    MatTableModule,
    MatTableModule,
    MatSidenavModule,
    MatPaginatorModule,
    CommonModule,
  ],
})
export class PricingDetailsComponent extends BaseListComponent implements OnInit {
  pricingDetails: PriceUpdate = {
    vendorDetails: { vendor_id: '', businessTypeId: 0 },
    skuDetails: this.initializeSkuDetails(),
    isUpdate: false,
  };

  skuPrice: FormGroup = this.fb.group({ ...this.initializeSkuDetails() });
  priceRuleInput: PricingRuleInput = {
    minQty: 0,
    maxQty: 0,
    discountType: '',
    discountValue: 0,
  };
  dataSource: SkuPricingRule[] = [];
  isMrpOrSpNull: boolean = false;
  isSpGreaterMrp: boolean = false;
  displayedColumns = ['minQty', 'maxQty', 'discountType', 'value', 'edit'];
  shouldBeNumbers: boolean = false;
  skuName: string = '';
  statusText: string = '';
  stockDetails: FormGroup = this.fb.group({ ...this.initializeStockDetails() });

  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    private loader: AppLoaderService,
    public dialog: MatDialog,
    private pricingDataService: PricingDataService,
    private pricingService: PricingService,
    private configService: ConfigService,
    private inventoryService: InventoryService,
    public productService: ProductService

  ) {
    super(auth, router, route, PageId.seller_price_and_stock);
  }

  override async ngOnInit() {
    super.ngOnInit();
    await this.getPricingDetails();
    this.stockCal()
  }


  async getPricingDetails() {
    try {
      const savedData = localStorage.getItem('pricingDetails');
      if (savedData) {
        const data = JSON.parse(savedData);
        this.pricingDataService.setRowData(data);
        this.subscribeToRowData().pipe(takeUntil(this.unsubscribe$)).subscribe((rowData: any) => {
          this.pricingDetails = rowData as PriceUpdate;
          this.statusText = this.pricingDetails.skuDetails.status == 'active' ? 'Mark Inactive' : 'Mark Active'
          this.skuName = this.pricingDetails.skuDetails.name;
          this.skuPrice = this.skuDetails(this.pricingDetails.skuDetails);
          this.stockDetails = this.skuStockDetails(this.pricingDetails.skuDetails);
        });
      }
      await this.getPricingRules();
    } catch (error) { }
  }



  async getPricingRules() {
    if (this.skuPrice && this.pricingDetails.skuDetails) {
      const response = await this.pricingService.getVendorSKUPricingRules(
        this.pricingDetails.skuDetails.skus_id,
        this.pricingDetails.skuDetails.vendor_id
      );
      this.dataSource = this.mapPricingRules(response, this.skuPrice.value.selling_price)
    }
  }

  private mapPricingRules(pricingRulesData: any[], sellingPrice: number): any[] {
    const mappedRules = pricingRulesData.map(rule => ({
      id: rule.id,
      sku_id: rule.sku_id,
      vendor_id: rule.vendor_id,
      min_quantity: rule.min_quantity,
      max_quantity: rule.max_quantity,
      pricing_type: rule.pricing_type,
      pricing_value: +rule.pricing_value,
      pricing_rate: this.calculateValue(+rule.pricing_type, +rule.pricing_value, +sellingPrice),
      status: rule.status,
      created_at: new Date(rule.created_at),
      updated_at: new Date(rule.updated_at)
    }));

    const filteredRules = mappedRules.filter(p => p.status == PricingRuleStatus.Active);

    filteredRules.sort((a, b) => a.min_quantity - b.min_quantity);

    return filteredRules;
  }


  private calculateValue(rule: number, value: number, sellingPrice: number) {
    if (rule == PricingRulePriceType.Rate) {
      return value.toFixed(2)
    } else if (rule == PricingRulePriceType.DiscountAmount) {
      return (sellingPrice - value).toFixed(2)
    } else if (rule == PricingRulePriceType.DiscountPercentage) {
      return ((sellingPrice) - ((sellingPrice / 100) * value)).toFixed(2)
    }
    return 0;
  }

  private subscribeToRowData() {
    const rowDataSubject = new BehaviorSubject<any>(null);

    this.pricingDataService.rowData$.pipe(takeUntil(this.unsubscribe$)).subscribe((rowData) => {
      rowDataSubject.next(rowData);
    });

    return rowDataSubject.asObservable();
  }

  async savePricing() {
    this.loader.open();
    this.isMrpOrSpNull = false;
    this.isSpGreaterMrp = false;
    const mrp = this.skuPrice.value.maximum_retail_price;
    const sp = this.skuPrice.value.selling_price;
    this.isMrpOrSpNull = this.validatePrices(mrp, sp);
    if (this.isMrpOrSpNull) {
      this.loader.close();

      return;
    }

    if (sp > mrp) {
      this.isSpGreaterMrp = true;
      this.loader.close();
      return;

    }

    if (!containsOnlyNumbers(mrp, sp)) {
      this.shouldBeNumbers = true;
      this.loader.close();


      return;
    }

    if (!mrp > sp) {
      this.isSpGreaterMrp = true;
      this.loader.close();

      return;
    }
    this.upsertPricing(this.skuPrice.value);
  }

  private async upsertPricing(skuPrice: VendorSkuPricing) {
    try {
      const vendorDetails = await this.configService.getVendorSettingsById(
        this.pricingDetails.skuDetails.vendor_id
      );
      const { globalMargin } = vendorDetails?.vendorConfig || 0;
      const inputEntity: PriceUpdateEntity = this.mapToSPUpdateCD(
        skuPrice,
        globalMargin
      );
      const response = await this.pricingService.updatePricing(inputEntity);
      this.loader.close();
      if (response) {
        this.pricingDetails.skuDetails.selling_price = inputEntity.selling_price;
        this.pricingDetails.skuDetails.maximum_retail_price = inputEntity.maximum_retail_price;
        this.savePricingDetails();
        //this.router.navigate(['/dashboard/pricing']);
        Swal.fire('', 'Price Updated Successfully ', 'success');

      }
    } catch (error) {
      this.loader.close();

      Swal.fire('', 'Min and Max Qty should not be decimal values', 'warning');
      console.error('Error block: ', error);
    }
  }

  private mapToSPUpdateCD(skuPrice: VendorSkuPricing, globalMargin: number) {
    return {
      id: skuPrice.pricing_list_id || uuid4(),
      vendor_id: skuPrice.vendor_id,
      sku_id: skuPrice.skus_id,
      maximum_retail_price: +skuPrice.maximum_retail_price,
      selling_price: +skuPrice.selling_price,
      type: PricingListType.Selling,
      margin: +skuPrice.margin || globalMargin || 0,
    };
  }

  private validatePrices(mrp: number, sp: number): boolean {
    const isMrpEmpty = !mrp;
    const isSpEmpty = !sp;

    return (
      (mrp === 0 && sp !== 0) ||
      (mrp !== 0 && sp === 0) ||
      (mrp === 0 && sp === 0) ||
      (isMrpEmpty && !isSpEmpty) ||
      (!isMrpEmpty && isSpEmpty)
    );
  }

  openDialog(row: any = '', isUpdate: boolean = false): void {
    const priceRuleInput = { ...row };
    priceRuleInput.pricing_type = +priceRuleInput.pricing_type;
    const dialogRef = this.dialog.open(PricingEditPopupComponent, {
      data: {
        priceRuleInput: priceRuleInput,
        existingPricingRules: this.dataSource,
        skuDetails: this.skuPrice,
        isUpdate: isUpdate,
      },
    });
    dialogRef.afterClosed().pipe(
      takeUntil(this.unsubscribe$) // Unsubscribe when the component is destroyed
    )
      .subscribe(async (response: any) => {
        if (response) {
          await this.getPricingRules();
        }
      });
  }

  private initializeSkuDetails(): VendorSkuPricing {
    return {
      vendor_sku_id: '',
      vendor_sku_name: '',
      vendor_id: '',
      skus_id: '',
      product_id: '',
      product_name: '',
      product_description: '',
      brand_id: '',
      brand_name: '',
      company_id: '',
      company_name: '',
      maximum_retail_price: 0,
      selling_price: 0,
      margin: 0,
      pricing_rules_count: 0,
      pricing_list_id: '',
      name: "",
      status: "",
      upc: 0,
      inner_pack: 0,
      available_stock: 0,
      damage: 0
    };
  }

  private initializeStockDetails(): StockDetails {
    return {
      totalAvailableStock: 0,
      availableCss: 0,
      availablePcs: 0,
      damageCss: 0,
      damagePcs: 0,
      totalDamagePcs: 0,
    };
  }

  private getItemQtyInCasesPieces(upc: number, qty: number) {
    if (upc == 0) {
      return { pieces: qty, cases: 0 };
    }
    const roundedUpc = Math.round(upc);
    const pieces = qty % roundedUpc;
    const cases = Math.floor(qty / roundedUpc);

    return { pieces, cases };
  }

  private skuStockDetails(skuDetails: VendorSkuPricing): FormGroup {

    const stock = this.getItemQtyInCasesPieces(+skuDetails.upc, +skuDetails.available_stock);
    const damage = this.getItemQtyInCasesPieces(+skuDetails.upc, +skuDetails.damage);

    return this.fb.group({
      availableCss: stock.cases,
      availablePcs: stock.pieces,
      damageCss: damage.cases,
      damagePcs: damage.pieces,
      totalDamagePcs: +skuDetails.damage,
      totalAvailableStock: +skuDetails.available_stock,
    })
  }

  private skuDetails(priceRuleDetails: VendorSkuPricing): FormGroup {
    return this.fb.group({
      pricing_list_id: new FormControl(priceRuleDetails.pricing_list_id || ''),
      vendor_sku_id: new FormControl(priceRuleDetails.vendor_sku_id || ''),
      vendor_sku_name: new FormControl(priceRuleDetails.vendor_sku_name || ''),
      vendor_id: new FormControl(priceRuleDetails.vendor_id || ''),
      skus_id: new FormControl(priceRuleDetails.skus_id || ''),
      product_id: new FormControl(priceRuleDetails.product_id || ''),
      product_name: new FormControl(priceRuleDetails.product_name || ''),
      name: new FormControl(priceRuleDetails.name || ''),
      product_description: new FormControl(priceRuleDetails.product_description || ''),
      brand_id: new FormControl(priceRuleDetails.brand_id || ''),
      brand_name: new FormControl(priceRuleDetails.brand_name || ''),
      company_id: new FormControl(priceRuleDetails.company_id || ''),
      company_name: new FormControl(priceRuleDetails.company_name || ''),
      maximum_retail_price: new FormControl(priceRuleDetails.maximum_retail_price || 0),
      selling_price: new FormControl(priceRuleDetails.selling_price || 0),
      margin: new FormControl(priceRuleDetails.margin || 0),
      pricing_rules_count: new FormControl(priceRuleDetails.pricing_rules_count || 0),
      status: new FormControl(priceRuleDetails.status || ""),
      upc: new FormControl(+priceRuleDetails.upc || 0),
      inner_pack: new FormControl(+priceRuleDetails.inner_pack || 0),
      available_stock: new FormControl(+priceRuleDetails.available_stock || 0),
      damage: new FormControl(+priceRuleDetails.damage || 0),

    });
  }

  getDiscountTypeText(value: number): string {
    const input = {
      [PricingRule.Rate]: 'Rate',
      [PricingRule.DiscountPercentage]: 'Discount Percentage',
      [PricingRule.DiscountAmount]: 'Discount Amount',
    };
    return input[+value as PricingRule];
  }

  async deletePricingRule(productDiscount: SkuPricingRule) {
    const result = await Swal.fire({
      title: 'Do you want delete pricing rule',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    });

    if (!result.isConfirmed) {
      return;
    }

    const payLoad = {
      id: productDiscount.id,
      sku_id: this.pricingDetails.skuDetails.skus_id,
      vendor_id: this.vendorId,
      status: PricingRuleStatus.Inactive,
      min_quantity: productDiscount.max_quantity,
      max_quantity: productDiscount.max_quantity,
      type: PricingListType.Selling,
      pricing_type: productDiscount.pricing_type,
      pricing_value: productDiscount.pricing_value,
    };
    await this.pricingService.updatePricingRule(payLoad);
    Swal.fire('', 'Price Rule deleted  successfully ', 'success');
    await this.getPricingDetails();


  }

  private stockCal() {
    this.stockDetails.get('availableCss')?.valueChanges.subscribe(() => {
      this.updateTotalAvailableStock();
    });
    this.stockDetails.get('availablePcs')?.valueChanges.subscribe(() => {
      this.updateTotalAvailableStock();
    });
    this.stockDetails.get('damageCss')?.valueChanges.subscribe(() => {
      this.updateTotalDamagePcs();
    });
    this.stockDetails.get('damagePcs')?.valueChanges.subscribe(() => {
      this.updateTotalDamagePcs();
    });
  }
  private updateTotalAvailableStock() {
    const availableCss = this.stockDetails.get('availableCss')?.value || 0;
    const availablePcs = this.stockDetails.get('availablePcs')?.value || 0;
    const totalAvailableStock = (availableCss * this.skuPrice.value.upc) + availablePcs;
    this.stockDetails.patchValue({ totalAvailableStock });
  }
  private updateTotalDamagePcs() {
    const damageCss = this.stockDetails.get('damageCss')?.value || 0;
    const damagePcs = this.stockDetails.get('damagePcs')?.value || 0;
    const totalDamagePcs = (damageCss * this.skuPrice.value.upc) + damagePcs;
    this.stockDetails.patchValue({ totalDamagePcs });
  }
  makeActive() {
  }
  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }
  private showSuccessMessage(action: string) {
    this.showMessage(`${action} Successfully`, SwalMessageTypes.Success);
  }
  private savePricingDetails() {
    localStorage.setItem('pricingDetails', JSON.stringify(this.pricingDetails));
  }

  changeStatus(rowId: string, status: string) {

    console.log("status :===",status)

    const newStatus = status === 'active' ? 'inactive' : 'active';
    this.updateSkuStatus(rowId, newStatus);
  }
  private async updateSkuStatus(rowId: string, newStatus: string) {
    try {
      this.loader.open();
      await this.productService.updateVendorSkuStatus(rowId, newStatus);
       const msgText= this.pricingDetails.skuDetails.status === 'active' ? 'Mark Inactive': 'Mark Active';
      this.pricingDetails.skuDetails.status = newStatus;
      this.statusText = newStatus === 'active' ? 'Mark Inactive' : 'Mark Active';
      this.showSuccessMessage(msgText);
      this.savePricingDetails();
    } catch (error) {
      this.loader.close();
      Swal.fire('error', 'Some thing went wrong', 'error');
    } finally {
      this.loader.close();

    }
  }
  isInteger(value: number) {
    return Number.isInteger(value);
  }

  private validateNegativeValues(stock: StockDetails): ValidationResult {
    const numericInputs = [
      { field: 'Available Pcs', value: stock.availablePcs },
      { field: 'Available Css', value: stock.availableCss },
      { field: 'Damage Css', value: stock.damageCss },
      { field: 'Damage Pcs', value: stock.damagePcs },
    ];

    const validation = validateNoNegativeValues(
      numericInputs.map((input) => input)
    );
    return {
      isValid: validation.isValid,
      failedFields: validation.failedFields,
    };
  }
  hasDecimal(number: number) {
    return /\./.test(number.toString());
  }

  private decimalValueCheck(stock: StockDetails) {
    if (this.hasDecimal(stock.availablePcs)) {
      this.showMessage(
        `Available PCs should not have decimal value`,
        SwalMessageTypes.Warning
      );
      return false
    }
    if (this.hasDecimal(stock.availableCss)) {
      this.showMessage(
        `Available CSs should not have decimal value`,
        SwalMessageTypes.Warning
      );
      return false
    }
     if (this.hasDecimal(stock.damagePcs)) {
      this.showMessage(
        `Damage PCs have decimal value`,
        SwalMessageTypes.Warning
      );
      return false
    }

    if (this.hasDecimal(stock.damageCss)) {
      this.showMessage(
        `Damage CSs have decimal value`,
        SwalMessageTypes.Warning
      );
      return false
    }



    return true;
  }

  async updateStock(stock: StockDetails) {
    try {
      const validateNegativeValues = this.validateNegativeValues(
        stock
      );
      if (!validateNegativeValues.isValid) {
        const message = this.generateErrorMessage(
          validateNegativeValues.failedFields
        );
        this.showMessage(message, SwalMessageTypes.Warning);
        return
      }
      if (!this.decimalValueCheck(stock)) {

        return
      }

      const stockInput = this.mapToInventoryObject(stock)
      this.loader.open();
      const inventoryResponse: any =
        await this.inventoryService.insertInventory(stockInput);
      if (inventoryResponse.success) {
        this.pricingDetails.skuDetails.available_stock = stock.totalAvailableStock;
        this.pricingDetails.skuDetails.damage = stock.totalDamagePcs;
        this.savePricingDetails();
        Swal.fire('Success', 'successfully Updated', 'success');
      }
      this.loader.close();
    } catch (error) {
      this.loader.close();
      Swal.fire('error', 'Some thing went wrong', 'error');
    } finally {
      this.loader.close();
    }
  }

  private mapToInventoryObject(stock: StockDetails) {
    return {
      id: uuidv4(),
      sku_id: this.pricingDetails.skuDetails.skus_id,
      vendor_id: this.pricingDetails.skuDetails.vendor_id,
      quantity: stock.totalAvailableStock,
      sale_in_progress: 0,
      purchase_in_progress: 0,
      damage: stock.totalDamagePcs,
      created_at: new Date(),
      updated_at: new Date(),
    };
  }

  private generateErrorMessage(
    failedFields: { field: string; failedField: string }[] | undefined
  ): string {
    let message = 'The following fields contain invalid numeric values:';
    if (failedFields) {
      failedFields.forEach((failedField) => {
        message += ` ${failedField.field} (${failedField.failedField}),`;
      });
      message = message.slice(0, -1) + '.';
    }
    return message;
  }
}
