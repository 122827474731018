import { AfterViewChecked, AfterViewInit, Component, OnDestroy, OnInit, OnChanges, ViewChild, ChangeDetectorRef, HostListener } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSidenavModule } from '@angular/material/sidenav';

import Swal from 'sweetalert2';
import { AppLoaderService } from '../../../../../shared/app-loader/app-loader.service';
import { AuthService } from '../../../../../services/auth.service';
import { SwalMessageTypes, SupplierStatus } from '../../../../../enums/enums';
import { CompanyListSearch, VendorCompanyEntity, VendorCompanyResponse } from '../models/companies.model';
import { CompaniesService } from '../../services/companies.service';
import { MatDialog } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SelectionModel } from '@angular/cdk/collections';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { uuidv4 } from '../../../../../utlity/utility';
import { VendorConfigEntity } from '../../../../shopsetting/models/shopsettings.model';
import { BaseListComponent } from '../../../../../shared/core/base.list.component';
import { PageId } from '../../../../../constants/enums';

export interface VrsMerchantAPIKeys {
  apiKey: string;
  apiSecret: string;
  apiUrl: string;
  selfSalesPersonId: string;
  apiModules: ApiModules;
}

export interface ApiModules {
  qwipo: string;
  viwito: string
}


@Component({
  selector: 'app-companies-list',
  standalone: true,
  imports: [MatCardModule, MatButtonModule, MatTableModule, RouterLink, MatInputModule, MatFormFieldModule, ReactiveFormsModule,
    MatSelectModule, MatIconModule, CommonModule, FormsModule, MatDatepickerModule, MatRadioModule, MatNativeDateModule, MatPaginatorModule, MatSidenavModule, MatCheckboxModule],
  templateUrl: './companies-list.component.html',
  styleUrls: ['./companies-list.component.scss']
})
export class CompanyListComponent extends BaseListComponent {
  singularTypes = Object.values(SupplierStatus).filter(value => typeof value === 'number');

  companies: VendorCompanyEntity[] = [];
  readonly DEFAULT_PAGE_SIZE = 25;

  totalCompanies: number = 0;
  form: FormGroup;
  showSelectedTable: boolean = false;
  selectedCompaniesDataSource!: MatTableDataSource<any>;
  selectedDisplayedColumns: string[] = ['id', 'name', 'delete'];
  selectedSelection = new SelectionModel<any>(true, []);
  displayedColumns = ['checkbox', 'id', 'name'];
  dataSource!: MatTableDataSource<any>;
  @ViewChild('allCompaniesPaginator') paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('vendorCompaniesPaginator') paginatorVendor!: MatPaginator;
  @ViewChild(MatSort) sortVendor!: MatSort;
  providers: any[] | undefined;
  selection = new SelectionModel<any>(true, []);
  searchText: string = '';
  merchantDetails: any;
  vendorConfiguration!: VendorConfigEntity;
  distributorKeyDetails!: VrsMerchantAPIKeys;
  merchantCompaniesList: any = [];
  mappedCompaniesList: any = [];
  vendorCompanies: number = 0;
  pageNo: number = 1;
  pageS = this.DEFAULT_PAGE_SIZE;

  constructor(public readonly companyService: CompaniesService,
    route: ActivatedRoute,
    router: Router,
    private loader: AppLoaderService,
    auth: AuthService,
    private fb: FormBuilder, private dialog: MatDialog) {

    super(auth, router, route, PageId.bms_vendors)
    this.form = this.fb.group({
      companySearch: ['']
    });
  }

  override async ngOnInit() {
    await super.ngOnInit();
    this.getMerchantDetails();
    await this.checkCompanyMasterList();
    await this.getAllCompanies();
    this.setupSearchInput();
    await this.getVendorCompanies();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  getMerchantDetails() {
    this.route.paramMap.subscribe(async (params) => {
      const state = history.state;
      this.merchantDetails = state.merchantDetails;
      // console.log("this.merchantDetails::", this.merchantDetails);
      // await this.loadvendorMappingData();

    });

  }
  @HostListener('document:keydown', ['$event'])
  handleKeyEvents(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  setupSearchInput() {
    this.form.controls['companySearch'].valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        this.applyFilter(value);
      });
  }

  applyFilter(filterValue: string) {
    this.searchText = filterValue;
    this.dataSource.filter = this.searchText;
  }
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  private mapCompanies(response: any) {

    return response.map((c: { attributes: any; id: any; }) => {
      return {
        name: c.attributes.name,
        id: c.id
      }
    })
  }
  async getAllCompanies() {
    try {
      this.loader.open();
      const response = await this.companyService.getAll().toPromise();
      const mappedCompanies = this.mapCompanies(response.data)
      this.dataSource = new MatTableDataSource(mappedCompanies);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      this.loader.close();
      await this.checkCompanyMasterList();
    } catch (error) {
      this.loader.close();
      this.showMessage('Failed to Fetch Companies. Please try again later.', SwalMessageTypes.Error);
      console.log("error:- ", error);
    }
  }

  async getVendorCompanies() {
    const searchInput: CompanyListSearch = {
      vendorId: this.merchantDetails.id,
      pageNumber: this.pageNo,
      pageSize: 0// for all data //this.pageS
    };
    const vendorCompanies = await this.companyService.getVendorCompanies(searchInput);
    const res = vendorCompanies['vendorCompanies']
    this.selectedCompaniesDataSource = new MatTableDataSource(res);
    this.vendorCompanies = vendorCompanies['totalCount'];
    this.selectedCompaniesDataSource.paginator = this.paginatorVendor;
    this.selectedCompaniesDataSource.sort = this.sortVendor;

    await this.checkCompanyMasterList();
  }

  async checkCompanyMasterList() {
    if (this.dataSource && Array.isArray(this.selectedCompaniesDataSource?.data)) {
      const selectedCompanyIds = this.selectedCompaniesDataSource.data.map((company: { company_id: string; }) => company.company_id);
      const companyData = this.dataSource.data.filter(company => !selectedCompanyIds.includes(company.id));
      this.dataSource.data = companyData;

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.totalCompanies = this.dataSource.data.length;
    }
  }

  async deleteCompany(row: any) {
    try {
      const result = await Swal.fire({
        title: 'Do you want to Delete Company?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      });

      if (!result.isConfirmed) {
        return;
      }
      console.log("deleteCompanydeleteCompany::", row.id);
      await this.companyService.deleteCompany(row.vendorId, row.companyId);
      await this.getVendorCompanies();
      this.clearFilters();
      this.showMessage('Company Deleted Successfully', SwalMessageTypes.Success);
    } catch (error) {
      console.error('Error occurred while creating company:');
      this.showMessage('Failed to Delete Company. Please try again later.', SwalMessageTypes.Error);
    }
  }


  override async onPageChange(event: any) {
    await super.onPageChange(event);
  }
  async onPage(event: any) {
    const { pageIndex, pageSize } = event;
    this.pageNo = pageIndex + 1;
    this.pageS = pageSize;
    //await this.getVendorCompanies();
  }

  async searchTasks() {
    const { companyName } = this.form.value;
    this.pageNumber = 1;
    this.pageSize = this.DEFAULT_PAGE_SIZE;
    this.paginator.pageIndex = 0;
    if (companyName !== undefined) {
      // this.companyListSearchInput = this.createCompanyListSearchInput(companyName);
      await this.getAllCompanies();

    }
  }


  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  async clearFilters() {
    this.searchForm();
    await this.getAllCompanies();
  }

  toggleCompaniesView() {
    this.showSelectedTable = !this.showSelectedTable;
    if (this.showSelectedTable) {
      this.searchForm();
      this.selection.clear();
    } else {
      this.getAllCompanies();
    }
  }
  async loadVendorMappingData() {
    this.vendorConfiguration = await this.companyService.getVendorSettingByVendorId(this.merchantDetails.id);
    if (this.vendorConfiguration && this.vendorConfiguration.vendorConfig.vendorMapping) {
      const url = this.vendorConfiguration.vendorConfig.vendorMapping.apiUrl;
      const apiKey = this.vendorConfiguration.vendorConfig.vendorMapping.apikey;
      const apiSecretKey = this.vendorConfiguration.vendorConfig.vendorMapping.apiSecret;
      const res: VrsMerchantAPIKeys = {
        apiKey: apiKey,
        apiSecret: apiSecretKey,
        apiUrl: url,
        selfSalesPersonId: this.vendorConfiguration.vendorConfig.vendorMapping.selfSalesPersonId,
        apiModules: {
          qwipo: this.vendorConfiguration.vendorConfig.vendorMapping.apiModules.qwipo,
          viwito: this.vendorConfiguration.vendorConfig.vendorMapping.apiModules.viwito
        }
      }
      this.distributorKeyDetails = res;
    } else {
      // Swal.fire("", "Vrs Configuration is not available for this vendor", 'warning')
    }

  }

  async createVendorCompany() {
    let companiesList = this.selection.selected;
    const companyIds = companiesList.map((c) => c.id)
    const vendorCompany: VendorCompanyEntity = {
      vendorId: this.merchantDetails.id,
      companyIds: companyIds
    };

    try {
      const response = await (await this.companyService.createVendorCompany(vendorCompany)).toPromise();
    } catch (error) {
      console.error('Failed to create Vendor-Company relationship', error);
    }
  }


  async saveChanges() {
    const selectedRows = this.selection.selected;
    if (selectedRows.length === 0) {
      Swal.fire('', "Select atleast one Company", 'warning');
      return;
    }
    await this.createVendorCompany();
    await this.getVendorCompanies();
    Swal.fire('', "Companies added successfully", 'success')
    this.searchForm();
    this.selection.clear();
    this.showSelectedTable = true;

  }
  private searchForm() {
    this.form.patchValue({
      companySearch: null,
    });
  }

}
