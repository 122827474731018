@if(hasEdit){
<ng-container>
  <div>
    <div class="col-md-12 d-flex justify-content-end">
      <mat-icon (click)="dialogRef.close()" class="closeicon m-3">close</mat-icon>
    </div>
  </div>
  <div>
    <div class="search-block">
      <section id="content">
        <div class="col-md-12 m-2" style="font-size: 21px;text-align: center;">
          {{selectedItem.name }}
        </div>
        <div class="card">
          <form [formGroup]="dataSource">
            <div class="row">
              <div class="col-md-6 ">
                <mat-form-field class="full-width">
                  <mat-label>Ordered Qty</mat-label>
                  <input type="number" matInput formControlName="orderedQty" name="orderedQty" readonly>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Available Qty</mat-label>
                  <input type="number" matInput formControlName="availableQuantity" name="availableQuantity">
                </mat-form-field>
              </div>
              <!-- If trip is from vendor means vendor drop don't have to show damage entry -->
              @if(currentTrip?.source != 3){
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Damage Qty</mat-label>
                  <input type="number" matInput formControlName="damageQuantity" name="damageQuantity">
                </mat-form-field>
              </div>
              }


            </div>
            <div class="col-md-12 text-center m-2">
              <button mat-raised-button color="primary" (click)="save()" [disabled]="dataSource.invalid">Update</button>
            </div>
          </form>
        </div>
      </section>
    </div>
  </div>
</ng-container>
} @else {
<ng-container>
  <p class=" text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
}