@if(hasEdit){
<ng-container>
<div class="heading-block">
  <div class="row">
    <div class="col-md-6">
      <mat-icon class="backicon" [routerLink]="['/dashboard/customers']">arrow_back</mat-icon>
    </div>
    <div class="col-md-6 text-right"></div>
  </div>
</div>
<div class="col-md-12 mt-3">
  <mat-tab-group>
    <mat-tab label="Profile">
      <app-profile-information></app-profile-information>
    </mat-tab>
    <mat-tab label="Address">
      <app-address-information></app-address-information>
    </mat-tab>
  </mat-tab-group>
</div>
</ng-container>
} @else {
  <ng-container>
    <p class="text-center">
      You dont have permission , Please contact administrator
    </p>
  </ng-container>
  }
<router-outlet></router-outlet>
