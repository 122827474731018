@if(hasView){
<ng-container>
  <div class="row m-3">
    <div class="col-md-6 ">
      <h2 class="card-title">Move Hop</h2>
    </div>
    <div class="col-md-6 d-flex justify-content-end">
      <mat-icon (click)="dialogRef.close()" class="closeicon m-3">close</mat-icon>
    </div>
  </div>
  <div class="row">
    <form [formGroup]="dataSource">
      <div class="row m-3 ">
        <div class="col-md-9 text-center full-width">
          <mat-form-field>
            <mat-label> Trip Name </mat-label>
            <mat-select formControlName="trips">
              <mat-option *ngFor="let trip of trips" [value]="trip">
                {{ trip.tripName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-12 text-center mt-2">
          <button *ngIf="hasCreate" mat-raised-button color="primary" (click)="save()">Save</button>
        </div>
      </div>
    </form>
  </div>
</ng-container>
} @else {
<ng-container>
  <p class="text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
}