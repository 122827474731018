<ng-container>
  <div class="p-3">


    <div class="row">
      <div class="col-md-6">
        <h2 class="card-title m-3 text-violet">Search</h2>
      </div>
      <div class="col-md-6">
        <mat-icon (click)="dialogRef.close()" class="float-right">
          close
        </mat-icon>
      </div>
    </div>

    <!-- your-component.component.html -->

    <div class="mat-elevation-z8">
      <form [formGroup]="dataSource" class="m-3 formcss">
        <div class="table-container p-2">
          <div class="row filters">
            <div class="form-group">
              <div class="row">
                <div class="col-md-12">
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="SKU Name" formControlName="skuName" name ="skuName">
                  </mat-form-field>
                </div>

                <!-- <div class="col-md-6">
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="Brand Id" formControlName="brandId" name="brandId">
                  </mat-form-field>
                </div> -->
              </div>
              <!-- <div class="row">
                <div class="col-md-6">
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="Company Id" formControlName="companyId" name="companyId">
                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="Category Id" formControlName="categoryId" name="categoryId">
                  </mat-form-field>
                </div>
              </div> -->
            </div>
            <div class="col-md-12 custom-flex">
              <div class="text-center mt-2 flex-item ml-10">
                <button mat-raised-button color="primary" (click)="search()" class="text-center ml">
                  Search
                </button>
                <button mat-raised-button color="primary" (click)="clearFilters()" class="text-center ml-5">
                  Clear Filters
                </button>
              </div>
            </div>
          </div>
        </div>

      </form>
    </div>
</div>
</ng-container>
