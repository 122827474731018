import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter',
  standalone: true
})
export class SearchFilterPipe implements PipeTransform {
  transform(items: any[], filter: string): any[] {
    if (!items || !filter) {
      return items;
    }
    filter = filter.toLowerCase();
    return items.filter((item) => {
      if (typeof item === 'string') {
        return item.toLowerCase().includes(filter);
      } else if (typeof item === 'object') {
        for (const key in item) {
          if (item.hasOwnProperty(key) && item[key].toString().toLowerCase().includes(filter)) {
            return true;
          }
        }
      }
      return false;
    });
  }

}
