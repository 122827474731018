import { Injectable } from '@angular/core';
import { LocationEntity, LocationListSearch, createLocationResponse } from '../models/locations.model';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationsService {
  baseServiceUrl: string = `${environment.logisticsUrl}`;

  constructor(
    private readonly http: HttpClient) {
  }


  async createLocation(data: LocationEntity) {

    let response = await lastValueFrom(this.http.post<{ res: createLocationResponse }>(`${this.baseServiceUrl}/api/location/create`, data));

    return response.res
  }

  async getLocations(body: LocationListSearch): Promise<any> {
    const res = await lastValueFrom(this.http.post<any>(`${this.baseServiceUrl}/api/location/get_by_filters`, body));

    if (res && res.data) {
      return {
        locationList: res.data.rows,
        totalLocations: res.data.count
      }
    }

  }

  async getLocationById(id: string): Promise<any> {
    const res = await lastValueFrom(this.http.get<any>(`${this.baseServiceUrl}/api/location/get_by_id/${id}`));
    return res;
  }

  async getAllLocations(): Promise<any> {
    try {
      const response = await lastValueFrom(this.http.get<any>(`${this.baseServiceUrl}/api/location/get_all`));
      return response;
    } catch (error) {
      console.error('Error fetching locations:', error);
      throw error;
    }
  }

  async updateLocation(locationEntity: LocationEntity, locationid: string) {
    let { id, ...locationUpdatedEntity } = locationEntity;
    let response = await lastValueFrom(this.http.post<{ res: createLocationResponse }>(
      `${this.baseServiceUrl}/api/location/update/${locationid}`,
      locationUpdatedEntity
    ));
    return response.res
  }

  async deleteLocation(id: string) {
    return await lastValueFrom(this.http.delete(`${this.baseServiceUrl}/api/location/delete/${id}`));
  }


}
