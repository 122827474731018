<div class="row">
    <div class="col-md-6">
        <h2 class="card-title m-3 text-violet">Add Companies</h2>
      </div>
      <div class="col-md-6 d-flex justify-content-end">
        <mat-icon (click)="dialogRef.close()" class=" m-3 closeicon">close</mat-icon>
    </div>
</div>
<div class="container">
    <div class="card m-3">
      <div class="card-body card-padding">
        <div class="row">
          <div class="item" *ngFor="let item of companiesList">
            <input type="checkbox" id="{{ item.company_id }}" [(ngModel)]="item.isSelected">
            <label class="ml-10" for="{{ item.company_name }}">{{ item.company_name }}</label>
          </div>
        </div>

        <div class="text-center mt-100">
          <label *ngIf="isCompanySelect" class="error-message">Didn't have any changes to save</label>
          <button mat-raised-button color="primary" (click)="saveCompanies()">
            Save
          </button>
        </div>

      </div>
    </div>
  </div>




