import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FirebaseDbService } from "./firebase-db.service";
import { catchError, map } from "rxjs/operators";
import { DB_PATH } from "../constants/db-path";
import { Coupon } from "../entities/coupon";
import { FirestoreService } from "./firestore.service";
import { WhereFilterOp } from "../enums/enums";
import { getBusinessTypes } from "../utlity/utility";

@Injectable({
  providedIn: "root",
})
export class CouponService {
  constructor(
    private firebaseDbService: FirebaseDbService,
    private firestoreService: FirestoreService,

  ) { }


  getAllAreas(): Observable<any[]> {
    const dbPath = `${DB_PATH.B2B_AREA_COORDINATES}/`;
    return this.firebaseDbService.fetchDataFromNode(dbPath);
  }

  getBusinessTypes() {
    const businessTypes=getBusinessTypes().map(businessType => ({
      id: businessType.businessTypeId,
      businessType: businessType.businessTypeName
    }));
    return businessTypes;
  }


  async saveCouponsVendorWise(couponEntity: any) {

    return await this.firestoreService.insertDocWithId(DB_PATH.COUPONS, couponEntity.id, couponEntity);
  }

  async getCouponsByFilter(vendorId: string = '', status: number) {

    const filters: Array<any> = [];
    filters.push({ fieldName: 'vendorId', operator: WhereFilterOp.EqualTo, fieldValue: vendorId });
    filters.push({ fieldName: 'active', operator: WhereFilterOp.EqualTo, fieldValue: status });

    return await this.firestoreService.queryCollectionByFilters(DB_PATH.COUPONS, filters)
  }

  async changeCouponStatus(couponEntity: Coupon) {
    return await this.firestoreService.updateDocument(DB_PATH.COUPONS, couponEntity.id, couponEntity);
  }
  async getCouponById(id: string) {

    return await this.firestoreService.fetchDocument(DB_PATH.COUPONS, id)
  }

}
