<div mat-dialog-content>
  <div>
    <div class="panel panel-default" style="width: 100%">
      <div class="panel-body">
        <div class="card-body scroll-55-rem">
          <div class="row">
            <div class="col-md-12">
              <h2 class="pull-left">{{ data.modalTitle }}</h2>
            </div>
            <div class="col-md-8 my-4 image">
              <!-- {{data.viewImgUrl}} -->
              <img class="butch-img1" alt="" [src]="data.viewImgUrl" />
            </div>
          </div>

        </div>
      </div>
      <div class="row m-5">
        <div class="col-md-12">
          <div class="d-flex justify-content-between">
            <button class="cancel" mat-button color="warn" id="sa-title" (click)="onCancel()">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>