@if(hasView){
    <ng-container>
      <div class="row m-3">
        <div class="col-md-6 ">
          <h2 class="card-title">Enter OTP</h2>
        </div>
        <div class="col-md-6 d-flex justify-content-end">
          <mat-icon (click)="close()" class="closeicon m-3">close</mat-icon>
      </div>
      </div>
       <div class="row">
        <form [formGroup]="dataSource">
            <div class="row m-3 ">
                <div class="col-md-9 text-center">
                    <mat-form-field class ="field">
                        <mat-label> Enter OTP </mat-label>
                        <input type="number" matInput placeholder="Enter OTP" formControlName="otp">
                      </mat-form-field>
                    </div>
                    <div class="col-md-12 text-center mt-2">
                      <label *ngIf="isOtpMatched" class="error-message">You entered the wrong Otp</label>
                        <button mat-raised-button color="primary" *ngIf="hasCreate" (click)="save()">Save</button>
                    </div>
                  </div>
        </form>
      </div>
    </ng-container>
    } @else {
      <ng-container>
        <p class="text-center">
          You dont have permission , Please contact administrator
        </p>
      </ng-container>
      }
