@if(hasView){
<ng-container>
  <div class="heading-block">
    <div class="row">
      <div class="col-md-6">
        <h2 class="card-title m-3">Promotions</h2>
      </div>
      <div class="col-md-6 text-right">
        @if(hasCreate){
        <button mat-raised-button color="primary" [routerLink]="['/dashboard/promotion/create-promotion']"
          class="topButtonSectionbtn">
          Add Promotion
        </button>
        }
      </div>
    </div>
  </div>
  <div class="search-block">
    <form [formGroup]="form">
      <section id="content">
        <div class="card">
          <div class="card-body card-padding">
            <div class="row">
              <div class="col-md-3">


                <mat-form-field class="full-width">
                  <mat-label>Promo Type</mat-label>
                  <mat-select formControlName="promoType">
                    <mat-option *ngFor="let type of promotionTypeList" [value]="type.promotionTypeId">
                      {{ type.promotionType }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <!-- <mat-form-field class="full-width">
                  <mat-label>Promo Type</mat-label>
                  <mat-select formControlName="promoType">
                    <mat-option *ngFor="let type of promotionTypeList" [value]="type.promotionTypeId">
                      {{ type.promotionType }}
                    </mat-option>
                  </mat-select>
                </mat-form-field> -->
              </div>
              <div class="col-md-3">
                <mat-form-field class="full-width">
                  <mat-label>Status</mat-label>
                  <mat-select formControlName="status">
                    <mat-option value="true">Active</mat-option>
                    <mat-option value="false">Inactive</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-3 searchbtns">
                <div class="text-left mt-2">
                  <button mat-raised-button color="primary" class="text-center ml" (click)="searchTasks()">
                    Search
                  </button>
                </div>
                <div class="text-right mt-2 clearfiltrtbtn">
                  <button mat-raised-button color="primary" class="text-center ml-w" (click)="clearFilters()">
                    Reset Filters
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </form>
  </div>

  <div class="table-block">
    <div class="m-3 flex flex-col md:flex-row justify-between">
      <mat-card class="p-0">
        <mat-card-content class="p-0">
          <mat-sidenav-container fxFlex="0 1 auto">
            <mat-sidenav-content>
              <div class="table-container">
                <mat-table [dataSource]="dataSource" matSort>
                  <ng-container matColumnDef="promoType">
                    <mat-header-cell *matHeaderCellDef>
                      Promo Type
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="promoType">
                      {{ getPromotionTypeByValue(row.promoType) }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="sequence">
                    <mat-header-cell *matHeaderCellDef>
                      Sequence No
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="sequence">
                      {{ row.sequence }}
                    </mat-cell>
                  </ng-container>


                  <ng-container matColumnDef="promoName">
                    <mat-header-cell *matHeaderCellDef>
                      Promo Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="promoName">
                      {{ row.promoName }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="vendorName">
                    <mat-header-cell *matHeaderCellDef>
                      Vendor
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="vendorName">
                      {{ row.vendorName }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="displayName">
                    <mat-header-cell *matHeaderCellDef>
                      Display Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="displayName">
                      {{ row.displayName }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef>
                      Status
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="status">
                      {{ row.status?'Active':'Inactive' }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef class="mat-column-actions"> Action </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="mat-column-actions">
                      <div class="d-flex">
                        <button *ngIf="hasEdit" mat-raised-button color="primary"
                          (click)="changeStatusPromotion(row)">Mark
                          as
                          {{row.status?'Inactive':'Active'}}</button>
                      </div>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="Details">
                    <mat-header-cell *matHeaderCellDef class="mat-column-actions"> Details </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="mat-column-actions">
                      <div class="d-flex">
                        <button *ngIf="hasView" mat-raised-button class="ms-2" (click)="navigateToDetails(row)"
                          color="primary">Details</button>
                      </div>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                </mat-table>
              </div>
            </mat-sidenav-content>
          </mat-sidenav-container>
          <mat-paginator [length]="totalPromotions" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="onPageChange($event)"></mat-paginator>
        </mat-card-content>
      </mat-card>
    </div>

  </div>

</ng-container>
} @else {
<ng-container>
  <p class="text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
}