<ng-container *ngIf="hasView">
  <div class="row">
    <div class="col-md-6 ">
      <h2 class="card-title m-3 text-violet">View Generate Trips Preview</h2>
    </div>
    <div class="col-md-6">
      <mat-icon (click)="closeDialog()" class="float-right m-3 closeicon">close</mat-icon>
    </div>
  </div>
  <div class="m-3">
    <div class="table-container p-5">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="headerRow">
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        </ng-container>

        <ng-container matColumnDef="cluster">
          <th mat-header-cell *matHeaderCellDef> Cluster Name </th>
          <td mat-cell *matCellDef="let element"> {{element.cluster}} </td>
        </ng-container>

        <ng-container matColumnDef="ordersCount">
          <th mat-header-cell *matHeaderCellDef> Orders </th>
          <td mat-cell *matCellDef="let element"> {{element.ordersCount}} </td>
        </ng-container>

        <ng-container matColumnDef="customerCount">
          <th mat-header-cell *matHeaderCellDef> Unique Retailers </th>
          <td mat-cell *matCellDef="let element"> {{element.customerCount}} </td>
        </ng-container>

        <ng-container matColumnDef="weight">
          <th mat-header-cell *matHeaderCellDef> Weight </th>
          <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
        </ng-container>


        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef> Value </th>
          <td mat-cell *matCellDef="let element"> {{element.value}} </td>
        </ng-container>

        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>


  <div class="col-md-12 mt-5 text-center">
    <button [disabled]="!selectedDate" mat-raised-button class="m-2" color="primary" (click)="generateTrips()">
      Generate Trips</button>
  </div>
</ng-container>