import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DB_PATH } from '../../../constants/db-path';
import { FirestoreService } from '../../../services/firestore.service';
import { lastValueFrom, map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { MESSAGE_QUEUE_TYPE } from '../../../constants/message-queue-types';
import Swal from 'sweetalert2';

interface IvidResponseModel {
  data: {
    vendorId: number;
  };
  status: number;
}
@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  constructor(
    private firestoreService: FirestoreService,
    private http: HttpClient
  ) {}

  private mapToVendor(vendorRes: any, vrsMapping: any) {
    return {
      ...vendorRes,
      ...vrsMapping,
    };
  }
  async getVendorById(id: string): Promise<any> {
    try {
      const vendorRes: any = await lastValueFrom(
        this.http.get(`${environment.coreServiceUrl}/api/vendor/${id}`)
      );

      return vendorRes;
    } catch (error) {
      throw error;
    }
  }

  async getResourceById(id: string): Promise<any> {
    try {
      const res = await lastValueFrom(
        this.http.get(`${environment.coreServiceUrl}/api/resource/${id}`)
      );
      return res;
    } catch (error) {
      throw error;
    }
  }

  async getPolygons() {
    const res = await this.firestoreService.fetchCollection(
      DB_PATH.MASTER_POLYGON
    );
    return res;
  }

  async getMerchantId(vendorRefId: string): Promise<number> {
    try {
      const fullUrl = `${environment.geoService}/vendor/vid/${vendorRefId}`;
      const response: IvidResponseModel = await lastValueFrom(
        this.http.get<{ data: { vendorId: number }; status: number }>(fullUrl)
      );

      return response?.data?.vendorId ?? null;
    } catch (error) {
      throw error;
    }
  }

  private mapToPolygonPayload(polygons: Array<any>) {
    const mappedPolygons = polygons.map((polygon) => {
      const coordinates = polygon.coordinates.map(
        (c: { latitude: string | number; longitude: string | number }) => [
          c.longitude,
          c.latitude,
        ]
      );
      return {
        type: 'Feature',
        properties: {
          location: polygon.name,
          id: polygon.id,
        },
        geometry: {
          type: 'Polygon',
          coordinates: [coordinates],
        },
      };
    });
    return {
      type: 'FeatureCollection',
      features: mappedPolygons,
    };
  }

  async postMessageToMessageQueue(messageType: string, body: {}) {
    try {
      const messageQueueUrl = `${environment.messagingService}/messagequeue/post`;
      return lastValueFrom(
        this.http
          .post(messageQueueUrl, {
            messageType: messageType,
            input: JSON.stringify(body),
          })
          .pipe(
            map((response) => {
              return response;
            })
          )
      );
    } catch (error) {
      throw error;
    }
  }
  async savePolygon(
    vendorRefId: string,
    businessTypeId: number,
    polygons: Array<any>
  ) {
    try {
      const mappedPolygon = this.mapToPolygonPayload(polygons);
      //  const pgVendorId: number = await this.getMerchantId(vendorRefId);
      const uploadUrl = `${environment.geoService}/vendor/${vendorRefId}/upload`;
      await lastValueFrom(this.http.post(uploadUrl, mappedPolygon));
      const messageBody = {
        vendorRefId: vendorRefId,
        vendorId: vendorRefId,
        vendorTypeId: businessTypeId,
      };
      const messageQueueResponse = await this.postMessageToMessageQueue(
        MESSAGE_QUEUE_TYPE.VENDOR_GEO_MAPPING,
        messageBody
      );

      return messageQueueResponse;
    } catch (error) {
      Swal.fire('Warning!', 'Something went wrong', 'warning');

      throw error;
    }
  }
  async savePolygonInCd(vendorId: string, selectedPolygons: string[], vendor_config: any | null) {
    const body = {
      vendorId: vendorId,
      vendorConfig: { ...vendor_config, polygonIds: selectedPolygons },
    };
    console.log('body::', body);
    try {
      const res = await lastValueFrom(
        this.http.post(
          `${environment.coreServiceUrl}/api/vendor_settings/create_vendor_config`,
          body
        )
      );
      return res;
    } catch (error) {
      console.log('!');
      Swal.fire('Warning!', 'Something went wrong', 'warning');

      throw error;
    }
  }

  async getVendorSettingsById(vendorId: string) {
    try {
      const fullUrl = `${environment.coreServiceUrl}/api/vendor_settings/get_vendor_config/${vendorId}`;
      const response = await lastValueFrom(
        this.http.get<{ data: any }>(fullUrl)
      );
      return response?.data ?? null;
    } catch (error) {
      throw error;
    }
  }
}
