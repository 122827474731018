<div class="container-fluid p-2">
  <mat-card class="p-3">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 d-flex mb-3">
          <h3 class="title">Add GRN </h3>
        </div>
      </div>
      <form [formGroup]="form">
        <div class="row">
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <mat-label>SKU Name</mat-label>
              <input matInput formControlName="skuName"  readonly="true" />
            </mat-form-field>

          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width w-100">
              <mat-label>MRP</mat-label>
              <input matInput formControlName="mrp" readonly="true" />
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width w-100">
              <mat-label>UPC</mat-label>
              <input matInput formControlName="upc"  readonly="true"  />
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <mat-label>Buying UOM</mat-label>
              <mat-select formControlName="buyinguom" readonly="true" >
                <mat-option *ngFor="let option of Uom" [value]="option.value">
                  {{ option.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div> 
          <div class="col-md-6">
            <mat-form-field class="example-full-width w-100">
              <mat-label>Requested UOM Qty</mat-label>
              <input matInput formControlName="requestedUomQty" readonly="true" />
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width w-100">
              <mat-label>Actual UOM Qty</mat-label>
              <input matInput formControlName="actualUomQty" (input)="calculatePcs($event)"/>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width w-100">
              <mat-label>Requested Qty In PC's</mat-label>
              <input matInput formControlName="requestedQty" readonly="true" />
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width w-100">
              <mat-label>Actual Qty In PC's</mat-label>
              <input matInput formControlName="actualQty" />
            </mat-form-field>
          </div>
        </div>
        <div style="display: flex;  justify-content: center;">
               
          <div style="margin-right: 10px;">
            <button mat-raised-button color="primary" class="text-center ml-w" (click)="addGrn()">
              Save
            </button>
          </div>
          <div style="margin-left: 10px;">
            <button mat-raised-button color="primary" class="text-center ml-w" (click)="dialogRef.close()" >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  </mat-card>
</div>