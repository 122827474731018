import { Component } from '@angular/core';
import { ProductService } from '../product/services/product.service';
import { MatButtonModule } from '@angular/material/button';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { CommonService } from '../../services/common.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { StatusComponent } from '../../shared/status/status.component';
import { TypePipe } from '../../shared/pipes/type.pipe';
import { CommonModule } from '@angular/common';
import { VendorApprovalRequestsFilterDialogComponent } from './vendor-approval-requests-filter-dialog/vendor-approval-requests-filter-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../services/auth.service';
import { FormBuilder } from '@angular/forms';
import { BaseListComponent } from '../../shared/core/base.list.component';
import { PageId } from '../../constants/enums';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-vendor-approval-requests',
  standalone: true,
  imports: [CommonModule, StatusComponent,TypePipe, MatIconModule,MatProgressSpinnerModule, MatButtonModule,MatTableModule,MatCardModule,RouterModule],
  templateUrl: './vendor-approval-requests.component.html',
  styleUrl: './vendor-approval-requests.component.scss'
})
export class VendorApprovalRequestsComponent extends BaseListComponent {
  permission:any=false
  pendingList:any=[]
  filterList:any=[]
  loading = true;
  checkQueryParam:boolean =false;
  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    public productService:ProductService,
    public dialog:MatDialog,
    public commonService: CommonService,
    ){
    super(auth, router, route, PageId.seller_my_requests);

  }

  override async ngOnInit() {
    await super.ngOnInit();
    setTimeout(() => {
      this.commonService.showLoder()
      this.productService.getMyPendingDocs().pipe(takeUntil(this.unsubscribe$)).subscribe(res=>{
        this.pendingList=res
        this.filterList=res
        if (this.route.snapshot.queryParams["name"] || this.route.snapshot.queryParams["status"] ) {
          this.checkQueryParam = true;
          this.filterData(this.route.snapshot.queryParams)
       }
        this.loading = false
        this.commonService.hideLoder()
     },
     (error:any)=>{
      this.loading = false
       this.commonService.showToaster("error",error.message)
       this.commonService.hideLoder()
     })
    }, 2000);
  }

  isDataAvailable(): boolean {
    return this.pendingList && this.pendingList?.length > 0;
  }
  edit(docData:any){
      if(docData.type=='add-sku' || docData.type=='update-sku' || docData.type=='add-sku-to-catalog' || docData.type=='update-sku-to-catalog'){
        this.router.navigateByUrl(`/dashboard/product/sku/draft-edit/${docData.id}`)
      }
      if(docData.type=='add-brand'){
        this.router.navigateByUrl(`/dashboard/brand/edit/${docData.id}`)
      }
      if(docData.type=='add-attribute'){
        this.router.navigateByUrl(`/dashboard/attribute/edit/${docData.id}`)
      }
      if(docData.type=='add-variant-type'){
        this.router.navigateByUrl(`/dashboard/variantType/edit/${docData.id}`)
      }
      if(docData.type=='add-variant-value'){
        this.router.navigateByUrl(`/dashboard/variantValue/${docData.payload.variants_id}/edit/${docData.id}`)
      }
      if(docData.type=='add-category'){
        this.router.navigateByUrl(`/dashboard/category/edit/${docData.id}`)
      }
      else{

      }
  }
  openFilter() {
    let routedata:any = {name:'',status:''}
    if (this.route.snapshot.queryParams) {
       routedata = this.route.snapshot.queryParams
    }
    const dialogRef = this.dialog.open(VendorApprovalRequestsFilterDialogComponent, {
      data: routedata,
    });

    dialogRef.afterClosed().pipe(
      takeUntil(this.unsubscribe$) // Unsubscribe when the component is destroyed
    )
    .subscribe((result) => {
     if (result) {
      this.filterData(result)
      }
    });
  }

  filterData(result:any){
    let convertedString:any;
    if(result.type){
      convertedString = result.type.toLowerCase().replace(/\s+/g, '-');
    }
    if(result.name || result.status || result.type){
      this.checkQueryParam = true;
    if(result.name){
      this.filterList = this.pendingList.filter((item:any) =>
   item?.payload?.name ?  item?.payload?.name?.toLowerCase().includes(result.name?.toLowerCase()) : item?.payload?.sku?.name?.toLowerCase().includes(result.name?.toLowerCase())
    );
  if(result.name && result.status ){
    this.filterList =  this.filterList.filter((item:any) =>
    item?.status?.toLowerCase().includes(result.status.toLowerCase())
    );
  }
    if(result.name && result.type ){
      this.filterList =  this.filterList.filter((item:any) =>
      item?.type?.toLowerCase().includes(convertedString.toLowerCase())
      );
  }
  }
   if(result.type){
    this.filterList =  this.pendingList.filter((item:any) =>
    item?.type?.toLowerCase().includes(convertedString.toLowerCase())
    );
    if(result.type && result.status ){
      this.filterList =  this.filterList.filter((item:any) =>
      item?.status?.toLowerCase().includes(result.status.toLowerCase())
      );
    }
      if(result.type && result.name ){
        this.filterList = this.filterList.filter((item:any) =>
        item?.payload?.name ?  item?.payload?.name?.toLowerCase().includes(result.name?.toLowerCase()) : item?.payload?.sku?.name?.toLowerCase().includes(result.name?.toLowerCase())
         );
    }
  }
  if(result.status){
    this.filterList =  this.pendingList.filter((item:any) =>
    item?.status?.toLowerCase().includes(result.status.toLowerCase())
    );
    if(result.status && result.type ){
      this.filterList =  this.filterList.filter((item:any) =>
      item?.type?.toLowerCase().includes(convertedString.toLowerCase())
      );
    }
      if(result.status && result.name ){
        this.filterList = this.filterList.filter((item:any) =>
        item?.payload?.name ?  item?.payload?.name?.toLowerCase().includes(result.name?.toLowerCase()) : item?.payload?.sku?.name?.toLowerCase().includes(result.name?.toLowerCase())
         );
    }
  }
  }
  else{
   this.filterList = this.pendingList
   this.checkQueryParam = false;
  }
   }
}
