<ng-container>
  <div class="col-md-12 d-flex">
    <div class="col-md-6">
      <h2 class="card-title m-3 text-violet">Add Category Margin</h2>
    </div>
    <div class="col-md-6">
      <mat-icon (click)="dialogRef.close()" class="float-right m-3 closeicon">close</mat-icon>
    </div>
  </div>
  <div class="col-md-12">
    <form [formGroup]="dataSource" class="m-3">
      <section id="content">
        <div class="container">
          <mat-card>
            <mat-card-content>
              <div class="row">
                <div class="col-md-12 d-flex">
                  <div class="col-md-4" *ngIf="!data.isEdit">
                    <mat-form-field class="full-width">
                      <mat-label>Select Category</mat-label>
                      <mat-select formControlName="category">
                        @for (category of categories; track category) {
                        <mat-option [value]="category">{{
                          category?.attributes.name
                          }}</mat-option>
                        }
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-md-4" *ngIf="data.isEdit">
                    <mat-form-field class="full-width">
                      <mat-label>Select Category</mat-label>
                      <input readonly="true" matInput type="string" formControlName="category" name="category"
                        placeholder="Category Value" />
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field class="full-width">
                      <mat-label>margin Percentage</mat-label>
                      <input matInput type="number" formControlName="categoryMargin" name="categoryValue"
                        placeholder="Percentage"/>
                    </mat-form-field>
                  </div>
                  <div class="col-md-2">
                    <a mat-raised-button color="primary" style="width: 30%; margin-top: 9px;"
                      (click)="addCategoryMargin()">
                      Add
                    </a>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </section>
    </form>
  </div>
</ng-container>
