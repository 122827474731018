import { Component, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatChipsModule } from '@angular/material/chips';
import { AuthService } from '../../../services/auth.service';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatCommonModule } from '@angular/material/core';
import { CommonModule } from '@angular/common';
import { AppLoaderService } from '../../../shared/app-loader/app-loader.service';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { PageId } from '../../../constants/enums';
import { MatSidenavModule } from '@angular/material/sidenav';
import { DeepLinksService } from '../services/deep-links.service';
@Component({
  selector: 'app-deep-links-list',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    RouterLink,
    MatCheckboxModule,
    FormsModule,
    MatChipsModule,
    MatCommonModule,
    MatPaginatorModule,
    MatSidenavModule,
    CommonModule,
  ],
  templateUrl: './deep-links-list.component.html',
  styleUrl: './deep-links-list.component.scss',
})
export class DeepLinksListComponent extends BaseListComponent {
  permissionObj: any;
  status: number = 1;
  totalDeeplink: number = 0;
  dataSource!: MatTableDataSource<any>;
  displayedColumns = ['deepLinkType', 'date', 'title', 'link', 'description'];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private deepLinksService: DeepLinksService,
    private loader: AppLoaderService
  ) {
    super(auth, router, route, PageId.deep_links);
  }

  override async ngOnInit() {
    await super.ngOnInit();
    this.getDeepLinks();
  }
  async getDeepLinks() {
    const res = await this.deepLinksService.getAllDeeplinks();
    this.dataSource = new MatTableDataSource(res);
    console.log('all deep links are the', res);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  getDate(date: any) {
    const milliseconds = date.seconds * 1000 + date.nanoseconds / 1000000;
    const newDate = new Date(milliseconds);
    const formattedDate = newDate.toLocaleString();
    return formattedDate;
  }
}
