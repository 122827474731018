<div class="heading-block">
  <div class="row">
    <div>
      <p class="p-2" [routerLink]="['/dashboard/merchant']">/Area Configuration</p>
    </div>
    <div class="col-md-6 text-right"></div>
  </div>
  <div class="container">

    <div class="card m-3">
      <div class="card-body card-padding">

        <div class="grid-container">
          <ng-container *ngFor="let item of dataSource; let i = index">
            <div class="grid-item" *ngIf="i < 48">
              <input type="checkbox" id="{{ item.id }}" [(ngModel)]="item.isSelected">
              <label class="ml-10" for="{{ item.id }}">{{ item.name }}</label>
            </div>
          </ng-container>
        </div>


        <div class="text-center mt-100">
          <button mat-raised-button color="primary" (click)="savePolygons()">
            Save
          </button>
        </div>

      </div>
    </div>
  </div>
</div>