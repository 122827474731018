<!-- <h1 mat-dialog-title>upload</h1> -->
<div mat-dialog-content>
  <div class="panel panel-default" style="width: 100%">
    <div class="panel-body">
      <div class="card cardcss">
        <div class="card-body scroll-55-rem">
          <div class="row">
            <div class="row">
              <div class="col-md-6">
                <h2 class="card-title m-3 text-violet">Products</h2>
              </div>
              <div class="col-md-6 text-right">
                <mat-icon (click)="cancelProducts()" class="float-right m-3 closeicon closeicon">
                  close
                </mat-icon>
              </div>
            </div>
            <div class="col-md-8" style="width: 98%; margin: 20px;">
              <dl class="dl-horizontal">
                <dt>Upload Excel</dt>
                <dd>
                  <input type="file" class="p-t-10" id="file-upload" (change)="onFileSelect($event)"
                    accept=".xlsx, .xls, .csv" />
                </dd>
              </dl>
            </div>
          </div>
          <table class="table my-3 customertable" *ngIf="productEntities.length > 0">
            <thead>
              <tr>
                <th class="heading">SKU ID</th>
                <th class="heading">SKU Name</th>
              </tr>
            </thead>
            <tbody>
              <tr class="clickable-row" *ngFor="let product of productsFromExcel">
                <td>{{product.skuId}}</td>
                <td>{{product.productName}}</td>
              </tr>
            </tbody>
          </table>
          <div class="row" *ngIf="productEntities.length > 0">
            <div class="col-md-12">
              <div class="d-flex justify-content-center">
                <button mat-raised-button color="primary" id="sa-title" (click)="setProducts()">Upload</button>
                <!--  <button
                class="btn btn-info waves-effect waves-button waves-float"
                id="sa-title"

                (click)="setProducts()"
              >
                OK
              </button> -->
                <button mat-raised-button color="warn" id="sa-title" (click)="cancelProducts()"
                  style="margin-left: 10px;"> Cancel</button>
                <!-- <button
                class="btn btn-default waves-effect waves-button waves-float"
                id="sa-title"

                (click)="cancelProducts()"
              >
                Cancel
              </button> -->
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div mat-dialog-actions>
  <button mat-button>No Thanks</button>
  <button mat-button>Ok</button>
</div> -->
