import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { ConfigService } from '../../../config/services/config.service';
import { PurchaseReceiptService } from '../../service/purchasereceipt.service';
import { CommonModule } from '@angular/common';
import { SwalMessageTypes, UOM } from '../../../../enums/enums';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { addGrn } from '../../models/purchasereceipt-model';

@Component({
  selector: 'app-add-grn',
  standalone: true,
  imports: [
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatCardModule,
    MatTableModule,
    RouterLink,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
  ],
  templateUrl: './add-grn.component.html',
  styleUrl: './add-grn.component.scss',
})
export class AddGrnComponent {
  form: FormGroup;
  prId: string = '';
  rowId: string = '';
  skuDetails: any;
  constructor(
    public route: ActivatedRoute,
    public dialogRef: MatDialogRef<AddGrnComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private purchaseReceiptService: PurchaseReceiptService,
    public fb: FormBuilder
  ) {
    this.form = this.fb.group({
      skuName: '',
      buyinguom: '',
      upc: '',
      requestedQty: null,
      actualQty: null,
      mrp: null,
      requestedUomQty: null,
      actualUomQty: null,
    });
  }
  Uom: { name: string; value: UOM }[] = [
    { name: 'Case', value: UOM.Case },
    { name: 'PCs', value: UOM.PCs },
    { name: 'Bag', value: UOM.Box },
  ];

  async ngOnInit() {
    console.log('row id is', this.data);

    this.getPrSku(this.data);
  }
  async getPrSku(id: string) {
    console.log('skus row iss', id);
    this.skuDetails = await this.purchaseReceiptService.getPrSkuDetails(id);
    console.log('sku details are ', this.skuDetails);

    this.form = this.mapToSkuDetails(this.skuDetails);
    const buyingUomControl = this.form.get('buyinguom') as FormControl;
    buyingUomControl.disable();
  }

  mapToSkuDetails(res: any): FormGroup {
    return this.fb.group({
      skuName: new FormControl(res.sku_name),
      buyinguom: new FormControl(res.uom_type || ''),
      upc: new FormControl(res.upc || ''),
      requestedQty: new FormControl(res.requested_in_pcs || ''),
      actualQty: new FormControl(res.actual_in_pcs || ''),
      requestedUomQty: new FormControl(res.uom_requested_qty || ''),
      actualUomQty: new FormControl(res.uom_actual_qty || ''),
      mrp: new FormControl(res.mrp || ''),
    });
  }
  async addGrn() {
    if (!this.validations()) {
      return;
    }
    const skuDetails = this.form.value;
    const skuUpdateDetails = this.mapToSku(skuDetails);
    const res = await this.purchaseReceiptService.updateSkuDetails(
      this.data,
      skuUpdateDetails
    );

    console.log('res', res);
    this.dialogRef.close();
  }
  private validations(): boolean {
    const negativeFields = this.validateNegativeValues(this.form.value);

    if (negativeFields.length) {
      this.showMessage(
        `${negativeFields} ,have negative values Please Check`,
        SwalMessageTypes.Warning
      );

      return false;
    }
    if (this.hasDecimal(this.form.value.actualUomQty)) {
      this.showMessage(
        `Actual UOM Qty should not have decimal value`,
        SwalMessageTypes.Warning
      );
      return false;
    }

    if (this.hasDecimal(this.form.value.actualQty)) {
      this.showMessage(
        `Actual Qty in Pcs should not have decimal value`,
        SwalMessageTypes.Warning
      );
      return false;
    }
    if (this.form.value.actualQty <= 0) {
      this.showMessage(
        `Actual Qty in Pcs should be greater than zero`,
        SwalMessageTypes.Warning
      );
      return false;
    }
    return true;
  }
  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  private validateNegativeValues(data: addGrn): string[] {
    const numericInputs = [
      { name: 'Actual UOM Qty', value: data.actualUomQty },
      { name: 'Actual Qty in Pcs', value: data.actualQty },
    ];

    const negativeFields = numericInputs
      .filter((input) => input.value < 0)
      .map((input) => input.name);

    return negativeFields;
  }
  hasDecimal(number: number) {
    return /\./.test(number.toString());
  }
  mapToSku(product: any) {
    return {
      id: this.data,
      actual_in_pcs: product.actualQty,
      uom_actual_qty: product.actualUomQty,
    };
  }
  async calculatePcs(event: Event): Promise<void> {
    {
      const actualUomQty: number = parseInt(
        (event.target as HTMLInputElement).value
      );
      console.log('key word', actualUomQty);
      let upc = this.form.get('upc')?.value;
      let uom = this.form.get('buyinguom')?.value;
      if (uom == UOM.Box || uom == UOM.Case) {
        const pc = actualUomQty * upc;
        this.form.patchValue({
          actualQty: pc || 0,
        });
      } else {
        this.form.patchValue({
          actualQty: actualUomQty,
        });
      }
    }
  }
}
