import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { RouterLink, RouterOutlet } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppLoaderService } from '../../../shared/app-loader/app-loader.service';
import Swal from 'sweetalert2';
import { UTRSummeryReport } from '../../../enums/enums';
import { ExcelExportService } from '../../../services/excel.service';
import { BmsReportsService } from '../services/bms-reports.service';
import { SummaryDictItem } from '../models/bms-reports-model';

@Component({
  selector: 'bulk-import-pop-up',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatButtonModule,
    RouterOutlet,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatCardModule,
    MatIconModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    CommonModule,
    MatTableModule,
    RouterLink,
    ReactiveFormsModule,
  ],
  providers: [AppLoaderService],
  templateUrl: './upload-utr-reports-pop-up.component.html',
})
export class UploadUtrReportsPopUp implements OnInit {
  isExcelFileInvalid: boolean = false;
  selectedFile: any;
  selectedFileName: string | null = null;
  summeryReportData: any = {};
  invalidUtr: string = '';
  isInvalidUtr: boolean = false;
  enableSave: boolean = false;
  utrData: SummaryDictItem[] = [];

  constructor(
    public dialogRef: MatDialogRef<UploadUtrReportsPopUp>,
    private egretLoader: AppLoaderService,
    private excelService: ExcelExportService,
    private bmsReportsService: BmsReportsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  onSelectExcelFile(event: any): void {
    this.invalidUtr = '';
    this.isInvalidUtr = false;
    this.enableSave = false;

    const file = event?.target?.files?.[0];
    if (!file) {
      this.showWarningMessage('Please Upload Excel File.');
      return;
    }

    this.selectedFile = file;
    console.log('Selected file:', this.selectedFile.name);

    this.importUtrReport();
  }

  async importUtrReport(): Promise<void> {
    try {
      if (!this.selectedFile) {
        this.showWarningMessage('Please Upload Excel File.');
        return;
      }

      const sheets = await this.excelService.readExcelDataMultipleSheets(
        this.selectedFile
      );
      this.summeryReportData = {
        summary: [],
        sellerPayoutDetails: [],
      };

      for (const sheetName in sheets) {
        if (sheets.hasOwnProperty(sheetName)) {
          const sheetData = sheets[sheetName];
          this.processSheetData(sheetName, sheetData);
        }
      }

      const filteredSummeryDict = this.filterSummaryDict();
      console.log(filteredSummeryDict);

      if (filteredSummeryDict.length == 0) {
        Swal.fire('', 'Excel should not be empty', 'warning');
        return;
      }

      // const invalidUtrNumbers = this.validateUtrNumbers(filteredSummeryDict);

      // if (invalidUtrNumbers.length > 0) {
      //   this.invalidUtr = invalidUtrNumbers.join(', ');
      //   this.isInvalidUtr = true;
      //   return;
      // }

      this.enableSave = true;
      this.utrData = filteredSummeryDict;

      // bmsReportsService
      //NEED TO CALL API HERE TO UPLOAD SHEET
    } catch (error) {
      console.error('Error uploading Excel:', error);
    } finally {
      this.resetFileInput();
      this.selectedFileName = null;
    }
  }

  private showWarningMessage(message: string): void {
    Swal.fire('', message, 'warning');
  }

  private processSheetData(sheetName: string, sheetData: any[]): void {
    switch (sheetName) {
      case UTRSummeryReport.Summary:
        this.summeryReportData.summary = this.mapSummaryData(sheetData);
        break;
      case UTRSummeryReport.SellerPayoutDetails:
        this.summeryReportData.sellerPayoutDetails = this.mapSellerPayoutData(
          sheetData
        );
        break;
      default:
        break;
    }
  }

  private mapSummaryData(sheetData: any[]): SummaryDictItem[] {
    return sheetData.map((row) => ({
      vendorId: row['Vendor Id'],
      utrNumber: row['UTR Number'],
      ids: [],
    }));
  }

  private mapSellerPayoutData(sheetData: any[]): any[] {
    return sheetData.map((row) => ({
      vendorId: row['Vendor Id'],
      id: row['Id'],
    }));
  }

  private filterSummaryDict(): SummaryDictItem[] {
    const summeryDict: {
      [key: string]: SummaryDictItem;
    } = this.summeryReportData.summary.reduce(
      (
        acc: { [key: string]: SummaryDictItem },
        item: { vendorId: string; utrNumber: string }
      ) => {
        acc[item.vendorId] = {
          utrNumber: item.utrNumber,
          vendorId: item.vendorId,
          ids: [],
        };
        return acc;
      },
      {}
    );

    this.summeryReportData.sellerPayoutDetails.forEach(
      (item: { vendorId: string; id: string }) => {
        if (summeryDict[item.vendorId]) {
          summeryDict[item.vendorId].ids = summeryDict[
            item.vendorId
          ].ids.concat(item.id.split(','));
        }
      }
    );

    const result: SummaryDictItem[] = Object.values(summeryDict).filter(
      (i) => i.utrNumber !== ''
    );

    return result.filter((item) => item.ids.length !== 0);
  }

  isValidUtrNumber(utrNumber: string): boolean {
    return utrNumber.length <= 30;
  }

  validateUtrNumbers(data: SummaryDictItem[]) {
    const invalidUtrs: string[] = [];
    data.forEach((item) => {
      if (item.utrNumber != undefined) {
        if (!this.isValidUtrNumber(item.utrNumber)) {
          invalidUtrs.push(item.utrNumber);
        }
      }
    });
    return invalidUtrs;
  }

  async saveUts() {
    const utrUpdateData = this.utrData.filter(
      (item: SummaryDictItem) => item.utrNumber !== undefined
    );
    await this.bmsReportsService.updateBulkUtr(utrUpdateData);
    const message = 'updated successfully';
    Swal.fire('', message, 'success');
    this.closeDialog();
  }

  resetFileInput() {
    const fileInput = document.getElementById('excelInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
