import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { CompanyService } from '../services/company.service';
import { lastValueFrom, takeUntil } from 'rxjs';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { AuthService } from '../../../services/auth.service';
import { ProductService } from '../../product/services/product.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from '../../../services/common.service';
import { MESSAGE_QUEUE_TYPE } from '../../../constants/message-queue-types';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { PageId } from '../../../constants/enums';

@Component({
  selector: 'app-company-add-edit',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    RouterLink,
    ReactiveFormsModule,
    MatIconModule
  ],
  templateUrl: './company-add-edit.component.html',
  styleUrl: './company-add-edit.component.scss',
})
export class CompanyAddEditComponent extends BaseListComponent {
  companyForm: any;
  filename: any;
  previewImage: any;
  requestImageBody: any;
  id: any;
  comment: any = ""
  constructor(
    route: ActivatedRoute,
    router: Router,
    auth: AuthService,
    public fb: FormBuilder,
    public companyService: CompanyService,
    public location: Location,
    public productService: ProductService,
    public commonService: CommonService
  ) {
    super(auth, router, route, PageId.bms_company);
    this.companyForm = this.fb.group({
      name: ['', Validators.required],
      display_name: ['', Validators.required],
      image_url: ['', Validators.required],
    });
  }
  override async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.id = params['id'];
      if (this.id) {
        this.patchValue();
      }
    });
  }
  async patchValue() {
    // this.commonService.hideLoder();
    // this.commonService.showLoder();
   
    if (this.hasBMSAdmin) {
      let res: any = (await this.companyService.getSingle(this.id) as any).data[0].attributes
      this.companyForm.patchValue(res);
      this.previewImage = res.image_url;
    }
    if (this.hasSellerAdmin) {
      this.productService.getSingleApprovalDoc(this.id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((docData: any) => {
          let res: any = docData.payload
          this.companyForm.patchValue(res);
          this.previewImage = res.image_url;
          this.comment = docData?.comment
        },
          (error: any) => {
            this.commonService.showToaster("error", error.message)
            this.commonService.hideLoder()
          });
    }
    // this.commonService.hideLoder();
  }
  async submit() {
    this.commonService.showLoder();
    try {
      if (this.requestImageBody) {
        let res: any = await lastValueFrom(
          this.companyService.upload(this.requestImageBody)
        );
        this.companyForm.patchValue({
          image_url: res.data.url,
        });
        this.commonService.hideLoder();
      }
      try {
        if (this.id) {
          if (this.hasBMSAdmin) {
            await this.companyService.update(this.id, this.companyForm.value);
            this.commonService.showToaster("success", 'Updated Successfully');
            this.router.navigateByUrl('/dashboard/company');
          }
          if (this.hasSellerAdmin) {
            this.productService.reSubmitRequestForApproval(this.id, this.companyForm.value);
            this.commonService.showToaster("success", 'Your request is submitted for approval');
            this.router.navigateByUrl('/dashboard/my-requests');
          }
        } else {
          if (this.hasBMSAdmin) {
            const res = await this.companyService.add(this.companyForm.value);
            const companyId = res[0].id;
            const messageQueueResponse = await this.saveCompanyToPg([companyId]);
            this.commonService.showToaster("success", 'Added Successfully');
            this.router.navigateByUrl('/dashboard/company');
          }
          if (this.hasSellerAdmin) {
            this.productService.addToPendingApproval("add-company", this.companyForm.value, "pending-approval");
            this.commonService.showToaster("success", 'Your request is submitted for approval');
            this.router.navigateByUrl('/dashboard/my-requests');
          }

        }
        this.companyForm.reset();
        this.commonService.hideLoder();

      } catch (err: any) {
        console.log(err);
        this.commonService.showToaster("error", err.error.message);
        this.commonService.hideLoder();
      }
    } catch (err: any) {
      console.log(err);
      this.commonService.showToaster("error", err.error.message);
      this.commonService.hideLoder();
    }
  }

  back() {
    this.location.back();
  }
  onFileChange(event: any) {
    if (event.target.files) {
      const file = event.target.files[0];
      this.filename = file.name;
      var reader = new FileReader();
      reader.onload = (event) => {
        this.previewImage = event?.target?.result;
        this.companyForm.patchValue({
          image_url: event?.target?.result,
        });
        const parts = this.previewImage.split(',');
        let base64String = parts[1];
        this.requestImageBody = {
          fileName: this.filename,
          fileExtension: this.getFileExtension(this.filename),
          fileData: base64String,
        };
      };
      reader.readAsDataURL(file);
    }
  }

  getFileExtension(filename: any) {
    return filename.split('.').pop();
  }

  removeImage() {
    this.previewImage = null;
    this.filename = null;
    this.requestImageBody = null;
    this.companyForm.patchValue({
      image_url: null
    });
    var fileInput: any = document.getElementById('fileInput');
    fileInput.value = '';
  }

  async saveCompanyToPg(companyIds: string[]) {
    const messageQueueResponse = await this.companyService.postMessageToMessageQueue(MESSAGE_QUEUE_TYPE.CREATE_COMPANY, { companyIds: companyIds });
    return messageQueueResponse;
  }
}
