<div class="row">
  <div class="row m-2">
    <div class="col-md-9">
      <h2 class="card-title m-3">Master Geo List</h2>
    </div>
    <div class="col-md-12">
      <button mat-raised-button type="button" class="m-2" (click)="openPopUp()" color="primary" style="float: right;">
        Add Area
      </button>
    </div>
  </div>
  <div class="row maindiv">
    <div class="card">
      <div class="card-body card-padding">
        <ng-container *ngIf="dataSource.length > 0">
          <mat-list>
            <mat-list-item *ngFor="let cluster of dataSource; let i = index">
              <div class="list-item-content">
                <div class="serial-number">{{ i + 1 }}.</div>
                <div class="cluster-name">{{ cluster.name }}</div>
              </div>
            </mat-list-item>
          </mat-list>
        </ng-container>
        <ng-template *ngIf="dataSource && dataSource.length == 0">
          <div class="no-data-text">There are no areas Added.</div>
        </ng-template>
      </div>
    </div>
  </div>


</div>