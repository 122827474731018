import { map } from 'rxjs';
import { Component, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSidenavModule } from '@angular/material/sidenav';
import Swal from 'sweetalert2';
import { AppLoaderService } from '../../../../../shared/app-loader/app-loader.service';
import { AuthService } from '../../../../../services/auth.service';
import {
  DownloadBillType,
  DownloadEWayType,
  DownloadInvoiceType,
  SwalMessageTypes,
  TripStatus,
} from '../../../../../enums/enums';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { TripService } from '../../services/trip.service';
import { MatDialog } from '@angular/material/dialog';
import { TripSearchPopupComponent } from '../trip-search-popup/trip-search-popup.component';
import { BaseListComponent } from '../../../../../shared/core/base.list.component';
import { PageId } from '../../../../../constants/enums';
import { ReplaceCommasPipe } from '../../../../../shared/pipes/currency-pipe';
import { MESSAGE_QUEUE_TYPE } from '../../../../../constants/message-queue-types';
import { MerchantsService } from '../../../../merchant/services/merchants.service';
import { MarginsService } from '../../../../settings/vendor-list/services/margins.service';
import { DownloadEWaySheet } from '../../models/trip.model';

@Component({
  selector: 'app-trip-summarys-list',
  standalone: true,
  templateUrl: './trip-summarys-list.component.html',
  styleUrls: ['./trip-summarys-list.component.scss'],
  imports: [
    MatCardModule,
    MatButtonModule,
    RouterLink,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatMenuModule,
    ReplaceCommasPipe,
  ],
})
export class TripSummaryListComponent extends BaseListComponent {
  tripStatus = Object.values(TripStatus).filter(
    (value) => typeof value === 'number'
  );
  // form: FormGroup;
  downloadOptions: string[] = [
    'Pickup Indent',
    'Invoice',
    'E - Way Bill',
    'E - Invoice',
    'Route Sheet',
  ];
  generateOptions: string[] = ['Invoices', 'E - Way Bill', 'E - Invoices'];
  statusId: number = 0;
  @ViewChild('downloadOptionsMenu') downloadOptionsMenu!: MatMenuTrigger;
  @ViewChild('generateOptionsMenu') generateOptionsMenu!: MatMenuTrigger;
  dataSource: any;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  tripId!: string;
  merchantId: any;

  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    public readonly tripService: TripService,
    private loader: AppLoaderService,
    private dialog: MatDialog,
    public readonly merchantService: MerchantsService,
    private vendorService: MarginsService
  ) {
    super(auth, router, route, PageId.logistics_trips);
    this.dataSource = this.fb.group({
      tripName: '',
      tripDate: '',
      deNumber: '',
      driverNumber: '',
      status: '',
      driverName: [{ value: '', disabled: false }],
      vehicleNumber: [{ value: '', disabled: false }],
      deName: [{ value: '', disabled: false }],
    });
  }

  override async ngOnInit() {
    super.ngOnInit();
    this.route.params.subscribe(async (params) => {
      this.tripId = params['id'];
    });
    await this.getByTripId(this.tripId);
    await this.getVendorId();
    console.log('trip status is the ', this.statusId);
  }

  async openDialog(fieldName: string, fieldValue: any): Promise<void> {
    const title = 'Search';
    const tripId = this.tripId;
    const dialogRef = this.dialog.open(TripSearchPopupComponent, {
      height: '30%',
      width: '35%',
      data: { tripId, fieldName: fieldName, fieldValue: fieldValue },
    });
    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result && fieldName === 'Driver Name') {
        this.dataSource.get('driverName')?.setValue(result);
      } else if (result && fieldName === 'Vehicle Number') {
        this.dataSource.get('vehicleNumber')?.setValue(result);
      } else if (result && fieldName === 'DE Name') {
        this.dataSource.get('deName')?.setValue(result);
      }
      await this.getByTripId(this.tripId);
    });
  }
  async getByTripId(id: string) {
    try {
      this.loader.open();
      const response = await this.tripService.getTripById(id);
      this.dataSource = this.mapFormData(response[0]);
      this.statusId = this.dataSource?.value?.statusId;
      this.loader.close();
    } catch (error) {
      this.loader.close();
      this.showMessage(
        'Failed to Fetch Trip Summary. Please try again later.',
        SwalMessageTypes.Warning
      );
    }
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  }

  mapFormData(data: any): any {
    const dispatchValue = parseFloat(data.dispatch_value) || 0;
    const cashCollections = parseFloat(data.cash_collections) || 0;
    const onlineCollections = parseFloat(data.online_collections) || 0;
    const returnsCollections = parseFloat(data.returns_collections) || 0;

    const retailDeliveryValue =
      data?.summary?.value?.retail_delivery_value ?? 0;
    const vendorPickupValue = data?.summary?.value?.vendor_pickup_value ?? 0;
    const vendorReturnsValue = data?.summary?.value?.vendor_returns_value ?? 0;
    const warehouseReturnValue =
      Math.round(data?.summary?.value?.warehouse_return_value) ?? 0;
    const retailCashCollections =
      Math.round(data?.summary?.collection?.retail_cash_collections) ?? 0;
    const retailOnlineCollections =
      Math.round(data?.summary?.collection.retail_online_collections) ?? 0;
    // CD - (CR + CCOD + CON)
    const balance =
      retailDeliveryValue -
      warehouseReturnValue -
      retailCashCollections -
      retailOnlineCollections;

    // dispatchValue - cashCollections - onlineCollections - returnsCollections;
    const pickUp = parseFloat(data.summary?.pickup?.hops_count || 0);
    const pickupTotalWeight =
      Math.round(parseFloat(data.summary?.pickup?.total_weight || 0) * 100) /
      100;
    return this.fb.group({
      tripName:
        (data.cluster ? data.cluster + '_' : '') + (data.sequence || ''),
      deName: data.delivery_agent_name || 'Unassigned',
      driverName: data.driver_name || 'Unassigned',
      vehicleNumber: data.vehicle_number || 'Unassigned',
      tripDate: this.formatDate(data.target_date) || '',
      deNumber: data.delivery_agent_mobile_number || 0,
      driverNumber: data.driver_mobile_number || 0,
      statusId: data?.status,
      status: this.getPlanningStatusText(data.status) || '',
      deliveryCount: data.summary?.delivery?.hops_count || 0,
      pickupCount: pickUp,
      returnsCount: data.summary?.return?.hops_count || 0,
      kms: data.kms || 0,
      pickupTotalWeight: pickupTotalWeight,
      valueSummary: {
        retailDeliveryValue,
        vendorPickupValue,
        vendorReturnsValue,
        warehouseReturnValue,
      },
      collectionSummary: {
        retailCashCollections,
        retailOnlineCollections,
      },
      dispatchValue: Math.round(dispatchValue),
      cashCollections: Math.round(cashCollections),
      onlineCollections: Math.round(onlineCollections),
      returnsCollections: Math.round(returnsCollections),
      balance: Math.round(balance),
      productsCount: data.skus_count || 0,
      uniqueSkusCount: data.unique_skus_count,
    });
  }

  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  openDownloadOptions() {
    this.downloadOptionsMenu.openMenu();
  }

  openGenerateOptions() {
    this.generateOptionsMenu.openMenu();
  }

  async onGenerateOptionClick(optionName: string) {
    if (this.dataSource.pickupCount > 1) {
      Swal.fire(`Can't generate Invoice from pickup Trips`, 'waring');

      return;
    }
    if (optionName === 'Invoices') {
      console.log('Generate ' + optionName);
      await this.generateInvoices();
    }
    if (optionName === 'E - Way Bill') {
      console.log('Generate ' + optionName);
      await this.generateEWayBill();
    }
    if (optionName === 'E - Invoices') {
      console.log('Generate ' + optionName);
      await this.generateEInvoice();
    }
  }

  async getVendorId() {
    const body = {
      id: '',
      name: '',
      mobileNumber: '',
      city: '',
      businessType: 5,
      pageNumber: 1,
      pageSize: 10,
    };
    const response = await this.merchantService.getMerchants(body);
    if (response && response['merchantList']) {
      this.merchantId = response['merchantList'][0];
    }
    return [];
  }

  private async generateEWayBill() {
    const vehicleNumber = this.dataSource?.get('vehicleNumber')?.value;
    if (vehicleNumber === 'Unassigned') {
      Swal.fire('', 'Please assign the vehicle for this trip', 'warning');
      return;
    }
    try {
      const body = {
        vehicle: vehicleNumber,
        type: DownloadBillType.GenerateEwayBill,
        vendor_id: this.merchantId.id,
        trip_ids: [this.tripId],
      };
      await this.tripService.generateEWayBill(body);
      Swal.fire(
        '',
        `E Way Bill Generation Process Started will generate in 10 mins`,
        'success'
      );
    } catch (error) {}
  }

  private async generateEInvoice() {
    try {
      const body = {
        type: DownloadInvoiceType.GenerateEInvoice,
        vendor_id: this.merchantId.id,
        trip_ids: [this.tripId],
      };
      await this.tripService.generateEInvoice(body);
      Swal.fire(
        '',
        `E Way Invoice Generation Process Started will generate in 10 mins`,
        'success'
      );
    } catch (error) {}
  }

  private async generateInvoices() {
    try {
      const response = await this.tripService.postMessageToMessageQueue(
        MESSAGE_QUEUE_TYPE.GENERATE_INVOICE_BY_TRIP_ID,
        { tripIds: [this.tripId] }
      );
      if (response) {
        Swal.fire(
          '',
          `Invoice Generation Process Started will generate in 10 mins`,
          'success'
        );
      } else {
        Swal.fire(
          '',
          'Invoice Generation failed please try once again',
          'error'
        );
      }
    } catch (error) {
      Swal.fire('', 'Invoice Generation failed please try once again', 'error');
      throw error;
    }
  }

  async onDownloadOptionClick(optionName: string) {
    //  ['Pickup Indent', 'Invoice'];
    try {
      if (optionName == 'Pickup Indent') {
        await this.pickupIndentSheet();
      }
      if (optionName == 'Invoice') {
        await this.downloadInvoice();
      }
      if (optionName == 'Route Sheet') {
        await this.downloadRouteSheet();
      }
      if (optionName == 'E - Way Bill') {
        let type = 2;
        await this.downloadEWayPdf(type);
      }
      if (optionName == 'E - Invoice') {
        let type = 1;
        await this.downloadEWayPdf(type);
      }
    } catch (error) {}
  }

  async pickupIndentSheet() {
    const res = {
      type: 2, //TO DO NEED TO FIX THIS BASED ON SELECTED OPTION(NEED TO GIVE OPTION ALSO)
      tripIds: [this.tripId],
    };
    await this.tripService.downloadIndentSheet(res);
    Swal.fire('success', 'Downloaded Indent Sheet Successfully', 'success');
  }

  async downloadEWayPdf(type: number) {
    const body: DownloadEWaySheet = {
      vendor_id: this.merchantId.id,
      trip_ids: [this.tripId],
      type:
        type === DownloadEWayType.DownloadEWayBill
          ? DownloadEWayType.DownloadEWayBill
          : DownloadEWayType.DownloadEWayInvoice,
    };
    await this.tripService.downloadEWaySheet(body);
    Swal.fire(
      '',
      `Downloaded ${
        body.type === 1 ? 'E invoice' : 'E Way Bill'
      } successfully`,
      'success'
    );
  }

  async downloadInvoice() {
    try {
      await this.tripService.downloadInvoices(
        this.tripId,
        this.dataSource.value.tripName
      );
      Swal.fire('success', 'Downloaded Invoice Successfully', 'success');
    } catch (error) {
      throw error;
    }
  }

  async downloadRouteSheet() {
    try {
      await this.tripService.downloadRouteSheet([this.tripId]);
      Swal.fire('success', 'Route Sheet Downloaded Successfully', 'success');
    } catch (error) {
      throw error;
    }
  }

  getStatusName(status: number | string): string {
    return typeof status === 'number' ? TripStatus[status] : status;
  }

  getPlanningStatusText(value: number): string {
    switch (value) {
      case TripStatus.NotStarted:
        return 'Not Started';
      case TripStatus.InProgress:
        return 'In Progress';
      case TripStatus.Completed:
        return 'Completed';
      default:
        return 'Unknown Status';
    }
  }

  async tripOptimization() {
    try {
      const body = {
        trip_id: this.tripId,
      };
      if (this.statusId == 1) {
        const response = await this.tripService.tripOptimization(body);
        console.log(response);
        this.showMessage(
          'Hop Numbers successfully updated ',
          SwalMessageTypes.Success
        );
      } else {
        const msg =
          this.statusId == 2
            ? 'Trip already started'
            : 'Trip already completed';

        this.showMessage(msg, SwalMessageTypes.Warning);
      }
    } catch (error) {
      this.showMessage(
        'Failed to update the hop numbers',
        SwalMessageTypes.Warning
      );
    }
  }
}
