import { Component, OnInit } from "@angular/core";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";

import { MatCardModule } from "@angular/material/card";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { MatIconModule } from "@angular/material/icon";
import { CommonModule } from "@angular/common";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatRadioModule } from "@angular/material/radio";
import Swal from 'sweetalert2';

import { PromotionsService } from '../../services/promotions.service';
import { AppUrlType, PromotionType } from '../../../../enums/enums';
import { BaseListComponent } from "../../../../shared/core/base.list.component";
import { AuthService } from "../../../../services/auth.service";
import { PageId } from "../../../../constants/enums";


@Component({
  selector: "app-promotion-details",
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    RouterLink,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatTableModule
  ],
  templateUrl: "./promotion-details.component.html",
  styleUrls: ["./promotion-details.component.scss"],
})
export class PromotionDetailsComponent extends BaseListComponent {
  promotionDetails?: any;
  promotionTypeName: string = "";
  categories: string = "";
  brands: string = "";
  status:string="";
  dataSource!: MatTableDataSource<any>;
  displayedColumnsProduct: string[] = ['skuId', 'skuName'];
  appUrlTypeName: string = "";
  constructor(
    public promotionsService: PromotionsService,
    route: ActivatedRoute,
    router: Router,
    auth: AuthService
  ) {
    super(auth, router, route,PageId.bms_promotions);
  }

  override async ngOnInit() {
    await super.ngOnInit();

    this.getPromotionDetails();
  }

  async getPromotionDetails() {
    try {
      const promotionDetails: any = await this.promotionsService.getPromotionById(this.route.snapshot.params["id"]);
      this.promotionDetails = promotionDetails.data;
      console.log("promotionDetails:- ", this.promotionDetails);
      this.status=this.promotionDetails.status == true ? "Active" : "InActive";
      const categories = this.promotionDetails?.categories?.map((category: { name: string; }) => { return category.name }) || [];
      this.categories = categories.join(', ');
      const brands = this.promotionDetails?.brands?.map((brand: { name: string; }) => { return brand.name }) || [];
      this.brands = brands.join(', ');
      this.promotionTypeName = PromotionType[this.promotionDetails.promoType || 1];
      this.appUrlTypeName = AppUrlType[this.promotionDetails.appUrl || 0];
      this.dataSource = new MatTableDataSource(this.promotionDetails?.skus);
    } catch (error) {
      Swal.fire('', "Some Thing went Wrong.", 'error')
      console.log("error",error);
      
    }
  }
}
