import { Component, ViewChild } from '@angular/core';
import {
  ResourceEntity,
  ResourceListSearch,
} from '../../models/resources.model';
import { ResourcesService } from '../../services/resources.service';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSidenavModule } from '@angular/material/sidenav';
import Swal from 'sweetalert2';
import { AppLoaderService } from '../../../../../shared/app-loader/app-loader.service';
import { AuthService } from '../../../../../services/auth.service';
import {
  SwalMessageTypes,
  SupplierStatus,
  ResourceType,
  ContractMode,
  LookUpType,
  DesignationType,
} from '../../../../../enums/enums';
import { LookUpTypeService } from '../../../../../services/lookup-type.service';
import { BaseListComponent } from '../../../../../shared/core/base.list.component';
import { PageId } from '../../../../../constants/enums';

@Component({
  selector: 'app-resources-list',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    RouterLink,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSidenavModule,
  ],
  templateUrl: './resources-list.component.html',
  styleUrls: ['./resources-list.component.scss'],
})
export class ResourceListComponent extends BaseListComponent {
  singularTypes = Object.values(SupplierStatus).filter(
    (value) => typeof value === 'number'
  );
  resources: ResourceEntity[] = [];
  readonly DEFAULT_PAGE_SIZE = 25;
  override pageNumber: number = 1;
  // override pageSize = this.DEFAULT_PAGE_SIZE;
  totalResources: number = 0;
  form: FormGroup;
  resourceListSearchInput: ResourceListSearch = this.initializeSearchInput();
  lookUpTypeSearchInput: LookUpType = this.initializeLookUpTypeSearchInput();
  resourceTypes = Object.values(ResourceType).filter(
    (value) => typeof value === 'string'
  );
  resourceStatus: any = Object.values(SupplierStatus).filter(
    (value) => typeof value === 'number'
  );
  public extractedData: any[] = [];
  designationMap: { [key: number]: string } = {
    1: 'Security',
    2: 'Houskeeping',
    3: 'Delivery Boy',
    4: 'Driver',
    5: 'Sales Representative',
    6: 'Manager',
    7: 'Night Loader',
    8: 'Day Loader',
    9: 'Adhoc Delivery Boy',
    10: 'Adhoc Driver',
    11: 'Driver Cum Delivery',
    12: 'Operation Executive',
    13: 'Operation Admin',
  };

  displayedColumns = [
    'name',
    'mobileNumber',
    'designation',
    'contractMode',
    'status',
    'edit',
    'delete',
  ];

  dataSource!: MatTableDataSource<ResourceEntity>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    auth: AuthService,
    router: Router,
    private fb: FormBuilder,
    route: ActivatedRoute,
    public readonly resourceService: ResourcesService,
    private loader: AppLoaderService,
    private lookupService: LookUpTypeService
  ) {
    super(auth, router, route, PageId.logistics_resources);

    this.form = this.fb.group({
      mobileNumber: '',
      extractedData: null,
      status: null,
    });
  }

  override async ngOnInit() {
    await super.ngOnInit();
    await this.getResources();
    await this.getDesignation();
  }

  async getDesignation() {
    const response = await this.lookupService.getLookUpTypes(
      this.lookUpTypeSearchInput
    );
    this.extractedData = response.data[0].data;
  }

  async getResources() {
    try {
      this.loader.open();
      const response = await this.resourceService.getResources(
        this.resourceListSearchInput
      );
      if (
        response &&
        response['resourceList'] &&
        response['resourceList'].length > 0
      ) {
        this.dataSource = response['resourceList'];
        this.resources = response['resourceList'];
        console.log('this.resources::', this.resources);
        this.loader.close();
        this.totalResources = response['totalResources'];
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } else {
        this.dataSource = response[''];
        this.loader.close();
        Swal.fire({
          icon: 'info',
          text: 'No resource data found.',
        });
      }
    } catch (error) {
      this.loader.close();
      this.showMessage(
        'Failed to Fetch Resources. Please try again later.',
        SwalMessageTypes.Error
      );
    }
  }

  async deleteResource(row: any) {
    try {
      const result = await Swal.fire({
        title: 'Do you want to Delete Resource?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      });

      if (!result.isConfirmed) {
        return;
      }
      await this.resourceService.deleteResource(row.id);
      await this.clearFilters();
      this.showMessage('Resource deleted Successful', SwalMessageTypes.Success);
    } catch (error) {
      console.error('Error occurred while creating resource:');
      this.showMessage(
        'Failed to Delete Resource. Please try again later.',
        SwalMessageTypes.Error
      );
    }
  }

  editResource(row: any) {
    this.router.navigateByUrl(`/dashboard/logistics/edit-resource/${row.id}`);
  }

  private initializeSearchInput(): ResourceListSearch {
    return {
      designation: null,
      mobileNumber: '',
      status: null,
      pageNumber: 1,
      pageSize: 10,
    };
  }

  private initializeLookUpTypeSearchInput(): LookUpType {
    return {
      tableName: 'designation',
      lookupType: 'designation',
      idColumnName: 'id',
      valueColumnName: 'designation',
    };
  }

  override async onPageChange(event: any) {
    const { pageIndex, pageSize } = event;
    this.pageNumber = pageIndex + 1;
    this.pageSize = pageSize;
    const { mobileNumber, designation, status } = this.form.value;
    this.resourceListSearchInput = this.createResourceListSearchInput(
      mobileNumber,
      designation,
      status
    );
    await this.getResources();
  }

  async searchTasks() {
    const { mobileNumber, extractedData, status } = this.form.value;
    this.pageNumber = 1;
    this.pageSize = this.DEFAULT_PAGE_SIZE;
    this.paginator.pageIndex = 0;
    if (
      mobileNumber ||
      (extractedData && extractedData.id) ||
      status !== undefined
    ) {
      this.resourceListSearchInput = this.createResourceListSearchInput(
        mobileNumber,
        extractedData?.id,
        status
      );
      await this.getResources();
    }
  }

  private createResourceListSearchInput(
    mobileNumber: string,
    designation: number,
    status: any
  ): ResourceListSearch {
    // CHANGE INPUTS BASED ON YOUR REQUIREMENTS
    // designation: supplierName || "",
    //   status: status == 0 ? 0 : !status ? null : status == 1 ? 1 : null,
    return {
      mobileNumber: mobileNumber || '',
      designation: designation || null,
      status: status == 0 ? 0 : !status ? null : status == 1 ? 1 : null,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
    };
  }

  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  async clearFilters() {
    this.searchForm();
    this.resourceListSearchInput = this.initializeSearchInput();
    await this.getResources();
  }

  private searchForm() {
    this.form = this.fb.group({
      mobileNumber: '',
      extractedData: null,
      status: null,
    });
  }

  getSupplierContractModeText(value: number): string {
    switch (value) {
      case ContractMode.Adhoc:
        return 'Adhoc';
      case ContractMode.Fixed:
        return 'Fixed';
      default:
        return 'Unknown Type';
    }
  }

  getSupplierTypeModeText(value: string): string {
    switch (value) {
      case ResourceType.Manpower:
        return 'Manpower';
      case ResourceType.Transporter:
        return 'Transporter';
      case ResourceType.Procurement:
        return 'Procurement';
      default:
        return 'Unknown Type';
    }
  }

  getDesignationName(designationId: number): string {
    const designations = [
      { id: 1, value: 'Security' },
      { id: 2, value: 'Housekeeping' },
      { id: 3, value: 'Delivery Boy' },
      { id: 4, value: 'Driver' },
      { id: 5, value: 'Sales Representative' },
      { id: 6, value: 'Manager' },
      { id: 7, value: 'Night Loader' },
      { id: 8, value: 'Day Loader' },
      { id: 9, value: 'Adhoc Delivery Boy' },
      { id: 10, value: 'Adhoc Driver' },
      { id: 11, value: 'Driver Cum Delivery' },
      { id: 12, value: 'Operation Executive' },
      { id: 13, value: 'Operation Admin' },
    ];

    const designation = designations.find((d) => d.id === designationId);
    return designation ? designation.value : 'Unknown';
  }

  getStatusName(status: number): string {
    return SupplierStatus[status];
  }
}
