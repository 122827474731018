import { get } from '@angular/fire/database';
import { Component } from '@angular/core';
import {
  FormBuilder,
  Validators,
  ReactiveFormsModule,
  FormsModule,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ResourcesService } from '../../services/resources.service';
import {
  ResourceEntity,
  ResourceListSearch,
  ResourceForm,
  PropertyValidation,
  validateName,
  ValidationResult,
  MultiPropertyValidation,
} from '../../models/resources.model';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AppLoaderService } from '../../../../../shared/app-loader/app-loader.service';
import { AuthService } from '../../../../../services/auth.service';
import {
  SwalMessageTypes,
  states,
  ContractMode,
  ResourceStatus,
  SupplierType,
  LookUpType,
} from '../../../../../enums/enums';
import Swal from 'sweetalert2';
import {
  applyMaxLengthValidation,
  guid,
  hasSpecialCharacters,
  nameValidate,
  uuidv4,
  validateGST,
  validateMaxLength,
  validateMaxText,
  validateNegativeValues,
  validateNoNegativeValues,
  validatePanNumber,
  validatePinCode,
  validateSpecialCharacters,
  validateText,
} from '../../../../../utlity/utility';
import { State } from '../../../supplier/models/supplier-model';
import imageCompression from 'browser-image-compression';
import { SupplierService } from '../../../supplier/services/supplier.service';
import { LookUpTypeService } from '../../../../../services/lookup-type.service';
import { BaseListComponent } from '../../../../../shared/core/base.list.component';
import { PageId } from '../../../../../constants/enums';

@Component({
  selector: 'app-create-resource',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    RouterLink,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatCheckboxModule,
  ],
  templateUrl: './create-resource.component.html',
  styleUrls: ['./create-resource.component.scss'],
})
export class CreateResourceComponent extends BaseListComponent {
  // singularTypes = Object.values(SupplierType).filter((value) => typeof value === 'number');
  resourceStatus = Object.values(ResourceStatus).filter((value) => typeof value === 'number');
  resourceContractMode = Object.values(ContractMode).filter((value) => typeof value === 'number');
  lookUpTypeSearchInput: LookUpType = this.initializeLookUpTypeSearchInput();
  supplierNames: LookUpType = this.initializeSuppliers();
  designationOptions: LookUpType = this.initializeDesignation();
  public supplierName: any[] = [];
  dataSource: any;
  resourceEntity!: ResourceEntity;
  states: State[] = states;
  uploadedFiles: any = {};
  aadharCopy: string = '';
  panCopy: string = '';
  gstCopy: string = '';
  public locations: any[] = [];
  public designation: any[] = [];
  userList: any = [];
  designationLookUpTypeSearchInput: LookUpType = this.initializeDesignationLookUpTypeSearchInput();
  supplierTypes: SupplierType[] = Object.values(SupplierType)
  .filter(value => typeof value === 'number') as SupplierType[];

supplierTypeNames: { [key: number]: string } = {
  [SupplierType.Manpower]: 'Manpower',
  [SupplierType.Transporter]: 'Transporter',
  [SupplierType.Procurement]: 'Procurement'
};


  constructor(
    auth: AuthService,
    router: Router,
    private fb: FormBuilder,
    route: ActivatedRoute,
    private resourcesService: ResourcesService,
    private loader: AppLoaderService,
    private supplierService: SupplierService,
    private lookupService: LookUpTypeService,) {
      super(auth, router,route, PageId.logistics_resources);
    this.dataSource = this.fb.group({
      name: [null, [Validators.required]],
      mobileNumber: [null, [Validators.required]],
      address: [null, [Validators.required]],
      city: [null, [Validators.required]],
      state: [null, [Validators.required]],
      pinCode: [null, [Validators.required]],
      areaName: [null, [Validators.required]],
      landMark: [null, [Validators.required]],
      country: [null, [Validators.required]],
      password: [null, [Validators.required]],
      latitude: [null, Validators.required],
      longitude: [null, Validators.required],
      email: [null, [Validators.required]],
      aadharNumber: "",
      aadharCopy: "",
      panNumber: "",
      panCopy: "",
      gstNumber: "",
      gstCopy: "",
      designation: [null, [Validators.required]],
      supplierName: [null, [Validators.required]],
      supplierType: [null, [Validators.required]],
      tcsPercentage: [null, [Validators.required]],
      contractMode: [null, [Validators.required]],
      status: [null, [Validators.required]],
      location: [null, [Validators.required]]
    });
    this.fixMaxLength()
  }

  override async ngOnInit() {
    await super.ngOnInit();
    this.resourcesService.getAllUsers().subscribe((res) => {
      this.userList = res.map((user) => { return user['email_id'].trim().toUpperCase() });
    })
    await this.getSuppliers();
    await this.getLocation();
    await this.getDesignation();
  }
  async getLocation() {
    const response = await this.lookupService.getLookUpTypes(this.lookUpTypeSearchInput);
    this.locations = response.data[0].data;
  }

  private initializeSuppliers(): LookUpType {
    return {
      tableName: "logistic_suppliers",
      lookupType: "LogisticSuppliers",
      idColumnName: "Id",
      valueColumnName: "Name"
    };
  }
  async getDesignation() {
    const response = await this.lookupService.getLookUpTypes(this.designationLookUpTypeSearchInput);
    this.designation = response.data[0].data;
  }
  private initializeDesignationLookUpTypeSearchInput(): LookUpType {
    return {
      tableName: "designation",
      lookupType: "designation",
      idColumnName: "id",
      valueColumnName: "designation"
    };
  }

  private initializeDesignation(): LookUpType {
    return {
      tableName: "designation",
      lookupType: "designation",
      idColumnName: "id",
      valueColumnName: "designation"
    };
  }

  async getSuppliers() {
    const response = await this.lookupService.getLookUpTypes(this.supplierNames);
    this.supplierName = response.data[0].data;
  }
  async saveResource() {
    this.loader.open();

    try {
      if (this.dataSource.invalid) {
        this.showMessage(
          'Please fill all required fields.',
          SwalMessageTypes.Warning
        );

        return;
      }

      await this.validateInput();
      await this.uploadFiles();
      await this.createResource();
    } catch (error) {
    } finally {
      this.loader.close();
    }
  }
  async uploadFiles() {
    const documentTypes = ['aadhar', 'pan', 'gst'];
    const urlEndpoints = ['resource_aadhaar', 'resource_pan', 'resource_gst'];
    await Promise.all(
      documentTypes.map(async (documentType, index) => {
        const urlEndpoint = urlEndpoints[index];
        if (this.uploadedFiles.hasOwnProperty(documentType)) {
          const requestImageBody = this.uploadedFiles[documentType];
          await this.uploadAndSetUrl(
            requestImageBody,
            documentType,
            urlEndpoint
          );
        }
      })
    );
  }
  private async uploadAndSetUrl(
    requestImageBody: any,
    documentType: string,
    urlEndpoint: string
  ) {
    console.log("upload");

    try {
      const res: any = await this.resourcesService.imageUpload(
        requestImageBody,
        urlEndpoint
      );
      console.log('res :', res);
      switch (documentType) {
        case 'aadhar':
          this.aadharCopy = res.data.url;
          console.log('this.aadharCopy :', this.aadharCopy);
          break;
        case 'pan':
          this.panCopy = res.data.url;
          break;
        case 'gst':
          this.gstCopy = res.data.url;
          break;
        default:
          console.error('Invalid document type');
          break;
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  }
  private mapResourceObj(userObj: any): any {
    return {
      name: userObj.name,
      email_id: userObj.email,
      mobile_number: userObj.mobileNumber,
      password: userObj.password,
      customId: guid(),
      roles: [],
      user_type: null,
      status: "",
      extended_permissions: {},
    }
  }

  private mapResource(resource: ResourceForm): ResourceEntity {
    const mapForm = resource;
    return {
      id: mapForm.id,
      name: mapForm?.name,
      mobileNumber: mapForm?.mobileNumber.toString(),
      password: mapForm?.password,
      email: mapForm?.email,
      address: mapForm?.address,
      city: mapForm?.city,
      state: mapForm?.state,
      pinCode: mapForm?.pinCode,
      areaName: mapForm?.areaName,
      landMark: mapForm?.landMark,
      country: mapForm?.country,
      latitude: mapForm?.latitude,
      longitude: mapForm?.longitude,
      panNumber: mapForm?.panNumber,
      panCopy: this.panCopy || '',
      aadharNumber: mapForm?.aadharNumber,
      aadharCopy: this.aadharCopy || '',
      gstNumber: mapForm?.gstNumber,
      gstCopy: this.gstCopy || '',
      designation: mapForm?.designation,
      supplierName: mapForm?.supplierName || '',
      supplierType: mapForm?.supplierType,
      contractMode: mapForm?.contractMode,
      status: mapForm?.status,
      tcsPercentage: mapForm?.tcsPercentage,
      location: mapForm?.location,
    };
  }

  async createResource(): Promise<any> {
    let resource = this.dataSource.value;
    if (this.userList.includes(resource.email.trim().toLocaleUpperCase())) {
      Swal.fire({
        title: '',
        text: 'User already exists with given mailId, please use another mailId',
        icon: 'warning'
      })
      this.loader.close();
      return
    }
    let designationValue = this.dataSource.get('designation').value;

    const firebaseResource = this.mapResourceObj(resource);
    const payLoad: ResourceListSearch = {
      designation: null,
      mobileNumber: firebaseResource.mobile_number.toString(),
      status: null,
      pageNumber: 1,
      pageSize: 10,
    }
    try {
      const res = await this.resourcesService.getResources(payLoad)
      if (res && res['totalResources'] > 0) {
        this.showMessage('Resource already exists with given mobile number, please give another mobile number.',
          SwalMessageTypes.Warning
        );
        return;
      }
      if ( designationValue === 6 || designationValue === 12 ||  designationValue === 13) {
        let result = await this.resourcesService.addUsers(firebaseResource);
        resource.id = result;
      } else {
        resource.id = guid();
      }
      const resourceEntity = this.mapResource(resource);
      const missingFields = this.checkMissingFields(resourceEntity);
      if (missingFields.length > 0) {
        this.showMessage(
          `Please fill both ${missingFields.join(' and ')} not only one.`,
          SwalMessageTypes.Warning
        );
        return;
      }
      const response = await this.resourcesService.createResource(
        resourceEntity
      );
      this.showMessage(
        'Resource created successfully',
        SwalMessageTypes.Success
      );
      this.router.navigateByUrl('/dashboard/logistics/resource-list');
    } catch (error) {
      console.error('Error occurred while creating resource:', error);
      this.showMessage(
        'Failed to create resource. Please try again later.',
        SwalMessageTypes.Error
      );
    }



  }

  // private Functions
  private async validateInput() {
    const result = this.validateMaxLength(this.dataSource.value);

    if (!result.isValid) {
      const errorMessage = this.createMaxLengthMsg(result.failedFields || []);
      this.showMessage(errorMessage, SwalMessageTypes.Warning);
      throw new Error('validateMaxLength.');
    }

    const validateNegativeValues = this.validateNegativeValues(
      this.dataSource.value
    );

    if (!validateNegativeValues.isValid) {
      const message = this.generateErrorMessage(
        validateNegativeValues.failedFields
      );
      this.showMessage(message, SwalMessageTypes.Warning);
      throw new Error('validateNegativeValues.');
    }

    const { pinCode } = this.dataSource.value;

    if (pinCode && !validatePinCode(pinCode)) {
      this.showMessage(
        'PinCode is not correct. Please provide a proper PinCode  number.',
        SwalMessageTypes.Warning
      );
      throw new Error('validateGST.');
    }

    const validationResult = this.validateText(this.dataSource.value);

    if (validationResult.failedField) {
      const errorMessage = this.buildErrorMessage(validationResult.failedField);
      this.showMessage(errorMessage, SwalMessageTypes.Warning);
      throw new Error('validateText.');
    }

    // if (this.validateText(this.dataSource.value)) {
    //   this.showMessage('The name or city is not valid. Please correct the name by removing special characters.', SwalMessageTypes.Warning);
    //   throw new Error('validateText');
    // }

    const { gstNumber } = this.dataSource.value;

    if (gstNumber && !validateGST(gstNumber)) {
      this.showMessage(
        'GST Number is not correct. Please provide a proper GST number.',
        SwalMessageTypes.Warning
      );
      throw new Error('validateGST.');
    }

    const { panNumber } = this.dataSource.value;

    if (panNumber && !validatePanNumber(panNumber)) {
      this.showMessage('PAN Number is not correct. Please provide a proper PAN number.', SwalMessageTypes.Warning);

      throw new Error('validateGST.');
    }

    if (!validatePinCode(this.dataSource.value.pinCode)) {
      this.showMessage(
        'PinCode is not correct. Please provide a proper PinCode  number.',
        SwalMessageTypes.Warning
      );
      throw new Error('validateGST.');
    }

    if (this.checkSpecialCharacters(this.dataSource.value)) {
      this.showMessage(
        'Aadhar,Pan and GST Should not have special characters. Please remove special characters.',
        SwalMessageTypes.Warning
      );
      throw new Error('validateText');
    }

    // const response = await this.supplierService.getSuppliers(supplierListSearchInput());
    // this.existingSupplier = response['supplierList'];

    // if (this.checkIsMobileNumberExists(this.dataSource.value.mobileNumber)) {
    //   this.showMessage('Mobile Number Already Exists.', SwalMessageTypes.Warning);
    //   this.loader.close();
    //   throw new Error('Mobile number already exists.');
    // }
  }
  private createMaxLengthMsg(
    failedFields: { field: string; failedField: string }[]
  ): string {
    const errorMessages: { [key: string]: string } = {
      mobileNumber: 'Mobile (10 digits)',
      pinCode: 'Pin Code (6 digits)',
      aadharNumber: 'Aadhar (12 digits)',
      panNumber: 'Pan (10 characters)',
      gstNumber: 'Gst (15 characters)',
    };

    const failedFieldNames = failedFields.map((failedField) => {
      return errorMessages[failedField.field];
    });

    return (
      'The input lengths for ' +
      failedFieldNames.join(', ') +
      ' do not match. Please ensure you enter the correct lengths for each field before proceeding.'
    );
  }
  private generateErrorMessage(
    failedFields: { field: string; failedField: string }[] | undefined
  ): string {
    let message = 'The following fields contain invalid numeric values:';
    if (failedFields) {
      failedFields.forEach((failedField) => {
        message += ` ${failedField.field} (${failedField.failedField}),`;
      });
      message = message.slice(0, -1) + '.';
    }
    return message;
  }
  private validateMaxLength(data: any) {
    const validations: MultiPropertyValidation[] = [
      { field: 'mobileNumber', property: data.mobileNumber, maxLength: 10 },
      { field: 'pinCode', property: data.pinCode, maxLength: 6 },
    ];
    if (this.dataSource.value.aadharNumber == null) {
      data.aadharNumber = '';
    }

    if (data.aadharNumber !== '') {
      validations.push({
        field: 'aadharNumber',
        property: data.aadharNumber,
        maxLength: 12,
      });
    }
    if (data.panNumber !== '') {
      validations.push({
        field: 'panNumber',
        property: data.panNumber,
        maxLength: 10,
      });
    }
    if (data.gstNumber !== '') {
      validations.push({
        field: 'gstNumber',
        property: data.gstNumber,
        maxLength: 15,
      });
    }

    return validateMaxLength(validations);
  }
  private validateText(data: ResourceForm): validateName {
    const textInputs = [
      { value: data.name, field: 'Name' },
      { value: data.city, field: 'City' },
    ];

    return nameValidate(textInputs.map((input) => input)) || { isValid: true };
  }

  private fixMaxLength() {
    applyMaxLengthValidation(this.dataSource, [
      { property: 'mobileNumber', maxLength: 10 },
      { property: 'pinCode', maxLength: 6 },
      { property: 'aadharNumber', maxLength: 12 },
      { property: 'panNumber', maxLength: 10 },
      { property: 'gstNumber', maxLength: 15 },
    ]);
  }

  private validateNegativeValues(data: any): ValidationResult {
    const numericInputs = [
      { field: 'mobileNumber', value: data.mobileNumber },
      { field: 'pinCode', value: data.pinCode },
      { field: 'tcsPercentage', value: data.tcsPercentage },
      { field: 'latitude', value: data.latitude },
      { field: 'longitude', value: data.longitude },
    ];

    const validation = validateNoNegativeValues(
      numericInputs.map((input) => input)
    );
    return {
      isValid: validation.isValid,
      failedFields: validation.failedFields,
    };
  }

  checkSpecialCharacters(data: any): boolean {
    const inputValues = [];
    if (data.aadharNumber !== '') {
      inputValues.push(data.aadharNumber);
    }
    if (data.panNumber !== '') {
      inputValues.push(data.panNumber);
    }
    if (data.gstNumber !== '') {
      inputValues.push(data.gstNumber);
    }
    return inputValues.length > 0 ? hasSpecialCharacters(inputValues) : false;
  }

  toUppercase(event: any) {
    event.target.value = event.target.value.toUpperCase();
  }

  async onFileChange(event: any, documentType: string) {
    if (event.target.files) {
      const file = event.target.files[0];
      const filename = file.name;
      const reader = new FileReader();
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      reader.onload = async (event) => {
        const result = event?.target?.result;

        if (typeof result === 'string') {
          const base64String: string = await this.compressAndConvertToBase64(
            file,
            options
          );
          this.uploadedFiles[documentType] = {
            fileName: filename,
            fileExtension: this.getFileExtension(filename),
            fileData: base64String,
          };
        } else if (result instanceof ArrayBuffer) {
          console.error('Unsupported file type');
          // Handle this case if needed
        }
      };

      reader.readAsDataURL(file);
    }
  }
  async compressAndConvertToBase64(file: File, options: any): Promise<string> {
    try {
      const compressedFile = await imageCompression(file, options);
      const base64String: string = await imageCompression.getDataUrlFromFile(
        compressedFile
      );
      return base64String.split(',')[1];
    } catch (error) {
      console.error('Error compressing image:', error);
      throw error;
    }
  }
  private getFileExtension(filename: any) {
    return filename.split('.').pop();
  }

  private buildErrorMessage(failedField: string): string {
    return `The ${failedField} is not valid. Please correct the ${failedField} by removing special characters.`;
  }

  private checkMissingFields(resourceEntity: any) {
    const missingFields: any = [];

    const checkField = (
      fieldCopy: any,
      fieldNumber: any,
      fieldName: string
    ) => {
      if (
        fieldNumber !== null &&
        ((fieldCopy !== '' && fieldNumber === '') ||
          (fieldCopy === '' && fieldNumber !== ''))
      ) {
        missingFields.push(`${fieldName} Number And ${fieldName} Image`);
      }
    };

    checkField(
      resourceEntity.aadharCopy,
      resourceEntity.aadharNumber,
      'Aadhar'
    );
    checkField(resourceEntity.panCopy, resourceEntity.panNumber, 'Pan');
    checkField(resourceEntity.gstCopy, resourceEntity.gstNumber, 'GST');

    return missingFields;
  }
  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  private initializeLookUpTypeSearchInput(): LookUpType {
    return {
      tableName: "locations",
      lookupType: "locations",
      idColumnName: "id",
      valueColumnName: "name"
    };
  }
}
