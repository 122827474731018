@if(hasView){
<ng-container>
  <div class="heading-block">
    <div class="row">
      <div class="col-md-6">
        <h2 class="card-title m-3">Incentive Slab</h2>
      </div>
      <div class="col-md-6 text-right">
        @if(hasCreate){
        <button mat-raised-button color="primary" [routerLink]="['/dashboard/incentives/create-incentive']"
          class="topButtonSectionbtn">
          Add Incentive Slab
        </button>
        }
      </div>
    </div>
  </div>

  <div class="table-block">
    <div class="m-3 flex flex-col md:flex-row justify-between">
      <mat-card class="p-0">
        <mat-card-content class="p-0">
          <mat-sidenav-container fxFlex="0 1 auto">
            <mat-sidenav-content>
              <div class="table-container">
                <mat-table [dataSource]="dataSource" matSort>
                  <ng-container matColumnDef="incentiveSlabName">
                    <mat-header-cell *matHeaderCellDef>
                      Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="incentiveSlabName">
                      {{ row.incentiveSlabName }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="visitsCountMin">
                    <mat-header-cell *matHeaderCellDef>
                      Visits Count Min
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="visitsCountMin">
                      {{ row.visitsCountMin}}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="visitsCountMax">
                    <mat-header-cell *matHeaderCellDef>
                      Visits Count Max
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="visitsCountMax">
                      {{ row.visitsCountMax }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="dayRevenuePercentMin">
                    <mat-header-cell *matHeaderCellDef>
                      Day Revenue Min %
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="dayRevenuePercentMin">
                      {{ row.dayRevenuePercentMin }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="dayRevenuePercentMax">
                    <mat-header-cell *matHeaderCellDef>
                      Day Revenue Max %
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="dayRevenuePercentMax">
                      {{ row.dayRevenuePercentMax }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="dayTasksRevenuePercentMin">
                    <mat-header-cell *matHeaderCellDef>
                      Tasks Revenue Min %
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="dayTasksRevenuePercentMin">
                      {{ row.dayTasksPercentMin }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="dayTasksRevenuePercentMax">
                    <mat-header-cell *matHeaderCellDef>
                      Tasks Revenue Max %
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="dayTasksRevenuePercentMax">
                      {{ row.dayTasksPercentMax }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="buyingCountPercentMin">
                    <mat-header-cell *matHeaderCellDef>
                      Buying Count Min %
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="buyingCountPercentMin">
                      {{ row.buyingCountPercentMin }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="buyingCountPercentMax">
                    <mat-header-cell *matHeaderCellDef>
                      Buying Count Max %
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="buyingCountPercentMax">
                      {{ row.buyingCountPercentMax }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="nonBuyingCountPercentMin">
                    <mat-header-cell *matHeaderCellDef>
                      Non Buying Count Min %
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="nonBuyingCountPercentMin">
                      {{ row.nonBuyingCountPercentMin }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="nonBuyingCountPercentMax">
                    <mat-header-cell *matHeaderCellDef>
                      Non Buying Count Max %
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="nonBuyingCountPercentMax">
                      {{ row.nonBuyingCountPercentMax}}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="incentiveBuyingOrder">
                    <mat-header-cell *matHeaderCellDef>
                      Incentive Buying Order
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="incentiveBuyingOrder">
                      {{ row.incentiveBuyingOrder }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="incentiveNonBuyingOrder">
                    <mat-header-cell *matHeaderCellDef>
                      Incentive Non Buying Order
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="incentiveNonBuyingOrder">
                      {{ row.incentiveNonBuyingOrder }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="petrolAllowance">
                    <mat-header-cell *matHeaderCellDef>
                      Petrol Allowance
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="petrolAllowance">
                      {{ row.petrolAllowance }}
                    </mat-cell>
                  </ng-container>


                  <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef>
                      Status
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="status">
                      {{ row.isActive }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef class="mat-column-actions">
                      Action
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="mat-column-actions">
                      <div class="d-flex" *ngIf="hasEdit">
                        <button mat-raised-button color="primary" (click)="editIncentiveSlab(row)" style="margin-left: 5px;">
                          Edit
                        </button>
                        <button mat-raised-button color="primary" (click)="deleteIncentiveSlab(row)" style="margin-left: 5px;">
                          Delete
                        </button>
                      </div>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                </mat-table>
              </div>
            </mat-sidenav-content>
          </mat-sidenav-container>
          <mat-paginator [length]="totalIncentiveSlabs" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            ></mat-paginator>
        </mat-card-content>
      </mat-card>
    </div>

  </div>

</ng-container>
} @else {
<ng-container>
  <p class="text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
 }
