import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { VarientTypeService } from '../services/varientType.service';
import { MatSelectModule } from '@angular/material/select';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatIconModule } from '@angular/material/icon';
import { AuthService } from '../../../services/auth.service';
import { ProductService } from '../../product/services/product.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from '../../../services/common.service';
import { groups } from '../../../constants/groups';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { PageId } from '../../../constants/enums';
@Component({
  selector: 'app-varientType-add-edit',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    RouterLink,
    ReactiveFormsModule,
    MatSelectModule,
    NgSelectModule,
    MatIconModule
  ],
  templateUrl: './varientType-add-edit.component.html',
  styleUrl: './varientType-add-edit.component.scss',
})
export class VarientTypeAddEditComponent extends BaseListComponent {
  variantTypeForm: any;
  filename: any;
  previewImage: any;
  requestImageBody: any;
  id: any;
  companyList: any = []
  categoriesOption: any = []
  comment: any = ""
  groupName: any = groups
  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    public fb: FormBuilder,
    public varientTypeService: VarientTypeService,
    public location: Location,
    public productService: ProductService,
    public commonService: CommonService
  ) {
    super(auth, router, route)
    this.variantTypeForm = this.fb.group({
      categories_id: [null, [Validators.required]],
      value_type: [null, [Validators.required]],
      name: [null, [Validators.required]],
      group_name: [null, []],
    });
  }
  override async ngOnInit() {
    await super.ngOnInit(PageId.bms_variant);
    this.getAllCategories();
    this.route.params.subscribe((params) => {
      this.id = params['id'];
      if (this.id) {
        this.patchValue();
      }
    });
  }
  async patchValue() {
    this.commonService.showLoder();
    if (this.hasBMSAdmin) {
      let res: any = (await this.varientTypeService.getSingle(this.id) as any).data[0].attributes
      this.variantTypeForm.patchValue(res);
      this.previewImage = res.image_url;
    }
    if (this.hasSellerAdmin) {
      this.productService.getSingleApprovalDoc(this.id).subscribe((docData: any) => {
        let res: any = docData.payload
        this.variantTypeForm.patchValue(res);
        this.previewImage = res.image_url;
        this.comment = docData?.comment
      },
        (error: any) => {
          this.commonService.showToaster("error", error.message);
          this.commonService.hideLoder();
        })
    }
    this.commonService.hideLoder();
  }
  async submit() {
    this.commonService.showLoder();
    try {
      try {
        if (this.id) {
          if (this.hasBMSAdmin) {
            await this.varientTypeService.update(this.id, this.variantTypeForm.value);
            this.commonService.showToaster("success", 'Updated Successfully')
            this.router.navigateByUrl('/dashboard/variantType');
          }
          if (this.hasSellerAdmin) {
            this.productService.reSubmitRequestForApproval(this.id, this.variantTypeForm.value)
            this.commonService.showToaster("success", 'Your request is submitted for approval')
            this.router.navigateByUrl('/dashboard/my-requests');
          }
        } else {
          if (this.hasBMSAdmin) {
            await this.varientTypeService.add(this.variantTypeForm.value);
            this.commonService.showToaster("success", 'Added Successfully')
            this.router.navigateByUrl('/dashboard/variantType');
          }
          if (this.hasSellerAdmin) {
            this.productService.addToPendingApproval("add-variant-type", this.variantTypeForm.value, "pending-approval")
            this.commonService.showToaster("success", 'Your request is submitted for approval')
            this.router.navigateByUrl('/dashboard/my-requests');
          }

        }
        this.variantTypeForm.reset();
        this.commonService.hideLoder()

      } catch (err: any) {
        console.log(err);
        this.commonService.showToaster("error", err.error.message)
        this.commonService.hideLoder()
      }
    } catch (err: any) {
      console.log(err);
      this.commonService.showToaster("error", err.error.message)
      this.commonService.hideLoder()
    }
  }

  back() {
    this.location.back();
  }

  getAllCategories() {
    this.productService.getAllRootCategories().subscribe((res: any) => {
      this.categoriesOption = res.data
    })
  }




}
