<ng-container>
  <div class="p-3">
    <div class="row">
      <div class="col-md-6">
        <h2 class="card-title m-3 text-violet">Update Available Quantity</h2>
      </div>
      <div class="col-md-6">
        <mat-icon (click)="dialogRef.close()" class="float-right m-3 closeicon">
          close
        </mat-icon>
      </div>
    </div>
    <div class="mat-elevation-z8">
      <form [formGroup]="dataSource" class="m-3 formcss">
        <div class="table-container p-2">
          <div class="row filters">

            <!-- <div class="col-md-12">
                <mat-form-field appearance="outline" class="w-100">
                  <textarea matInput placeholder="SKU Name" cols="2" [disabled]="true"
                    formControlName="skuName"></textarea>
                </mat-form-field>

              </div> -->

            <div class="col-md-12">
              <mat-form-field appearance="outline" class="w-100">
                <textarea matInput placeholder="SKU Name" cols="2" readonly formControlName="skuName"
                  style="color: black;"></textarea>
              </mat-form-field>
            </div>



            <div class="col-md-6">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label> Ordered Quantity </mat-label>
                <input style="color: black;" matInput placeholder="Ordered Quantity" formControlName="totalQuantity">
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Available Quantity*</mat-label>
                <input matInput type="number" placeholder="Available Quantity*" formControlName="availableQuantity">
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field appearance="outline" class="w-100">
                <textarea matInput placeholder="Reason*" cols="1" formControlName="reason"></textarea>
              </mat-form-field>
            </div>
            <div class="col-md-12 custom-flex">
              <div class="text-center mt-2 flex-item ml-10">
                <button mat-raised-button color="primary" (click)="submitResult()" class="text-center ml">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

  </div>
</ng-container>
