<ng-container *ngIf="hasView">
  <div class="heading-block">
    <div class="row">
      <div class="col-md-6">
        <h2 class="card-title m-3">Notifications</h2>
      </div>
      <div class="col-md-6 text-right ">
        @if(hasCreate){
        <button mat-raised-button color="primary" [routerLink]="['/dashboard/notifications/app-create-notification']"
          class="topButtonSectionbtn m-3">
          Create Notification
        </button>
        }
      </div>
    </div>
  </div>

  <div class="search-block">
    <form [formGroup]="form">
      <section id="content">
        <div class="card">
          <div class="card-body card-padding">
            <div class="row">
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <input matInput type="text" formControlName="title" name="title" placeholder="Notification Title" />
                </mat-form-field>
              </div>

              <div class="col-md-3 searchbtns">
                <div class="text-left mt-2">
                  <button mat-raised-button color="primary" class="text-center ml" (click)="searchNotifications()">
                    Search
                  </button>
                </div>
                <div class="text-right mt-2 clearfiltrtbtn">
                  <button mat-raised-button color="primary" class="text-center ml-w" (click)="clearFilters()">
                    Reset Filters
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </form>
  </div>

  <div class="table-block col-md-12">
    <div class="m-3 flex flex-col md:flex-row justify-between">
      <mat-card class="p-0 w-100">
        <mat-card-content class="p-0">
          <mat-sidenav-container fxFlex="0 1 auto" class="w-100">
            <mat-sidenav-content>
              <div class="table-container">
                <mat-table [dataSource]="dataSource" matSort>



                  <ng-container matColumnDef="title">
                    <mat-header-cell *matHeaderCellDef>
                      Title
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="title">
                      {{ row.title }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="date">
                    <mat-header-cell *matHeaderCellDef>
                      Created Date
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="createdDate">
                      {{row.createdDate}}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="link">
                    <mat-header-cell *matHeaderCellDef class="center">
                      Deep Link
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="link" class="center">
                      <!-- {{ row.link }} -->
                      <button mat-icon-button aria-label="Copy link" (click)="copyToClipboard(row.link)">
                        <mat-icon>file_copy</mat-icon>
                      </button>
                    </mat-cell>
                  </ng-container>


                  <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef class="center">
                      Action
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="action" class="center">
                      <button mat-raised-button color="primary" class="text-center ml" (click)="sendNotification(row)">
                        Send App Notification
                      </button>
                    </mat-cell>
                  </ng-container>



                  <ng-container matColumnDef="delete">
                    <mat-header-cell *matHeaderCellDef class="center">
                      Delete
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="delete" class="center">
                      <button mat-icon-button aria-label="Delete" (click)="onDelete(row.id)">
                        <mat-icon class="red">delete</mat-icon>
                      </button>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                </mat-table>
              </div>
            </mat-sidenav-content>
          </mat-sidenav-container>
          <mat-paginator [length]="totalNotifications" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="onPageChange($event)"></mat-paginator>
        </mat-card-content>
      </mat-card>
    </div>

  </div>


</ng-container>
<ng-container *ngIf="!hasView">
  <p class="text-center">You dont have permission , Please contact administrator</p>
</ng-container>
