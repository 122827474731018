import { Injectable } from '@angular/core';
import { VehicleEntity, VehicleImageResponse, VehicleListSearch, createVehicleResponse } from '../models/vehicles.model';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VehiclesService {
  baseServiceUrl: string = `${environment.logisticsUrl}`;
  // logisticsUrl: string = `${environment.logisticsUrl}`;
  fileServicesApiUrl: string = `${environment.fileUploadBaseUrl}`;
  constructor(
    public http: HttpClient

  ) {
  }


  async createVehicle(data: VehicleEntity) {

    let response = await lastValueFrom(this.http.post<{ res: createVehicleResponse }>(`${this.baseServiceUrl}/api/vehicle/create`, data));

    return response.res
  }

  async getVehicles(body: VehicleListSearch): Promise<any> {
    const res = await lastValueFrom(this.http.post<any>(`${this.baseServiceUrl}/api/vehicle/get_by_filters`, body));
    if (res && res.data) {
      return {
        vehicleList: res.data.rows,
        totalVehicles: res.data.count
      }
    }

  }

  async getVehicleById(id: string): Promise<any> {
    const res = await lastValueFrom(this.http.get<any>(`${this.baseServiceUrl}/api/vehicle/get_by_id/${id}`));
    return res.data;
  }

  async updateVehicle(vehicleEntity: VehicleEntity, vehicleid: string) {
    let { id, ...vehicleUpdatedEntity } = vehicleEntity;
    let response = await lastValueFrom(this.http.post<{ res: createVehicleResponse }>(
      `${this.baseServiceUrl}/api/vehicle/update/${vehicleid}`,
      vehicleUpdatedEntity
    ));
    return response.res
  }

  async deleteVehicle(id: string) {
    return await lastValueFrom(this.http.delete(`${this.baseServiceUrl}/api/vehicle/delete/${id}`));
  }

  async imageUpload(body: { fileName: string; fileExtension: string; fileData: any; }, urlEndpoint: string) {

    let response = await lastValueFrom(this.http.put<{ res: VehicleImageResponse }>(`${this.fileServicesApiUrl}/documents/${urlEndpoint}`, body));

    return response
  }

}
