import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { ExcelExportService } from '../../../../services/excel.service';
import { isEmptyValue } from '../../../../utlity/utility';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink, RouterOutlet } from '@angular/router';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-upload-products-dialog',
  templateUrl: './upload-products-dialog.component.html',
  styleUrls: ['./upload-products-dialog.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatButtonModule,
    RouterOutlet,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatCardModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    CommonModule,
    MatTableModule,
    RouterLink,
    ReactiveFormsModule,
    MatIconModule,
  ],
})
export class UploadProductsDialogComponent {
  productFile: File | undefined;
  productRowsWithError: any[] = [];
  productsFromExcel: any[] = [];
  productEntities: any[] = [];
  productErrorMessage: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>,
    private excelService: ExcelExportService
  ) {
    this.productsFromExcel = data.control.value;
  }

  onFileSelect(event: any) {
    this.productFile = event.target.files[0];
    this.convertProductExcel();
  }

  async convertProductExcel() {
    console.log('this.productFile', this.productFile);
    if (!this.productFile) {
      Swal.fire('', 'Please Upload Excel File.', 'warning');
      // this.commonService.swal("Please Upload Excel File.", "error");
      return;
    }

    this.productRowsWithError = [];
    this.productsFromExcel = [];
    this.productEntities = [];
    this.productErrorMessage = '';

    try {
      const binarystr = await this.excelService.readExcelData(this.productFile);
      this.productsFromExcel = binarystr.map((row) => {
        return {
          skuId: row['SKU ID'],
          productName: row['SKU Name'],
        };
      });

      if (this.productsFromExcel.length <= 0) {
        Swal.fire('', 'Excel should not be empty', 'error');
        return;
      }


      if (!this.validateProductColumns(this.productsFromExcel)) {
        Swal.fire('Warning!', 'Fields should not be empty', 'warning');
        return;
      }

      const productsProductName = this.productsFromExcel.map((product) =>
        product.productName.toString().trim().toLowerCase()
      );

      const duplicateProduct =
        this.findDuplicateProductInExcel(productsProductName);

      this.productRowsWithError = this.productsFromExcel.filter((product) =>
        duplicateProduct.includes(product.productName.toString().toLowerCase())
      );

      if (duplicateProduct.length > 0) {
        Swal.fire('', 'Please remove the duplicate products', 'warning');
        this.productErrorMessage =
          'Please remove the following duplicate entries from Excel:';
        return;
      }

      this.productEntities = this.productsFromExcel;
    } catch (error) {
      console.error('Error reading Excel file:', error);
    }
  }

  private findDuplicateProductInExcel(itemList: string[]): string[] {
    const inputList = new Set<string>();
    const duplicates = new Set<string>();

    for (const item of itemList) {
      if (inputList.has(item)) {
        duplicates.add(item);
      } else {
        inputList.add(item);
      }
    }

    return Array.from(duplicates);
  }

  private validateProductColumns(productList: any[]): boolean {
    this.productErrorMessage = '';
    this.productRowsWithError = [];

    for (const product of productList) {
      if (
        isEmptyValue(product.skuId) ||
        isEmptyValue(product.productName)
      ) {
        this.productRowsWithError.push(product);
        Swal.fire('Warning!', 'Fields should not be empty', 'warning');

        return false;
      }
      if (product.productName.length > 100) {
        this.productRowsWithError.push(product);
        this.productErrorMessage = 'Max Products Allow is 100';
        return false;
      }
    }

    return true;
  }

  setProducts() {
    if (this.productsFromExcel.length > 0) {
      this.dialogRef.close({ productsFromExcel: this.productsFromExcel });
    } else {
      this.dialogRef.close({ productsFromExcel: [] });
    }
  }

  cancelProducts() {
    this.dialogRef.close({ productsFromExcel: [] });
  }
}
