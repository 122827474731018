import { Component, Inject, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  PurchaseDataResponse,
  PurchaseReceiptSearch,
} from '../models/purchasereceipt-model';
import { PurchaseReceiptService } from '../service/purchasereceipt.service';
import { getDateFormat, getDateYYYYMMDD } from '../../../utlity/utility';
import Swal from 'sweetalert2';
import { LookUpType, PurchaseOrderStatus } from '../../../enums/enums';
import { AddSupplierComponent } from '../Add-supplier/add-supplier.component';
import { LookUpTypeService } from '../../../services/lookup-type.service';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { AuthService } from '../../../services/auth.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { PageId } from '../../../constants/enums';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
  takeUntil,
} from 'rxjs';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSort } from '@angular/material/sort';
import { AppLoaderService } from '../../../shared/app-loader/app-loader.service';
import { ReplaceCommasPipe } from '../../../shared/pipes/currency-pipe';
import { AppStateService } from '../../../services/appstate.service';

@Component({
  selector: 'app-purchaseorder-list',
  standalone: true,
  templateUrl: './purchaseorder-list.component.html',
  styleUrl: './purchaseorder-list.component.scss',
  imports: [
    MatCardModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatIconModule,
    MatPaginatorModule,
    MatInputModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReplaceCommasPipe,
  ],
})
export class PurchaseorderListComponent extends BaseListComponent {
  form: FormGroup;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  dialogRef!: MatDialogRef<AddSupplierComponent>;
  supplierSearchControl = new FormControl();
  supplierSearchResults: any[] = [];
  PurchaseOrderStatus: any = Object.values(PurchaseOrderStatus).filter(
    (value) => typeof value === 'number'
  );
  purchaseSearchInput: PurchaseReceiptSearch = this.initializeSearchInput();
  supplierNames: LookUpType = this.initializeSuppliers();
  public suppliersList: any[] = [];
  displayedColumns: string[] = [
    'purchaseRecieptNo',
    'purchaseDate',
    'recievedDate',
    'supplierName',
    'poStatus',
    'netpayable',
    'action',
  ];
  dataSource: any = [];
  body: any;
  dataLength: number = 0;
  prDetails: any;
  today: Date;
  constructor(
    auth: AuthService,
    router: Router,
    private fb: FormBuilder,
    route: ActivatedRoute,
    public purchaseReceiptService: PurchaseReceiptService,
    private lookupService: LookUpTypeService,
    private loader: AppLoaderService,
    public dialog: MatDialog,
    private appState: AppStateService
  ) {
    super(auth, router, route, PageId.seller_pr);
    this.form = this.fb.group({
      selectedSupplier: '',
      prStatus: null,
      prNumber: '',
      startDate: '',
      endDate: '',
    });
    this.today = new Date();
  }

  override async ngOnInit() {
    await super.ngOnInit();
    this.setupSkuSearch();
    if (this.vendorId) {
      await this.getSuppliers();
      const date = '';
      this.body = this.mapToBody(date);
      this.getPurchaseReceipts(this.body);
      this.initializeSearchInput();
    }
  }

  setupSkuSearch() {
    this.supplierSearchControl.valueChanges
      .pipe(
        debounceTime(300), // Wait for 300 milliseconds after the last keystroke
        distinctUntilChanged(), // Only emit distinct values
        filter((value) => value.length >= 3), // Filter out search terms less than 3 characters
        switchMap((value) => this.searchSupplier(value)) // Perform the search and switch to the latest observable
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((results: any[]) => {
        this.supplierSearchResults = results;
      });
  }
  async searchSupplier(searchTerm: string) {
    const response = await this.purchaseReceiptService.supplierSearch(
      searchTerm,
      this.vendorId
    );
    return response;
  }

  mapToBody(date: string) {
    return {
      receipt_id: this.purchaseSearchInput.receipt_id || '',
      start_date: this.purchaseSearchInput.start_date || date,
      end_date: this.purchaseSearchInput.end_date || date,
      supplier_id: this.purchaseSearchInput.supplier_id || '',
      status: this.purchaseSearchInput.status || null,
      page_number: this.pageNumber,
      page_size: this.pageSize,
    };
  }

  navigateAddPR() {
    const dialogRef = this.dialog.open(AddSupplierComponent, {
      width: '40%',
      maxWidth: '100vw',
      maxHeight: '80vh',
    });
  }
  getPrStatusName(status: number): string {
    return PurchaseOrderStatus[status];
  }

  mapToPr(searchInput: PurchaseReceiptSearch) {
    const startDate = searchInput.start_date
      ? getDateYYYYMMDD(new Date(searchInput.start_date))
      : '';
    const endDate = searchInput.end_date
      ? getDateYYYYMMDD(new Date(searchInput.end_date))
      : '';
    return {
      receipt_id: searchInput.receipt_id,
      start_date: startDate || '',
      end_date: endDate || '',
      supplier_id: searchInput.supplier_id,
      status: searchInput.status,
      page_number: this.pageNumber,
      page_size: this.pageSize,
    };
  }

  getPrStatusText(value: number): string {
    switch (value) {
      case PurchaseOrderStatus.Created:
        return 'Created';
      case PurchaseOrderStatus.RequestedForGRN:
        return 'Requested For GRN';
      case PurchaseOrderStatus.GRNCompleted:
        return 'GRN Completed';
      case PurchaseOrderStatus.RequestedForApproval:
        return 'Requested For Approval';
      case PurchaseOrderStatus.Approved:
        return 'Approved';
      default:
        return 'Unknown Type';
    }
  }
  async getPurchaseReceipts(body: any) {
    this.loader.open();
    const result: PurchaseDataResponse =
      await this.purchaseReceiptService.getPurchaseReceipts(body);
    const data = result.rows;
    this.dataSource = data;
    this.dataLength = result.count;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.loader.close();
    if (this.dataSource.length < 1) {
      Swal.fire(
        'Warning',
        'No Purchase Receipts are available, Please  try again later',
        'warning'
      );
    }
  }

  edit(id: any) {
    this.purchaseSearchInput.start_date =
      this.purchaseSearchInput?.start_date == ''
        ? ''
        : getDateYYYYMMDD(new Date(this.purchaseSearchInput?.start_date));
    this.purchaseSearchInput.end_date =
      this.purchaseSearchInput?.end_date == ''
        ? ''
        : getDateYYYYMMDD(new Date(this.purchaseSearchInput?.end_date));

    this.appState.setItem('purchaseReceiptFilters', this.purchaseSearchInput);
    this.router.navigateByUrl(
      `/dashboard/pocreation/edit-purchase-order/${id}`
    );
  }

  getSupplierName(supplierId: string) {
    for (const supplier of this.suppliersList) {
      if (supplier.id == supplierId) {
        return supplier.value;
      }
    }
    return null;
  }
  hasValidFormValues(): boolean {
    return Object.values(this.form.value).some(
      (value) => value !== null && value !== undefined && value !== ''
    );
  }
  async search() {
    if (!this.hasValidFormValues()) {
      Swal.fire('warning', 'Please give the atleast one value', 'warning');
      return;
    }
    const formStartDate = this.form.get('startDate')?.value;
    const formEndDate = this.form.get('endDate')?.value;
    if ((formStartDate && !formEndDate) || (!formStartDate && formEndDate)) {
      Swal.fire(
        'warning',
        'Both Start Date and End Date should be provided.',
        'warning'
      );
      return;
    }
    if (formStartDate && formEndDate && formStartDate > formEndDate) {
      Swal.fire(
        'warning',
        'Start date should not be greater than End date.',
        'warning'
      );
      return;
    }
    const { selectedSupplier, prStatus, prNumber, startDate, endDate } =
      this.form.value;
    const supplier = selectedSupplier?.id;
    if (
      supplier ||
      (startDate && endDate) ||
      prStatus !== undefined ||
      prNumber
    ) {
      this.purchaseSearchInput = this.purchaseReceiptSearchInput(
        supplier,
        prStatus,
        prNumber,
        startDate,
        endDate
      );
      this.body = this.mapToPr(this.purchaseSearchInput);
    }
    this.purchaseSearchInput.selected_supplier = selectedSupplier;
    this.appState.setItem('purchaseReceiptFilters', this.purchaseSearchInput);

    this.body.page_number = 1;
    this.getPurchaseReceipts(this.body);
  }

  async getSuppliers() {
    const response = await this.lookupService.getLookUpTypes(
      this.supplierNames
    );
    this.suppliersList = response.data[0].data;
  }
  private initializeSuppliers(): LookUpType {
    return {
      tableName: 'suppliers',
      lookupType: 'suppliers',
      idColumnName: 'Id',
      valueColumnName: 'Name',
    };
  }

  override async onPageChange(event: any): Promise<void> {
    await super.onPageChange(event);
    this.body.page_number = this.pageNumber;
    this.body.page_size = this.pageSize;
    await this.getPurchaseReceipts(this.body);
  }

  async clearFilters() {
    this.appState.clearAll();
    this.searchForm();
    this.supplierSearchResults = [];
    this.supplierSearchControl.setValue('', { emitEvent: false });
    await this.getPurchaseReceipts(this.body);
  }

  private initializeSearchInput(): PurchaseReceiptSearch {
    const purchaseSearchInput = this.appState.getItem<PurchaseReceiptSearch>(
      'purchaseReceiptFilters'
    ) || {
      supplier_id: '',
      status: null,
      receipt_id: '',
      start_date: '',
      end_date: '',
      page_number: this.pageNumber,
      page_size: this.pageSize,
    };
    this.form = this.fb.group({
      selectedSupplier: [purchaseSearchInput.selected_supplier],
      prStatus: [purchaseSearchInput.status],
      prNumber: [purchaseSearchInput.receipt_id],
      startDate: [purchaseSearchInput.start_date],
      endDate: [purchaseSearchInput.end_date],
    });
    this.supplierSearchResults = purchaseSearchInput.selected_supplier
      ? [purchaseSearchInput.selected_supplier]
      : [];

    this.supplierSearchResults = purchaseSearchInput.selected_supplier
      ? [purchaseSearchInput.selected_supplier]
      : [];
    return purchaseSearchInput;
  }

  private searchForm() {
    this.body.supplier_id = '';
    this.body.status = null;
    this.body.receipt_id = '';
    this.body.start_date = '';
    this.body.end_date = '';
    this.body.page_number = 1;
    this.body.page_size = this.pageSize;
    this.form = this.fb.group({
      selectedSupplier: '',
      prStatus: null,
      prNumber: '',
      startDate: '',
      endDate: '',
    });
  }
  private purchaseReceiptSearchInput(
    supplier: string,
    poStatus: any,
    prNumber: string,
    startDate: string,
    endDate: string
  ): PurchaseReceiptSearch {
    return {
      supplier_id: supplier || '',
      status: poStatus || null,
      receipt_id: prNumber || '',
      start_date: startDate || '',
      end_date: endDate || '',
      page_number: this.pageNumber,
      page_size: this.pageSize,
    };
  }
  async clone(id: string) {
    const result = await this.purchaseReceiptService.clonePr(id);
    if (result.success) {
      Swal.fire(
        'Success',
        'Purchase Receipt clone Successfuly Done ',
        'success'
      );
    }
    this.getPurchaseReceipts(this.body);
  }
  async download(id: any) {
    Swal.fire('Warning', 'Invoice not attached yet', 'warning');
  }
  downloadInvoice(url: any) {
    const anchor = document.createElement('a');
    anchor.href = url;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }
}
