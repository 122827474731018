@if(hasBMSAdmin){
<div class="container-fluid p-2">
  <mat-card class="p-3">
    <form [formGroup]="variantTypeForm">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 d-flex mb-3">
            <div class="me-3">
              <mat-icon role="button" (click)="back()">keyboard_backspace</mat-icon>
            </div>
            <h3 class="title">{{ id ? "Update" : "Add" }} Variant Type</h3>
          </div>
        </div>
        @if (comment) {
        <div class="row">
          <div class="alert alert-warning" role="alert">
            {{comment}}
          </div>
        </div>
        }
        <div class="row">
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <mat-label>Name</mat-label>
              <input matInput formControlName="name" />
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <ng-select [items]="categoriesOption"
            bindValue="id"
            bindLabel="attributes.browser_path"
            placeholder="Search by category"
            formControlName="categories_id"
            appendTo="body"
         >
    </ng-select>
           <!--  <mat-form-field class="example-full-width">
              <mat-label>Select Category</mat-label>
              <mat-select formControlName="categories_id">
                @for (category of categoriesOption; track $index) {
                <mat-option [value]="category.id">{{category.attributes.name}} </mat-option>
                }
              </mat-select>
            </mat-form-field> -->
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <mat-label>Type</mat-label>
              <mat-select formControlName="value_type">
                <mat-option [value]="'number'"> Number </mat-option>
                <mat-option [value]="'text'"> Text </mat-option>
                <mat-option [value]="'dropdown'"> Dropdown </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <mat-label>Group Name</mat-label>
              <mat-select formControlName="group_name">
                @for (item of groupName; track $index) {
                <mat-option [value]="item.value"> {{item.text}} </mat-option>
               }
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row my-3">
          <div class="col-md-12 text-center">
            <button [disabled]="!variantTypeForm.valid" mat-raised-button (click)="submit()" color="primary">
              {{ id ? "Update" : "Save" }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </mat-card>
</div>
}
