@if(hasView){
<ng-container>
  <div class="table-block">
    <div class="m-3 flex flex-col md:flex-row justify-between">
      <mat-card class="p-0">
        <mat-card-content class="p-0">
          <mat-sidenav-container fxFlex="0 1 auto">
            <mat-sidenav-content>
              @if(currentTrip?.status === 1 ){
              <div class="moveHop">
                <button mat-raised-button type="button" class="m-2" color="primary" (click)="moveHop()">
                  Move Hop
                </button>
              </div>
              }
              <div class="table-container" style="overflow-x: auto;">
                <mat-table [dataSource]="dataSource" matSort>
                  <ng-container matColumnDef="checkbox">
                    <mat-header-cell *matHeaderCellDef>
                      <mat-checkbox [checked]="selectAllCheckbox" (change)="toggleSelectAll()"
                        color="primary"></mat-checkbox>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      <ng-container *ngIf="row.point_type !== 3">
                        <mat-checkbox [checked]="checkboxStates[row.hop_id]" (change)="toggleCheckbox(row)"
                          color="primary">
                        </mat-checkbox>
                      </ng-container>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="hope">
                    <mat-header-cell *matHeaderCellDef> Hop</mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="hope">
                      {{ row.stop_number }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="destinationName">
                    <mat-header-cell *matHeaderCellDef>
                      Destination Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="destinationName">
                      {{ row.point_business_name }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="number">
                    <mat-header-cell *matHeaderCellDef>
                      Number
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="number">
                      {{ row.point_mobile_number }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="address">
                    <mat-header-cell *matHeaderCellDef>
                      Address
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="address">
                      {{ row.point_address }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="type">
                    <mat-header-cell *matHeaderCellDef>
                      Hop Mode
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="type">
                      <ng-container *ngIf="currentTrip?.source === 3 && row.point_type === 1; else defaultLabel">
                        Vendor Drop
                      </ng-container>
                      <ng-template #defaultLabel>
                        {{
                        getPointModeLabel(row.point_mode) + ' ' +
                        getConcatenateLabel(row.point_mode) + ' ' +
                        getPointTypeLabel(row.point_type)
                        }}
                      </ng-template>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="tonnage">
                    <mat-header-cell *matHeaderCellDef> Tonnage </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="tonnage">
                      {{ row.total_weight | number:'1.2-2'}}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="kms">
                    <mat-header-cell *matHeaderCellDef>
                      KMS
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="kms">
                      <!-- {{ row.kms }}0 -->
                      0
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef>
                      Status
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="status">
                      {{ getPlanningStatusText(row.hop_status) }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="locationLink">
                    <mat-header-cell *matHeaderCellDef>
                      Location Link
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="locationLink">
                      <div class="d-flex">
                        <button mat-icon-button (click)="navigateToMap(row)">
                          <mat-icon>room</mat-icon>
                        </button>
                      </div>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef class="mat-column-actions">
                      Action
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="mat-column-actions">
                      <div class="d-flex">
                        @if(hasView){
                        <button mat-icon-button (click)="navigateToHop(row)">
                          <mat-icon>visibility</mat-icon>
                        </button>
                        }
                        @if(currentTrip?.status == 1 &&row.point_type !=3 ){
                        <button mat-icon-button (click)="delete(row)" color="warn">
                          <mat-icon class="me-0">delete</mat-icon>
                        </button>
                        }


                      </div>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                </mat-table>
              </div>
            </mat-sidenav-content>
          </mat-sidenav-container>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

</ng-container>
} @else {
<ng-container>
  <p class="text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
}