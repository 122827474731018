import { Component, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatChipsModule } from '@angular/material/chips';
import { AuthService } from '../../../services/auth.service';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatCommonModule } from '@angular/material/core';
import { CommonModule } from '@angular/common';
import { AppLoaderService } from '../../../shared/app-loader/app-loader.service';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { PageId } from '../../../constants/enums';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NotificationService } from '../services/notification.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationListSearch } from '../models/notification.model';
import Swal from 'sweetalert2';
import { SwalMessageTypes } from '../../../enums/enums';
import { MESSAGE_QUEUE_TYPE } from '../../../constants/message-queue-types';
@Component({
  selector: 'app-notification-list',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    RouterLink,
    MatCheckboxModule,
    FormsModule,
    MatChipsModule,
    MatCommonModule,
    MatPaginatorModule,
    MatSidenavModule,
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
  ],
  templateUrl: './notification-list.component.html',
  styleUrl: './notification-list.component.scss',
})
export class NotificationListComponent extends BaseListComponent {
  permissionObj: any;
  status: number = 1;
  dataSource!: any;
  displayedColumns = ['title', 'date', 'action', 'link', 'delete'];
  form!: FormGroup;
  notificationListSearchInput: NotificationListSearch = this.initializeSearchInput();
  totalNotifications: number = 0;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  DEFAULT_PAGE_SIZE: number = 25;

  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private notificationService: NotificationService,
    private loader: AppLoaderService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar
  ) {
    super(auth, router, route, PageId.bms_notifications);
    this.form = this.fb.group({
      title: [''],
    });
  }

  override async ngOnInit() {
    await super.ngOnInit();
    await this.getNotifications();
  }
  async getNotifications() {
    try {
      this.loader.open();
      const response = await this.notificationService.getAllNotifications(this.notificationListSearchInput);
      this.loader.close();
      if (response && Array.isArray(response.notificationList)) {
        this.dataSource = new MatTableDataSource(response.notificationList);
        this.totalNotifications = response.totalNotifications;

        if (this.totalNotifications > 0) {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        } else {
          Swal.fire('Warning!', 'No Notifications Found', 'warning');
        }
      } else {
        console.error('Error: notificationList is not an array or does not exist in the response.');
      }
    } catch (error) {
      Swal.fire('Error!', 'Failed to get notifications', 'error');
    } finally {
      this.loader.close();
    }
  }

  private initializeSearchInput(): NotificationListSearch {
    return {
      title: "",
      pageNumber: 1,
      pageSize: 25,
      createdDate: ""
    };
  }


  getDate(date: any) {
    const milliseconds = date.seconds * 1000 + date.nanoseconds / 1000000;
    const newDate = new Date(milliseconds);
    const formattedDate = newDate.toLocaleString();
    return formattedDate;
  }

  async searchNotifications() {
    const { title } = this.form.value;
    if (!title) {
      Swal.fire('Warning!', 'Please enter title', 'warning');
      return;
    }
    this.pageNumber = 1;
    this.pageSize = this.DEFAULT_PAGE_SIZE;
    this.paginator.pageIndex = 0;
    this.notificationListSearchInput = this.createNotificationListSearchInput(title?.trim());
    await this.getNotifications();

  }

  async clearFilters() {
    this.clearForm();
    this.notificationListSearchInput = this.initializeSearchInput();
    await this.getNotifications();
  }

  private clearForm() {
    this.form = this.fb.group({
      title: [null],
      pageNumber: 1,
      pageSize: 25,
      createdDate: ""
    });
  }



  private createNotificationListSearchInput(title: string): NotificationListSearch {
    return {
      title: title || '',
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      createdDate: ""
    };
  }

  override async onPageChange(event: any) {
    await super.onPageChange(event);
    const { title } = this.form.value;
    this.notificationListSearchInput = this.createNotificationListSearchInput(title)
    await this.getNotifications();
  }

  onResetFilters() {
    this.form.reset();
  }

  async sendNotification(row: any) {
    const inputObj = {
      notificationId: row.id
    }

    await this.notificationService.postMessageToMessageQueue(inputObj);

    Swal.fire({
      title: 'Notification Sent Successfully.',
      text: 'Please wait for 10 minutes.',
      icon: 'success'
    });

  }

  copyToClipboard(text: string) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log('Link copied to clipboard:', text);
        this.snackBar.open('Link copied to clipboard!', 'Close', {
          duration: 3000, // Duration in milliseconds
          verticalPosition: 'bottom', // Position of the snackbar
          horizontalPosition: 'center', // Position of the snackbar
        });
      })
      .catch((err) => {
        console.error('Failed to copy link:', err);
        this.snackBar.open('Failed to copy link.', 'Close', {
          duration: 3000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center',
        });
      });
  }

  async onDelete(rowId: string): Promise<void> {
    try {
      const { isConfirmed } = await Swal.fire({
        title: 'Are you sure you want to delete this notification?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      });

      if (!isConfirmed) {
        Swal.fire('Cancelled', 'Notification was not deleted', 'info');
        return;
      }
      this.loader.open();
      await this.notificationService.delete(rowId);
      this.loader.close();

      await Swal.fire('Deleted!', 'Notification deleted successfully.', 'success');

      await this.getNotifications();
    } catch (error) {
      await Swal.fire('Error', 'An error occurred while deleting the notification. Please try again later.', 'error');
    }
  }



}
