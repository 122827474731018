<div class="container">
  <form [formGroup]="form">
    <section id="content">
      <mat-card>
        <div class="card-body card-padding">
          <div class="col-md-6 m-3">
            <span class="header">
              <h2 class="title">Sale Register</h2>
            </span>
          </div>
          <div class="row m-3">
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="srFromDate" placeholder="From Date" [matDatepicker]="appDatepicker">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="srToDate" placeholder="To Date" [matDatepicker]="appDatepickers">
                <mat-datepicker-toggle matSuffix [for]="appDatepickers"></mat-datepicker-toggle>
                <mat-datepicker #appDatepickers></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>Format Type</mat-label>
                <mat-select formControlName="formatType">
                  <mat-option *ngFor="let status of formatType" [value]="status">
                    {{ getFormatTypeName(status) }}
                  </mat-option>

                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="button-container text-center mb-3">
            <button mat-raised-button color="primary" (click)="download(DownloadType.SaleRegister)">Download</button>
          </div>
        </div>
      </mat-card>

      <mat-card class="mt-1">
        <div class="card-body card-padding">
          <div class="col-md-6 m-3">
            <span class="header">
              <h2 class="title">Credit Note Register</h2>
            </span>
          </div>
          <div class="row m-3">
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="srFromDate" placeholder="From Date" [matDatepicker]="appDatepicker11">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker11"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker11></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="srToDate" placeholder="To Date" [matDatepicker]="appDatepickers12">
                <mat-datepicker-toggle matSuffix [for]="appDatepickers12"></mat-datepicker-toggle>
                <mat-datepicker #appDatepickers12></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>Format Type</mat-label>
                <mat-select formControlName="formatType">
                  <mat-option *ngFor="let status of formatType" [value]="status">
                    {{ getFormatTypeName(status) }}
                  </mat-option>

                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="button-container text-center mb-3">
            <button mat-raised-button color="primary"
              (click)="download(DownloadType.CreditNoteRegister)">Download</button>
          </div>
        </div>
      </mat-card>

      <!-- <mat-card>
        <div class="card-body card-padding">
          <div class="col-md-6 m-3">
            <span class="header">
              <h2 class="title">Net Register</h2>
            </span>
          </div>
          <div class="row m-3">
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="srFromDate" placeholder="From Date" [matDatepicker]="appDatepicker12">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker12"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker12></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="srToDate" placeholder="To Date" [matDatepicker]="appDatepickers22">
                <mat-datepicker-toggle matSuffix [for]="appDatepickers22"></mat-datepicker-toggle>
                <mat-datepicker #appDatepickers22></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>Format Type</mat-label>
                <mat-select formControlName="formatType">
                

                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="button-container text-center mb-3">
            <button mat-raised-button color="primary" (click)="download(DownloadType.SaleRegister)">Download</button>
          </div>
        </div>
      </mat-card> -->


      <!--for admin provider reconcilation-->



      <!-- <mat-card>
    <div class="card-body card-padding">
      <div class="col-md-6 m-3">
        <span class="header">
          <h2 class="title">Admin Provider Reconciliation</h2>
        </span>
      </div>
      <div class="row m-3">
        <div class="col-md-6">
          <mat-form-field class="full-width">
            <input matInput formControlName="srFromDate" placeholder="From Date" [matDatepicker]="apFromDatepicker">
            <mat-datepicker-toggle matSuffix [for]="apFromDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #apFromDatepicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="full-width">
            <input matInput formControlName="srToDate" placeholder="To Date" [matDatepicker]="apToDatepicker">
            <mat-datepicker-toggle matSuffix [for]="apToDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #apToDatepicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width">
            <mat-label>Provider</mat-label>
            <mat-select formControlName="provider">
              <mat-option *ngFor="let provider of gatewayTypes" [value]="provider">
               {{getProviderName(provider)}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width">
            <mat-label>Status</mat-label>
            <mat-select formControlName="status">
              <mat-option *ngFor="let status of paymentStatuses" [value]="status">
                {{ getPaymentStatusName(status) }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="button-container text-center mb-3">
        <button mat-raised-button color="primary" (click)="download(DownloadType.AdminProviderReconciliation)">Download</button>
      </div>
    </div>
  </mat-card> -->


      <!--for admin provider reconcilation till hereeee-->

      <!--for admin seller payout-->

      <!-- <mat-card>
      <div class="card-body card-padding">
        <div class="col-md-6 m-3">
          <span class="header">
            <h2 class="title">Admin Seller Payout</h2>
          </span>
        </div>
        <div class="row m-3">
          <div class="col-md-6">
            <mat-form-field class="full-width">
              <input matInput formControlName="srFromDate" placeholder="From Date" [matDatepicker]="apsDatepicker">
              <mat-datepicker-toggle matSuffix [for]="apsDatepicker"></mat-datepicker-toggle>
              <mat-datepicker #apsDatepicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="full-width">
              <input matInput formControlName="srToDate" placeholder="To Date" [matDatepicker]="apsDatepickers">
              <mat-datepicker-toggle matSuffix [for]="apsDatepickers"></mat-datepicker-toggle>
              <mat-datepicker #apsDatepickers></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="button-container text-center mb-3">
          <button mat-raised-button color="primary" (click)="download(DownloadType.AdminSellerPayout)">Download</button>
        </div>
      </div>
    </mat-card> -->


      <!--for admin seller payout till hereeee-->



      <mat-card class="mt-1">
        <div class="card-body card-padding">
          <div class="col-md-6 m-3">
            <span class="header">
              <h2 class="title">Invoice level Settlement Report</h2>
            </span>
          </div>
          <div class="row m-3">
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="invFromDate" placeholder="From Date" [matDatepicker]="appDatepicker1">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker1"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker1></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="invToDate" placeholder="To Date" [matDatepicker]="appDatepicker2">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker2"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker2></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>Format Type</mat-label>
                <mat-select formControlName="invFormatType">
                  <mat-option *ngFor="let status of formatType" [value]="status">
                    {{ getFormatTypeName(status) }}
                  </mat-option>

                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="button-container text-center mb-3">
            <button mat-raised-button color="primary"
              (click)="download(DownloadType.InvoiceSettlement)">Download</button>
          </div>
        </div>
      </mat-card>
      <mat-card class="mt-1" style="display:none">
        <div class="card-body card-padding">
          <div class="col-md-6 m-3">
            <span class="header">
              <h2 class="title">SKU level Settlement Report</h2>
            </span>
          </div>
          <div class="row m-3">
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="srFromDate" placeholder="From Date" [matDatepicker]="appDatepicker3">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker3"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker3></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="srToDate" placeholder="To Date" [matDatepicker]="appDatepicker4">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker4"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker4></mat-datepicker>
              </mat-form-field>
            </div>

          </div>
          <div class="button-container text-center mb-3">
            <button mat-raised-button color="primary"
              (click)="download(DownloadType.SkuLevelSettlement)">Download</button>
          </div>
        </div>
      </mat-card>
      <!-- <mat-card class="mt-1">
        <div class="card-body card-padding">
          <div class="col-md-6 m-3">
            <span class="header">
              <h2 class="title">Admin Collection Reconciliation</h2>
            </span>
          </div>
          <div class="row m-3">
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="srFromDate" placeholder="From Date" [matDatepicker]="appDatepicker5">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker5"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker5></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="srToDate" placeholder="To Date" [matDatepicker]="appDatepicker6">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker6"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker6></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="button-container text-center mb-3">
            <button mat-raised-button color="primary" (click)="download(DownloadType.AdminCollectionReconciliation)">Download</button>
          </div>
        </div>
      </mat-card> -->

      <mat-card class="mt-1">
        <div class="card-body card-padding">
          <div class="col-md-6 m-3">
            <span class="header">
              <h2 class="title">Purchase Register</h2>
            </span>
          </div>
          <div class="row m-3">
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="srFromDate" placeholder="From Date" [matDatepicker]="appDatepicker01">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker01"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker01></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="srToDate" placeholder="To Date" [matDatepicker]="appDatepicker02">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker02"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker02></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>Format Type</mat-label>
                <mat-select formControlName="formatType">
                  <mat-option *ngFor="let status of formatType" [value]="status">
                    {{ getPurchaseFormatTypeName(status) }}
                  </mat-option>

                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="button-container text-center mb-3">
            <button mat-raised-button color="primary"
              (click)="download(DownloadType.PurchaseRegister)">Download</button>
          </div>
        </div>
      </mat-card>
      <!-- <mat-card class="mt-1">
        <div class="card-body card-padding">
          <div class="col-md-6 m-3">
            <span class="header">
              <h2 class="title">Purchase Return Register</h2>
            </span>
          </div>
          <div class="row m-3">
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="srFromDate" placeholder="From Date" [matDatepicker]="appDatepicker7">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker7"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker7></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="srToDate" placeholder="To Date" [matDatepicker]="appDatepicker8">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker8"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker8></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>Format Type</mat-label>
                <mat-select formControlName="prrFormatType">
                 

                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="button-container text-center mb-3">
            <button mat-raised-button color="primary"
              (click)="download(DownloadType.PurchaseReturnRegister)">Download</button>
          </div>
        </div>
      </mat-card> -->
      <mat-card class="mt-1">
        <div class="card-body card-padding">
          <div class="col-md-6 m-3">
            <span class="header">
              <h2 class="title">Purchase Forecast</h2>
            </span>
          </div>
          <div class="row m-3"></div>
          <div class="button-container text-center mb-3">
            <button mat-raised-button color="primary"
              (click)="download(DownloadType.PurchaseForecast)">Download</button>
          </div>
        </div>
      </mat-card>
      <mat-card class="mt-1">
        <div class="card-body card-padding">
          <div class="col-md-6 m-3">
            <span class="header">
              <h2 class="title">Margin</h2>
            </span>
          </div>
          <div class="row m-3">
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="srFromDate" placeholder="From Date" [matDatepicker]="appDatepicker9">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker9"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker9></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="srToDate" placeholder="To Date" [matDatepicker]="appDatepicker10">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker10"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker10></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="button-container text-center mb-3">
            <button mat-raised-button color="primary" (click)="download(DownloadType.Margin)">Download</button>
          </div>
        </div>
      </mat-card>
    </section>
  </form>
</div>