import {
  SupplierEntity,
  SupplierListSearch,
} from '../../models/supplier-model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SupplierService } from '../../services/supplier.service';
import { MatSidenavModule } from '@angular/material/sidenav';
import {
  ContractMode,
  LogisticsSupplierType,
  SupplierStatus,
  SupplierType,
  SwalMessageTypes,
} from '../../../../../enums/enums';
import Swal from 'sweetalert2';
import { AppLoaderService } from '../../../../../shared/app-loader/app-loader.service';
import { AuthService } from '../../../../../services/auth.service';
import { BaseListComponent } from '../../../../../shared/core/base.list.component';
import { PageId } from '../../../../../constants/enums';

@Component({
  selector: 'app-logistics-supplier-list',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    RouterLink,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSidenavModule,
  ],
  templateUrl: './logistics-supplier-list.component.html',
  styleUrl: './logictics-supplier-list.component.scss',
})
export class LogisticsSupplierListComponent extends BaseListComponent {
  dataSource!: MatTableDataSource<SupplierEntity>;
  supplierServiceObservable: any;
  suppliers: SupplierEntity[] = [];
  totalSuppliers: number = 0;
  form: FormGroup;
  supplierStatus: any = Object.values(SupplierStatus).filter(
    (value) => typeof value === 'number'
  );

  supplierListSearchInput: SupplierListSearch = this.initializeSearchInput();

  displayedColumns: string[] = [
    'name',
    'mobileNumber',
    'supplierType',
    'contractMode',
    'status',
    'edit',
    'delete',
  ];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  supplierTypes: LogisticsSupplierType[] = Object.values(
    LogisticsSupplierType
  ).filter((value) => typeof value === 'number') as LogisticsSupplierType[];
  supplierTypeNames: { [key: number]: string } = {
    [LogisticsSupplierType.Manpower]: 'Manpower',
    [LogisticsSupplierType.Transporter]: 'Transporter',
  };

  constructor(
    auth: AuthService,
    router: Router,
    private fb: FormBuilder,
    route: ActivatedRoute,
    private supplierService: SupplierService,

    private loader: AppLoaderService
  ) {
    super(auth, router, route, PageId.logistics_suppliers);
    this.form = this.fb.group({
      supplierName: '',
      type: null,
      status: null,
    });
  }

  override async ngOnInit() {
    await super.ngOnInit();
    await this.getSuppliers();
  }

  private async getSuppliers() {
    try {
      this.loader.open();
      const response = await this.supplierService.getSuppliers(
        this.supplierListSearchInput
      );
      if (
        response &&
        response['supplierList'] &&
        response['supplierList'].length > 0
      ) {
        this.dataSource = response['supplierList'];
        this.suppliers = response['supplierList'];
        this.totalSuppliers = response['totalSuppliers'];
        this.loader.close();
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.totalSuppliers = response.totalSuppliers;
      } else {
        this.totalSuppliers = 0;
        this.dataSource = response[''];
        this.loader.close();
        Swal.fire({
          icon: 'info',
          text: 'No suppliers found.',
        });
      }
    } catch (error) {
      this.loader.close();
    }
  }

  editSupplier(supplier: any) {
    this.router.navigateByUrl(
      `/dashboard/logistics/edit-supplier/${supplier.id}`
    );
  }

  async deleteSupplier(row: any) {
    try {
      const result = await Swal.fire({
        title: 'Do you want to Delete Supplier?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      });

      if (!result.isConfirmed) {
        return;
      }
      await this.supplierService.deleteSupplier(row.id);
      await this.clearFilters();
      this.showMessage(
        'Supplier deleted Successfully',
        SwalMessageTypes.Success
      );
    } catch (error) {
      console.error('Error occurred while creating supplier:');
      this.showMessage(
        'Failed to Delete Supplier. Please try again later.',
        SwalMessageTypes.Error
      );
    }
  }

  override async onPageChange(event: any) {
    super.onPageChange(event);
    const { supplierName, type, status } = this.form.value;
    this.supplierListSearchInput = this.createSupplierListSearchInput(
      supplierName,
      type,
      status
    );
    await this.getSuppliers();
  }

  async searchTasks() {
    const { supplierName, type, status } = this.form.value;
    this.pageNumber = this.pageNumber;
    this.pageSize = this.pageSize;
    if (supplierName || type || status !== undefined) {
      this.supplierListSearchInput = this.createSupplierListSearchInput(
        supplierName,
        type,
        status
      );
      await this.getSuppliers();
    }
  }

  async clearFilters() {
    this.searchForm();
    this.supplierListSearchInput = this.initializeSearchInput();
    await this.getSuppliers();
  }

  getSupplierStatusText(value: number): string {
    switch (value) {
      case SupplierStatus.Active:
        return 'Active';
      case SupplierStatus.InActive:
        return 'InActive';
      default:
        return 'Unknown Type';
    }
  }

  getSupplierContractModeText(value: number): string {
    switch (value) {
      case ContractMode.Adhoc:
        return 'Adhoc';
      case ContractMode.Fixed:
        return 'Fixed';
      default:
        return 'Unknown Type';
    }
  }
  getSupplierTypeModeText(value: number): string {
    switch (value) {
      case SupplierType.Manpower:
        return 'Manpower';
      case SupplierType.Transporter:
        return 'Transporter';
      case SupplierType.Procurement:
        return 'Procurement';
      default:
        return 'Unknown Type';
    }
  }

  // private functions

  private initializeSearchInput(): SupplierListSearch {
    return {
      searchText: '',
      type: null,
      status: null,
      pageNumber: 1,
      pageSize: 10,
    };
  }

  private createSupplierListSearchInput(
    supplierName: string,
    type: any,
    status: any
  ): SupplierListSearch {
    return {
      searchText: supplierName || '',
      type: type || null,
      status: status == 0 ? 0 : !status ? null : status == 1 ? 1 : null,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
    };
  }
  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }
  private searchForm() {
    this.form = this.fb.group({
      supplierName: '',
      type: null,
      status: null,
    });
  }
  getStatusName(status: number): string {
    return SupplierStatus[status];
  }
}
