import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-company-filter-dialog',
  standalone: true,
  imports: [ReactiveFormsModule,MatButtonModule,
    MatDialogModule, MatSelectModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule],
  templateUrl: './company-filter-dialog.component.html',
  styleUrl: './company-filter-dialog.component.scss'
})
export class CompanyFilterDialogComponent implements OnInit {
  filterForm:any;
  constructor(
    public dialogRef: MatDialogRef<CompanyFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb:FormBuilder
  ) {
    this.filterForm = this.fb.group({
      name: [null],
      display_name: [null],
      page: 1,
      limit: 5
    })
  }
  ngOnInit(): void {
    this.filterForm.patchValue(this.data)
  }
  close(){
    this.dialogRef.close()
  }
  submit(){
    let data =   {
      name: this.filterForm.value.name,
      display_name: this.filterForm.value.display_name,
    page: 1,
    limit: 5
    }
    this.dialogRef.close(data)
  }
  reset(){
    this.filterForm.patchValue(
      {
        name: null,
        display_name: null,
      page: 1,
      limit: 5
      }
    )
    this.dialogRef.close(this.filterForm.value)
  }
}
