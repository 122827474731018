import { Component } from '@angular/core';
import { ProductService } from '../product/services/product.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { PendingApprovalDetailsComponent } from '../../shared/dialogs/pending-approval-details/pending-approval-details.component';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { CommonService } from '../../services/common.service';
import { AuthService } from '../../services/auth.service';
import { StatusComponent } from '../../shared/status/status.component';
import { TypePipe } from '../../shared/pipes/type.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PendingApprovalsFilterDialogComponent } from './pending-approvals-filter-dialog/pending-approvals-filter-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseListComponent } from '../../shared/core/base.list.component';
import { FormBuilder } from '@angular/forms';
import { PageId } from '../../constants/enums';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-pending-approvals',
  standalone: true,
  imports: [
    StatusComponent,
    MatProgressSpinnerModule,
    TypePipe,
    MatIconModule,
    CommonModule,
    MatButtonModule,
    MatTableModule,
    MatCardModule,
  ],
  templateUrl: './pending-approvals.component.html',
  styleUrl: './pending-approvals.component.scss',
})
export class PendingApprovalsComponent extends BaseListComponent {
  pendingList: any = [];
  filterList: any = [];
  loading = true;
  checkQueryParam: boolean = false;
  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    public productService: ProductService,
    public dialog: MatDialog,
    public authService: AuthService,
    public commonService: CommonService
  ) {
    super(auth, router, route, PageId.bms_approvals);

  }
  override async ngOnInit() {
    await super.ngOnInit();
    this.commonService.showLoder();
    this.productService.getAllPendingDocs()
      .pipe(takeUntil(this.unsubscribe$)).subscribe(
        (res) => {
          this.pendingList = res;
          this.filterList = res;
          if (this.route.snapshot.queryParams['name']) {
            this.checkQueryParam = true;
            this.filterData({
              name: this.route.snapshot.queryParams['name'],
              type: this.route.snapshot.queryParams['type'],
              status: this.route.snapshot.queryParams['status'],
              vendor_name: this.route.snapshot.queryParams['vendor_name'],
            });
          }
          this.loading = false;
          this.commonService.hideLoder();
        },
        (err: any) => {
          this.commonService.hideLoder();
          this.loading = false;
          this.commonService.showToaster('error', err?.error?.message);
          console.log(err);
        }
      );

    if (this.route.snapshot.queryParams['id']) {
      this.getSingleData();
    }
  }

  async getSingleData() {
    let id = this.route.snapshot.queryParams['id'];
    let res: any = await (
      await this.productService.getSinglePendingDoc(id)
    ).data();
    res.id = id;
    console.log(res, 'getSinglePendingDoc');
    if (res.status == 'pending-approval') {
      this.view(res);
    }
  }
  async approve(item: any) {
    this.commonService.showLoder();
    try {
      item.admin_name = this.authService.userData.email_id;
      await this.productService.approveRequest(item);
      this.commonService.showToaster(
        'success',
        'Request Approved Successfully'
      );
      this.commonService.hideLoder();
    } catch (err: any) {
      this.commonService.showToaster('error', err.error.message);
      this.commonService.hideLoder();
    }
  }
  view(item: any) {
    const dialogRef = this.dialog.open(PendingApprovalDetailsComponent, {
      data: item,
      panelClass: 'custom-dialog-detail-class',
    });
  }
  isDataAvailable(): boolean {
    return this.pendingList && this.pendingList?.length > 0;
  }
  openFilter() {
    let routedata: any = { name: '', status: '' };
    if (this.route.snapshot.queryParams) {
      routedata = this.route.snapshot.queryParams;
    }
    const dialogRef = this.dialog.open(PendingApprovalsFilterDialogComponent, {
      data: routedata,
    });

    dialogRef.afterClosed().pipe(
      takeUntil(this.unsubscribe$) // Unsubscribe when the component is destroyed
    ).subscribe((result) => {
      if (result) {
        this.filterData(result);
      }
    });
  }
  filterData(result: any) {
    let convertedString: any;
    if (result.type) {
      convertedString = result.type.toLowerCase().replace(/\s+/g, '-');
    }
    if (result.name || result.vendor_name || result.type || result.status) {
      this.checkQueryParam = true;
      if (result.name) {
        this.filterList = this.pendingList.filter((item: any) =>
          item?.payload?.product?.name
            ?.toLowerCase()
            .includes(result.name?.toLowerCase())
        );
        if (result.name && result.vendor_name) {
          this.filterList = this.filterList.filter((item: any) =>
            item?.vendor_name
              ?.toLowerCase()
              .includes(result.vendor_name.toLowerCase())
          );
        }
        if (result.name && result.type) {
          this.filterList = this.filterList.filter((item: any) =>
            item?.type?.toLowerCase().includes(convertedString.toLowerCase())
          );
        }
        if (result.name && result.status) {
          this.filterList = this.filterList.filter((item: any) =>
            item?.status?.toLowerCase().includes(status.toLowerCase())
          );
        }
      }
      if (result.type) {
        this.filterList = this.pendingList.filter((item: any) =>
          item?.type?.toLowerCase().includes(convertedString.toLowerCase())
        );
        if (result.type && result.vendor_name) {
          this.filterList = this.filterList.filter((item: any) =>
            item?.vendor_name
              ?.toLowerCase()
              .includes(result.vendor_name.toLowerCase())
          );
        }
        if (result.type && result.name) {
          this.filterList = this.filterList.filter((item: any) =>
            item?.payload?.name
              ?.toLowerCase()
              .includes(result.name?.toLowerCase())
          );
        }
        if (result.type && result.status) {
          this.filterList = this.filterList.filter((item: any) =>
            item?.status?.toLowerCase().includes(result.status?.toLowerCase())
          );
        }
      }
      if (result.vendor_name) {
        this.filterList = this.pendingList.filter((item: any) =>
          item?.vendor_name
            ?.toLowerCase()
            .includes(result.vendor_name.toLowerCase())
        );
        if (result.vendor_name && result.type) {
          this.filterList = this.filterList.filter((item: any) =>
            item?.type?.toLowerCase().includes(convertedString.toLowerCase())
          );
        }
        if (result.vendor_name && result.name) {
          this.filterList = this.filterList.filter((item: any) =>
            item?.payload?.name
              ?.toLowerCase()
              .includes(result.name?.toLowerCase())
          );
        }
        if (result.type && result.status) {
          this.filterList = this.filterList.filter((item: any) =>
            item?.status?.toLowerCase().includes(result.status?.toLowerCase())
          );
        }
      }
      if (result.status) {
        this.filterList = this.pendingList.filter((item: any) =>
          item?.status?.toLowerCase().includes(result.status.toLowerCase())
        );
        if (result.status && result.type) {
          this.filterList = this.filterList.filter((item: any) =>
            item?.type?.toLowerCase().includes(convertedString.toLowerCase())
          );
        }
        if (result.status && result.name) {
          this.filterList = this.filterList.filter((item: any) =>
            item?.payload?.name
              ?.toLowerCase()
              .includes(result.name?.toLowerCase())
          );
        }
        if (result.status && result.vendor_name) {
          this.filterList = this.filterList.filter((item: any) =>
            item?.vendor_name
              ?.toLowerCase()
              .includes(result.vendor_name?.toLowerCase())
          );
        }
      }
    } else {
      this.filterList = this.pendingList;
      this.checkQueryParam = false;
    }
  }
}
