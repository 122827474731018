import {
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { PricingService } from '../services/pricing.service';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import {
  DownloadPricingDetails,
  PricingFiltersEntity,
  SearchItem,
  VendorSkuPricing,
  searchSkuInput,
} from '../../../entities/pricing';
import { trigger, transition, style, animate } from '@angular/animations';
import { PricingFilterPopupComponent } from '../pricing-filter/pricing-filter.component';
import { PricingDetailsComponent } from '../pricing-details/pricing-details.component';
import { CommonModule } from '@angular/common';
import { PricingDataService } from '../services/pricing-data.service';
import Swal from 'sweetalert2';
import { SettingService } from '../../settings/services/settings.service';
import { AuthService } from '../../../services/auth.service';
import { StockPopUp } from './stockpopup/stockpopup.component';
import { AppLoaderService } from '../../../shared/app-loader/app-loader.service';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { PageId } from '../../../constants/enums';
import { MerchantBusinessType } from '../../../enums/enums';
import { ReplaceCommasPipe } from "../../../shared/pipes/currency-pipe";
import { AppStateService } from '../../../services/appstate.service';
import * as XLSX from 'xlsx';

@Component({
  standalone: true,
  selector: 'app-pricing-list',
  templateUrl: './pricing-list.component.html',
  styleUrls: ['./pricing-list.component.scss'],
  animations: [
    trigger('animate', [
      transition('* => *', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 })),
      ]),
    ]),
  ],
  imports: [
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    RouterLink,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    MatTableModule,
    MatTableModule,
    MatSidenavModule,
    MatPaginatorModule,
    CommonModule,
    PricingDetailsComponent,
    ReplaceCommasPipe
  ]
})
export class PricingListComponent extends BaseListComponent implements OnInit {
  displayedColumns = [
    'name',
    // 'category',
    'brand',
    // 'company',
    'mrp',
    'sellingPrice',
    'pricingRule',
    'stock',
    'saleInProgress',
    'stockAvailableToCustomer',
    'status',
    'priceAction',
  ];
  totalPages: number = 1;
  result: any;
  merchantId: string = '';
  searchItems: SearchItem[] = [];
  searchSkuInput!: searchSkuInput;
  keyLabelMap: { [key: string]: string } = {
    skuTerm: 'SKU Name',
    company: 'Company Name',
    vendorId: '',
    status: 'Status'
  };
  selectedRowData: any;
  dataSource!: MatTableDataSource<VendorSkuPricing>;
  showPriceButton: boolean = false;
  showStockButton: boolean = false;
  defaultStatus: string[] = ['active'];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private pricingService: PricingService,
    private settingService: SettingService,
    private pricingDataService: PricingDataService,
    public egretLoader: AppLoaderService,
    private appState: AppStateService

  ) {
    super(auth, router, route, PageId.seller_price_and_stock);
  }

  override async ngOnInit() {
    await super.ngOnInit();
    this.resolveShowButtons();
    this.egretLoader.open();
    this.searchSkuInput = this.initializeSearchInput();
    this.updateSearchItemsData(this.searchSkuInput);
    await this.getVendorSkuList();
    this.merchantId = history.state.merchantDetail;
    this.egretLoader.close();
  }


  private resolveShowButtons() {
    if (this.BusinessType === MerchantBusinessType.Inventory && this.hasPricing) {
      this.showPriceButton = true;
    } else if (this.BusinessType === MerchantBusinessType.Inventory && this.hasInventory) {
      this.showStockButton = true;
    } else if (this.BusinessType !== MerchantBusinessType.Inventory && this.BusinessType !== MerchantBusinessType.None) {
      this.showStockButton = true;
      this.showPriceButton = true;
    }
  }
  async getVendorSkuList() {
    this.searchSkuInput.vendorId = this.vendorId;
    let customerSearchInput = this.transformInputData(this.searchSkuInput);
    customerSearchInput.status = this.defaultStatus;
    const response = await this.pricingService.getVendorSkuPricingList(
      customerSearchInput,
      this.pageNumber,
      this.pageSize
    );
    this.dataSource = response['priceList'];
    this.totalPages = response['totalCount'];
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  private transformInputData(inputData: any) {
    const transformedData = { ...inputData };
    if (
      Array.isArray(transformedData.company) &&
      !transformedData.company.every((item: any) => typeof item === 'string')
    ) {
      transformedData.companyIds = transformedData.company.map(
        (item: any) => item.id
      );
    }
    delete transformedData.company;
    return transformedData;
  }

  openDialog(): void {

    const dialogRef = this.dialog.open(PricingFilterPopupComponent, {
      width: '40%',
      height: '50%',
      data: {
        searchSkuInput: this.searchSkuInput,
        status: this.defaultStatus
      },
    });
    dialogRef.afterClosed().subscribe(async (response: any) => {
      if (response) {
        this.handleSearchDialogResult(response.searchSkuInput);
      }
    });
  }

  override async onPageChange(event: any) {
    await super.onPageChange(event);
    await this.getVendorSkuList();
  }

  private initializeSearchInput(): searchSkuInput {
    const filters = this.appState.getItem<PricingFiltersEntity>('pricingFilters');
    if (filters != null) {
      this.updateSearchItemsData(filters.searchSkuInput);
      this.pageNumber = filters.pageNumber;
      this.pageSize = filters.pageSize;
    }
    this.searchSkuInput = filters ? filters.searchSkuInput : {
      vendorId: this.vendor?.id || '',
      skuTerm: '',
      status: this.defaultStatus,
    };

    return this.searchSkuInput;
  }

  async clearFilters() {
    // this.searchForm();
    this.appState.clearAll();
    this.defaultStatus = [];
    this.searchSkuInput = this.initializeSearchInput();
    this.updateSearchItemsData(this.searchSkuInput);
    await this.getVendorSkuList();
  }

  navigateToPriceDetails(row: any) {
    const pricingFilters: PricingFiltersEntity = {
      searchSkuInput: this.searchSkuInput,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize
    }
    this.appState.setItem('pricingFilters', pricingFilters);
    let vendor = {
      vendor_id: this.vendor.id,
      businessTypeId: this.vendor.business_type,
    };
    const data = { skuDetails: row, vendorDetails: vendor };
    localStorage.setItem('pricingDetails', JSON.stringify(data));
    this.pricingDataService.setRowData(data);
    this.router.navigate(['/dashboard/pricing/pricing-details']);
  }

  async downloadExcel() {
    try {

      const blobData = await this.settingService.exportMarginProductsByFilters(
        this.vendor.id
      );
      if (this.result == 0) {
        Swal.fire({
          title: 'No records found',
          icon: 'info',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
        return;
      }

      // const result = await Swal.fire({
      //   title: 'Do you want to proceed?',
      //   icon: 'question',
      //   showCancelButton: true,
      //   confirmButtonText: 'Yes',
      //   cancelButtonText: 'No',
      // });

      // if (!result.isConfirmed) {
      //   return;
      // }

      const blob = new Blob([blobData], { type: 'application/xlsx' });
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = 'MarginProductsReport.xlsx';
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error in downloadToExcel:', error);
    }
  }

  openPopUp(data: any) {

    const inputObj = {
      rowData: data,
      vendorBusinessType: this.vendor.business_type || this.vendor.businessType || "",
    };
    const dialogRef = this.dialog.open(StockPopUp, {
      width: '50%',
      height: '40%',
      data: inputObj,
    });
    dialogRef.afterClosed().subscribe(async (response: any) => {
      if (response) {
        this.egretLoader.open();
        await this.getVendorSkuList();
        this.egretLoader.close();
      }
    });
  }

  private handleSearchDialogResult(result: any) {
    this.searchSkuInput = result;
    this.defaultStatus = result.status
    this.updateSearchItemsData(result);
    this.resetPageAndFetchData();
  }

  async removeSearchItem(id: number, removedSearch: string) {
    this.searchItems = this.searchItems.filter((badge) => badge.id !== id);
    this.removeBadge(removedSearch);
    if (removedSearch == 'status' && id == 2) {
      this.defaultStatus = []
    }
    await this.resetPageAndFetchData();


  }
  private removeBadge(badgeText: string) {
    if (Array.isArray(this.searchSkuInput[badgeText])) {
      this.searchSkuInput[badgeText] = '';
    } else {
      this.searchSkuInput[badgeText] = '';
    }
  }

  private async resetPageAndFetchData() {
    this.pageNumber = 1;
    this.pageSize = this.pageSize;
    this.paginator.pageIndex = 0;
    await this.getVendorSkuList();
  }

  mapToDownLoadExcel(sku: DownloadPricingDetails[]) {
    const data: any = {
      Pricing: sku.map((item: any) => ({
        "SKU Name": item.name,
        "Brand": item.brand_name,
        "MRP": item.maximum_retail_price,
        "Selling Price": item.selling_price,
        "Pricing Rules": item.pricing_rules_count,
        "Stock": item.available_stock,
        "Sale In Progress": item.sale_in_progress,
        "Available To Customer": item.available_stock - item.sale_in_progress,
        "Status": item.status === 'active' ? 'Active' : 'Inactive',
      }))
    };

    return data
  }

  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  private isSalesPersonResponseEmpty(data: any) {
    return Object.keys(data).every((key) => data[key].length === 0);
  }

  async downloadPricing() {
    try {
      this.egretLoader.open();
      this.searchSkuInput.vendorId = this.vendorId;
      let customerSearchInput = this.transformInputData(this.searchSkuInput);
      const response = await this.pricingService.getVendorSkuPricingList(
        customerSearchInput,
        1,
        5000
      );
      const data = this.mapToDownLoadExcel(response['priceList']);
      let isDataNotEmpty = this.isSalesPersonResponseEmpty(this.dataSource);

      if (isDataNotEmpty) {
        this.showMessage('There is no data to downLoad', 'warning');
        this.egretLoader.close();

        return;
      }

      const workbook = XLSX.utils.book_new();
      Object.keys(data).forEach((key) => {
        const sheet = XLSX.utils.json_to_sheet(data[key]);
        XLSX.utils.book_append_sheet(workbook, sheet, key);
      });

      XLSX.writeFile(workbook, 'Pricing List.xlsx');
      this.showMessage('downloaded successfully', 'success');
      this.egretLoader.close();
    } catch (error) {
      this.showMessage('Some thing went wrong', 'warning');
      this.egretLoader.close();

    } finally {
      this.egretLoader.close();
    }

  }

  private updateSearchItemsData(result: any) {
    result.status = result.status && result.status.filter((item: string) => this.defaultStatus.includes(item));
    this.searchItems = Object.entries(result)
      .filter(([key, value]) => {
        if (Array.isArray(value) && value.length === 0) {
          return false;
        }
        return value !== '' && value !== null;
      })
      .map(([key, value], index) => {

        const statusMap: { [key: string]: string } = {
          active: 'Active',
          inactive: 'In-Active'
        };
        if (key === 'company') {
          if (Array.isArray(value)) {
            const names = value.map((item: any) => item.name);
            return {
              id: index + 1,
              text: key,
              searchText: names.join(', '),
            } as SearchItem;
          } else if (typeof value === 'object' && value !== null) {
            return {
              id: index + 1,
              text: key,
              searchText: (value as { name: string }).name,
            } as SearchItem;
          } else {
            return {
              id: index + 1,
              text: key,
              searchText: String(value),
            } as SearchItem;
          }
        } else if (key === 'status') {
          // Handling for 'status
          if (Array.isArray(value)) {
            const names = value.map((status: string) => statusMap[status] || status); // Convert values to their display names
            return {
              id: index + 1,
              text: key,
              searchText: names.join(', '),
            } as SearchItem;

          } else if (typeof value === 'string') {
            return {
              id: index + 1,
              text: key,
              searchText: statusMap[value] || value, // Convert single value to its display name
            } as SearchItem;
          } else {
            // Use the defaultStatus if the value is not an array or string
            const defaultNames = this.defaultStatus.map((status: string) => statusMap[status] || status);
            return {
              id: index + 1,
              text: key,
              searchText: defaultNames.join(', '), // Use default status for searchText
            } as SearchItem;
          }
        } else {
          return {
            id: index + 1,
            text: key,
            searchText: String(value),
          } as SearchItem;
        }
      })
      .filter((item): item is SearchItem => item !== null);
  }

}
