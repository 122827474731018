import { Component, Input } from '@angular/core';
import { CommonService } from '../../../../services/common.service';
import { ProductService } from '../../../../pages/product/services/product.service';
import { TypePipe } from '../../../pipes/type.pipe';
import { StatusComponent } from '../../../status/status.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { ReplaceCommasPipe } from "../../../pipes/currency-pipe";

@Component({
    selector: 'app-view-sku',
    standalone: true,
    templateUrl: './view-sku.component.html',
    styleUrl: './view-sku.component.scss',
    imports: [StatusComponent, MatCardModule,
        TypePipe, CommonModule, FormsModule, ReplaceCommasPipe]
})
export class ViewSkuComponent {
  @Input() data:any;
  skuData:any;
  constructor(public commonService: CommonService, public productService: ProductService){
    this.commonService.showLoder()
    setTimeout(() => {
      this.getAll()
    }, 1000);

  }
  ngOnInit():void{}

  getAll(){
    this.commonService.showLoder()
   this.productService.getDraftSkuPreviewDetails(this.data.payload).subscribe((res:any)=>{
      this.skuData=res.data
      console.log(this.skuData,"skuData")
      this.commonService.hideLoder()
     }, (err:any)=>{
      this.commonService.hideLoder()
      this.commonService.showToaster('error',err.message)

     })

  }
}
