import { ConfigService } from './../services/config.service';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import Swal from 'sweetalert2';
import { VendorDB } from '../../../models/db-vendor.model';
import { MatIconModule } from '@angular/material/icon';
import { AppLoaderService } from '../../../shared/app-loader/app-loader.service';

@Component({
  selector: 'area-config',
  standalone: true,
  imports: [
    MatButtonModule,
    RouterOutlet,
    MatCardModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    CommonModule,
    RouterLink,
    ReactiveFormsModule,
    MatIconModule
  ],

  templateUrl: './area-config.component.html',
  styleUrl: './area-config.component.scss',
})
export class AreaConfigComponent {
  page_id = 'bms_area_config';
  dataSource: any[] = [];
  vendor!: VendorDB;
  selectedIds: any;
  id!: string;
  form: FormGroup;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public route: ActivatedRoute,
    private configService: ConfigService,
    private readonly egretLoader: AppLoaderService,


  ) {
    this.form = this.fb.group({
      polygons: new FormControl(),
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe(async (params) => {
      this.id = params['id'];
      await this.fetchData();
    });
  }

  async fetchData(): Promise<void> {
    this.egretLoader.open();
    const response = await this.configService.getVendorById(this.id);
    const masterPolygons = await this.configService.getPolygons();
    this.vendor = response.data;
    this.selectedIds = this.vendor?.vendor_config?.polygonIds || [];
    this.egretLoader.close();
    const dataSource = this.mapLocations(masterPolygons, this.selectedIds);
    if (dataSource.length > 0) {
      this.dataSource = dataSource
    } else {
      Swal.fire('Warning!', 'No Areas found', 'warning');

    }
  }

  private mapLocations(res: any[], selectIds: string[]): any[] {
    return res.map((item) => ({
      ...item,
      isSelected: selectIds.includes(item.id),
    }));
  }

  async savePolygons(): Promise<void> {
    const selectedPolygons = this.dataSource.filter(item => item.isSelected);
    if (selectedPolygons.length === 0) {
      Swal.fire('Warning!', 'Select at least one Area', 'warning');
      return;
    }
    const polygonsWithCoordinates = selectedPolygons.filter(polygon => polygon.coordinates == null);
    if (polygonsWithCoordinates.length > 0) {
      Swal.fire('Warning!', 'Selected area doesnot have coordinates', 'warning');
      return;
    }
    try {
      const selectedIds = selectedPolygons.map(item => item.id);
      await this.configService.savePolygon(this.vendor.id, this.vendor.business_type, selectedPolygons);
      await this.configService.savePolygonInCd(this.vendor.id, selectedIds, this.vendor?.vendor_config ?? null);


      Swal.fire('Success', 'Area polygons updated successfully', 'success');
      this.router.navigateByUrl(`/dashboard/merchant`);
    } catch (error) {
      throw error;
    }

  }

}
