import {
  Component,
  ViewChild,
  Inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  FormBuilder,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AuthService } from '../../../../../../services/auth.service';
import {
  TripStatus,
} from '../../../../../../enums/enums';
// import { PlanningsService } from '../../services/trip.service';
import { MatMenuModule } from '@angular/material/menu';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { TripService } from '../../../services/trip.service';
import { getDateFormat } from '../../../../../../utlity/utility';
import { BaseListComponent } from '../../../../../../shared/core/base.list.component';
import { PageId } from '../../../../../../constants/enums';
import { MoveHopResponse } from '../../../../../../entities/trip-info';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-move-hop',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    RouterLink,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatMenuModule,
  ],
  templateUrl: './move-hop.component.html',
  styleUrls: ['./move-hop.component.scss'],
})
export class MoveHopComponent extends BaseListComponent {
  tripStatus = Object.values(TripStatus).filter(
    (value) => typeof value === 'number'
  );
  dataSource: any;
  trips: any;
  currentTripId: string = '';
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    public readonly tripService: TripService,
    public dialogRef: MatDialogRef<MoveHopComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(auth, router, route, PageId.logistics_trips);
    this.currentTripId = data.tripId;
    this.dataSource = this.fb.group({
      trips: new FormControl(''),
    });
  }
  override async ngOnInit() {
    await super.ngOnInit();
    const today: Date = new Date();
    const formattedDate = getDateFormat(today);
    const trips: MoveHopResponse[] =
      await this.tripService.getMoveHopTrips(formattedDate);
    let filteredTrips = trips.filter(
      (trip: any) => trip.tripId !== this.currentTripId
    );
    this.trips = filteredTrips.sort((a, b) => {
      if (a.tripName < b.tripName) {
        return -1;
      }
      if (a.tripName > b.tripName) {
        return 1;
      }
      return 0;
    });

  }

  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  save() {
    if (this.dataSource.value.trips == '') {
      this.showMessage("You have't selected any trip", 'warning');

      return
    }
    this.dialogRef.close({
      trip: this.dataSource.value.trips,
    });
  }
}
