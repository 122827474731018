<div class="container">
   @if(hasView){
  <ng-container>
    <div class="row">
      <div class="col-md-6">
        <h2 class="card-title m-3 text-violet">Add Area</h2>
      </div>
      <div class="col-md-6">
        <mat-icon (click)="dialogRef.close()" class="float-right m-3 closeicon">close</mat-icon>
      </div>
    </div>
    <div class="row">

      <form [formGroup]="dataSource" class="m-3">
        <section id="content">
          <div class="container">
            <mat-card>
              <mat-card-content>
                <div class="row">
                  <div class="col-xs-12">
                    <div>
                      <mat-form-field class="full-half nameinput">
                        <input matInput formControlName="name" name="name" placeholder="Area Name" />
                      </mat-form-field>
                    </div>
                    <label class="errorMessage" *ngIf="isNameExists">The name already exists. Please
                      choose a different name.</label>
                    <label class="errorMessage" *ngIf="isNameValid">Name is not Valid. Please
                      correct Name(Remove Extra Spaces and Special Characters).</label>

                    <input type="file" accept=".geojson" (change)="onSelectFile($event)" class="m-10 choosefile"
                      id="fileInput" />

                    <button mat-raised-button color="primary" (click)="addNewArea()">Save</button>

                    <label class="errorMessage" *ngIf="isGeoJsonNotSingle">Upload Only one geo Polygon At a
                      time.</label>

                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </section>
      </form>
    </div>
  </ng-container>
  } @else {
  <ng-container>
    <p class="text-center">
      You dont have permission , Please contact administrator
    </p>
  </ng-container>
  } 

</div>