<ng-container>
  <div class="row">
    <div class="col-md-6">
      <h2 class="card-title m-3 text-violet">Stock Update</h2>
    </div>
    <div class="col-md-6 d-flex justify-content-end">
      <mat-icon (click)="dialogRef.close()" class=" m-3 closeicon">close</mat-icon>
    </div>
  </div>
  <div class="row">
    <form [formGroup]="dataSource" class="m-3">
      <section id="content">
        <div class="container">
          <mat-card>
            <mat-card-content>
              <div class="row">
                <div class="col-md-6">
                  <h2 class="card-title text-violet">Available Quantity</h2>
                  <h3 class="card-title text-violet">{{ productData?.rowData?.available_stock }}</h3>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="full-half nameinput">
                    <input matInput formControlName="availableQuantity" placeholder="Available Quantity" />
                  </mat-form-field>
                </div>
              </div>
              @if(productData?.vendorBusinessType === 5 ){
              <div class="row">
                <div class="col-md-6">
                  <h2 class="card-title text-violet">Damage Quantity</h2>
                  <h3 class="card-title text-violet">{{productData?.rowData?.damage}}</h3>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="full-half nameinput">
                    <input matInput formControlName="damageQuantity" placeholder="Damage Quantity" />
                  </mat-form-field>
                </div>
              </div>
              }

              <div class="col-md-12 custom-flex">
                <div class="text-center mt-2 flex-item ml-10">
                  <button mat-raised-button type="button" color="primary" class="text-center ml" (click)="submitForm()">
                    Submit
                  </button>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </section>
    </form>
  </div>
</ng-container>