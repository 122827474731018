<div class="container">
  <form [formGroup]="form">
    <section id="content">
      <mat-card>
        <div class="card-body card-padding">
          <div class="col-md-6 m-3">
            <span class="header">
              <h2 class="title">Collection</h2>
            </span>
          </div>
          <div class="row m-3">

            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="collectionFromDate" placeholder="From Date"
                  [matDatepicker]="appDatepicker5" [readonly]="true" >
                <mat-datepicker-toggle matSuffix [for]="appDatepicker5"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker5></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="collectionToDate" placeholder="To Date"
                  [matDatepicker]="appDatepicker4" [readonly]="true" >
                <mat-datepicker-toggle matSuffix [for]="appDatepicker4"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker4></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="button-container text-center mb-3">
            <button mat-raised-button color="primary"
              (click)="download(DownloadType.LogisticsCollections)">Download</button>
            <button class="ml-5" mat-raised-button color="primary"
              (click)="clearFilters(DownloadType.LogisticsCollections)">Clear Filters</button>
          </div>
        </div>
      </mat-card>
      <mat-card class="mt-1">
        <div class="card-body card-padding">
          <div class="col-md-6 m-3">
            <span class="header">
              <h2 class="title">Customer Returns</h2>
            </span>
          </div>
          <div class="row m-3">

            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="crFromDate" placeholder="From Date" [matDatepicker]="appDatepicker" [readonly]="true" >
                <mat-datepicker-toggle matSuffix [for]="appDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="crToDate" placeholder="To Date" [matDatepicker]="appDatepicker1" [readonly]="true" >
                <mat-datepicker-toggle matSuffix [for]="appDatepicker1"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker1></mat-datepicker>
              </mat-form-field>
            </div>

          </div>
          <div class="button-container text-center mb-3">
            <button mat-raised-button color="primary"
              (click)="download(DownloadType.LogisticsCustomerReturns)">Download</button>
            <button class="ml-5" mat-raised-button color="primary"
              (click)="clearFilters(DownloadType.LogisticsCustomerReturns)">Clear Filters</button>
          </div>
        </div>
      </mat-card>
      <mat-card class="mt-1">
        <div class="card-body card-padding">
          <div class="col-md-6 m-3">
            <span class="header">
              <h2 class="title">Seller Returns</h2>
            </span>
          </div>
          <div class="row m-3">

            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="slReturnedDate" placeholder="Date"
                  [matDatepicker]="appDatepicker2" [readonly]="true" >
                <mat-datepicker-toggle matSuffix [for]="appDatepicker2"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker2></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>Status</mat-label>
                <mat-select formControlName="status">
                  <mat-option *ngFor="let status of warehouseTypes" [value]="status">
                {{ warehouseItemStatus[status] }}
              </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="button-container text-center mb-3">
            <button mat-raised-button color="primary"
              (click)="download(DownloadType.LogisticsSellerReturns)">Download</button>
              <button class="ml-5" mat-raised-button color="primary"
              (click)="clearFilters(DownloadType.LogisticsSellerReturns)">Clear Filters</button>
          </div>
        </div>
      </mat-card>
      <mat-card class="mt-1" style="display:none">
        <div class="card-body card-padding">
          <div class="col-md-6 m-3">
            <span class="header">
              <h2 class="title">Purchase Returns</h2>
            </span>
          </div>
          <div class="row m-3">

            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="prReturnedDate" placeholder="Returned Date"
                  [matDatepicker]="appDatepicker6">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker6"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker6></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="prReceivedDate" placeholder="Recieved Date"
                  [matDatepicker]="appDatepicker7">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker7"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker7></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>Seller</mat-label>
                <mat-select formControlName="prSeller">
                  <!-- <mat-option *ngFor="let status of PurchaseOrderStatus" [value]="status">
                {{ getPrStatusName(status) }}
              </mat-option> -->

                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>Status</mat-label>
                <mat-select formControlName="prStatus">
                  <!-- <mat-option *ngFor="let status of PurchaseOrderStatus" [value]="status">
                {{ getPrStatusName(status) }}
              </mat-option> -->

                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="button-container text-center mb-3">
            <button mat-raised-button color="primary"
              (click)="download(DownloadType.LogisticsPurchaseReturns)">Download</button>
          </div>
        </div>
      </mat-card>
    </section>
  </form>
</div>
