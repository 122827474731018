import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, inject } from '@angular/core';
import { FormBuilder, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ShopSettingsService } from '../../services/shopsettings.service';
import { ShopSettingEntity, VendorConfigEntity } from '../../models/shopsettings.model';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule, formatDate } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import Swal from 'sweetalert2';
import { AppLoaderService } from '../../../../shared/app-loader/app-loader.service';
import { AuthService } from '../../../../services/auth.service';
import { DaysOfWeek, SwalMessageTypes } from '../../../../enums/enums';
import { generateNumbers, isEmptyValue } from '../../../../utlity/utility';
import { MatChipEditedEvent, MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { lastValueFrom, takeUntil } from 'rxjs';
import { ConfigService } from '../../../config/services/config.service';
import { BaseListComponent } from '../../../../shared/core/base.list.component';
import { PageId } from '../../../../constants/enums';

@Component({
  selector: 'app-create-shopsetting',
  standalone: true,
  imports: [MatCardModule, MatButtonModule, MatTableModule, RouterLink, MatInputModule,
    MatFormFieldModule, ReactiveFormsModule, MatSelectModule, MatIconModule,
    CommonModule, FormsModule, MatDatepickerModule, MatRadioModule, MatNativeDateModule,
    MatPaginatorModule, MatSidenavModule, MatDatepickerModule, MatCheckboxModule, MatChipsModule],
  templateUrl: './create-shopsetting.component.html',
  styleUrls: ['./create-shopsetting.component.scss']
})
export class CreateShopSettingComponent extends BaseListComponent {
  permission: any = false;
  dataSource: any;
  shopsettingEntity!: ShopSettingEntity;
  weekDays: string[];
  holidayList: string[] = [];
  hours: string[] = [];
  minutes: string[] = [];
  filename: any;
  previewImage: any;
  requestImageBody: any;
  vendorConfiguration!: VendorConfigEntity;
  yesterday = new Date();
  constructor(
    auth: AuthService,
    router: Router,
    private fb: FormBuilder,
    route: ActivatedRoute,
    private shopsettingsService: ShopSettingsService,
    private loader: AppLoaderService,
    private configService: ConfigService) {
    super(auth, router, route, PageId.seller_shop_settings);
    this.yesterday.setDate(this.yesterday.getDate() - 0);
    this.weekDays = Object.values(DaysOfWeek)
      .filter(value => typeof value !== 'number')
      .map(day => day.toString());
    this.dataSource = this.fb.group({
      id: [null],
      shopOpeningHour: [null, [Validators.required]],
      shopOpeningMinute: [null, [Validators.required]],
      shopClosingHour: [null, [Validators.required]],
      shopClosingMinute: [null, [Validators.required]],
      shopClosed: [false],
      weekends: [null],
      holidays: [null],
      image_url: [''],
    });
  }
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  announcer = inject(LiveAnnouncer);

  override async ngOnInit() {
    super.ngOnInit();
    this.hours = generateNumbers(0, 23);
    this.minutes = generateNumbers(0, 59);
    if (this.vendorId) {
      this.loadShopSettingData();

    }
  }
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.holidayList.push(value);
    }
    event.chipInput!.clear();
  }

  remove(holiday: string): void {
    const index = this.holidayList.indexOf(holiday);
    if (index >= 0) {
      this.holidayList.splice(index, 1);
      this.announcer.announce(`Removed ${holiday}`);
    }
  }

  edit(holiday: string, event: MatChipEditedEvent) {
    const value = event.value.trim();
    if (!value) {
      this.remove(holiday);
      return;
    }
    const index = this.holidayList.indexOf(holiday);
    if (index >= 0) {
      this.holidayList[index] = value;
    }
  }


  addHoliday() {
    const holiday = this.dataSource.get("holidays")?.value;
    if (holiday) {
      const formatedHoliday = formatDate(holiday, 'yyyy-MM-dd', 'en-US');
      if (!this.holidayList) {
        this.holidayList = []; // Initialize holidayList if it's undefined
      }
      if (this.holidayList.includes(formatedHoliday)) {
        this.showMessage('This date is already in the holiday list.', SwalMessageTypes.Warning);
        return;
      }
      this.holidayList.push(formatedHoliday);
      this.dataSource.get("holidays")?.reset();
    }
  }
  async saveShopSetting() {
    this.loader.open();

    try {
      if (this.dataSource.invalid) {
        this.showMessage('Please fill all required fields.', SwalMessageTypes.Warning);

        return
      }
      console.log("this.dataSource.invalid:- ", this.dataSource.invalid, this.dataSource.value);
      const validationResult = await this.validateInput();
      if (!validationResult) {
        return;
      }
      await this.createShopSetting();
    } catch (error) {
      console.error('Error occurred during shopsetting creation:', error);
      this.showMessage('Failed to create shopsetting. Please try again later.', SwalMessageTypes.Error);
    } finally {
      this.loader.close();
    }

  }

  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }


  private mapShopSetting(shopsetting: any): ShopSettingEntity {

    const shopOpening = `${(shopsetting.shopOpeningHour ? shopsetting.shopOpeningHour.padStart(2, '0') : '00')}:${(shopsetting.shopOpeningMinute ? shopsetting.shopOpeningMinute.padStart(2, '0') : '00')}`;
    const shopClosing = `${(shopsetting.shopClosingHour ? shopsetting.shopClosingHour.padStart(2, '0') : '00')}:${(shopsetting.shopClosingMinute ? shopsetting.shopClosingMinute.padStart(2, '0') : '00')}`;
    return {
      shopOpening: shopOpening,
      shopClosing: shopClosing,
      shopClosed: shopsetting?.shopClosed,
      weekends: shopsetting?.weekends,
      holidays: this.holidayList,
      vendorImageUrl: shopsetting?.image_url || this.previewImage || ''
    }
  }

  async createShopSetting() {
    try {
      if (this.requestImageBody) {
        let res: any = await lastValueFrom(this.shopsettingsService.uploadVendorShopImage(this.requestImageBody));
        this.dataSource.patchValue({
          image_url: res.data.url
        });
      }
      const shopsettingEntity = this.mapShopSetting(this.dataSource.value);
      const vendorSettings = this.mapVendorSetting(shopsettingEntity);
      if (isEmptyValue(vendorSettings.vendorConfig.vendorImageUrl)) {
        this.showMessage('Please Upload shop image.', SwalMessageTypes.Error);
        return;
      }

      const response = await this.shopsettingsService.createShopSetting(vendorSettings);// saving data in redis cache
      // await this.shopsettingsService.createVendorSettingInCache(vendorSettings);
      this.showMessage('Shop Setting created successfully', SwalMessageTypes.Success);
    } catch (error) {
      console.error('Error occurred while creating shopsetting:', error);
      this.showMessage('Failed to create shopsetting. Please try again later.', SwalMessageTypes.Error);
    }
  }
  mapVendorSetting(marginData: any) {
    return {
      vendorId: this.vendorId,
      vendorConfig: marginData
    }
  }
  // private Functions
  async validateInput() {
    if (!isEmptyValue(this.dataSource.get("shopOpeningHour")?.value) &&
      this.dataSource.get("shopOpeningMinute")?.value == undefined
    ) {
      this.showMessage('Please select shop opening minute!', SwalMessageTypes.Warning);
      return false;
    }
    if (!isEmptyValue(this.dataSource.get("shopClosingHour")?.value) &&
      this.dataSource.get("shopClosingMinute")?.value == undefined
    ) {
      this.showMessage('Please select shop closing minute!', SwalMessageTypes.Warning);
      return false;
    }

    return true;
  }
  onFileChange(event: any) {
    if (event.target.files) {
      const file = event.target.files[0];
      this.filename = file.name;
      var reader = new FileReader();
      reader.onload = (event) => {
        this.previewImage = event?.target?.result;
        this.dataSource.patchValue({
          image_url: event?.target?.result,
        });
        const parts = this.previewImage.split(',');
        let base64String = parts[1];
        const newFileName = `${this.vendorId}_${Date.now()}`;
        this.requestImageBody = {
          fileName: newFileName,
          fileExtension: this.getFileExtension(this.filename),
          fileData: base64String,
        };
      };
      reader.readAsDataURL(file);
    }
  }

  getFileExtension(filename: any) {
    return filename.split('.').pop();
  }
  removeImage() {
    this.previewImage = null;
    this.filename = null;
    this.requestImageBody = null;
    this.dataSource.patchValue({
      image_url: null
    });
    var fileInput: any = document.getElementById('fileInput');
    fileInput.value = '';
  }
  async loadShopSettingData() {
    this.vendorConfiguration = await this.shopsettingsService.getVendorSettingByVendorId(this.vendorId);
    let vendorConfig = this.vendorConfiguration && this.vendorConfiguration.vendorConfig
    if (vendorConfig) {
      const shopOpening = vendorConfig.shopOpening && vendorConfig.shopOpening.split(":", 2) || [];
      const shopClosing =  vendorConfig.shopClosing && vendorConfig.shopClosing.split(":", 2) || [];
      const shopClosed = vendorConfig.shopClosed;
      const weekends = vendorConfig.weekends;
      const holidays = vendorConfig.holidays;
      const vendorImageUrl = vendorConfig.vendorImageUrl;
      const shopOpeningHour = shopOpening[0] ? parseInt(shopOpening[0]) : "0";
      const shopOpeningMinute = shopOpening[1] ? parseInt(shopOpening[1]) : "0";
      const shopClosingHour = shopClosing[0] ? parseInt(shopClosing[0]) : "0";
      const shopClosingMinute = shopClosing[1] ? parseInt(shopClosing[1]) : "0";

      this.dataSource.get('shopOpeningHour')?.setValue(shopOpeningHour.toString() || "0");
      this.dataSource.get('shopOpeningMinute')?.setValue(shopOpeningMinute.toString() || "0");
      this.dataSource.get('shopClosingHour')?.setValue(shopClosingHour.toString() || "0");
      this.dataSource.get('shopClosingMinute')?.setValue(shopClosingMinute.toString() || "0");
      this.dataSource.get('shopClosed')?.setValue(shopClosed);
      this.dataSource.get('weekends')?.setValue(weekends);
      if (vendorImageUrl) {
        this.previewImage = vendorImageUrl;
      }
      this.holidayList = holidays;
    }

  }
  getHourAndMinute(timeString: string): { hour: number; minute: number } {
    const date = new Date(timeString);
    const hour = date.getHours();
    const minute = date.getMinutes();
    return { hour, minute };
  }
}
