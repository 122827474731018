@if(hasEdit){
<section id="content">
  <div class="heading-block">
    <div class="row">
      <div class="col-md-6">
        <mat-icon class="backicon" [routerLink]="['/dashboard/users']">arrow_back</mat-icon>
        <span class="header">
          <h2 class="card-title m-3"> {{ id ? "Edit User" : "Create User" }}</h2>
        </span>
      </div>
      <div class="col-md-6 text-right"></div>
    </div>
  </div>
  <div class="container">
    <div class="card">
      <div class="card-body card-padding">
        <div class="row">
          <div class="col-md-6">
            <mat-form-field class="full-width">
              <input matInput name="name" required [(ngModel)]="userObj.name" placeholder="Name" />
              <mat-icon class="required-star" aria-hidden="false" aria-label="Required" matSuffix>
                *
              </mat-icon>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="full-width">
              <input matInput name="email_id" [readonly]="id" required email [(ngModel)]="userObj.email_id"
                placeholder="Email Id" />
              <mat-icon class="required-star" aria-hidden="false" aria-label="Required" matSuffix>
                *
              </mat-icon>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="full-width">
              <input matInput name="mobile_number" required number [(ngModel)]="userObj.mobile_number"
                placeholder="Mobile number" />
              <mat-icon class="required-star" aria-hidden="false" aria-label="Required" matSuffix>
                *
              </mat-icon>
            </mat-form-field>
          </div>
          <div class="col-md-6" *ngIf="!id">
            <mat-form-field class="full-width">
              <input matInput name="password" required [(ngModel)]="userObj.password" placeholder="Password" />
              <mat-icon class="required-star" aria-hidden="false" aria-label="Required" matSuffix>
                *
              </mat-icon>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="full-width">
              <mat-label>Roles</mat-label>
              <mat-select [compareWith]="compareFn" [(ngModel)]="userObj.roles"
                (selectionChange)="getSelectedRoles($event)" multiple>
                <mat-option *ngFor="let role of rolesList" [value]="role.id">{{
                  role.role_name
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>



          <div class="col-md-6">
            <mat-form-field class="full-width">
              <mat-label>User Type</mat-label>
              <mat-select [(ngModel)]="selectedUserType">
                <mat-option *ngFor="let usertype of res" required [value]="userTypes[usertype]">
                  {{ usertype }}
                </mat-option>
              </mat-select>
              <mat-icon class="required-star" aria-hidden="false" aria-label="Required" matSuffix>
                *
              </mat-icon>
            </mat-form-field>
          </div>

          <div class="col-md-6">
            <mat-form-field class="full-width">
              <mat-label>Select Application</mat-label>
              <mat-select [(ngModel)]="selectedApplication" (selectionChange)="changeApplication($event.value)">
                <mat-option *ngFor="let application of applications" [value]="application.application_id">{{
                  application.application_name
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="full-width">
              <mat-label>Vendor</mat-label>
              <mat-select [(ngModel)]="userObj.vendor_id">
                <input matInput formControlName="vendorSearch" placeholder="Search Vendor" (keyup)="onKey($event)"
                  class="editableSearch" />
                <mat-option *ngFor="let vendor of vendorSearchList" [value]="vendor.id">
                  {{ vendor.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <label for="status">Active</label>
            <input type="checkbox" [(ngModel)]="userObj.status" id="status" class="mx-2" />
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-12">
            <div class="table-container">
              <table>
                <thead>
                  <th>Pages</th>
                  <th class="perm">Extended Permission</th>
                </thead>
                <tbody *ngIf="applications.length>0 && pages.length>0">
                  <tr *ngFor="let item of pages | searchFilter : selectedApplication">
                    <td>
                      {{ item.page_name }}
                    </td>
                    <td>

                      <div class="d-flex" *ngIf="combined_permissions[item.page_id]">
                        <div class="perm">
                          <input type="checkbox" [disabled]="role_permissions[item.page_id]['create']"
                            [checked]="combined_permissions[item.page_id]['create']" [(ngModel)]="
                            combined_permissions[item.page_id]['create']" class="mx-2" />
                          <label>Create</label>
                        </div>
                        <div class="perm">
                          <input type="checkbox" [disabled]="
                          role_permissions[item.page_id]['view']
                            " [checked]="combined_permissions[item.page_id]['view']" [(ngModel)]="
                            combined_permissions[item.page_id]['view']" class="mx-2" />
                          <label>View</label>
                        </div>
                        <div class="perm">
                          <input type="checkbox" [disabled]="
                          role_permissions[item.page_id]['edit']
                            " [checked]="combined_permissions[item.page_id]['edit']" [(ngModel)]="
                            combined_permissions[item.page_id]['edit']
                            " class="mx-2" />
                          <label>Edit</label>
                        </div>
                        <div class="perm">
                          <input type="checkbox" [disabled]="
                          role_permissions[item.page_id]['delete']
                            " [checked]="combined_permissions[item.page_id]['delete']" [(ngModel)]="
                            combined_permissions[item.page_id][
                                'delete'
                              ]
                            " class="mx-2" />
                          <label>Delete</label>
                        </div>
                        <div class="perm">
                          <input type="checkbox" [disabled]="
                          role_permissions[item.page_id]['import']
                            " [checked]="combined_permissions[item.page_id]['import']" [(ngModel)]="
                            combined_permissions[item.page_id][
                                'import'
                              ]
                            " class="mx-2" />
                          <label>Import</label>
                        </div>
                        <div class="perm">
                          <input type="checkbox" [disabled]="
                          role_permissions[item.page_id]['export']
                            " [checked]="combined_permissions[item.page_id]['export']" [(ngModel)]="
                            combined_permissions[item.page_id]['export']" class="mx-2" />
                          <label>Export</label>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12 text-end">
            @if(hasEdit){
            <button mat-raised-button color="primary" (click)="saveUser()">
              {{ id ? "Update" : "Save" }}
            </button>

            <!--     <button mat-raised-button color="primary" (click)="check()">
              check
              </button> -->
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
} @else {
<ng-container>
  <p class="text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
}
