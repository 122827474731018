import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../../services/auth.service';
import { PurchaseReceiptService } from '../service/purchasereceipt.service';
import { ConfigService } from '../../config/services/config.service';
import { PurchaseOrderStatus, UOM } from '../../../enums/enums';
import Swal from 'sweetalert2';
import { ExcelExportService } from '../../../services/excel.service';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { PageId } from '../../../constants/enums';

@Component({
  selector: 'complete-grn',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatButtonModule,
    RouterOutlet,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatCardModule,
    MatIconModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    CommonModule,
    MatTableModule,
    RouterLink,
    ReactiveFormsModule,
  ],

  templateUrl: './complete-grn.component.html',
  styleUrl: './complete-grn.component.scss',
})
export class CompleteGrnComponent extends BaseListComponent {

  displayedColumns: string[] = ["No", "item", "requestedUom", "requestedUomQty", "actualUomQty", "requestedQtyPcs", "actualQtyPcs"];
  page_id = "bms_area_edit";
  permission: any = false;
  dataSource: any[] = [];
  prId: string = '';
  isAllSelected: boolean = false;
  public suppliersList: any[] = [];
  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CompleteGrnComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public authService: AuthService,
    private configService: ConfigService,
    public purchaseReceiptService: PurchaseReceiptService,
  ) {
    super(auth, router, route, PageId.seller_pr);

  }

  override async ngOnInit() {
    await super.ngOnInit();
    this.prId = this.data
    await this.getPurchaseReceiptSkus(this.prId);
  }
  async getPurchaseReceiptSkus(prId: string) {
    const res = await this.purchaseReceiptService.getPurchaseReceiptItems(prId);
    const filteredItems = res.filter((item: { actual_in_pcs: any; requested_in_pcs: any; }) => item.actual_in_pcs !== item.requested_in_pcs);
    const itemsWithFalseSelection = filteredItems.map((item: any) => ({ ...item, isSelected: false }));
    this.dataSource = itemsWithFalseSelection;
  }

  async completeGrn() {
    this.isAllSelected = false
    const checkedItems = this.dataSource.filter(element => element.isSelected);

    if (checkedItems.length === this.dataSource.length || this.dataSource.length === 0) {
      const body = this.mapToBody()
      console.log("update body is ", body);

      const res = await this.purchaseReceiptService.updatePrAttributes(body, this.prId)
      if (res.success) {
        Swal.fire("success", "Successfully GRN Completed", "success")
      }
      this.dialogRef.close(true)

    } else {
      this.isAllSelected = true
      console.log('Not all items are selected');
    }

  }

  close(){
    this.dialogRef.close(false)
  }

  mapToBody() {
    return [{
      status: PurchaseOrderStatus.GRNCompleted,
    },
    {
      grn_by: this.vendorId
    }
    ]
  }
  getUomType(type: number): string {
    return UOM[type];
  }

}
