import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { environment } from '../../environments/environment'
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class FileUploadService {
    constructor(public http: HttpClient) { }

    async uploadExcelFile(fileName: string, fileExtension: string, file: any) {
        try {
            const fullUrl = `${environment.fileUploadBaseUrl}/documents/bulk_import`
            const payLoad: any = {
                "fileName": fileName,
                "fileExtension": fileExtension,
                "fileData": file
            }

            return await this.http.put(fullUrl, payLoad, undefined).toPromise();
        } catch (error) {
            throw error;
        }

    }
}
