<ng-container>
  <div class="p-3">
    <div class="row">
      <div class="col-md-6">
        <h2 class="card-title m-3 text-violet">Error Log Details</h2>
      </div>
      <div class="col-md-6 text-right">
        <mat-icon (click)="dialogRef.close()" class="float-right m-3 closeicon closeicon">
          close
        </mat-icon>
      </div>
    </div>
  </div>
  <div class="table-block">
    <div class=" flex flex-col md:flex-row justify-between">
      <mat-card class="p-0">
        <mat-card-content class="p-0 m-5">
          <mat-sidenav-container fxFlex="0 1 auto">

            <div class="row">
              <h2 class="headtext">Message Body</h2>
              <div>
                {{errorLog.messageBody }}
              </div>
            </div>

            <div class="row">
              <h2 class="headtext">Error</h2>
              <div>
                {{ errorLog.messageResult.errors[0] }}
              </div>
            </div>
            <!-- <mat-sidenav-content>
              <div class="table-container">
                <mat-table [dataSource]="errorLog" matSort>
                  <ng-container matColumnDef="messageBody">
                    <mat-header-cell *matHeaderCellDef class="header-center">Message Body</mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="messageBody">
                      {{ row.messageBody }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="error">
                    <mat-header-cell *matHeaderCellDef>Error</mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="error">
                      {{ row.messageResult.errors[0] }}
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                </mat-table>
              </div>
            </mat-sidenav-content> -->
          </mat-sidenav-container>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</ng-container>