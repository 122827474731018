import { Component, ElementRef, HostListener } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { CommonModule, formatDate } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import {
  ContractMode,
  SupplierStatus,
  SupplierType,
  SwalMessageTypes,
  states,
} from '../../../../enums/enums';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  SupplierEntity,
  State,
  SupplierForm,
  SupplierListSearch,
  createSupplierResponse,
  PropertyValidation,
  TextInput,
  validateName,
  MultiPropertyValidation,
  ValidationResult,
} from '../../models/logisticsupplier-model';
import uuid4 from 'uuid4';
import Swal from 'sweetalert2';
import { AppLoaderService } from '../../../../shared/app-loader/app-loader.service';
import { AuthService } from '../../../../services/auth.service';
import {
  applyMaxLengthValidation,
  hasSpecialCharacters,
  nameValidate,
  supplierListSearchInput,
  validateGST,
  validateMaxLength,
  validateMaxText,
  validateNegativeValues,
  validateNoNegativeValues,
  validateNumbersOnly,
  validatePinCode,
  validateSpecialCharacters,
  validateText,
} from '../../../../utlity/utility';
import imageCompression from 'browser-image-compression';
import { ConfigService } from '../../../config/services/config.service';
import { MESSAGE_QUEUE_TYPE } from '../../../../constants/message-queue-types';
import { Auth, User, authState } from '@angular/fire/auth';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { SuppliersService } from '../../services/logisticsupplier.service';
import { BaseListComponent } from '../../../../shared/core/base.list.component';
import { PageId } from '../../../../constants/enums';

@Component({
  selector: 'app-create-supplier',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    RouterLink,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatCheckboxModule,
  ],
  templateUrl: './create-supplier.component.html',
  styleUrl: './create-supplier.component.scss',
})
export class CreateSupplierComponent extends BaseListComponent {
  dataSource: any;
  existingSupplier: any;
  supplierEntity!: SupplierEntity;
  states: State[] = states;
  filename: any;
  previewImage: any;
  requestImageBody: any;
  uploadedFiles: any = {};
  aadharCopy: string = '';
  panCopy: string = '';
  gstCopy: string = '';

  userSubject = new BehaviorSubject<User | null>(null);

  supplierStatus = Object.values(SupplierStatus).filter(
    (value) => typeof value === 'number'
  );
  // supplierTypes = Object.values(SupplierType).filter(value => typeof value === 'number');
  supplierTypes: SupplierType[] = Object.values(SupplierType).filter(
    (value) => typeof value === 'number'
  ) as SupplierType[];
  supplierContractMode = Object.values(ContractMode).filter(
    (value) => typeof value === 'number'
  );
  supplierTypeNames: { [key: number]: string } = {
    [SupplierType.Manpower]: 'Manpower',
    [SupplierType.Transporter]: 'Transporter',
    [SupplierType.Procurement]: 'Procurement',
  };

  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    private supplierService: SuppliersService,
    private loader: AppLoaderService,
    private el: ElementRef,
    private auth1: Auth,
    private configService: ConfigService
  ) {
    super(auth, router, route, PageId.seller_suppliers);
    this.dataSource = this.fb.group({
      name: [null, Validators.required],
      mobileNumber: [null, Validators.required],
      residence: [null, Validators.required],
      area: [null],
      landmark: [null],
      state: [null, Validators.required],
      city: [null, Validators.required],
      pinCode: [null, Validators.required],
      latitude: [null],
      longitude: [null],
      aadharNumber: '',
      aadharCopy: '',
      panNumber: '',
      panCopy: '',
      gstNumber: '',
      gstCopy: '',
      supplierType: [null, Validators.required],
      contractMode: [null, Validators.required],
      status: [null, Validators.required],
      tcsPercentage: [null],
      creditDays: null,
    });
  }
  override async ngOnInit() {
    await super.ngOnInit();
    authState(this.auth1)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (user) => {
        if (user) {
          this.userSubject.next(user);
        }
      });
    this.fixMaxLength();
  }

  async saveSupplier() {
    this.loader.open();
    try {
      if (this.dataSource.invalid) {
        this.showMessage(
          'Please fill all required fields.',
          SwalMessageTypes.Warning
        );

        return;
      }
      await this.validateInput();
      await this.uploadFiles();
      await this.createSupplier();
    } catch (error) {
    } finally {
      this.loader.close();
    }
  }
  async uploadFiles() {
    const documentTypes = ['aadhar', 'pan', 'gst'];
    const urlEndpoints = ['supplier_aadhaar', 'supplier_pan', 'supplier_gst'];

    await Promise.all(
      documentTypes.map(async (documentType, index) => {
        const urlEndpoint = urlEndpoints[index];
        if (this.uploadedFiles.hasOwnProperty(documentType)) {
          const requestImageBody = this.uploadedFiles[documentType];
          await this.uploadAndSetUrl(
            requestImageBody,
            documentType,
            urlEndpoint
          );
        }
      })
    );
  }
  async onFileChange(event: any, documentType: string) {
    if (event.target.files) {
      const file = event.target.files[0];
      const filename = file.name;
      const reader = new FileReader();
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      reader.onload = async (event) => {
        const result = event?.target?.result;

        if (typeof result === 'string') {
          const base64String: string = await this.compressAndConvertToBase64(
            file,
            options
          );
          this.uploadedFiles[documentType] = {
            fileName: filename,
            fileExtension: this.getFileExtension(filename),
            fileData: base64String,
          };
        } else if (result instanceof ArrayBuffer) {
          console.error('Unsupported file type');
          // Handle this case if needed
        }
      };

      reader.readAsDataURL(file);
    }
  }

  async compressAndConvertToBase64(file: File, options: any): Promise<string> {
    try {
      const compressedFile = await imageCompression(file, options);
      const base64String: string = await imageCompression.getDataUrlFromFile(
        compressedFile
      );
      return base64String.split(',')[1];
    } catch (error) {
      console.error('Error compressing image:', error);
      throw error;
    }
  }

  // PRIVATE FUNCTIONS

  private validateText(data: SupplierForm): validateName {
    const textInputs = [
      { value: data.name, field: 'Name' },
      { value: data.city, field: 'City' },
    ];

    return nameValidate(textInputs.map((input) => input)) || { isValid: true };
  }

  private async validateInput() {
    const result = this.validateMaxLength(this.dataSource.value);

    if (!result.isValid) {
      const errorMessage = this.createMaxLengthMsg(result.failedFields || []);

      console.log('errorMessage :-------------', errorMessage);

      this.showMessage(errorMessage, SwalMessageTypes.Warning);
      throw new Error('validateMaxLength.');
    }

    const validateNegativeValues = this.validateNegativeValues(
      this.dataSource.value
    );

    if (!validateNegativeValues.isValid) {
      const message = this.generateErrorMessage(
        validateNegativeValues.failedFields
      );
      this.showMessage(message, SwalMessageTypes.Warning);
      throw new Error('validateNegativeValues.');
    }

    const { pinCode } = this.dataSource.value;

    if (pinCode && !validatePinCode(pinCode)) {
      this.showMessage(
        'PinCode is not correct. Please provide a proper PinCode  number.',
        SwalMessageTypes.Warning
      );
      throw new Error('validateGST.');
    }

    const validationResult = this.validateText(this.dataSource.value);

    if (validationResult.failedField) {
      const errorMessage = this.buildErrorMessage(validationResult.failedField);
      this.showMessage(errorMessage, SwalMessageTypes.Warning);
      throw new Error('validateText.');
    }

    // if (this.validateText(this.dataSource.value)) {
    //   this.showMessage('The name or city is not valid. Please correct the name by removing special characters.', SwalMessageTypes.Warning);
    //   throw new Error('validateText');
    // }

    const { gstNumber } = this.dataSource.value;

    if (gstNumber && !validateGST(gstNumber)) {
      this.showMessage(
        'GST Number is not correct. Please provide a proper GST number.',
        SwalMessageTypes.Warning
      );
      throw new Error('validateGST.');
    }

    if (!validatePinCode(this.dataSource.value.pinCode)) {
      this.showMessage(
        'PinCode is not correct. Please provide a proper PinCode  number.',
        SwalMessageTypes.Warning
      );
      throw new Error('validateGST.');
    }

    if (this.checkSpecialCharacters(this.dataSource.value)) {
      this.showMessage(
        'Aadhar,Pan and GST Should not have special characters. Please remove special characters.',
        SwalMessageTypes.Warning
      );
      throw new Error('validateText');
    }

    // const response = await this.supplierService.getSuppliers(supplierListSearchInput());
    // this.existingSupplier = response['supplierList'];

    // if (this.checkIsMobileNumberExists(this.dataSource.value.mobileNumber)) {
    //   this.showMessage('Mobile Number Already Exists.', SwalMessageTypes.Warning);
    //   this.loader.close();
    //   throw new Error('Mobile number already exists.');
    // }
  }

  private checkMissingFields(supplierEntity: any) {
    const missingFields: any = [];

    const checkField = (
      fieldCopy: any,
      fieldNumber: any,
      fieldName: string
    ) => {
      if (
        fieldNumber !== null &&
        ((fieldCopy !== '' && fieldNumber === '') ||
          (fieldCopy === '' && fieldNumber !== ''))
      ) {
        missingFields.push(`${fieldName} Number And ${fieldName} Image`);
      }
    };

    checkField(
      supplierEntity.aadharCopy,
      supplierEntity.aadharNumber,
      'Aadhar'
    );
    checkField(supplierEntity.panCopy, supplierEntity.panNumber, 'Pan');
   // checkField(supplierEntity.gstCopy, supplierEntity.gstNumber, 'GST');

    return missingFields;
  }

  checkSpecialCharacters(data: any): boolean {
    const inputValues = [];
    if (data.aadharNumber !== '') {
      inputValues.push(data.aadharNumber);
    }
    if (data.panNumber !== '') {
      inputValues.push(data.panNumber);
    }
    if (data.gstNumber !== '') {
      inputValues.push(data.gstNumber);
    }
    return inputValues.length > 0 ? hasSpecialCharacters(inputValues) : false;
  }

  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  private async uploadAndSetUrl(
    requestImageBody: any,
    documentType: string,
    urlEndpoint: string
  ) {
    try {
      const res: any = await this.supplierService.imageUpload(
        requestImageBody,
        urlEndpoint
      );
      switch (documentType) {
        case 'aadhar':
          this.aadharCopy = res.data.url;
          break;
        case 'pan':
          this.panCopy = res.data.url;
          break;
        case 'gst':
          this.gstCopy = res.data.url;
          break;
        default:
          console.error('Invalid document type');
          break;
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  }

  private getFileExtension(filename: any) {
    return filename.split('.').pop();
  }

  private mapSupplier(supplier: SupplierForm): SupplierEntity {
    return {
      id: uuid4(),
      mobileNumber: supplier?.mobileNumber,
      name: supplier?.name,
      vendorId: this.vendorId,
      address: {
        residence: supplier?.residence || '',
        area: supplier?.area || '',
        landmark: supplier?.landmark || '',
        city: supplier?.city,
        state: supplier?.state,
        pinCode: supplier?.pinCode,
        country: 'INDIA',
        latitude: supplier?.latitude?.toString() || '',
        longitude: supplier?.longitude?.toString() || '',
      },
      aadharNumber: supplier?.aadharNumber.toString(),
      aadharCopy: this.aadharCopy || '',
      panNumber: supplier?.panNumber,
      panCopy: this.panCopy || '',
      gstNumber: supplier?.gstNumber,
      gstCopy: this.gstCopy || '',
      supplierType: supplier?.supplierType,
      tcsPercentage: supplier?.tcsPercentage || 0,
      contractMode: supplier?.contractMode,
      status: supplier?.status,
      creditDays: supplier?.creditDays || 0,
    };
  }

  private fixMaxLength() {
    applyMaxLengthValidation(this.dataSource, [
      { property: 'mobileNumber', maxLength: 10 },
      { property: 'pinCode', maxLength: 6 },
      { property: 'aadharNumber', maxLength: 12 },
      { property: 'panNumber', maxLength: 10 },
      { property: 'gstNumber', maxLength: 15 },
    ]);
  }

  toUppercase(event: any) {
    event.target.value = event.target.value.toUpperCase();
  }

  async createSupplier() {
    const supplierEntity = this.mapSupplier(this.dataSource.value);
    console.log('supplierEntityyyy :', supplierEntity);
    const missingFields = this.checkMissingFields(supplierEntity);
    if (missingFields.length > 0) {
      this.showMessage(
        `Please fill both ${missingFields.join(' and ')} not only one.`,
        SwalMessageTypes.Warning
      );
      throw new Error(`Missing required fields: ${missingFields.join(', ')}`);
    }
    try {
      const response = await this.supplierService.createSupplier(
        supplierEntity
      );
      if (!response.success) {
        this.showMessage(response.message, SwalMessageTypes.Error);
        return;
      }
      this.showMessage(
        'Supplier created successfully',
        SwalMessageTypes.Success
      );
      this.router.navigateByUrl('/dashboard/supplier/supplier-list');
    } catch (error: any) {
      console.error('Error occurred while creating supplier:', error);
      if (error.status == 409) {
        this.showMessage(error.error.message, SwalMessageTypes.Error);
        return;
      }
      this.showMessage(
        'Failed to create supplier. Please try again later.',
        SwalMessageTypes.Error
      );
    }
  }

  private maptosupplier(supplierId: any, vendorId: any) {
    return {
      supplierId: supplierId,
      vendorId: vendorId,
    };
  }

  private createMaxLengthMsg(
    failedFields: { field: string; failedField: string }[]
  ): string {
    const errorMessages: { [key: string]: string } = {
      mobileNumber: 'Mobile (10 digits)',
      pinCode: 'Pin Code (6 digits)',
      aadharNumber: 'Aadhar (12 digits)',
      panNumber: 'Pan (10 characters)',
      gstNumber: 'Gst (15 characters)',
    };

    const failedFieldNames = failedFields.map((failedField) => {
      return errorMessages[failedField.field];
    });

    return (
      'The input lengths for ' +
      failedFieldNames.join(', ') +
      ' do not match. Please ensure you enter the correct lengths for each field before proceeding.'
    );
  }

  private validateMaxLength(data: any) {
    const validations: MultiPropertyValidation[] = [
      { field: 'mobileNumber', property: data.mobileNumber, maxLength: 10 },
      { field: 'pinCode', property: data.pinCode, maxLength: 6 },
    ];
    if (this.dataSource.value.aadharNumber == null) {
      data.aadharNumber = '';
    }

    if (data.aadharNumber !== '') {
      validations.push({
        field: 'aadharNumber',
        property: data.aadharNumber,
        maxLength: 12,
      });
    }
    if (data.panNumber !== '') {
      validations.push({
        field: 'panNumber',
        property: data.panNumber,
        maxLength: 10,
      });
    }
    if (data.gstNumber !== '') {
      validations.push({
        field: 'gstNumber',
        property: data.gstNumber,
        maxLength: 15,
      });
    }

    return validateMaxLength(validations);
  }

  private validateNegativeValues(data: any): ValidationResult {
    const numericInputs = [
      { field: 'mobileNumber', value: data.mobileNumber },
      { field: 'pinCode', value: data.pinCode },
      // { field: 'tcsPercentage', value: data.tcsPercentage },
      // { field: 'latitude', value: data.latitude },
      // { field: 'longitude', value: data.longitude },
      // { field: 'creditDays', value: data.creditDays },
    ];

    const validation = validateNoNegativeValues(
      numericInputs.map((input) => input)
    );
    return {
      isValid: validation.isValid,
      failedFields: validation.failedFields,
    };
  }

  private buildErrorMessage(failedField: string): string {
    return `The ${failedField} is Invalid.`;
  }

  private generateErrorMessage(
    failedFields: { field: string; failedField: string }[] | undefined
  ): string {
    let message = 'The following fields contain invalid numeric values:';
    if (failedFields) {
      failedFields.forEach((failedField) => {
        message += ` ${failedField.field} (${failedField.failedField}),`;
      });
      message = message.slice(0, -1) + '.';
    }
    return message;
  }
}
