@if(hasView){
<ng-container>
  <div class="heading-block">
    <div class="row">
      <div class="col-md-6">
        <h2 class="card-title m-3">Returns Report</h2>
      </div>
      <div class="col-md-6">
        @if(hasExport){
        <button
          mat-raised-button
          color="primary"
          class="topButtonSectionbtn btn-width"
          (click)="downloadReports()"
        >
          Download
        </button>
        }
      </div>
    </div>
  </div>
  <div class="search-block">
    <form [formGroup]="form">
      <section id="content">
        <div class="card">
          <div class="card-body card-padding">
            <div class="row">
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    formControlName="startDate"
                    placeholder="Start Date"
                    [matDatepicker]="appDatepicker"
                    [readonly]="true"
                  />

                  <mat-datepicker-toggle
                    matSuffix
                    [for]="appDatepicker"
                  ></mat-datepicker-toggle>
                </mat-form-field>

                <mat-datepicker #appDatepicker></mat-datepicker>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    formControlName="endDate"
                    placeholder="End Date"
                    [matDatepicker]="appDatepickerEnd"
                    [readonly]="true"
                  />

                  <mat-datepicker-toggle
                    matSuffix
                    [for]="appDatepickerEnd"
                  ></mat-datepicker-toggle>
                </mat-form-field>

                <mat-datepicker #appDatepickerEnd></mat-datepicker>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Trip Name</mat-label>
                  <input
                    matInput
                    type="string"
                    formControlName="tripName"
                    name="tripName"
                    placeholder="Trip Name"
                  />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Vendor Name</mat-label>
                  <input
                    matInput
                    type="string"
                    formControlName="vendorName"
                    name="vendorName"
                    placeholder="Vendor Name"
                  />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Trip Number</mat-label>
                  <input
                    matInput
                    type="string"
                    formControlName="tripNumber"
                    name="tripNumber"
                    placeholder="Trip Number"
                  />
                </mat-form-field>
              </div>
              <div class="row">
                <div class="col-md-4 ml"></div>
                <div class="col-md-4 searchbtns">
                  <div class="text-left mt-2">
                    <button
                      mat-raised-button
                      color="primary"
                      class="text-center ml"
                      (click)="searchTasks()"
                    >
                      Search
                    </button>
                  </div>
                  <div class="text-right mt-2 clearfiltrtbtn">
                    <button
                      mat-raised-button
                      color="primary"
                      class="text-center ml-w"
                      (click)="clearFilters()"
                    >
                      Clear Filters
                    </button>
                  </div>
                </div>
                <div class="col-md-4 mr"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  </div>

  <div class="table-block">
    <div class="m-3 flex flex-col md:flex-row justify-between">
      <mat-card class="p-0">
        <mat-card-content class="p-0">
          <mat-sidenav-container fxFlex="0 1 auto">
            <mat-sidenav-content>
              <div class="table-container">
                <mat-table [dataSource]="dataSource" matSort>
                  <ng-container matColumnDef="tripName">
                    <mat-header-cell *matHeaderCellDef>
                      Trip Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="tripName">
                      {{ row.tripName }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="vendorName">
                    <mat-header-cell *matHeaderCellDef>
                      Vendor Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="vendorName">
                      {{ row.vendorName }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="tripNumber">
                    <mat-header-cell *matHeaderCellDef>
                      Trip Number
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="tripNumber">
                      {{ row.tripNumber }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="date">
                    <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="date">
                      {{ row.date }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="productName">
                    <mat-header-cell *matHeaderCellDef>
                      Product Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="productName">
                      {{ row.productName }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="case">
                    <mat-header-cell *matHeaderCellDef> Case </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="case">
                      {{ row.case }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="pcs">
                    <mat-header-cell *matHeaderCellDef> PCs </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="pcs">
                      {{ row.pcs }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="totalQty">
                    <mat-header-cell *matHeaderCellDef>
                      Total QTY
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="totalQty">
                      {{ row.totalQty }}
                    </mat-cell>
                  </ng-container>
                  <mat-header-row
                    *matHeaderRowDef="displayedColumns"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></mat-row>
                </mat-table>
              </div>
            </mat-sidenav-content>
          </mat-sidenav-container>
          <mat-paginator
            [length]="totalReturnsReports"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
            (page)="onPageChange($event)"
          ></mat-paginator>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</ng-container>
} @else {
<ng-container>
  <p class="text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
}
