<div class="container-fluid p-2">
  <div class="icon">
    <mat-icon (click)="dialogRef.close()" class="float-right m-3 closeicon">close</mat-icon>
  </div>
  <mat-card class="p-3">
    <div class="row" *ngIf="isItemPayoutZero">

      <label>
        Invoice amount for items
        <span style="color: red;">{{payOutZeroItems}}</span>
        is should not be zero
      </label>
    </div>
    <div class="row" *ngIf="!isItemPayoutZero">
      <div class="col-md-12 table-wrapper">
        <table mat-table [dataSource]="dataSource">

          <ng-container matColumnDef="No">
            <th mat-header-cell *matHeaderCellDef> No</th>
            <td mat-cell *matCellDef="let element">
              <mat-checkbox [(ngModel)]="element.isSelected"></mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="supplier">
            <th mat-header-cell *matHeaderCellDef>Supplier</th>
            <td mat-cell *matCellDef="let element">{{ getSupplierName(element.supplier) }} </td>
          </ng-container>
          <ng-container matColumnDef="expense_type">
            <th mat-header-cell *matHeaderCellDef>Expense Type</th>
            <td mat-cell *matCellDef="let element">
              {{
              element.expense_type === 'netPayable'
              ? 'Net Payable'
              : element.expense_type
              ? getExpenseType(element.expense_type)
              : 'Procurement'
              }}
            </td>
          </ng-container>
          <ng-container matColumnDef="rate">
            <th mat-header-cell *matHeaderCellDef>Rate</th>
            <td mat-cell *matCellDef="let element"> {{element.rate }}
            </td>
          </ng-container>
          <ng-container matColumnDef="payout">
            <th mat-header-cell *matHeaderCellDef>Payout</th>
            <td mat-cell *matCellDef="let element"> {{element.payout  | replaceCommas  }}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
      <div style="display: flex;  justify-content: center;">
        <label *ngIf="isAllSelected" class="error-message">Please check the all records</label>
      </div>
      <div class="text-center mt-2 flex-item">
        <ng-container *ngIf="loading; else notLoading">
          <mat-spinner [diameter]="30"></mat-spinner>
        </ng-container>
        <ng-template #notLoading>

          <button mat-raised-button color="primary" class="text-center ml-w" (click)="approve()">
            Proceed
          </button>
        </ng-template>
      </div>
    </div>
  </mat-card>
</div>