import { SupplierEntity, SupplierImageResponse, SupplierListSearch, createSupplierResponse } from './../models/supplier-model';
import { Injectable } from '@angular/core';
import { Observable, Subject, lastValueFrom, of } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class SupplierService {
  logisticsUrl: string = `${environment.logisticsUrl}`;
  fileServicesApiUrl: string = `${environment.fileUploadBaseUrl}`;
  constructor(
    public http: HttpClient

  ) { }

  async createSupplier(data: SupplierEntity) {

    let response = await lastValueFrom(this.http.post<any>(`${this.logisticsUrl}/api/logistics_supplier/create`, data));

    return response.res
  }

  async getSuppliers(body: SupplierListSearch): Promise<any> {
    const res = await lastValueFrom(this.http.post<any>(`${this.logisticsUrl}/api/logistics_supplier/get_by_filters`, body));

    if (res && res.data) {
      return {
        supplierList: res.data.rows,
        totalSuppliers: res.data.count
      }
    }
    return {
      supplierList: [],
      totalSuppliers: 0
    };

  }

  async getSupplierById(id: string): Promise<any> {
    const res = await lastValueFrom(this.http.get<any>(`${this.logisticsUrl}/api/logistics_supplier/get_by_id/${id}`));
    return res;
  }


  async imageUpload(body: { fileName: string; fileExtension: string; fileData: any; }, urlEndpoint: string) {

    let response = await lastValueFrom(this.http.put<{ res: SupplierImageResponse }>(`${this.fileServicesApiUrl}/documents/${urlEndpoint}`, body));

    return response
  }


  async updateSupplier(supplierEntity: SupplierEntity, supplierId: string) {
    let { id, ...supplierUpdatedEntity } = supplierEntity;

    let response = await lastValueFrom(this.http.post<{ res: createSupplierResponse }>(`${this.logisticsUrl}/api/logistics_supplier/update/${supplierId}`, supplierUpdatedEntity));

    return response.res
  }

  async deleteSupplier(supplierId: string) {
    return await lastValueFrom(this.http.delete(`${this.logisticsUrl}/api/logistics_supplier/delete/${supplierId}`));
  }

}
