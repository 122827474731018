import { Component, Input } from '@angular/core';
import { TypePipe } from '../../../pipes/type.pipe';
import { StatusComponent } from '../../../status/status.component';

@Component({
  selector: 'app-add-attribute',
  standalone: true,
  imports: [StatusComponent,TypePipe],
  templateUrl: './add-attribute.component.html',
  styleUrl: './add-attribute.component.scss'
})
export class AddAttributeComponent {
  @Input() data:any;
}
