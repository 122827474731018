@if(hasView){
  <div class="container-fluid p-2">
    <mat-card class="p-3">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            <h3 class="title">SKU List</h3>
          </div>
          <div class="col-md-6 text-end">
            <button mat-raised-button (click)="openFilter()" color="primary" class="me-2 filter-btn">
              <div class="icon-dot"
                [ngClass]="{'active-filter': !(filterForm?.value?.name || filterForm?.value?.categories_id || filterForm?.value?.companies_id || filterForm?.value?.brands_id)}">
              </div>
              <mat-icon class="me-0">filter_list</mat-icon>
            </button>
            @if(hasCreate){
            <button mat-raised-button [routerLink]="['/dashboard/product/published']"
              routerLinkActive="router-link-active" color="primary">
              <mat-icon>add</mat-icon> Add
            </button>
            }
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 table-wrapper">
          <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)"
            class="mat-elevation-z8">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
              <td mat-cell *matCellDef="let element">{{ element.attributes.name }}</td>
            </ng-container>
            <ng-container matColumnDef="brands_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Brand Name</th>
              <td mat-cell *matCellDef="let element">{{ element.attributes?.product.brands_name }}</td>
            </ng-container>
            <ng-container matColumnDef="companies_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="highlight-column"  >Companies Name</th>
              <td mat-cell *matCellDef="let element" class="highlight-column">{{ element.attributes?.product.companies_name }}</td>
            </ng-container>
            <ng-container matColumnDef="categories_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="highlight-column"  >Categories Name</th>
              <td mat-cell *matCellDef="let element" class="highlight-column">{{ element.attributes?.product.categories_name }}</td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Status</th>
              <td mat-cell *matCellDef="let element">{{ element.attributes?.status | titlecase }}</td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>Action</th>
              <td mat-cell *matCellDef="let element">
                <div class="d-flex " *ngIf="hasEdit">
                  <button mat-raised-button class="ms-2" color="primary"
                    [routerLink]="['/dashboard/product/sku/edit/update-sku-to-catalog',element.id]">
                    <mat-icon class="me-0">edit</mat-icon>
                  </button>
                  @if(element.attributes.status=='active'){
                  <button mat-raised-button color="primary" class="mx-2 nowrap"
                    (click)="changeStatus(element.id,'inactive','InActive')">Mark Inactive</button>
                  }
                  @if(element.attributes.status=='inactive'){
                  <button mat-raised-button color="primary" class="mx-2 nowrap"
                    (click)="changeStatus(element.id,'active','Active')">Mark Active</button>
                  }
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <div class="d-flex justify-content-center my-2">
            @if (loading) {
            <mat-spinner [diameter]="30"></mat-spinner>
            }
          </div>
          <div class="d-flex justify-content-center my-2">
            @if (!isDataAvailable() && !loading) {
            <p class="no-data-found-text">No record found</p>
            }
          </div>
          <mat-paginator #paginator [length]="meta?.totalCount" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="onPaginationChange($event)" aria-label="Select page">
          </mat-paginator>
        </div>
      </div>
    </mat-card>
  </div>
  }
  @else {
  <p class="text-center my-5">You don't have permission to access this</p>
  }
