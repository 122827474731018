import { Injectable } from '@angular/core';
import * as turf from '@turf/turf';

@Injectable({
  providedIn: 'root',
})
export class PolygonService {
  constructor() { }

  arePolygonsOverlapping(polygon1: number[][], polygon2: number[][]): boolean {
    const turfPolygon1 = turf.polygon([polygon1]);
    const turfPolygon2 = turf.polygon([polygon2]);
    const isSamePolygon = turf.booleanEqual(turfPolygon1, turfPolygon2);
    if (isSamePolygon) {
      return isSamePolygon;
    }
    const overlapping = turf.booleanOverlap(turfPolygon1, turfPolygon2);

    return overlapping;
  }

  arePolygonsSame(polygon1: number[][], polygon2: number[][]): boolean {
    // Create Turf.js geometry objects from input polygons
    const turfPolygon1 = turf.polygon([polygon1]);
    const turfPolygon2 = turf.polygon([polygon2]);

    // Use Turf.js booleanEqual function to compare polygons
    return turf.booleanEqual(turfPolygon1, turfPolygon2);
  }
}

