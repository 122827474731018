<ng-container>
  <div class="heading-block">
    <div class="row">
      <div class="col-md-6">
        <span class="header">
          <h2 class="card-title m-3">Update Address </h2>
        </span>
      </div>
      <div class="col-md-6 text-right">
      </div>
    </div>
  </div>

  <div class="table-block">
    <form [formGroup]="dataSource">
      <section id="content">
        <div class="card">
          <div class="card-body card-padding">
            <div class="row">

              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Latitude (Eg: 73.456)</mat-label>
                  <input matInput type="number" formControlName="latitude" name="latitude" placeholder="Latitude" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Longitude (Eg: 74.346)</mat-label>
                  <input matInput type="number" formControlName="longitude" name="longitude" placeholder="Longitude" />
                </mat-form-field>
              </div>

              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Street</mat-label>
                  <input matInput type="string" formControlName="street" name="street" placeholder="Street" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>LandMark</mat-label>
                  <input matInput type="string" formControlName="landmark" name="landmark" placeholder="Landmark" />
                </mat-form-field>
              </div>


              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Area</mat-label>
                  <input matInput type="string" formControlName="area_code" name="Area" placeholder="Area" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>City</mat-label>
                  <input matInput type="string" formControlName="city" name="city" placeholder="City" />
                </mat-form-field>
              </div>



              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Select State</mat-label>
                  <mat-select formControlName="state" [(value)]="dataSource.State">
                    @for (state of states; track state) {
                    <mat-option [value]="state.name">{{
                      state.name
                      }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>PinCode</mat-label>
                  <input matInput type="string" formControlName="pincode" name="pincode" placeholder="PinCode"
                    [maxlength]="6" />
                </mat-form-field>
              </div>


            </div>

            <div class="row mt-5 text-center">
              <div class="buttonSection">
                <button mat-raised-button color="primary" class="text-center ml"
                  (click)="updateAddressById()">Update</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  </div>
</ng-container>