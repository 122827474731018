import {
  Component,
  ViewChild,
} from '@angular/core';
import {
  IAllowanceModel,
  SalesPersonPerformanceEntity,
  SalesPersonPerformanceListSearch,
} from '../../models/salesperonperformance.model';
import { SalesPeronPerformanceService } from '../../services/salesperonperformance.service';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSidenavModule } from '@angular/material/sidenav';
import Swal from 'sweetalert2';
import { AppLoaderService } from '../../../../shared/app-loader/app-loader.service';
import { AuthService } from '../../../../services/auth.service';
import { SwalMessageTypes, SupplierStatus } from '../../../../enums/enums';
import { MatDialog } from '@angular/material/dialog';
import { EditSalesPeronPerformance } from '../edit-salespersonperformance/edit-salespersonperformance.component';
import { getDateYYYYMMDD } from '../../../../utlity/utility';
import { SalesPersonPerformance } from '../../../targets/models/targets.model';
import * as XLSX from 'xlsx';
import { BaseListComponent } from '../../../../shared/core/base.list.component';
import { PageId } from '../../../../constants/enums';


@Component({
  selector: 'app-salesperonperformance-list',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    RouterLink,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSidenavModule,
  ],
  templateUrl: './salesperonperformance-list.component.html',
  styleUrls: ['./salesperonperformance-list.component.scss'],
})
export class SalesPersonPerformanceListComponent extends BaseListComponent {
  singularTypes = Object.values(SupplierStatus).filter(
    (value) => typeof value === 'number'
  );

  permission: any = false;
  performance: any;
  salesperonperformance: SalesPersonPerformanceEntity[] = [];
  totalSalesPeronPerformance: number = 0;
  form: FormGroup;
  salesPersonList: any[] = [];
  endDate: string = '';
  startDate: string = '';
  isDataFound: boolean = false;

  salesPersonPerformanceListSearchInput: SalesPersonPerformanceListSearch = this.initializeSearchInput();

  displayedColumns = [
    'baName',
    'area',
    'tlName',
    'tasksCountTarget',
    'tasksCountAchieved',
    'buyingCountTarget',
    'buyingCountAchieved',
    'nonBuyingCountTarget',
    'nonBuyingCountAchieved',
    'dayRevenueTarget',
    'dayRevenueAchieved',
    'dayTasksRevenueTarget',
    'dayTasksRevenueAchieved',
    'petrolAllowance',
    'incentiveAllowance',
    'attendanceEligibleCount',
    'exception',
  ];
  selectedSalesPersonIds: string[] = [];
  dataSource!: MatTableDataSource<SalesPersonPerformance[]>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    public readonly salesPersonPerformanceService: SalesPeronPerformanceService,
    route: ActivatedRoute,
    router: Router,
    private loader: AppLoaderService,
    auth: AuthService,
    private fb: FormBuilder,
    private dialog: MatDialog
  ) {
    super(auth, router, route, PageId.bms_performance);

    this.form = this.fb.group({
      startDate: [new Date(), [Validators.required]],
      endDate: [new Date(), [Validators.required]],
      salespersonId: null,
    });
  }

  override async ngOnInit() {
    await super.ngOnInit();
    this.startDate = getDateYYYYMMDD(new Date());
    this.endDate = getDateYYYYMMDD(new Date());
    this.salesPersonList = await this.salesPersonPerformanceService.getSalesPersons();

    await this.getSalesPeronPerformance(false);
  }

  private mapSalesPersonInfo(response: SalesPersonPerformance[]) {
    const updatedRes = response.map((resItem) => {
      const salesPersonId = resItem.salesPersonId;

      const matchedSalesperson = this.salesPersonList.find(
        (person) => person.id === salesPersonId
      );

      if (matchedSalesperson) {
        return {
          ...resItem,
          baName: matchedSalesperson.name,
          area: matchedSalesperson.areaName,
          tlName: matchedSalesperson.salesLeadName,
        };
      } else {
        return { ...resItem, baName: '', area: '', tlName: '' };
      }
    });
    return updatedRes.filter((item) => Object.keys(item).length > 0);
  }
  getSelectedSalesPerson() {
    this.selectedSalesPersonIds = this.form.get('salespersonId')?.value || [];
  }

  async getSalesPeronPerformance(clearFilters: boolean) {
    try {
      this.loader.open();
      this.salesPersonPerformanceListSearchInput = this.createSalesPersonPerformanceListSearchInput();
      const response = await this.salesPersonPerformanceService.getSalesPeronPerformance(
        this.salesPersonPerformanceListSearchInput
      );

      if (response.length > 0) {
        this.isDataFound = true;
        const res = this.mapSalesPersonInfo(response);
        this.performance = res;
        this.dataSource = new MatTableDataSource<any>(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      else {
        if (!clearFilters) {
          this.isDataFound = false;
          this.loader.close();
          Swal.fire('Warning!', 'No Performance Found', 'warning');
        }

      }

    } catch (error) {
      this.loader.close();
      this.showMessage(
        'Failed to Fetch SalesPeronPerformance. Please try again later.',
        SwalMessageTypes.Error
      );
    } finally {
      this.loader.close();
    }
  }

  editSalesPersonPerformance(row: any, type: number, amount: number) {
    if (this.startDate !== this.endDate) {
      this.showMessage(
        'your selected dates should be same To Edit',
        SwalMessageTypes.Warning
      );

      return;
    }

    const allowance = type == 1 ? "Attendance eligibility is" : type == 2 ? "Petrol allowances are" : "Incentive allowances are";

    // Attendance eligibility is fixed, you cannot change
    if (row?.committed == true) {
      this.showMessage(
        ` ${allowance} fixed, you can not change`,
        SwalMessageTypes.Warning
      );

      return;
    }
    const input: IAllowanceModel = {
      allowanceType: type,
      performanceDate: this.startDate,
      salesPersonId: row.salesPersonId,
      amount: amount,
      reason: '',
    };
    const dialogRef = this.dialog.open(EditSalesPeronPerformance, {
      data: input,
    });
    dialogRef.afterClosed().subscribe(async (response: IAllowanceModel) => {
      if (response) {
        this.handelAllowanceUpdate(response);
      }
    });
  }

  private mapAllowance(input: IAllowanceModel, att1: string, att2: string) {
    const dynamicMapping: Record<string, any> = {};
    dynamicMapping[att1] = input.allowanceType == 1 ? true : +input.amount;
    dynamicMapping[att2] = input.reason;
    return dynamicMapping;
  }

  async handelAllowanceUpdate(data: IAllowanceModel) {
    switch (data.allowanceType) {
      case 1:
        await this.updateAllowance(
          data,
          'attendance_eligible',
          'attendance_eligible_comments'
        );
        break;
      case 2:
        await this.updateAllowance(
          data,
          'petrol_allowance',
          'petrol_eligible_comments'
        );
        break;
      case 3:
        await this.updateAllowance(
          data,
          'incentive_allowance',
          'incentive_eligible_comments'
        );
        break;
      default:
        break;
    }
  }

  private async updateAllowance(
    data: IAllowanceModel,
    updateAttributeOne: string,
    updateAttributeReason: string
  ) {
    try {
      this.loader.open();
      const targetsEntity = this.mapAllowance(
        data,
        updateAttributeOne,
        updateAttributeReason
      );
      const response = await this.salesPersonPerformanceService.updateAllowance(
        targetsEntity,
        data.salesPersonId,
        data.performanceDate
      );
      this.showMessage('Updated Successfully ', SwalMessageTypes.Success);
      this.loader.close();
      await this.getSalesPeronPerformance(false);
    } catch (error) {
      this.loader.close();
    }
  }

  private initializeSearchInput(): SalesPersonPerformanceListSearch {
    return {
      salespersonIds: [],
      goalType: 'filterDates',
      startDate: this.startDate,
      endDate: this.endDate,
    };
  }



  async searchTasks() {
    if (this.form.invalid) {
      this.showMessage('Please Select Date.', SwalMessageTypes.Warning);

      return;
    }
    this.startDate = getDateYYYYMMDD(new Date(this.form.value.startDate));
    this.endDate = getDateYYYYMMDD(new Date(this.form.value.endDate));
    if (new Date(this.startDate) > new Date(this.endDate)) {
      this.showMessage('Please give valid dates', SwalMessageTypes.Warning);

      return;
    }



    this.getSalesPeronPerformance(false);
  }

  private createSalesPersonPerformanceListSearchInput() {
    return {
      salespersonIds: this.selectedSalesPersonIds,
      goalType: 'filterDates',
      startDate: this.startDate,
      endDate: this.endDate,
    };
  }

  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  async clearFilters() {
    this.startDate = getDateYYYYMMDD(new Date());
    this.endDate = getDateYYYYMMDD(new Date());
    this.searchForm();
    this.selectedSalesPersonIds = []
    this.salesPersonPerformanceListSearchInput = this.initializeSearchInput();
    await this.getSalesPeronPerformance(true);
  }

  private searchForm() {
    this.form = this.fb.group({
      salespersonId: null,
      goalType: 'filterDates',
      startDate: new Date(),
      endDate: new Date(),
    });
  }


  private isSalesPersonResponseEmpty(data: any) {
    return Object.keys(data).every((key) => data[key].length === 0);
  }

  // generateExcel() {
  //   let isDataNotEmpty = this.isSalesPersonResponseEmpty(this.performance);

  //   const data: any = {
  //     performance: this.performance,
  //   };
  //   if (isDataNotEmpty) {
  //     this.showMessage('There Is No data to downLoad', 'warning');
  //     return;
  //   }
  //   const workbook = XLSX.utils.book_new();
  //   Object.keys(data).forEach((key) => {
  //     const sheet = XLSX.utils.json_to_sheet(data[key]);
  //     XLSX.utils.book_append_sheet(workbook, sheet, key);
  //   });
  //   XLSX.writeFile(workbook, 'Sales Person Performance Report.xlsx');
  // }

  generateExcel() {
    let isDataNotEmpty = this.isSalesPersonResponseEmpty(this.performance);



    if (isDataNotEmpty) {
      this.showMessage('There is no data to downLoad', 'warning');
      return;
    }

    const data: any = {
      performance: this.performance.map((item: any) => ({
        "Ba Name": item.baName,
        "Area": item.area,
        "Tl Name": item.tlName,
        "Tasks Count Target": item.tasksCountTarget,
        "Tasks Count Achieved": item.tasksCountAchieved,
        "Buying Count Target": item.buyingCountTarget,
        "Buying Count Achieved": item.buyingCountAchieved,
        "Non Buying Count Target": item.nonBuyingCountTarget,
        "Non Buying Count Achieved": item.nonBuyingCountAchieved,
        "Day Revenue Target": item.dayRevenueTarget,
        "Day Revenue Achieved": item.dayRevenueAchieved,
        "Day Tasks Revenue Target": item.dayTasksRevenueTarget,
        "Day Tasks Revenue Achieved": item.dayTasksRevenueAchieved,
        "Petrol Allowance": item.petrolAllowance,
        "Incentive Allowance": item.incentiveAllowance,
        "Attendance Eligible Count": item.attendanceEligibleCount
      }))
    };

    const workbook = XLSX.utils.book_new();
    Object.keys(data).forEach((key) => {
      const sheet = XLSX.utils.json_to_sheet(data[key]);
      XLSX.utils.book_append_sheet(workbook, sheet, key);
    });

    XLSX.writeFile(workbook, 'Sales Person Performance Report.xlsx');
  }


}
