import { SupplierEntity, SupplierImageResponse, SupplierListSearch, createSupplierResponse } from './../models/logisticsupplier-model';
import { Injectable } from '@angular/core';
import { Observable, Subject, lastValueFrom, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class SuppliersService {
  baseConfigUrl: string = `${environment.inventoryUrl}`;
  fileServicesApiUrl: string = `${environment.fileUploadBaseUrl}`;
  constructor(
    public http: HttpClient

  ) { }

  async createSupplier(data: SupplierEntity) {

    let response = await lastValueFrom(this.http.post<any>(`${this.baseConfigUrl}/api/supplier/create`, data));
    return response
  }

  async getSuppliers(body: SupplierListSearch): Promise<any> {
    const res = await lastValueFrom(this.http.post<any>(`${this.baseConfigUrl}/api/supplier/get_by_filters`, body));

    if (res && res.data) {
      return {
        supplierList: res.data.rows,
        totalSuppliers: res.data.count
      }
    }

  }

  async getSupplierById(id: string): Promise<any> {
    const res = await lastValueFrom(this.http.get<any>(`${this.baseConfigUrl}/api/supplier/get_by_id/${id}`));
    return res;
  }


  async imageUpload(body: { fileName: string; fileExtension: string; fileData: any; }, urlEndpoint: string) {

    let response = await lastValueFrom(this.http.put<{ res: SupplierImageResponse }>(`${this.fileServicesApiUrl}/documents/${urlEndpoint}`, body));

    return response
  }


  async updateSupplier(supplierEntity: SupplierEntity, supplierId: string) {
    let { id, ...supplierUpdatedEntity } = supplierEntity;

    let response = await lastValueFrom(this.http.post<{ res: createSupplierResponse }>(`${this.baseConfigUrl}/api/supplier/update/${supplierId}`, supplierUpdatedEntity));

    return response.res
  }

  async deleteSupplier(supplierId: string) {
    try {
      console.log('Deleting supplier with ID:', supplierId);
      const response = await lastValueFrom(this.http.get<any>(`${this.baseConfigUrl}/api/supplier/delete/${supplierId}`));
      return response;
    } catch (error: any) {
      console.error('Error occurred while deleting supplier:', error.message || error);
      throw error;
    }
  }

}
