import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { RouterLink } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { ImageDialog } from '../../models/vehicles.model';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-upload-image-dialog',
  standalone: true,
  imports: [MatCardModule, MatButtonModule, MatTableModule, RouterLink, MatIconModule],
  templateUrl: './upload-image-dialog.component.html',
  styleUrl: './upload-image-dialog.component.scss',
})
export class UploadImageDialogComponent implements OnInit {
  dataSource: any;
  constructor(public dialogRef: MatDialogRef<UploadImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ImageDialog,
    private fb: FormBuilder,) {
    this.dataSource = this.fb.group({
      imageUrl: data.imageUrl,
    });


  }

  ngOnInit(): void {

  }

}
