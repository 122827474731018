import { Component } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute, RouterLink } from '@angular/router';
import { AuthService } from '../../../../../services/auth.service';
import { AppLoaderService } from '../../../../../shared/app-loader/app-loader.service';
import { CustomersService } from '../../../services/customers.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { AddressUpdateComponent } from '../../address-update/address-update.component';
import { BaseListComponent } from '../../../../../shared/core/base.list.component';
import { PageId } from '../../../../../constants/enums';
import Swal from 'sweetalert2';
import { AddressStatus, SwalMessageTypes } from '../../../../../enums/enums';

@Component({
  selector: 'app-address-information',
  standalone: true,
  imports: [MatCardModule, MatButtonModule, MatTableModule, RouterLink, MatInputModule, MatFormFieldModule, ReactiveFormsModule,
    MatSelectModule, MatIconModule, CommonModule, FormsModule, MatDatepickerModule, MatRadioModule, MatNativeDateModule, MatPaginatorModule, MatSidenavModule,
    MatTableModule,
    MatButtonModule
  ],
  templateUrl: './address-information.component.html',
  styleUrl: './address-information.component.scss'
})
export class AddressInformationComponent extends BaseListComponent {
  dataSource: any;
  address: string = '';

  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    public customerService: CustomersService,
    private dialog: MatDialog,
    private customersService: CustomersService
  ) {
    super(auth, router, route, PageId.bms_customers);
  }

  id: string = ''
  displayedColumns: string[] = ['sno', 'address', 'action', 'status', 'defaultAddress'];


  override async ngOnInit() {
    await super.ngOnInit()
    this.route.params.subscribe(async (params) => {
      this.id = params['id'];
      this.getMultipleAddressByCustomerId(this.id)
    });

  }



  openDialog(id: string): void {
    const dialogRef = this.dialog.open(AddressUpdateComponent, {
      width: '500px',
      data: {
        addressId: id,
        customerId: this.id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getMultipleAddressByCustomerId(this.id)
    });
  }





  async getMultipleAddressByCustomerId(customerId: string) {
    const response: any = await this.customersService.getMultiAddressByCustomerId(customerId);
    console.log("response::", response);
    this.dataSource = response['addressList'].map((address: any) => {
      const formattedAddress = this.getFormattedCustomerAddress(address);
      return { ...address, formattedAddress };
    });
    console.log("this.dataSource::", this.dataSource);

  }

  private getFormattedCustomerAddress(address: any) {
    const { street, landmark, areaCode, city, state, country, pincode } = address;
    const formattedAddressParts = [street, landmark, areaCode, city, state, country, pincode].filter(Boolean);
    return formattedAddressParts.join(', ');
  }

  async updateAddressStatus(row: any) {
    console.log("row::", row);
    if (row.status == AddressStatus.WaitingForApproval) {
      try {
        const mappedAddress = this.mapToAddress(row)
        console.log("mappedAddress:", mappedAddress);
        const res: boolean = await this.customerService.updateAddressById(row.id, mappedAddress)
        if (res) {
          this.showMessage('Status Approved successfully', SwalMessageTypes.Success);
          row.status = AddressStatus.Approved
        } else {
          this.showMessage('Failed to update Address status', SwalMessageTypes.Error);
        }
      } catch (error) {
        this.showMessage('Failed to update Address status', SwalMessageTypes.Error);
      }
    }
  }

  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  private mapToAddress(row: any) {
    return {
      street: row.street,
      landmark: row.landmark || '',
      area_code: row.area_code,
      city: row.city,
      state: row.state,
      country: row.country,
      latitude: +row.latitude,
      longitude: +row.longitude,
      pincode: +row.pincode,
      is_default: +row.is_default,
      status: 1
    }
  }


}
