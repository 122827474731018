import { Injectable } from '@angular/core';
import { IncentiveSlab, IncentiveSlabEntity, IncentiveSlabListSearch, createIncentiveSlabResponse } from '../models/incentiveslabs.model';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IncentiveSlabsService {
  baseServiceUrl: string = `${environment.coreServiceUrl}`;

  constructor(
    private readonly http: HttpClient) {
  }


  async createIncentiveSlab(data: IncentiveSlabEntity) {

    let response = await lastValueFrom(this.http.post<{ res: createIncentiveSlabResponse }>(`${this.baseServiceUrl}/api/incentive_slab/create`, data));

    return response.res
  }

  async deleteIncentiveSlab(id: string) {
    try {
      console.log('Deleting supplier with ID:', id);
      const response = await lastValueFrom(this.http.delete<any>(`${this.baseServiceUrl}/api/incentive_slab/delete/${id}`));
      return response;
    } catch (error: any) {
      console.error('Error occurred while deleting supplier:', error.message || error);
      throw error;
    }
  }

  // async getIncentiveSlabs(): Promise<any> {
  //   const res = await lastValueFrom(this.http.get<any>(`${this.baseServiceUrl}/api/incentive_slab/getAllIncentives`));

  //   if (res && res.data) {
  //     return {
  //       incentiveSlabList: res.data.rows,
  //       totalIncentiveSlabs: res.data.count
  //     }
  //   }

  // }




  async getIncentiveSlabs(): Promise<{ incentiveSlabList: IncentiveSlab[]; totalIncentiveSlabs: number }> {
    const res = await lastValueFrom(this.http.get<any>(`${this.baseServiceUrl}/api/incentive_slab/getAllIncentives`));

    if (res && res.data) {


      const input={

      }

      const incentiveSlabList: IncentiveSlab[] = res.data.rows.map((row: any) => ({
        incentiveSlabId: row.incentive_slab_id,
        incentiveSlabName: row.incentive_slab_name,
        visitsCountMin: Math.round(row.visits_count_min),
        visitsCountMax: Math.round(row.visits_count_max),
        dayRevenuePercentMin: Math.round(row.day_revenue_percent_min),
        dayRevenuePercentMax: Math.round(row.day_revenue_percent_max),
        dayTasksPercentMin: Math.round(row.day_tasks_percent_min),
        dayTasksPercentMax: Math.round(row.day_tasks_percent_max),
        buyingCountPercentMin: Math.round(row.buying_count_percent_min),
        buyingCountPercentMax: Math.round(row.buying_count_percent_max),
        nonBuyingCountPercentMin: Math.round(row.non_buying_count_percent_min),
        nonBuyingCountPercentMax: Math.round(row.non_buying_count_percent_max),
        incentiveBuyingOrder: Math.round(row.incentive_buying_order),
        incentiveNonBuyingOrder: Math.round(row.incentive_non_buying_order),
        petrolAllowance: Math.round(row.petrol_allowance),
        createdDate: row.created_date,
        updatedDate: row.updated_date,
        createdBy: row.created_by,
        updatedBy: row.updated_by,
        isActive: row.is_active==1 ? "Active" : "In-Active",
        createdAt: row.createdAt,
        updatedAt: row.updatedAt,
      }));

      return {
        incentiveSlabList,
        totalIncentiveSlabs: res.data.count,
      };
    }
    return { incentiveSlabList: [], totalIncentiveSlabs: 0 };
  }

  async getIncentiveSlabById(id: string): Promise<any> {
    const res = await lastValueFrom(this.http.get<any>(`${this.baseServiceUrl}/api/incentive_slab/get_by_id/${id}`));
    return res;
  }

  async updateIncentiveSlab(incentiveSlabEntity: IncentiveSlabEntity, incentiveId: string) {
    let { incentiveSlabId, ...incentiveSlabUpdatedEntity } = incentiveSlabEntity;
    let response = await lastValueFrom(this.http.post<{ res: createIncentiveSlabResponse }>(
      `${this.baseServiceUrl}/api/incentive_slab/update/${incentiveId}`,
      incentiveSlabUpdatedEntity
    ));
    return response.res
  }

}
