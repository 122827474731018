import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { PreviewDialogComponent } from '../preview-dialog/preview-dialog.component';
import { PlanningService } from '../services/planning.service';
import { LogisticsStatus, PlanningTypes } from '../../../../enums/enums';
import { CommonModule, formatDate } from '@angular/common';
import {
  ReactiveFormsModule,
  FormsModule,
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { showWarningMessage } from '../../../../utlity/utility';
import { AuthService } from '../../../../services/auth.service';
import { BaseListComponent } from '../../../../shared/core/base.list.component';
import { PageId } from '../../../../constants/enums';

export interface PeriodicElement {
  typeId: number;
  type: string;
  ordersCount: number;
  customersCount: number;
  weight: number;
  value: number;
  isSelected: boolean;
}

@Component({
  selector: 'app-planning-list',
  templateUrl: './planning-list.component.html',
  styleUrls: ['./planning-list.component.scss'],
  imports: [
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    RouterLink,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSidenavModule,
  ],
  standalone: true,
})
export class PlanningListComponent extends BaseListComponent {
  displayedColumns: string[] = [
    'checkbox',
    'type',
    'ordersCount',
    'customersCount',
    'weight',
    'value',
  ];
  dataSource: any;
  form: FormGroup;
  selectAllCheckbox: boolean = false;
  checkboxStates: { [key: string]: boolean } = {};
  minDate: Date = new Date();
  planningPreview: any;
  selectedPlanning: any[] = [];
  locationId: string = '';
  startDate: any;
  resource: any;

  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private planningService: PlanningService,
    private authService: AuthService,
    private dateAdapter: DateAdapter<Date>
  ) {
    super(auth, router, route, PageId.logistics_planning);

    this.form = this.fb.group({
      startDate: [null, Validators.required], // Initialize with null
    });
    this.minDate = this.dateAdapter.today();
  }

  override async ngOnInit() {
    await super.ngOnInit();
    await this.getResource();
    this.locationId = this.resource?.data.location;
    await this.getPlanning(this.locationId);
  }

  private async getResource() {
    this.resource = await this.planningService.getResourceById(this.userId);
  }

  async getPlanning(locationId: string) {
    try {
      const response = await this.planningService.getPlanning(locationId);
      console.log("response :", response)
      const mappedData = this.mapToPanningResponse(response);
      this.dataSource = mappedData;
    } catch (error) {
      console.error('Error fetching planning data:', error);
    }
  }

  onSelect(event: any) {
    const selectedDate = event.value;
    this.startDate = selectedDate;
  }

  private mapToPanningResponse(response: any[]): PeriodicElement[] {
    return response.map((item) => ({
      type: PlanningTypes[item.type],
      typeId: item.type,
      ordersCount: item.orders_count,
      customersCount: item.customer_count,
      weight: item.weight,
      value: item.value,
      isSelected: false,
    }));
  }

  async preview() {
    try {
      const startDate = this.form.get('startDate')?.value;
      if (startDate == null) {
        showWarningMessage('Please select Date');
        return
      }
      const formattedStartDate = formatDate(startDate, 'yyyy-MM-dd', 'en-US');
      if (this.validateSelections(this.selectedPlanning)) {
        const payLoad = {
          statuses: [LogisticsStatus.ReadyForDispatchPlan, LogisticsStatus.ReadyForPickupPlan],
          planningTypes: this.selectedPlanning.map((item) => item.typeId),
          location_id: this.locationId,
        };

        this.planningPreview = await this.getSummaryByCluster(payLoad);
        console.log("this.planningPreview", this.planningPreview);

        if (this.planningPreview.length) {
          const dialogRef = this.dialog.open(PreviewDialogComponent, {
            height: '75%',
            width: '60%',
            data: {
              date: startDate ? formattedStartDate : '',
              planningSummary: this.planningPreview,
              vendorLocationId: this.locationId,
              planningTypes: this.selectedPlanning.map((item) => item.typeId),
            },
          });
          dialogRef.afterClosed().subscribe(() => { });
        } else {
          showWarningMessage('There is no trip for your selected option');
        }
      }
    } catch (error) {
      showWarningMessage('Something went wrong, Please try again later');
    }
  }

  async getSummaryByCluster(payLoad: any) {
    try {
      const response = await this.planningService.getSummaryByCluster(payLoad);
      const mappedData = this.mapToSummaryResponse(response);
      return mappedData;
    } catch (error) {
      return [];
    }
  }

  private mapToSummaryResponse(response: any[]): any[] {
    return response.map((item) => ({
      clusterId: item.cluster_id,
      cluster: item.cluster,
      ordersCount: item.orders_count,
      customerCount: item.customer_count,
      weight: Math.round(item.weight),
      value: item.value,
    }));
  }

  validateSelections(planningSummary: any[]): boolean {
    const validateNotEmpty = (selection: any[], message: string): boolean => {
      if (!selection.length) {
        showWarningMessage(message);
        return false;
      }
      return true;
    };

    if (
      !validateNotEmpty(
        planningSummary,
        'Please select any in Planning section'
      )
    ) {
      return false;
    }

    return true;
  }

  toggleSelectAll() {
    this.selectAllCheckbox = !this.selectAllCheckbox;

    for (const row of this.dataSource) {
      this.checkboxStates[row.typeId] = this.selectAllCheckbox;

      if (this.selectAllCheckbox) {
        this.selectedPlanning.push(row);
      } else {
        const selectedIndex = this.selectedPlanning.findIndex(
          (sp) => sp.typeId === row.typeId
        );
        if (selectedIndex !== -1) {
          this.selectedPlanning.splice(selectedIndex, 1);
        }
      }
    }
  }

  toggleCheckbox(planning: any) {
    this.checkboxStates[planning.typeId] = !this.checkboxStates[
      planning.typeId
    ];

    if (this.checkboxStates[planning.typeId]) {
      this.selectedPlanning.push(planning);
      planning.isSelected = true;
    } else {
      const selectedIndex = this.selectedPlanning.findIndex(
        (sp) => sp.typeId === planning.typeId
      );
      if (selectedIndex !== -1) {
        this.selectedPlanning.splice(selectedIndex, 1);
        planning.isSelected = false;
      }
    }
    const allSelected = this.dataSource.every((item: any) => item.isSelected);
    this.selectAllCheckbox = allSelected;
  }
}
