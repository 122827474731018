import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from '../services/product.service';
import { AuthService } from '../../../services/auth.service';
import { CommonService } from '../../../services/common.service';
import { first } from 'rxjs';
import { Location } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ReplaceCommasPipe } from "../../../shared/pipes/currency-pipe";

@Component({
    selector: 'app-preview-publish-product',
    standalone: true,
    templateUrl: './preview-publish-product.component.html',
    styleUrl: './preview-publish-product.component.scss',
    imports: [MatButtonModule, MatIconModule, MatCardModule, ReplaceCommasPipe]
})
export class PreviewPublishProductComponent {
  id:any;
  productDetail:any;
  previewDetail:any
    constructor(
      public route: ActivatedRoute,
      public productService: ProductService,
      public router: Router,
      public location:Location,
      public authService: AuthService,
      public commonService: CommonService
    ){
     
    }
  ngOnInit():void{
    this.route.params.subscribe((params) => {
      this.id = params['id'];
      this.getAll()
    })
    
  }
  back() {
    this.location.back();
  }
  getAll(){
    this.commonService.showLoder()
    this.productService.getSinglePublishedProducts(this.id).pipe(first()).subscribe((res:any)=>{
     this.previewDetail = res.data[0].attributes
     
      this.commonService.hideLoder()
    },err=>{
      this.commonService.showToaster("error",err.message)
      this.commonService.hideLoder()
    })
  }
    publishProduct() {
      this,this.commonService.showLoder()
      this.productService.getSingleApprovalDoc(this.id).pipe(first()).subscribe(async (res: any) => {
        if (this.authService.isVendor()) {
          this.productService.reSubmitRequestForApproval(res.id, res.payload)
          this.commonService.showToaster("success",'Product has been send for approval')
          this.router.navigateByUrl('/dashboard/my-requests');
        } else {
          await this.productService.approveRequest(res).then(async (res) => {
            this.commonService.showToaster("success",'Product has been published successfully')
            this.router.navigateByUrl('/dashboard/product/published');
          },(err:any)=>{
            console.log(err)
            this.commonService.showToaster("error",err.error.message)
            this.commonService.hideLoder()
          })
  
        }
        this.commonService.hideLoder()
      },err=>{
        this.commonService.showToaster("error",err.error.message)
        this.commonService.hideLoder()
      })
  
    }
}
