import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { capitalizeFirstLetter, extractCoordinates, isAreaNameValid, isValidGeoJsonFile, removeEndSpaces, removingSpace } from '../../../utlity/utility';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { PolygonService } from '../../../services/polygon.service';
import { IAreaDialogData, MasterCluster } from '../../master-geo/services/master-geo-model';


@Component({
  selector: 'area-edit',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatButtonModule,
    RouterOutlet,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatCardModule,
    MatIconModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    CommonModule,
    MatTableModule,
    RouterLink,
    ReactiveFormsModule,
  ],

  templateUrl: './update_coordinates.component.html',
  styleUrl: './update_coordinates.component.scss',
})
export class UpdateCoordinatesComponent {

  isNameExists: boolean = false;
  txtJson = '';
  polygonRawData: any = {};
  selectedFiles: any;
  jsonGeoData: any;
  isGeoJsonNotSingle: boolean = false;
  isFileSelected: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<UpdateCoordinatesComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, cluster: MasterCluster }
  ) {

  }

  
 
  updateCoordinates(){
    let updatedCoordinates = null;
    if (this.jsonGeoData !== undefined && this.jsonGeoData.features && this.jsonGeoData.features.length != 1) {
      this.isGeoJsonNotSingle = true;

      return;
    }
    if (this.selectedFiles == undefined) {
      this.isFileSelected = true
      this.resetFileInput();

      return;
    }

    if (this.jsonGeoData !== undefined && this.jsonGeoData.features[0] && this.jsonGeoData.features[0].geometry.coordinates
      && this.jsonGeoData.features[0].geometry.coordinates[0]) {

      const coordinates: { latitude: number; longitude: number }[] = extractCoordinates(this.jsonGeoData.features[0].geometry.coordinates[0]);
      updatedCoordinates = coordinates;

    }

    this.dialogRef.close({ cluster: this.data.cluster, updatedCoordinates });
  }

  onSelectFile(evnt:any){
    const file: any = evnt.target.files[0];
    if (!file) {
      Swal.fire('Warning!', 'Please upload the file!', 'warning');
      this.resetFileInput();
      return;
    }
    if (file && !isValidGeoJsonFile(file.name)) {
      Swal.fire('Warning!', 'Invalid File Format!!', 'warning');
      this.resetFileInput();
      return;
    }

    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (event: ProgressEvent) => {
      try {
        this.txtJson = (event.target as FileReader).result?.toString() || '{}';
        const validateJsonFeatures = JSON.parse(this.txtJson);

        if (validateJsonFeatures.features.length !== 1) {
          this.isGeoJsonNotSingle = true;
          return
        }

        this.jsonGeoData = validateJsonFeatures;
        this.selectedFiles = evnt.target.files;
      } catch {
        Swal.fire('', 'Invalid File', 'error');
      }
    };

  }

  private resetFileInput() {
    this.selectedFiles = null;
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }

  

}
