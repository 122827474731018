<div class="row">
  <div class="row m-2">
    <div class="col-md-9">
      <!--<h2 class="card-title m-3">Margin works</h2>-->
    </div>
    <div class="col-md-12">

      <button mat-raised-button type="button" class="m-2" color="primary" style="float: right;"
        (click)="downloadToExcel()">
        Download Excel
      </button>
      <button mat-raised-button type="button" class="m-2" color="primary" style="float: right;"
        (click)=" marginImport()">
        Import
      </button>
      <button mat-raised-button type="button" class="m-2" (click)="openDialog()" style="float: right;">
        <mat-icon>search</mat-icon>
      </button>
    </div>
  </div>


</div>
<div class="row ">
  <div class="m-3 flex flex-col md:flex-row justify-between " style="clear: both;">
    <mat-card class="p-0">
      <mat-card-content class="p-0">
        <mat-sidenav-container fxFlex="0 1 auto">
          <mat-sidenav-content>
            <div class="table-container">
              <mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="SKUId">
                  <mat-header-cell *matHeaderCellDef> SKU Id</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="SKUId">
                    {{row.skus_id}}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="SkuName">
                  <mat-header-cell *matHeaderCellDef> SKU Name</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="SkuName">
                    {{row.name}}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="brand">
                  <mat-header-cell *matHeaderCellDef> Brand</mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="brand">
                    {{row.brand_name}}
                  </mat-cell>
                </ng-container>
                <!--
                <ng-container matColumnDef="Category">
                  <mat-header-cell *matHeaderCellDef> Category </mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="Category">
                    {{row.company}}
                  </mat-cell>
                </ng-container> -->

                <ng-container matColumnDef="company">
                  <mat-header-cell *matHeaderCellDef> company </mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="company"> {{row.company_name}}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="sellingprice">
                  <mat-header-cell *matHeaderCellDef> SellingPrice
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="sellingprice">
                    {{row.selling_price}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="mrp">
                  <mat-header-cell *matHeaderCellDef> mrp
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="mrp">
                    {{row.maximum_retail_price | replaceCommas}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="margin">
                  <mat-header-cell *matHeaderCellDef>margin
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="margin">
                    {{row.margin}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="edit">
                  <mat-header-cell *matHeaderCellDef>Edit</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <button mat-icon-button (click)=" editPopUp(row.margin,row.skus_id)">
                      <mat-icon>edit</mat-icon>
                    </button>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="count">
                  <mat-header-cell *matHeaderCellDef>Pricing Rules </mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="count">
                    {{row.count}}
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              </mat-table>
            </div>

            <mat-paginator [length]="totalPages" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100,250]"
              (page)="onPageChange($event)"></mat-paginator>

          </mat-sidenav-content>
        </mat-sidenav-container>
      </mat-card-content>
    </mat-card>
  </div>

</div>