@if(hasEdit){
<ng-container>
  <div class="heading-block">
    <div class="row">
      <div class="col-md-6">
        <mat-icon class="backicon" [routerLink]="['/dashboard/logistics/location-list']">arrow_back</mat-icon>
        <span class="header">
          <h2 class="card-title m-3">Update Location</h2>
        </span>
      </div>
      <div class="col-md-6 text-right"></div>
    </div>
  </div>

  <div class="table-block">
    <form [formGroup]="dataSource">
      <section id="content">
        <div class="card">
          <div class="card-body card-padding">
            <div class="row">
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Name</mat-label>
                  <input matInput type="string" formControlName="name" name="name" placeholder="Name" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Warehouse Name</mat-label>
                  <input matInput type="string" formControlName="warehouseName" name="warehouseName"
                    placeholder="Warehouse Name" />
                </mat-form-field>
              </div>

              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Area</mat-label>
                  <input matInput type="string" formControlName="area" name="area" placeholder="Area" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Select State</mat-label>
                  <mat-select formControlName="state">
                    @for (state of states; track state) {
                    <mat-option [value]="state.name">{{
                      state.name
                      }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Pincode</mat-label>
                  <input matInput type="number" formControlName="pincode" name="pincode" placeholder="Pincode"
                    maxlength="6" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>City</mat-label>
                  <input matInput type="string" formControlName="city" name="city" placeholder="City" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Country</mat-label>
                  <input matInput type="string" formControlName="country" name="country" placeholder="Country" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Latitude</mat-label>
                  <input matInput type="number" formControlName="latitude" name="latitude" placeholder="Latitude" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Longitude</mat-label>
                  <input matInput type="number" formControlName="longitude" name="longitude" placeholder="Longitude" />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="buttonSection">
                @if(hasEdit){
                <button mat-raised-button color="primary" class="text-center ml" (click)="updateLocation()">
                  Update
                </button>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  </div>
</ng-container>
} @else {
<ng-container>
  <p class="text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
}
