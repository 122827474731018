@if(hasView){
<ng-container>
  <div class="heading-block">
    <div class="row m-3">
      <div class="col-md-6">
        <h2 class="card-title "> Orders </h2>
      </div>
      <div class="col-md-6 text-right">
        @if(hasExport){
        <button mat-raised-button color="primary m-m-t" class="topButtonSectionbtn" (click)="downloadToExcel()">
          Download Excel
        </button>
        }
        <button mat-raised-button color="primary m-m-t" class="topButtonSectionbtn" (click)="orderSummaryPopUp()">
          Summary
        </button>
      </div>
    </div>
  </div>

  <div class="search-block">
    <form [formGroup]="form">
      <section id="content">
        <div class="card">
          <div class="card-body card-padding">
            <div class="card-body card-padding">
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field class="example-full-width">
                    <input matInput formControlName="orderNumber" name="orderNumber" placeholder="Order Number" />
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width">
                    <input matInput formControlName="customerId" name="customerId" placeholder="Customer Id" />
                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field class="example-full-width">
                    <mat-label>Business Type</mat-label>
                    <mat-select formControlName="businessTypeId" (selectionChange)="getSelectedBusinessTypes()"
                      multiple>
                      <mat-option *ngFor="let businessType of businessTypesList" [value]="businessType.id">
                        {{ businessType.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field class="example-full-width">
                    <input matInput formControlName="customerMobileNumber" name="mobileNumber"
                      placeholder="Customer Mobile Number" type="number" />
                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field class="example-full-width">
                    <mat-label>Sales Person</mat-label>
                    <mat-select formControlName="salesperson" (selectionChange)="getSelectedSalesPerson()" multiple>
                      <mat-option *ngFor="let salesPerson of salesPersonList" [value]="salesPerson.id">
                        {{ salesPerson.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width">
                    <mat-label>Order Status</mat-label>
                    <mat-select formControlName="orderStatus" (selectionChange)="getSelectedOrderStatus()" multiple>
                      <mat-option *ngFor="let orderStatus of orderStatusList" [value]="orderStatus.id">
                        {{ orderStatus.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width">
                    <input matInput formControlName="startDate" placeholder="From Date" [matDatepicker]="appDatepicker"
                      [readonly]="true" />
                    <mat-datepicker-toggle matSuffix [for]="appDatepicker"></mat-datepicker-toggle>
                    <mat-datepicker #appDatepicker></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width">
                    <input matInput formControlName="endDate" placeholder="To Date" [matDatepicker]="appDatepickers"
                      [readonly]="true" />
                    <mat-datepicker-toggle matSuffix [for]="appDatepickers"></mat-datepicker-toggle>
                    <mat-datepicker #appDatepickers></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="col-md-12 text-center">
                    <button mat-raised-button color="primary" class="mt-100px" (click)="search()">
                      Search
                    </button>
                    <button mat-raised-button color="primary" class="text-center ml-w" (click)="clearFilters()"
                      style="margin-left: 5px;">
                      Clear Filters
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  </div>

  <div class="m-3 flex flex-col md:flex-row justify-between" style="clear: both">
    <mat-card class="p-0">
      <mat-card-content class="p-0">
        <mat-sidenav-container fxFlex="0 1 auto">
          <mat-sidenav-content>
            <div class="table-container">
              <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="salesPerson">
                  <mat-header-cell *matHeaderCellDef>
                    SalesPerson
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="salesPerson">
                    {{ row.salesPersonName }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="salesPersonMobileNumber">
                  <mat-header-cell *matHeaderCellDef>
                    SalesPerson Mobile Number
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="salesPersonMobileNumber">
                    {{ row.salesPersonMobile }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="areaCode">
                  <mat-header-cell *matHeaderCellDef>
                    Area Code
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="areaCode">
                    {{ row.customerAreaCode }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="businessName">
                  <mat-header-cell *matHeaderCellDef>
                    Business Name
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="businessName">
                    {{ row.customerBusinessName }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="customerMobileNumber">
                  <mat-header-cell *matHeaderCellDef>Customer Mobile Number
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="customerMobileNumber">
                    {{ row.customerMobile }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="orderAmount">
                  <mat-header-cell *matHeaderCellDef>
                    Total Amount
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="orderAmount">
                    {{ row.totalAmount || 0 }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="orderNumber">
                  <mat-header-cell *matHeaderCellDef>
                    Order Number
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="orderNumber">
                    {{ row.orderNumber }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="createdOrderDate">
                  <mat-header-cell *matHeaderCellDef>
                    Order Date
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="createdOrderDate">
                    {{ row.createdOrderDate}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="deliveredDate">
                  <mat-header-cell *matHeaderCellDef>
                    Delivered Date
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="deliveredDate">
                    {{ row.deliveredDate }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="orderStatus">
                  <mat-header-cell *matHeaderCellDef>
                    Order Status
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="orderStatus">
                    {{ getOrderStatus(row.orderStatusId) }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="customerId">
                  <mat-header-cell *matHeaderCellDef>Customer Id
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="customerId">
                    {{ row.customerId }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="orderType">
                  <mat-header-cell *matHeaderCellDef>
                    Order Placed By
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="orderType">
                    {{ getOrderSourceType(row.orderSourceType) }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="sellerName">
                  <mat-header-cell *matHeaderCellDef>
                    Seller Name
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" data-label="sellerName">
                    {{ row.vendorName }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="action">
                  <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <button mat-raised-button class="mr-8 p-2" color="primary" *ngIf="hasView"
                      (click)="navigateToOrderDetails(row)">
                      View Order
                    </button>
                  </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
              </mat-table>
            </div>
          </mat-sidenav-content>
        </mat-sidenav-container>
        <mat-paginator [length]="totalPages" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
          (page)="onPageChange($event)"></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</ng-container>
} @else {
<ng-container>
  <p class="text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
}
