@if(hasView){
<ng-container>
  <div class="heading-block">
    <div class="row">
      <div class="col-md-6">
        <h2 class="card-title m-3">Vehicles</h2>
      </div>
      <div class="col-md-6 text-right">
        @if(hasCreate){
        <button mat-raised-button color="primary" [routerLink]="['/dashboard/logistics/create-vehicle']"
          class="topButtonSectionbtn">
          Add Vehicle
        </button>
        }
      </div>
    </div>
  </div>
  <div class="search-block">
    <form [formGroup]="form">
      <section id="content">
        <div class="card">
          <div class="card-body card-padding">
            <div class="row">
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>VehicleType</mat-label>
                  <mat-select formControlName="vehicleType">
                    <mat-option *ngFor="let option of vehicleModes" [value]="option.value">
                      {{ option.type }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Tonnage</mat-label>
                  <mat-select formControlName="tonnage">
                    <mat-option *ngFor="let option of vehicleTonnageOptions" [value]="option.value">
                      {{ option.tonnage }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>ContractMode</mat-label>
                  <mat-select formControlName="contractMode">
                    <mat-option *ngFor="let type of vehicleContractMode" [value]="type">
                      {{ type === 1 ? "Fixed" : "Adhoc" }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Status</mat-label>
                  <mat-select formControlName="status">
                    <mat-option *ngFor="let type of vehicleStatus" [value]="type">
                      {{ type === 0 ? "InActive" : "Active" }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-md-6 mt-2 d-flex justify-content-end">
                <button mat-raised-button color="primary" class="ml" (click)="searchTasks()">
                  Search
                </button>
              </div>
              <div class="col-md-6 mt-2">
                <button mat-raised-button color="primary" class="ml-10" (click)="clearFilters()">
                  Clear Filters
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  </div>

  <div class="table-block">
    <div class="m-3 flex flex-col md:flex-row justify-between">
      <mat-card class="p-0">
        <mat-card-content class="p-0">
          <mat-sidenav-container fxFlex="0 1 auto">
            <mat-sidenav-content>
              <div class="table-container">
                <mat-table [dataSource]="dataSource" matSort>
                  <ng-container matColumnDef="vehicleModel">
                    <mat-header-cell *matHeaderCellDef>
                      Vehicle Type
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="vehicleModel">
                      {{ getVehicleModelName(row.vehicleModel) }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="vehicleTonnage">
                    <mat-header-cell *matHeaderCellDef>
                      Vehicle Tonnage
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="vehicleTonnage">
                      {{getVehicleTonnageName(row.vehicleTonnage) }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="vehicleNumber">
                    <mat-header-cell *matHeaderCellDef>
                      Vehicle Number
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="vehicleNumber">
                      {{ row.vehicleNumber }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="contractMode">
                    <mat-header-cell *matHeaderCellDef>
                      Contract Mode
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="contractMode">
                      {{ getVehicleContractModeText(row.contractMode) }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef>
                      Status
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="status">
                      {{ getStatusName(row.status) }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="edit">
                    <mat-header-cell *matHeaderCellDef class="mat-column-actions">
                      Edit
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="mat-column-actions">
                      <div class="d-flex">
                        @if(hasEdit){
                        <button mat-icon-button (click)="editVehicle(row)">
                          <mat-icon>edit</mat-icon>
                        </button>
                        }
                      </div>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="delete">
                    <mat-header-cell *matHeaderCellDef class="mat-column-actions">
                      Delete
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="mat-column-actions">
                      <div class="d-flex">
                        @if(hasDelete){
                        <button mat-icon-button (click)="deleteVehicle(row)">
                          <mat-icon color="warn">delete</mat-icon>
                        </button>
                        }
                      </div>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                </mat-table>
              </div>
            </mat-sidenav-content>
          </mat-sidenav-container>
          <mat-paginator [length]="totalVehicles" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="onPageChange($event)"></mat-paginator>
        </mat-card-content>
      </mat-card>
    </div>

  </div>

</ng-container>
} @else {
<ng-container>
  <p class="text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
} 