import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  OnChanges,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSidenavModule } from '@angular/material/sidenav';
import Swal from 'sweetalert2';
import { AppLoaderService } from '../../../../../../shared/app-loader/app-loader.service';
import { AuthService } from '../../../../../../services/auth.service';
import {
  SwalMessageTypes,
  SupplierStatus,
  TripStatus,
} from '../../../../../../enums/enums';
import { TripService } from '../../../services/trip.service';
import { HopItems, Order } from '../../../models/trip.model';
import { BaseListComponent } from '../../../../../../shared/core/base.list.component';
import { PageId } from '../../../../../../constants/enums';
import { ReplaceCommasPipe } from '../../../../../../shared/pipes/currency-pipe';

@Component({
  selector: 'app-order-view',
  standalone: true,
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.scss'],
  imports: [
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    RouterLink,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatMenuModule,
    ReplaceCommasPipe,
  ],
})
export class OrderViewComponent extends BaseListComponent {
  readonly DEFAULT_PAGE_SIZE = 25;
  id: any;
  hopItems: HopItems[] = [];
  dataSource: Order[] = [];
  hopOrders: Order[] = [];
  totalPlannings: number = 0;
  displayedColumns = ['orderDate', 'orderNumber', 'orderAmount','orderCouponDiscountAmount','netAmount', 'orderStatus'];

  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    public readonly tripService: TripService,
    private loader: AppLoaderService
  ) {
    super(auth, router, route, PageId.logistics_trips);
  }
  override async ngOnInit() {
    await super.ngOnInit();
    this.route.params.subscribe(async (params: any) => {
      this.id = params['id'];
      if (this.id) {
        await this.getOrders(this.id);
      }
    });
  }
  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  getHopDetails() {
    this.router.navigateByUrl(`/dashboard/logistics/hop-details`);
  }
  async getOrders(id: string) {
    try {
      this.hopItems = await this.tripService.getHopItems(id);
      this.hopOrders = this.mapToOrders(this.hopItems);
      this.dataSource = this.hopOrders;
    } catch (error) { }
  }
  private mapToOrders(orderItems: HopItems[]): Order[] {

    const groupedData: Order[] = orderItems.reduce<Order[]>((result, item) => {
      const { orderNumber } = item;
      const existingGroupIndex = result.findIndex(
        (group) => group.orderNumber === orderNumber
      );

      const itemAmount = item.quantity * item.sellingPrice;
      const discount=item.orderItemDiscountAmount

      if (existingGroupIndex !== -1) {
        result[existingGroupIndex].items.push({
          itemId: item.itemId,
          id: item.id,
          itemName: item.itemName,
          quantity: item.quantity,
          sellingPrice: item.sellingPrice,
          weight: item.weight,
          upc: item.upc,
          imageUrl: item.imageUrl,
          mrp: item.mrp,
          modifiedQty: item.modifiedQty,
          returnQty: item.returnQty,
          itemKey: item.itemKey,
        });
        result[existingGroupIndex].orderAmount += Math.round(itemAmount);
        result[existingGroupIndex].discountAmount += Math.round(discount);
      } else {
        result.push({
          orderNumber: orderNumber,
          orderStatus: item.orderStatusId.toString(),
          createdDate: item.createdDate ?? '',
          createdTimeStamp: item.createdTimeStamp.toString(),
          customerId: item.customerId,
          vendorId: item.vendorId,
          orderStatusLabel: item.orderStatusLabel,
          orderStatusId: item.orderStatusId,
          orderCouponDiscountAmount:Math.round(item.orderCouponDiscountAmount),
          items: [
            {
              itemId: item.itemId,
              id: item.id,
              itemName: item.itemName,
              quantity: item.quantity,
              sellingPrice: item.sellingPrice,
              weight: item.weight,
              upc: item.upc,
              imageUrl: item.imageUrl,
              mrp: item.mrp,
              modifiedQty: item.modifiedQty,
              returnQty: item.returnQty,
              itemKey: item.itemKey,
            },
          ],
          orderAmount: Math.round(itemAmount),
          discountAmount:Math.round(discount),
        });
      }
      return result;
    }, []);

    return groupedData;
  }
}
