import { Component } from '@angular/core';
import { FormBuilder, Validators, ReactiveFormsModule, FormsModule, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MerchantsService } from '../../services/merchants.service';
import { MerchantEntity, MerchantForm, PropertyValidation } from '../../models/merchants.model';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import Swal from 'sweetalert2';
import { MerchantBusinessType, SupplierStatus, SwalMessageTypes, states } from '../../../../enums/enums';
import { AuthService } from '../../../../services/auth.service';
import { AppLoaderService } from '../../../../shared/app-loader/app-loader.service';
import { applyMaxLengthValidation, containsMail, containsOnlyAlphanumeric, hasSpecialCharacters, isTextValid, validLatitude, validLongitude, validateGST, validateMaxText, validateNegativeValues, validatePanNumber, validatePinCode, validateSpecialCharacters } from '../../../../utlity/utility';
import { State } from '../../../logistics/supplier/models/supplier-model';
import { BaseListComponent } from '../../../../shared/core/base.list.component';
import { takeUntil } from 'rxjs';
import { PageId } from '../../../../constants/enums';


@Component({
  selector: 'app-edit-merchant',
  standalone: true,
  imports: [MatCardModule, MatButtonModule, MatTableModule, RouterLink, MatInputModule,
    MatFormFieldModule, ReactiveFormsModule, MatSelectModule, MatIconModule,
    CommonModule, FormsModule, MatDatepickerModule, MatRadioModule, MatNativeDateModule,
    MatPaginatorModule, MatSidenavModule, MatDatepickerModule, MatCheckboxModule],
  templateUrl: './edit-merchant.component.html',
  styleUrls: ['./edit-merchant.component.scss']
})
export class EditMerchantComponent extends BaseListComponent {
  states: State[] = states;
  businessTypes: { type: string, value: number }[] = [
    { type: "Distributor", value: 1 },
    { type: "Manufacturer", value: 2 },
    { type: "Wholesaler", value: 3 },
    { type: "JitVendor", value: 4 },
    { type: "Inventory", value: 5 },
  ];
  status: { type: string, value: number }[] = [
    { type: "InActive", value: 0 },
    { type: "Active", value: 1 }
  ];
  dataSource: any;
  id: string = "";
  merchantEntity!: MerchantEntity;

  constructor(private fb: FormBuilder,
    router: Router,
    route: ActivatedRoute,
    auth: AuthService,
    private merchantsService: MerchantsService,
    private loader: AppLoaderService,
  ) {
    super(auth, router, route, PageId.bms_vendors);
    this.editForms();
    this.fixMaxLength();
  }

  override async ngOnInit() {
    super.ngOnInit();
    this.route.params
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (params) => {
        this.id = params['id'];
        console.log("ngOnInit:ngOnInit", this.id);

        await this.getMerchantById();

      });
  }

  editForms() {
    this.dataSource = this.fb.group({
      id: [null, [Validators.required]],
      businessName: [null, [Validators.required]],
      name: [null, [Validators.required]],
      email: [null, [Validators.required]],
      mobileNumber: [null, [Validators.required]],
      gstNumber: [null, [Validators.required]],
      panNumber: [null, [Validators.required]],
      businessType: [null, [Validators.required]],
      // password: [null, [Validators.required]],
      address: [null, [Validators.required]],
      state: [null, [Validators.required]],
      city: [null, [Validators.required]],
      area: [null, [Validators.required]],
      pincode: [null, [Validators.required]],
      latitude: [null, [Validators.required]],
      longitude: [null, [Validators.required]],
      status: [null, [Validators.required]],
    });
  }
  async getMerchantById() {
    try {
      this.loader.open();
      const response = await this.merchantsService.getMerchantById(this.id);
      this.dataSource = this.mapToEditInput(response);
      this.dataSource.patchValue({ status: response.status });
    } catch (error) {
      this.showMessage('successfully updated', SwalMessageTypes.Error);
    } finally {
      this.loader.close();

    }
  }


  async updateMerchant() {
    this.loader.open();

    try {
      if (this.dataSource.invalid) {
        this.showMessage('Please fill all required fields.', SwalMessageTypes.Warning);

        return
      }
      await this.updateMerchantById();
    } catch (error) {
      console.error('Error occurred during merchant creation:', error);
    } finally {
      this.loader.close();
    }
  }

  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }



  private mapMerchant(merchant: MerchantForm): MerchantEntity {
    const form = merchant;
    return {
      id: form?.id,
      business_name: form?.businessName,
      name: form?.name,
      email: form?.email,
      mobile_number: form?.mobileNumber,
      gst_number: form?.gstNumber,
      pan_number: form?.panNumber,
      business_type: form?.businessType,
      password: form?.password,
      address: form?.address,
      area: form?.area,
      state: form?.state,
      city: form?.city,
      pincode: form?.pincode,
      latitude: form?.latitude,
      longitude: form?.longitude,
      status: form?.status
    }
  }

  async updateMerchantById() {
    const merchantEntity = this.mapMerchant(this.dataSource.value);

    try {
      if (this.validMerchant(merchantEntity)) {
        console.log("!")
        merchantEntity.pan_number = merchantEntity.pan_number.toLocaleUpperCase();
        merchantEntity.gst_number = merchantEntity.gst_number.toLocaleUpperCase();
        merchantEntity.business_type = parseInt(MerchantBusinessType[merchantEntity.business_type]);

        const response = await this.merchantsService.updateMerchant(merchantEntity, this.id);
        this.showMessage('Vendor updated successfully', SwalMessageTypes.Success);
        this.router.navigateByUrl('/dashboard/merchant');
      } else {
        console.log("2")

      }

    } catch (error) {
      console.error('Error occurred while creating merchant:', error);
      this.showMessage('Failed to create Vendor. Please try again later.', SwalMessageTypes.Error);
    }
  }
  getBusinessTypeName(status: number): string {
    return MerchantBusinessType[status];
  }


  private mapToEditInput(merchant: MerchantEntity): FormGroup {
    const entity = merchant;
    return this.fb.group({
      id: new FormControl(entity.id || ""),
      businessName: new FormControl(entity.business_name || ""),
      name: new FormControl(entity.name || ""),
      email: new FormControl(entity.email || ""),
      mobileNumber: new FormControl(entity.mobile_number || ""),
      gstNumber: new FormControl(entity.gst_number || ""),
      panNumber: new FormControl(entity.pan_number || ""),
      businessType: new FormControl(this.getBusinessTypeName(entity.business_type) || ""),
      password: new FormControl(entity.password || ""),
      address: new FormControl(entity.address || ""),
      state: new FormControl(entity.state || ""),
      city: new FormControl(entity.city || ""),
      area: new FormControl(entity.area || ""),
      pincode: new FormControl(entity.pincode || ""),
      latitude: new FormControl(entity.latitude || ""),
      longitude: new FormControl(entity.longitude || ""),
      status: new FormControl(entity.status || ""),
    });
  }

  private fixMaxLength() {
    applyMaxLengthValidation(this.dataSource, [
      { property: "mobileNumber", maxLength: 10 },
      { property: "pincode", maxLength: 6 },
    ]);
  }



  private validMerchant(merchant: MerchantEntity): boolean {

    if (!merchant.name || containsOnlyAlphanumeric(merchant.name)) {

      this.showMessage('Please enter valid name .', SwalMessageTypes.Warning);

      return false;
    }
    if (!merchant.business_name || containsOnlyAlphanumeric(merchant.business_name)) {

      this.showMessage('Please enter valid business name .', SwalMessageTypes.Warning);

      return false;
    }

    if (!containsMail(merchant.email)) {

      this.showMessage('Please give valid mail id.', SwalMessageTypes.Error);
      return false;
    }

    if (!merchant.gst_number || !validateGST(merchant.gst_number)) {

      this.showMessage('Please enter valid GST number .', SwalMessageTypes.Warning);

      return false;
    }

    if (!merchant.pan_number || !validatePanNumber(merchant.pan_number)) {

      this.showMessage('Please enter valid PAN number .', SwalMessageTypes.Warning);

      return false;
    }
    if (!merchant.address) {

      this.showMessage('Please enter valid address .', SwalMessageTypes.Warning);

      return false;
    }

    if (!merchant.area || containsOnlyAlphanumeric(merchant.area)) {

      this.showMessage('Please enter valid area .', SwalMessageTypes.Warning);

      return false;
    }
    if (!merchant.city || !isTextValid(merchant.city)) {

      this.showMessage('Please enter valid city .', SwalMessageTypes.Warning);

      return false;
    }

    if (!merchant.state) {

      this.showMessage('Please enter valid state .', SwalMessageTypes.Warning);

      return false;
    }

    if (!merchant.pincode || !validatePinCode(+merchant.pincode)) {

      this.showMessage('Please enter valid pincode .', SwalMessageTypes.Warning);

      return false;
    }

    if (!merchant.latitude || !validLatitude(merchant.latitude.toString())) {

      this.showMessage('Please enter valid latitude .', SwalMessageTypes.Warning);

      return false;
    }
    if (!merchant.longitude || !validLongitude(merchant.longitude.toString())) {

      this.showMessage('Please enter valid longitude .', SwalMessageTypes.Warning);

      return false;
    }





    return true;
  }
}
