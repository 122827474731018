<div class="container-fluid">
  <div class="row">
    <div class="col-md-4">
      <label>Name</label>
      <p>{{data?.payload?.name}}</p>
    </div>
    <div class="col-md-4">
      <label>Type</label>
      <p>{{data?.type | type}}</p>
    </div>
    <div class="col-md-4">
      <label>Status</label>
      <p><app-status [value]="data.status" ></app-status></p>
    </div>
  </div>
</div>