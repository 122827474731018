import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule, Location } from '@angular/common';
import { capitalizeFirstLetter, extractCoordinates, isAreaNameValid, isValidGeoJsonFile, removingSpace } from '../../../utlity/utility';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { PolygonService } from '../../../services/polygon.service';
import { IAreaDialogData } from '../../master-geo/services/master-geo-model';
import { LookUpTypeService } from '../../../services/lookup-type.service';
import { LookUpType } from '../../../enums/enums';
import { AuthService } from '../../../services/auth.service';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { PageId } from '../../../constants/enums';

interface ExistingData {
  searchKey: string;
  locationId: string;
}
@Component({
  selector: 'area-edit',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatButtonModule,
    RouterOutlet,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatCardModule,
    MatIconModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    CommonModule,
    MatTableModule,
    RouterLink,
    ReactiveFormsModule,
  ],

  templateUrl: './area-edit.component.html',
  styleUrl: './area-edit.component.scss',
})
export class AreaEditComponent extends BaseListComponent {

  dataSource: any;
  jsonGeoData: any;
  txtJson: string = '';
  selectedFiles: any | null;
  isNameExists: boolean = false;
  isNameValid: boolean = false;
  currentClusterSearchKeys: Array<any> = [];
  isGeoJsonNotSingle: boolean = false;
  isMandatoryProperties: boolean = false;
  public location: any[] = [];
  lookUpTypeSearchInput: LookUpType = this.initializeLookUpTypeSearchInput();


  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AreaEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IAreaDialogData,
    private polygonService: PolygonService,
    private lookupService: LookUpTypeService
  ) {
    super(auth, router, route, PageId.logistics_clusters); // Call the constructor of BaseListComponent

    this.dataSource = this.fb.group({
      id: [''],
      name: ['', Validators.required],
      searchKey: ['', Validators.required],
      location: ['', Validators.required],
      coordinates: [null],
    });
  }
  override async ngOnInit() {
    await super.ngOnInit();
    await this.getLocation();
    this.currentClusterSearchKeys = this.data.currentClusterSearchKeys;
  }

  async getLocation() {
    const response = await this.lookupService.getLookUpTypes(this.lookUpTypeSearchInput);
    this.location = response.data[0].data;
  }

  private checkName(): boolean {
    return this.currentClusterSearchKeys.some(e =>
      e.searchKey === this.dataSource.value.searchKey &&
      e.locationId === this.dataSource.value.location.id
    );
  }


  addNewArea() {
    this.isNameExists = false;
    this.isNameValid = false
    this.isMandatoryProperties = false;
    this.isGeoJsonNotSingle = false;
    if (!this.dataSource.value.name) {
      Swal.fire('Warning!', 'Please add the  cluster name!', 'warning');
      return;
    }
    if (!this.dataSource.value.location) {
      Swal.fire('Warning!', 'Please add the Location!', 'warning');
      return;
    }

    if (!this.selectedFiles) {
      Swal.fire('Warning!', 'Please upload the GeoJSON file!', 'warning');
      return;
    }

    this.dataSource.value.name = this.dataSource.value.name.trim();
    let nameValidate = isAreaNameValid(this.dataSource.value.name);

    if (!nameValidate) {
      this.isNameValid = true;
      return
    }
    this.dataSource.value.name = capitalizeFirstLetter(this.dataSource.value.name);
    this.dataSource.value.searchKey = removingSpace(this.dataSource.value.name);
    if (this.checkName()) {
      this.isNameExists = true;

      return
    }

    if (this.jsonGeoData !== undefined && this.jsonGeoData.features && this.jsonGeoData.features.length != 1) {
      this.isGeoJsonNotSingle = true;
      Swal.fire('Error!', 'Upload Only one geo Polygon At a time.', 'error');
      return;
    }
    if (this.jsonGeoData !== undefined && this.jsonGeoData.features[0] && this.jsonGeoData.features[0].geometry.coordinates) {
      const coordinates: { latitude: number; longitude: number }[] = extractCoordinates(this.jsonGeoData.features[0].geometry.coordinates[0]);
      this.dataSource.value.coordinates = coordinates;
    }
    if (this.dataSource.value.coordinates == null) {
      Swal.fire('Warning!', 'Please add the Area Geo Json!', 'warning');

      return
    }
    this.dialogRef.close({
      cluster: this.dataSource.value,
    });
  }

  private initializeLookUpTypeSearchInput(): LookUpType {
    return {
      tableName: "locations",
      lookupType: "locations",
      idColumnName: "id",
      valueColumnName: "name"
    };
  }
  onSelectFile(evnt: any) {
    const file: any = evnt.target.files[0];
    if (!file) {

      Swal.fire('Warning!', 'Please upload the file!', 'warning');
      this.resetFileInput();
      return;
    }
    if (file && !isValidGeoJsonFile(file.name)) {
      Swal.fire('Warning!', 'Invalid File Format!!', 'warning');
      this.resetFileInput();
      return;
    }

    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (event: ProgressEvent) => {
      try {
        this.txtJson = (event.target as FileReader).result?.toString() || '{}';
        const validateJsonFeatures = JSON.parse(this.txtJson);

        this.jsonGeoData = validateJsonFeatures;
        this.selectedFiles = evnt.target.files


      } catch {
        Swal.fire('', 'Invalid File', 'error');
      }
    };
  }

  private resetFileInput() {
    this.selectedFiles = null;
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }


}
