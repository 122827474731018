import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { CommonModule } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SwalMessageTypes } from '../../../../enums/enums';
import { MatCheckboxModule } from '@angular/material/checkbox';
import Swal from 'sweetalert2';
import { AppLoaderService } from '../../../../shared/app-loader/app-loader.service';
import { AuthService } from '../../../../services/auth.service';
import { User } from '@angular/fire/auth';
import { BehaviorSubject } from 'rxjs';
import { VendorGeoMappingService } from '../../services/vendor-geo-mapping.service';
import { UploadLocationComponent } from '../upload-location/upload-location.component';
import { IAreaDialogData } from '../../models/vendor-geo-mapping-model';
import { MatDialog } from '@angular/material/dialog';
import { AddCompanyComponent } from '../add-company/add-company.component';
import { EditCompanyComponent } from '../edit-company/edit-company.component';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-add-vendor-geo-mapping',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    RouterLink,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatCheckboxModule,
  ],
  templateUrl: './add-vendor-geo-mapping.component.html',
  styleUrl: './add-vendor-geo-mapping.component.scss',
})
export class AddVendorGeoMappingComponent {
  page_id = 'bms_create_suppliers';
  permission: any = false;
  id: any;
  merchantId!: string;
  dataSource: any;
  dataSource1: any = [];
  selectedFiles: any | null;
  mappingConflict: any;
  txtJson: string = '';
  isLoading: boolean = false;
  singlelocation: Array<any> = [];
  companiesList: Array<any> = [];
  userSubject = new BehaviorSubject<User | null>(null);
  locationMappedCompaniesList: Array<string> = [];
  displayedColumns = ['locations', 'company', 'action'];
  locationsList: any = [];
  selectedLocationId: any;

  constructor(
    public fb: FormBuilder,
    public route: ActivatedRoute,
    public router: Router,
    private loader: AppLoaderService,
    public auth: AuthService,
    private dialog: MatDialog,
    public vendorGeoMappingService: VendorGeoMappingService
  ) {
    console.log('vendor mapping page');
  }
  ngOnInit() {
    this.route.params.subscribe(async (params) => {
      this.id = params['id'];
      this.merchantId = this.id;
      await this.getMerchantById();

      await this.getLocations(this.merchantId);
    });
  }

  async getMerchantById() {
    try {
      this.loader.open();
      const response = await this.vendorGeoMappingService.getMerchantById(
        this.id
      );
      this.dataSource = response;
    } catch (error) {
      this.showMessage(
        'Failed to Get merchant. Please try again later.',
        SwalMessageTypes.Error
      );
    } finally {
      this.loader.close();
    }
  }

  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }
  async getLocations(merchantId: string) {
    try {
      this.locationsList = await this.vendorGeoMappingService.getLocations(
        merchantId
      );
      this.locationsList = this.locationsList || [];
      const companiesList: Array<any> = [].concat.apply(
        [],
        this.locationsList.map((l: any) => l.companyNames)
      );

      this.locationMappedCompaniesList = [].concat.apply(
        [],
        companiesList.filter(Boolean)
      );
    } catch (error) {
      throw error;
      // swal("", "Something went wrong while processing your request.Please try again after some time.", "warning");
    }
  }

  async downloadLocationGeoJson(locationId: number, locationName: string) {
    ``;

    let geoVendor: any = await this.vendorGeoMappingService.locationGeoJson(
      locationId
    );
    const blob = new Blob([JSON.stringify(geoVendor)], {
      type: 'application/json',
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = locationName;
    a.click();
    a.remove();
  }

  async downloadGeoJsonVendor() {
    let geoVendor: any = await this.vendorGeoMappingService.geoJsonByVendor(
      this.merchantId
    );
    const blob = new Blob([JSON.stringify(geoVendor)], {
      type: 'application/json',
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'download';
    a.click();
    a.remove();
  }

  // async downloadAreaWiseCustomers() {
  //   try {
  //     this.isLoading = true;
  //     let customerList: Array<any> =
  //       await this.vendorGeoMappingService.getCustomersByVendorGeoArea(
  //         this.merchantId
  //       );
  //     if (customerList && customerList.length > 0) {
  //       let customerListForExcelExport =
  //         this.mapCustomersForExcelExport(customerList);
  //       this.jsonToXcel(customerListForExcelExport);
  //     } else {
  //       Swal.fire('Data not found!', '', 'info');
  //     }
  //     this.isLoading = false;
  //   } catch (error) {
  //     this.isLoading = false;
  //     Swal.fire('Something went wrong. please try again.', '', 'error');
  //   }
  // }
  openPopUp() {
    const dialogRef = this.dialog.open(UploadLocationComponent, {
      width: '50%',
      height: '40%',
      data: {
        title: 'Add location',
        geoJson: '',
      } as IAreaDialogData,
    });

    dialogRef.afterClosed().subscribe(async (response: IAreaDialogData) => {
      console.log('response', response.geoJson);
      if (response.geoJson) {
        console.log('if condition');
        await this.vendorGeoMappingService.saveGeoJson(
          this.merchantId,
          response.geoJson
        );
        //user activity log
        await this.getLocations(this.merchantId);
        Swal.fire('Uploaded!', 'Uploaded Geo JSON Successfully', 'success');
      }
    });
  }
  async openPopUpCompany(companyIds: any, index: any, selectedLocationId: any) {
    const dialogRef = this.dialog.open(AddCompanyComponent, {
      width: '50%',
      height: '40%',
      data: {
        title: 'Add Company',
        companyIds: companyIds,
        index: index,
        selectedLocationId: selectedLocationId,
        vendorId: this.id,
        vendorTypeId: this.dataSource.businessType,
        vendorRefId: this.merchantId,
      },
    });

    dialogRef.afterClosed().subscribe(async (companyList: any) => {
      console.log('companylist', companyList);
      try {
        await this.vendorGeoMappingService.mapCompanies(companyList);
        await this.getLocations(this.merchantId);
        Swal.fire('', 'Added companies successfully', 'success');

        console.log('selected companies response', companyList);
      } catch (error: any) {
        if (error.status === 409) {
          this.mappingConflict = [];
          const response = error.error;
          if (response && response.data) {
            this.mappingConflict = response.data;
            if (this.mappingConflict) {
              let errormessage = this.mappingConflict.map((conflict: any) => {
                let errormessage =
                  'This area being served by' +
                  conflict.vendorName +
                  'for' +
                  conflict.companyName;
                console.log('error message', errormessage);

                return errormessage;
              });
              let consolidateMessage = errormessage.join(', ');
              console.log('messaaaaa', consolidateMessage);

              alert(consolidateMessage);
            }
          }
        } else {
          Swal.fire(
            '',
            'Something went wrong while processing your request.Please try again after some time.',
            'warning'
          );
        }
      }
    });
  }

  async openPopUpEditCompany(
    companyIds: any,
    index: any,
    selectedLocationId: any
  ) {
    console.log('helloooooooo');

    const dialogRef = this.dialog.open(EditCompanyComponent, {
      width: '50%',
      height: '40%',
      data: {
        title: 'Add Company',
        companyIds: companyIds,
        index: index,
        selectedLocationId: selectedLocationId,
        vendorId: this.id,
        vendorTypeId: this.dataSource.businessType,
        vendorRefId: this.merchantId,
      },
    });
    dialogRef.afterClosed().subscribe(async (returnData: any) => {
      console.log('companylist', returnData);
      if (!returnData.isFileSelected) {
        const companyList = returnData.mapdata;
        console.log('companies data', companyList);
        await this.vendorGeoMappingService.unmapCompanies(companyList);
        await this.getLocations(this.merchantId);
        // Swal.fire('', 'Removes companies successfully', 'success');
      }
      if (returnData.isFileSelected) {
        const geoJson = returnData.mapdata;
        const vendorId = returnData.vendorId;
        const locationId = returnData.locationId;
        console.log('location id', locationId);
        console.log('vendorId', vendorId);
        console.log('geodata', geoJson);

        await this.getSingleLocationData(geoJson, vendorId, locationId);

        return;
      }
      console.log('selected companies response', returnData);
    });
  }

  mapCustomersForExcelExport(customers: Array<any>) {
    return customers.map((customer) => {
      return {
        'Vendor business name': customer.vendorBusinessName,
        'Vendor ID': customer.vendorId,
        'Customer id': customer.customerId,
        'Business name': customer.businessName,
        'Territory/area code': customer.areaCode,
        'Customer number': customer.mobileNumber,
        'Customer name': customer.name,
        'Registered date': customer.registeredDate,
        Latitude: customer.latitude,
        Longitude: customer.longitude,
        'Physical Address': customer.address,
      };
    });
  }
  private jsonToXcel(items: Array<any>) {
    let sheetName = `Customers-Under-${this.dataSource.mobileNumber}`;
    var workBook = XLSX.utils.book_new();
    var worksheet = XLSX.utils.json_to_sheet(items);
    this.autofitColumns(worksheet);
    XLSX.utils.book_append_sheet(workBook, worksheet, sheetName);

    var outputFileName = `${sheetName}.xlsx`;
    XLSX.writeFile(workBook, outputFileName);
  }

  private autofitColumns(worksheet: any) {
    let objectMaxLength: any = [
      { width: 25 },
      { width: 20 },
      { width: 20 },
      { width: 25 },
      { width: 12 },
      { width: 12 },
      { width: 20 },
      { width: 12 },
      { width: 18 },
      { width: 18 },
      { width: 30 },
    ];
    worksheet['!cols'] = objectMaxLength;
  }

  async getSingleLocationData(geoJson: any, vendorId: any, locationId: any) {
    console.log('getsingle location data method');

    this.singlelocation = this.locationsList.filter(
      (location: any) => location.locationId === locationId
    );
    console.log('single location', this.singlelocation);

    const companiesList: Array<any> = [].concat.apply(
      [],
      this.singlelocation.map((l) => l.companyNames)
    );
    console.log('companies of selected location', companiesList);

    const locationMappedCompaniesList: Array<string> = [].concat.apply(
      [],
      companiesList.filter(Boolean)
    );
    console.log('locationmappedcompanieslist', locationMappedCompaniesList);
    if (locationMappedCompaniesList.length === 0) {
      await this.removeCompanies(locationId, geoJson);
    } else {
      const result = await Swal.fire({
        title:
          'All Companies mapped to the location will be <br> removed, do you wish to proceed?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      });

      if (!result.isConfirmed) {
        return;
      }

      await this.removeCompanies(locationId, geoJson);
    }
  }

  async removeCompanies(locationId: any, geoJson: any) {
    try {
      console.log('remove companies methods');

      await this.saveGeoJsonFile(locationId, geoJson);
    } catch (error) {
      throw error;
    }
  }

  async saveGeoJsonFile(locationId: any, geoJson: any) {
    console.log('save geo json dataaaa', geoJson);

    try {
      if (Object.keys(geoJson).length > 0) {
        await this.vendorGeoMappingService.updateLocation(
          locationId,
          this.merchantId,
          geoJson
        );
        //user activity log
        await this.getLocations(this.merchantId);

        Swal.fire(
          'Uploaded!',
          'Uploaded Geo JSON and Companies Removed Successfully',
          'success'
        );
      }
    } catch (error: any) {
      console.log('error::: ', error);
      let message: string =
        'Something went wrong while processing your request.Please try again after some time.';
      if (error.status === 400) {
        const responce = await error.json();
        message = responce.message || '';
      }

      Swal.fire('', message, 'warning');
    }
  }
}
