<form [formGroup]="filterForm">
  <div class="d-flex align-items-center justify-content-between" >
    <h1 mat-dialog-title>Filter </h1>
    <button mat-button (click)="close()" 
      class="mx-2"  mat-button color="warn">
      <mat-icon >close</mat-icon>
    </button></div>
<div mat-dialog-content>
 <div class="container">
  <div class="row">
    <div class="col-md-12">
      <mat-form-field class="example-full-width">
        <mat-label>Search by Name</mat-label>
        <input matInput formControlName="name" />
      </mat-form-field>
    </div>
  </div>
 </div>
  </div>
<div mat-dialog-actions class="d-flex justify-content-end  ">
  <button mat-button (click)="reset()" 
    class="mx-2"  mat-raised-button color="warn">Reset</button>
  <button mat-button  cdkFocusInitial [disabled]="filterForm.invalid"
  (click)="submit()"
   mat-raised-button color="primary">Apply</button>
</div>
</form>