<!-- <mat-card class="p-3 my-2">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 d-flex mb-3">
        <div class="me-3">
          <mat-icon role="button"
          (click)="back()">keyboard_backspace</mat-icon>
        </div>
        <h3 class="title">Add SKU</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <label>Product Name</label>
        <p> {{productDetails?.name}}</p>
      </div>
      <div class="col-md-3">
        <label>Brand Name</label>
        <p> {{productDetails?.brands_name}}</p>
      </div>
      <div class="col-md-3">
        <label>Company Name</label>
        <p> {{productDetails?.companies_name}}</p>
      </div>
      <div class="col-md-3">
        <label>Categories Name</label>
        <p> {{productDetails?.categories_name}}</p>
      </div>

    </div>
  </div>
</mat-card> -->
@if (comment) {
<div class="row">
  <div class="alert alert-warning" role="alert">
    Your request is rejected :
    <span style="color: red;">{{ comment }}</span>
  </div>

</div>
}
<router-outlet></router-outlet>
