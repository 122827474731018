@if(hasView){
<div class="row">
  <div class="col-md-6">
    <mat-icon class="backicon" [routerLink]="['/dashboard/pricing']">arrow_back</mat-icon>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="col-md-10">
        <h4 class="skuNameCss">
          SKU Name :
          <span style="color: green;">{{ skuName}}</span>
        </h4>
      </div>
      <button style="float: right;" mat-raised-button color="primary" class="mx-2 nowrap"
        (click)="changeStatus(this.pricingDetails.skuDetails.vendor_sku_id,this.pricingDetails.skuDetails.status)">
        {{statusText}}</button>
    </div>
  </div>

  <div class="row">
    <div class="m-2">
      <div class="row formcss">
        <mat-card class="m-15">
          <div class="row" style="margin-bottom: 0px;">
            <div class="col-md-12">
              <h2 class="card-title m-3" style="display: inline-block;">Stock</h2>

              <p class="m-3" style="float: right;">Default Pieces per case : <span
                  class="card-title">{{skuPrice.value.upc}}</span></p>
            </div>

          </div>
          <form [formGroup]="stockDetails" class="m-3 formcss">
            <div class="row">
              <div class="col-md-4">
                <div class="lableclass">
                  <mat-label>Available Css</mat-label>
                </div>
                <mat-form-field appearance="outline" class="w-100" *ngIf="skuPrice.value.upc != 0">
                  <input matInput type="number" formControlName="availableCss" name="availableCss"
                    placeholder="Available Css" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-100" *ngIf="skuPrice.value.upc == 0">
                  <input matInput type="number" formControlName="availableCss" name="availableCss" [readonly]="true"
                    [attr.tabindex]="-1" placeholder="Available Css" />
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <div class="lableclass">
                  <mat-label>Available Pcs</mat-label>
                </div>
                <mat-form-field appearance="outline" class="w-100">
                  <input matInput type="number" formControlName="availablePcs" name="availablePcs"
                    placeholder="Available Pcs" />
                </mat-form-field>
              </div>

              <div class="col-md-4">
                <div class="lableclass">
                  <mat-label>Total Available Pcs</mat-label>
                </div>
                <mat-form-field appearance="outline" class="w-100">
                  <input matInput type="number" [readonly]="true" formControlName="totalAvailableStock"
                    name="totalAvailableStock" placeholder="Total Available Pcs" [attr.tabindex]="-1" />
                </mat-form-field>
              </div>

              <div class="col-md-4" *ngIf="[5].includes(pricingDetails.vendorDetails.businessTypeId)">
                <div class="lableclass">
                  <mat-label>Damage Css</mat-label>
                </div>
                <mat-form-field appearance="outline" class="w-100" *ngIf="skuPrice.value.upc != 0">
                  <input matInput type="number" formControlName="damageCss" name="damageCss" placeholder="Damage Css" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-100" *ngIf="skuPrice.value.upc == 0">
                  <input matInput type="number" formControlName="damageCss" name="damageCss" [readonly]="true"
                    [attr.tabindex]="-1" placeholder="Damage Css" />
                </mat-form-field>
              </div>
              <div class="col-md-4" *ngIf="[5].includes(pricingDetails.vendorDetails.businessTypeId)">
                <div class="lableclass">
                  <mat-label>Damage Pcs</mat-label>
                </div>
                <mat-form-field appearance="outline" class="w-100">
                  <input matInput type="number" formControlName="damagePcs" name="damagePcs" placeholder="Damage Pcs" />
                </mat-form-field>
              </div>
              <div class="col-md-4" *ngIf="[5].includes(pricingDetails.vendorDetails.businessTypeId)">
                <div class="lableclass">
                  <mat-label>Total Damage Pcs</mat-label>
                </div>
                <mat-form-field appearance="outline" class="w-100">
                  <input matInput type="number" [readonly]="true" formControlName="totalDamagePcs" name="totalDamagePcs"
                    placeholder="Total Damage Pcs" [attr.tabindex]="-1" />
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-12 updatebtn">
              <button mat-raised-button type="button" color="primary" (click)="updateStock(stockDetails.value)"
                [disabled]="!enableStockUpdate">
                Update Stock
              </button>
            </div>
          </form>
        </mat-card>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="m-2">

      <div class="row formcss">
        <mat-card class="m-15">
          <div class="row" style="margin-bottom: 0px;">
            <div class="col-md-6">
              <h2 class="card-title m-3">Add Pricing</h2>
            </div>

          </div>
          <form [formGroup]="skuPrice" class="m-3 formcss">

            <div class="row">
              <div class="col-md-6">
                <div class="lableclass">
                  <mat-label>MRP</mat-label>
                </div>
                <mat-form-field appearance="outline" class="w-100">
                  <input matInput type="number" formControlName="maximum_retail_price" name="maximum_retail_price"
                    placeholder="MRP" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <div class="lableclass">
                  <mat-label>Selling Price</mat-label>
                </div>
                <mat-form-field appearance="outline" class="w-100">
                  <input matInput type="number" formControlName="selling_price" name="selling_price"
                    placeholder="Selling Price" />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <label class="errorMessage" *ngIf="isMrpOrSpNull">Maximum Retail Price (MRP) and Selling Price should not
                be null or 0.</label>
              <label class="errorMessage" *ngIf="shouldBeNumbers">Values should not have Negative or Special Characters
              </label>
              <label class="errorMessage" *ngIf="isSpGreaterMrp">Selling Price should not be higher than Maximum Retail
                Price (MRP).</label>
            </div>


            <div class="col-md-12 updatebtn">
              <button mat-raised-button type="button" color="primary" [disabled]="!enablePricingUpdate"
                (click)="savePricing()">
                Update Price
              </button>
            </div>
          </form>
        </mat-card>
      </div>

      <div class="col-md-12" *ngIf="[1,2,3,5].includes(pricingDetails.vendorDetails.businessTypeId)">
        <div class="row">
          <div class="col-md-6 ml-5">
            <h2 class="hed card-title"> Price Discount Slabs</h2>
          </div>
          <div class="col-md-6 text-right" *ngIf="hasCreate">
            <button mat-raised-button type=" button" color="primary" (click)="openDialog()"
              [disabled]="!enablePricingUpdate">
              Add Price Rule
            </button>
          </div>

        </div>
        <div class="m-3 flex flex-col md:flex-row justify-between "
          [@animate]="{value:'*',params:{y:'50px',delay:'300ms'}}" style="clear: both;">
          <mat-card class="p-0">
            <mat-card-content class="p-0">
              <div class="table-container">
                <mat-table [dataSource]="dataSource" matSort>

                  <ng-container matColumnDef="minQty">
                    <mat-header-cell *matHeaderCellDef> Min Qty </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="minQty">
                      {{row.min_quantity}}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="maxQty">
                    <mat-header-cell *matHeaderCellDef> Max Qty </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="maxQty"> {{row.max_quantity}}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="discountType">
                    <mat-header-cell *matHeaderCellDef> Discount Type </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="discountType">
                      {{ getDiscountTypeText(row.pricing_type) }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="value">
                    <mat-header-cell *matHeaderCellDef> Unit Price
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="value">
                      {{row.pricing_rate | number: '1.2-2' }} </mat-cell>
                  </ng-container>


                  <ng-container matColumnDef="edit">
                    <mat-header-cell *matHeaderCellDef>Edit</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      <button mat-icon-button (click)="openDialog(row,true)" *ngIf="hasEdit"
                        [disabled]="!enablePricingUpdate">
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button mat-icon-button (click)="deletePricingRule(row)" *ngIf="hasEdit"
                        [disabled]="!enablePricingUpdate">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

                </mat-table>
              </div>
            </mat-card-content>
          </mat-card>

        </div>



      </div>
    </div>
  </div>
</div>

}@else{
<ng-container>
  <p class="text-center">
    You don't have permission , Please contact administrator
  </p>
</ng-container>
}