<ng-container>
  <div class="p-3">
    <div class="row">
      <div class="col-md-6">
        <h2 class="card-title m-3 text-violet"> </h2>
      </div>
      <div class="col-md-6 text-right">
        <mat-icon (click)="dialogRef.close()" class="float-right m-3 closeicon">
          close
        </mat-icon>
      </div>
    </div>
    <div class="mat-elevation-z8">
      <form [formGroup]="dataSource" class="m-3 formcss">
        <div class="table-container p-2">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                    <mat-form-field class="full-width">
                      <mat-label>Margin</mat-label>
                      <input type="number" matInput formControlName="margin" name="margin" placeholder="Margin" />
                    </mat-form-field>
                </div>
              </div>
            </div>
          <div class="col-md-12 custom-flex">
            <div class="text-center mt-2 flex-item ml-10">
              <button mat-raised-button color="primary" class="text-center ml" (click)="updateMargin()">
                Save
              </button>
            </div>
            <div class="text-center mt-2 flex-item">
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>
