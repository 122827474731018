import { Component } from '@angular/core';
import {
  FormBuilder,
  Validators,
  ReactiveFormsModule,
  FormsModule,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { IncentiveSlabsService } from '../../services/incentiveslabs.service';
import {
  IncentiveSlabEntity,
  IncentiveSlabForm,
  PropertyValidation,
} from '../../models/incentiveslabs.model';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AppLoaderService } from '../../../../shared/app-loader/app-loader.service';
import { AuthService } from '../../../../services/auth.service';
import { SwalMessageTypes, SupplierStatus } from '../../../../enums/enums';
import Swal from 'sweetalert2';
import {
  applyMaxLengthValidation,
  hasSpecialCharacters,
  validateMaxText,
  validateNegativeValues,
} from '../../../../utlity/utility';
import uuid4 from 'uuid4';
import { ConfigService } from '../../../config/services/config.service';
import { BaseListComponent } from '../../../../shared/core/base.list.component';
import { PageId } from '../../../../constants/enums';

@Component({
  selector: 'app-create-incentiveslab',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    RouterLink,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatCheckboxModule,
  ],
  templateUrl: './create-incentiveslab.component.html',
  styleUrls: ['./create-incentiveslab.component.scss'],
})
export class CreateIncentiveSlabComponent extends BaseListComponent {
  permission: any = false;
  dataSource: any;
  incentivesSlabEntity!: IncentiveSlabEntity;

  constructor(
    private fb: FormBuilder,
    router: Router,
    route: ActivatedRoute,
    private incentiveSlabsService: IncentiveSlabsService,
    private loader: AppLoaderService,
    auth: AuthService,
  ) {
    super(auth, router, route, PageId.bms_incentives);

    this.dataSource = this.fb.group({
      incentiveSlabId: [null],
      incentiveSlabName: [null, [Validators.required]],
      visitsCountMin: [null, [Validators.required]],
      visitsCountMax: [null, [Validators.required]],
      dayRevenuePercentMin: [null, [Validators.required]],
      dayRevenuePercentMax: [null, [Validators.required]],
      dayTasksPercentMin: [null, [Validators.required]],
      dayTasksPercentMax: [null, [Validators.required]],
      buyingCountPercentMin: [null, [Validators.required]],
      buyingCountPercentMax: [null, [Validators.required]],
      nonBuyingCountPercentMin: [null, [Validators.required]],
      nonBuyingCountPercentMax: [null, [Validators.required]],
      incentiveBuyingOrder: [null, [Validators.required]],
      incentiveNonBuyingOrder: [null, [Validators.required]],
      petrolAllowance: [null, [Validators.required]],
      isActive: [true, [Validators.required]],
    });
    this.fixMaxLength();

  }

  override async ngOnInit() {
    await super.ngOnInit();
  }
  async saveIncentiveSlab() {
    this.loader.open();

    try {
      if (this.dataSource.invalid) {
        this.showMessage(
          'Please fill all required fields.',
          SwalMessageTypes.Warning
        );
        return;
      }

      if (!this.validation()) {

        return
      }




      const negativeFields = this.validateNegativeValues(this.dataSource.value);
      if (negativeFields.length) {
        this.showMessage(
          `${negativeFields} ,have negative values Please Check`,
          SwalMessageTypes.Warning
        );
        return;
      }

      await this.createIncentiveSlab();
    } catch (error) {
      console.error('Error occurred during incentive slab creation:', error);
      this.showMessage(
        'Failed to create incentive slab. Please try again later.',
        SwalMessageTypes.Error
      );
    } finally {
      this.loader.close();
    }
  }

  private hasOnlyEmptySpaces(str: string) {
    return /^\s*$/.test(str);
  }

  private showMessage(message: string, msgType: any) {
    Swal.fire('', message, msgType);
  }

  private mapIncentiveSlab(
    incentivesSlab: IncentiveSlabForm
  ): IncentiveSlabEntity {
    return {
      incentiveSlabId: uuid4(),
      incentiveSlabName: incentivesSlab.incentiveSlabName,
      visitsCountMin: incentivesSlab.visitsCountMin,
      visitsCountMax: incentivesSlab.visitsCountMax,
      dayRevenuePercentMin: incentivesSlab.dayRevenuePercentMin,
      dayRevenuePercentMax: incentivesSlab.dayRevenuePercentMax,
      dayTasksPercentMin: incentivesSlab.dayTasksPercentMin,
      dayTasksPercentMax: incentivesSlab.dayTasksPercentMax,
      buyingCountPercentMin: incentivesSlab.buyingCountPercentMin,
      buyingCountPercentMax: incentivesSlab.nonBuyingCountPercentMax,
      nonBuyingCountPercentMin: incentivesSlab.nonBuyingCountPercentMin,
      nonBuyingCountPercentMax: incentivesSlab.nonBuyingCountPercentMax,
      incentiveBuyingOrder: +incentivesSlab.incentiveBuyingOrder,
      incentiveNonBuyingOrder: +incentivesSlab.incentiveNonBuyingOrder,
      petrolAllowance: incentivesSlab.petrolAllowance,
      isActive: incentivesSlab.isActive == true ? 1 : 0,
      createdDate: new Date(),
      updatedDate: new Date(),
      createdBy: 'Sales Admin',
      updatedBy: 'Sales Admin',
    };
  }

  async createIncentiveSlab() {
    const incentiveSlabEntity = this.mapIncentiveSlab(this.dataSource.value);
    console.log('incentiveSlabEntity :', incentiveSlabEntity);
    try {
      const response = await this.incentiveSlabsService.createIncentiveSlab(
        incentiveSlabEntity
      );
      this.showMessage(
        'IncentiveSlab created successfully',
        SwalMessageTypes.Success
      );
      this.router.navigateByUrl('/dashboard/incentives/incentives-list');
    } catch (error) {
      console.error('Error occurred while creating incentive slab:', error);
      this.showMessage(
        'Failed to create incentive slab. Please try again later.',
        SwalMessageTypes.Error
      );
    }
  }

  // private Functions
  async validateInput() {
    const negativeFields = this.validateNegativeValues(this.dataSource.value);
    if (negativeFields.length) {
      this.showMessage(
        `${negativeFields} ,have negative values Please Check`,
        SwalMessageTypes.Warning
      );

      return false;
    }

    return true;
  }

  private validateNegativeValues(data: IncentiveSlabForm): string[] {
    const numericInputs = [
      { name: 'Visits Count Min', value: data.visitsCountMin },
      { name: 'Visits Count Max', value: data.visitsCountMax },
      { name: 'Day Tasks Percent Min', value: data.dayTasksPercentMin },
      { name: 'Petrol Allowance', value: data.petrolAllowance },
      { name: 'Day Revenue Percent Min', value: data.dayRevenuePercentMin },
      { name: 'Day Revenue Percent Max', value: data.dayRevenuePercentMax },
      { name: 'Day Tasks Percent Max', value: data.dayTasksPercentMax },
      { name: 'Buying Count Percent Min', value: data.buyingCountPercentMin },
      { name: 'Buying Count Percent Max', value: data.buyingCountPercentMax },
      {
        name: 'Non Buying Count Percent Min',
        value: data.nonBuyingCountPercentMin,
      },
      {
        name: 'Non Buying Count Percent Max',
        value: +data.nonBuyingCountPercentMax,
      },
      { name: 'Incentive Buying Order', value: +data.incentiveBuyingOrder },
      {
        name: 'Incentive Non Buying Order',
        value: +data.incentiveNonBuyingOrder,
      },
    ];

    const negativeFields = numericInputs
      .filter((input) => input.value < 0)
      .map((input) => input.name);

    return negativeFields;
  }


  private validation(): boolean {
    if (this.hasOnlyEmptySpaces(this.dataSource.value.incentiveSlabName)) {
      this.showMessage(
        `You entered only empty space`,
        SwalMessageTypes.Warning
      );

      return false;
    }

    if (this.dataSource.value.visitsCountMin > this.dataSource.value.visitsCountMax) {
      this.showMessage(
        `min qty should be less than max qty`,
        SwalMessageTypes.Warning
      );

      return false;
    }

    if (this.hasDecimal(this.dataSource.value.visitsCountMin)) {
      this.showMessage(
        `Visits Count Min should not have decimal value`,
        SwalMessageTypes.Warning
      );
      return false
    }
    if (this.hasDecimal(this.dataSource.value.visitsCountMax)) {
      this.showMessage(
        `Visits Count Max should not have decimal value`,
        SwalMessageTypes.Warning
      );
      return false
    }

    if (this.hasDecimal(this.dataSource.value.petrolAllowance)) {
      this.showMessage(
        `petrol should not have decimal value`,
        SwalMessageTypes.Warning
      );
      return false
    }

    return true;
  }

  hasDecimal(number: number) {
    return /\./.test(number.toString());
  }

  private fixMaxLength() {
    applyMaxLengthValidation(this.dataSource, [
      { property: "visitsCountMin", maxLength: 6 },
      { property: "visitsCountMax", maxLength: 6 },
      { property: "dayRevenuePercentMin", maxLength: 6 },
      { property: "dayRevenuePercentMax", maxLength: 6 },
      { property: "dayTasksPercentMin", maxLength: 6 },
      { property: "dayTasksPercentMax", maxLength: 6 },
      { property: "buyingCountPercentMin", maxLength: 6 },
      { property: "buyingCountPercentMax", maxLength: 6 },
      { property: "nonBuyingCountPercentMin", maxLength: 6 },
      { property: "nonBuyingCountPercentMax", maxLength: 6 },
      { property: "incentiveBuyingOrder", maxLength: 6 },
      { property: "incentiveNonBuyingOrder", maxLength: 6 },
      { property: "petrolAllowance", maxLength: 6 },
    ]);
  }



}
