import { Component } from '@angular/core';
import { Auth, authState } from '@angular/fire/auth';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-auth',
  standalone: true,
  imports: [],
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.scss',
})
export class AuthComponent {
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public auth: Auth
  ) {
    authState(this.auth).subscribe((user) => {
      if (user) {
        this.router.navigateByUrl('/dashboard/order');
      } else {
        if (this.route.snapshot.queryParams['idToken']) {
          this.signin(this.route.snapshot.queryParams['idToken']);
        } else {
          window.open(
            `${environment.ssoUrl}/login?redirectto=http://${window.location.host}/pro-ops-revamp/sessions/signin`,
            '_self'
          );
        }
      }
    });
  }

  async signin(token: any) {
    try {
      await this.authService.signinWithToken(token);
      this.router.navigateByUrl('/dashboard');
    } catch (error) {
      console.log('Signin Error:', error);
    }
  }

  async signout() {
    try {
      await this.authService.signOut();
      localStorage.clear();
      this.router.navigate(['/signin']);
    } catch (error) {
      console.log('Signout ::: ', error);
    }
  }
}
