import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MappedTrip } from '../entities/trip-info';
import { DB_HOP } from '../entities/hop-db.model';
import { OrderFilters } from '../models/order';
import { MultiLocalStorageService } from './multi-local-storage.service';

const storageKeys = {
  tripListFilters: 'tripListFilters',
  pricingFilters: 'pricingFilters',
  promotionsFilters: 'promotionsFilters',
  purchaseReceiptFilters: 'purchaseReceiptFilters',
  adminOrdersFilter: 'adminOrdersFilter',
};

@Injectable({
  providedIn: 'root', // Consider changing this based on your service usage
})
export class AppStateService {
  private localStorageService: MultiLocalStorageService;
  private selectedTripSubject = new BehaviorSubject<MappedTrip | null>(null);
  selectedTrip$: Observable<MappedTrip | null>;

  private selectedHopSubject = new BehaviorSubject<DB_HOP | null>(null);
  selectedHop$: Observable<DB_HOP | null>;

  private selectedOrderFiltersSubject = new Subject<OrderFilters | null>();
  selectedOrderFilters$: Observable<OrderFilters | null>;

  constructor() {
    this.localStorageService = new MultiLocalStorageService(storageKeys);
    this.selectedTrip$ = this.selectedTripSubject.asObservable();
    this.selectedHop$ = this.selectedHopSubject.asObservable();
    this.selectedOrderFilters$ = this.selectedOrderFiltersSubject.asObservable();
  }

  setSelectedTrip(trip: MappedTrip) {
    this.selectedTripSubject.next(trip);
  }

  setSelectedHop(hop: DB_HOP) {
    this.selectedHopSubject.next(hop);
  }

  setSelectedOrderFilters(orderFilters: OrderFilters) {
    this.selectedOrderFiltersSubject.next(orderFilters);
  }

  setItem<T>(key: string, item: T) {
    this.localStorageService.setItem(key, item);
  }

  getItem<T>(key: string): T | null {
    return this.localStorageService.getItem<T>(key);
  }

  clearAll(): void {
    try {
      localStorage.clear();
      console.log('LocalStorage cleared successfully.');
    } catch (error) {
      console.error('Error clearing localStorage:', error);
    }
  }
}
