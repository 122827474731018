@if(hasCreate){
<section id="content">
  <div class="heading-block">
    <div class="row">
      <div class="col-md-6">
        <mat-icon class="backicon" [routerLink]="['/dashboard/roles']">arrow_back</mat-icon>
        <span class="header">
          <h2 class="card-title m-3"> {{ id ? "Edit Role" : "Create Role" }}</h2>
        </span>
      </div>
      <div class="col-md-6 text-right"></div>
    </div>
  </div>
  <div class="container">
    <div class="card">
      <div class="card-body card-padding">
        <div class="row">
          <div class="col-md-6">
            <mat-form-field class="full-width">
              <input matInput name="name" [(ngModel)]="rolesObj.role_name" placeholder="Role Name" />
              <mat-icon class="required-star" aria-hidden="false" aria-label="Required" matSuffix>
                *
              </mat-icon>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="full-width">
              <mat-label>Select Application</mat-label>
              <mat-select [(ngModel)]="selectedApplication">
                <mat-option *ngFor="let application of applications" [value]="application">{{
                  application
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <table>
              <thead>
                <th>Pages</th>
                <th class="perm">Permission</th>
              </thead>
              <tbody>
                <tr *ngFor="let item of pages | searchFilter : selectedApplication">
                  <td>
                    {{item.page_name}}
                  </td>
                  <td>
                    <div class="d-flex" *ngIf="rolesObj.permissions[item.page_id]">
                      <div class="perm">

                        <input type="checkbox" [(ngModel)]="rolesObj.permissions[item.page_id]['create']" id="create"
                          class="mx-2"> <label for="create">Create</label>
                      </div>
                      <div class="perm">

                        <input type="checkbox" [(ngModel)]="rolesObj.permissions[item.page_id]['view']" id="view"
                          class="mx-2"> <label for="view">View</label>
                      </div>
                      <div class="perm">

                        <input type="checkbox" [(ngModel)]="rolesObj.permissions[item.page_id]['edit']" id="edit"
                          class="mx-2"> <label for="edit">Edit</label>
                      </div>
                      <!--    </div>
        <div class="d-flex"> -->
                      <div class="perm">

                        <input type="checkbox" [(ngModel)]="rolesObj.permissions[item.page_id]['delete']" id="delete"
                          class="mx-2"> <label for="delete">Delete</label>
                      </div>
                      <div class="perm">

                        <input type="checkbox" [(ngModel)]="rolesObj.permissions[item.page_id]['import']" id="import"
                          class="mx-2"> <label for="import">Import</label>
                      </div>
                      <div class="perm">

                        <input type="checkbox" [(ngModel)]="rolesObj.permissions[item.page_id]['export']" id="export"
                          class="mx-2"> <label for="export">Export</label>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-end">
            @if(hasCreate){
            <button mat-raised-button color="primary" (click)="saveRoles()">
              {{id ? 'Update' :'Save'}}
            </button>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
} @else {
<ng-container>
  <p class="text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
}
