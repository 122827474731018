import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDrawer } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { AuthService } from '../../services/auth.service';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { ProductService } from '../../pages/product/services/product.service';
import { first } from 'rxjs';
import { AppStateService } from '../../services/appstate.service';
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    MatButtonModule,
    RouterModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  @Input() sidenav!: MatDrawer;
  data: any;
  notification_count = 0;
  notificationSubscription: any;
  constructor(
    public authService: AuthService,
    public dialog: MatDialog,
    public productService: ProductService,
    public appState: AppStateService
  ) {
    this.authService.getCurrentUser().subscribe(async (res: any) => {
      if (res) {
        this.data = await this.authService.getUserById(res.uid);
        setTimeout(() => {
          this.setNotificationCount();
        }, 3000);
      }
    });
  }

  private setNotificationCount() {
    if (this.authService.isAdmin()) {
      this.authService.getAdminLastRead().subscribe((res) => {
        if (this.notificationSubscription) {
          this.notificationSubscription.unsubscribe();
        }
        if (res) {
          let data: any = res;

          this.notificationSubscription = this.productService
            .getUnreadAdminNotifications(data.last_seen)
            .subscribe((res: any) => {
              this.notification_count = res.length;
            });
        }
      });
    }
    if (this.authService.isVendor()) {
      this.authService.getVendorLastRead().subscribe((res) => {
        if (this.notificationSubscription) {
          this.notificationSubscription.unsubscribe();
        }
        if (res) {
          let data: any = res;
          this.notificationSubscription = this.productService
            .getUnreadVendorNotifications(data.last_seen)
            .subscribe((res: any) => {
              this.notification_count = res.length;
            });
        }
      });
    }
  }

  toggleSidenav(): void {
    this.sidenav.toggle();
  }

  logout() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Logout Confirmation',
        message: 'Are you sure you want to logout?',
      },
      panelClass: 'custom-dialog-class',
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result == 'yes') {
        this.appState.clearAll();
        this.authService.signOut();
      }
    });
  }
}
