import { Component, ElementRef, HostListener, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { CommonModule, formatDate } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AuthService } from '../../../../services/auth.service';
import { Auth, User, authState } from '@angular/fire/auth';
import { BehaviorSubject } from 'rxjs';
import { VendorGeoMappingService } from '../../services/vendor-geo-mapping.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { isValidGeoJsonFile } from '../../../../utlity/utility';
import { AppLoaderService } from '../../../../shared/app-loader/app-loader.service';

@Component({
  selector: 'app-edit-company',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    RouterLink,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatCheckboxModule,
  ],
  templateUrl: './edit-company.component.html',
  styleUrl: './edit-company.component.scss',
})
export class EditCompanyComponent {
  page_id = 'bms_create_suppliers';
  permission: any = false;
  mappedCompanies: any;
  selectedCompanies: any | null;
  selectedFiles: any | null;
  companiesList: any;
  isCompanyUnselected: boolean = false;
  isJsonSelected: boolean = false;
  jsonGeoData: any;
  txtJson: string = '';
  id: any;
  userSubject = new BehaviorSubject<User | null>(null);
  displayedColumns = ['locations', 'company', 'action'];

  constructor(
    public fb: FormBuilder,
    public route: ActivatedRoute,
    public router: Router,
    public auth: AuthService,
    private el: ElementRef,
    public dialogRef: MatDialogRef<EditCompanyComponent>,
    public vendorGeoMappingService: VendorGeoMappingService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  async ngOnInit() {
    console.log('ngOnInit:ngOnInit', this.data);
    const vendorId = this.data.vendorId;
    this.selectedCompanies = this.data.companyIds[0];
    console.log('selected files', this.selectedCompanies);

    await this.getCompanies(vendorId);
    // this.dataSource = ["mtm","hyderabad","vijayawada","gudivada","kphb","nizampet","miyapur","bhel","kolhapur","pune","bangalore"]
  }

  async getCompanies(vendorId: string) {
    try{
    // this.egretLoader.open();
    this.companiesList = await this.vendorGeoMappingService.getCompanies(
      vendorId
    );
    console.log('company list', this.companiesList);

    const mappedCompaniesList = this.companiesList.filter((data: any) =>
      this.selectedCompanies.includes(data.companyId)
    );
    this.mappedCompanies = this.companyeListMap(mappedCompaniesList);
    console.log('mapped companies', this.mappedCompanies);
    // this.egretLoader.close()
    }catch(error){
      // this.egretLoader.close();
    }

  }

  private companyeListMap(res: any) {
    return res.map((obj: any) => {
      return { ...obj, isSelected: true };
    });
  }

  async unmapCompanies() {
    this.isCompanyUnselected = false;
    this.isJsonSelected = false;
    let companiesList = this.mappedCompanies.filter(
      (data: any) => !data.isSelected
    );
    console.log('unmapped companies', companiesList);

    if (companiesList.length != 0 && this.selectedFiles) {
      console.log('selected file', this.jsonGeoData);
      this.isCompanyUnselected = true;
      return;
    }
    if (companiesList.length == 0 && !this.selectedFiles) {
      console.log("Didn't have any changes to save");

      this.isJsonSelected = true;
      return;
    }
    if (companiesList != 0) {
      const geoMapData = this.mapLCM(companiesList);
      const res = {
        mapdata: geoMapData,
        isFileSelected: false,
      };
      this.dialogRef.close(res);
     
    } else if (this.selectedFiles) {
      const res = {
        mapdata: this.jsonGeoData,
        isFileSelected: true,
        vendorId:this.data.vendorRefId,
        locationId:this.data.selectedLocationId
      };
      this.dialogRef.close(res);
    }
  }

  private mapLCM(companiesList: Array<any>) {
    return {
      vendorId: this.data.vendorRefId,
      locationId: this.data.selectedLocationId,
      companies: companiesList.map((data) => data.companyId),
    };
  }

  onSelectFile(evnt: any) {
    const file: any = evnt.target.files[0];
    if (!file) {
      Swal.fire('Warning!', 'Please upload the file!', 'warning');
      this.resetFileInput();
      return;
    }
    if (file && !isValidGeoJsonFile(file.name)) {
      Swal.fire('Warning!', 'Invalid File Format!!', 'warning');
      this.resetFileInput();
      return;
    }

    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (event: ProgressEvent) => {
      try {
        this.txtJson = (event.target as FileReader).result?.toString() || '{}';
        const validateJsonFeatures = JSON.parse(this.txtJson);

        this.jsonGeoData = validateJsonFeatures;
        this.selectedFiles = evnt.target.files;

        console.log('this.selectedFiles ::::', this.selectedFiles);
      } catch {
        Swal.fire('', 'Invalid File', 'error');
      }
    };
  }

  private resetFileInput() {
    this.selectedFiles = null;
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }
}
