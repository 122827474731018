import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SettingService } from '../services/settings.service';
import { removeEndSpaces } from '../../../utlity/utility';
import { CdkTableDataSourceInput } from '@angular/cdk/table';
import { QueryFilter, UserType } from '../../../enums/enums';
import { FirebaseDbService } from '../../../services/firebase-db.service';
import Swal from 'sweetalert2';

interface VendorSearch {
  id: number;
  refId: string;
  name: string;
  mobileNumber: string;
  businessName: string;
  createdBy: string;
  updatedBy: string | null; // Assuming it could be null
}

@Component({
  selector: 'vendor-search',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatButtonModule,
    RouterOutlet,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatCardModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    CommonModule,
    MatTableModule,
    RouterLink,
    ReactiveFormsModule,
  ],

  templateUrl: './vendor-search.component.html',
  styleUrl: './vendor-search.component.scss',
})
export class VendorSearchComponent {
  searchForm: FormGroup;
  merchantList: VendorSearch[] = [];
  searchedMerchants: Array<VendorSearch> = [];
  displayedColumns: string[] = ['id', 'name', 'mobileNumber', 'businessName'];
  constructor(
    private fb: FormBuilder,
    private settingService: SettingService,
    private router: Router,
    private firebaseDbService: FirebaseDbService
  ) {
    this.searchForm = this.fb.group({
      nameLowerCase: [null, [Validators.pattern(/^[a-zA-Z\s]+$/)]],
      mobileNumber: [null, [Validators.pattern(/^[6-9]\d{9}$/)]],
    });
  }

  ngOnInit(): void {


  }



  searchMerchant() {

    console.log("this.searchForm.value:", this.searchForm.value)
    if (!this.hasSearchFields()) {
      this.searchedMerchants = [];
      Swal.fire('', "At least one field value is required", 'info');
      return;
    }
    if (this.searchForm.valid) {
      console.log("this.searchForm.value:    valid input")

      this.merchantsByQuery();
    }

  }

  async merchantsByQuery() {
    const queryFilters = this.constructQueryParams();
    console.log("queryFiltersqueryFilters::", queryFilters)
    const result = await this.firebaseDbService.usersQuery(queryFilters);
    console.log("merchantsByQuery::result:", result)
    if (result.length === 0) {
      Swal.fire('', "No Records Found", 'info');
    }
    this.searchedMerchants = result;
  }

  private constructQueryParams(): QueryFilter[] {
    return Object.entries(this.searchForm.value)
      .filter(([property, value]) => this.shouldIncludeInQuery(property, value))
      .map(([property, value]) => this.createQueryFilter(property, value));
  }

  private shouldIncludeInQuery(property: string, value: any): boolean {
    return value !== undefined && value !== null && value !== '';
  }

  private createQueryFilter(property: string, value: any): QueryFilter {
    if (property === 'id') {
      return new QueryFilter(property, value);
    }
    return new QueryFilter(property, this.transformValue(value));
  }

  private transformValue(value: any): string {
    return value ? (typeof value === 'string' ? value.toLowerCase() : value) : '';
  }


  private hasSearchFields(): boolean {
    const { nameLowerCase, mobileNumber } = this.searchForm.value;
    return nameLowerCase || mobileNumber;
  }


  navigateToVendorList(merchantId: number) {

    const selectedMerchant = this.searchedMerchants.find(
      (merchant) => merchant.id === merchantId
    );
    if (selectedMerchant) {
      this.router.navigate(['/dashboard/settings/vendor-list'], {
        state: { merchantDetails: selectedMerchant },
      });
    }
  }



}
