import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import Swal from 'sweetalert2';
import { MappedOrderItemResponse, ProductViewProductDetails } from '../../../models/order';
import { isEmptyValue } from '../../../utlity/utility';
export interface DialogData {
  title: string;
  dialogInput: ProductViewProductDetails;
}
@Component({
  selector: 'app-nauka-orders-popup',
  standalone: true,
  imports: [
    MatAutocompleteModule,
    MatDialogModule,
    MatFormFieldModule,
    MatButtonModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatCardModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    MatIconModule,
  ],
  templateUrl: './nauka-orders-popup.component.html',
  styleUrl: './nauka-orders-popup.component.scss'
})
export class NaukaOrdersPopupComponent {
  dataSource: any;
  availableQty: number = 0

  constructor(
    public dialogRef: MatDialogRef<NaukaOrdersPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public fb: FormBuilder,

  ) {
    console.log("data.dialogInput:::", data.dialogInput);
    this.availableQty = data?.dialogInput?.availableQty
    this.dataSource = this.skuDetails(data.dialogInput);
  }

  ngOnInit() {

  }


  private skuDetails(skuDetails: any): FormGroup {
    return this.fb.group({
      skuId: [skuDetails.skuId || ''],
      skuName: [{ value: skuDetails.skuName || '', disabled: true }],
      totalQuantity: [{ value: skuDetails.availableQty || '', disabled: true }],
      invoiceQty: [{ value: skuDetails.invoiceQty || '', disabled: true }],
      availableQuantity: [skuDetails.availableQty || ''],
      mrp: [skuDetails.mrp || ''],
      sellingPrice: [skuDetails.sellingPrice || ''],
      reason: [null]
    });
  }

  submitResult() {
    const totalQuantity = this.dataSource.get("totalQuantity")?.value || "";
    const availableQuantity = this.dataSource.get("availableQuantity")?.value || "";
    const reason = this.dataSource.get("reason")?.value || "";

    if (+availableQuantity < 0) {
      Swal.fire('', "Please enter valid quantity!", 'warning');
      return;
    }

    if (availableQuantity > totalQuantity) {
      Swal.fire('', "Available quantity can not be greater than ordered quantity!", 'warning');
      return;
    }

    if (availableQuantity > this.availableQty) {
      Swal.fire('', "Available Quantity should be less than Ordered Quantity", 'warning');
      return;
    }


    if (isEmptyValue(reason)) {
      Swal.fire('', "Please Enter the reason!", 'warning');
      return;
    }
    // if (+availableQuantity === 0) {

    //   this.dialogRef.close(this.dataSource.value);
    //   return;
    // }
    this.dialogRef.close(availableQuantity);
  }
}
