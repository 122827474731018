import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  OnChanges,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import {
  LocationEntity,
  LocationListSearch,
} from '../../models/locations.model';
import { LocationsService } from '../../services/locations.service';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import {
  MatPaginator,
  MatPaginatorModule,
  PageEvent,
} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSidenavModule } from '@angular/material/sidenav';

import Swal from 'sweetalert2';
import { AppLoaderService } from '../../../../../shared/app-loader/app-loader.service';
import { AuthService } from '../../../../../services/auth.service';
import { SwalMessageTypes, SupplierStatus } from '../../../../../enums/enums';
import { BaseListComponent } from '../../../../../shared/core/base.list.component';
import { PageId } from '../../../../../constants/enums';

@Component({
  selector: 'app-locations-list',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    RouterLink,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSidenavModule,
  ],
  templateUrl: './locations-list.component.html',
  styleUrls: ['./locations-list.component.scss'],
})
export class LocationListComponent extends BaseListComponent {
  singularTypes = Object.values(SupplierStatus).filter(
    (value) => typeof value === 'number'
  );

  locations: LocationEntity[] = [];
  readonly DEFAULT_PAGE_SIZE = 25;
  override pageNumber: number = 1;
  // override pageSize = this.DEFAULT_PAGE_SIZE;
  // override pageSizeOptions: number[] = [5, 10, 25, 50];
  totalLocations: number = 0;
  form!: FormGroup;
  locationListSearchInput: LocationListSearch = this.initializeSearchInput();
  displayedColumns = ['name', 'edit', 'delete'];
  allLocations: any[] = [];

  dataSource!: MatTableDataSource<LocationEntity>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    auth: AuthService,
    router: Router,
    private fb: FormBuilder,
    route: ActivatedRoute,
    public readonly locationService: LocationsService,
    private loader: AppLoaderService
  ) {
    super(auth, router, route, PageId.logistics_location);
  }
  override async ngOnInit() {
    await super.ngOnInit();
    await this.getLocations();
  }

  async getLocations() {
    try {
      this.loader.open();

      const response = await this.locationService.getAllLocations();
      if (response && response.success) {
        this.allLocations = response.data.map((location: any) => {
          return {
            id: location.id,
            name: location.name,
          };
        });

        if (this.allLocations.length === 0) {
          this.loader.close();
          Swal.fire('', 'No data found.', 'warning');
          return;
        }

        this.totalLocations = this.allLocations.length;
        this.setPageData();
        this.loader.close();
      } else {
        this.showMessage(
          'Failed to Fetch Locations. Please try again later.',
          SwalMessageTypes.Error
        );
      }
    } catch (error) {
      this.loader.close();
      this.showMessage(
        'Failed to Fetch Locations. Please try again later.',
        SwalMessageTypes.Error
      );
    }
  }
  setPageData() {
    const startIndex = (this.pageNumber - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.locations = this.allLocations.slice(startIndex, endIndex);
    this.dataSource = new MatTableDataSource(this.locations);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  override async onPageChange(event: any) {
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.setPageData();
  }

  async deleteLocation(row: any) {
    try {
      const result = await Swal.fire({
        title: 'Do you want to Delete Location?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      });

      if (!result.isConfirmed) {
        return;
      }
      // await this.locationService.deleteLocation(row.id);
      // await this.clearFilters();
      this.showMessage(
        `Don't have permission to delete Contact Admin team.`,
        SwalMessageTypes.Warning
      );
    } catch (error) {
      console.error('Error occurred while creating location:');
      this.showMessage(
        'Failed to Delete Location. Please try again later.',
        SwalMessageTypes.Error
      );
    }
  }

  editLocation(row: any) {
    this.router.navigateByUrl(`/dashboard/logistics/edit-location/${row.id}`);
  }

  private initializeSearchInput(): LocationListSearch {
    return {
      pageNumber: 1,
      pageSize: 10,
    };
  }

  async searchTasks() {
    const { locationName, type, status } = this.form.value;
    this.pageNumber = 1;
    this.paginator.pageIndex = 0;
    if (locationName || type || status !== undefined) {
      this.locationListSearchInput = this.createLocationListSearchInput(
        locationName,
        type,
        status
      );
      await this.getLocations();
    }
  }

  private createLocationListSearchInput(
    locationName: string,
    type: any,
    status: any
  ): LocationListSearch {
    return {
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
    };
  }

  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  async clearFilters() {
    this.searchForm();
    this.locationListSearchInput = this.initializeSearchInput();
    await this.getLocations();
  }

  private searchForm() {
    this.form = this.fb.group({
      supplierName: '',
      type: null,
      status: null,
    });
  }
}
