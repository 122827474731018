@if(hasView){
<div class="container-fluid p-2">
  <mat-card class="p-3">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">
          <h3 class="title">Purchase Order List</h3>
        </div>
        <div class="col-md-6 right-list">

          <!-- <button mat-raised-button (click)="openSearch()" color="primary" class="mat-small me-2 mb-2">
            <mat-icon>search</mat-icon>
          </button> -->
          @if(hasCreate && hasProcurement){
          <button mat-raised-button type="button" class="mat-small me-2 mb-2" (click)="navigateAddPR()" color="primary">
            Add PO
          </button>
          }
        </div>
      </div>
    </div>
    <div class="search-block">
      <div class="mat-elevation-z8">
        <form [formGroup]="form">
          <section id="content">
            <div class="card">
              <div class="card-body card-padding">
                <div class="row">
                  <div class="col-md-4">
                    <mat-form-field class="full-width">
                      <mat-label>Supplier</mat-label>
                      <mat-select formControlName="selectedSupplier">
                        <input matInput [formControl]="supplierSearchControl" placeholder="Search Supplier"
                          class="editableSearch" />
                        <mat-option [value]="" *ngIf="!supplierSearchResults.length">
                          No Result Found
                        </mat-option>
                        <mat-option *ngFor="let supplier of supplierSearchResults" [value]="supplier">
                          {{ supplier.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field class="full-width">
                      <mat-label>PR Number</mat-label>
                      <input matInput formControlName="prNumber" name="prNumber" placeholder="PR Number" />
                    </mat-form-field>

                  </div>
                  <div class="col-md-4">

                    <mat-form-field class="full-width">
                      <mat-label>Status</mat-label>
                      <mat-select formControlName="prStatus">
                        <mat-option *ngFor="let status of PurchaseOrderStatus" [value]="status">
                          {{ getPrStatusText(status) }}
                        </mat-option>

                      </mat-select>
                    </mat-form-field>

                  </div>
                  <div class="col-md-4">
                    <mat-form-field class="full-width">
                      <mat-label>Start Date</mat-label>
                      <input matInput formControlName="startDate" placeholder="Purchase Date"
                        [matDatepicker]="appDatepicker" readonly [max]="today" />
                      <mat-datepicker-toggle matSuffix [for]="appDatepicker"></mat-datepicker-toggle>
                      <mat-datepicker #appDatepicker></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field class="full-width">
                      <mat-label>End Date</mat-label>
                      <input matInput formControlName="endDate" placeholder="Purchase Date"
                        [matDatepicker]="appDatepicker1" readonly [max]="today" />
                      <mat-datepicker-toggle matSuffix [for]="appDatepicker1"></mat-datepicker-toggle>
                      <mat-datepicker #appDatepicker1></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12" style="text-align: center;">
                    <button mat-raised-button color="primary" class="text-center ml" (click)="search()">
                      Search
                    </button>
                    <button mat-raised-button color="primary" class="text-center ml-w" (click)="clearFilters()"
                      style="margin-left: 5px;">
                      Clear Filters
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 table-wrapper">
        <table mat-table [dataSource]="dataSource">

          <ng-container matColumnDef="purchaseRecieptNo">
            <th mat-header-cell *matHeaderCellDef> PO Number</th>
            <td mat-cell *matCellDef="let element"> {{ element.receipt_id }} </td>
          </ng-container>

          <ng-container matColumnDef="purchaseDate">
            <th mat-header-cell *matHeaderCellDef>Purchase Date</th>
            <td mat-cell *matCellDef="let element">{{ element.purchase_date }} </td>
          </ng-container>

          <ng-container matColumnDef="recievedDate">
            <th mat-header-cell *matHeaderCellDef>Received Date</th>
            <td mat-cell *matCellDef="let element"> {{ element.received_date }} </td>
          </ng-container>

          <ng-container matColumnDef="supplierName">
            <th mat-header-cell *matHeaderCellDef>Supplier Name</th>
            <td mat-cell *matCellDef="let element"> {{ getSupplierName(element.supplier_id) }} </td>
          </ng-container>

          <ng-container matColumnDef="poStatus">
            <th mat-header-cell *matHeaderCellDef>PO Status</th>
            <td mat-cell *matCellDef="let element"> {{ getPrStatusText(element.status) }} </td>
          </ng-container>

          <!-- <ng-container matColumnDef="grnStatus">
            <th mat-header-cell *matHeaderCellDef>GRN Status</th>
            <td mat-cell *matCellDef="let element"> {{ element.grnStatus }} </td>
          </ng-container> -->

          <ng-container matColumnDef="netpayable">
            <th mat-header-cell *matHeaderCellDef>Total Bill Value</th>
            <td mat-cell *matCellDef="let element"> {{ +element.payout | replaceCommas}} </td>
          </ng-container>

          <!-- <ng-container matColumnDef="landingcost">
            <th mat-header-cell *matHeaderCellDef>Landing Cost</th>
            <td mat-cell *matCellDef="let element"> {{ element.landingcost }} </td>
          </ng-container> -->

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let element">
              @if(hasEdit){
              <div class="button-container">
                <button color="primary" mat-raised-button (click)="edit(element.receipt_id)">
                  <mat-icon class="me-0">edit</mat-icon>
                </button>
                <button color="primary" mat-raised-button (click)="clone(element.receipt_id)">
                  <!-- <mat-icon class="me-0">edit</mat-icon> -->
                  Clone
                </button>
                @if(element.purchase_bill){
                <button color="primary" mat-raised-button (click)="downloadInvoice(element.purchase_bill)">
                  <mat-icon class="me-0">download</mat-icon>
                </button>
                }
                @else{
                <button color="primary" mat-raised-button (click)="download(element.receipt_id)">
                  <mat-icon class="me-0">error</mat-icon>
                </button>
                }
              </div>
              }
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

      </div>
      <mat-paginator [length]="dataLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="onPageChange($event)"></mat-paginator>
    </div>
  </mat-card>
</div>
}@else {
<ng-container>
  <p class="text-center">
    You don't have permission , Please contact administrator
  </p>
</ng-container>
}