import { AfterViewChecked, AfterViewInit, Component, OnDestroy, OnInit, OnChanges, ViewChild, ChangeDetectorRef } from '@angular/core';
import { PromotionEntity, PromotionListSearch } from '../../models/promotions.model';
import { PromotionsService } from '../../services/promotions.service';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSidenavModule } from '@angular/material/sidenav';

import Swal from 'sweetalert2';
import { AppLoaderService } from '../../../../shared/app-loader/app-loader.service';
import { AuthService } from '../../../../services/auth.service';
import { SwalMessageTypes, SupplierStatus, PromotionType } from '../../../../enums/enums';
import { findEnumNameByValue, getDateYYYYMMDD, getPromotionType } from '../../../../utlity/utility';
import { BaseListComponent } from '../../../../shared/core/base.list.component';
import { PageId } from '../../../../constants/enums';
import { AppStateService } from '../../../../services/appstate.service';


@Component({
  selector: 'app-promotions-list',
  standalone: true,
  imports: [MatCardModule, MatButtonModule, MatTableModule, RouterLink, MatInputModule, MatFormFieldModule, ReactiveFormsModule,
    MatSelectModule, MatIconModule, CommonModule, FormsModule, MatDatepickerModule, MatRadioModule, MatNativeDateModule, MatPaginatorModule, MatSidenavModule],
  templateUrl: './promotions-list.component.html',
  styleUrls: ['./promotions-list.component.scss']
})
export class PromotionListComponent extends BaseListComponent {
  singularTypes = Object.values(SupplierStatus).filter(value => typeof value === 'number');
  permission: any = false;
  promotions: PromotionEntity[] = [];
  promotionTypeList: Array<any> = [];
  totalPromotions: number = 0;
  form!: FormGroup;
  promotionListSearchInput: PromotionListSearch = this.initializeSearchInput();

  displayedColumns = [
    'promoType',
    'sequence',
    'promoName',
    'vendorName',
    'displayName',
    'status',
    "actions",
    "Details"
  ];

  dataSource!: MatTableDataSource<PromotionEntity>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(public readonly promotionService: PromotionsService,
    route: ActivatedRoute,
    router: Router,
    private loader: AppLoaderService,
    auth: AuthService,
    private appState: AppStateService,
    private fb: FormBuilder) {
    super(auth, router, route, PageId.bms_promotions); // Call the constructor of BaseListComponent

  }

  override async ngOnInit() {
    await super.ngOnInit();
    this.promotionTypeList = getPromotionType();
    await this.getPromotions();
  }

  getPromotionTypeByValue(promoType: number) {
    return findEnumNameByValue(PromotionType, promoType);
  }

  async getPromotions() {
    try {
      this.loader.open();
      const response = await this.promotionService.getPromotions(this.promotionListSearchInput);
      const promotionRes = response['promotionList'];
      this.dataSource = promotionRes.sort((a: { sequence: number; }, b: { sequence: number; }) => a.sequence - b.sequence)
      this.promotions = response['promotionList'];
      this.totalPromotions = response["totalPromotions"];
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } catch (error) {
      this.loader.close();
      this.showMessage('Failed to Fetch Promotions. Please try again later.', SwalMessageTypes.Error);
    } finally {
      this.loader.close();
    }
  }

  async deletePromotion(row: any) {
    try {
      const result = await Swal.fire({
        title: 'Do you want to Delete Promotion?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      });

      if (!result.isConfirmed) {
        return;
      }
      await this.promotionService.deletePromotion(row.id);
      await this.clearFilters();
      this.showMessage('Promotion Updated Successfully', SwalMessageTypes.Success);
    } catch (error) {
      console.error('Error occurred while creating promotion:');
      this.showMessage('Failed to Delete Promotion. Please try again later.', SwalMessageTypes.Error);
    }
  }

  navigateToDetails(row: any) {
    this.appState.setItem('promotionsFilters', this.promotionListSearchInput);
    this.router.navigateByUrl(`/dashboard/promotion/detail/${row.id}`);
  }


  private isValidPromotionDate(promotionEntity: PromotionEntity) {
    const existingDate = getDateYYYYMMDD(new Date(promotionEntity.expiryDate))
    const isValid = existingDate >= getDateYYYYMMDD(new Date());
    console.log("isValidCouponDate", isValid);

    return isValid;
  }

  private initializeSearchInput(): PromotionListSearch {
    this.promotionListSearchInput = this.appState.getItem<PromotionListSearch>('promotionsFilters') || {
      promoType: 1,
      vendorId: [],
      status: true,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize
    };

    this.form = this.fb.group({
      promoType: [this.promotionListSearchInput.promoType],
      vendorId: [this.promotionListSearchInput.vendorId], // Ensure this matches the expected structure
      status: [this.promotionListSearchInput.status],
      pageNumber: [this.promotionListSearchInput.pageNumber],
      pageSize: [this.promotionListSearchInput.pageSize],
    });

    return this.promotionListSearchInput;
  }

  override async onPageChange(event: any) {
    await super.onPageChange(event);
    const { vendorId, promoType, status } = this.form.value;
    this.promotionListSearchInput = this.createPromotionListSearchInput(vendorId, promoType, status)
    await this.getPromotions();
  }

  async searchTasks() {
    const { promotionName, promoType, status } = this.form.value;
    // this.pageNumber = 1;
    // this.pageSize = this.DEFAULT_PAGE_SIZE;
    // this.paginator.pageIndex = 0;
    if (promotionName || promoType || status !== undefined) {
      this.promotionListSearchInput = this.createPromotionListSearchInput([], promoType, status);
      await this.getPromotions();

    }
  }

  private createPromotionListSearchInput(vendorId: string[], type: any, status: any): PromotionListSearch {
    // CHANGE INPUTS BASED ON YOUR REQUIREMENTS
    // searchText: supplierName || "",
    // type: type || null,
    // status: status == 0 ? 0 : !status ? null : status == 1 ? 1 : null,
    return {
      promoType: type,
      vendorId: vendorId || [],
      status: status || true,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize
    };
  }


  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  async clearFilters() {
    this.searchForm();
    this.promotionListSearchInput = this.initializeSearchInput();
    await this.getPromotions();
  }

  private searchForm() {
    this.form = this.fb.group({
      vendorId: "",
      promoType: [1],
      status: ['true'],
    });
  }

  async changeStatusPromotion(promotion: PromotionEntity) {
    const statusLabelReverse = promotion.status ? "Inactive" : "Active";
    const statusLabelVal = promotion.status ? false : true;

    try {
      const result = await Swal.fire({
        title: "Change Promotion Status",
        text: `Are you sure to make this promotion ${statusLabelReverse}?`,
        icon: "question",
        confirmButtonText: "Yes, Proceed!",
        showCancelButton: true
      });

      if (result.isConfirmed) {
        if (statusLabelVal == true && !this.isValidPromotionDate(promotion)) {
          Swal.fire('', "You can not activate expired promotion!.", 'warning');
          return;
        }
        promotion.status = statusLabelVal;
        this.loader.open();
        await this.promotionService.changePromotionStatus(promotion);
        // if (statusLabelVal) {
        //   await this.sendCreateCouponMessageToBmsMsgQueue(coupon);
        // } else {
        //   await this.sendDeleteCouponMessageToBmsMsgQueue(coupon.id);
        // }
        this.loader.close();
        this.getPromotions();
      }
    } catch (error) {
      console.error("changeCouponStatus error:", error);
      this.loader.close();
    }
  }

}
