import { Injectable } from "@angular/core";
import { Database, equalTo, get, objectVal, orderByChild, query, ref, set, update } from '@angular/fire/database';
import { Observable } from "rxjs";
import { QueryFilter, UserType } from "../enums/enums";
import { DB_PATH } from "../constants/db-path";
import { getDateFormat, removeEndSpaces, toCustomArray } from "../utlity/utility";

interface UserActivity {
  datetime: string;
  userId: string;
  userName: string;
  page: string;
  activity: string;
  existingData: any;
  modifiedData: any;
}

@Injectable({
  providedIn: 'root'
})
export class FirebaseDbService {
  userTypeDictionary: any = {

  };

  constructor(private database: Database) {
  }

  fetchDataFromNode(nodePath: string): Observable<any> {
    const doc = ref(this.database, nodePath);

    return objectVal(doc);
  }

  queryDataByValue(nodePath: string, field: string, value: any): Observable<any[]> {
    const dataRef = ref(this.database, nodePath);
    const queryRef = query(dataRef, orderByChild(field), equalTo(value));

    return objectVal(queryRef);
  }


  getDataFromNode(nodePath: string): Promise<any> {
    const doc = ref(this.database, nodePath);

    return get(doc);
  }

  getDataByValue(nodePath: string, field: string, value: any): Promise<any> {
    const dataRef = ref(this.database, nodePath);
    const queryRef = query(dataRef, orderByChild(field), equalTo(value));
    return get(queryRef);
  }

  async updateData(nodePath: string, data: any): Promise<void> {
    const doc = ref(this.database, nodePath);

    console.log("data", data);
    return update(doc, data);
  }

  setData(nodePath: string, data: any): Promise<void> {
    const doc = ref(this.database, nodePath);
    return set(doc, data);
  }

  usersQuery(queryData: Array<QueryFilter>): any {
    let dbPath = `${DB_PATH.VIWITO_MERCHANTS}`;
    queryData.forEach((data) => {
      if (data.fieldName === "nameLowerCase") {
        data.fieldValue = removeEndSpaces(data.fieldValue)
        data.fieldName = "nameLowerCase";
      } else if (data.fieldName === "mobileNumber") {
        data.fieldName = "mobileNumber";
        data.fieldValue = data.fieldValue.toString();
      } else if (data.fieldName === "id") {
        data.fieldName = "id";
      }
    });

    return this.queryDataPromises(queryData, dbPath).then((result) => {
      let users: any[] = [];

      result.forEach((snapValue: { val: () => any; }) => {
        let snapUsers = snapValue.val();
        users.push(...toCustomArray(snapUsers));
      });

      return users;
    });
  }

  async getCustomerByFilter(queryData: Array<QueryFilter>) {
    let dbPath = `${DB_PATH.CUSTOMER}`;

    queryData.forEach((data) => {
      if (data.fieldName === "name") {
        data.fieldValue = removeEndSpaces(data.fieldValue)
        data.fieldName = "nameLowerCase";
      } else if (data.fieldName === "mobileNumber") {
        data.fieldName = "mobileNumber";
        data.fieldValue = data.fieldValue.toString()
      } else if (data.fieldName === "id") {

        data.fieldName = "id";
      }
    });


    const result = await this.queryDataPromises(queryData, dbPath);

    let users: any[] = [];
    result.forEach((snapValue: { val: () => any; }) => {
      let snapUsers = snapValue.val();
      users.push(...toCustomArray(snapUsers));
    });

    return users;
  }


  private queryDataPromises(queryData: Array<QueryFilter>, dbPath: any): Promise<any> {
    let promises: any = [];
    queryData.forEach((data) => {
      let promise: any = this.getDataByValue(dbPath, data.fieldName, data.fieldValue);
      promises.push(promise);
    });
    return Promise.all(promises);
  }

  async saveUserActivity(userId: string, userName: string, page: string, activity: string, existingData: any, modifiedData: any): Promise<any> {
    let activityDate = getDateFormat();
    let uId = userId
    let dbPath = `${DB_PATH.USER_ACTIVITIES}/ProOps/${activityDate}/${uId}/${activityDate} ${(new Date).toTimeString()}`;

    let userActivity: UserActivity = {
      datetime: `${activityDate} ${(new Date).toTimeString()}`,
      userId: userId,
      userName: userName,
      page: page,
      activity: activity,
      existingData: existingData,
      modifiedData: modifiedData,
    }
    return this.setData(dbPath, userActivity);
  }
}
