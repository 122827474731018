import { Pipe, PipeTransform } from '@angular/core';
import { groups } from '../../constants/groups';

@Pipe({
  name: 'groupname',
  standalone: true
})
export class GroupNamePipe implements PipeTransform {
  transform(value: string) {
    let found= groups.find((item)=>{
        return item.value == value
    })
    if(found){
      return found.text
    }else{
      return value
    }
  }

}
