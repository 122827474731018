<div class="container-fluid">
  <div class="row">
    <div class="col-md-4">
      <label>Name</label>
      <p>{{productData?.name}}</p>
    </div>
    <div class="col-md-4">
      <label>Type</label>
      <p>{{data?.type | type}}</p>
    </div>
    <div class="col-md-4">
      <label>Status</label>
      <p><app-status [value]="data.status" ></app-status></p>
    </div>
    <div class="col-md-3">
      <label>Brands Name</label>
      <p>{{productData?.brands_name}}</p>
    </div>
    <div class="col-md-3">
      <label>Categories Name</label>
      <p>{{productData?.categories_name}}</p>
    </div>
    <div class="col-md-3">
      <label>Companies Name</label>
      <p>{{productData?.companies_name}}</p>
    </div>
    <div class="col-md-3">
      <label>Description</label>
      <p>{{productData?.description}}</p>
    </div>
    <h4 class="mt-3">Product Variants
    </h4>
    <div class="col-md-12">
      <table class="table">
        <thead>
          <tr>
            <th>
              Variant Name
            </th>
          </tr>
        </thead>
        <tbody>
          @for (item of productData?.product_variants ; track $index) {

          <tr>
         
            <td>
              {{item.variant_types_name}}
            </td>
            
          </tr>
        }
        </tbody>
      </table>
    </div>
    <h4>SKU</h4>
    @for (item of productData?.skus; track item.id) {
      
    <div class="row">
      <div class="col-md-4">
        <label>Name</label>
       <div >
          {{item.name}}
        </div>
    </div>
    <div class="col-md-4">
      <label>Barcode</label>
     <div >
        {{item.barcode}}
      </div>
  </div>
  <div class="col-md-4">
    <label>Short Description</label>
   <div >
      {{item.short_description}}
    </div>
</div>
<div class="col-md-4">
  <label>Meta Title</label>
 <div >
    {{item.meta_title}}
  </div>
</div>
<div class="col-md-4">
<label>Short Code</label>
<div >
  {{item.short_code}}
</div>
</div>
<div class="col-md-4">
<label>Print Name</label>
<div >
{{item.print_name}}
</div>
</div>
<div class="col-md-12">
  <label>Long Description</label>
  <div >
     {{item.long_description}}
   </div>
</div>
</div>

@if (item?.selected_variant_values) {
 <h4>Selected Variant Values</h4>
@for (item1 of item.selected_variant_values; track item1.id) {
  <div class="row">
  <div class="col-md-6">
  <label>Variant Type</label>
  <div >
     {{item1.variant_types_name}}
   </div>
</div>
<div class="col-md-6">
  <label>Variant Value</label>
  <div >
     {{item1.variant_values_name}}
   </div>
</div>
</div>
} 
}
@if (item?.sku_attributes) {
<h4>SKU Attributes</h4>
@for (item1 of item.sku_attributes; track item1.id) {
<div class="row">
<div class="col-md-6">
<label>Key</label>
<div >
   {{item1.key}}
 </div>
</div>
<div class="col-md-6">
<label>Value</label>
<div >
   {{item1.value}}
 </div>
</div>
</div>
} 
}
@if (item?.sku_packing_sections) {
<h4>SKU Packing Sections</h4>
<div class="row">
<div class="col-md-3">
<label>Height Dimensions</label>
<div >
   {{item.sku_packing_sections?.dimensions?.height}}
 </div>
</div>
<div class="col-md-3">
<label>Length Dimensions</label>
<div >
   {{item.sku_packing_sections?.dimensions?.length}}
 </div>
</div>
<div class="col-md-3">
<label>Width Dimensions</label>
<div >
   {{item.sku_packing_sections?.dimensions?.width}}
 </div>
</div>
<div class="col-md-3">
<label>Grams Weight</label>
<div >
   {{item.sku_packing_sections?.weight_per_unit?.grams}}
 </div>
</div>
<div class="col-md-3">
<label>Kg Weight</label>
<div >
   {{item.sku_packing_sections?.weight_per_unit?.kg}}
 </div>
</div>

<div class="col-md-3">
<label>Inner Pack</label>
<div >
   {{item.sku_packing_sections?.inner_pack}}
 </div>
</div>
<div class="col-md-3">
<label>Min Order Qty</label>
<div >
   {{item.sku_packing_sections?.min_order_qty}}
 </div>
</div>
<div class="col-md-3">
<label>MRP</label>
<div >
   {{item.sku_packing_sections?.mrp}}
 </div>
</div>
<div class="col-md-3">
<label>Product Package Type</label>
<div >
   {{item.sku_packing_sections?.product_package_type}}
 </div>
</div>
<div class="col-md-3">
<label>uom</label>
<div >
   {{item.sku_packing_sections?.uom}}
 </div>
</div>
<div class="col-md-3">
<label>upc</label>
<div >
   {{item.sku_packing_sections?.upc}}
 </div>
</div>
</div>
}

@if (item?.sku_tax_details) {
<h4>SKU Tax Details</h4>
<div class="row">
<div class="col-md-3">
  <label>Countries</label>
  <div >
     {{item?.sku_tax_details?.countires_name}}
   </div>
</div>
<div class="col-md-3">
  <label>HSN Code</label>
  <div >
     {{item?.sku_tax_details?.hsn_code}}
   </div>
</div>
<div class="col-md-3">
  <label>Tax Categories</label>
  <div >
     {{item?.sku_tax_details?.tax_categories_name}}
   </div>
</div>
<div class="col-md-3">
  <label>Tax Percentage</label>
  <div >
     {{item?.sku_tax_details?.tax_percentage}}
   </div>
</div>
</div>
}

@if(item?.sku_images){
  <h4>SKU Images</h4>
  <div class="d-flex">
   @if (item?.sku_images?.permanent_image) {
     <div class="m-2">
       <img [src]="item?.sku_images?.permanent_image">
     </div>
    }

  @if (item?.sku_images?.optional_image1) {
   <div class="m-2">
     <img [src]="item?.sku_images?.optional_image1">
   </div>
  }
  @if (item?.sku_images?.optional_image2) {
   <div class="m-2">
     <img [src]="item?.sku_images?.optional_image2">
   </div>
  }
  @if (item?.sku_images?.optional_image3) {
   <div class="m-2">
     <img [src]="item?.sku_images?.optional_image3">
   </div>
  }
  @if (item?.sku_images?.optional_image4) {
   <div class="m-2">
     <img [src]="item?.sku_images?.optional_image4">
   </div>
  }

  </div>
 }
 <hr>
  }
  </div>
</div>