import { Delivery } from './../../../../../../entities/trip-info';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import Swal from 'sweetalert2';
import { AuthService } from '../../../../../../services/auth.service';
import {
  TripStatus,
  HopStatus,
  LogisticsEvents,
  PointMode,
  PointType,
} from '../../../../../../enums/enums';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { MoveHopComponent } from '../move-hop/move-hop.component';
import { CompleteHopComponent } from '../complete-hop/complete-hop.component';
import { TripService } from '../../../services/trip.service';
import { HopResponse } from '../../../models/trip.model';
import { AppLoaderService } from '../../../../../../shared/app-loader/app-loader.service';
import { BaseListComponent } from '../../../../../../shared/core/base.list.component';
import { PageId } from '../../../../../../constants/enums';
import { takeUntil } from 'rxjs';
import { MappedTrip } from '../../../../../../entities/trip-info';
import { AppStateService } from '../../../../../../services/appstate.service';
import { DB_HOP } from '../../../../../../entities/hop-db.model';
import { ReplaceCommasPipe } from '../../../../../../shared/pipes/currency-pipe';

interface SummaryDetail {
  isVisible: boolean;
  amount: number;
}

interface SummaryObj {
  grossProductAmount?: SummaryDetail;
  returnProductsAmount?: SummaryDetail;
  customerCod?: SummaryDetail;
  customerOnlinePayment?: SummaryDetail;
}

@Component({
  selector: 'app-hop-summary',
  standalone: true,
  templateUrl: './hop-summary.component.html',
  styleUrls: ['./hop-summary.component.scss'],
  imports: [
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    RouterLink,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatMenuModule,
    ReplaceCommasPipe,
  ],
})
export class HopSummaryComponent extends BaseListComponent {
  id: any;
  balance: any;
  dataSource: any;
  pointMode: { type: string; value: number }[] = [
    { type: 'Pick Up', value: 1 },
    { type: 'Delivery', value: 2 },
    { type: 'Return', value: 3 },
    { type: 'None', value: 4 },
  ];
  currentTrip: MappedTrip | null = null;
  currentHop: DB_HOP | null = null;
  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    private loader: AppLoaderService,
    private readonly tripService: TripService,
    private appState: AppStateService,
    private dialog: MatDialog
  ) {
    super(auth, router, route, PageId.logistics_trips);
    this.mapToCurrentTrip();
    this.mapToCurrentHop();
    this.dataSource = this.fb.group({
      hopId: '',
      hopDate: '',
      pointBusinessName: '',
      pointMobileNumber: '',
      status: '',
    });
  }

  override async ngOnInit() {
    super.ngOnInit();
    this.route.params
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (params: any) => {
        this.id = params['id'];
        if (this.id) {
          await this.getHopeDetails(this.id);
        }
      });
  }

  private mapToCurrentTrip() {
    this.appState.selectedTrip$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((trip) => {
        if (trip) {
          this.currentTrip = trip;
        } else {
          const localStorageTrip: string | null =
            localStorage.getItem('tripDetails');
          const trip = localStorageTrip && JSON.parse(localStorageTrip);
          this.appState.setSelectedTrip(trip);
          this.currentTrip = trip;
        }
      });
  }

  private mapToCurrentHop() {
    this.appState.selectedHop$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((hop) => {
        if (hop) {
          this.currentHop = hop;

        } else {
          // TODO
        }
      });
  }
  getPointName(value: number): string {
    const pointMode = this.pointMode.find((type) => type.value === value);
    return pointMode ? pointMode.type : 'Unknown';
  }

  getStatusName(status: number | string): string {
    return typeof status === 'number' ? HopStatus[status] : status;
  }
  moveHop() {
    const dialogRef = this.dialog.open(MoveHopComponent, {
      width: '30%',
      height: '40%',
      data: {
        title: 'Move Hop',
        tripId: this.dataSource.value.tripId || '',
      },
    });

    dialogRef.afterClosed().subscribe(async (trip: any) => {
      const tripId = trip.trip.tripId;
      const hopId = this.id;
      const res = await this.tripService.moveHop(tripId, hopId);
      if (res.success) {
        await this.getHopeDetails(this.id);
        Swal.fire(
          'Success',
          'Hop moved to ' + tripId + ' successfully',
          'success'
        );
      } else {
        Swal.fire('Warning!', 'Failed to move hop', 'warning');
      }
    });
  }

  completeHop() {
    const otp = this.dataSource.value.otp;
    const dialogRef = this.dialog.open(CompleteHopComponent, {
      width: '30%',
      height: '40%',
      data: {
        otp: otp,
      },
    });
    dialogRef.afterClosed().subscribe(async (otp: any) => {
      if (otp?.otp?.otp) {
        const hopId = this.id;
        const res = await this.tripService.completeHop(
          HopStatus.Completed,
          hopId
        );
        if (res.success) {
          await this.getHopeDetails(this.id);
          Swal.fire(
            'Success',
            'Hop Number :' +
            this.dataSource.value.stopNumber +
            ' completed successfully',
            'success'
          );
        } else {
          Swal.fire('Warning!', 'Failed to complete the hop', 'warning');
        }
      } else {
      }
    });
  }

  async getHopeDetails(hop_id: string) {
    try {
      this.loader.open();
      const hop_details = await this.tripService.getHopDetails(hop_id);
      this.dataSource = this.mapToHop(hop_details);
      if (this.dataSource?.value?.pointType == PointType.CUSTOMER && this.dataSource?.value?.pointMode == PointMode.DELIVERY) {

        this.calculateBalanceAmount(this.dataSource.value);
      }
      this.loader.close();
    } catch (error) {
      this.loader.close();
    }
  }
  private mapToHop(hop_details: HopResponse) {
    return this.fb.group({
      hopId: hop_details.hop_id,
      tripId: hop_details.trip_id,
      pointId: hop_details.point_id,
      pointAddress: hop_details.point_address,
      pointLat: hop_details.point_lat,
      pointLang: hop_details.point_lng,
      otp: hop_details.otp,
      status: this.getPlanningStatusText(hop_details.hop_status),
      stopNumber: hop_details.stop_number,
      pointMode: hop_details.point_mode,
      pointType: hop_details.point_type,
      hopDate: this.formatDate(hop_details.hop_updated_at),
      hopUpdatedAt: hop_details.hop_updated_at,
      ordersCount: hop_details.orders_count,
      totalHopItems: hop_details.total_hop_items,
      totalQuantity: hop_details.total_quantity,
      totalWeight: (+hop_details?.total_weight).toFixed(2) || 0,
      pointBusinessName: hop_details.point_business_name,
      pointMobileNumber: hop_details.point_mobile_number,
      pointName: hop_details.point_name,
      cashCollections: +hop_details.cash_collections,
      onlineCollections: +hop_details.online_collections,
      returnsCollections: Math.round(hop_details.returns_collections) || 0,
      dispatchValue: Math.round(hop_details.dispatch_value) || 0,
      summary: this.processEvents(hop_details),
    });
  }

  private mapToPointModeAndType(
    mode: PointMode | undefined,
    type: PointType | undefined
  ) {
    const reverseMapping: any = {
      '1_1': LogisticsEvents.VendorPickup,
      '2_3': LogisticsEvents.WarehouseDelivery,
      '2_2': LogisticsEvents.CustomerDelivery,
      '3_3': LogisticsEvents.WarehouseReturn,
      '3_1': LogisticsEvents.VendorReturn,
      '1_3': LogisticsEvents.WarehousePickup,
    };
    const key = `${mode}_${type}`;
    return reverseMapping[key] || null;
  }

  private processEvents(hopInfo: HopResponse) {
    const summaryObj: SummaryObj = {};
    try {
      const logisticsEventType = this.mapToPointModeAndType(
        +hopInfo?.point_mode,
        +hopInfo?.point_type
      );

      switch (logisticsEventType) {
        case LogisticsEvents.VendorPickup:
          /**
           * Amount Of Products - Gross product amount
           *
           */
          summaryObj['grossProductAmount'] = {
            isVisible: true,
            amount: +hopInfo.vendor_pickup_value,
          };
          break;

        case LogisticsEvents.WarehouseDelivery:
          /**
           * Amount Of Products - Gross product amount
           */
          // summaryObj['grossProductAmount'] = {
          //   isVisible: true,
          //   amount: hopInfo.vendor_pickup_value,
          // };
          summaryObj['grossProductAmount'] = {
            isVisible: true,
            amount: +hopInfo.warehouse_delivery_value,
          };
          break;

        case LogisticsEvents.CustomerDelivery:
          /**
           * Amount Of Products - Gross product amount
           * Customer Returns Amount
           * COD - cash on delivery
           * Online Payments
           */
          summaryObj['grossProductAmount'] = {
            isVisible: true,
            amount: +hopInfo.retail_delivery_value,
          };
          summaryObj['returnProductsAmount'] = {
            isVisible: true,
            amount: Math.round(+hopInfo.warehouse_return_value),
          };
          summaryObj['customerCod'] = {
            isVisible: true,
            amount: +hopInfo.cash_collections,
          };
          summaryObj['customerOnlinePayment'] = {
            isVisible: true,
            amount: +hopInfo.online_collections,
          };

          break;

        case LogisticsEvents.WarehouseReturn:
          summaryObj['grossProductAmount'] = {
            isVisible: true,
            amount: +hopInfo.warehouse_return_value,
          };
          break;

        case LogisticsEvents.VendorReturn:
          summaryObj['grossProductAmount'] = {
            isVisible: true,
            amount: hopInfo.vendor_returns_value,
          };
          break;
        case LogisticsEvents.WarehousePickup:
          summaryObj['grossProductAmount'] = {
            isVisible: true,
            amount:
              hopInfo.vendor_returns_value + hopInfo.warehouse_pickup_value,
          };
          break;

        default:
          break;
      }

      return summaryObj;
    } catch (error) {
      throw error;
    }
  }

  private calculateBalanceAmount(hop: any) {
    // const dispatchValue = this.dataSource.dispatchValue;
    // const cash = this.dataSource.cashCollections;
    // const online = this.dataSource.onlineCollections;
    // const returns = this.dataSource.returnsCollections;
    // this.balance = Math.round(dispatchValue - cash - online - returns) || 0;
    this.balance = hop?.summary?.grossProductAmount?.amount - (hop?.summary?.returnProductsAmount?.amount + hop?.summary?.customerOnlinePayment?.amount +
      hop?.summary?.customerCod?.amount
    )

  }

  getPlanningStatusText(value: number): string {
    switch (value) {
      case HopStatus.NotStarted:
        return 'Not Started';
      case HopStatus.Completed:
        return 'Completed';
      default:
        return 'Unknown Status';
    }
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  }
}
