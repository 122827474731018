import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterModule,
} from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ProductService } from '../services/product.service';
import { AuthService } from '../../../services/auth.service';
import { FormBuilder } from '@angular/forms';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProductFilterDialogComponent } from '../product-filter-dialog/product-filter-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmDialogComponent } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.component';
import { CommonService } from '../../../services/common.service';
import { CommonModule } from '@angular/common';
import { MatSortHeader, MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { first, takeUntil } from 'rxjs';
import Swal from 'sweetalert2';
import { SwalMessageTypes } from '../../../enums/enums';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { PageId } from '../../../constants/enums';

@Component({
  selector: 'app-master-sku-list',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatSortModule,
    MatCardModule,
    RouterModule,
    MatButtonModule,
    MatTabsModule,
    MatTableModule,
    RouterLink,
    MatPaginatorModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './master-sku-list.component.html',
  styleUrl: './master-sku-list.component.scss',
})
export class MasterSkuListComponent
  extends BaseListComponent
  implements OnInit
{
  page_id = 'bms_master_skus';
  dataSource: any = [];
  displayedColumns: string[] = [
    'name',
    'brands_id',
    'companies_id',
    'categories_id',
    'status',
    'action',
  ];
  permission: any = false;
  draftProducts: any = [];
  queryForm: any;
  meta: any;
  loading = true;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public commonService: CommonService,
    public productService: ProductService
  ) {
    super(auth, router, route, PageId.bms_manage_sku);
    this.queryForm = this.fb.group({
      name: [null],
      companies_id: [null],
      brands_id: [null],
      categories_id: [null],
      page: [1],
      limit: [25],
      sort_order: [],
      sort_by: [],
    });
  }

  goToCorrectPage() {
    if (this.paginator) {
      this.paginator.pageIndex = this.queryForm.value.page - 1;
      // Trigger a manual page change event so that the MatPaginator updates accordingly
      // this.paginator._changePageSize(this.paginator.pageSize);
    }
  }

  override async ngOnInit() {
    await super.ngOnInit();

    this.queryForm.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$) // Unsubscribe when the component is destroyed
      )
      .subscribe((value: any) => {
        this.navigateWithParams(value);
        this.getAllSkus();
      });
    if (this.route.snapshot.queryParams) {
      this.queryForm.patchValue(this.route.snapshot.queryParams);
    } else {
      this.getAllSkus();
    }
  }

  isDataAvailable(): boolean {
    return this.dataSource && this.dataSource?.data?.length > 0;
  }
  getAllSkus() {
    this.loading = true;
    this.dataSource = new MatTableDataSource([]);

    this.productService
      .getAllMasterSkus(this.queryForm.value)
      .subscribe((res: any) => {
        this.meta = res.meta;
        this.dataSource = new MatTableDataSource(res.data);
        this.goToCorrectPage();
        this.loading = false;
      });
  }

  onPaginationChange(event: any) {
    this.queryForm.patchValue({
      page: event.pageIndex + 1,
      limit: event.pageSize,
    });
  }

  navigateWithParams(queryParams: any) {
    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key] || queryParams[key] == 'null') {
        delete queryParams[key];
      }
    });
    // Navigate to the current route with the specified query parameters
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
    });
  }

  openFilter() {
    const dialogRef = this.dialog.open(ProductFilterDialogComponent, {
      data: this.queryForm.value,
      panelClass: 'custom-dialog-class-p',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      if (result) {
        this.queryForm.patchValue(result);
        this.goToCorrectPage();
      }
    });
  }
  announceSortChange(event: any) {
    console.log(event, 'event');
    this.queryForm.patchValue({
      page: 1,
      limit: 5,
      sort_order: event.direction,
      sort_by: event.active,
    });
  }

  changeStatus(id: any, status: any, type: any) {
    this.productService.updateMasterSkuStatus(id, status).then((res) => {
      this.getAllSkus();
      this.showMessage(`${type} Successfully`, SwalMessageTypes.Success);
    });
  }
  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  edit(element: any) {
    this.productService
      .getMyDraftSkus()
      .pipe(first())
      .subscribe((res: any) => {
        console.log(element, res);
        let found = res.find((item: any) => {
          return item.payload.sku.id == element.id;
        });
        if (!found) {
          this.router.navigate([
            '/dashboard/product/sku/edit/update-sku',
            element.id,
          ]);
        } else {
          this.router.navigate(['/dashboard/product/sku/draft-edit', found.id]);
        }
      });
  }
}
