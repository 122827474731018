import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CommonModule } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';
import {
  PriceUpdateEntity,
  PricingRuleInput,
  SkuPricingRule,
  VendorSkuPricing,
  skuDetails,
  vendorDetails,
} from '../../../entities/pricing';
import { PricingListType, PricingRule, PricingRulePriceType, PricingRuleStatus } from '../../../enums/enums';
import { PricingEditPopupComponent } from '../pricing-Edit/pricing-edit.component';
import { PricingDataService } from '../services/pricing-data.service';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { PricingService } from '../services/pricing.service';
import Swal from 'sweetalert2';
import { containsOnlyNumbers } from '../../../utlity/utility';
import uuid4 from 'uuid4';
import { ConfigService } from '../../config/services/config.service';
import { AppLoaderService } from '../../../shared/app-loader/app-loader.service';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { AuthService } from '../../../services/auth.service';
import { PageId } from '../../../constants/enums';

interface PriceUpdate {
  vendorDetails: vendorDetails;
  skuDetails: VendorSkuPricing;
  isUpdate: boolean;
}

@Component({
  selector: 'pricing-details',
  standalone: true,
  templateUrl: './pricing-details.component.html',
  styleUrls: ['./pricing-details.component.scss'],
  animations: [
    trigger('animate', [
      transition('* => *', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 })),
      ]),
    ]),
  ],
  imports: [
    MatFormFieldModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    RouterLink,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    MatTableModule,
    MatTableModule,
    MatSidenavModule,
    MatPaginatorModule,
    CommonModule,
  ],
})
export class PricingDetailsComponent extends BaseListComponent implements OnInit {
  pricingDetails: PriceUpdate = {
    vendorDetails: { vendor_id: '', businessTypeId: 0 },
    skuDetails: this.initializeSkuDetails(),
    isUpdate: false,
  };
  skuPrice: FormGroup = this.fb.group({ ...this.initializeSkuDetails() });
  priceRuleInput: PricingRuleInput = {
    minQty: 0,
    maxQty: 0,
    discountType: '',
    discountValue: 0,
  };
  dataSource: SkuPricingRule[] = [];
  isMrpOrSpNull: boolean = false;
  isSpGreaterMrp: boolean = false;
  displayedColumns = ['minQty', 'maxQty', 'discountType', 'value', 'edit'];
  shouldBeNumbers: boolean = false;
skuName:string='';

  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    private loader: AppLoaderService,
    public dialog: MatDialog,
    private pricingDataService: PricingDataService,
    private pricingService: PricingService,
    private configService: ConfigService
  ) {
    super(auth, router, route, PageId.seller_price_and_stock);
  }

  override async ngOnInit() {
    super.ngOnInit();
    await this.getPricingDetails();
  }

  async getPricingDetails() {
    try {
      const savedData = localStorage.getItem('pricingDetails');
      if (savedData) {
        const data = JSON.parse(savedData);
        this.pricingDataService.setRowData(data);
        this.subscribeToRowData().pipe(takeUntil(this.unsubscribe$)).subscribe((rowData: any) => {
          this.pricingDetails = rowData as PriceUpdate;
          // this.skuName=this.pricingDetails.skuDetails.name;
          this.skuPrice = this.skuDetails(this.pricingDetails.skuDetails);
        });
      }
      await this.getPricingRules();
    } catch (error) { }
  }

  async getPricingRules() {
    if (this.skuPrice && this.pricingDetails.skuDetails) {
      const response = await this.pricingService.getVendorSKUPricingRules(
        this.pricingDetails.skuDetails.skus_id,
        this.pricingDetails.skuDetails.vendor_id
      );

      this.dataSource = this.mapPricingRules(response, this.skuPrice.value.selling_price)


      console.log(" this.dataSource::", this.dataSource);
      // const filteredData = response.filter(p => p.status == PricingRuleStatus.Active)
      // this.dataSource = filteredData.sort(
      //   (a, b) => a.min_quantity - b.min_quantity
      // );
    }
  }

  private mapPricingRules(pricingRulesData: any[], sellingPrice: number): any[] {
    const mappedRules = pricingRulesData.map(rule => ({
      id: rule.id,
      sku_id: rule.sku_id,
      vendor_id: rule.vendor_id,
      min_quantity: rule.min_quantity,
      max_quantity: rule.max_quantity,
      pricing_type: rule.pricing_type,
      pricing_value: +rule.pricing_value,
      pricing_rate: this.calculateValue(+rule.pricing_type, +rule.pricing_value, +sellingPrice),
      status: rule.status,
      created_at: new Date(rule.created_at),
      updated_at: new Date(rule.updated_at)
    }));

    const filteredRules = mappedRules.filter(p => p.status == PricingRuleStatus.Active);

    filteredRules.sort((a, b) => a.min_quantity - b.min_quantity);

    return filteredRules;
  }


  private calculateValue(rule: number, value: number, sellingPrice: number) {
    if (rule == PricingRulePriceType.Rate) {
      return value.toFixed(2)
    } else if (rule == PricingRulePriceType.DiscountAmount) {
      return (sellingPrice - value).toFixed(2)
    } else if (rule == PricingRulePriceType.DiscountPercentage) {
      return ((sellingPrice) - ((sellingPrice / 100) * value)).toFixed(2)
    }
    return 0;
  }

  private subscribeToRowData() {
    const rowDataSubject = new BehaviorSubject<any>(null);

    this.pricingDataService.rowData$.pipe(takeUntil(this.unsubscribe$)).subscribe((rowData) => {
      rowDataSubject.next(rowData);
    });

    return rowDataSubject.asObservable();
  }

  async savePricing() {
    this.loader.open();
    this.isMrpOrSpNull = false;
    this.isSpGreaterMrp = false;
    const mrp = this.skuPrice.value.maximum_retail_price;
    const sp = this.skuPrice.value.selling_price;
    this.isMrpOrSpNull = this.validatePrices(mrp, sp);
    if (this.isMrpOrSpNull) {
      this.loader.close();

      return;
    }

    if (sp > mrp) {
      this.isSpGreaterMrp = true;
      this.loader.close();
      return;

    }

    if (!containsOnlyNumbers(mrp, sp)) {
      this.shouldBeNumbers = true;
      this.loader.close();


      return;
    }

    if (!mrp > sp) {
      this.isSpGreaterMrp = true;
      this.loader.close();

      return;
    }
    this.upsertPricing(this.skuPrice.value);
  }

  private async upsertPricing(skuPrice: VendorSkuPricing) {
    try {
      const vendorDetails = await this.configService.getVendorSettingsById(
        this.pricingDetails.skuDetails.vendor_id
      );
      const { globalMargin } = vendorDetails?.vendorConfig || 0;
      const inputEntity: PriceUpdateEntity = this.mapToSPUpdateCD(
        skuPrice,
        globalMargin
      );
      const response = await this.pricingService.updatePricing(inputEntity);
      this.loader.close();
      if (response) {
        this.router.navigate(['/dashboard/pricing']);
        Swal.fire('', 'Price Updated Successfully ', 'success');

      }
    } catch (error) {
      this.loader.close();

      Swal.fire('', 'Min and Max Qty should not be decimal values', 'warning');
      console.error('Error block: ', error);
    }
  }

  private mapToSPUpdateCD(skuPrice: VendorSkuPricing, globalMargin: number) {
    return {
      id: skuPrice.pricing_list_id || uuid4(),
      vendor_id: skuPrice.vendor_id,
      sku_id: skuPrice.skus_id,
      maximum_retail_price: +skuPrice.maximum_retail_price,
      selling_price: +skuPrice.selling_price,
      type: PricingListType.Selling,
      margin: +skuPrice.margin || globalMargin || 0,
    };
  }

  private validatePrices(mrp: number, sp: number): boolean {
    const isMrpEmpty = !mrp;
    const isSpEmpty = !sp;

    return (
      (mrp === 0 && sp !== 0) ||
      (mrp !== 0 && sp === 0) ||
      (mrp === 0 && sp === 0) ||
      (isMrpEmpty && !isSpEmpty) ||
      (!isMrpEmpty && isSpEmpty)
    );
  }

  openDialog(row: any = '', isUpdate: boolean = false): void {
    const priceRuleInput = { ...row };
    priceRuleInput.pricing_type = +priceRuleInput.pricing_type;
    const dialogRef = this.dialog.open(PricingEditPopupComponent, {
      data: {
        priceRuleInput: priceRuleInput,
        existingPricingRules: this.dataSource,
        skuDetails: this.skuPrice,
        isUpdate: isUpdate,
      },
    });
    dialogRef.afterClosed().pipe(
      takeUntil(this.unsubscribe$) // Unsubscribe when the component is destroyed
    )
      .subscribe(async (response: any) => {
        if (response) {
          await this.getPricingRules();
        }
      });
  }

  private initializeSkuDetails(): VendorSkuPricing {
    return {
      vendor_sku_id: '',
      vendor_sku_name: '',
      vendor_id: '',
      skus_id: '',
      product_id: '',
      product_name: '',
      product_description: '',
      brand_id: '',
      brand_name: '',
      company_id: '',
      company_name: '',
      maximum_retail_price: 0,
      selling_price: 0,
      margin: 0,
      pricing_rules_count: 0,
      pricing_list_id: '',
      name:""
    };
  }

  private skuDetails(priceRuleDetails: VendorSkuPricing): FormGroup {
    return this.fb.group({
      pricing_list_id: new FormControl(priceRuleDetails.pricing_list_id || ''),
      vendor_sku_id: new FormControl(priceRuleDetails.vendor_sku_id || ''),
      vendor_sku_name: new FormControl(priceRuleDetails.vendor_sku_name || ''),
      vendor_id: new FormControl(priceRuleDetails.vendor_id || ''),
      skus_id: new FormControl(priceRuleDetails.skus_id || ''),
      product_id: new FormControl(priceRuleDetails.product_id || ''),
      product_name: new FormControl(priceRuleDetails.product_name || ''),
      name:new FormControl(priceRuleDetails.name || ''),
      product_description: new FormControl(
        priceRuleDetails.product_description || ''
      ),
      brand_id: new FormControl(priceRuleDetails.brand_id || ''),
      brand_name: new FormControl(priceRuleDetails.brand_name || ''),
      company_id: new FormControl(priceRuleDetails.company_id || ''),
      company_name: new FormControl(priceRuleDetails.company_name || ''),
      maximum_retail_price: new FormControl(
        priceRuleDetails.maximum_retail_price || 0
      ),
      selling_price: new FormControl(priceRuleDetails.selling_price || 0),
      margin: new FormControl(priceRuleDetails.margin || 0),
      pricing_rules_count: new FormControl(
        priceRuleDetails.pricing_rules_count || 0
      ),
    });
  }

  getDiscountTypeText(value: number): string {
    const input = {
      [PricingRule.Rate]: 'Rate',
      [PricingRule.DiscountPercentage]: 'Discount Percentage',
      [PricingRule.DiscountAmount]: 'Discount Amount',
    };
    return input[+value as PricingRule];
  }

  async deletePricingRule(productDiscount: SkuPricingRule) {
    const result = await Swal.fire({
      title: 'Do you want delete pricing rule',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    });

    if (!result.isConfirmed) {
      return;
    }

    const payLoad = {
      id: productDiscount.id,
      sku_id: this.pricingDetails.skuDetails.skus_id,
      vendor_id: this.vendorId,
      status: PricingRuleStatus.Inactive,
      min_quantity: productDiscount.max_quantity,
      max_quantity: productDiscount.max_quantity,
      type: PricingListType.Selling,
      pricing_type: productDiscount.pricing_type,
      pricing_value: productDiscount.pricing_value,
    };
    await this.pricingService.updatePricingRule(payLoad);
    Swal.fire('', 'Price Rule deleted  successfully ', 'success');
    await this.getPricingDetails();


  }
}
