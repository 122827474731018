<div class="container-fluid p-2">
  <mat-card class="p-3">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 d-flex mb-3">
          <div class="me-3">
            <mat-icon role="button" (click)="goBack()">keyboard_backspace</mat-icon>
          </div>
          <h3 class="title">Edit Expenses</h3>
        </div>
      </div>
      <form [formGroup]="form">
        <div class="row">
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <mat-label>Expensive Type</mat-label>
              <mat-select formControlName="expensesType" name="expensesType" placeholder="Expensive Type">
                <mat-option *ngFor="let option of expenses" [value]="option.value">
                  {{ option.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <mat-label>Supplier Name</mat-label>
              <input matInput formControlName="supplierName" readonly="true" />
              <!-- <mat-select formControlName="supplierName">
                <mat-option
                  *ngFor="let item of suppliersList"
                  [value]="item.id"
                >
                  {{ item.value }}
                </mat-option>
              </mat-select> -->
            </mat-form-field>
          </div>


          <div class="col-md-6">
            <mat-form-field class="example-full-width w-100">
              <mat-label>TDS Percentage</mat-label>
              <input matInput formControlName="tdsPercentage" />
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width w-100">
              <mat-label>TDS Value</mat-label>
              <input matInput formControlName="tdsValue" readonly="true" />
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width w-100">
              <mat-label>Charge Type</mat-label>
              <mat-select formControlName="chargeType" name="chargeType" placeholder="Charge Type">
                <mat-option *ngFor="let option of charges" [value]="option.value">
                  {{ option.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width w-100">
              <mat-label>Rate</mat-label>
              <input matInput formControlName="rate" />
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width w-100">
              <mat-label>Distribute Charges</mat-label>
              <mat-select formControlName="distributeCharge" name="distributeCharge" placeholder="Distribute Charges">
                <mat-option *ngFor="let option of distributeCharges" [value]="option.value">
                  {{ option.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-6" *ngIf="expenseType===ExpenseType.Discount">
            <mat-form-field class="example-full-width w-100">
              <mat-label>Discount %</mat-label>
              <input matInput formControlName="discountPercent" name="discountPercent" type="number" />
            </mat-form-field>
          </div>
          <div class="mt-10">
            <div class="col-md-12 custom-flex">
              <div class="text-center mt-2 flex-item">
                <button mat-raised-button color="primary" class="text-center ml-w" (click)="save()">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>

      </form>
    </div>
  </mat-card>
</div>