import { OrderMerchant } from '../models/order-merchant';

export enum PromotionStatus {
  Active = 1,
  InActive = 2,
  All = 3,
}
export enum WhereFilterOp {
  LessThan = '<',
  GreaterThan = '>',
  EqualTo = '==',
  LessThanOrEqualTo = '<=',
  GreaterThanOrEqualTo = '>=',
  NotEqualTo = '!=',
  ArrayContains = 'array-contains',
  ArrayContainsAny = 'array-contains-any',
  In = 'in',
  NotIn = 'not-in',
}

export enum PricingRule {
  Rate = 1,
  DiscountPercentage = 2,
  DiscountAmount = 3,
}

export enum MerchantBusinessType {
  Distributor = 1,
  Manufacturer = 2,
  Wholesaler = 3,
  JitVendor = 4,
  Inventory = 5,
  None = 99,
}

export class QueryFilter {
  fieldName: string;
  fieldValue: string;
  constructor(fieldName: string, fieldValue: string) {
    this.fieldName = fieldName;
    this.fieldValue = fieldValue;
  }
}

export enum UserType {
  Customer = 1,
  Dealer = 2,
  Merchant = 3,
  FieldAgent = 4,
  SupportMember = 5,
  MarketingAgent = 6,
  SalesPerson = 7,
  SalesLead = 8,
  SalesManager = 9,
  Guest = 99,
  ViwitoMerchants = 11,
}
export const Roles: { [key: string]: string } = {
  SuperAdmin: 'Super Admin',
  SellerAdmin: 'Seller Admin',
  SellerInventory: 'Seller Inventory',
  SellerProcurment: 'Seller Procurment',
  SellerPricing: 'Seller Pricing',
  BMSAdmin: 'BMS Admin',
  LogisticsAdmin: 'Logistics Admin',
};

export interface SalesPersonInfo {
  id: string;
  name: string;
}
export interface OrderStatusInfo {
  id: number;
  name: string;
}
export interface OrdersReport {
  businessName: string;
  customerMobileNumber: string;
  areaCode: string;
  orderNumber: string;
  orderType: string;
  orderDate: string;
  deliveredDate: string | null;
  orderAmount: number;
  orderStatus: string;
  orderTypeId: number;
  orderStatusId: number;
  customerId: string;
  salesPerson: string;
  salesPersonEmail: string;
  salesPersonMobileNumber: string;
  dealerId: string;
  businessType: string;
}
export enum OrderStatus {
  OrderPlaced = 1,
  QuotationRequested = 2,
  OrderConfirmed = 3,
  ReadyForPickup = 4,
  OrderInvoiced = 5,
  ReadyForShipment = 6,
  OutforDelivery = 7,
  Delivered = 8,
  NotDelivered = 9,
  Cancelled = 10,
  CustomerCancellationRequest = 11,
  None = 99,
}

export interface SalesPersonsOrdersSummary {
  pageNumber: number;
  pageSize: number;
  businessType: number[];
  mobileNumber: string;
  orderNumber: string;
  customerId: string;
  salespersonId: string[];
  orderStatusId: number[];
  startDate: string;
  endDate: string;
}

export enum OrderStatusFilter {
  OrderPlaced = 1,
  ReadyForPickup = 4,
  OutforDelivery = 7,
  OrderInvoiced = 5,
  Delivered = 8,
  NotDelivered = 9,
  Cancelled = 10,
  None = 99,
}

export interface OrderSearchInput {
  orderNumber: string;
  customerId: string;
  businessTypeId: number[];
  customerMobileNumber: string;
  salesperson: string[];
  startDate: string;
  endDate: string;
  orderStatus: number[];
  pageNumber: number;
  pageSize: number;
}

export interface DownloadOrdersList {
  orderNumber: string;
  customerId: string;
  businessTypeId: number[];
  customerMobileNumber: string;
  salesperson: string[];
  startDate: string;
  endDate: string;
  orderStatus: number[];
}

export interface DownloadOrderModificationRequest {
  startDate: string;
  endDate: string;
}

export interface OrderProductSearchInput {
  orderNumber: string;
  customerId: string;
  businessType: string[];
  mobileNumber: string;
  dealerId: string[];
  salespersonId: string[];
  startDate: string;
  endDate: string;
  productName: string;
  orderStatusId: string[];
}

export enum CreateUserType {
  SuperAdmin = 1,
  Admin = 2,
  DataAdmin = 3,
  Vendor = 4,
}
export enum SupplierStatus {
  InActive = 0,
  Active = 1,
}

export enum SupplierType {
  Manpower = 1,
  Transporter = 2,
  Procurement = 3,
}

export enum LogisticsSupplierType {
  Manpower = 1,
  Transporter = 2,
}

export enum ResourceType {
  Manpower = '1',
  Transporter = '2',
  Procurement = '3',
}
export enum ResourceStatus {
  InActive = 0,
  Active = 1,
}
export enum DesignationType {
  DeliveryBoy = 3,
  Driver = 4,
  SalesRepresentative = 5,
  Manager = 6,
  OperationExecutive = 12,
  OperationAdmin = 13,
}
export enum FulfillmentType {
  Qwipo = 1,
  Vendor = 2,
}

export interface MarginEntity {
  id: string;
  globalMargin: number;
  category: string;
  categoryValue: number;
}
export interface AdminConfigEntity {
  vendorId: string;
  adminConfig: any;
}
export interface MarginListSearch {
  pageNumber: number;
  pageSize: number;
}

export interface MarginForm {
  id: string;
  globalMargin: number;
  category: string;
  categoryValue: number;
}
export enum ContractMode {
  Fixed = 1,
  Adhoc = 2,
}

export enum DaysOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export enum VehicleModel {
  TwoWheeler = 2,
  ThreeWheeler = 3,
  FourWheeler = 4,
}

export enum VehicleTonnage {
  TwoKg = 1,
  FiveKg = 2,
  TenKg = 3,
  TwoTon = 4,
  FourTon = 5,
  SixTon = 6,
  SixTonAndAbove = 7,
}

// export enum DownloadBillType {
// 	GenerateEwayBill = 1,
// 	GenerateEwayBillJson = 2,
// }

export enum DownloadInvoiceType {
  GenerateEInvoice = 1,
  GenerateEInvoiceJson = 2,
}

export enum DownloadType {
  SaleRegister = 1,
  PurchaseForecast = 6,
  InvoiceSettlement = 8,
  SkuLevelSettlement = 9,
  CreditNoteRegister = 2,
  PurchaseRegister = 5,
  PurchaseReturnRegister = 24,
  Margin = 7,
  LogisticsCollections = 31,
  LogisticsCustomerReturns = 32,
  LogisticsSellerReturns = 33,
  LogisticsPurchaseReturns = 11,
  BMSCollectionReconciliation = 12,
  BMSProviderReconciliation = 13,
  BMSSellerPayout = 14,
  PurchaseReceipt = 15,
  AdminCollectionReconciliation = 21,
  AdminProviderReconciliation = 22,
  AdminSellerPayout = 23,
  ProvidersTransactionReport = 35,
}

export var states = [
  {
    id: 1,
    name: 'Andhra Pradesh',
    stateCode: 37,
  },
  {
    id: 2,
    name: 'Arunachal Pradesh',
    stateCode: 12,
  },
  {
    id: 3,
    name: 'Assam',
    stateCode: 18,
  },
  {
    id: 4,
    name: 'Bihar',
    stateCode: 10,
  },
  {
    id: 5,
    name: 'Chhattisgarh',
    stateCode: 22,
  },
  {
    id: 6,
    name: 'Delhi / New Delhi',
    stateCode: 7,
  },
  {
    id: 7,
    name: 'Goa',
    stateCode: 30,
  },
  {
    id: 8,
    name: 'Gujarat',
    stateCode: 24,
  },
  {
    id: 9,
    name: 'Haryana',
    stateCode: 6,
  },
  {
    id: 10,
    name: 'Himachal Pradesh',
    stateCode: 2,
  },
  {
    id: 11,
    name: 'Jammu & Kashmir',
    stateCode: 1,
  },
  {
    id: 12,
    name: 'Jharkhand',
    stateCode: 20,
  },
  {
    id: 13,
    name: 'Karnataka',
    stateCode: 29,
  },
  {
    id: 14,
    name: 'Kerala',
    stateCode: 32,
  },
  {
    id: 15,
    name: 'Madhya Pradesh',
    stateCode: 23,
  },
  {
    id: 16,
    name: 'Maharashtra',
    stateCode: 27,
  },
  {
    id: 17,
    name: 'Manipur',
    stateCode: 14,
  },
  {
    id: 18,
    name: 'Meghalaya',
    stateCode: 17,
  },
  {
    id: 19,
    name: 'Mizoram',
    stateCode: 15,
  },
  {
    id: 20,
    name: 'Nagaland',
    stateCode: 13,
  },
  {
    id: 21,
    name: 'Odisha (Orissa)',
    stateCode: 21,
  },
  {
    id: 22,
    name: 'Punjab',
    stateCode: 3,
  },
  {
    id: 23,
    name: 'Rajasthan',
    stateCode: 8,
  },
  {
    id: 24,
    name: 'Sikkim',
    stateCode: 11,
  },
  {
    id: 25,
    name: 'Tamil Nadu',
    stateCode: 33,
  },
  {
    id: 26,
    name: 'Telangana',
    stateCode: 36,
  },
  {
    id: 27,
    name: 'Tripura',
    stateCode: 16,
  },
  {
    id: 28,
    name: 'Uttar Pradesh',
    stateCode: 9,
  },
  {
    id: 29,
    name: 'Uttarakhand',
    stateCode: 5,
  },
  {
    id: 30,
    name: 'West Bengal',
    stateCode: 19,
  },
];

export enum SwalMessageTypes {
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
}

export interface Quotation {
  merchant: OrderMerchant;
  quotationPrice: number;
  percentage: number;
  gstAmount: number;
  quotationDate: number;
  elapsedDate: number;
}

export enum OrderType {
  Medicine = 1,
  Butchery = 2,
  Sweets = 3,
  Food = 4,
  Nursery = 5,
  VegetablesFruits = 6,
  CakesBakery = 7,
  Milk = 8,
  Fuel = 9,
  Groceries = 10,
  PoojaNeeds = 11,
  OrganicGroceries = 12,
  HealthDiet = 13,
  CarServices = 14,
  Laundry = 15,
  MilkSubscription = 16,
  None = 99,
}

export enum CouponDiscountType {
  Price = 1,
  Percentage = 2,
  None = 99,
}
export enum CalculationType {
  Price = 1,
  Percentage = 2,
}
export enum ShippingChargeType {
  Percentage = 2,
  PerKg = 3,
}
export enum ChargeBase {
  Weight = 1,
  TotalValue = 2,
}

export enum CouponProvider {
  Qwipo = 1,
  Dealer = 2,
  None = 3,
}

export enum PrescriptionType {
  Image = 1,
  Text = 2,
  None = 99,
}

export enum ThirdPartyRequestedBillStatus {
  BillRequested = 1,
  BillProcessed = 2,
  None = 99,
}

export enum OrderSource {
  Customer = 1,
  B2B = 2,
  None = 99,
}

export enum PriceUnit {
  KG = 1,
  Litre = 2,
  Grams = 3,
  MilliLitres = 4,
  SingleItem = 5,
  Pack = 6,
  Piece = 7,
  None = 99,
}

export enum PaymentType {
  COD = 1,
  Cashless = 2,
  None = 99,
}

export enum OrderPaymentStatus {
  Initiated = 1,
  Success = 2,
  Failure = 3,
  None = 99,
}
// export enum GateWayType {
//   Paytm = 1,
//   Instamojo = 2,
//   Mobikwik = 3,
//   PaySharp = 4,
//   Rupifi = 5,
//   EPayLater = 6,
//   None = 99,
// }
// export enum PaymentStatus {
//   Paid = 1,
//   UnPaid = 2,
//   InProgress = 3,
//   None = 99,
// }

export enum DeliveryStatus {
  Delivered = 1,
  NotDelivered = 2,
  Cancelled = 3,
  None = 99,
}

export interface ValuePair {
  id: number;
  name: string;
}
export interface LookUpType {
  tableName: string;
  lookupType: string;
  idColumnName: string;
  valueColumnName: string;
}

export enum RoleType {
  SupportMember = 1,
  TeleCaller = 2,
  internalDeparment = 3,
}
export class EnumEx {
  static getNames(e: any) {
    return Object.keys(e).filter((v) => isNaN(parseInt(v, 10)));
  }

  static getValues(e: any) {
    return Object.keys(e)
      .map((v) => parseInt(v, 10))
      .filter((v) => !isNaN(v));
  }

  static getNamesAndValues(e: any): Array<ValuePair> {
    return EnumEx.getValues(e).map((v) => {
      return { id: v, name: e[v] as string };
    });
  }
}
export enum PromotionType {
  Banner = 1,
  PromotionKpi = 2,
}

export enum TransitType {
  Drop = 1,
  Pickup = 2,
}
export enum bulkImportStatus {
  NotStarted = 1,
  InProgress = 2,
  Completed = 3,
  None = 99,
}

export enum PlanningTypes {
  RetailerDelivery = 1,
  SellerReturns = 2,
  None = 99,
}

export enum RefundStatus {
  Initiated = 1,
  Succeeded = 2,
  Requested = 3,
  None = 99,
}

export enum TripOrderStatus {
  OrderPlaced = 1,
  OrderPreparation = 2,
  OrderConfirmed = 3,
  ReadyForPickup = 4,
  OrderInvoiced = 5,
  ReadyForShipment = 6,
  OutForDelivery = 7,
  Delivered = 8,
  Cancelled = 10,
  None = 99,
}

export enum LogisticsStatus {
  ReadyForPickupPlan = 1,
  ReadyForPickup = 2,
  ReadyForDispatchPlan = 3,
  ReadyForDispatch = 4,
}

export enum TransitMode {
  VENDOR_DROP = 1,
  QWIPO_PICKUP = 2,
  VENDOR_DELIVERY = 3,
  QWIPO_DELIVERY = 4,
}

export enum FulfilmentType {
  QWIPO = 1,
  VENDOR = 2,
}

export enum OrderSourceType {
  CUSTOMER = 1,
  SALES_PERSON = 2,
}

export enum TripStatus {
  NotStarted = 1,
  InProgress = 2,
  Completed = 3,
  Cancelled = 4,
  None = 99,
}

export enum TripSource {
  Qwipo = 1,
  ThreePL = 2,
  Vendor = 3,
  None = 99,
}

export enum HopStatus {
  NotStarted = 1,
  Completed = 2,
  None = 99,
}

export enum PointMode {
  PICKUP = 1,
  DELIVERY = 2,
  RETURN = 3,
  NONE = 99,
}

export enum PointType {
  VENDOR = 1,
  CUSTOMER = 2,
  WAREHOUSE = 3,
  NONE = 99,
}
export enum ShowOrderStatus {
  'Order Placed' = 1,
  'Order Preparation' = 2,
  'Order Confirmed' = 3,
  'Ready For Pickup' = 4,
  'Picked Up Order' = 5,
  'Ready For Shipment' = 6,
  'Out For Delivery' = 7,
  'Delivered' = 8,
  'Cancelled' = 10,
  'None' = 99,
}

export enum BusinessTagStatus {
  'Lost' = 1,
  'Lost-Not-Interested' = 2,
  'Business-Shutdown' = 3,
  'Internal-User' = 4,
  'Active-User' = 5,
  'Duplicate' = 6,
  'Temporary-Shutdown' = 7,
  'None' = 99,
}

export enum BusinessTypeUI {
  'Restaurant' = 1,
  'Kirana' = 2,
  'Caterers' = 3,
  'TiffinCenters' = 4,
  'PgHostel' = 5,
  'Bakery' = 6,
  'SweetHouse' = 7,
  'VegetableShops' = 8,
  'Institutional' = 9,
  'Others' = 10,
  'Tier2Customer' = 11,
  'Medicals' = 12,
  'Chemist' = 14,
  'Cosmetic' = 15,
  'Supermarket' = 16,
  'Wholesale' = 17,
  'RiceTraders' = 18,
  'OilTraders' = 19,
  'None' = 99,
}

export enum CustomerStatusUI {
  'Active' = 1,
  'InActive' = 0,
}

export enum PurchaseOrderStatus {
  Created = 1,
  RequestedForGRN = 2,
  GRNCompleted = 3,
  RequestedForApproval = 4,
  Approved = 5,
}

export enum GRNStatus {
  Created = 1,
  RequestedForGRN = 2,
  GRNCompleted = 3,
  RequestedForApproval = 4,
  Approved = 5,
}

export enum TaxDeductionType {
  Inclusive = 1,
  Exclusive = 2,
  None = 99,
}

export enum UOM {
  Case = 1,
  Box = 2,
  PCs = 3,
}

export enum ExpenseType {
  Loading = 1,
  Unloading = 2,
  Transport = 3,
  Brokerage = 4,
  Discount = 5,
  PurchaseCredit = 6,
  LoadingByBuyer = 7,
}

export enum ChargeType {
  TotalValue = 1,
  RatePerKg = 2,
}

export enum DistributeCharges {
  Quantity = '1',
  Weight = '2',
}
export enum PricingListType {
  None = 99,
  Buying = 1,
  Selling = 2,
}
export enum PricingRuleStatus {
  Active = 1,
  Inactive = 0,
}

export enum PricingRuleType {
  Buying = 1,
  Selling = 2,
}

export enum ExcelIds {
  ID_1101 = 1101,
  ID_1102 = 1102,
  ID_1103 = 1103,
}

export enum RequiredSheets {
  Price = 'Price',
  PriceSlab = 'PriceSlab',
  Stock = 'Stock',
}

export enum RequiredColumns {
  NewMRP = 'NewMRP',
  NewSellingPrice = 'NewSellingPrice',
  NewRate = 'NewRate',
  NewAvailableStock = 'NewAvailableStock',
  NewDamage = 'NewDamage',
  RateOrDiscount = 'RateOrDiscount',
}

export enum PricingRulePriceType {
  Rate = 1,
  DiscountPercentage = 2,
  DiscountAmount = 3,
}
export enum AppUrlType {
  None = 0,
  ProductList = 1,
  Rupifi = 2,
}

export const BusinessTypes = [
  {
    id: 1,
    name: 'Restaurant',
  },
  {
    id: 2,
    name: 'Kirana',
  },
  {
    id: 3,
    name: 'Caterers',
  },
  {
    id: 4,
    name: 'TiffinCenters',
  },
  {
    id: 5,
    name: 'PgHostel',
  },
  {
    id: 6,
    name: 'Bakery',
  },
  {
    id: 7,
    name: 'SweetHouse',
  },
  {
    id: 8,
    name: 'VegetableShops',
  },
  {
    id: 9,
    name: 'Institutional',
  },
  {
    id: 10,
    name: 'Others',
  },
  {
    id: 11,
    name: 'Tier2Customer',
  },
  {
    id: 12,
    name: 'Medicals',
  },
  {
    id: 14,
    name: 'Chemist',
  },
  {
    id: 15,
    name: 'Cosmetic',
  },
  {
    id: 16,
    name: 'Supermarket',
  },
  {
    id: 17,
    name: 'Wholesale',
  },
  {
    id: 18,
    name: 'RiceTraders',
  },
  {
    id: 19,
    name: 'OilTraders',
  },
  {
    id: 99,
    name: 'None',
  },
];

export var CustomerStatus = [
  {
    id: 0,
    name: 'InActive',
  },
  {
    id: 1,
    name: 'Active',
  },
];

export enum AddressStatus {
  WaitingForApproval = 0,
  Approved = 1,
}

export enum DownloadPickupSheetType {
  Vendor = 1,
  Warehouse = 2,
  None = 99,
}
export enum InvoiceType {
  SaleInvoice = 1,
  CreditNote = 2,
  None = 99,
}

export enum FormatType {
  Qwipo = 1,
  Tally = 2,
}

export enum PurchaseFormatType {
  Qwipo = 1,
  Zoho = 2,
}

export enum GateWayType {
  Paytm = 1,
  Instamojo = 2,
  Mobikwik = 3,
  PaySharp = 4,
  Rupifi = 5,
  EPayLater = 6,
  None = 99,
}

export enum PaymentStatus {
  Initiated = 1,
  Success = 2,
  Failure = 3,
  Pending = 4,
  Cancelled = 5,
  Refunded = 6,
  Expired = 7,
  Declined = 8,
  None = 99,
}

export enum DownloadBillType {
  GenerateEwayBill = 1,
  GenerateEwayBillJson = 2,
}

export enum DownloadEWayType {
  DownloadEWayBill = 1,
  DownloadEWayInvoice = 2,
}
export enum UTRSummeryReport {
  Summary = 'Summary',
  SellerPayoutDetails = 'Seller Payout Details',
}

export enum WarehouseItemStatus {
  Available = 1,
  Planned = 2,
  InTransit = 3,
  Delivered = 4,
}

export enum LogisticsEvents {
  VendorPickup = 1,
  WarehouseDelivery = 2,
  CustomerDelivery = 3,
  WarehouseReturn = 4,
  VendorReturn = 5,
  WarehousePickup = 6,
}

export enum DeepLinkType {
  Promotion = 1,
  PromotionKpi = 2,
  Category = 3,
  Brand = 4,
  Vendor = 5,
  Sku = 6,
}
