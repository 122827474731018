import { get } from '@angular/fire/database';
import { Component, HostListener } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { ExcelExportService } from '../../../services/excel.service';
import { MatDialog } from '@angular/material/dialog';
import {
  getCategories,
  getDateFormat,
  getEnumNameByValue,
  isEmptyNumber,
  isEmptyValue,
  uuidv4,
  validateDecimal,
} from '../../../utlity/utility';
import Swal from 'sweetalert2';
import { CouponService } from '../../../services/coupon.service';
import { Coupon } from '../../../entities/coupon';
import { UploadProductsDialogComponent } from './upload-products-dialog/upload-products-dialog.component';
import { UploadCustomersDialogComponent } from './upload-customers-dialog/upload-customers-dialog.component';
import { CommonModule, formatDate } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { AuthService } from '../../../services/auth.service';
import { ConfigService } from '../../config/services/config.service';
import { MESSAGE_QUEUE_TYPE } from '../../../constants/message-queue-types';
import { AppLoaderService } from '../../../shared/app-loader/app-loader.service';
import { CategoryService } from '../../category/services/category.service';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { PageId } from '../../../constants/enums';
import { MerchantBusinessType } from '../../../enums/enums';

export interface CategoryWiseInput {
  minimumCategory: any | null;
  minimumCategoryDiscountPercent: number;
}
@Component({
  selector: 'app-coupon-add-edit',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    RouterLink,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatNativeDateModule,
  ],
  templateUrl: './coupon-add.component.html',
  styleUrl: './coupon-add.component.scss',
})
export class CouponAddComponent extends BaseListComponent {
  categories: any[] = [];
  businessTypes: any = [];
  categoryWiseInput: CategoryWiseInput = {
    minimumCategory: null,
    minimumCategoryDiscountPercent: 0,
  };
  products: any = [];
  customers: any = '';
  productIds: any = '';
  productNames: string[] = [];
  customerIds: any = '';
  form: FormGroup;
  private subscriptions: Subscription[] = [];
  constructor(
    private fb: FormBuilder,
    router: Router,
    route: ActivatedRoute,
    auth: AuthService,
    private couponService: CouponService,
    private excelService: ExcelExportService,
    private dialog: MatDialog,
    private configService: ConfigService,
    private loader: AppLoaderService,
    private categoryService: CategoryService
  ) {
    super(auth, router, route, PageId.seller_coupons);
    this.form = this.createCouponForm();
  }

  override async ngOnInit() {
    await super.ngOnInit();
    this.businessTypes = this.couponService.getBusinessTypes();
    this.setupValueChangeListeners();
    const queryObj = { level: 1, sort_by: 'display_order', sort_order: 'asc' };
    this.categoryService.getAll(queryObj).subscribe(async (res: any) => {
      this.categories = res.data.map((category: any) => {
        return {
          id: category.id,
          name: category?.attributes?.name,
        };
      });
    });
  }

  async saveCoupon() {
    const couponEntity = this.form.value;
    if (!this.isValidCoupon(couponEntity)) {
      return;
    }

    if (this.form.value.lastPurchasedDays < 0) {
      this.form.value.lastPurchasedDays == 0;
      Swal.fire('', 'Days Since Last Purchase cannot be negative.', 'warning');
      return;
    }

    try {
      const coupon = this.mapCoupon(couponEntity);
      this.loader.open();
      await this.couponService.saveCouponsVendorWise(coupon);
      await this.sendCreateCouponMessageToBmsMsgQueue(coupon);
      this.loader.close();
      Swal.fire({
        title: '',
        text: 'Coupon created successfully.',
        icon: 'success',
      });
      this.clearForm();
      this.router.navigateByUrl('/dashboard/coupon');
    } catch (error) {
      this.loader.close();
      console.log('error::', error);
      this.handleError(error);
    }
  }

  private async sendCreateCouponMessageToBmsMsgQueue(coupon: any) {
    const messageQueueResponse =
      await this.configService.postMessageToMessageQueue(
        MESSAGE_QUEUE_TYPE.CREATE_COUPON,
        coupon
      );
    return messageQueueResponse;
  }

  // private setupValueChangeListeners(): void {
  //   const valueChangeHandler = (controlName: string, value: any) => {
  //     if (typeof value === 'string' || Array.isArray(value)) {
  //       const maxLength = controlName === 'discountPercent' ? 5 : 10;
  //       this.form.get(controlName)?.setValue(value.slice(0, maxLength), { emitEvent: false });
  //     }
  //   };

  //   ['minValue', 'maxValue', 'discountPercent'].forEach((controlName) => {
  //     this.form.get(controlName)?.valueChanges.subscribe((value) => valueChangeHandler(controlName, value));
  //   });
  // }

  private setupValueChangeListeners(): void {
    const valueChangeHandler = (controlName: string, value: any) => {
      if (typeof value === 'string' || Array.isArray(value)) {
        const maxLength = controlName === 'discountPercent' ? 5 : 10;
        this.form
          .get(controlName)
          ?.setValue(value.slice(0, maxLength), { emitEvent: false });
      } else if (value instanceof Date) {
        const formattedDate = formatDate(value, 'yyyy-MM-dd', 'en-US');
        this.form
          .get(controlName)
          ?.setValue(formattedDate, { emitEvent: false });
      }
    };

    ['minValue', 'maxValue', 'discountPercent', 'startDate', 'endDate'].forEach(
      (controlName) => {
        this.form
          .get(controlName)
          ?.valueChanges.subscribe((value) =>
            valueChangeHandler(controlName, value)
          );
      }
    );
  }

  private mapCoupon(coupon: any): Coupon {
    return {
      id: uuidv4(),
      name: coupon?.name || '',
      displayName: coupon?.displayName || '',
      description: coupon.description || '',
      vendorId: this.vendorId || '',
      vendorInfo: {
        id: this.vendorId || '',
        name: this.vendor?.name || '',
        businessType:
          getEnumNameByValue(MerchantBusinessType, this.BusinessType) || '',
        businessTypeId: this.BusinessType || '',
      },
      createdDate: formatDate(new Date(), 'yyyy-MM-dd', 'en-US'),
      timeStamp: new Date().getTime(),
      startDate: coupon.startDate,
      endDate: coupon.endDate,
      minValue: coupon.minValue || 0,
      maxValue: coupon.maxValue || 0,
      active: coupon.active || 0,
      areaSelection: coupon.areaSelection || [],
      categorySelection: coupon.categorySelection || [],
      businessTypeSelection: coupon.businessTypeSelection,
      productIds: this.products || [],
      customerIds: this.customers || [],
      discountPercent: coupon.discountPercent || 0,
      lastPurchasedDays: coupon.lastPurchasedDays || 0,
      minimumCategoryWisePercent: coupon.minimumCategoryWisePercent || [],
    };
  }

  private createCouponForm(): FormGroup {
    return this.fb.group({
      id: uuidv4(),
      name: [null],
      displayName: [null],
      description: [''],
      minValue: [0],
      maxValue: [0],
      discountPercent: [0],
      active: [1],
      startDate: [null],
      endDate: [null],
      categorySelection: [[]],
      businessTypeSelection: [null],
      productIds: [[]],
      customerIds: [[]],
      lastPurchasedDays: [0, [Validators.min(0)]],
      areaSelection: [null],
      minimumCategoryWisePercent: [[]],
      createdDate: [null],
    });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyEvents(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  removeCategoryWisePercentFromList(index: number) {
    const controlKey = 'minimumCategoryWisePercent';
    const categoryWisePercentList = this.form.get(controlKey)?.value;

    if (Array.isArray(categoryWisePercentList)) {
      categoryWisePercentList.splice(index, 1);
      this.form.get(controlKey)?.setValue(categoryWisePercentList);
    } else {
      console.error(`${controlKey} is not defined or not an array.`);
    }
  }

  // In ngOnDestroy(), unsubscribe from all subscriptions:
  override ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  addCategoryWise() {
    // Extract input values for clarity
    const { minimumCategory, minimumCategoryDiscountPercent } =
      this.categoryWiseInput;
    if (this.categoryWiseInput.minimumCategoryDiscountPercent < 0) {
      Swal.fire('', 'Minimum category value cannot be negative.', 'warning');
      return;
    }

    if (
      isEmptyNumber(minimumCategoryDiscountPercent) ||
      minimumCategoryDiscountPercent === 0
    ) {
      Swal.fire({
        title: '',
        text: 'Please enter category percentage!',
        icon: 'warning',
      });
      return;
    }

    if (!minimumCategory || isEmptyValue(minimumCategory.id)) {
      Swal.fire({
        title: '',
        text: 'Please select category!',
        icon: 'warning',
      });
      return;
    }

    try {
      const categoryWiseDis = {
        categoryId: minimumCategory?.id,
        categoryName: minimumCategory?.name,
        minimumPercent: minimumCategoryDiscountPercent,
      };

      const control = this.form.get('minimumCategoryWisePercent');

      if (control && Array.isArray(control.value)) {
        const list = [...control.value, categoryWiseDis];
        control.setValue(list);
      } else {
        throw new Error(
          'minimumCategoryWisePercent is not an array or is undefined'
        );
      }

      // Reset input values
      this.categoryWiseInput.minimumCategory = null;
      this.categoryWiseInput.minimumCategoryDiscountPercent = 0;
    } catch (error) {
      Swal.fire({
        title: '',
        text: 'An error occurred while adding category-wise discount!',
        icon: 'error',
      });
      //this.commonService.swal("An error occurred while adding category-wise discount!", "error");
    }
  }

  downloadExcelFormat(formate: string) {
    const sampleData: Array<any> = this.getSampleExcelExportData(formate);
    this.excelService.downloadSampleUploadFile(formate, sampleData);
  }
  private getSampleExcelExportData(formate: string) {
    return formate === 'CustomerFormat'
      ? [
          {
            ID: '',
            'Business Name': '',
            'Mobile Number': '',
          },
        ]
      : [
          {
            'SKU ID': '',
            'SKU Name': '',
          },
        ];
  }

  openUploadProductsDialog(formControl: AbstractControl) {
    const dialogRef = this.dialog.open(UploadProductsDialogComponent, {
      width: '60%',
      height: '60%',
      data: { control: formControl },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result?.productsFromExcel) {
        this.products = result?.productsFromExcel;

        this.productIds = this.products
          .map((product: { skuId: any }) => product.skuId)
          .join(' , ');
      }
      this.productNames = this.products
        .map((product: { productName: any }) => product.productName)
        .join(' , ');
    });
  }
  openUploadCustomersDialog(formControl: AbstractControl) {
    const dialogRef = this.dialog.open(UploadCustomersDialogComponent, {
      width: '60%',
      height: '60%',
      data: { control: formControl },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result?.customersFromExcel) {
        this.customers = result?.customersFromExcel;
        this.customerIds = this.customers
          .map((customer: { id: any }) => customer.id)
          .join(' , ');
      }
    });
  }
  openProductUploadModal() {
    const productIdsControl = this.form.get('productIds');
    this.openUploadProductsDialog(productIdsControl!);
  }

  openCustomerUploadModal() {
    const customerIdsControl = this.form.get('customerIds');
    this.openUploadCustomersDialog(customerIdsControl!);
  }

  getCommaSeparatedIds(formControlName: string): string {
    const control = this.form.get(formControlName);

    if (!control || !Array.isArray(control.value)) {
      return '';
    }
    const cleanedIds = control.value
      .filter((product) => typeof product.id === 'string')
      .map((product) => product.id.trim().toLowerCase());

    return cleanedIds.join(',');
  }

  private isDateValid(date: any): boolean {
    return date instanceof Date && !isNaN(date.getTime());
  }

  private isValidCoupon(coupon: Coupon): boolean {
    coupon.description = coupon.description.replace(/["'\\\n\t]/g, ' ');

    if (
      isEmptyValue(coupon.name) ||
      isEmptyValue(coupon.displayName) ||
      isEmptyValue(coupon.description) ||
      isEmptyNumber(coupon.minValue) ||
      isEmptyNumber(coupon.maxValue) ||
      isEmptyNumber(coupon.discountPercent)
    ) {
      Swal.fire({
        title: '',
        text: 'Please fill all required fields!',
        icon: 'warning',
      });
      return false;
    }
    if (!coupon.startDate || !coupon.endDate) {
      Swal.fire('', 'Enter Start date & End date values ', 'warning');
      return false;
    }
    if (
      new Date(coupon.startDate).setHours(0, 0, 0, 0) <
      new Date().setHours(0, 0, 0, 0)
    ) {
      Swal.fire(
        '',
        'Start date should not be less than current date!',
        'warning'
      );
      return false;
    }
    if (new Date(coupon.endDate) < new Date(coupon.startDate)) {
      Swal.fire(
        '',
        'End date should be greater than or equal to start date!',
        'warning'
      );
      return false;
    }
    var endDateMax = new Date(coupon.startDate);
    endDateMax.setDate(endDateMax.getDate() + 30);
    if (endDateMax < new Date(coupon.endDate)) {
      Swal.fire(
        '',
        'End date should not be more than 30 days from start date!',
        'warning'
      );
      return false;
    }
    const start = new Date(coupon.startDate);
    const end = new Date(coupon.endDate);

    if (
      coupon.startDate === undefined ||
      coupon.endDate === undefined ||
      !this.isDateValid(start) ||
      !this.isDateValid(end)
    ) {
      Swal.fire({
        title: '',
        text: 'Please select valid start and end dates!',
        icon: 'warning',
      });
      return false;
    }

    if (
      parseFloat(coupon.maxValue.toString()) <
      parseFloat(coupon.minValue.toString())
    ) {
      Swal.fire('', 'Max value should not be less than Min value!', 'warning');
      return false;
    }

    if (
      parseFloat(coupon.minValue.toString()) < 0 ||
      !validateDecimal(coupon.minValue)
    ) {
      Swal.fire({
        title: '',
        text: 'Min value should be valid and positive number, and it is valid up to two decimal places only!',
        icon: 'warning',
      });
      return false;
    }

    if (
      parseFloat(coupon.maxValue.toString()) < 0 ||
      !validateDecimal(coupon.maxValue)
    ) {
      Swal.fire({
        title: '',
        text: 'Max value should be valid and positive number, and it is valid up to two decimal places only!',
        icon: 'warning',
      });
      return false;
    }

    if (
      parseFloat(coupon.minValue.toString()) >
      parseFloat(coupon.maxValue.toString())
    ) {
      Swal.fire({
        title: '',
        text: 'Max value should not be less than Min value!',
        icon: 'warning',
      });
      return false;
    }

    if (
      parseFloat(coupon.discountPercent.toString()) < 0 ||
      parseFloat(coupon.discountPercent.toString()) < 0.1 ||
      parseFloat(coupon.discountPercent.toString()) > 100 ||
      !validateDecimal(coupon.discountPercent)
    ) {
      Swal.fire({
        title: '',
        text: 'Discount value can not be less than 0.1 and greater than 100 and it is valid up to two decimal places only!',
        icon: 'warning',
      });
      return false;
    }

    // if (
    //   coupon?.categorySelection?.length === 0 &&
    //   this.products.length === 0
    // ) {
    //   Swal.fire({
    //     title: '',
    //     text: 'Please select either categories or product Ids.',
    //     icon: 'warning',
    //   });
    //   return false;
    // }

    if (
      coupon?.categorySelection?.length > 0 &&
      this.products?.length > 0
    ) {
       Swal.fire({
         title: '',
         text: 'Please select either categories or product Ids, not both.',
         icon: 'warning',
       });
       return false;
    }

    return true;
  }

  private handleError(error: any) {
    Swal.fire({
      title: '',
      text: 'Something went wrong!',
      icon: 'error',
    });
    // this.commonService.swal("Something went wrong!", "error");
  }

  private clearForm() {
    this.form.reset({
      id: uuidv4(),
      name: [null],
      schemeDisplayName: [null],
      description: [''],
      minValue: [0],
      maxValue: [0],
      discountPercent: [0],
      active: [false],
      startDate: [null],
      endDate: [null],
      categorySelection: [[]],
      businessTypeSelection: [null],
      productIds: [[]],
      customerIds: [[]],
      lastPurchasedDays: [0],
      areaSelection: [null],
      minimumCategoryWisePercent: [[]],
      createdDate: [null],
    });
    this.categoryWiseInput.minimumCategoryDiscountPercent = 0;
    this.categoryWiseInput.minimumCategory = null;
  }
}
