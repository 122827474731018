import {
  DeliveryStatus,
  OrderSource,
  OrderStatus,
  OrderType,
  PrescriptionType,
  PriceUnit,
  Quotation,
  ThirdPartyRequestedBillStatus,
} from '../enums/enums';
import { InvoiceItemEntity, Payment } from '../utlity/utility';
import { Address } from './address';
import { OrderCoupon } from './order-coupon';
import { OrderCustomer } from './order-customer';
import { OrderFieldAgent } from './order-fieldagent';
import { OrderMerchant } from './order-merchant';
import { OrderSlot } from './order-slot';
import { QuantityUnit } from './product';
export class DeliveryDetails {
  date!: number;
  reason!: string;
  deliveryStatusId: DeliveryStatus = DeliveryStatus.None;
}
export class OrderFilters {
  vendorId: string = '';
  startDate: string = '';
  endDate: string = '';
  pageSize: number = 5;
  pageNumber: number = 1;
  vendorClusterIds: number[] = [];
  orderStatusIds: number[] = [];
}
export interface OrderResponse {
  vendor_cluster_id: string;
  order_number: string;
  customer_id: string;
  customer_name: string;
  vendor_id: string;
  vendor_name: string;
  order_status_id: number;
  logistics_status_id: number;
  expected_delivery_date: string;
  total_amount: string;
  total_weight: string;
  outstanding_amount: string;
  order_date: string;
  sku_count: number;
}

export interface MappedOrderResponse {
  vendorClusterId: string;
  orderNumber: string;
  customerId: string;
  customerName: string;
  vendorId: string;
  vendorName: string;
  orderStatusId: number;
  orderStatus: string;
  logisticsStatusId: number;
  expectedDeliveryDate: string;
  totalAmount: number;
  totalWeight: string;
  outstandingAmount: string;
  orderDate: string;
  skuCount: number;
}
export interface OrderItemResponse {
  hop_id: string;
  sku_id: string;
  sku_name: string;
  image_url: string;
  upc: number;
  mrp: number;
  selling_price: number;
  quantity: number;
  total_weight: number;
  order_number: string;
  order_date: string;
  order_status_id: number;
}
export interface MappedOrderItemResponse {
  hopId: string;
  skuId: string;
  skuName: string;
  imageUrl: string;
  upc: number;
  mrp: number;
  sellingPrice: number;
  quantity: number;
  totalWeight: number;
  orderNumber: string;
  orderDate: string;
}

export interface OrderProduct {
  quantity: number;
  minQuantity: number;
  quantityUnit: QuantityUnit;
  isHalalCutItem: boolean;
  decimalBefore: number;
  decimalAfter: number;
  totalPrice: number; //quatity*product.price
  imageUrl: string; //Product Image
  categoryId: string; //Product Category Id
  totalMerchantPrice: number;
  productMini: ProductMini;
}

export interface ProductMini {
  id: string;
  name: string;
  lookupId: string;
  description: string; //small pieces
  priceUnit: PriceUnit;
  displayPriceUnit: string;

  //Actual DB Values
  merchantPrice: number;
  merchantDiscountPrice: number; // Merchant Settlement Price
  qwipoMarginPercentage: number;
  //Calculated Values Actual Merchant Price + Qwipo Margin Percent
  price: number;
  discountPrice: number;
  merchantId: string;
  shopName: string;

  // Settlement Purpose
  merchantMarkupPercent: number;
  isMerchantServiceTax: boolean;
  isMerchantIgst: boolean;
  salesTaxPercent: number;
}

export interface Order {
  orderNumber: string;
  orderDetails: OrderDetails;
  deliveryAddress: Address;
  orderDate: number;
  orderStatusId: OrderStatus;
  trackingStatusId: OrderStatus;
  orderTypeId: OrderType.None;
  customer: OrderCustomer;
  // dealer: OrderDealer = new OrderDealer();
  quotations: Array<Quotation>;
  merchants: Array<OrderMerchant>;
  quotation: Quotation;
  fieldAgent: OrderFieldAgent;
  items: Array<InvoiceItemEntity>;
  payment: Payment;
  delivery: DeliveryDetails;
  prescriptionType: PrescriptionType;
  coupon: OrderCoupon;
  deliverySlot: OrderSlot;
  inventorySlot: OrderSlot;
  isRated: boolean;
  cacnelDetails: {};
  isThirdParty: boolean;
  requestedBillStatus: ThirdPartyRequestedBillStatus;
  orderSource: OrderSource.B2B;
  id: string;
}

export interface OrderDetails {
  orderTypeId: OrderType.Medicine;
  prescriptionText: string;
  moreInfo: string;
  prescriptionImageUrl: string;
  prescriptionImageUrls: Array<any>;
  products: Array<OrderProduct>;
}

export interface OrderNumbersResponse {
  created_at: string;
  order_date: string;
  order_number: string;
  quantity: number;
  order_status_id: number;
  total_weight: number;
}

export interface ProductViewProductDetailsResponse {
  sku_id: string;
  sku_name: string;
  vendor_id: string;
  upc: number;
  selling_price: number;
  mrp: number;
  total_qty: number;
  order_status_id: number;
  inventory_selling_price?: number;
  ordernumbers: OrderNumbersResponse[];
}

export interface OrdersDetails {
  createdAt: any;
  orderDate: any;
  orderNumber: string;
  quantity: number;
  skuId?: string;
  order_status_id: number;
}

export interface OrderReturnApiInput {
  orderNumber: string;
  modifiedItems: OrderModifyEntity[];
}

export interface ModifyOrderByHop {
  modifiedItems: ModifiedItem[];
  orderNumber: string;
}

export interface ModifiedItem {
  weight: number;
  reason: string;
  quantity: number;
  sku_id: string;
}

export interface OrderModifyEntity {
  reason: string;
  quantity: number;
  sku_id: string;
}

export interface OrderToModify {
  orderNumber: string;
  quantity: number;
  skuId: string;
}

export interface ProductViewProductDetails {
  skuId: string;
  skuName: string;
  vendorId: string;
  upc: number;
  sellingPrice: number;
  mrp: number;
  pieces: number;
  cases: number;
  totalQuantity: number;
  inventory_selling_price?: number;
  orderNumbers: OrdersDetails[];
  invoiceQty: number;
  availableQty: number;
  unitWeight: number;
}

export interface productViewResponse {
  totalCount: number;
  data: ProductViewProductDetails[];
  totalRevenue: number;
  totalWeight: number;
}

export interface IProcessedProduct {
  itemId: string;
  imageUrl: string;
  mrp: number;
  sellingPrice: number;
  totalQty: number;
  invoiceQty: number;
  itemName: string;
  totalWeight: number;
  upc: number;
  pieces: number;
  cases: number;
  orders: IOrderInfo[];
}

export interface IOrderInfo {
  orderNumber: string;
  quantity: number;
  orderDate: number;
  weight: number;
  orderStatusId: number;
  hopId: string | null;
}

export interface skuProduct {
  createdAt: number;
  orderDate: number;
  orderNumber: string;
  quantity: number;
  skuId: string;
  order_status_id: number;
  total_weight: number;
}

export interface skuProductData {
  skuId: string;
  skuName: string;
  vendorId: string;
  upc: number;
  sellingPrice: number;
  mrp: number;
  pieces: number;
  cases: number;
  invoiceQty: number;
  availableQty: number;
  totalQuantity: number;
  orderNumbers: skuProduct[];
  unitWeight: number;
}
