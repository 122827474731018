import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ProductService } from '../../services/product.service';
import { Location } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import uuid4 from "uuid4";
import { MatIconModule } from '@angular/material/icon';
import { first } from 'rxjs';
import { CategorySelectorComponent } from '../../../../shared/category-selector/category-selector.component';
import { CommonService } from '../../../../services/common.service';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
  selector: 'app-details',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    RouterModule,
    MatCardModule,
    MatSelectModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    CategorySelectorComponent,
    NgSelectModule,
    FormsModule,
    RxReactiveFormsModule],
  templateUrl: './details.component.html',
  styleUrl: './details.component.scss'
})
export class DetailsComponent {
  productForm: FormGroup;
  filename: any;
  id: any;
  companyList: any = []
  brandsList: any = []
  categoriesList: any = []
  productDetails: any;
  button: boolean = false;
  productId: string = '';
  constructor(
    public fb: FormBuilder,
    public route: ActivatedRoute,
    public router: Router,
    public productService: ProductService,
    public location: Location,
    public commonService: CommonService,
  ) {
    this.productForm = this.fb.group({
      id: [uuid4(), Validators.required],
      name: [''],
      companies_id: ['', Validators.required],
      brands_id: ['', Validators.required],
      categories_id: ['', Validators.required]
    });
  }
  async ngOnInit() {
    this.getAllCompanies()
    this.getAllCategories()

    this.route.params.subscribe((params) => {
      console.log(params)
      if (history.state?.productDetails?.id) {
        this.button = true;
        const state = history.state;
        this.productDetails = state.productDetails;
        console.log("this.productDetails",this.productDetails);
      }
      this.id = params['id'];
      if (this.id) {
        this.patchValue();
      } else {
        this.productForm.get("companies_id")!.valueChanges.subscribe(res => {
          this.productForm.patchValue({ brands_id: null })
          this.getAllBrands()
        })
      }
    });
  }
  async patchValue() {
    this.commonService.showLoder()
    const productDetails = this.productService.getSingleApprovalDoc(this.id).pipe(first()).subscribe((res: any) => {
      this.productForm.patchValue(res.payload);
      this.commonService.hideLoder()
      this.getAllBrands()
      this.productForm.get("companies_id")!.valueChanges.subscribe(res => {
        this.productForm.patchValue({ brands_id: null })
        this.getAllBrands()
      })
    },
      (error: any) => {
        this.commonService.showToaster("error", error.message)
        this.commonService.hideLoder()
      })
      if (productDetails) {
        this.productForm.patchValue({
          name: this.productDetails.name,
          companies_id: this.productDetails.company_id,
          brands_id: this.productDetails.brand_id,
          categories_id: this.productDetails.category_id
        });
    }
  }

  async submit() {
    this.commonService.showLoder()
    try {
      try {
          this.router.navigate([`/dashboard/product/sku/add-sku/add`],{queryParams:this.productForm.value});
        this.commonService.hideLoder()
      } catch (err: any) {
        console.log(err);
        this.commonService.showToaster("error",err.error.message)
        this.commonService.hideLoder()
      }
    } catch (err: any) {
      console.log(err);
      this.commonService.showToaster("error",err.error.message)
      this.commonService.hideLoder()
    }
  }

  back() {
    this.location.back();
  }


  getAllCompanies() {
    this.productService.getAllCompanies().subscribe((res: any) => {
      this.companyList = res.data
    })
  }

  getAllCategories() {
    this.productService.getAllCategories().subscribe((res: any) => {
      this.categoriesList = res.data
    })
  }

  getAllBrands() {
    this.productService.getAllBrandsByCompany(this.productForm.value.companies_id).subscribe((res: any) => {
      this.brandsList = res.data
    })
  }
  async updateProduct() {
    try {
      if (this.productDetails?.id) {
        this.productForm?.get('name')?.setValue(this.productDetails?.name);
        console.log("this.productForm?.value",this.productForm?.value);
        this.productService.updateProduct(this.productDetails?.id, this.productForm?.value);
        this.commonService.showToaster("success", 'Updated Successfully')
        this.location.back();
      }
    } catch (err: any) {
      console.log(err);
      this.commonService.showToaster("error", err.error.message)
      this.commonService.hideLoder()
    }
  }
}
