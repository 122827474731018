<div>
<!----  Vendor-config-works-->
</div>

<mat-card class="custom-card">
  <mat-card-header>
    <mat-card-title>
      <span class="card-title">Customer Details</span>
    </mat-card-title>
    <div class="spacer"></div>
    <button type="button" class="btn btn-primary" routerLink="../create-employee">Edit</button>
  </mat-card-header>
  <mat-card-content>
    <div class="row">
      <div class="col-sm-8">
        <div class="row">
          <div class="col-sm-4 ft-20">Customer Id </div>
          <div class="col-sm-8 ft-19">: 3sddjdjsax38w2s</div>
        </div>
        <div class="row">
          <div class="col-sm-4 ft-20">Business Name :</div>
          <div class="col-sm-8 ft-19">: Akshith</div>
        </div>
        <div class="row">
          <div class="col-sm-4 ft-20">Customer Name </div>
          <div class="col-sm-8 ft-19">: Akshith</div>
        </div>
        <div class="row">
          <div class="col-sm-4 ft-20">Mobile Number </div>
          <div class="col-sm-8 ft-19">: 7054939399</div>
        </div>
        <div class="row">
          <div class="col-sm-4 ft-20">Registered Date</div>
          <div class="col-sm-8 ft-19">: 24-02-2024</div>
        </div>
        <div class="row">
          <div class="col-sm-4 ft-20">Source </div>
          <div class="col-sm-8 ft-19">: customerApp</div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
