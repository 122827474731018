import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Swal from "sweetalert2";
import * as XLSX from 'xlsx';
import { ExcelExportService } from '../../../../services/excel.service';
import { isEmptyValue } from '../../../../utlity/utility';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink, RouterOutlet } from '@angular/router';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';


@Component({
  selector: 'app-upload-sku-dialog',
  templateUrl: './upload-sku-dialog.component.html',
  styleUrls: ['./upload-sku-dialog.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatButtonModule,
    RouterOutlet,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatCardModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    CommonModule,
    MatTableModule,
    RouterLink,
    ReactiveFormsModule,
    MatIconModule
  ],
})
export class UploadSkuDialogComponent {
  skuFile: File | null = null;
  skusRowsWithError: any[] = [];
  skusFromExcel: any[] = [];
  skuEntities: any[] = [];
  skusErrorMessage: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>,
    private excelService: ExcelExportService
  ) {
    this.skusFromExcel = [];//data.control.value;
  }

  onFileSelect(event: any): void {
    this.skuFile = event.target.files[0] || null;
    this.convertSkuExcel();
  }
  async convertSkuExcel(): Promise<void> {

    if (!this.skuFile) {
      Swal.fire("Warning!", "Please Upload Excel File.", "warning");
      return;
    }

    this.resetSkuData();

    try {
      const excelData = await this.excelService.readExcelData(this.skuFile);
      this.skusFromExcel = excelData.map((row) => {
        return {
          skuId: row['Sku Id']
        }
      });


      if (excelData.length <= 0) {
        Swal.fire('', 'Excel should not be empty', 'error')
        // this.commonService.swal('Excel should not be empty', 'error');
        return;
      }

      if (!this.validateSkuColumns(this.skusFromExcel)) {
        Swal.fire("", "SKU Field should not be empty.", "warning");
        return;
      }

      const skuIds = this.skusFromExcel.map(sku => sku.skuId);
      const duplicateSkuIds = this.findDuplicateSkuInExcel(skuIds);

      if (duplicateSkuIds.length > 0) {
        Swal.fire("Warning!", "Please remove the duplicate skuIds.", "warning");
        this.skusErrorMessage = 'Please remove the following duplicate entries from Excel:';
        return;
      }

      this.skuEntities = this.skusFromExcel;
    } catch (error) {
      console.error('Error reading Excel data:', error);
      Swal.fire('', 'An error occurred while reading the Excel file.', 'error')
      // this.commonService.swal('An error occurred while reading the Excel file.', 'error');
    }
  }


  private resetSkuData(): void {
    this.skusRowsWithError = [];
    this.skusFromExcel = [];
    this.skuEntities = [];
    this.skusErrorMessage = '';
  }

  private findDuplicateSkuInExcel(itemList: string[]): string[] {
    const inputList = new Set<string>();
    const duplicates = new Set<string>();

    for (const item of itemList) {
      if (inputList.has(item)) {
        duplicates.add(item);
      } else {
        inputList.add(item);
      }
    }

    return Array.from(duplicates);
  }

  private validateSkuColumns(skuList: any[]): boolean {
    this.skusErrorMessage = '';
    this.skusRowsWithError = [];

    for (const sku of skuList) {
      if (isEmptyValue(sku.skuId)) {
        this.skusRowsWithError.push(sku);
        Swal.fire("", "SkuId Field should not be empty.", "warning");
        return false;
      }
    }

    return true;
  }

  setSkus(): void {
    if (this.skusFromExcel.length > 0) {
      this.dialogRef.close({ skusFromExcel: this.skusFromExcel });
    } else {
      this.dialogRef.close({ skusFromExcel: [] });
    }
  }

  cancelSkus(): void {
    this.dialogRef.close({ skusFromExcel: [] });
  }
}
