@if(hasView){
<ng-container>
  <div class="heading-block">
    <div class="row">
      <div class="col-md-6">
        <h2 class="card-title m-3">Location list</h2>
      </div>
      <div class="col-md-6 text-right">
        @if(hasCreate){
        <button mat-raised-button color="primary" [routerLink]="['/dashboard/logistics/create-location']"
          class="topButtonSectionbtn">
          Add Location
        </button>
        }
      </div>
    </div>
  </div>
  <!-- <div class="search-block">
    <form [formGroup]="form">
      <section id="content">
        <div class="card">
          <div class="card-body card-padding">
            <div class="row">
              <div class="col-md-3 searchbtns">
                <div class="text-left mt-2">
                  <button
                    mat-raised-button
                    color="primary"
                    class="text-center ml"
                    (click)="searchTasks()"
                  >
                    Search
                  </button>
                </div>
                <div class="text-right mt-2 clearfiltrtbtn">
                  <button
                    mat-raised-button
                    color="primary"
                    class="text-center ml-w"
                    (click)="clearFilters()"
                  >
                    Clear Filters
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </form>
</div>  -->

  <div class="table-block">
    <div class="m-3 flex flex-col md:flex-row justify-between">
      <mat-card class="p-0">
        <mat-card-content class="p-0">
          <mat-sidenav-container fxFlex="0 1 auto">
            <mat-sidenav-content>
              <div class="table-container">
                <mat-table [dataSource]="dataSource" matSort>
                  <!-- <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef>
                      Id
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="id">
                      {{ row.id }}
                    </mat-cell>
                  </ng-container> -->

                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>
                      Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="name">
                      {{ row.name }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="edit">
                    <mat-header-cell *matHeaderCellDef class="mat-column-actions">
                      Edit
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="mat-column-actions">
                      <div class="d-flex">
                        @if(hasEdit){
                        <button mat-icon-button (click)="editLocation(row)">
                          <mat-icon>edit</mat-icon>
                        </button>
                        }
                      </div>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="delete">
                    <mat-header-cell *matHeaderCellDef class="mat-column-actions">
                      Delete
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="mat-column-actions">
                      <div class="d-flex">
                        @if(hasDelete){
                        <button mat-icon-button (click)="deleteLocation(row)">
                          <mat-icon color="warn">delete</mat-icon>
                        </button>
                        }
                      </div>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                </mat-table>
              </div>
            </mat-sidenav-content>
          </mat-sidenav-container>
          <mat-paginator [length]="totalLocations" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="onPageChange($event)"></mat-paginator>
        </mat-card-content>
      </mat-card>
    </div>

  </div>

</ng-container>
} @else {
<ng-container>
  <p class="text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
}
