import { Component, Inject } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CompanyService } from '../../company/services/company.service';
import { BrandService } from '../../brand/services/brand.service';
import { CategoryService } from '../../category/services/category.service';
import { MatIconModule } from '@angular/material/icon';
import { NgSelectModule } from '@ng-select/ng-select';
import { getStatusTypes } from '../../../utlity/utility';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-product-filter-dialog',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIconModule,
    NgSelectModule,
    MatCardModule,
    MatTableModule,
    RouterLink,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSidenavModule,
  ],
  templateUrl: './product-filter-dialog.component.html',
  styleUrl: './product-filter-dialog.component.scss'
})
export class ProductFilterDialogComponent {
  filterForm: any;
  categoryList: any = [];
  brandList: any = [];
  companyList: any = [];
  selectedStatusIds: string[] = [];
  statuses = getStatusTypes();

  constructor(
    public dialogRef: MatDialogRef<ProductFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: FormBuilder,
    public companyService: CompanyService,
    public brandService: BrandService,
    public categoryService: CategoryService,

  ) {
    this.filterForm = this.fb.group({
      name: [null],
      companies_id: [null],
      brands_id: [null],
      categories_id: [null],
      status: [null],
      page: [1],
      limit: [25]
    })
  }

  ngOnInit(): void {
    this.filterForm.patchValue(this.data);
    this.getAll()
  }
  getStatus() {
    this.selectedStatusIds = this.filterForm.get('status')?.value || [];
  }
  getAll() {
    this.categoryService.getAll().subscribe((res: any) => {
      this.categoryList = res.data
    })
    this.brandService.getAll().subscribe((res: any) => {
      this.brandList = res.data
    })
    this.companyService.getAll().subscribe((res: any) => {
      this.companyList = res.data
    })
  }
  close() {
    this.dialogRef.close()
  }
  reset() {
    this.filterForm.patchValue(
      {
        name: null,
        companies_id: null,
        brands_id: null,
        categories_id: null,
        status: null,
        page: 1,
        limit: 25
      }
    )
    this.dialogRef.close(this.filterForm.value)
  }
  submit() {
    let data = {
      name: this.filterForm.value.name,
      companies_id: this.filterForm.value.companies_id,
      brands_id: this.filterForm.value.brands_id,
      categories_id: this.filterForm.value.categories_id,
      status: this.selectedStatusIds && this.selectedStatusIds.length ? this.selectedStatusIds : [],
      page: 1,
      limit: 25
    }
    this.dialogRef.close(data)
  }


}
