import { Component } from '@angular/core';
import { FormBuilder, Validators, ReactiveFormsModule, FormsModule, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { VehiclesService } from '../../services/vehicles.service';
import { VehicleEntity, VehicleForm, PropertyValidation, ImageDialog } from '../../models/vehicles.model';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AppLoaderService } from '../../../../../shared/app-loader/app-loader.service';
import { AuthService } from '../../../../../services/auth.service';
import { SwalMessageTypes, SupplierStatus, LookUpType, SupplierType, DesignationType, ContractMode } from '../../../../../enums/enums';
import Swal from 'sweetalert2';
import { applyMaxLengthValidation, hasSpecialCharacters, supplierListSearchInput, validateGST, validateMaxText, validateNegativeValues, validateSpecialCharacters, validateText } from '../../../../../utlity/utility';
import { LookUpTypeService } from '../../../../../services/lookup-type.service';
import { UploadImageDialogComponent } from '../upload-image-dialog/upload-image-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { BaseListComponent } from '../../../../../shared/core/base.list.component';
import { PageId } from '../../../../../constants/enums';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';

@Component({
  selector: 'app-edit-vehicle',
  standalone: true,
  imports: [MatCardModule, MatButtonModule, MatTableModule, RouterLink, MatInputModule,
    MatFormFieldModule, ReactiveFormsModule, MatSelectModule, MatIconModule,
    CommonModule, FormsModule, MatDatepickerModule, MatRadioModule, MatNativeDateModule,
    MatPaginatorModule, MatSidenavModule, MatDatepickerModule, MatCheckboxModule],
  templateUrl: './edit-vehicle.component.html',
  styleUrls: ['./edit-vehicle.component.scss']
})
export class EditVehicleComponent extends BaseListComponent {
  singularTypes = Object.values(SupplierType).filter(value => typeof value === 'number');
  supplierStatus = Object.values(SupplierStatus).filter(value => typeof value === 'number');
  vehicleContractMode = Object.values(ContractMode).filter(value => typeof value === 'number');
  vehicleModes: { type: string, value: number }[] = [
    { type: "2 wheeler", value: 2 },
    { type: "3 wheeler", value: 3 },
    { type: "4 wheeler", value: 4 },
  ];
  vehicleTonnageOptions: { tonnage: string, value: number }[] = [
    { tonnage: '2 kg', value: 1 },
    { tonnage: '5 kg', value: 2 },
    { tonnage: '10 kg', value: 3 },
    { tonnage: '2 Ton', value: 4 },
    { tonnage: '4 Ton', value: 5 },
    { tonnage: '6 Ton', value: 6 },
    { tonnage: '6 Ton And Above', value: 7 }
  ];
  vehicleStatus: { type: string, value: number}[] = [{type: 'InActive', value: 0},{type: 'Active',value: 1}]
  lookUpTypeSearchInput: LookUpType = this.initializeLookUpTypeSearchInput();
  dataSource: any;
  id: string = "";
  vehicleEntity!: VehicleEntity;
  public locations: any[] = [];
  supplierNames: LookUpType = this.initializeSuppliers();
  public supplierName: any[] = [];
  // locationNames: LookUpType = this.initializeLocation();
  public location: any[] = [];
  driverIds: LookUpType = this.initializeDriverIds();
  public driverList: any[] = [];
  driverNames: LookUpType = this.initializeDriverNames();
  public driverName: any[] = [];
  driverIdToNameMap: { [id: string]: string } = {};
  uploadedFiles: any = {};
  documentUrl: string = "";
  constructor(
    auth: AuthService,
    router: Router,
    private fb: FormBuilder,
    route: ActivatedRoute,
    private vehiclesService: VehiclesService,
    private loader: AppLoaderService,
    private lookupService: LookUpTypeService,
    private dialog: MatDialog,
   ) {
    super(auth, router,route,PageId.logistics_vehicles);
    this.dataSource = this.fb.group({
      vehicleModel: [null, [Validators.required]],
      supplierName: [null, [Validators.required]],
      vehicleTonnage: [null, [Validators.required]],
      vehicleNumber: [null, [Validators.required]],
      registrationNumber: [null, [Validators.required]],
      //registrationDocument: [null, [Validators.required]],
      noOfDays: [null, [Validators.required]],
      cost: [null, [Validators.required]],
      contractMode: [null, [Validators.required]],
      defaultDriver: [null, [Validators.required]],
      location: [null, [Validators.required]],
      status: [null, [Validators.required]]
    });
  }

  override async ngOnInit() {
    await super.ngOnInit();
    this.route.params.pipe(
      takeUntil(this.unsubscribe$) // Unsubscribe when the component is destroyed
    ).subscribe(async (params) => {
      this.id = params['id'];
    });
    await this.getLocation();
    await this.getDrivers();
    await this.getSuppliers();
    await this.getVehicleById();


    // this.editForms();

  }


  private initializeSuppliers(): LookUpType {
    return {
      tableName: "logistic_suppliers",
      lookupType: "LogisticSuppliers",
      idColumnName: "Id",
      valueColumnName: "Name"
    };
  }

  private initializeDriverIds(): LookUpType {
    return {
      tableName: "resource",
      lookupType: "resource",
      idColumnName: "id",
      valueColumnName: "designation"
    };
  }

  private initializeDriverNames(): LookUpType {
    return {
      tableName: "resource",
      lookupType: "resource",
      idColumnName: "id",
      valueColumnName: "name"
    };
  }


  async getSuppliers() {
    const response = await this.lookupService.getLookUpTypes(this.supplierNames);
    this.supplierName = response.data[0].data;
  }

  async getDrivers() {
    const response = await this.lookupService.getLookUpTypes(this.driverIds);
    this.driverList = response.data[0].data;
    this.driverList = this.driverList.filter(item => item.value === DesignationType.Driver);

    const responseNames = await this.lookupService.getLookUpTypes(this.driverNames);
    this.driverName = responseNames.data[0].data;
    this.driverName = this.driverName.map(driverId => {
      const matchedDriver = this.driverName.find(driver => driver.id === driverId.id);
      if (matchedDriver) {
        this.driverIdToNameMap[driverId.id] = matchedDriver.value;
        return matchedDriver.value;
      }
      return null;
    });
  }


  async getLocation() {
    const response = await this.lookupService.getLookUpTypes(this.lookUpTypeSearchInput);
    this.locations = response.data[0].data;
  }

  async getVehicleById() {
    try {
      this.loader.open();
      const response = await this.vehiclesService.getVehicleById(this.id);
      this.dataSource = this.mapToEditInput(response);
      this.dataSource.patchValue({
        status: response.status
        });
      console.log("this.dataSource ::",this.dataSource );
    } catch (error) {
      console.log("error::: ", error);
      this.showMessage('Failed to Get vehicle. Please try again later.', SwalMessageTypes.Error);
    } finally {
      this.loader.close();

    }
  }
  handleKeyPress(event: KeyboardEvent) {
    if (event.key === '-') {
      event.preventDefault();
    }
  }


  async updateVehicle() {
    this.loader.open();

    try {
      const costControl = this.dataSource.get('cost');
      if (costControl && costControl.value < 0) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid Cost',
          text: 'Cost cannot be negative.'
        });
        return;
      }
      if (this.dataSource.invalid) {
        this.showMessage('Please fill all required fields.', SwalMessageTypes.Warning);

        return
      }
      await this.uploadFiles();
      await this.updateVehicleById();
    } catch (error) {
      console.error('Error occurred during vehicle creation:', error);
      this.showMessage('Failed to create vehicle. Please try again later.', SwalMessageTypes.Error);
    } finally {
      this.loader.close();
    }
  }

  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }



  private mapVehicle(vehicle: VehicleForm): VehicleEntity {
    const form = vehicle;
    return {
      id: form?.id,
      vehicleModel: form?.vehicleModel,
      supplierId: form?.supplierName,
      vehicleTonnage: form?.vehicleTonnage,
      vehicleNumber: form?.vehicleNumber,
      registrationNumber: form?.registrationNumber,
      registrationDocument: this.documentUrl ? this.documentUrl : form?.registrationDocument,
      noOfDays: form?.noOfDays,
      cost: form?.cost,
      location: form?.location,
      contractMode: form?.contractMode,
      defaultDriver: form?.defaultDriver,
      status: form?.status,
    }
  }

  async updateVehicleById() {
    const vehicleEntity = this.mapVehicle(this.dataSource.value);
    if (+vehicleEntity.noOfDays < 0) {
      this.showMessage(' Please enter valid number of days', SwalMessageTypes.Warning);
      return
    }
    //return;
    try {
      const response = await this.vehiclesService.updateVehicle(vehicleEntity, this.id);
      this.showMessage('Vehicle updated successfully', SwalMessageTypes.Success);
      this.router.navigateByUrl('/dashboard/logistics/vehicle-list');
    } catch (error) {
      console.error('Error occurred while creating vehicle:', error);
      this.showMessage('Failed to create vehicle. Please try again later.', SwalMessageTypes.Error);
    }
  }


  private mapToEditInput(vehicle: VehicleEntity): FormGroup {
    const entity = vehicle;
    this.documentUrl = entity.registrationDocument;
    return this.fb.group({
      id: new FormControl(entity.id || ""),
      vehicleModel: new FormControl(entity.vehicleModel || ""),
      supplierName: new FormControl(entity.supplierName || ""),
      vehicleTonnage: new FormControl(entity.vehicleTonnage || ""),
      vehicleNumber: new FormControl(entity.vehicleNumber || "",Validators.required),
      registrationNumber: new FormControl(entity.registrationNumber || "",Validators.required),
      //registrationDocument: new FormControl(entity.registrationDocument || ""),
      noOfDays: new FormControl(entity.noOfDays || ""),
      cost: new FormControl(entity.cost || ""),
      location: new FormControl(entity.location || ""),
      contractMode: new FormControl(entity.contractMode || ""),
      defaultDriver: new FormControl(entity.defaultDriver || ""),
      status: new FormControl(entity.status || null),
    });
  }


  private validateNegativeValues(data: VehicleForm): boolean {
    const numericInputs = [
      data.noOfDays,
    ];

    return validateNegativeValues(numericInputs);
  }

  private checkSpecialCharacters(data: any): boolean {
    const inputValues = [];
    inputValues.push(data.vehicleModel);
    inputValues.push(data.registrationDocument);


    return inputValues.length > 0 ? hasSpecialCharacters(inputValues) : false;
  }

  private initializeLookUpTypeSearchInput(): LookUpType {
    return {
      tableName: "locations",
      lookupType: "locations",
      idColumnName: "id",
      valueColumnName: "name"
    };
  }
  openPopUp(imageUrl: string) {
    const dialogRef = this.dialog.open(UploadImageDialogComponent, {
      width: "50%",
      height: "40%",
      data: {
        imageUrl: imageUrl,
      } as ImageDialog
    });
    dialogRef.afterClosed().subscribe(async (response: any) => {
      if (response) {

      }
    });
  }

  private getFileExtension(filename: any) {
    return filename.split('.').pop();
  }

  async onFileChange(event: any, documentType: string) {
    if (event.target.files) {
      const file = event.target.files[0];
      const filename = file.name;
      const reader = new FileReader();

      reader.onload = (event) => {
        const result = event?.target?.result;

        if (typeof result === 'string') {
          const parts = result.split(',');
          const base64String = parts[1];
          this.uploadedFiles[documentType] = {
            fileName: filename,
            fileExtension: this.getFileExtension(filename),
            fileData: base64String,
          };
        } else if (result instanceof ArrayBuffer) {
          console.error('Unsupported file type');
          // Handle this case if needed
        }
      };

      reader.readAsDataURL(file);
    }
  }
  async uploadFiles() {
    const documentType = 'register';
    const urlEndpoint = 'supplier_aadhaar';

    if (this.uploadedFiles.hasOwnProperty(documentType)) {
      console.log("has Ownproperty");

      const requestImageBody = this.uploadedFiles[documentType];
      await this.uploadAndSetUrl(requestImageBody, documentType, urlEndpoint);
    }
  }
  private async uploadAndSetUrl(requestImageBody: any, documentType: string, urlEndpoint: string) {
    try {
      const res: any = await this.vehiclesService.imageUpload(requestImageBody, urlEndpoint);
      switch (documentType) {
        case 'register':
          this.documentUrl = res.data.url;
          break;
        default:
          console.error('Invalid document type');
          break;
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  }
}
