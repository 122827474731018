import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, map } from 'rxjs';
import axios from 'axios';
import { DownloadType } from '../../../enums/enums';

@Injectable({
  providedIn: 'root',
})
export class SellerReportsService {
  logisticsUrl: string = `${environment.logisticsUrl}`;
  messagingService: string = `${environment.messagingService}`;
  coreServiceUrl: string = `${environment.coreServiceUrl}`;
  // coreServiceUrl:string = "http://localhost:4201"

  constructor(public http: HttpClient) {}

  async downloadRegisterReports(body: any): Promise<any> {
    const url: string = `${this.coreServiceUrl}/api/download/report`;

    try {
      const response = await lastValueFrom(
        this.http.post(url, body, { responseType: 'blob' })
      );
      console.log('response is the ', response);

      if (
        response.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        const blob = new Blob([response], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        const keys: number[] = Object.values(body);
        console.log('keys are the ', keys);
        const reportName = this.getEnumNameByValue(DownloadType, keys[0]);
        // const secondKeyName = keys[1];
        link.download = reportName + '.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        console.error('Unexpected response:', response);
      }
      return response;
    } catch (error) {
      console.error('Error downloading file:', error);
      throw error;
    }
  }
  getEnumNameByValue(enumObj: any, value: number): string | undefined {
    return Object.keys(enumObj).find((key) => enumObj[key] === value);
  }
}
