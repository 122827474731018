import { inject, Injectable } from '@angular/core';
import {
  MerchantEntity,
  MerchantListSearch,
  createMerchantResponse,
} from '../models/merchants.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  collection,
  collectionData,
  doc,
  Firestore,
  query,
  setDoc,
} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class MerchantsService {
  baseServiceUrl: string = `${environment.coreServiceUrl}`;
  firestore: Firestore = inject(Firestore);

  constructor(private readonly http: HttpClient) {}

  async createMerchant(data: MerchantEntity) {
    let response = await lastValueFrom(
      this.http.post<any>(`${this.baseServiceUrl}/api/vendor/create`, data)
    );
    return response;
  }

  async getMerchants(body: MerchantListSearch): Promise<any> {
    const res = await lastValueFrom(
      this.http.post<any>(
        `${this.baseServiceUrl}/api/vendor/get_by_filters`,
        body
      )
    );

    if (res && res.data) {
      return {
        merchantList: res.data.rows,
        totalMerchants: res.data.count,
      };
    }
    return {
      merchantList: [],
      totalMerchants: 0,
    };
  }

  async getMerchantById(id: string): Promise<any> {
    const res = await lastValueFrom(
      this.http.get<any>(`${this.baseServiceUrl}/api/vendor/${id}`)
    );
    return res.data;
  }

  async updateMerchant(merchantEntity: MerchantEntity, merchantid: string) {
    let { id, ...merchantUpdatedEntity } = merchantEntity;
    let response = await lastValueFrom(
      this.http.post<{ res: createMerchantResponse }>(
        `${this.baseServiceUrl}/api/vendor/update/${merchantid}`,
        merchantUpdatedEntity
      )
    );
    return response.res;
  }

  async deleteMerchant(id: string) {
    return await lastValueFrom(
      this.http.delete(`${this.baseServiceUrl}/api/vendor/delete/${id}`)
    );
  }

  async addUsers(data: any): Promise<any> {
    data.timestamp = new Date();
    try {
      let uid = '';
      let userData = await this.getAuthUserByEmailId(data.email_id);
      if (!(userData && userData?.response)) {
        const headers = new HttpHeaders().set('No-Auth', 'true');
        let functionCall$ = this.http.post(
          `${environment.cloudFunctionsUrl}/createUser`,
          {
            email: data.email_id,
            password: data.password,
            customId: data.customId,
          },
          { headers }
        );
        const res: any = await lastValueFrom(functionCall$);
        console.log('res :', res);
        uid = res.userRecord?.userRecord?.uid;
      } else {
        uid = userData.response?.uid;
      }

      const aDoccument = doc(this.firestore, `Users/${uid}`);
      delete data.password;
      await setDoc(aDoccument, data);
      return uid;
    } catch (err) {
      console.log(err);
    }
  }
  async getAuthUserByEmailId(emailId: string): Promise<any> {
    try {
      const headers = new HttpHeaders().set('No-Auth', 'true');
      let functionCall$ = this.http.get(
        `${environment.cloudFunctionsUrl}/get_auth_user_by_email_id?emailid=${emailId}`,
        { headers }
      );
      const userResult: any = await lastValueFrom(functionCall$);
      if (userResult && userResult.response) {
        return userResult.response;
      } else {
        return null;
      }
    } catch {
      return null;
    }
  }

  getAllUsers() {
    const aCollection = collection(this.firestore, 'Users');
    let q = query(aCollection);
    let items$ = collectionData(q, { idField: 'id' });
    return items$;
  }

  async getMerchantByMobileNumber(mobileNumber: string) {
    const res = await lastValueFrom(
      this.http.get<any>(
        `${this.baseServiceUrl}/api/vendor/get_by_mobile_number/${mobileNumber}`
      )
    );
    return res.success;
  }
}
