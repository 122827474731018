@if(hasView){
<div class="row">
  <div class="col-md-6">
    <mat-icon class="backicon" [routerLink]="['/dashboard/pricing']">arrow_back</mat-icon>


  </div>
  <!-- <h2 class="m-2">Add Pricing</h2> -->
  <div class="row ">

    <div class="m-2">
      <div class="row" style="margin-bottom: 0px;">
        <div class="col-md-6">
          <h2 class="card-title m-3">Add Pricing</h2>
        </div>

      </div>
      <div class="row formcss">
        <mat-card class="m-15">
          <form [formGroup]="skuPrice" class="m-3 formcss">
            <div class="col-md-12">
              <h4 class="">
                SKU Name :
                <span style="color: green;">{{ skuPrice.value.name
                  }}</span>

              </h4>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="lableclass">
                  <mat-label>MRP</mat-label>
                </div>
                <mat-form-field appearance="outline" class="w-100">
                  <input matInput type="number" formControlName="maximum_retail_price" name="maximum_retail_price"
                    placeholder="MRP" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <div class="lableclass">
                  <mat-label>Selling Price</mat-label>
                </div>
                <mat-form-field appearance="outline" class="w-100">
                  <input matInput type="number" formControlName="selling_price" name="selling_price"
                    placeholder="Selling Price" />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <label class="errorMessage" *ngIf="isMrpOrSpNull">Maximum Retail Price (MRP) and Selling Price should not
                be null or 0.</label>
              <label class="errorMessage" *ngIf="shouldBeNumbers">Values should not have Negative or Special Characters
              </label>
              <label class="errorMessage" *ngIf="isSpGreaterMrp">Selling Price should not be higher than Maximum Retail
                Price (MRP).</label>
            </div>


            <div class="col-md-12 updatebtn">
              <button mat-raised-button type="button" color="primary" (click)="savePricing()">
                Update Price
              </button>
            </div>
          </form>
        </mat-card>
      </div>

      <div class="col-md-12" *ngIf="[1,2,3,5].includes(pricingDetails.vendorDetails.businessTypeId)">
        <div class="row">
          <div class="col-md-6 ml-5">
            <h2 class="hed"> Price Discount Slabs</h2>
          </div>
          <div class="col-md-6 text-right" *ngIf="hasCreate">
            <button mat-raised-button type=" button" color="primary" (click)="openDialog()">
              Add Price Rule
            </button>
          </div>

        </div>
        <div class="m-3 flex flex-col md:flex-row justify-between "
          [@animate]="{value:'*',params:{y:'50px',delay:'300ms'}}" style="clear: both;">
          <mat-card class="p-0">
            <mat-card-content class="p-0">
              <div class="table-container">
                <mat-table [dataSource]="dataSource" matSort>

                  <ng-container matColumnDef="minQty">
                    <mat-header-cell *matHeaderCellDef> Min Qty </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="minQty">
                      {{row.min_quantity}}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="maxQty">
                    <mat-header-cell *matHeaderCellDef> Max Qty </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="maxQty"> {{row.max_quantity}}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="discountType">
                    <mat-header-cell *matHeaderCellDef> Discount Type </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="discountType">
                      {{ getDiscountTypeText(row.pricing_type) }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="value">
                    <mat-header-cell *matHeaderCellDef> Unit Price
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="value">
                      {{row.pricing_rate | number: '1.2-2' }} </mat-cell>
                  </ng-container>


                  <ng-container matColumnDef="edit">
                    <mat-header-cell *matHeaderCellDef>Edit</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      <button mat-icon-button (click)="openDialog(row,true)" *ngIf="hasEdit">
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button mat-icon-button (click)="deletePricingRule(row)" *ngIf="hasEdit">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

                </mat-table>
              </div>
            </mat-card-content>
          </mat-card>

        </div>



      </div>
    </div>

  </div>

</div>
}@else{
<ng-container>
  <p class="text-center">
    You don't have permission , Please contact administrator
  </p>
</ng-container>
}
