import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  Address,
  CustomerEntity,
  CustomerImageResponse,
  CustomerListEntity,
  CustomerListResponse,
  CustomerListSearch,
  createCustomerResponse,
} from '../models/customers.model';
import { DB_PATH } from '../../../constants/db-path';
import { FirestoreService } from '../../../services/firestore.service';
import {
  BusinessTagStatus,
  BusinessTypeUI,
  CustomerStatusUI,
} from '../../../enums/enums';
import { getDateDDMMYYY } from '../../../utlity/utility';

@Injectable({
  providedIn: 'root',
})
export class CustomersService {
  baseServiceUrl: string = `${environment.logisticsUrl}`;
  lookupServiceUrl: string = `${environment.coreServiceUrl}`;
  fileServicesApiUrl: string = `${environment.fileUploadBaseUrl}`;

  constructor(
    private readonly http: HttpClient,
    public firestoreService: FirestoreService
  ) { }

  async createCustomer(data: CustomerEntity) {
    let response = await lastValueFrom(
      this.http.post<{ res: createCustomerResponse }>(
        `${this.baseServiceUrl}/api/customer/create`,
        data
      )
    );

    return response.res;
  }

  async exportCustomersByFilters(
    searchFields: CustomerListSearch
  ): Promise<Blob> {
    const url = `${this.lookupServiceUrl}/api/customer/download_customer_sheet`;

    const observable: Observable<Blob> = this.http.post(url, searchFields, {
      responseType: 'blob',
    });

    try {
      const result = await lastValueFrom(observable);
      return result;
    } catch (error) {
      console.error('Error exporting customers:', error);
      throw error;
    }
  }

  async getCustomersByFilters(body: CustomerListSearch): Promise<any> {
    const res = await lastValueFrom(
      this.http.post<any>(
        `${this.lookupServiceUrl}/api/customer/get_by_filters`,
        body
      )
    );
    if (res && res.data) {
      const mapEntity = this.mapData(res.data.rows);
      return {
        customerList: mapEntity,
        totalCustomers: res.data.count,
      };
    }
  }

  private mapData(data: CustomerListResponse[]): CustomerListEntity[] {
    return data.map((c) => ({
      id: c.id,
      name: c.name,
      businessName: c.business_name,
      mobileNumber: c.mobile_number,
      businessTagStatus: BusinessTagStatus[c.business_tag_status],
      businessTypeId: BusinessTypeUI[c.business_type_id],
      registeredDate: getDateDDMMYYY(new Date(c?.registered_date)),
      email: c.email,
      gstNumber: c.gst_number,
      shopUrl: c.shop_url,
      profilePicUrl: c.profile_pic_url,
      gstCertificateUrl: c.gst_certificate_url,
      registrationDocumentUrl: c.registration_document_url,
      photoIdUrl: c.photo_id_url,
      userTypeId: c.user_type_id,
      status: CustomerStatusUI[c.status],
      pushNotificationToken: c.push_notification_token,
      createdDate: getDateDDMMYYY(new Date(c.created_at)),
      updatedDate: c.updated_at,
      salespersonId: c.salesperson_id,
    }));
  }

  async getSalesPersons() {
    const response = await this.firestoreService.fetchCollection(
      DB_PATH.SALES_PERSON
    );
    return this.mapToSalesPerson(response);
  }
  private mapToSalesPerson(salesPersons: Array<any> = []) {
    // TODO need to define type of sales person

    return salesPersons
      .map((salesPerson) => {
        return {
          name: salesPerson.name || '',
          mobileNumber: salesPerson.mobileNumber || '',
          id: salesPerson.id || '',
          businessName: salesPerson.businessName || '',
          isActive: salesPerson.isActive || false,
          areaName: salesPerson.cluster ? salesPerson.cluster.name || '' : '',
          areaId: salesPerson.cluster ? salesPerson.cluster.id || '' : '',
          area: salesPerson.cluster || {},
          customerCount: salesPerson.customerCount || 0,
          salesLeadId: salesPerson.salesLeadId || '',
          salesLeadName: salesPerson.salesLead
            ? salesPerson.salesLead.name || ''
            : '',
          salesLeadMobileNumber: salesPerson.salesLead
            ? salesPerson.salesLead.mobileNumber || ''
            : '',
          salesManagerId: salesPerson.salesManagerId || '',
          salesManagerName: salesPerson.salesManager
            ? salesPerson.salesManager.name || ''
            : '',
          salesManagerMobileNumber: salesPerson.salesManager
            ? salesPerson.salesManager.mobileNumber || ''
            : '',
          enableLogOut: salesPerson.enableLogOut
            ? salesPerson.enableLogOut || false
            : false,
        };
      })
      .sort((a, b) => b.customerCount - a.customerCount);
  }
  async getCustomerById(id: string): Promise<any> {
    const res = await lastValueFrom(
      this.http.get<any>(`${this.lookupServiceUrl}/api/customer/${id}`)
    );
    return res && res.data;
  }

  async updateCustomer(customerEntity: CustomerEntity, customerid: string) {
    let { id, ...customerUpdatedEntity } = customerEntity;
    let response = await lastValueFrom(
      this.http.put<{ res: createCustomerResponse }>(
        `${this.lookupServiceUrl}/api/customer/${customerid}`,
        customerUpdatedEntity
      )
    );
    return response;
  }

  async deleteCustomer(id: string) {
    return await lastValueFrom(
      this.http.delete(`${this.baseServiceUrl}/api/customer/delete/${id}`)
    );
  }

  async imageUpload(
    body: { fileName: string; fileExtension: string; fileData: any },
    urlEndpoint: string
  ) {
    let response = await lastValueFrom(
      this.http.put<{ res: CustomerImageResponse }>(
        `${this.fileServicesApiUrl}/documents/${urlEndpoint}`,
        body
      )
    );

    return response;
  }

  async updateAddress(addressEntity: any, id: string) {
    let response = await lastValueFrom(
      this.http.put<any>(
        `${this.lookupServiceUrl}/api/customer_address/${id}`,
        addressEntity
      )
    );

    return response.success;
  }

  async getAddressById(id: string): Promise<any> {
    const res = await lastValueFrom(
      this.http.get<any>(`${this.lookupServiceUrl}/api/customer_address/${id}`)
    );
    return res && res.data;
  }

  async getMultiAddressByCustomerId(id: string): Promise<any> {
    const res = await lastValueFrom(
      this.http.get<any>(
        `${this.lookupServiceUrl}/api/customer_address/customer/${id}`
      )
    );

    if (res && res.data) {
      return {
        addressList: res.data.rows,
        totalCustomers: res.data.count,
      };
    }
  }

  async updateAddressById(addressId: string, body: { street: string, landmark: string, area_code: string, city: string, state: string, country: string, latitude: number, longitude: number, pincode: number, is_default: number }): Promise<boolean> {
    try {

      const res = await lastValueFrom(
        this.http.put<any>(
          `${this.lookupServiceUrl}/api/customer_address/${addressId}`, body
        )
      );

      return res && res.success;
    } catch (error) {
      return false;
    }
  }
}
