import { Component } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-sku-list',
  standalone: true,
  imports: [RouterModule,MatTabsModule],
  templateUrl: './sku-list.component.html',
  styleUrl: './sku-list.component.scss'
})
export class SkuListComponent {

  constructor(public router: Router){
  }
}
