import { Component, Input } from '@angular/core';
import { CommonService } from '../../../../services/common.service';
import { VarientTypeService } from '../../../../pages/varientType/services/varientType.service';
import { StatusComponent } from '../../../status/status.component';
import { TypePipe } from '../../../pipes/type.pipe';

@Component({
  selector: 'app-add-variant-value',
  standalone: true,
  imports: [StatusComponent,TypePipe],
  templateUrl: './add-variant-value.component.html',
  styleUrl: './add-variant-value.component.scss'
})
export class AddVariantValueComponent {
  @Input() data:any;
  variantsName:any;
  constructor(public commonService: CommonService, public varientTypeService: VarientTypeService)
   {
    this.commonService.showLoder()
    setTimeout(() => {
  this.getAll()
    }, 1000);
   }
   async getAll(){
    this.commonService.showLoder()
    try{
    let data:any =  await  this.varientTypeService.getSingle(this.data?.payload?.variants_id)
 this.variantsName = data.data[0].attributes.name
  this.commonService.hideLoder()
}
catch(err:any){
  this.commonService.hideLoder()
  this.commonService.showToaster('error',err.error.message)
}
   }
}
