import { Injectable, inject } from '@angular/core';
import { FirebaseDbService } from '../../../services/firebase-db.service';
import { HttpClient } from '@angular/common/http';
import { FirestoreService } from '../../../services/firestore.service';
import { MasterCluster } from './master-geo-model';
import { DB_PATH } from '../../../constants/db-path';

@Injectable({
  providedIn: 'root',
})
export class MasterGeoService {
  constructor(
    public firestoreService: FirestoreService,
    private firebaseDbService: FirebaseDbService,
    private http: HttpClient
  ) {}

  async getAllMasterClusters(): Promise<any> {
    const response = await this.firestoreService.fetchCollection(
      DB_PATH.MASTER_POLYGON
    );

    return this.mapToCluster(response as MasterCluster[]);
  }

  async createMasterCluster(cluster: MasterCluster) {
    return await this.firestoreService.insertDocWithId(
      DB_PATH.MASTER_POLYGON,
      cluster.id,
      cluster
    );
  }

  private mapToCluster(data: MasterCluster[] | MasterCluster) {
    if (Array.isArray(data)) {
      return data
        .map((c) => {
          return {
            id: c.id,
            name: c.name,
            searchKey: c.searchKey,
            coordinates: c.coordinates,
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name));
    } else {
      return {
        id: data.id,
        name: data.name,
        searchKey: data.searchKey,
        coordinates: data.coordinates,
      };
    }
  }

  async getAllClusters(): Promise<any> {
    const response = await this.firestoreService.fetchCollection(
      DB_PATH.LOGISTIC_CLUSTERS
    );

    return response;
  }
  async createCluster(cluster: any) {
    return await this.firestoreService.insertDocWithId(
      DB_PATH.LOGISTIC_CLUSTERS,
      cluster.id,
      cluster
    );
  }

  async deleteCluster(id: string) {
    console.log('deleteCluster Doc Id', id);
    return await this.firestoreService.deleteDoc(DB_PATH.LOGISTIC_CLUSTERS, id);
  }

  async getCluster(clusterId: string): Promise<any> {
    const data = await this.firestoreService.fetchDocument(
      DB_PATH.LOGISTIC_CLUSTERS,
      clusterId
    );

    if (data) {
      // Cast the data to Cluster
      // return data as Cluster;

      return data;
    } else {
      // Handle the case where the document is not found
      throw new Error('No such document!');
    }
  }
  async updateCluster(clusterId: string, updateObj: any) {
    return await this.firestoreService.updateDocument(
      DB_PATH.LOGISTIC_CLUSTERS,
      clusterId,
      updateObj
    );
  }
}
