<div class="container">
  @if(hasView){
  <ng-container>
    <div class="heading-block">
      <div class="row">
        <div class="col-md-6">
          <!-- <mat-icon
          class="backicon"
          [routerLink]="['/dashboard/logistics/trip-list']"
          >arrow_back</mat-icon
        > -->
          <span class="header">
            <h2 class="card-title m-3">Trip Assignment</h2>
          </span>
        </div>
        <div class="col-md-6">
          @if(hasCreate){
          <button mat-raised-button color="primary" class="topButtonSectionbtn btn-width"
            [matMenuTriggerFor]="generateOptionsMenu">
            Generate
            <mat-icon>arrow_drop_down</mat-icon>
          </button>



          <button mat-raised-button *ngIf="hasExport" color="primary" class="topButtonSectionbtn btn-width"
            [matMenuTriggerFor]="downloadOptionsMenu">
            Download
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          <button mat-raised-button color="primary" class="topButtonSectionbtn btn-width" (click)="tripOptimization()">
            Route Optimization
          </button>
          }
        </div>
      </div>
    </div>

    <!-- @if(dataSource.pickupCount == 1){ -->
    <mat-menu #generateOptionsMenu="matMenu" class="btn-width">
      <button mat-menu-item *ngFor="let option of generateOptions" (click)="onGenerateOptionClick(option)">
        {{ option }}
      </button>
    </mat-menu>
    <!-- } -->


    <mat-menu #downloadOptionsMenu="matMenu" class="btn-width">
      <button mat-menu-item *ngFor="let option of downloadOptions" (click)="onDownloadOptionClick(option)">
        {{ option }}
      </button>
    </mat-menu>

    <div class="search-block">
      <form [formGroup]="dataSource">
        <section id="content">
          <div class="card">
            <div class="card-body card-padding">
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Trip Name</mat-label>
                    <input matInput type="string" formControlName="tripName" name="tripName" placeholder="Trip Name"
                      [readonly]="true" />
                  </mat-form-field>
                </div>
                <!-- <div class="col-md-6">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    formControlName="tripDate"
                    placeholder="Trip Date"
                    [matDatepicker]="appDatepicker"
                    [readonly]="true"
                  />

                  <mat-datepicker-toggle
                    matSuffix
                    [for]="appDatepicker"
                  ></mat-datepicker-toggle>
                </mat-form-field>

                <mat-datepicker #appDatepicker></mat-datepicker>
              </div> -->
                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Trip Date</mat-label>
                    <input matInput type="string" formControlName="tripDate" name="tripDate" placeholder="Trip Date"
                      [readonly]="true" />
                  </mat-form-field>
                </div>

                <div class="col-md-6" *ngIf="statusId !== 3">
                  <mat-form-field class="full-width">
                    <mat-label>DE Name</mat-label>
                    <input matInput type="string" formControlName="deName" name="deName" placeholder="DE Name"
                      [readonly]="true" />
                    <button matSuffix mat-icon-button (click)="openDialog('DE Name', dataSource.get('deName').value)">
                      <mat-icon>open_in_new</mat-icon>
                    </button>
                  </mat-form-field>
                </div>

                <div class="col-md-6" *ngIf="statusId == 3">
                  <mat-form-field class="full-width">
                    <mat-label>DE Name</mat-label>
                    <input matInput type="string" formControlName="deName" name="deName" placeholder="DE Name"
                      [readonly]="true" />
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>DE Number</mat-label>
                    <input matInput type="string" formControlName="deNumber" name="deNumber" placeholder="DE Number"
                      [readonly]="true" />
                  </mat-form-field>
                </div>
                <div class="col-md-6" *ngIf="statusId !== 3">
                  <mat-form-field class="full-width">
                    <mat-label>Driver Name</mat-label>
                    <input matInput type="string" formControlName="driverName" name="driverName"
                      placeholder="Driver Name" [readonly]="true" />
                    <button matSuffix mat-icon-button
                      (click)="openDialog('Driver Name', dataSource.get('driverName').value)">
                      <mat-icon>open_in_new</mat-icon>
                    </button>
                  </mat-form-field>
                </div>

                <div class="col-md-6" *ngIf="statusId == 3">
                  <mat-form-field class="full-width">
                    <mat-label>Driver Name</mat-label>
                    <input matInput type="string" formControlName="driverName" name="driverName"
                      placeholder="Driver Name" [readonly]="true" />
                  </mat-form-field>
                </div>



                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Driver Number</mat-label>
                    <input matInput type="string" formControlName="driverNumber" name="driverNumber"
                      placeholder="Driver Number" [readonly]="true" />
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Vehicle Number</mat-label>
                    <input matInput type="string" formControlName="vehicleNumber" name="vehicleNumber"
                      placeholder="Vehicle Number" [readonly]="true" />
                    <button matSuffix mat-icon-button
                      (click)="openDialog('Vehicle Number', dataSource.get('vehicleNumber').value)">
                      <mat-icon>open_in_new</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Status</mat-label>
                    <input matInput type="string" formControlName="status" name="status" placeholder="Status"
                      [readonly]="true" />
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>

    <div class="search-block">
      <section id="content">
        <div class="card">
          <div class="card-body card-padding">
            <div class="row">
              <div class="col-md-3" style="font-size: 23px; font-weight: bold;margin-bottom: 10px;">
                <h2 class="card-title m-3">Summary</h2>

              </div>
            </div>

            <div class="row">
              <div class="col-md-6 col-xs-12">
                <div class="card">
                  <div class="card-body card-padding">
                    <div class="col-md-3" style="font-size: 21px; font-weight: bold">
                      Trip Details
                    </div>
                    <div class="row1" style="padding-top: 19px">
                      <div>Pick-Up</div>
                      <div>: {{ dataSource.value.pickupCount === 0 ? 0 : dataSource.value.pickupCount | number:'1.2-2'
                        }}
                      </div>
                    </div>
                    <div class="row1" style="padding-top: 19px">
                      <div>Deliveries</div>
                      <div>: {{ dataSource.value.deliveryCount === 0 ? 0 : dataSource.value.deliveryCount |
                        number:'1.2-2'
                        }}</div>
                    </div>
                    <div class="row1" style="padding-top: 19px">
                      <div>Returns</div>
                      <div>: {{ dataSource.value.returnsCount === 0 ? 0 : dataSource.value.returnsCount |
                        number:'1.2-2'}}
                      </div>
                    </div>
                    <div class="row1" style="padding-top: 19px">
                      <div>KMS</div>
                      <div>: {{ dataSource.value.kms }}</div>
                    </div>
                    <div class="row1" style="padding-top: 19px">
                      <div>Weight (Kgs)</div>
                      <div>: {{ dataSource.value.pickupTotalWeight === 0 ? 0 : dataSource.value.pickupTotalWeight |
                        number:'1.2-2' }} </div>
                    </div>
                    <div class="row1" style="padding-top: 19px">
                      <div>Products</div>
                      <div>: {{ dataSource.value.uniqueSkusCount }}</div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="col-md-6 col-xs-12">
                <div class="card">
                  <div class="card-body card-padding">
                    <div class="col-md-3" style="font-size: 21px; font-weight: bold">
                      Collections
                    </div>



                    <div class="row1" style="padding-top: 19px">
                      <div>Customer Delivery Value</div>
                      <div>
                        {{ dataSource.value.valueSummary?.retailDeliveryValue |
                        replaceCommas}}
                      </div>
                    </div>


                    <div class="row1" style="padding-top: 19px">
                      <div>Customer Return Value</div>
                      <div>
                        {{ dataSource.value.valueSummary?.warehouseReturnValue |
                        replaceCommas}}
                      </div>
                    </div>

                    <div class="row1" style="padding-top: 19px">
                      <div>Vendor Pickup/Drop Value</div>
                      <div>
                        {{ dataSource.value.valueSummary?.vendorPickupValue |
                        replaceCommas}}
                      </div>
                    </div>

                    <div class="row1" style="padding-top: 19px">
                      <div>Vendor Returns</div>
                      <div>
                        {{ dataSource.value.valueSummary?.vendorReturnsValue |
                        replaceCommas}}
                      </div>
                    </div>






                    <!--
                    <div class="row1" style="padding-top: 19px">
                      <div>Dispatch Value</div>
                      <div>
                        {{ dataSource?.value?.dispatchValue !== undefined && dataSource?.value?.dispatchValue !== null
                        ? (dataSource.value.dispatchValue === 0 ? 0 : dataSource.value.dispatchValue | replaceCommas)
                        : 0 }}
                      </div>
                    </div> -->
                    <div class="row1" style="padding-top: 19px">
                      <div>Customer COD Collections</div>
                      <div>
                        {{ dataSource.value?.collectionSummary?.retailCashCollections |
                        replaceCommas
                        }}
                      </div>
                    </div>
                    <div class="row1" style="padding-top: 19px">
                      <div>Customer Online Payments</div>
                      <div>
                        {{dataSource.value?.collectionSummary?.retailOnlineCollections |
                        replaceCommas
                        }}
                      </div>
                    </div>
                    <!-- <div class="row1" style="padding-top: 19px">
                      <div>Returns</div>
                      <div> {{ dataSource?.value?.returnsCollections !== undefined &&
                        dataSource?.value?.returnsCollections !== null
                        ? (dataSource.value.returnsCollections === 0 ? 0 : dataSource.value.returnsCollections |
                        replaceCommas)
                        : 0 }}</div>
                    </div> -->
                    <div class="row1" style="padding-top: 19px">
                      <div>Balance(Pending Amount)</div>
                      <div>
                        <div>
                          {{ dataSource?.value?.balance !== undefined && dataSource?.value?.balance !== null
                          ? (dataSource.value.balance === 0 ? 0 : dataSource.value.balance | replaceCommas)
                          : 0 }}
                        </div>
                      </div>
                    </div>
                    <div class="row1" style="padding-top: 19px"></div>
                    <div class="row1" style="padding-top: 19px"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </ng-container>
  } @else {
  <ng-container>
    <p class="text-center">
      You dont have permission , Please contact administrator
    </p>
  </ng-container>
  }
</div>