import { BusinessType } from './../pages/customers/models/customers.model';
import {
  DeepLinkType,
  FulfillmentType,
  MerchantBusinessType,
  PricingRule,
  PromotionType,
  TransitMode,
  TransitType,
} from '../enums/enums';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import {
  MultiPropertyValidation,
  PropertyValidation,
  SupplierListSearch,
  TextInput,
  ValidationResult,
  validateName,
} from '../pages/logistics/supplier/models/supplier-model';
import Swal from 'sweetalert2';

export function uuidv4() {
  return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  );
}

export function orderStatus() {
  return [
    { name: 'OrderPlaced', id: 1 },
    // { name: "OrderConfirmed", id: 3 },
    // { name: "ReadyForPickup", id: 4 },
    // { name: "PickedUpOrder", id: 5 },
    { name: 'ReadyForShipment', id: 6 },
    { name: 'OutforDelivery', id: 7 },
    { name: 'Delivered', id: 8 },
    // { name: "NotDelivered", id: 9 },
    { name: 'Cancelled', id: 10 },
    // { name: "CustomerCancellationRequest", id: 11 },
    // { name: "None", id: 99 }
  ];
}

export function removeEndSpaces(inputString: string) {
  return inputString.toLowerCase().replace(/[^a-z0-9]/g, '');
}

export function convertToTimeStamp(strDate: string | Date) {
  const expiryDate = strDate + ' ' + '23:31:30';
  const dt = Date.parse(expiryDate);

  return dt / 1000;
}

export function generatePromotionId() {
  var text = 'PROM';
  var possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (var i = 0; i < 10; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}
export function generateLogisticsClusterId() {
  var text = 'LC_';
  var possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (var i = 0; i < 10; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

export function getServiceApiUrl(env: string) {
  return {
    development: `https://dev-qwipo.azurewebsites.net/services/api`,
    test: `https://test-qwipo.azurewebsites.net/services/api`,
    staging: `https://staging-qwipo.azurewebsites.net/services/api`,
    production: `https://qwipo.com/services/api`,
  }[env];
}

export function getDiscountTypeValue(type: PricingRule) {
  return (
    {
      1: 'Rate',
      2: 'DiscountPercentage',
      3: 'DiscountAmount',
    }[type] || 'Rate'
  );
}

export function containsOnlyNumbers(...texts: string[]): boolean {
  var pattern = /^\d*\.?\d+$/;

  return texts.every((text) => pattern.test(text));
}
export function isEmptyValueNonZero(data: string) {
  if (data === ' ' || data === '' || data === undefined || data === null) {
    return true;
  }
  return false;
}
export function isNonNegativeValue(data: any): boolean {
  const regex = /^-\d+(\.\d+)?$/;
  return regex.test(data.toString());
}

export function toCustomArray(object: Record<string, any> = {}) {
  object = object || {};

  return Object.keys(object).map((key) => {
    let actualObject = object[key];
    let idExists = Object.keys(actualObject).some(
      (property) => property === 'id'
    );
    actualObject['id'] = key;
    return object[key];
  });
}

export function isValidGeoJsonFile(filename: string) {
  const ext = getExtension(filename);
  return ext.toLowerCase() === 'geojson' || ext.toLowerCase() === 'json';
}

function getExtension(filename: string) {
  const parts = filename.split('.');
  return parts[parts.length - 1];
}

export function capitalizeFirstLetter(inputString: string): string {
  if (!inputString) {
    return inputString;
  }
  return (
    inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase()
  );
}

export function isAreaNameValid(areaName: string): boolean {
  const areaNameRegex = /^(?!.* {2})[A-Za-z0-9-]+(?:[ \t]+[A-Za-z0-9-]+)*$/;
  const trimmedAreaName = areaName.trim();

  return areaNameRegex.test(trimmedAreaName);
}
export function removingSpace(inputString: string): string {
  const trimmedAndLowercase = inputString.trim().toLowerCase();
  const cleanedString = trimmedAndLowercase.replace(/ +/g, ' ');

  return cleanedString;
}
export function extractCoordinates(
  data: [number, number][]
): { latitude: number; longitude: number }[] {
  return data.map(([longitude, latitude]) => ({ latitude, longitude }));
}
export function generateClusterId() {
  var text = 'AREA';
  var possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (var i = 0; i < 10; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

export function getCategories() {
  return [
    {
      id: '177c2830-c299-4460-bf6c-1373e6fafd1b',
      name: 'Rice & Rice Products',
    },
    {
      id: '184f8f20-d8b9-11eb-b8bc-0242ac130003',
      name: 'Spreads,Sauce & Ketchup',
    },
    {
      id: '1b0c4ffa-ad53-11eb-8529-0242ac130003',
      name: 'Dry Fruits',
    },
    {
      id: '435b0a10-b53b-11eb-8529-0242ac130003',
      name: 'Ready to Cook & Eat',
    },
    {
      id: '560620df-3978-4d44-bdde-da624c080fb9',
      name: 'Edible Oils & Ghee',
    },
    {
      id: '5ce334de-6aa0-11eb-9439-0242ac130002',
      name: 'Cleaning & Household',
    },
    {
      id: '72add594-653d-11ea-bc55-0242ac130003',
      name: 'Dals & Pulses',
    },
    {
      id: 'af3ea579-7804-44db-8f7a-a01e001697ba',
      name: 'Salt, Sugar & Spices',
    },
    {
      id: 'cbf9467e-b3de-11eb-8529-0242ac130003',
      name: 'Oats,Noodles,Pasta &Vermicelli',
    },
    {
      id: 'cbf948b8-b3de-11eb-8529-0242ac130003',
      name: 'Pickles & Podis (Powders)',
    },
    {
      id: 'd2401aa8-ad99-11eb-8529-0242ac130003',
      name: 'Atta, Flours & Sooji',
    },
    {
      id: 'df2c0e80-f06a-4c79-9395-848cf5a8fc57',
      name: 'Service Model Category',
    },
    {
      id: 'e0b773c8-b894-11eb-8529-0242ac130003',
      name: 'Pooja Needs',
    },
    {
      id: 'e90f03c6-6a9f-11eb-9439-0242ac130002',
      name: 'Personal Care',
    },
    {
      id: 'e90f05d8-6a9f-11eb-9439-0242ac130002',
      name: 'Biscuits,Snacks & Namkeeens',
    },
    {
      id: 'e90f06c8-6a9f-11eb-9439-0242ac130002',
      name: 'Beverages',
    },
  ];
}
export function getProviders() {
  return ['Rupify', 'PaySharp', 'Instamojo', 'EPaylater'];
}
export function getFulfillment() {
  const enumKeyValueArray: {
    fulfillmentProvider: string;
    fulfillmentId: number;
  }[] = [];
  for (const fulfillmentProvider in FulfillmentType) {
    if (isNaN(Number(fulfillmentProvider))) {
      const fulfillmentId =
        FulfillmentType[fulfillmentProvider as keyof typeof FulfillmentType];
      enumKeyValueArray.push({ fulfillmentProvider, fulfillmentId });
    }
  }
  return enumKeyValueArray;
}
export function getMerchantBusinessType() {
  const enumKeyValueArray: {
    businessTypeName: string;
    businessTypeId: number;
  }[] = [];
  for (const businessTypeName in MerchantBusinessType) {
    if (isNaN(Number(businessTypeName))) {
      const businessTypeId =
        MerchantBusinessType[
          businessTypeName as keyof typeof MerchantBusinessType
        ];
      enumKeyValueArray.push({ businessTypeName, businessTypeId });
    }
  }
  return enumKeyValueArray;
}
export function getPromotionType() {
  const enumKeyValueArray: {
    promotionType: string;
    promotionTypeId: number;
  }[] = [];
  for (const promotionType in PromotionType) {
    if (isNaN(Number(promotionType))) {
      const promotionTypeId =
        PromotionType[promotionType as keyof typeof PromotionType];
      enumKeyValueArray.push({ promotionType, promotionTypeId });
    }
  }
  return enumKeyValueArray;
}
export function getDeeplinksType() {
  const enumKeyValueArray: {
    deepLinkType: string;
    deepLinkTypeId: number;
  }[] = [];
  for (const deepLinkType in DeepLinkType) {
    if (isNaN(Number(deepLinkType))) {
      const deepLinkTypeId =
        DeepLinkType[deepLinkType as keyof typeof DeepLinkType];
      enumKeyValueArray.push({ deepLinkType, deepLinkTypeId });
    }
  }
  return enumKeyValueArray;
}
export function getTransitModes() {
  const enumKeyValueArray: {
    transitModeName: string;
    transitModeId: number;
  }[] = [];
  for (const transitMode in TransitMode) {
    if (isNaN(Number(transitMode))) {
      const transitModeId =
        TransitMode[transitMode as keyof typeof TransitMode];
      const transitModeName = convertToReadableString(transitMode);
      enumKeyValueArray.push({ transitModeName, transitModeId });
    }
  }
  return enumKeyValueArray;
}
export interface FieldAgent {
  id: string;
  token: string;
  userTypeId: UserType.FieldAgent;
  name: string;
  isActive: boolean;
  nameLowerCase: string;
  email: string;
  mobileNumber: string;
  dealerId: string;
  address: Address;
}

export enum PaymentStatus {
  Paid = 1,
  UnPaid = 2,
  InProgress = 3,
  None = 99,
}

export enum PaymentType {
  COD = 1,
  Cashless = 2,
  None = 99,
}
export enum CouponProvider {
  Qwipo = 1,
  Dealer = 2,
  None = 3,
}

export enum GateWayType {
  Paytm = 1,
  Instamojo = 2,
  None = 3,
  Paysharp = 4,
}
export enum OrderPaymentStatus {
  Initiated = 1,
  Success = 2,
  Failure = 3,
  None = 99,
}

export class Payment {
  itemsTotal: number = 0;
  discountAmount: number = 0;
  collectionFee: number = 0;
  shippingFee: number = 0;
  deliveryFee: number = 0;
  surchargeFee: number = 0;
  packingCharge: number = 0;
  dealerFee: number = 0;
  fixedFee: number = 0;
  marketPlaceFee: number = 0;
  serviceTaxFee: number = 0;
  salesTaxFee: number = 0;
  totalAmount: number = 0;
  qwipoCash: number = 0;
  qwipoAmount: number = 0;
  invoiceUrl: string = '';
  invoiceNumber: string = '';
  paymentTypeId?: PaymentType.COD;
  paytmTransactionId: string = '';
  onlineTransactionId: string = '';
  gateWayTransactionId: string = '';
  gateWayType: GateWayType = GateWayType.None;
  paymentStatus: OrderPaymentStatus = OrderPaymentStatus.None;
  couponProvider: CouponProvider = CouponProvider.None;
  paymentIn?: {
    paymentStatusId: PaymentStatus;
    dueAmount: number;
    paymentDate: number;
  };
  paymentOut?: {
    dealer: {
      paymentStatusId: PaymentStatus;
      paymentDate: number;
      settlementAmount: number;
    };
    dealerToMerchants: any;
    merchants: any;
    executive: {
      id: '';
      paymentStatusId: PaymentStatus;
      paymentDate: number;
      settlementAmount: number;
    };
  };
}

export interface InvoiceItemEntity {
  description: string;
  quantity: number;
  amount: number;
}

export enum UserType {
  Customer = 1,
  Dealer = 2,
  Merchant = 3,
  FieldAgent = 4,
  SupportMember = 5,
  MarketingAgent = 6,
  SalesPerson = 7,
  SalesLead = 8,
  SalesManager = 9,
  Guest = 99,
}

export class Address {
  street: string = '';
  area: string = '';
  areaCode?: string = '';
  state: string = '';
  country: string = 'INDIA';
  pincode: string = '';
  city: string = '';
  cityLowerCase: string = '';
  landMark: string = '';
  longitude: number = 0;
  latitude: number = 0;
  subAreaCode?: string = '';
  subAreaName?: string = '';
}

export function getCustomerBusinessTypes() {
  return [
    { name: 'Kirana', isSelected: false, value: 'Kirana' },
    { name: 'Restaurant', isSelected: false, value: 'Restaurant' },
    { name: 'Caterers', isSelected: false, value: 'Caterers' },
    { name: 'TiffinCenters', isSelected: false, value: 'TiffinCenters' },
    { name: 'PG/Hostel', isSelected: false, value: 'PG/Hostel' },
    { name: 'Institutional', isSelected: false, value: 'Institutional' },
    { name: 'Bakery', isSelected: false, value: 'Bakery' },
    { name: 'Sweet House', isSelected: false, value: 'Sweet House' },
    { name: 'Vegetable shops', isSelected: false, value: 'Vegetable shops' },
    { name: 'Tier2Customer', isSelected: false, value: 'Tier2Customer' },
    { name: 'Medicals', isSelected: false, value: 'Medicals' },
    { name: 'bulkcategory', isSelected: false, value: 'Bulkcategory' },
    { name: 'chemist', isSelected: false, value: 'Chemist' },
    { name: 'cosmetic', isSelected: false, value: 'Cosmetic' },
    { name: 'supermarket', isSelected: false, value: 'Supermarket' },
    { name: 'Wholesale', isSelected: false, value: 'wholesale' },
    { name: 'Rice Traders', isSelected: false, value: 'ricetraders' },
    { name: 'Oil Traders', isSelected: false, value: 'oiltraders' },
    { name: 'Others', isSelected: false, value: 'Others' },
  ];
}
export function getBusinessTypes() {
  const enumKeyValueArray: {
    businessTypeName: string;
    businessTypeId: number;
  }[] = [];
  for (const businessType in BusinessType) {
    if (isNaN(Number(businessType))) {
      const businessTypeId =
        BusinessType[businessType as keyof typeof BusinessType];
      const businessTypeName = convertToReadableString(businessType);
      enumKeyValueArray.push({ businessTypeName, businessTypeId });
    }
  }
  return enumKeyValueArray;
}
export function getTransitType() {
  const enumKeyValueArray: {
    transitTypeName: string;
    transitTypeId: number;
  }[] = [];
  for (const transitTypeName in TransitType) {
    if (isNaN(Number(transitTypeName))) {
      const transitTypeId =
        TransitType[transitTypeName as keyof typeof TransitType];
      enumKeyValueArray.push({ transitTypeName, transitTypeId });
    }
  }
  return enumKeyValueArray;
}
export function generateNumbers(from: number, upTo: number): string[] {
  const numbers: string[] = [];
  for (let i = from; i <= upTo; i++) {
    numbers.push(i.toString());
  }
  return numbers;
}
export function isEmptyValue(data: string) {
  if (
    data === ' ' ||
    data === '' ||
    data == '0' ||
    data === undefined ||
    data === null
  ) {
    return true;
  }
  return false;
}

export function isEmptyValueAllowZero(data: string) {
  if (data === ' ' || data === '' || data === undefined || data === null) {
    return true;
  }
  return false;
}

export function isEmptyNumber(data: number) {
  if (data === undefined || data === null || data === 0) {
    return true;
  }
  return false;
}

export const getDateFormat = (d = new Date()): string =>
  `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d
    .getDate()
    .toString()
    .padStart(2, '0')}`;

export function validateDecimal(decimalNumber: any) {
  var reg = /^[0-9]{0,6}\d*(\.\d{0,2})?$/;
  return reg.test(decimalNumber);
}

export function validatePinCode(pinCode: number): boolean {
  if (pinCode.toString().length !== 6) {
    return false; // GST number should be exactly 15 characters long
  }

  const reg = /^\d{6}$/;
  return reg.test(pinCode.toString());
}

export function validateMobileNumber(mobileNumber: number): boolean {
  if (mobileNumber.toString().length !== 10) {
    return false; // GST number should be exactly 15 characters long
  }

  const reg = /^\d{10}$/;
  return reg.test(mobileNumber.toString());
}

export function validateGST(gstNumber: string): boolean {
  const gstRegex =
    /([0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[0-9]{1}[Zz]{1}[a-zA-Z]{1}){0,15}$/;

  if (gstNumber.length !== 15) {
    return false; // GST number should be exactly 15 characters long
  }

  return gstRegex.test(gstNumber);
}

export function validatePanNumber(panNumber: string): boolean {
  const gstRegex = /^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/;

  if (panNumber.length !== 10) {
    return false; // GST number should be exactly 15 characters long
  }

  return gstRegex.test(panNumber);
}

export function validateNegativeValues(numericInputs: any): boolean {
  let hasNull = false;
  for (const input of numericInputs) {
    let numericValue = validateAndReturnNumber(input);
    if (numericValue === null) {
      hasNull = true;
      break;
    }
  }
  return hasNull;
}

export function validateAndReturnNumber(input: any): number | null {
  const trimmedInput: string = String(input).trim();
  if (
    trimmedInput === '' ||
    trimmedInput.startsWith('-') ||
    trimmedInput.startsWith('.')
  ) {
    return null;
  }
  const containsNonDigits: boolean = /[^0-9.]/.test(trimmedInput);
  if (containsNonDigits) {
    return null;
  }
  const num: number = parseFloat(trimmedInput);
  if (isNaN(num) || num < 0) {
    return null;
  }

  return num;
}

export function validateText(textInputs: any): boolean {
  let hasNull = false;
  for (const input of textInputs) {
    if (!isTextValid(input)) {
      hasNull = true;
      break;
    }
  }

  return hasNull;
}

export function isTextValid(areaName: string): boolean {
  const areaNameRegex = /^[A-Za-z\s-]+$/;
  const trimmedAreaName = areaName.trim();

  return areaNameRegex.test(trimmedAreaName);
}

export function validateMaxText(validations: PropertyValidation[]): boolean {
  let isMaxLengthExceeded = false;
  for (const validation of validations) {
    if (validation.property == null) {
      console.log('validation condition');
      return false;
    }

    const inputValue = validation.property.toString();
    if (isMaxLengthValid(inputValue, validation.maxLength)) {
      isMaxLengthExceeded = true;
      break;
    }
  }

  return isMaxLengthExceeded;
}

export function isMaxLengthValid(
  inputValue: string,
  maxLength: number
): boolean {
  return inputValue.length < maxLength;
}

export function applyMaxLengthValidation(
  form: FormGroup,
  maxLengthConfig: { property: string; maxLength: number }[]
) {
  for (const validation of maxLengthConfig) {
    const control: AbstractControl | null = form.get(validation.property);
    if (control) {
      control.valueChanges.subscribe((value: any) => {
        const maxLength = validation.maxLength;
        if (value && value.toString().length > maxLength) {
          control.setValue(value.toString().slice(0, maxLength), {
            emitEvent: false,
          });
        }
      });
    }
  }
}
export function supplierListSearchInput(): SupplierListSearch {
  return {
    searchText: '',
    type: null,
    status: null,
    pageNumber: 1,
    pageSize: 500,
  };
}

export function hasSpecialCharacters(textInputs: any): boolean {
  let hasSpecialChars = false;
  for (const input of textInputs) {
    if (validateSpecialCharacters(input)) {
      hasSpecialChars = true;
      break;
    }
  }
  return hasSpecialChars;
}

export function validateSpecialCharacters(areaName: string): boolean {
  if (typeof areaName !== 'string') {
    return false;
  }
  const areaNameRegex = /[^a-zA-Z0-9\s]/;
  const trimmedAreaName = areaName.trim();
  return areaNameRegex.test(trimmedAreaName);
}
export function validateNumbersOnly(input: string) {
  var numberRegex = /^[0-9]+$/;
  if (numberRegex.test(input)) {
    return true;
  } else {
    return false;
  }
}

export function nameValidate(textInputs: any[]): validateName {
  for (const input of textInputs) {
    if (!isTextValid(input.value)) {
      return { isValid: false, failedField: input.field };
    }
  }

  return { isValid: true };
}

export function validateMaxLength(
  validations: MultiPropertyValidation[]
): ValidationResult {
  const failedFields: { field: string; failedField: string }[] = [];

  for (const validation of validations) {
    if (validation.property == null) {
      failedFields.push({ field: '', failedField: validation.property });
    } else {
      const inputValue = validation.property.toString();
      if (isMaxLengthValid(inputValue, validation.maxLength)) {
        failedFields.push({ field: validation.field, failedField: inputValue });
      }
    }
  }

  return { isValid: failedFields.length === 0, failedFields };
}

export function validateNoNegativeValues(
  numericInputs: any[]
): ValidationResult {
  const failedFields: { field: string; failedField: string }[] = [];

  for (const input of numericInputs) {
    const numericValue = validateToAndReturnNumber(input.value);
    if (numericValue === null || numericValue < 0) {
      failedFields.push({
        field: input.field,
        failedField: String(input.value),
      }); // Fix here
    }
  }

  return { isValid: failedFields.length === 0, failedFields };
}

export function validateToAndReturnNumber(input: any): number | null {
  const trimmedInput: string = String(input).trim();
  if (
    trimmedInput === '' ||
    trimmedInput.startsWith('-') ||
    trimmedInput.startsWith('.')
  ) {
    return null;
  }
  const containsNonDigits: boolean = /[^0-9.]/.test(trimmedInput);
  if (containsNonDigits) {
    return null;
  }
  const num: number = parseFloat(trimmedInput);
  if (isNaN(num)) {
    return null;
  }

  return num;
}
export function findEnumNameByValue(
  enumType: any,
  value: number
): string | undefined {
  for (const key in enumType) {
    if (enumType.hasOwnProperty(key)) {
      const enumValue = enumType[key];
      if (typeof enumValue === 'number' && enumValue === value) {
        return key;
      }
    }
  }
  return undefined;
}

export function convertToReadableString(inputString: string) {
  var words = inputString.split('_');
  for (var i = 1; i < words.length; i++) {
    words[i] =
      words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
  }
  words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase();
  var readableString = words.join(' ');
  return readableString;
}

export function showWarningMessage(message: string) {
  Swal.fire('warning', message, 'warning');
  return new Error(message);
}
export function YYYYMMDD(d = new Date()) {
  return `${d.getFullYear().toString().padStart(4, '0')}-${(d.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`;
}

export function getDateYYYYMMDD(d: Date = new Date()): string {
  const dp = getDateParts(d);
  return `${dp.yyyy}-${dp.mm}-${dp.dd}`;
}

function getDateParts(d: Date = new Date()): any {
  const dd = `${d.getDate().toString().padStart(2, '0')}`;
  const yyyy = `${d.getFullYear().toString().padStart(4, '0')}`;
  const mm = `${(d.getMonth() + 1).toString().padStart(2, '0')}`;
  return {
    dd: dd,
    yyyy: yyyy,
    mm: mm,
  };
}
export function convertToYYYYMMDD(dateString: string): string {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export function getItemQtyInCasesPieces(upc: number, orderQty: number) {
  if (upc === 0) {
    return { pieces: orderQty, cases: 0 };
  }
  const roundedUpc = Math.round(upc);
  const pieces = orderQty % roundedUpc;
  const cases = Math.floor(orderQty / roundedUpc);
  return { pieces, cases };
}
export function getEnumNameByValue<T>(myEnum: T, value: any): keyof T | null {
  for (const key in myEnum) {
    if (myEnum[key] === value) {
      return key as keyof T;
    }
  }
  return null;
}
export function getEnumKeyValueList<T extends Record<string, any>>(
  enumObject: T
): { key: keyof T; value: T[keyof T] }[] {
  const enumKeyValueArray: { key: keyof T; value: T[keyof T] }[] = [];
  for (const key in enumObject) {
    if (!isNaN(Number(enumObject[key]))) {
      enumKeyValueArray.push({ key: key as keyof T, value: enumObject[key] });
    }
  }
  return enumKeyValueArray;
}

export function getMainFilters() {
  return [
    {
      id: 1101,
      name: 'transportExpenses',
      displayName: 'Transport Expenses',
      isSelected: false,
    },
    {
      id: 1102,
      name: 'unloadingExpenses',
      displayName: 'Unloading Expenses',
      isSelected: false,
    },
    {
      id: 1103,
      name: 'brokerageExpenses',
      displayName: 'Brokerage Expenses',
      isSelected: false,
    },
    {
      id: 1104,
      name: 'purchaseCredit',
      displayName: 'Purchase Credit',
      isSelected: false,
    },
  ];
}

export function getActionFilters() {
  return [
    {
      id: 1101,
      name: 'addToTransportSupplier',
      displayName: 'Add to Transport Supplier',
      isSelected: false,
    },
    {
      id: 1101,
      name: 'addToPurchaseSupplier',
      displayName: 'Add to Purchase Supplier',
      isSelected: false,
    },

    {
      id: 1102,
      name: 'addToTransportSupplier',
      displayName: 'Add to Transport Supplier',
      isSelected: false,
    },
    {
      id: 1102,
      name: 'addToPurchaseSupplier',
      displayName: 'Add to Purchase Supplier',
      isSelected: false,
    },
    {
      id: 1102,
      name: 'deductToTransportSupplier',
      displayName: 'Deduct to Transport Supplier',
      isSelected: false,
    },
    {
      id: 1102,
      name: 'deductToPurchaseSupplier',
      displayName: 'Deduct to Purchase Supplier',
      isSelected: false,
    },

    {
      id: 1103,
      name: 'addToBrokerageSupplier',
      displayName: 'Add to Brokerage Supplier',
      isSelected: false,
    },

    {
      id: 1104,
      name: 'addToPurchaseSupplier',
      displayName: 'Add to Purchase Supplier',
      isSelected: false,
    },
  ];
}

export function getSupplierNames() {
  return [
    {
      id: 1101,
      name: 'rajTransporter',
      displayName: 'Raj Transporter',
      isSelected: false,
    },
    {
      id: 1101,
      name: 'rajBroker',
      displayName: 'Raj Broker',
      isSelected: false,
    },
  ];
}
export function getSearchNames() {
  return [{ id: 1001, name: '' }];
}
export function toCamelCase(str: any) {
  return str.replace(/_([a-z])/g, function (match: any, letter: any) {
    return letter.toUpperCase();
  });
}

export function containsOnlyAlphanumeric(input: string): boolean {
  const regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  return regex.test(input);
}

export function containsMail(input: string): boolean {
  const regex = /^\w+([\.-]?\w+)*@(\w+([\.-]?\w+)*\.)+com$/;
  return regex.test(input);
}

export function validLatitude(latitude: string): boolean {
  if (+latitude < 0) {
    return false; // GST number should be exactly 15 characters long
  }
  const regex = /^-?([0-9]|[1-8][0-9]|90)(\.\d+)?$/;
  return regex.test(latitude);
}

export function validLongitude(longitude: string): boolean {
  if (+longitude < 0) {
    return false; // GST number should be exactly 15 characters long
  }
  const regex = /^-?((0|[1-9]|[1-9][0-9]|1[0-7][0-9]|180)(\.\d+)?)$/;
  return regex.test(longitude);
}

export function getDistinctValues(arr: any[], property: string) {
  const valuesSet = new Set();
  arr.forEach((obj) => {
    valuesSet.add(obj[property]);
  });
  return Array.from(valuesSet);
}

export function areObjectsEqual(obj1: any, obj2: any): boolean {
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return obj1 === obj2;
  }

  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) {
      return false;
    }
    for (let i = 0; i < obj1.length; i++) {
      if (!areObjectsEqual(obj1[i], obj2[i])) {
        return false;
      }
    }
    return true;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!areObjectsEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}
export function isValidMobileNumber(name: string) {
  var reg = /^\d{10}$/;
  return reg.test(name) && name.toString().length == 10;
}
export function isValidEmail(email: string) {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
export function isValidUrl(url: string): boolean {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
}

export function getDateDDMMYYY(d = new Date()) {
  return `${d.getFullYear().toString().padStart(4, '0')}-${(d.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`;
}

export function integerValidator(
  control: FormControl
): { [key: string]: any } | null {
  const value = control.value;
  if (value === null || value === undefined) {
    return null; // Allow null values if needed
  }

  const isInteger = Number.isInteger(value);
  const isNonNegative = value >= 0;

  if (!isInteger || !isNonNegative) {
    return {
      integerError: {
        message:
          'Please enter a valid integer (whole number) greater than or equal to zero.',
      },
    };
  }

  return null; // Validation successful
}

export function formatToCustomDate(isoString: string | number | Date) {
  const date = new Date(isoString);

  // Add 5 hours and 30 minutes
  const offsetMs = (5 * 60 + 30) * 60 * 1000; // 5:30 hours in milliseconds
  const adjustedDate = new Date(date.getTime() + offsetMs);

  const day = String(adjustedDate.getUTCDate()).padStart(2, '0');
  const month = String(adjustedDate.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = adjustedDate.getUTCFullYear();

  const hours = String(adjustedDate.getUTCHours()).padStart(2, '0');
  const minutes = String(adjustedDate.getUTCMinutes()).padStart(2, '0');
  const seconds = String(adjustedDate.getUTCSeconds()).padStart(2, '0');

  const formattedDate = `${day}-${month}-${year}`;
  const formattedTime = `${hours}:${minutes}:${seconds}`;

  return `${formattedDate} ${formattedTime}`;
}

export function getStatusTypes() {
  return [
    { id: 1, name: 'Active', value: 'active' },
    { id: 2, name: 'In-Active', value: 'inactive' },
  ];
}
