// multi-local-storage.service.ts
export class MultiLocalStorageService {
  private storageKeys: { [key: string]: string };

  constructor(storageKeys: { [key: string]: string }) {
    this.storageKeys = storageKeys;
  }

  setItem<T>(key: string, item: T): void {
    try {
      const storageKey = this.storageKeys[key];
      if (!storageKey) {
        throw new Error(`No storage key found for '${key}'`);
      }
      const serializedItem = JSON.stringify(item);
      localStorage.setItem(storageKey, serializedItem);
    } catch (error) {
      console.error('Error setting item to localStorage', error);
    }
  }

  getItem<T>(key: string): T | null {
    try {
      const storageKey = this.storageKeys[key];
      if (!storageKey) {
        throw new Error(`No storage key found for '${key}'`);
      }
      const serializedItem = localStorage.getItem(storageKey);
      return serializedItem ? (JSON.parse(serializedItem) as T) : null;
    } catch (error) {
      console.error('Error getting item from localStorage', error);
      return null;
    }
  }

  removeItem(key: string): void {
    try {
      const storageKey = this.storageKeys[key];
      if (!storageKey) {
        throw new Error(`No storage key found for '${key}'`);
      }
      localStorage.removeItem(storageKey);
    } catch (error) {
      console.error('Error removing item from localStorage', error);
    }
  }

  static clearAll(): void {
    try {
      localStorage.clear();
    } catch (error) {
      console.error('Error clearing localStorage', error);
    }
  }
}
