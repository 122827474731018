import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  first,
  forkJoin,
  lastValueFrom,
  map,
} from 'rxjs';
import { User } from 'firebase/auth';

import {
  Auth,
  authState,
  createUserWithEmailAndPassword,
  signOut,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  updateProfile,
  getIdTokenResult,
  getIdToken,
  signInWithCustomToken,
} from '@angular/fire/auth';
import {
  Firestore,
  collection,
  collectionData,
  doc,
  docData,
  getDoc,
  getDocs,
  query,
  setDoc,
} from '@angular/fire/firestore';
import { MerchantBusinessType, Roles } from '../../app/enums/enums';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { updateDoc } from 'firebase/firestore';
import { ConfigService } from '../pages/config/services/config.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userSubject = new BehaviorSubject<User | null>(null);
  user$: Observable<User | null> = this.userSubject.asObservable();
  userDocData$: BehaviorSubject<any> = new BehaviorSubject(null);
  pages$: BehaviorSubject<any> = new BehaviorSubject([]);
  permission$: BehaviorSubject<any> = new BehaviorSubject({});
  roles$: BehaviorSubject<any> = new BehaviorSubject([]);
  private authTokenSubject: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);
  private authTokenAPISubject: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);
  authAPIToken$ = this.authTokenAPISubject.asObservable();

  apiAuthToken: string = "";
  userData: any;
  vendorData: any;
  constructor(
    private auth: Auth,
    private firestore: Firestore,
    public http: HttpClient,
    private configService: ConfigService
  ) {
    authState(this.auth).subscribe(async (user) => {
      if (user) {
        this.userSubject.next(user);
        this.userData = await this.getUserById(this.userSubject.value?.uid);
        this.apiAuthToken = await this.getAPIToken(this.UserId);
        const vendorId = this.getVendorId();
        if (vendorId) {
          const vendorDetails = await this.configService.getVendorById(
            vendorId
          );
          this.vendorData = vendorDetails.data;
        }
        await this.subscribeUserDataAwait();
        await this.subscribePageDataAwait();
        await this.subscribeRolesDataAwait();
        if (this.isDataReady()) {
          this.setPermission();
          // if (this.authCompletedCallback) {
          //   this.authCompletedCallback('auth');
          // }
          // this.emitValue('auth completed');
        }
      } else {
        if (!window.location.href.includes('/signin')) {
          window.open(
            `${environment.ssoUrl}/login?redirectto=http://${window.location.host}/signin`,
            '_self'
          );
        }
      }
    });
  }

  getCurrentUser(): Observable<User | null> {
    return this.user$;
  }

  // Method to get the current auth token
  getToken(): Observable<string | null> {
    console.log('get token');
    return this.authTokenSubject.asObservable();
  }

  // Method to set the auth token
  setToken(token: string | null): void {
    console.log('set token');

    this.authTokenSubject.next(token);
  }

  // Method to refresh the token (you can implement your own logic)
  refreshToken(): Observable<string | null> {
    console.log('refresh token');

    // Implement your token refresh logic here
    const newToken = 'newToken'; // Dummy token, replace with your actual logic
    this.setToken(newToken);
    return this.getToken();
  }


  async getAPIToken(userId: string): Promise<any> {
    if(this.authTokenAPISubject.value){
      return this.authTokenAPISubject.value;
    }
    try {
      const authGenerateTokenApiUrl: string = `${environment.authServiceApiUrl}/api/auth/generate_token`;
      // console.log(authGenerateTokenApiUrl, userId);
      const response = await this.http.post<any>(authGenerateTokenApiUrl, { "userId": userId, "userType" : 4 }).toPromise();
      const token = response?.data?.token;
      this.apiAuthToken = token;
      // console.log('authservice get api token', token);

      this.authTokenAPISubject.next(token);

      return token;
    } catch (error) {
      this.authTokenAPISubject.next(null);
      throw error;
    }
  }

  async refreshAPIToken(token: string): Promise<any> {
    try {
      const authRefreshApiUrl: string = `${environment.authServiceApiUrl}/api/auth/refresh_token`;
      const response = await this.http.post<any>(authRefreshApiUrl, { token }).toPromise();
      const newToken = response?.data?.token;
      this.apiAuthToken = newToken;
      console.log('refresh api token', newToken);
      this.authTokenAPISubject.next(newToken);
      return newToken;
    } catch (error) {
      this.authTokenAPISubject.next(null);
      throw error;
    }
  }


  async signUp(email: string, password: string) {
    const res = await createUserWithEmailAndPassword(
      this.auth,
      email,
      password
    );
    return res;
  }

  async signOut(): Promise<any> {
    try {
      const headers = new HttpHeaders().set('No-Auth', 'true');
      let functionCall$ = this.http.post(
        `${environment.cloudFunctionsUrl}/revoke_user_token_uid`,
        { uid: this.userSubject.value?.uid },
        { headers }
      );
      await lastValueFrom(functionCall$);
      window.location.reload();
    } catch (error) { }
  }

  async updateProfile(displayName: string, photoURL: string): Promise<void> {
    const user = this.auth.currentUser;

    if (!user) {
      // Handle the case where no user is authenticated
      throw new Error('No user is authenticated.');
    }

    try {
      await updateProfile(user, {
        displayName,
        photoURL,
      });
    } catch (error) {
      // Handle errors here
      console.error('Error updating profile:', error);
      throw error; // You can rethrow the error or handle it as needed
    }
  }

  isLoggedIn(): Observable<boolean> {
    return this.user$.pipe(map((user) => !!user));
  }

  private subscribeUserData() {
    const docRef = doc(this.firestore, `Users/${this.userSubject.value?.uid}`);
    docData(docRef, { idField: 'id' }).subscribe((res: any) => {
      this.userDocData$.next(res);
      this.setPermission();
    });
  }

  private subscribeUserDataFork(): Observable<any> {
    const docRef = doc(this.firestore, `Users/${this.userSubject.value?.uid}`);
    return docData(docRef, { idField: 'id' });
  }
  async subscribeUserDataAwait() {
    try {
      const docRef = doc(
        this.firestore,
        `Users/${this.userSubject.value?.uid}`
      );
      const docSnapshot = await getDoc(docRef);
      const userData = { ...docSnapshot.data(), id: docSnapshot.id };
      this.userDocData$.next(userData);
    } catch (error) {
      console.error('Error fetching user data:', error);
      // Handle error as per your requirement
    }
  }

  private subscribePageData() {
    const aCollection = collection(this.firestore, 'pages');
    let q = query(aCollection);
    collectionData(q, { idField: 'id' }).subscribe((res: any) => {
      this.pages$.next(res);
      this.setPermission();
    });
  }

  // private subscribePageDataFork() : Observable<any>  {
  //   const aCollection = collection(this.firestore, 'pages');
  //   let q = query(aCollection);
  //   return collectionData(q, { idField: 'id' });
  // }

  async subscribePageDataAwait() {
    try {
      const aCollection = collection(this.firestore, 'pages');
      const querySnapshot = await getDocs(aCollection);
      const pagesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      this.pages$.next(pagesData);
    } catch (error) {
      console.error('Error fetching page data:', error);
      // Handle error as per your requirement
    }
  }

  async getUserById(id: any) {
    const aDoccument = doc(this.firestore, `Users/${id}`);
    let item = (await getDoc(aDoccument)).data();
    return item;
  }

  private subscribeRolesData() {
    const aCollection = collection(this.firestore, 'Roles');
    let q = query(aCollection);
    collectionData(q, { idField: 'id' }).subscribe((roles: any) => {
      console.log('subscribe master', roles);
      this.roles$.next(roles);
      this.setPermission();
    });
  }

  // private subscribeRolesDataFork() :Observable<any>  {
  //   const aCollection = collection(this.firestore, 'Roles');
  //   let q = query(aCollection);
  //   return collectionData(q, { idField: 'id' });
  // }

  async subscribeRolesDataAwait() {
    const aCollection = collection(this.firestore, 'Roles');
    const q = query(aCollection);
    try {
      const querySnapshot = await getDocs(q);
      const roles = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      // console.log('Roles data:', roles);
      this.roles$.next(roles);
    } catch (error) {
      console.error('Error reading roles data:', error);
      // Handle error as per your requirement
    }
  }

  setPermission() {
    if (this.isDataReady()) {
      const new_permissions: any = {};
      const assignedRoles = this.userDocData$.value.roles || [];
      // console.log(assignedRoles)
      const roleList = this.roles$.value.filter((ele: any) =>
        assignedRoles.includes(ele.id)
      );
      for (const page of this.pages$.value) {
        new_permissions[page.page_id] = {
          view: false,
          create: false,
          delete: false,
          edit: false,
          import: false,
          export: false,
        };
      }
      for (const role of roleList) {
        for (const page of this.pages$.value) {
          new_permissions[page.page_id] = {
            view: this.updatePermission(
              new_permissions[page.page_id]['view'],
              role.permissions[page.page_id]?.view
            ),
            create: this.updatePermission(
              new_permissions[page.page_id]['create'],
              role.permissions[page.page_id]?.create
            ),
            delete: this.updatePermission(
              new_permissions[page.page_id]['delete'],
              role.permissions[page.page_id]?.delete
            ),
            edit: this.updatePermission(
              new_permissions[page.page_id]['edit'],
              role.permissions[page.page_id]?.edit
            ),
            import: this.updatePermission(
              new_permissions[page.page_id]['import'],
              role.permissions[page.page_id]?.import
            ),
            export: this.updatePermission(
              new_permissions[page.page_id]['export'],
              role.permissions[page.page_id]?.export
            ),
          };
        }
      }
      if (roleList && roleList.length > 0) {
        for (const page of this.pages$.value) {
          new_permissions[page.page_id] = {
            view: this.updatePermission(
              new_permissions[page.page_id]['view'],
              this.userDocData$.value.extended_permissions[page.page_id]?.view
            ),
            create: this.updatePermission(
              new_permissions[page.page_id]['create'],
              this.userDocData$.value.extended_permissions[page.page_id]?.create
            ),
            delete: this.updatePermission(
              new_permissions[page.page_id]['delete'],
              this.userDocData$.value.extended_permissions[page.page_id]?.delete
            ),
            edit: this.updatePermission(
              new_permissions[page.page_id]['edit'],
              this.userDocData$.value.extended_permissions[page.page_id]?.edit
            ),
            import: this.updatePermission(
              new_permissions[page.page_id]['import'],
              this.userDocData$.value.extended_permissions[page.page_id]?.import
            ),
            export: this.updatePermission(
              new_permissions[page.page_id]['export'],
              this.userDocData$.value.extended_permissions[page.page_id]?.export
            ),
          };
        }
      }
      this.permission$.next(new_permissions);
      // console.log(this.permission$.value)
    }
  }

  isDataReady(): boolean {
    return (
      this.userDocData$.value &&
      this.pages$.value.length > 0 &&
      this.roles$.value.length > 0 &&
      this.authTokenAPISubject.value
    );
  }

  private updatePermission(
    existingValue: boolean,
    newValue: boolean | undefined
  ): boolean {
    return existingValue || (newValue !== undefined && newValue);
  }

  signinWithToken(token: any) {
    return signInWithCustomToken(this.auth, token).then((res) => {
      return res;
    });
  }

  isVendor() {
    return this.hasRole('Seller Admin');
    // return this.userDocData$?.value?.user_type == 4
  }

  isAdmin() {
    // return this.userDocData$?.value?.user_type == 5
    return this.hasRole('BMS Admin');
  }

  hasRole(roleName: any) {
    const userRoles = this.getUserRoleNames();
    return userRoles.includes(roleName);
  }

  isLogisticsAdmin() {
    return this.hasRole('Logistics Admin');
    // return this.userDocData$?.value?.user_type == 6
  }

  // isLogisticsUser() {
  //   return this.userDocData$?.value?.user_type == 7
  // }

  getUserId() {
    return this.userSubject.value?.uid;
  }

  getVendorId() {
    if (this.userData && this.userData.vendor_id) {
      return this.userData.vendor_id || '';
    } else if (this.userDocData$?.value?.user_type == 4) {
      return this.userSubject.value?.uid;
    } else {
      return '';
    }
  }
  getVendor() {
    return this.vendorData;
  }
  get UserId(): string {
    return this.userSubject.value?.uid || '';
  }
  get VendorId(): string {
    return this.userData.vendor_id || '';
  }

  get BusinessType(): MerchantBusinessType {
    const businessType: number =
      this.vendorData.business_type || MerchantBusinessType.None;
    return businessType as MerchantBusinessType;
  }
  get UserType(): string {
    return this.userData.user_type || '';
  }
  get Fulfillment(): string {
    return this.vendorData?.admin_config
      ? this.vendorData.admin_config.fulfillment?.fulfillmentBy
      : 0;
  }
  hasPermission(permission: string, pageId: string): boolean {
    // return true;
    return this.permission$.value?.[pageId]?.[permission] ?? false;
  }

  getUserRoleNames() {
    const assignedRoles: string[] = this.userDocData$.value.roles || [];

    // Create a map of roles for faster lookup
    const rolesMap = new Map<string, any>(
      this.roles$.value.map((role: any) => [role.id, role])
    );
    // Filter roles based on assigned role IDs
    const roleList = assignedRoles
      .map((roleId: string) => rolesMap.get(roleId))
      .filter(Boolean);
    // Extract role names
    return roleList.map((role: any) => role.role_name);
  }

  updateNotificationsLastRead() {
    const aDoccument = doc(
      this.firestore,
      `notifications_meta_data/${this.userSubject.value?.uid}`
    );
    setDoc(aDoccument, { last_seen: new Date() });
  }

  updateAdminNotificationsLastRead() {
    const aDoccument = doc(this.firestore, `notifications_meta_data/admin`);
    setDoc(aDoccument, { last_seen: new Date() });
  }

  getAdminLastRead() {
    const aDoccument = doc(this.firestore, `notifications_meta_data/admin`);
    return docData(aDoccument, { idField: 'id' });
  }

  getVendorLastRead() {
    const aDoccument = doc(
      this.firestore,
      `notifications_meta_data/${this.userSubject.value?.uid}`
    );
    return docData(aDoccument, { idField: 'id' });
  }





}

// emitValue(value: string) {
//   console.log('suspecting emiting');
//   this.dataSubject.next(value);
// }

// getDataObservable() {
//   return this.dataSubject.asObservable();
// }

// forkJoin({
//   userData: this.subscribeUserDataFork(),
//   pageData: this.subscribePageDataFork(),
//   rolesData: this.subscribeRolesDataFork()
// }).subscribe({
//   next: ({ userData, pageData, rolesData }) => {
//     // Do something with the results if needed
//     console.log('User Data:', userData);
//     console.log('Page Data:', pageData);
//     console.log('Roles Data:', rolesData);
//     this.userDocData$.next(userData);
//     this.pages$.next(pageData);
//     this.roles$.next(rolesData);
//     // Call setPermission after all observables have completed
//     //this.setPermission();
//   },
//   error: error => {
//     // Handle error if any of the observables emits an error
//     console.error('Error:', error);
//   },
//   complete: () => {
//     // Logic to execute after all observables have completed
//     console.log('All observables completed');
//     this.setPermission();

//   }
// });
// console.log('user data, pagedata, roles data is completed ');

// this.permission$.subscribe(res => {
//   this.permissionResult = res
// });
