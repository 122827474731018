import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ProductService } from '../services/product.service';
import { AuthService } from '../../../services/auth.service';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProductFilterDialogComponent } from '../product-filter-dialog/product-filter-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmDialogComponent } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.component';
import { CommonService } from '../../../services/common.service';
import { CommonModule } from '@angular/common';
import { MatSortHeader, MatSortModule } from '@angular/material/sort';
import { DraftFilterComponent } from './draft-filter/draft-filter.component';
import Swal from 'sweetalert2';
import { SwalMessageTypes } from '../../../enums/enums';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { PageId } from '../../../constants/enums';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-draft-sku-list',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatIconModule, MatSortModule, MatCardModule, MatButtonModule, MatTableModule, RouterLink, MatPaginatorModule, MatProgressSpinnerModule],
  templateUrl: './draft-sku-list.component.html',
  styleUrl: './draft-sku-list.component.scss',
})
export class DraftSkuListComponent extends BaseListComponent implements OnInit {
  page_id = "bms_draft_products"
  dataSource: any = [];
  filterList: any = [];
  displayedColumns: string[] = ['name', 'updated', 'status', 'action'];
  permission: any = false
  loading = false
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  queryForm: any;
  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public commonService: CommonService,
    public productService: ProductService,) {
    super(auth, router, route, PageId.bms_manage_sku);
    this.queryForm = this.fb.group({
      name: [null]
    });

  }

  override async ngOnInit() {
    await super.ngOnInit();

    this.getMyDraft();

    this.queryForm.valueChanges.pipe(
      takeUntil(this.unsubscribe$) // Unsubscribe when the component is destroyed
    ).subscribe((value: any) => {
      this.filter(value);
    })
  }

  getMyDraft() {
    this.productService.getMyDraftSkus().pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      this.filterList = res;
      this.dataSource = new MatTableDataSource(res);
    })
  }
  async discardPendingDoc(id: any) {
    /*  const dialogRef = this.dialog.open(ConfirmDialogComponent, {
       data: { title: 'Delete Confirmation', message: "Are you sure you want to delete?" },
       panelClass: 'custom-dialog-class'
     });
     dialogRef.afterClosed().subscribe(async result => {
       if (result == 'yes') {
         try {
           await this.productService.discardPendingDoc(id)
           this.commonService.showToaster("success", 'Deleted Successfully')
         }
         catch (err: any) {
           this.commonService.showToaster("error", err.error.message)
         }
       }
     }); */
    try {
      const result = await Swal.fire({
        title: 'Do you want to Delete?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      });

      if (!result.isConfirmed) {
        return;
      }
      await this.productService.discardPendingDoc(id)
      this.showMessage(
        'Deleted Successfully',
        SwalMessageTypes.Success
      );
    } catch (error: any) {
      this.showMessage(
        error.error.message,
        SwalMessageTypes.Error
      );
    }
  }
  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }
  filter(value: any) {
    if (value?.name != '') {
      let result = this.filterList.filter((ele: any) => ele.payload.sku.name.toLowerCase().includes(value.name.toLowerCase()));
      this.dataSource = new MatTableDataSource(result);
    }
    else {
      this.dataSource = new MatTableDataSource(this.filterList);
    }
  }
}
