@if (authService.userDocData$.value && authService.isDataReady()) {

@if(isActiveUser){
<mat-drawer-container class="example-container" autosize>
  <mat-drawer #drawer class="example-sidenav" mode="side" opened="true">
    <app-sidebar [sidenav]="drawer" [menuItems]="items"></app-sidebar>


  </mat-drawer>
  <div class="example-sidenav-content">
    <app-header [sidenav]="drawer"></app-header>

    <router-outlet></router-outlet>
  </div>
</mat-drawer-container>
}@else {
<div class="loader-container">
  <div class="inactive-user-container">
    <p>Admin setup is not completed. Please contact the admin team.</p>
  </div>
</div>

<div class="loader-container" style="margin-top: 60px;">
  <div class="inactive-user-container">
    <button mat-raised-button (click)="logout()" color="primary">
      Log Out
    </button>
  </div>
</div>

}

}
@else {
<div class="loader-container">
  <img src="assets/images/loader.gif" alt="Loading..." class="loader">
</div>
}
