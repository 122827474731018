<div class="container-fluid p-2">
  <mat-card class="p-3">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 d-flex mb-3">
          <div class="me-3">
            <mat-icon role="button"
              (click)="goBack()">keyboard_backspace</mat-icon>
          </div>
          <h3 class="title">Edit Sku</h3>
        </div>
      </div>
      <form [formGroup]="form">
        <div class="row">
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <mat-label>SKU Name</mat-label>
              <input matInput formControlName="skuName"  />
            </mat-form-field>

          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width w-100">
              <mat-label>MRP</mat-label>
              <input matInput formControlName="mrp" type="number"/>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width w-100">
              <mat-label>HSN</mat-label>
              <input matInput formControlName="hsn" readonly="true"/>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width w-100">
              <mat-label>UPC</mat-label>
              <input matInput formControlName="upc" (input)="calculatePcs($event,'upc')" type="number" readonly="true"/>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width w-100">
              <mat-label>Item Tax Type</mat-label>
              <input matInput formControlName="itemTaxType" readonly="true"/>
            </mat-form-field>
          </div>
         
          <div class="col-md-6">
            <mat-form-field class="example-full-width w-100">
              <mat-label>Tax Rate</mat-label>
              <input matInput formControlName="taxRate"  readonly="true"/>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <mat-label>Buying UOM</mat-label>
              <mat-select formControlName="buyinguom" (selectionChange)="resetUomQty()">
                <mat-option *ngFor="let option of Uom" [value]="option.value">
                  {{ option.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width w-100">
              <mat-label> Requested UOM Qty</mat-label>
              <input matInput formControlName="uomQty" (input)="calculatePcs($event,'uomQty')" type="number" readonly={{isReadOnly}}/>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width w-100">
              <mat-label>Requested Qty In Pc's</mat-label>
              <input matInput formControlName="pcs" readonly={{isReadOnly}}/>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width w-100">
              <mat-label>Cess Rate</mat-label>
              <input matInput formControlName="cessRate" readonly="true"/>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width w-100">
              <mat-label>Actual UOM Qty</mat-label>
              <input matInput formControlName="actualUomQty" readonly="true"/>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width w-100">
              <mat-label>Actual Qty In Pc's</mat-label>
              <input matInput formControlName="actualPcs" readonly="true"/>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width w-100">
              <mat-label>Requested Invoice Amount</mat-label>
              <input matInput formControlName="requestedInvoiceAmount" name="actualInvoiceAmount"  />
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width w-100">
              <mat-label>Actual Invoice Amount</mat-label>
              <input matInput formControlName="invoiceAmount" type="number" readonly="true"/>
            </mat-form-field>
          </div>
        
          <div class="col-md-6">
            <mat-form-field class="example-full-width w-100">
              <mat-label>Weight</mat-label>
              <input matInput formControlName="weight"  type="number" readonly="true"/>
            </mat-form-field>
          </div>

          <div class="row p-3">
            <div class="col-md-6">
              <h3 class="title">Tax Details Section</h3>
            </div>
          </div>
          <div class="mt-10">
            <div class="row">
              <div class="col-md-6">
                <mat-form-field class="example-full-width w-100">
                  <mat-label>Taxable Amount</mat-label>
                  <input matInput formControlName="taxableAmount" readonly="true"/>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="example-full-width w-100">
                  <mat-label>SGST</mat-label>
                  <input matInput formControlName="sgst" readonly="true"/>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <mat-form-field class="example-full-width w-100">
                  <mat-label>CGST</mat-label>
                  <input matInput formControlName="cgst" readonly="true"/>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="example-full-width w-100">
                  <mat-label>IGST</mat-label>
                  <input matInput formControlName="igst" readonly="true"/>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <mat-form-field class="example-full-width w-100">
                  <mat-label>Cess</mat-label>
                  <input matInput formControlName="cess" readonly="true"/>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="example-full-width w-100">
                  <mat-label>TCS/TDS</mat-label>
                  <input matInput formControlName="tcs_tds" readonly="true"/>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <mat-form-field class="example-full-width w-100">
                  <mat-label>Total Bill Value</mat-label>
                  <input matInput formControlName="totalBillValue" readonly="true"/>
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-12 custom-flex">
              <!-- <div class="text-center mt-2 flex-item">
              <button mat-raised-button color="primary" class="text-center ml">
                Calculate
              </button>
            </div> -->
              <div class="text-center mt-2 flex-item">
                <button mat-raised-button color="primary" class="text-center ml-w" (click)="saveSku()">
                  Save 
                </button>
              </div>
            </div>
          </div>
        </div>

      </form>
    </div>
  </mat-card>
</div>