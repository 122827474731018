import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Auth } from '@angular/fire/auth';
import { AuthService } from '../../../services/auth.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BrandService {
  auth: Auth = inject(Auth);
  constructor(public http: HttpClient,public authService:AuthService) {}

  upload(body: any) {
    return this.http.put(`${environment.fileUploadBaseUrl}/images/brand`, body);
  }

  async add(data:any){
    return lastValueFrom(this.http.post(`${environment.backendUrl}/brands`,data))
  }

  delete(id:string){
    return lastValueFrom(this.http.delete(`${environment.backendUrl}/brands/${id}`))
  }
  getSingle(id:string){
    return lastValueFrom(this.http.get(`${environment.backendUrl}/brands/${id}`))
  }
  async update(id:string,data:any){
    return lastValueFrom(this.http.patch(`${environment.backendUrl}/brands/${id}`,data))
  }

  getAll(queryObj?:any){
    if(!queryObj){
      queryObj={limit:10000}
    }
    return this.http.get(`${environment.backendUrl}/brands?${this.objectToQueryString(queryObj)}`)
  }

  getAllCompanies(){
    return this.http.get(`${environment.backendUrl}/companies`)
  }
  objectToQueryString(obj: any): string {
    let params = new HttpParams();

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if(obj[key]!=null){
          params = params.append(key, obj[key]);
        }
      }
    }

    return params.toString();
  }
}
