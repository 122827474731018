import { Component } from '@angular/core';
import { FormBuilder, Validators, ReactiveFormsModule, FormsModule, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { VehiclesService } from '../../services/vehicles.service';
import { VehicleEntity, VehicleListSearch, VehicleForm, PropertyValidation } from '../../models/vehicles.model';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AppLoaderService } from '../../../../../shared/app-loader/app-loader.service';
import { AuthService } from '../../../../../services/auth.service';
import { SwalMessageTypes, SupplierStatus, SupplierType, ContractMode, LookUpType } from '../../../../../enums/enums';
import Swal from 'sweetalert2';
import { applyMaxLengthValidation, hasSpecialCharacters, supplierListSearchInput, uuidv4, validateGST, validateMaxText, validateNegativeValues, validateSpecialCharacters, validateText } from '../../../../../utlity/utility';
import { LookUpTypeService } from '../../../../../services/lookup-type.service';
import { BaseListComponent } from '../../../../../shared/core/base.list.component';
import { PageId } from '../../../../../constants/enums';

@Component({
  selector: 'app-create-vehicle',
  standalone: true,
  imports: [MatCardModule, MatButtonModule, MatTableModule, RouterLink, MatInputModule,
    MatFormFieldModule, ReactiveFormsModule, MatSelectModule, MatIconModule,
    CommonModule, FormsModule, MatDatepickerModule, MatRadioModule, MatNativeDateModule,
    MatPaginatorModule, MatSidenavModule, MatDatepickerModule, MatCheckboxModule],
  templateUrl: './create-vehicle.component.html',
  styleUrls: ['./create-vehicle.component.scss']
})
export class CreateVehicleComponent extends BaseListComponent {
  vehicleStatus = Object.values(SupplierStatus).filter(value => typeof value === 'number');
  vehicleTypes = Object.values(SupplierType).filter(value => typeof value === 'number');
  vehicleContractMode = Object.values(ContractMode).filter(value => typeof value === 'number');
  supplierNames: LookUpType = this.initializeSuppliers();
  public supplierName: any[] = [];
  locationNames: LookUpType = this.initializeLocation();
  public location: any[] = [];
  driverIds: LookUpType = this.initializeDriverIds();
  public driverId: any[] = [];
  driverNames: LookUpType = this.initializeDriverNames();
  public driverName: any[] = [];
  driverIdToNameMap: { [id: string]: string } = {};
  vehicleListSearchInput: VehicleListSearch = this.initializeSearchInput();

  dataSource: any;
  vehicleEntity!: VehicleEntity;
  uploadedFiles: any = {};
  register: string = "";
  registrationDocument: string = "";
  vehicleTonnageOptions: { tonnage: string, value: number }[] = [
    { tonnage: '2 kg', value: 1 },
    { tonnage: '5 kg', value: 2 },
    { tonnage: '10 kg', value: 3 },
    { tonnage: '2 Ton', value: 4 },
    { tonnage: '4 Ton', value: 5 },
    { tonnage: '6 Ton', value: 6 },
    { tonnage: '6 Ton And Above', value: 7 }
  ];
  vehicleModes: { type: string, value: number }[] = [
    { type: "2 wheeler", value: 2 },
    { type: "3 wheeler", value: 3 },
    { type: "4 wheeler", value: 4 },
  ];

  constructor(
    auth: AuthService,
    router: Router,
    private fb: FormBuilder,
    route: ActivatedRoute,
    private vehiclesService: VehiclesService,
    private loader: AppLoaderService,
    private lookupService: LookUpTypeService,
    private vehicleService: VehiclesService
  ) {
    super(auth, router, route, PageId.logistics_vehicles); // Call the constructor of BaseListComponent

    this.dataSource = this.fb.group({
      vehicleModel: [null, [Validators.required]],
      supplierName: [null, [Validators.required]],
      vehicleTonnage: [null, [Validators.required]],
      vehicleNumber: [null, [Validators.required]],
      registrationNumber: [null, [Validators.required]],
      registrationDocument: [null, [Validators.required]],
      noOfDays: [null, [Validators.required, this.noDecimalValidator()]],
      cost: [null, [Validators.required, this.noDecimalValidator()]],
      contractMode: [null, [Validators.required]],
      defaultDriver: [null, [Validators.required]],
      location: [null, [Validators.required]],
      status: [null, [Validators.required]]
    });
  }

  override async ngOnInit() {
    await super.ngOnInit();
    await this.getDrivers();
    await this.getSuppliers();
    await this.getLocation();
  }

  noDecimalValidator() {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value === null || value === undefined || value === '') {
        return null;
      }
      if (value.toString().includes('.')) {
        return { 'decimalNotAllowed': true };
      }
      return null;
    };
  }
  async getLocation() {
    const response = await this.lookupService.getLookUpTypes(this.locationNames);
    this.location = response.data[0].data;
  }
  private initializeSuppliers(): LookUpType {
    return {
      tableName: "logistic_suppliers",
      lookupType: "LogisticSuppliers",
      idColumnName: "Id",
      valueColumnName: "Name"
    };
  }

  private initializeLocation(): LookUpType {
    return {
      tableName: "locations",
      lookupType: "locations",
      idColumnName: "id",
      valueColumnName: "name"
    };
  }

  private initializeDriverIds(): LookUpType {
    return {
      tableName: "resource",
      lookupType: "resource",
      idColumnName: "id",
      valueColumnName: "designation"
    };
  }

  private initializeDriverNames(): LookUpType {
    return {
      tableName: "resource",
      lookupType: "resource",
      idColumnName: "id",
      valueColumnName: "name"
    };
  }


  async getSuppliers() {
    const response = await this.lookupService.getLookUpTypes(this.supplierNames);
    this.supplierName = response.data[0].data;
  }

  async getDrivers() {
    const response = await this.lookupService.getLookUpTypes(this.driverIds);
    this.driverId = response.data[0].data;
    this.driverId = this.driverId.filter(item => item.value === 4);
    console.log("getDriversgetDrivers::", this.driverId);

    const responseNames = await this.lookupService.getLookUpTypes(this.driverNames);
    this.driverName = responseNames.data[0].data;
    // Map through each driverId and find matching driverName, then extract the value
    this.driverName = this.driverId.map(driverId => {
      const matchedDriver = this.driverName.find(driver => driver.id === driverId.id);
      if (matchedDriver) {
        this.driverIdToNameMap[driverId.id] = matchedDriver.value; // Create ID to name mapping
        return matchedDriver.value;
      }
      return null;
    });
    console.log("Driver Values: ", this.driverName);
    console.log("ID to Name Map: ", this.driverIdToNameMap);
  }

  async validateInput() {
    const vehicleNumber = this.dataSource.get('vehicleNumber').value;
    const registrationNumber = this.dataSource.get('registrationNumber').value;

    try {
      const response = await this.vehicleService.getVehicles(this.vehicleListSearchInput);
      const vehicles = response.vehicleList;

      const { vehicleNumberExists, registrationNumberExists } = this.validateVehicleUniqueness(vehicleNumber, registrationNumber, vehicles);

      if (vehicleNumberExists) {
        this.showMessage('Vehicle number already exists.', SwalMessageTypes.Warning);
        return false;
      }

      if (registrationNumberExists) {
        this.showMessage('Registration number already exists.', SwalMessageTypes.Warning);
        return false;
      }

      return true;
    } catch (error) {
      console.error('Validation error:', error);
      throw error;
    }
  }

  async saveVehicle() {
    this.loader.open();

    try {
      if (this.dataSource.invalid) {
        this.handleValidationErrors();
        return;
      }

      const isInputValid = await this.validateInput();
      if (isInputValid) {
        await this.uploadFiles();
        await this.createVehicle();
      }
    } catch (error) {
      console.error('Error occurred during vehicle creation:', error);
      this.showMessage('Failed to create vehicle. Please try again later.', SwalMessageTypes.Error);
    } finally {
      this.loader.close();
    }
  }

  handleValidationErrors() {
    if (this.dataSource.get('noOfDays').hasError('decimalNotAllowed') || this.dataSource.get('cost').hasError('decimalNotAllowed')) {
      this.showMessage('Decimal values are not allowed', SwalMessageTypes.Warning);
    } else {
      this.showMessage('Please fill all required fields.', SwalMessageTypes.Warning);
    }
  }


  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }
  async uploadFiles() {
    // vehicle_registrationDocument
    const documentType = 'register';
    const urlEndpoint = 'vehicle_registrationDocument';

    if (this.uploadedFiles.hasOwnProperty(documentType)) {
      console.log('has Ownproperty');

      const requestImageBody = this.uploadedFiles[documentType];
      await this.uploadAndSetUrl(requestImageBody, documentType, urlEndpoint);
    }
  }



  private async uploadAndSetUrl(requestImageBody: any, documentType: string, urlEndpoint: string) {
    console.log("upload and seturl");

    try {
      const res: any = await this.vehiclesService.imageUpload(requestImageBody, urlEndpoint);
      switch (documentType) {
        case 'register':
          this.registrationDocument = res.data.url;
          console.log("registration", this.registrationDocument);

          break;
        default:
          console.error('Invalid document type');
          break;
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  }



  private mapVehicle(vehicle: VehicleForm): VehicleEntity {
    const mapForm = vehicle
    return {
      id: uuidv4(),
      vehicleModel: mapForm?.vehicleModel,
      supplierId: mapForm?.supplierName,
      vehicleTonnage: mapForm?.vehicleTonnage,
      vehicleNumber: mapForm?.vehicleNumber,
      registrationNumber: mapForm?.registrationNumber,
      registrationDocument: this.registrationDocument || "",
      noOfDays: mapForm?.noOfDays,
      cost: mapForm?.cost,
      contractMode: mapForm?.contractMode,
      defaultDriver: mapForm?.defaultDriver,
      status: mapForm?.status,
      location: mapForm?.location
    }
  }

  async createVehicle() {
    const vehicleEntity = this.mapVehicle(this.dataSource.value);
    if (+vehicleEntity.noOfDays < 0) {
      this.showMessage(' Please enter valid number of days', SwalMessageTypes.Warning);
      return
    }
    try {
      const response = await this.vehiclesService.createVehicle(vehicleEntity);
      this.showMessage('Vehicle created successfully', SwalMessageTypes.Success);
      this.router.navigateByUrl('/dashboard/logistics/vehicle-list');
    } catch (error) {
      console.error('Error occurred while creating vehicle:', error);
      this.showMessage('Failed to create vehicle. Please try again later.', SwalMessageTypes.Error);
    }
  }

  validateVehicleUniqueness(vehicleNumber: any, registrationNumber: any, vehicles: any) {
    const vehicleNumberExists = vehicles.some((vehicle: { vehicleNumber: any; }) => vehicle.vehicleNumber === vehicleNumber);
    const registrationNumberExists = vehicles.some((vehicle: { registrationNumber: any; }) => vehicle.registrationNumber === registrationNumber);

    return { vehicleNumberExists, registrationNumberExists };
  }

  // private Functions

  private validateNegativeValues(data: VehicleForm): boolean {
    const numericInputs = [
      data.noOfDays,
    ];

    return validateNegativeValues(numericInputs);
  }

  private checkSpecialCharacters(data: any): boolean {
    const inputValues = [];
    inputValues.push(data.vehicleModel);
    inputValues.push(data.registrationDocument);


    return inputValues.length > 0 ? hasSpecialCharacters(inputValues) : false;
  }

  private getFileExtension(filename: any) {
    return filename.split('.').pop();
  }

  async onFileChange(event: any, documentType: string) {
    if (event.target.files) {
      const file = event.target.files[0];
      const filename = file.name;
      const reader = new FileReader();

      reader.onload = (event) => {
        const result = event?.target?.result;

        if (typeof result === 'string') {
          const parts = result.split(',');
          const base64String = parts[1];
          this.uploadedFiles[documentType] = {
            fileName: filename,
            fileExtension: this.getFileExtension(filename),
            fileData: base64String,
          };
        } else if (result instanceof ArrayBuffer) {
          console.error('Unsupported file type');
          // Handle this case if needed
        }
      };

      reader.readAsDataURL(file);
    }
  }

  private initializeSearchInput(): VehicleListSearch {
    return {
      vehicleType: null,
      tonnage: null,
      contractMode: null,
      status: null,
      pageNumber: 1,
      pageSize: 10
    };
  }
}
