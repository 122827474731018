import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSidenavModule } from '@angular/material/sidenav';
import {
  ContractMode,
  SupplierStatus,
  SupplierType,
  SwalMessageTypes,
} from '../../../../enums/enums';
import Swal from 'sweetalert2';
import { AppLoaderService } from '../../../../shared/app-loader/app-loader.service';
import { AuthService } from '../../../../services/auth.service';
import { VendorGeoMappingService } from '../../services/vendor-geo-mapping.service';
import { MerchantEntity } from '../../models/vendor-geo-mapping-model';

@Component({
  selector: 'app-vendor-geo-mapping-search',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    RouterLink,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSidenavModule,
  ],
  templateUrl: './vendor-geo-mapping-search.component.html',
  styleUrl: './vendor-geo-mapping-search.component.scss',
})
export class VendorGeoMappingSearchComponent implements OnInit {
  page_id = 'bms_suppliers_list';
  permission: any = false;
  readonly DEFAULT_PAGE_SIZE = 25;
  pageNumber: number = 1;
  pageSize = this.DEFAULT_PAGE_SIZE;
  form: FormGroup;

  dataSource!: MatTableDataSource<MerchantEntity>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  businessTypes: { type: string; value: number }[] = [
    { type: 'Distributor', value: 1 },
    { type: 'Manufacturer', value: 2 },
    { type: 'Wholesaler', value: 3 },
    { type: 'JitVendor', value: 4 },
    { type: 'Inventory', value: 5 },
  ];
  displayedColumns = [
    'businessName',
    'mobileNumber',
    'businessType',
    'address',
    'state',
    'city',
    'action',
  ];

  constructor(
    private fb: FormBuilder,
    public route: ActivatedRoute,
    public router: Router,
    public vendorGeoMappingService: VendorGeoMappingService,
    private loader: AppLoaderService,
    public auth: AuthService
  ) {
    this.auth.permission$.subscribe((res) => {
      this.permission = res[this.page_id];
    });

    this.form = this.fb.group({
      mobileNumber: [null, [Validators.pattern(/^[6-9]\d{9}$/)]],
    });
  }

  async ngOnInit() { }
  async searchMerchant() {
    const { mobileNumber } = this.form.value;

    if (mobileNumber == null) {
      Swal.fire('', 'Please enter the mobiel number', 'warning');
      return;
    }
    if (mobileNumber) {
      const mobileNumberString = mobileNumber.toString();
      if (mobileNumberString.length > 10 || mobileNumberString.length <= 9) {
        Swal.fire('', 'Please enter valid mobileNumber', 'warning');
        return;
      }
    }
    if (this.form.valid) {
      const response = await this.vendorGeoMappingService.getVendors(
        mobileNumber
      );
      console.log('response', response);
      this.dataSource = response;
      console.log('datasource', this.dataSource);
    }
  }
  resetForm() { }
  rowClick(vendor: string) { }

  getBusinessTypeName(value: number): string {
    const businessType = this.businessTypes.find(
      (type) => type.value === value
    );
    return businessType ? businessType.type : 'Unknown';
  }
}
