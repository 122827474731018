import { FulfillmentType } from './../../../enums/enums';
import { map, takeUntil } from 'rxjs';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModifyOrderByHop, OrderFilters, OrderItemResponse, OrderReturnApiInput, OrderToModify, ProductViewProductDetails, productViewResponse, skuProductData } from '../../../models/order';
import { getDateFormat, getEnumNameByValue, getItemQtyInCasesPieces } from '../../../utlity/utility';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { CommonModule } from '@angular/common';
import { MatSort } from '@angular/material/sort';
import { OrderService } from '../../../services/orderService.service';
import { AuthService } from '../../../services/auth.service';
import { ConfigService } from '../../config/services/config.service';
import { OrderStatus } from '../../../enums/enums';
import { NaukaOrdersPopupComponent } from '../nauka-orders-popup/nauka-orders-popup.component';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { PageId } from '../../../constants/enums';
import { ReplaceCommasPipe } from "../../../shared/pipes/currency-pipe";
import { AppStateService } from '../../../services/appstate.service';

@Component({
  selector: 'app-order-product-view',
  standalone: true,
  templateUrl: './order-product-view.component.html',
  styleUrl: './order-product-view.component.scss',
  imports: [
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    RouterLink,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    MatTableModule,
    MatSidenavModule,
    MatPaginatorModule,
    CommonModule,
    ReplaceCommasPipe
  ]
})
export class OrderProductViewComponent extends BaseListComponent {
  page_id = "bms_order_list";
  displayedColumns: string[] = ['skuName', 'totalQuantity', 'invoiceQty', 'availableQty', 'cases', 'pieces', 'mrp', 'sellingPrice', 'action'];
  totalPages: number = 1;
  searchOrderInput: OrderFilters = this.initializeSearchInput();
  totalOrderWeight: number = 0;
  totalOrderAmount: number = 0;
  totalProductCount: number = 0;
  dataSource: any;
  isEdit: boolean = false;
  rawOrderResponse: any;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @Output() summaryEvent = new EventEmitter<any>();
  constructor(
    private orderService: OrderService,
    router: Router,
    auth: AuthService,
    route: ActivatedRoute,
    private appStateService: AppStateService,
    private dialog: MatDialog
  ) {
    super(auth, router, route, PageId.seller_orders);
  }
  override async ngOnInit() {
    await super.ngOnInit();
    this.appStateService.selectedOrderFilters$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (value) => {
        //console.log("data passing service:- ", value);
        this.searchOrderInput.vendorId = value?.vendorId || '';
        this.searchOrderInput.startDate = value?.startDate || '';
        this.searchOrderInput.endDate = value?.endDate || '';
        this.searchOrderInput.orderStatusIds = value?.orderStatusIds || [];
        const { orderStatusIds } = this.searchOrderInput;
        this.isEdit = orderStatusIds.length === 1 && orderStatusIds[0] === 1;
        if (this.searchOrderInput.vendorId) {
          await this.loadData();

        }
      });
  }
  async loadData() {
    try {
      const resultArray: productViewResponse = await this.orderService.getOrderItemsBySummeryVendorId(this.searchOrderInput);
      this.rawOrderResponse = resultArray.data;
      this.dataSource = resultArray.data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.totalProductCount = resultArray.totalCount
      if (this.totalProductCount == 0) {
        Swal.fire('Warning!', 'No Products Found!', 'warning');
        return;
      }
    } catch (error) {
      console.error('MarginComponent loadData :', error);
    }
  }
  private initializeSearchInput(): OrderFilters {
    return {
      endDate: getDateFormat(new Date()),
      startDate: getDateFormat(new Date()),
      pageSize: this.pageSize,
      pageNumber: this.pageNumber,
      vendorClusterIds: [],
      orderStatusIds: [],
      vendorId: this.vendor?.id || "",
    };
  }
  mapToVendorOrderItems(data: any[]) {
    return data.map((item) => ({
      orderNumber: item.order_number,
      customerId: item.customer_id,
      customerName: item.customer_name,
      vendorId: item.vendor_id,
      vendorName: item.vendor_name || '',
      orderStatusId: item.order_status_id,
      orderStatus: getEnumNameByValue(OrderStatus, item.order_status_id),
      logisticsStatusId: item.logistics_status_id || '',
      orderTotal: item.total_amount || undefined,
      totalWeight: item.total_weight || undefined,
      orderDate: item.order_date || undefined,
      skuCount: item.sku_count || undefined,
      totalRows: item.total_rows || 0,
    }));
  }
  override async onPageChange(event: any): Promise<void> {
    await super.onPageChange(event);
    this.searchOrderInput.pageSize = this.pageSize;
    this.searchOrderInput.pageNumber = this.pageNumber;
    await this.loadData();
  }


  private adjustQuantity(item: ProductViewProductDetails, availableQty: number) {
    const sortedOrders = item.orderNumbers.filter((o) => o.order_status_id < OrderStatus.OrderInvoiced)
      .sort((a, b) => b.quantity - a.quantity); // Sort orders by quantity (descending)

    let remainingQty = +availableQty

    const fulfilledOrders: OrderToModify[] = [];

    for (const order of sortedOrders) {
      if (order.quantity <= remainingQty) {
        remainingQty -= order.quantity;
        fulfilledOrders.push({
          orderNumber: order.orderNumber,
          skuId: item.skuId,
          quantity: order.quantity,
        });
        // order.qty = 0; // Set order quantity to 0 if fully fulfilled
      } else {
        fulfilledOrders.push({
          orderNumber: order.orderNumber,
          skuId: item.skuId,
          quantity: remainingQty,
        });
        // order.qty -= remainingQty;
        remainingQty = 0;
        break; // Stop iterating when available quantity is depleted
      }
    }

    item.totalQuantity -= remainingQty; // Update overall item quantity
    return fulfilledOrders; // Return an array of fulfilled orders
  }


  async callAsyncService(item: any, reason: string): Promise<any> {
    const payload = {
      "reason": reason,
      "quantity": item.quantity,
      "sku_id": item.skuId,
    }
    await this.orderService.adjustOrderByVendor(payload, item.orderNumber);
    return Promise.resolve(); // Placeholder for your async call
  }
  openEditPopup(skuData: skuProductData): void {
    const dialogRef = this.dialog.open(NaukaOrdersPopupComponent, {
      width: '50%',
      data: {
        dialogInput: skuData,
      },
    });
    dialogRef.afterClosed().subscribe(async (response) => {
      if (response != null) {
        console.log("response", response);
        const qtyToDecrees =
          skuData.totalQuantity - skuData.invoiceQty - +response;
        console.log("skuData", skuData);
        let adjustedOrders = this.adjustQuantity(skuData, qtyToDecrees);

        adjustedOrders = adjustedOrders.filter((o) => o.quantity > 0)

        console.log("adjustedOrders:::", adjustedOrders);

        await this.modifyOrders(adjustedOrders);
        await this.modifyHopItems(adjustedOrders, skuData),


          // for (const item of adjustedOrders) {
          //   await this.callAsyncService(item, response.reason);
          // }
          await this.loadData();
        Swal.fire('', "Product quantity successfully updated!", 'success');
        this.summaryEvent.emit("Calculate Summary from items");
      }
    });
  }



  private async modifyOrders(ordersToBeModified: OrderToModify[]) {
    try {
      const mappedOrderModify: OrderReturnApiInput[] = ordersToBeModified.map(
        (order) => {
          return {
            orderNumber: order.orderNumber,
            modifiedItems: [
              {
                reason: `Modified by vendor from web Page`,
                quantity: +order.quantity,
                sku_id: order.skuId,
              },
            ],
          };
        }
      );


      const response = await this.orderService.bulkOrderModify(
        mappedOrderModify
      );

      return response;
    } catch (error) {
      throw error;
    }
  }


  private async modifyHopItems(
    ordersToBeModified: OrderToModify[],
    item: skuProductData
  ) {
    try {
      const payload: ModifyOrderByHop[] = ordersToBeModified.map((order) => {
        const itemOrder = item.orderNumbers.find(
          (o) => o.orderNumber == order.orderNumber
        );

        console.log("itemOrder::", itemOrder);
        // O1 - 5

        // itemOrder = 5

        // 5 ? 5 - 5 :

        const qty: number = itemOrder?.quantity
          ? itemOrder?.quantity - order.quantity
          : itemOrder?.quantity || order.quantity;
        const unitWeight: number = item.unitWeight || 0;
        return {
          orderNumber: order.orderNumber,
          modifiedItems: [
            {
              sku_id: order.skuId,
              quantity: qty,
              reason: `Modified by vendor from web Page`,
              weight: unitWeight * qty,
            },
          ],
        };
      });

      console.log("payLoad:::", payload);

      const updateHopItemsRes = await this.orderService.updateHopItemsByOrderItems(payload);
      return updateHopItemsRes;
    } catch (error) {
      // TODO log the error
      throw error;
    }
  }

}
