@if(hasView){
<div class="container-fluid p-2">
  <mat-card class="p-0">
    <mat-card-content class="p-0">
      <mat-sidenav-container fxFlex="0 1 auto">
        <mat-sidenav-content>
          <div class="table-container">
            <mat-table [dataSource]="dataSource" matSort>

              <ng-container matColumnDef="createdOrderDate">
                <mat-header-cell *matHeaderCellDef> Order Date</mat-header-cell>
                <mat-cell *matCellDef="let row" data-label="createdOrderDate">
                  {{row.createdOrderDate}}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="customerName">
                <mat-header-cell *matHeaderCellDef> Customer Name</mat-header-cell>
                <mat-cell *matCellDef="let row" data-label="customerName">
                  {{row.customerName}}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="customerBusinessName">
                <mat-header-cell *matHeaderCellDef>Customer Business Name</mat-header-cell>
                <mat-cell *matCellDef="let row" data-label="customerBusinessName">
                  {{row.customerBusinessName}}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="orderTotal">
                <mat-header-cell *matHeaderCellDef class="mat-text-center" style="width: 50px;"> Order Total
                </mat-header-cell>
                <mat-cell *matCellDef="let row" data-label="orderTotal" [ngClass]="'cell-text-center'"> {{
                  row.orderTotal | replaceCommas}}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="orderNumber">
                <mat-header-cell *matHeaderCellDef> Order Number</mat-header-cell>
                <mat-cell *matCellDef="let row" data-label="orderNumber">
                  {{row.orderNumber}}
                </mat-cell>
              </ng-container>



              <ng-container matColumnDef="orderStatus">
                <mat-header-cell *matHeaderCellDef> Order Status
                </mat-header-cell>
                <mat-cell *matCellDef="let row" data-label="orderStatus">
                  {{row.orderStatus}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
                <mat-cell *matCellDef="let row">
                  <button mat-raised-button (click)=" viewOrderDetails(row)" color="primary">
                    View Order
                  </button>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
          </div>

          <mat-paginator [length]="totalOrders" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="onPageChange($event)"></mat-paginator>

        </mat-sidenav-content>
      </mat-sidenav-container>
    </mat-card-content>
  </mat-card>
</div>
} @else {
<ng-container>
  <p class="text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
}