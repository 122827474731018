import { Injectable, inject } from '@angular/core';
import { Auth, createUserWithEmailAndPassword } from '@angular/fire/auth';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  Firestore,
  addDoc,
  collection,
  collectionData,
  deleteDoc,
  doc,
  docData,
  getDoc,
  orderBy,
  query,
  setDoc,
  updateDoc,
} from '@angular/fire/firestore';
import { environment } from '../../environments/environment';
import { lastValueFrom } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class UsersService {
  firestore: Firestore = inject(Firestore);
  auth: Auth = inject(Auth);
  constructor(public http: HttpClient) {}

  getAllPages() {
    const aCollection = collection(this.firestore, 'pages');
    let q = query(aCollection);
    let items$ = collectionData(q, { idField: 'id' });
    return items$;
  }
  getAllRoles() {
    const aCollection = collection(this.firestore, 'Roles');
    let q = query(aCollection);
    let items$ = collectionData(q, { idField: 'id' });
    return items$;
  }
  getAllUsers() {
    const aCollection = collection(this.firestore, 'Users');
    let q = query(aCollection);
    let items$ = collectionData(q, { idField: 'id' });
    return items$;
  }
  async getUserById(id: any) {
    const aDoccument = doc(this.firestore, `Users/${id}`);
    let item = (await getDoc(aDoccument)).data();
    return item;
  }
  async addUsers(data: any) {
    data.timestamp = new Date();
    try {
      let uid = '';
      let userData = await this.getAuthUserByEmailId(data.email_id);
      if (!(userData && userData?.response)) {
        const headers = new HttpHeaders().set('No-Auth', 'true');
        let functionCall$ = this.http.post(
          `${environment.cloudFunctionsUrl}/createUser`,
          {
            email: data.email_id,
            password: data.password,
          },
          { headers }
        );
        const res: any = await lastValueFrom(functionCall$);
        uid = res.userRecord?.userRecord?.uid;
      } else {
        uid = userData.response?.uid;
      }

      const aDoccument = doc(this.firestore, `Users/${uid}`);
      delete data.password;
      await setDoc(aDoccument, data);
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }
  async getAuthUserByEmailId(emailId: string): Promise<any> {
    try {
      const headers = new HttpHeaders().set('No-Auth', 'true');

      let functionCall$ = this.http.get(
        `${environment.cloudFunctionsUrl}/get_auth_user_by_email_id?emailid=${emailId}`,
        { headers }
      );
      const userResult: any = await lastValueFrom(functionCall$);
      if (userResult && userResult.response) {
        return userResult.response;
      } else {
        return {};
      }
    } catch {
      return {};
    }
  }
  updateUsers(id: any, data: any) {
    delete data.password;
    const aDoccument = doc(this.firestore, `Users/${id}`);
    return updateDoc(aDoccument, data);
  }
  deleteUsers(id: any) {
    const aDoccument = doc(this.firestore, `Users/${id}`);
    return deleteDoc(aDoccument);
  }
  /* async signUp(data: any) {
    try {
      let res: any = await createUserWithEmailAndPassword(
        this.auth,
        data.email,
        data.password
      );
      const aDoccument = doc(this.firestore, `Users/${res.user.uid}`);
      delete data.password
      await setDoc(aDoccument, data);
    } catch (err) {
      console.log(err);
    }
  } */
}
