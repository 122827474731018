<!-- <div class="me-3 p-2">
  <mat-icon role="button" (click)="back()">keyboard_backspace</mat-icon>
 </div> -->
@if (comment) {
  <div class="row">
    <div class="alert alert-warning" role="alert">
      {{comment}}
    </div>
  </div>
}
<router-outlet></router-outlet>