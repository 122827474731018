import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { HopListComponent } from '../hop-list/hop-list.component';
import { TripSummaryListComponent } from '../trip-summarys-list/trip-summarys-list.component';
import { Location } from '@angular/common';
import { AppStateService } from '../../../../../services/appstate.service';
import { BaseListComponent } from '../../../../../shared/core/base.list.component';
import { AuthService } from '../../../../../services/auth.service';
import { PageId } from '../../../../../constants/enums';

@Component({
  selector: 'trip-details',
  standalone: true,
  templateUrl: './trip-details.component.html',
  styleUrl: './trip-details.component.scss',
  imports: [
    RouterOutlet,
    MatTabsModule,
    CommonModule,
    MatIconModule,
    RouterLink,
    TripSummaryListComponent,
    HopListComponent,
  ],
})
export class TripDetailsComponent extends BaseListComponent {
  id: any;
  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private location: Location,
  ) {
    super(auth, router, route, PageId.logistics_trips);
  }
  override ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      this.id = params['id'];
    });

  }

  goBack() {
    this.location.back();
  }
}
