<div class="row">
    <div class="col-md-6">
        <h2 class="card-title m-3 text-violet">Add Companies</h2>
      </div>
      <div class="col-md-6 d-flex justify-content-end">
        <mat-icon (click)="dialogRef.close()" class=" m-3 closeicon">close</mat-icon>
    </div>
</div>
<div class="container">
    <div class="card m-3">
      <div class="card-body card-padding">
        <div class="row">
          <div class="item" *ngFor="let item of mappedCompanies">
            <input type="checkbox" id="{{ item.companyId }}" [(ngModel)]="item.isSelected">
            <label class="ml-10" for="{{ item.companyName }}">{{ item.companyName }}</label>
          </div>
        </div>
        <div>
          <input type="file" accept=".geojson" (change)="onSelectFile($event)" class="m-10 choosefile"
                    id="fileInput" />
        </div>
        <div class="text-center mt-100">
          <label *ngIf="isCompanyUnselected" class="error-message">select only one option either unmap Companies or upload file</label>
          <label *ngIf="isJsonSelected" class="error-message">Didn't have any changes to save</label>
          <button mat-raised-button color="primary" (click)="unmapCompanies()">
            Save
          </button>
        </div>
        
      </div>
    </div>
  </div>
  
  
  

