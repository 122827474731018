import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-toaster-dialog',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatButtonModule
  ],
  templateUrl: './toaster-dialog.component.html',
  styleUrl: './toaster-dialog.component.scss'
})
export class ToasterDialogComponent {
constructor(  public dialogRef: MatDialogRef<ToasterDialogComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any){}
}
