import { Component, Input } from '@angular/core';
import { CompanyService } from '../../../../pages/company/services/company.service';
import { StatusComponent } from '../../../status/status.component';
import { TypePipe } from '../../../pipes/type.pipe';

@Component({
  selector: 'app-add-brand',
  standalone: true,
  imports: [StatusComponent,TypePipe],
  templateUrl: './add-brand.component.html',
  styleUrl: './add-brand.component.scss'
})
export class AddBrandComponent {
  @Input() data:any;
  companyName:any;
  constructor(public companyService:CompanyService){}
 
  ngOnInit() {
    if(this.data?.payload?.companies_id){
      this.getBrands()
    }
  
  }
 async getBrands(){
  try{
  let data:any = await this.companyService.getSingle(this.data?.payload?.companies_id)
  this.companyName=data.data[0]?.attributes?.name
  }
  catch(err:any){

  }
  }

}
