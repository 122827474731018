<div class="container-fluid p-2">

  <mat-card class="p-3 my-2">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 d-flex">
          <div class="me-3">
            <mat-icon role="button" (click)="back()">keyboard_backspace</mat-icon>
          </div>
          <h3 class="title">SKUs</h3>
        </div>
        <div class="col-md-6 text-end">
          <button (click)="addSku()" mat-raised-button color="primary"><mat-icon>add</mat-icon> Add</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 table-wrapper">
          <table class="table">
            <thead>
              <th>Name</th>
              <th>Status</th>
              <th style="width: 15%">Action</th>
            </thead>
            @for (item of draftSkUDocs; track $index) {
            <tr>
              <td>{{item.payload.sku.name}}</td>
              <td>{{item.status | titlecase}}</td>
              <td>
                <div class="d-flex">
                <button mat-raised-button color="primary" class="mx-2" (click)="editDraft(item.id)"><mat-icon
                    class="me-0">edit</mat-icon></button>
                <button mat-raised-button color="primary" class="mx-2" (click)="discardPendingDoc(item.id)"><mat-icon
                    class="me-0">delete</mat-icon></button>
                    </div>
              </td>
            </tr>
            }
            @for (item of masterSkus; track $index) {
            @if (!isItemInDraft(item.id)) {
            <tr>
              <td>{{item?.attributes?.name}}</td>
              <td>{{item?.attributes?.status | titlecase}}</td>
              <td>
                <div class="d-flex">
                <button mat-raised-button color="primary" class="mx-2" (click)="editSku(item.id)"><mat-icon
                    class="me-0">edit</mat-icon></button>
                @if(item.attributes.status=='active'){
                  <button mat-raised-button color="primary" class="mx-2 nowrap" (click)="changeStatus(item.id,'inactive','Inactive')">Mark Inactive</button>
                }
                @if(item.attributes.status=='inactive'){
                  <button mat-raised-button color="primary" class="mx-2 nowrap" (click)="changeStatus(item.id,'active','Active')">Mark Active</button>
                }
              </div>
              </td>
            </tr>
            }
            }
          </table>
        </div>

      </div>
    </div>
  </mat-card>

</div>