import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { CategoryService } from '../services/category.service';
import { MatSelectModule } from '@angular/material/select';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatIconModule } from '@angular/material/icon';
import { AuthService } from '../../../services/auth.service';
import { ProductService } from '../../product/services/product.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from '../../../services/common.service';
import { lastValueFrom, takeUntil } from 'rxjs';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { PageId } from '../../../constants/enums';
@Component({
  selector: 'app-category-add-edit',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    RouterLink,
    ReactiveFormsModule,
    MatSelectModule,
    NgSelectModule,
    MatIconModule
  ],
  templateUrl: './category-add-edit.component.html',
  styleUrl: './category-add-edit.component.scss',
})
export class CategoryAddEditComponent extends BaseListComponent {
  categoryForm: any;
  filename: any;
  previewImage: any;
  requestImageBody: any;
  id: any;
  companyList: any = []
  categoriesOption: any = []
  comment: any = ""
  constructor(
    auth: AuthService,
    route: ActivatedRoute,
    router: Router,
    public fb: FormBuilder,
    public categoryService: CategoryService,
    public location: Location,
    public productService: ProductService,
    public commonService: CommonService
  ) {
    super(auth, router, route, PageId.bms_category)
    this.categoryForm = this.fb.group({
      parent_categories_id: [null],
      display_name: ['', Validators.required],
      image_url: ['', Validators.required],
      name: [null, Validators.required],
    });
  }
  override async ngOnInit() {
    await super.ngOnInit()
    this.getAllCategories()
    this.route.params
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params) => {
        this.id = params['id'];
        if (this.id) {
          this.patchValue();
        }
      });
  }
  onCategorySelectionChange(event: any) {
    if (event['$ngOptionLabel'] == 'Root') {
      this.categoryForm.patchValue({
        parent_categories_id: null
      })
    }
  }
  async patchValue() {
    this.commonService.showLoder()
    if (this.hasBMSAdmin && this.id) {
      let res: any = (await this.categoryService.getSingle(this.id) as any).data[0].attributes
      this.categoryForm.patchValue(res);
      this.previewImage = res.image_url;
    }
    if (this.hasSellerAdmin && this.id) {
      this.productService.getSingleApprovalDoc(this.id).pipe(takeUntil(this.unsubscribe$)).subscribe((docData: any) => {
        let res: any = docData.payload;
        this.categoryForm.patchValue(res);
        this.previewImage = res.image_url;
        this.comment = docData?.comment;
      },
        (error: any) => {
          this.commonService.showToaster("error", error.message);
          this.commonService.hideLoder();
        })
    }
    this.commonService.hideLoder();
  }
  onFileChange(event: any) {
    if (event.target.files) {
      const file = event.target.files[0];
      this.filename = file.name;
      var reader = new FileReader();
      reader.onload = (event) => {
        this.previewImage = event?.target?.result;
        this.categoryForm.patchValue({
          image_url: event?.target?.result,
        });
        const parts = this.previewImage.split(',');
        let base64String = parts[1];
        this.requestImageBody = {
          fileName: this.filename,
          fileExtension: this.getFileExtension(this.filename),
          fileData: base64String,
        };
      };
      reader.readAsDataURL(file);
    }
  }
  getFileExtension(filename: any) {
    return filename.split('.').pop();
  }
  async submit() {
    this.commonService.showLoder();
    try {
      if (this.requestImageBody) {
        let res: any = await lastValueFrom(
          this.categoryService.upload(this.requestImageBody)
        );
        this.categoryForm.patchValue({
          image_url: res.data.url
        });
        this.commonService.hideLoder();
      }
      try {
        if (this.id) {
          if (this.hasBMSAdmin) {
            await this.categoryService.update(this.id, this.categoryForm.value);
            this.commonService.showToaster("success", 'Updated Successfully');
            this.router.navigateByUrl('/dashboard/category');
          }
          if (this.hasSellerAdmin) {
            this.productService.reSubmitRequestForApproval(this.id, this.categoryForm.value);
            this.commonService.showToaster("success", 'Your request is submitted for approval');
            this.router.navigateByUrl('/dashboard/my-requests');
          }
        } else {
          if (this.hasBMSAdmin) {
            await this.categoryService.add(this.categoryForm.value);
            this.commonService.showToaster("success", 'Added Successfully');
            this.router.navigateByUrl('/dashboard/category');
          }
          if (this.hasSellerAdmin) {
            this.productService.addToPendingApproval("add-category", this.categoryForm.value, "pending-approval");
            this.commonService.showToaster("success", 'Your request is submitted for approval');
            this.router.navigateByUrl('/dashboard/my-requests');
          }
        }
        this.categoryForm.reset();
        this.commonService.hideLoder();

      } catch (err: any) {
        this.commonService.showToaster("error", err.error.message);
        this.commonService.hideLoder();
      }
    } catch (err: any) {
      this.commonService.showToaster("error", err.error.message);
      this.commonService.hideLoder();
    }
  }

  back() {
    this.location.back();
  }

  getAllCategories() {
    this.categoryService.getAll()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((res: any) => {
      this.categoriesOption = res.data.filter((item: any) => {
        if (this.id) {
          return item.id != this.id && item.attributes.level < 2;
        } else {
          return item.attributes.level < 2;
        }
      })
    })
  }
  removeImage() {
    this.previewImage = null;
    this.filename = null;
    this.requestImageBody = null;
    this.categoryForm.patchValue({
      image_url: null
    });
    var fileInput: any = document.getElementById('fileInput');
    fileInput.value = '';
  }
}
