<div class="container-fluid py-3">
  <nav mat-tab-nav-bar  [tabPanel]="tabPanel"
  >
     <a mat-tab-link 
     [routerLink]="['/dashboard/product/skus/master_skus']" 
     routerLinkActive="active" > 
      <span class="sub">Published SKU</span> 
      </a>
      <a mat-tab-link
      [routerLink]="['/dashboard/product/skus/draft']" 
      routerLinkActive="active"
      > 
      <span class="sub">Draft SKU</span>
    </a>
    <a mat-tab-link
    [routerLink]="['/dashboard/product/skus/pending-tab']" 
    routerLinkActive="active"
    > 
    <span class="sub">Pending Approval</span>
  </a>
  </nav>
  <mat-tab-nav-panel #tabPanel>
    <router-outlet></router-outlet>
  </mat-tab-nav-panel>
</div>
