import { OrderService } from './../../../services/orderService.service';
import { Component, ViewChild } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { FormBuilder, ReactiveFormsModule, FormGroup, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { YYYYMMDD, getDateYYYYMMDD } from '../../../utlity/utility';
import {
  ModifyOrderByHop,
  OrderFilters,
  OrderReturnApiInput,
  OrderToModify,
  ProductViewProductDetails,
  productViewResponse,
  skuProductData,
} from '../../../models/order';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { NaukaOrdersPopupComponent } from '../nauka-orders-popup/nauka-orders-popup.component';
import Swal from 'sweetalert2';
import { LogisticsStatus, OrderStatus } from '../../../enums/enums';
import { PageId } from '../../../constants/enums';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { ReplaceCommasPipe } from '../../../shared/pipes/currency-pipe';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatTabsModule } from '@angular/material/tabs';
import { OrderListViewComponent } from '../order-list-view/order-list-view.component';
import { OrderProductViewComponent } from '../order-product-view/order-product-view.component';

@Component({
  selector: 'app-nauka-orders',
  standalone: true,
  templateUrl: './nauka-orders.component.html',
  styleUrl: './nauka-orders.component.scss',
  imports: [
    MatCardModule,
    MatButtonModule,
    CommonModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatDialogModule,
    MatFormFieldModule,
    NgSelectModule,
    ReactiveFormsModule,
    MatSortModule,
    MatTableModule,
    RouterLink,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatInputModule,
    FormsModule,
    RouterOutlet,
    MatTabsModule,
    ReplaceCommasPipe,
    MatDividerModule,
    OrderListViewComponent,
    OrderProductViewComponent,
    ReplaceCommasPipe,

  ],
})
export class NaukaOrdersComponent extends BaseListComponent {
  orders: any;
  rawOrderResponse: any;
  loading = false;
  dataSource: any;
  displayedColumns: string[] = [
    'skuName',
    'mrp',
    'inventory_selling_price',
    'totalQuantity',
    'invoiceQty',
    'availableQty',
    'cases',
    'pieces',
    'action',
  ];
  startDate: string = '';
  endDate: string = '';
  filters: OrderFilters = new OrderFilters();
  totalOrderWeight: number = 0;
  totalOrderAmount: number = 0;
  totalProductCount: number = 0;
  vendorClusterIds: number[] = [];
  orderStatusIds: number[] = [OrderStatus.OrderPlaced];
  downloadOptions: string[] = ['Pickup Indent'];
  dataLength: number = 0;
  filterForm: any;
  totalPageCount: number = 0;
  today = new Date();

  @ViewChild('downloadOptionsMenu') downloadOptionsMenu!: MatMenuTrigger;
  @ViewChild('generateOptionsMenu') generateOptionsMenu!: MatMenuTrigger;
  @ViewChild('vendorOrderPaginator') paginatorVendor!: MatPaginator;
  @ViewChild(MatSort) sortVendor!: MatSort;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    auth: AuthService,
    router: Router,
    private fb: FormBuilder,
    route: ActivatedRoute,
    private orderService: OrderService,
    private dialog: MatDialog
  ) {
    super(auth, router, route, PageId.seller_orders); // Call the constructor of BaseListComponent
    this.filterForm = this.fb.group({
      name: [''],
      startDate: [null],
      endDate: [null],
    });
  }
  override async ngOnInit() {
    await super.ngOnInit();
    if (this.vendorId) {
      const startDate: string = YYYYMMDD(new Date());
      const endDate: string = YYYYMMDD(new Date());
      this.setFilterDates(startDate, endDate)
      await this.getProducts(startDate, endDate);
    }
  }

  private setFilterDates(startDate: string, endDate: string) {
    this.filters.startDate = startDate;
    this.filters.endDate = endDate;
    this.filterForm.get('startDate')?.setValue(new Date(startDate));
    this.filterForm.get('endDate')?.setValue(new Date(endDate));
  }

  async searchOrders() {
    const startDate = this.filterForm.get('startDate')?.value;
    const endDate = this.filterForm.get('endDate')?.value;
    const orderStatusIds = this.filterForm.get('orderStatus')?.value;

    // Update filter values
    this.filters.startDate = getDateYYYYMMDD(startDate);
    this.filters.endDate = getDateYYYYMMDD(endDate);
    this.filters.vendorId = this.vendorId;
    this.filters.orderStatusIds = orderStatusIds;
    if (this.filters.endDate < this.filters.startDate) {
      this.totalOrderAmount = 0;
      this.totalOrderWeight = 0;
      Swal.fire({
        icon: 'warning',
        title: 'Invalid Date Range',
        text: 'End date must be greater than or equal to start date.',
      });
      return
    }
    // Emit updated filter values

    await this.getProducts(this.filters.startDate, this.filters.endDate);

  }

  async clearFilters() {
    // Reset form controls
    this.filterForm.get('startDate')?.reset();
    this.filterForm.get('endDate')?.reset();
    this.totalOrderAmount = 0;
    this.totalOrderWeight = 0;
    this.dataSource = [];


  }


  private async getProducts(startDate: string, endDate: string) {
    this.filters = this.initializeFilterData(startDate, endDate);
    await this.getOrdersByFilters(this.filters);
    this.setupFormValueChanges();
  }

  private setupFormValueChanges(): void {
    this.filterForm.valueChanges.subscribe((value: any) => {
      if (value.name && value.name.length > 0) {
        const searchResult = this.orders.filter((order: { skuName: string }) =>
          order.skuName.toLowerCase().includes(value.name.toLowerCase())
        );
        this.updateDataSource(searchResult, this.pageSize);
      } else {
        this.updateDataSource(this.orders, this.totalPageCount);
      }
    });
  }

  private updateDataSource(data: any[], totalPageCount: number): void {

    this.dataSource = data;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataLength = totalPageCount;
  }

  override async onPageChange(event: any): Promise<void> {
    await super.onPageChange(event);
    this.startDate = YYYYMMDD(new Date());
    this.endDate = YYYYMMDD(new Date());
    await this.getProducts(this.startDate, this.endDate);
  }

  private async getOrdersByFilters(payLoad: any) {
    try {
      this.loading = true;


      const resultArray: productViewResponse =
        await this.orderService.getOrderItemsBySummeryNaukaVendorId(payLoad);
      if (resultArray && resultArray.data.length > 0) {
        (this.totalOrderAmount = resultArray.totalRevenue),
          (this.totalOrderWeight = resultArray.totalWeight);
        this.processOrderResponse(resultArray);
      } else {

        this.handleEmptyResponse();
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
    } finally {
      this.loading = false;
    }
  }

  async onDownloadOptionClick(optionName: string) {
    await this.indentSheet();
  }


  private getUniqueOrderNumbers(data: Array<{ orderNumbers: Array<{ orderNumber: string }> }>): string[] {
    const orderNumbersSet = new Set(
      data.flatMap(item => item.orderNumbers.map(order => order.orderNumber))
    );
    return Array.from(orderNumbersSet);
  }

  async indentSheet() {
    if (this.dataSource.length === 0) {
      Swal.fire('', 'Orders not found', 'warning');
      return;
    }
    const startDate = this.filterForm.get('startDate')?.value;
    const endDate = this.filterForm.get('endDate')?.value;

    const orderFilters:OrderFilters ={
      vendorId: this.vendorId,
      startDate: getDateYYYYMMDD(startDate),
      endDate: getDateYYYYMMDD(endDate),
      pageSize: 2000,
      pageNumber:1,
      vendorClusterIds: [],
      orderStatusIds:[1]
    }

    const resultArray: productViewResponse =
    await this.orderService.getOrderItemsBySummeryNaukaVendorId(orderFilters);

    const orderNumbers =this.getUniqueOrderNumbers(resultArray.data)

    const res = {
      order_numbers: orderNumbers,
    };

    await this.orderService.indentSheet(res, this.BusinessType);

  }
  async callAsyncService(item: any, reason: string): Promise<void> {
    const payload = {
      reason: reason,
      quantity: item.quantity,
      sku_id: item.skuId,
    };
    await this.orderService.adjustOrderByVendor(payload, item.orderNumber);
  }

  reset() {
    this.filterForm.patchValue({
      name: null,
      companies_id: null,
      brands_id: null,
      categories_id: null,
      page: 1,
      limit: this.pageSize,
    });
    //this.getProducts()
  }

  openEditPopup(skuData: skuProductData): void {
    const dialogRef = this.dialog.open(NaukaOrdersPopupComponent, {
      width: '50%',
      data: {
        dialogInput: skuData,
      },
    });
    dialogRef.afterClosed().subscribe(async (response) => {
      if (response != null) {
        const qtyToDecrees =
          skuData.totalQuantity - skuData.invoiceQty - +response;
        let adjustedOrders = this.adjustQuantity(skuData, qtyToDecrees);

        adjustedOrders = adjustedOrders.filter((o) => o.quantity > 0)


        await this.modifyOrders(adjustedOrders);
        await this.modifyHopItems(adjustedOrders, skuData),


          // for (const item of adjustedOrders) {
          //   await this.callAsyncService(item, response.reason);
          // }
          await this.getOrdersByFilters(this.filters);
        Swal.fire('', "Product quantity successfully updated!", 'success');
      }
    });
  }

  //Private methods

  private processOrderResponse(response: productViewResponse): void {
    this.orders = response.data;
    this.totalPageCount = response.totalCount;
    this.updateDataSource(this.orders, response.totalCount);
  }

  private handleEmptyResponse(): void {
    this.loading = false;
    this.totalOrderAmount = 0;
    this.totalOrderWeight = 0;
    this.dataSource = [];
    this.orders = []
    Swal.fire('', "No products found!", 'warning');


  }

  private adjustVendorModifyQuantity(
    items: ProductViewProductDetails,
    adjustingSkuDetail: any
  ): any {
    const highestQtyItems = items.orderNumbers.sort(
      (a, b) => b.quantity - a.quantity
    );
    if (+adjustingSkuDetail.availableQuantity <= 0) {
      return highestQtyItems;
    }
    let remainingQuantity =
      items.totalQuantity - +adjustingSkuDetail.availableQuantity;
    let modifiedOrderQty: Array<any> = [];
    for (const item of highestQtyItems) {
      if (remainingQuantity <= 0) {
        return modifiedOrderQty;
      }
      if (item.quantity < remainingQuantity) {
        item.quantity = item.quantity;
        item.skuId = items.skuId;
        modifiedOrderQty.push(item);
        remainingQuantity -= item.quantity;
      } else {
        item.quantity = remainingQuantity;
        item.skuId = items.skuId;
        modifiedOrderQty.push(item);
        remainingQuantity = 0;
      }
    }

    return modifiedOrderQty;
  }

  private initializeFilterData(startDate: string, endDate: string) {

    return {
      vendorId: this.vendorId,
      startDate: startDate,
      endDate: endDate,
      pageSize: this.pageSize,
      pageNumber: this.pageNumber,
      vendorClusterIds: this.vendorClusterIds,
      orderStatusIds: this.orderStatusIds,
      // logisticsStatusIds: [
      //   LogisticsStatus.ReadyForPickup,
      //   LogisticsStatus.ReadyForPickupPlan,
      // ],
    };
  }

  // private async handleEditPopupResponse(
  //   skuData: any,
  //   response: any
  // ): Promise<void> {
  //   const adjustedOrders = this.adjustVendorModifyQuantity(skuData, response);
  //   for (const item of adjustedOrders) {
  //     await this.callAsyncService(item, response.reason);
  //   }
  //   await this.getOrdersByFilters(this.filters);
  //   Swal.fire('', 'Product quantity successfully updated!', 'success');
  // }



  private adjustQuantity(item: ProductViewProductDetails, availableQty: number) {
    const sortedOrders = item.orderNumbers.filter((o) => o.order_status_id < OrderStatus.OrderInvoiced)
      .sort((a, b) => b.quantity - a.quantity); // Sort orders by quantity (descending)

    let remainingQty = +availableQty

    const fulfilledOrders: OrderToModify[] = [];

    for (const order of sortedOrders) {
      if (order.quantity <= remainingQty) {
        remainingQty -= order.quantity;
        fulfilledOrders.push({
          orderNumber: order.orderNumber,
          skuId: item.skuId,
          quantity: order.quantity,
        });
        // order.qty = 0; // Set order quantity to 0 if fully fulfilled
      } else {
        fulfilledOrders.push({
          orderNumber: order.orderNumber,
          skuId: item.skuId,
          quantity: remainingQty,
        });
        // order.qty -= remainingQty;
        remainingQty = 0;
        break; // Stop iterating when available quantity is depleted
      }
    }

    item.totalQuantity -= remainingQty; // Update overall item quantity
    return fulfilledOrders; // Return an array of fulfilled orders
  }



  private async modifyOrders(ordersToBeModified: OrderToModify[]) {
    try {
      const mappedOrderModify: OrderReturnApiInput[] = ordersToBeModified.map(
        (order) => {
          return {
            orderNumber: order.orderNumber,
            modifiedItems: [
              {
                reason: `Modified by vendor from web Page`,
                quantity: +order.quantity,
                sku_id: order.skuId,
              },
            ],
          };
        }
      );


      const response = await this.orderService.bulkOrderModify(
        mappedOrderModify
      );

      return response;
    } catch (error) {
      throw error;
    }
  }


  private async modifyHopItems(
    ordersToBeModified: OrderToModify[],
    item: skuProductData
  ) {
    try {
      const payload: ModifyOrderByHop[] = ordersToBeModified.map((order) => {
        const itemOrder = item.orderNumbers.find(
          (o) => o.orderNumber == order.orderNumber
        );

        console.log("itemOrder::", itemOrder);
        // O1 - 5

        // itemOrder = 5

        // 5 ? 5 - 5 :

        const qty: number = itemOrder?.quantity
          ? itemOrder?.quantity - order.quantity
          : itemOrder?.quantity || order.quantity;
        const unitWeight: number = item.unitWeight || 0;
        return {
          orderNumber: order.orderNumber,
          modifiedItems: [
            {
              sku_id: order.skuId,
              quantity: qty,
              reason: `Modified by vendor from web Page`,
              weight: unitWeight * qty,
            },
          ],
        };
      });

      console.log("payLoad:::", payload);

      const updateHopItemsRes = await this.orderService.updateHopItemsByOrderItems(payload);
      return updateHopItemsRes;
    } catch (error) {
      // TODO log the error
      throw error;
    }
  }
}
