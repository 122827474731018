@if(hasCreate){
<ng-container>
  <div class="heading-block">
    <div class="row">
      <div class="col-md-6">
        <mat-icon class="backicon" [routerLink]="['/dashboard/supplier/supplier-list']">arrow_back</mat-icon>
        <span class="header">
          <h2 class="card-title m-3">Create Supplier</h2>
        </span>
      </div>
      <div class="col-md-6 text-right"></div>
    </div>
  </div>
  <div class="table-block">
    <div class="mat-elevation-z8">
      <form [formGroup]="dataSource">
        <section id="content">
          <div class="card">
            <div class="card-body card-padding">
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Supplier Name*</mat-label>
                    <input matInput formControlName="name" name="name" placeholder="Supplier Name" />
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Mobile Number*</mat-label>
                    <input matInput type="string" formControlName="mobileNumber" name="mobileNumber"
                      placeholder="Mobile Number" [maxLength]="10" />
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Address*</mat-label>
                    <input matInput formControlName="residence" name="residence " placeholder="Flat No/ Address" />
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Area Name*</mat-label>
                    <input matInput formControlName="area" name="area " placeholder="Area Name" />
                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Landmark*</mat-label>
                    <input matInput formControlName="landmark" name="landmark " placeholder="Land Mark" />
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>City*</mat-label>
                    <input matInput formControlName="city" name="city" placeholder="City" />
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Pin Code*</mat-label>
                    <input matInput formControlName="pinCode" name="pinCode" placeholder="Pin Code" type="number"
                      [maxlength]="6" />
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>select State*</mat-label>
                    <mat-select formControlName="state">
                      @for (state of states; track state) {
                      <mat-option [value]="state.name">{{
                        state.name
                        }}</mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Latitude*</mat-label>
                    <input matInput formControlName="latitude" name="latitude" placeholder="Latitude" type="number" />
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Longitude*</mat-label>
                    <input matInput formControlName="longitude" name="longitude" placeholder="Longitude"
                      type="number" />
                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Aadhar Number</mat-label>
                    <input matInput type="number" formControlName="aadharNumber" name="aadharNumber"
                      placeholder="Aadhar Number" />
                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-label>Upload Aadhar</mat-label>
                  <input formControlName="aadharCopy" class="form-control" (change)="onFileChange($event, 'aadhar')"
                    type="file" />
                </div>

                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Pan Number</mat-label>
                    <input matInput formControlName="panNumber" name="panNumber" placeholder="Pan Number"
                      (input)="toUppercase($event)" />
                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-label>Upload Pan</mat-label>
                  <input formControlName="panCopy" class="form-control" (change)="onFileChange($event, 'pan')"
                    type="file" />
                </div>

                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>GST Number</mat-label>
                    <input matInput formControlName="gstNumber" name="gstNumber" placeholder="GST Number"
                      (input)="toUppercase($event)" />
                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-label>Upload GST</mat-label>
                  <input formControlName="gstCopy" class="form-control" (change)="onFileChange($event, 'gst')"
                    type="file" />
                </div>

                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Supplier Type*</mat-label>
                    <mat-select formControlName="supplierType">
                      <mat-option *ngFor="let type of supplierTypes" [value]="type">
                        {{ supplierTypeNames[type] }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>TCS Percentage*</mat-label>
                    <input matInput formControlName="tcsPercentage" name="tcsPercentage" placeholder="TCS Percentage"
                      type="number" />
                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Supplier Contract Mode*</mat-label>
                    <mat-select formControlName="contractMode">
                      <mat-option *ngFor="let type of supplierContractMode" [value]="type">
                        {{ type === 1 ? "Fixed" : "Adhoc" }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field class="full-width">
                    <mat-label>Status*</mat-label>
                    <mat-select formControlName="status">
                      <mat-option *ngFor="let type of supplierStatus" [value]="type">
                        {{ type === 0 ? "InActive" : "Active" }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Credit Days</mat-label>
                  <input matInput type="number" formControlName="creditDays" name="creditDays" placeholder="Credit Days"
                    [maxLength]="2" />
                </mat-form-field>
              </div>
              <div class="row">
                <div class="buttonSection">
                  @if(hasCreate){
                  <button mat-raised-button color="primary" class="text-center ml" (click)="saveSupplier()">
                    Save
                  </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
</ng-container>
} @else {
<ng-container>
  <p class="text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
}