@if(hasEdit){
<ng-container>
  <div class="heading-block">
    <div class="row">
      <div class="col-md-6">
        <mat-icon class="backicon" [routerLink]="['/dashboard/merchant']">arrow_back</mat-icon>
        <span class="header">
          <h2 class="card-title m-3">Update Vendor</h2>
        </span>
      </div>
      <div class="col-md-6 text-right"></div>
    </div>
  </div>

  <div class="table-block">
    <form [formGroup]="dataSource">
      <section id="content">
        <div class="card">
          <div class="card-body card-padding">
            <div class="row">
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Vendor Id</mat-label>
                  <input matInput type="string" formControlName="id" name="id" placeholder="Vendor Id"
                    [readonly]="true" />
                </mat-form-field>
              </div>

              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Business Name</mat-label>
                  <input matInput type="string" formControlName="businessName" name="businessName"
                    placeholder="Business Name" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Name</mat-label>
                  <input matInput type="string" formControlName="name" name="name" placeholder="Name" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Email</mat-label>
                  <input matInput type="string" formControlName="email" name="email" placeholder="Email" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Mobile Number</mat-label>
                  <input [readonly]="true" matInput type="number" formControlName="mobileNumber" name="mobileNumber"
                    placeholder="Mobile Number" />
                </mat-form-field>
              </div>

              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Business Type</mat-label>
                   <input [readonly]="true" matInput  formControlName="businessType" name="businessType">
                  <!-- <mat-select formControlName="businessType">
                    <mat-option *ngFor="let option of businessTypes" [value]="option.value">
                      {{ option.type }}
                    </mat-option>
                  </mat-select> -->
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Gst Number</mat-label>
                  <input matInput type="string" formControlName="gstNumber" name="gstNumber" placeholder="Gst Number" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Pan Number</mat-label>
                  <input matInput type="string" formControlName="panNumber" name="panNumber" placeholder="Pan Number" />
                </mat-form-field>
              </div>


              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>D-No/Street</mat-label>
                  <input matInput type="string" formControlName="address" name="address" placeholder="D-No/Street" />
                </mat-form-field>
              </div>

              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Area</mat-label>
                  <input matInput type="string" formControlName="area" name="area" placeholder="Area" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>City</mat-label>
                  <input matInput type="string" formControlName="city" name="city" placeholder="City" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Pincode</mat-label>
                  <input matInput type="string" formControlName="pincode" name="pincode" placeholder="Pincode"
                    [maxlength]="6" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>State</mat-label>
                  <mat-select formControlName="state">
                    <mat-option *ngFor="let state of states" [value]="state.name">
                      {{ state.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Latitude</mat-label>
                  <input matInput type="number" formControlName="latitude" name="latitude" placeholder="Latitude" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Longitude</mat-label>
                  <input matInput type="number" formControlName="longitude" name="longitude" placeholder="Longitude" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Status</mat-label>
                  <mat-select formControlName="status">
                    <mat-option *ngFor="let option of status" [value]="option.value">
                      {{ option.type }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="buttonSection">
                @if(hasEdit){
                <button mat-raised-button color="primary" class="text-center ml" (click)="updateMerchant()">
                  Update
                </button>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  </div>
</ng-container>
} @else {
<ng-container>
  <p class="text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
}
