import { Component, OnInit } from "@angular/core";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { ActivatedRoute, RouterLink } from "@angular/router";
import { Coupon } from "../../../entities/coupon";
import { CouponService } from "../../../services/coupon.service";
import { MatCardModule } from "@angular/material/card";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { MatIconModule } from "@angular/material/icon";
import { CommonModule } from "@angular/common";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatRadioModule } from "@angular/material/radio";
import Swal from 'sweetalert2';


@Component({
  selector: "app-coupon-details",
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    RouterLink,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatTableModule
  ],
  templateUrl: "./coupon-details.component.html",
  styleUrls: ["./coupon-details.component.scss"],
})
export class CouponDetailsComponent implements OnInit {
  couponDetails?: Coupon;
  dataSource?: MatTableDataSource<any> | undefined;
  displayedColumns: string[] = ['categoryName', 'minimumPercent'];

  dataSourceCategory?: MatTableDataSource<any>;
  displayedColumnsCategory: string[] = ['id', 'businessName', 'mobileNumber'];

  dataSourceProduct?: MatTableDataSource<any>;
  displayedColumnsProduct: string[] = [ 'skuId', 'productName'];

  constructor(
    public couponService: CouponService,
    public route: ActivatedRoute
  ) { }

  ngOnInit() {
    // TODO need add Loading event.
    this.getCouponDetails();
  }

  async getCouponDetails() {
    try {
      const couponDetails: any = await this.couponService.getCouponById(this.route.snapshot.params["id"]);
      console.log(couponDetails);
      this.couponDetails = couponDetails;
      this.dataSource = new MatTableDataSource(this.couponDetails?.minimumCategoryWisePercent);
      this.dataSourceCategory = new MatTableDataSource(this.couponDetails?.customerIds);
      this.dataSourceProduct = new MatTableDataSource(this.couponDetails?.productIds);
    } catch (error) {
      Swal.fire('', "Some Thing went Wrong.", 'error')
    }
  }
}
