import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Auth } from '@angular/fire/auth';
import { AuthService } from '../../../services/auth.service';
import { lastValueFrom, map } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  auth: Auth = inject(Auth);
  constructor(public http: HttpClient, public authService: AuthService) { }

  upload(body: any) {
    return this.http.put(`${environment.fileUploadBaseUrl}/images/company`, body);
  }

  async add(data: any) {
    const res = await lastValueFrom(this.http.post<any>(`${environment.backendUrl}/companies`, data));
    return res && res.data
  }

  delete(id: string) {
    return lastValueFrom(this.http.delete(`${environment.backendUrl}/companies/${id}`))
  }
  getSingle(id: string) {
    return lastValueFrom(this.http.get(`${environment.backendUrl}/companies/${id}`))
  }
  async update(id: string, data: any) {
    return lastValueFrom(this.http.patch(`${environment.backendUrl}/companies/${id}`, data))
  }

  getAll(queryObj?: any) {
    if(!queryObj){
      queryObj={limit:10000}
    }
    return this.http.get(`${environment.backendUrl}/companies?${this.objectToQueryString(queryObj)}`)
  }
  objectToQueryString(obj: any): string {
    let params = new HttpParams();

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] != null) {
          params = params.append(key, obj[key]);
        }
      }
    }

    return params.toString();
  }

  async postMessageToMessageQueue(messageType: string, body: {}) {
    try {
      const messageQueueUrl = `${environment.messagingService}/messagequeue/post`;
      return lastValueFrom(
        this.http.post(messageQueueUrl, { messageType: messageType, input: JSON.stringify(body) }).pipe(map((response) => {

          return response;
        }))
      )
    } catch (error) {

      throw error;
    }
  }
}
