<div class="container-fluid">


    <mat-card class="p-2 mt-3">
    <h4 class="mt-3">SKU Details</h4>
    <div class="row">
    <div class="col-md-4 c-border">
      <label>Company Name</label>
      <p>{{skuData?.product?.companies_name}}</p>
    </div>
    <div class="col-md-4 c-border">
      <label>Brand Name</label>
      <p>{{skuData?.product?.brands_name}}</p>
    </div>
    <div class="col-md-4 c-border">
      <label>Categories Browser Path</label>
      <p>{{skuData?.product?.categories_browser_path}}</p>
    </div>
    <div class="col-md-4 c-border">
      <label>Name</label>
      <p>{{skuData?.sku?.name}}</p>
    </div>
    <div class="col-md-4 c-border">
      <label>Type</label>
      <p>{{data?.type | type}}</p>
    </div>
    <div class="col-md-4 c-border">
      <label>Status</label>
      <p><app-status [value]="data.status"></app-status></p>
    </div>
    <div class="col-md-4 c-border">
      <label>Short Code</label>
      <p>{{skuData?.sku?.print_name }}</p>
    </div>
    <div class="col-md-4 c-border">
      <label>Barcode</label>
      <p>{{skuData?.sku?.barcode}}</p>
    </div>
    <div class="col-md-4 c-border">
      <label>Short Description</label>
      <p>{{skuData?.sku?.short_description}}</p>
    </div>
    <div class="col-md-8 ">
      <label>Long Description</label>
      <p>{{skuData?.sku?.long_description}}</p>
    </div>
    @if(skuData?.sku?.selling_units?.length > 0){
    <div class="col-md-4" >
      <label>Selling Units</label>
      <ul>
        @for (item of skuData?.sku?.selling_units; track $index) {
        <li>
         {{item}}
        </li>
      }
      </ul>
    </div>
  }</div>
  </mat-card>

    <!-- <div class="col-md-3">
      <label>Vendor SKU packing section</label>
      <p>{{skuData?.sku?.vendor_sku_packing_section_id}}</p>
    </div>
    <div class="col-md-3">
      <label>Vendor SKU tax details</label>
      <p>{{skuData?.sku?.vendor_sku_tax_details_id}}</p>
    </div> -->
    <mat-card class="p-2 mt-3">
    <h4 class="mt-3">SKU Tax Details</h4>
    <div class="row">
    <div class="col-md-3 c-border">
      <label>Countries Name</label>
      <p>{{skuData?.sku?.sku_tax_details?.countires_name}}</p>
    </div>
    <div class="col-md-3 c-border">
      <label>HSN Code</label>
      <p>{{skuData?.sku?.sku_tax_details?.hsn_code}}</p>
    </div>
    <div class="col-md-3 c-border">
      <label>Tax Categories Name</label>
      <p>{{skuData?.sku?.sku_tax_details?.tax_categories_name}}</p>
    </div>
     <div class="col-md-3 c-border">
      <label>Tax Percentage</label>
      <p>{{skuData?.sku?.sku_tax_details?.tax_percentage}}</p>
    </div>
    </div>
    </mat-card>

    <mat-card class="p-2 mt-3">
      <div class="row">
    <h4 class="mt-3">SKU Packing Sections</h4>
    <div class="col-md-3 c-border">
      <label>Mrp</label>
      <p>{{skuData?.sku?.sku_packing_sections?.mrp | replaceCommas}}</p>
    </div>
    <div class="col-md-3 c-border">
      <label>Inner Pack</label>
      <p>{{skuData?.sku?.sku_packing_sections?.inner_pack
        }}</p>
    </div>
    <div class="col-md-3 c-border">
      <label>Min Order Qty</label>
      <p>{{skuData?.sku?.sku_packing_sections?.min_order_qty}}</p>
    </div>
    <div class="col-md-3 c-border">
      <label>Product Package Type</label>
      <p>{{skuData?.sku?.sku_packing_sections?.product_package_type}}</p>
    </div>
    <div class="col-md-3 c-border">
      <label>Upc</label>
      <p>{{skuData?.sku?.sku_packing_sections?.upc}}</p>
    </div>
    <div class="col-md-3 c-border">
      <label>Uom</label>
      <p>{{skuData?.sku?.sku_packing_sections?.uom}}</p>
    </div>
    <div class="col-md-3 c-border">
      <label>Height</label>
      <p>{{skuData?.sku?.sku_packing_sections?.dimensions?.height}}</p>
    </div>
    <div class="col-md-3 c-border">
      <label>Length</label>
      <p>{{skuData?.sku?.sku_packing_sections?.dimensions?.length}}</p>
    </div>
    <div class="col-md-3 ">
      <label>Width</label>
      <p>{{skuData?.sku?.sku_packing_sections?.dimensions?.width}}</p>
    </div>
    <div class="col-md-3  ">
      <label>Grams</label>
      <p>{{skuData?.sku?.sku_packing_sections?.weight_per_unit?.grams}}</p>
    </div>
    <div class="col-md-3 ">
      <label>Kg</label>
      <p>{{skuData?.sku?.sku_packing_sections?.weight_per_unit?.kg}}</p>
    </div></div>
    </mat-card>

    <mat-card class="p-2 mt-3">
      <div class="row">
    <h4 class="mt-3">SKU Attributes</h4>
    <div class="col-md-12">
      <table class="table">
        <thead>
          <tr>
            <th>
              Key
            </th>
            <th>
              Value
            </th>
          </tr>
        </thead>
        <tbody>
          @for (item of skuData?.sku?.sku_attributes; track $index) {

          <tr>
            <td>
              {{item.key}}
            </td>
            <td>
              {{item.value}}
            </td>
          </tr>}
        </tbody>
      </table>
    </div></div>
    </mat-card>
    <mat-card class="p-2 mt-3">
      <div class="row">
    <h4 class="mt-3">Selected Variant Values</h4>
    <div class="col-md-12">
      <table class="table">
        <thead>
          <tr>
            <th>
              Variant Types Name
            </th>
            <th>
              Variant Values Name
            </th>
            <th>
             Variant Group Name
            </th>
          </tr>
        </thead>
        <tbody>
          @for (item1 of skuData?.sku?.selected_variant_values; track $index) {

          <tr>
            <td>
              {{item1.variant_types_name}}
            </td>
            <td>
              {{item1.variant_value}}
            </td>
            <td>
              {{item1.variant_group_name }}
            </td>
          </tr>}
        </tbody>
      </table>

    </div>
    </div>
    </mat-card>
    @if(skuData?.sku?.sku_images){
      <mat-card class="p-2 mt-3">
        <div class="row">
    <h4>SKU Images</h4>
    <div class="d-flex">
      @if (skuData?.sku?.sku_images?.permanent_image) {
      <div class="m-2">
        <img [src]="skuData?.sku?.sku_images?.permanent_image">
      </div>
      }

      @if (skuData?.sku?.sku_images?.optional_image1) {
      <div class="m-2">
        <img [src]="skuData?.sku?.sku_images?.optional_image1">
      </div>
      }
      @if (skuData?.sku?.sku_images?.optional_image2) {
      <div class="m-2">
        <img [src]="skuData?.sku?.sku_images?.optional_image2">
      </div>
      }
      @if (skuData?.sku?.sku_images?.optional_image3) {
      <div class="m-2">
        <img [src]="skuData?.sku?.sku_images?.optional_image3">
      </div>
      }
      @if (skuData?.sku?.sku_images?.optional_image4) {
      <div class="m-2">
        <img [src]="skuData?.sku?.sku_images?.optional_image4">
      </div>
      }

    </div>
  </div>
  </mat-card>
    }
  </div>
