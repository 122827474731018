<mat-toolbar role="heading">
  <button mat-icon-button (click)="toggleSidenav()" class="example-icon" aria-label="Example icon-button with menu icon">
    <mat-icon>menu</mat-icon>
  </button>
  @if (authService.isAdmin()) {
  <span>Admin</span>}
  @if (authService.isVendor()) {<span>Vendor</span>}
  @if (authService.isLogisticsAdmin()) {<span>Logistics</span>}

  <span class="example-spacer"></span>
  <button mat-icon-button class="example-icon favorite-icon">
    <mat-icon>search</mat-icon>
  </button>
  <div class="position-relative">
   @if (notification_count) {
    <div class="count">{{notification_count}}</div>
   }
  @if (authService.isVendor()) {
  <button mat-icon-button class="example-icon"
  [routerLink]="['/dashboard/vendor-notifications']"  >
    <mat-icon>notifications</mat-icon>
  </button> }
  @if (authService.isAdmin()) {
  <button mat-icon-button class="example-icon"
  [routerLink]="['/dashboard/admin-notifications']"  >
    <mat-icon>notifications</mat-icon>
  </button>
}</div>
 <button mat-icon-button [matMenuTriggerFor]="menu">
  <mat-icon > person_pin</mat-icon>
</button>

    <mat-menu #menu="matMenu">
      <span class="p-2">  {{data?.email_id}}</span>
      <hr>
      <button (click)="logout()" mat-menu-item> <mat-icon > exit_to_app</mat-icon>Sign Out</button>
    </mat-menu>
</mat-toolbar>
