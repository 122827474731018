import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { BrandService } from '../services/brand.service';
import { lastValueFrom, takeUntil } from 'rxjs';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { AuthService } from '../../../services/auth.service';
import { ProductService } from '../../product/services/product.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from '../../../services/common.service';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { PageId } from '../../../constants/enums';

@Component({
  selector: 'app-brand-add-edit',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    RouterLink,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule
  ],
  templateUrl: './brand-add-edit.component.html',
  styleUrl: './brand-add-edit.component.scss',
})
export class BrandAddEditComponent extends BaseListComponent {
  brandForm: any;
  filename: any;
  previewImage: any;
  requestImageBody: any;
  id: any;
  companyList: any = []
  comment: any = ""
  constructor(
    public fb: FormBuilder,
    route: ActivatedRoute,
    router: Router,
    public brandService: BrandService,
    public location: Location,
    auth: AuthService,
    public productService: ProductService,
    public commonService: CommonService
  ) {
    super(auth, router, route, PageId.bms_brand);
    this.brandForm = this.fb.group({
      name: ['', Validators.required],
      display_name: ['', Validators.required],
      image_url: ['', Validators.required],
      companies_id: ['', Validators.required]
    });
  }
  override async ngOnInit() {
    await super.ngOnInit();
    this.getAllCompanies()
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.id = params['id'];
      if (this.id) {
        this.patchValue();
      }
    });
  }
  async patchValue() {
    this.commonService.showLoder()
    if (this.hasBMSAdmin) {
      let res: any = (await this.brandService.getSingle(this.id) as any).data[0].attributes
      this.brandForm.patchValue(res);
      this.previewImage = res.image_url;
    }
    if (this.hasSellerAdmin) {
      this.productService.getSingleApprovalDoc(this.id).pipe(takeUntil(this.unsubscribe$)).subscribe((docData: any) => {
        let res: any = docData.payload
        this.brandForm.patchValue(res);
        this.previewImage = res.image_url;
        this.comment = docData?.comment
      },
        (error: any) => {
          this.commonService.showToaster("error", error.message)
          this.commonService.hideLoder()
        })

    }
    this.commonService.hideLoder()
  }
  async submit() {
    this.commonService.showLoder()
    try {
      if (this.requestImageBody) {
        let res: any = await lastValueFrom(
          this.brandService.upload(this.requestImageBody)
        );
        this.brandForm.patchValue({
          image_url: res.data.url
        });
        this.commonService.hideLoder()
      }
      try {
        if (this.id) {
          if (this.hasBMSAdmin) {
            await this.brandService.update(this.id, this.brandForm.value);
            this.commonService.showToaster("success", 'Updated Successfully')
            this.router.navigateByUrl('/dashboard/brand');
          }
          if (this.hasSellerAdmin) {
            this.productService.reSubmitRequestForApproval(this.id, this.brandForm.value)
            this.commonService.showToaster("success", 'Your request is submitted for approval')
            this.router.navigateByUrl('/dashboard/my-requests');
          }
        } else {
          if (this.hasBMSAdmin) {
            await this.brandService.add(this.brandForm.value);
            this.commonService.showToaster("success", 'Added Successfully')
            this.router.navigateByUrl('/dashboard/brand');
          } if (this.hasSellerAdmin) {
            this.productService.addToPendingApproval("add-brand", this.brandForm.value, "pending-approval")
            this.commonService.showToaster("success", 'Your request is submitted for approval')
            this.router.navigateByUrl('/dashboard/my-requests');
          }
        }
        this.brandForm.reset();
        this.commonService.hideLoder()

      } catch (err: any) {
        console.log(err);
        this.commonService.showToaster("error", err.error.message)
        this.commonService.hideLoder()
      }
    } catch (err: any) {
      console.log(err);
      this.commonService.showToaster("error", err.error.message)
      this.commonService.hideLoder()
    }
  }

  back() {
    this.location.back();
  }
  onFileChange(event: any) {
    if (event.target.files) {
      const file = event.target.files[0];
      this.filename = file.name;
      var reader = new FileReader();
      reader.onload = (event) => {
        this.previewImage = event?.target?.result;
        this.brandForm.patchValue({
          image_url: event?.target?.result,
        });
        const parts = this.previewImage.split(',');
        let base64String = parts[1];
        this.requestImageBody = {
          fileName: this.filename,
          fileExtension: this.getFileExtension(this.filename),
          fileData: base64String,
        };
      };
      reader.readAsDataURL(file);
    }
  }
  getFileExtension(filename: any) {
    return filename.split('.').pop();
  }

  getAllCompanies() {
    this.brandService.getAllCompanies().pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      this.companyList = res.data
    })
  }
  removeImage() {
    this.previewImage = null;
    this.filename = null;
    this.requestImageBody = null;
    this.brandForm.patchValue({
      image_url: null
    });
    var fileInput: any = document.getElementById('fileInput');
    fileInput.value = '';
  }
}
