  @if(hasView){
    <div class="container-fluid p-2">
      <mat-card class="p-3">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <h3 class="title">Variant Types</h3>
            </div>
            <div class="col-md-6 right-list">
              @if(hasBMSAdmin){
              <label for="file" class="custom-upload">
                Import
                 <input type="file" #fileInput (input)="clearFileInput()" (change)="upload($event)"  id="file" style="display: none;">
                 </label>
                 <button type="button"
                 mat-raised-button
                 (click)="export()"
                 color="primary"
              class="me-2 mb-2">
                  Export
                   </button>
                   <button type="button"
                   mat-raised-button
                   (click)="downloadSample()"
                   color="primary"
                   matTooltip="Sample CSV Download"
                class="me-2 mb-2">
                <mat-icon class="me-0">note_add</mat-icon>
                     </button>
                  }
              <button
              mat-raised-button
              (click)="openFilter()"
              color="primary"
              class="me-2 mb-2 filter-btn"
            ><div class="icon-dot" [ngClass]="{'active-filter': !queryForm.value.name && !queryForm.value.group_name && !queryForm.value.value_type && !queryForm.value.categories_id}"></div>
            <mat-icon class="me-0">filter_list</mat-icon>
            </button>
              @if(hasCreate){
                <button
                mat-raised-button
                [routerLink]="['/dashboard/variantType/add']"
                routerLinkActive="router-link-active"
                color="primary"
                class="mb-2"
              >
              <mat-icon>add</mat-icon>  Add
              </button>
              }
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 table-wrapper">
            <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)" class="mat-elevation-z8">
              <ng-container matColumnDef="attributes.name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                <td mat-cell *matCellDef="let element">{{ element.attributes.name }}</td>
              </ng-container>

              <ng-container matColumnDef="attributes.value_type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
                <td mat-cell *matCellDef="let element">{{ element.attributes.value_type }}</td>
              </ng-container>

              <ng-container matColumnDef="attributes.parent_categories.name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
                <td mat-cell *matCellDef="let element">
                  {{element.attributes.parent_categories.name}}
                </td>
              </ng-container>
              <ng-container matColumnDef="attributes.group_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Group Name</th>
                <td mat-cell *matCellDef="let element">
                  {{element.attributes.group_name}}
                </td>
              </ng-container>

              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>Action</th>
                <td mat-cell *matCellDef="let element">
                  <div class="d-flex ">
                  @if(hasEdit && hasBMSAdmin){
                    <button
                    mat-raised-button
                    [routerLink]="['/dashboard/variantType/edit', element.id]"
                    color="primary"
                  >
                  <mat-icon class="me-0">edit</mat-icon>
                  </button>
                  }
                  @if(hasDelete && hasBMSAdmin){
                    <button
                    mat-raised-button
                    class="ms-2"
                    (click)="delete(element.id)"
                    color="warn"
                  >
                  <mat-icon  class="me-0">delete</mat-icon>
                  </button>
                  }
                  <button
                  *ngIf="element.attributes.value_type=='dropdown'"
                  mat-raised-button
                  class="ms-2"
                  [routerLink]="['/dashboard/variantValue',element.id]"
                  color="primary"
                >
                  Values
                </button>

                 </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
            <div class="d-flex justify-content-center my-2">
              @if (loading) {
                <mat-spinner [diameter]="30"></mat-spinner>
              }
            </div>
            <div class="d-flex justify-content-center my-2">
              @if (!isDataAvailable() && !loading) {
                <p class="no-data-found-text">No record found</p>
              }
            </div>
            <mat-paginator #paginator [length]="meta?.totalCount" [pageSize]="queryForm?.value?.limit" [pageSizeOptions]="[5,10,25,100]" (page)="onPaginationChange($event)"
              aria-label="Select page">
            </mat-paginator>
          </div>
        </div>
      </mat-card>
    </div>
  }
  @else {
    <p class="text-center my-5">You dont have permission to access this</p>
  }
