<ng-container>
  <div class="col-md-12 d-flex">
    <div class="col-md-6">
      <h2 class="card-title m-3 text-violet">Add Providers</h2>
    </div>
    <div class="col-md-6">
      <mat-icon (click)="dialogRef.close()" class="float-right m-3 closeicon">close</mat-icon>
    </div>
  </div>
  <div class="col-md-12">
    <form [formGroup]="dataSource" class="m-3">
      <section id="content">
        <div class="container">
          <mat-card>
            <mat-card-content>
              <div class="row">
                <div class="col-md-12 d-flex">
                  <div class="col-md-6">
                    <mat-form-field class="full-width">
                      <mat-label>Select Provider</mat-label>
                      <mat-select formControlName="providerName">
                        @for (provider of providers; track provider) {
                        <mat-option [value]="provider.value">{{
                          provider.key
                          }}</mat-option>
                        }
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <mat-form-field class="full-width">
                      <mat-label>Settlement Days</mat-label>
                      <input matInput type="number" formControlName="settlementDays" name="settlementDays"
                        placeholder="Settlement Days" />
                    </mat-form-field>
                  </div>


                </div>
                <div class="col-md-12 d-flex">
                  <div class="col-md-6">
                    <mat-form-field class="full-width">
                      <mat-label>Charge Type</mat-label>
                      <mat-select formControlName="chargeType">
                        @for (calculationType of calculationTypes; track calculationType) {
                          <mat-option [value]="calculationType.value">{{
                            calculationType.key
                            }}</mat-option>
                          }
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <mat-form-field class="full-width">
                      <mat-label>Value</mat-label>
                      <input matInput type="number" formControlName="chargeValue" name="chargeValue"
                        placeholder="Value" />
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="col-md-2">
                    <a mat-raised-button color="primary" style="width: 30%; margin-top: 9px;" (click)="addProvider()">
                      Add
                    </a>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </section>
    </form>
  </div>
</ng-container>
