<div class="table-block">
  <form [formGroup]="dataSource">
    <section id="content">
      <div class="card">
        <div class="card-body card-padding">
          <div class="row">
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <mat-label>Vendor Id</mat-label>
                <input
                  matInput
                  type="string"
                  formControlName="id"
                  name="id"
                  placeholder="Merchand Id"
                  [readonly]="true"
                />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <mat-label>Mobile Number</mat-label>
                <input
                  matInput
                  type="number"
                  formControlName="mobileNumber"
                  name="mobileNumber"
                  placeholder="Mobile Number"
                  [maxLength]="10"
                  [readonly]="true"
                />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <mat-label>Name</mat-label>
                <input
                  matInput
                  type="string"
                  formControlName="name"
                  name="name"
                  placeholder="Name"
                  [readonly]="true"
                />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <mat-label>Business Name</mat-label>
                <input
                  matInput
                  type="string"
                  formControlName="businessName"
                  name="businessName"
                  placeholder="Business Name"
                  [readonly]="true"
                />
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <mat-form-field class="full-width">
                <mat-label>Email</mat-label>
                <input
                  matInput
                  type="string"
                  formControlName="email"
                  name="email"
                  placeholder="Email"
                  [readonly]="true"
                />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <mat-label>Business Type</mat-label>
                <input
                  matInput
                  [value]="getBusinessTypeName(dataSource.value.businessType)"
                  [readonly]="true"
                />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </section>
  </form>
</div>
