<div class="container-fluid p-2">
  <mat-card class="p-3">
    <form [formGroup]="form">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 d-flex mb-3">
          <div class="me-3">
            <mat-icon role="button" [routerLink]="['/dashboard/pocreation/']">keyboard_backspace</mat-icon>
          </div>
          <h3 class="title">Create Purchase Order</h3>
        </div>
      </div>
      <!-- <form [formGroup]="form"> -->
        <div class="row p-3">
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <mat-label>Invoice Reference Number</mat-label>
              <input matInput formControlName="invoiceRfNo"  />
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <mat-label>Created Date</mat-label>
              <input matInput formControlName="createdDate" readonly="true" />
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <mat-label>Supplier Name</mat-label>
              <input matInput type="string" formControlName="supplierName" name="supplierName"
                placeholder="Supplier Name" readonly="true" />
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <mat-label>Supplier GSTN</mat-label>
              <input matInput formControlName="supplierGstn" readonly="true" />
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <mat-label>TDS Percent</mat-label>
              <input matInput formControlName="tdsPercent"  />
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <mat-label>TDS Value</mat-label>
              <input matInput formControlName="tdsValue" readonly="true" />
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <mat-label>Status</mat-label>
              <!-- <input matInput type="string" formControlName="status" name="status" placeholder="Status"  readonly="true"/> -->
              <mat-select formControlName="status">
                <mat-option *ngFor="let status of purchaseOrderStatus" [value]="status">
                  {{ getStatusName(status) }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <mat-label>Product Tax Type</mat-label>
              <mat-select formControlName="productTaxType" name="productTaxType" placeholder="Product Tax Type">
                <mat-option *ngFor="let option of taxes" [value]="option.value">
                  {{ getTaxName(option.value) }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      <!-- </form>  -->
    </div>
    <div class="row p-3">
      <div class="col-md-6">
        <h3 class="title">Items</h3>
      </div>
      <div class="col-md-6 right-list">
        <button mat-raised-button type="button" class="mat-small" (click)="navigateToAddProduct()" color="primary">
          Add Product
        </button>
      </div>
    </div>
    <div class="row p-3" >
      <div class="col-md-12 table-wrapper" >
        <table mat-table [dataSource]="dataSource1">
          <ng-container matColumnDef="product">
            <th mat-header-cell *matHeaderCellDef>SKU Name</th>
            <td mat-cell *matCellDef="let element">{{ element.sku_name }}</td>
          </ng-container>
          <ng-container matColumnDef="uom">
            <th mat-header-cell *matHeaderCellDef>UOM</th>
            <td mat-cell *matCellDef="let element" >
              {{ getUomType(element.uom_requested_qty) }}
            </td>
          </ng-container>
          <ng-container matColumnDef="mrp">
            <th mat-header-cell *matHeaderCellDef>MRP</th>
            <td mat-cell *matCellDef="let element">{{ element.mrp }}</td>
          </ng-container>

          <ng-container matColumnDef="tax">
            <th mat-header-cell *matHeaderCellDef>Tax</th>
            <td mat-cell *matCellDef="let element">
              {{ getTaxName(element.tax_type) }}
            </td>
          </ng-container>
          <ng-container matColumnDef="perpccost">
            <th mat-header-cell *matHeaderCellDef>PerPc Cost</th>
            <td mat-cell *matCellDef="let element">{{ element.rate_per_pc }}</td>
          </ng-container>
          <ng-container matColumnDef="netpayable">
            <th mat-header-cell *matHeaderCellDef>Net Payable</th>
            <td mat-cell *matCellDef="let element">{{ element.net_payable }}</td>
          </ng-container>
          <ng-container matColumnDef="requestedQty">
            <th mat-header-cell *matHeaderCellDef>Requested Qty</th>
            <td mat-cell *matCellDef="let element">{{ element.requested_in_pcs }}</td>
          </ng-container>
          <ng-container matColumnDef="actualQty">
            <th mat-header-cell *matHeaderCellDef>Actual Qty</th>
            <td mat-cell *matCellDef="let element">
              {{ element.actual_in_pcs }}
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let element">
              <div class="d-flex">
                <button color="primary" mat-raised-button (click)="productEditComponent(element.id)">
                  <mat-icon class="me-0">edit</mat-icon>
                </button>
                <button color="primary" mat-raised-button (click)="deleteSku(element.id)" class ="button">
                  <mat-icon class="me-0">delete</mat-icon>
                </button>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>

    <div class="row p-3">
      <div class="col-md-6">
        <h3 class="title">Expenses</h3>
      </div>
      <div class="col-md-6 right-list">
        <button mat-raised-button type="button" class="mat-small" (click)="navigateToAddExpenses()" color="primary">
          Add Expenses
        </button>
      </div>
    </div>

    <div class="row p-3">
      <div class="col-md-12 table-wrapper">
        <table mat-table mat-table [dataSource]="dataSource2">
          <!-- Order No Column -->
          <ng-container matColumnDef="expensestype">
            <th mat-header-cell *matHeaderCellDef>Expenses Type</th>
            <td mat-cell *matCellDef="let element">
                {{ getExpenseType(element.expense_type) }}
              </td>
          </ng-container>
          <ng-container matColumnDef="totalvalue">
            <th mat-header-cell *matHeaderCellDef>Total Value</th>
            <td mat-cell *matCellDef="let element">{{ element.total_value }}</td>
          </ng-container>
          <ng-container matColumnDef="supplier">
            <th mat-header-cell *matHeaderCellDef>Supplier</th>
            <td mat-cell *matCellDef="let element">{{ element.supplier_id }}</td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let element">
              <button mat-raised-button color="primary" aria-label="Edit" (click)="expensesEditComponent(element.id)">
                <mat-icon>edit</mat-icon>
              </button>
              <button color="primary" mat-raised-button (click)="deleteExpense(element.id)" class ="button">
                <mat-icon class="me-0">delete</mat-icon>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumnss"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnss"></tr>
        </table>
      </div>
    </div>

    <div class="row p-3">
      <div class="col-md-6">
        <h3 class="title">Tax</h3>
      </div>
      <div class="col-md-6 right-list">
        <button mat-raised-button type="button" class="mat-small" color="primary">
          Calculate LP
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 table-wrapper">
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="gstTaxSlab">
            <th mat-header-cell *matHeaderCellDef>GST Tax Slab</th>
            <td mat-cell *matCellDef="let element">{{ element.gstTaxSlab }}</td>
          </ng-container>

          <!-- <ng-container matColumnDef="buyinguom">
            <th mat-header-cell *matHeaderCellDef>Taxable Slab</th>
            <td mat-cell *matCellDef="let element">{{ element.buyinguom }}</td>
          </ng-container> -->

          <ng-container matColumnDef="taxableAmount">
            <th mat-header-cell *matHeaderCellDef>Taxable Amount</th>
            <td mat-cell *matCellDef="let element">{{ element.taxableAmount }}</td>
          </ng-container>

          <ng-container matColumnDef="cgst">
            <th mat-header-cell *matHeaderCellDef>CGST</th>
            <td mat-cell *matCellDef="let element">
              {{ element.cgst }}
            </td>
          </ng-container>

          <ng-container matColumnDef="sgst">
            <th mat-header-cell *matHeaderCellDef>SGST</th>
            <td mat-cell *matCellDef="let element">{{ element.sgst }}</td>
          </ng-container>

          <ng-container matColumnDef="cess">
            <th mat-header-cell *matHeaderCellDef>Cess</th>
            <td mat-cell *matCellDef="let element">{{ element.cess }}</td>
          </ng-container>

          <ng-container matColumnDef="tcstds">
            <th mat-header-cell *matHeaderCellDef>TCS/TDS</th>
            <td mat-cell *matCellDef="let element">{{ element.tcstds }}</td>
          </ng-container>

          <ng-container matColumnDef="totalBillAmount">
            <th mat-header-cell *matHeaderCellDef class="col-md-2">
              Total bill Amount
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.totalBillAmount }}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedCreatePoColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedCreatePoColumns"></tr>
        </table>
      </div>
    </div>
    <div class="table-block" style="margin: 5px">
      <div class="mat-elevation-z8">
        <!-- <form [formGroup]="dataSource">  -->
        <section id="content">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <!-- <span class="header"> -->
                  <h2 class="card-title">Upload Bills</h2>
                  <!-- </span> -->
                </div>
                <div class="row" style="margin-bottom: 5px">
                  <div class="col-md-6">
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <mat-label>Purchase Bill</mat-label>
                    <input formControlName="purchaseBill" class="form-control"
                      (change)="onFileChange($event, 'purchase-bill')" type="file" />
                  </div>
                  <div class="col-md-6">
                    <mat-label>Other Bills</mat-label>
                    <input formControlName="otherBills" class="form-control"
                      (change)="onFileChange($event, 'other-bills')" type="file" />
                  </div>
                </div>
                <div class="col-md-12 custom-flex">
                  <div class="text-center mt-2 flex-item">
                    <button mat-raised-button color="primary" class="text-center ml" (click)="save()">
                      Save
                    </button>
                  </div>
                  <div class="text-center mt-2 flex-item">
                    <button mat-raised-button color="primary" class="text-center ml-w">
                      Request For GRN
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>
    </div>
     </form>
  </mat-card>
</div>
