import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { OrderService } from '../../service/order.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import { ReplaceCommasPipe } from '../../../../shared/pipes/currency-pipe';
import {
  OrderSearchInput,
  SalesPersonsOrdersSummary,
} from '../../../../enums/enums';
export interface DialogData {
  title: string;
  searchFields: any;
  pageNumber: number;
  pageSize: number;
  salesPersonList: any;
}
export interface SalesPersonOrdersInfo {
  salesPersonId: string;
  salesPersonName: string;
  orderCount: number;
  orderAmountSum: number;
  uniqueCustomerCount: number;
}
@Component({
  selector: 'app-order-summary-dialog',
  standalone: true,
  templateUrl: './order-summary-dialog.component.html',
  styleUrls: ['./order-summary-dialog.component.scss'],
  imports: [
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    RouterLink,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatCheckboxModule,
    ReplaceCommasPipe,
  ],
})
export class OrderSummaryDialogComponent implements OnInit, OnDestroy {
  selectedBusinessTypes: string[] = [];
  orderSearchInput: any;
  dataSource!: SalesPersonOrdersInfo[];
  pageNumber: number;
  pageSize: number;
  totalOrderAmountSum!: number;
  private ngUnsubscribe = new Subject<void>();
  displayedColumns = [
    'salesPersonName',
    'uniqueCustomerCount',
    'orderCount',
    'orderAmountSum',
  ];

  constructor(
    public dialogRef: MatDialogRef<OrderSummaryDialogComponent>,
    private fb: FormBuilder,
    private orderService: OrderService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.orderSearchInput = data.searchFields;
    this.pageNumber = data.pageNumber;
    this.pageSize = data.pageSize;
  }

  async ngOnInit() {
    const mappedInput = this.mapInputToSalesPersonsOrdersSummary(
      this.orderSearchInput
    );
    const response = await this.orderService.getSalesPersonsOrdersSummary(
      mappedInput
    );

    const data = this.mapToSalespersons();

    this.dataSource = response['orderList'].map((i: any) => ({
      ...i,
      salesPersonName: data[i.salesPersonId]?.salespersonName || '',
    }));

    this.totalOrderAmountSum = this.dataSource.reduce((total, item) => {
      const amount =
        typeof item.orderAmountSum === 'string'
          ? parseFloat(item.orderAmountSum)
          : item.orderAmountSum;
      return total + amount;
    }, 0);

    this.totalOrderAmountSum = Math.round(this.totalOrderAmountSum * 100) / 100;
  }

  async ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private mapToSalespersons() {
    return this.data.salesPersonList.reduce(
      (
        acc: {
          [key: string]: { salesPersonId: string; salespersonName: string };
        },
        cur: any
      ) => {
        acc[cur.id] = {
          salesPersonId: cur.id,
          salespersonName: cur.name,
        };
        return acc;
      },
      {}
    );
  }

  roundToTwoDecimalPlaces(amount: number): number {
    return Math.round(amount * 100) / 100;
  }

  mapInputToSalesPersonsOrdersSummary(
    input: OrderSearchInput
  ): SalesPersonsOrdersSummary {
    return {
      pageNumber: input.pageNumber,
      pageSize: 1000,
      businessType: input.businessTypeId,
      mobileNumber: input.customerMobileNumber,
      orderNumber: input.orderNumber,
      customerId: input.customerId,
      salespersonId: input.salesperson,
      orderStatusId: input.orderStatus,
      startDate: input.startDate,
      endDate: input.endDate,
    };
  }
}
