<form [formGroup]="filterForm">
  <div class="d-flex align-items-center justify-content-between" >
  <h1 mat-dialog-title>Filter </h1>
  <button mat-button (click)="close()"
    class="mx-2"  mat-button color="warn">
    <mat-icon >close</mat-icon>
  </button></div>
  <div mat-dialog-content>
   <div class="container">
    <div class="row">
      <div class="col-md-6">
        <mat-form-field class="example-full-width">
          <mat-label>Search by Name</mat-label>
          <input matInput formControlName="name" />
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <!-- <mat-form-field class="example-full-width" >
          <mat-label>Companies Name</mat-label>
          <mat-select formControlName="companies_id">
            @for (item of companyList; track $index) {
          <mat-option [value]="item.id">{{item.attributes.name}}</mat-option>
            }
          </mat-select>
        </mat-form-field> -->
        <ng-select [items]="companyList" bindValue="id" bindLabel="attributes.name" placeholder="Select Company"
        formControlName="companies_id" appendTo="body">
      </ng-select>
      </div>
      <div class="col-md-6">
       <!--  <mat-form-field class="example-full-width" >
          <mat-label>Brand Name</mat-label>
          <mat-select formControlName="brands_id">
            @for (item of brandList; track $index) {
          <mat-option [value]="item.id">{{item.attributes.name}}</mat-option>
            }
          </mat-select>
        </mat-form-field> -->
        <ng-select [items]="brandList" bindValue="id" bindLabel="attributes.name" placeholder="Select Brand"
        formControlName="brands_id"
        appendTo="body">
      </ng-select>
      </div>
      <div class="col-md-6">
        <ng-select [items]="categoryList"
        bindValue="id"
        bindLabel="attributes.name"
        placeholder="Search by category"
        formControlName="categories_id"
        appendTo="body"
     >
</ng-select>
   <!--      <mat-form-field class="example-full-width" >
          <mat-label>Categories Name</mat-label>
          <mat-select formControlName="categories_id">
            @for (item of categoryList; track $index) {
          <mat-option [value]="item.id">{{item.attributes.name}}</mat-option>
            }
          </mat-select>
        </mat-form-field> -->

      </div>
      <div class="col-md-12">
        <mat-form-field style="width: 50%;">
          <mat-label>Status</mat-label>
          <mat-select formControlName="status" (selectionChange)="getStatus()" multiple>
            <mat-option *ngFor="let skuStatus of statuses" [value]="skuStatus.value">
              {{ skuStatus.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
   </div>
    </div>
  <div mat-dialog-actions class="d-flex justify-content-end  ">
    <button mat-button (click)="reset()"
    class="mx-2"  mat-raised-button color="warn">Reset</button>
    <button mat-button  cdkFocusInitial [disabled]="filterForm.invalid"
    (click)="submit()"
     mat-raised-button color="primary">Apply</button>
  </div>
  </form>
