<ng-container>
    <div class="row">
        <div class="col-md-6">
            <h2 class="card-title m-3 text-violet">Upload Excel File</h2>
        </div>
        <div class="col-md-6 d-flex justify-content-end">
            <mat-icon (click)="closeDialog()" class=" m-3 closeicon">close</mat-icon>
        </div>
    </div>
    <div class="row">
        <form class="m-3">
            <section id="content">
                <div class="container">
                    <mat-card>
                        <mat-card-content>
                            <div class="row">
                                <div class="col-md-12">
                                    <input type="file" accept=".xlsx, .xls" (change)="onSelectExcelFile($event)"
                                        class="m-10 choosefile" id="excelInput" />
                                    <label *ngIf="selectedFileName">{{ selectedFileName }}</label>
                                    <button mat-raised-button color="primary" (click)="uploadExcel()"
                                        [disabled]="!selectedFileName">Upload Excel</button>
                                    <label class="errorMessage" *ngIf="isExcelFileInvalid">Invalid Excel file format.
                                        Please upload a valid Excel file.</label>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </section>
        </form>
    </div>
</ng-container>