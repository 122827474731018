
<ng-container >
  <div class="row m-3">
    <div class="col-md-6">
          <h2 class="card-title m-3 text-violet">import</h2>
    </div>
      <div class="col-md-6 text-end">
          <mat-icon (click)="dialogRef.close()" class = m-3 >close</mat-icon>
      </div>
  </div>

  <div class="m-3">
      <section id="content">
          <div class="container">
              <div class="card">
                  <div class="card-body card-padding">
                      <input type="file"
                      class="p-t-10"
                      id="file-upload"
                      (change)="onFileSelect($event)"
                      accept=".xlsx, .xls, .csv" />

                          <div class="row ">
                              <div class="col-md-12 text-center">
                                  <button mat-raised-button class="m-1" color="primary" (click)="saveMargins()"[disabled]="isButtonDisabled">Save</button>
                                  <button mat-raised-button class="m-1" color="primary" (click)="dialogRef.close()">Cancel</button>
                              </div>

                          </div>
                  </div>
              </div>
          </div>
      </section>
  </div>
  </ng-container>

