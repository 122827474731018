<div>
  <div class="row m-0">
    <div class="col p-0">
      <h2 class="card-title m-3 text-violet">{{ data.title }}</h2>
      <h3 class="card-title m-3 text-violet">Total Order Amount : {{totalOrderAmountSum | replaceCommas}}</h3>
    </div>
    <div class="col text-right p-0">
      <mat-icon (click)="dialogRef.close()" class="m-3 closeicon">
        close
      </mat-icon>
    </div>
  </div>
  <div class="content">
    <mat-card class="p-0">
      <mat-card-content class="p-0">
        <mat-sidenav-container fxFlex="0 1 auto" style="height: 100%;">
          <div class="table-container" style="overflow: auto; height: 100%;">
            <mat-table [dataSource]="dataSource" matSort style="width: 100%;">
              <ng-container matColumnDef="salesPersonName">
                <mat-header-cell *matHeaderCellDef> Salesperson Name </mat-header-cell>
                <mat-cell *matCellDef="let row" data-label="salesPersonName">
                  {{row.salesPersonName}}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="uniqueCustomerCount">
                <mat-header-cell *matHeaderCellDef>Unique Customers
                </mat-header-cell>
                <mat-cell *matCellDef="let row" data-label="uniqueCustomerCount">
                  {{row.uniqueCustomerCount}}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="orderCount">
                <mat-header-cell *matHeaderCellDef>Orders Count
                </mat-header-cell>
                <mat-cell *matCellDef="let row" data-label="orderCount">
                  {{row.orderCount}}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="orderAmountSum">
                <mat-header-cell *matHeaderCellDef>Total Amount
                </mat-header-cell>
                <mat-cell *matCellDef="let row" data-label="orderAmountSum">
                  {{roundToTwoDecimalPlaces(row.orderAmountSum || 0)}}
                </mat-cell>
              </ng-container>
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

            </mat-table>
          </div>
        </mat-sidenav-container>
      </mat-card-content>
    </mat-card>
  </div>
</div>
