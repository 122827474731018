@if(hasEdit){
<ng-container>
  <div class="heading-block">
    <div class="row">
      <div class="col-md-6">
        <mat-icon class="backicon" [routerLink]="['/dashboard/targets/targets-list']">arrow_back</mat-icon>
        <span class="header">
          <h2 class="card-title m-3">Update Targets</h2>
        </span>
      </div>
      <div class="col-md-6 text-right"></div>
    </div>
  </div>

  <div class="table-block">
    <form [formGroup]="dataSource">
      <section id="content">
        <div class="card">
          <div class="card-body card-padding">
            <div class="row">
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Buying Target</mat-label>
                  <input matInput type="number" formControlName="buyingTarget" name="buyingTarget"
                    placeholder="Buying Target" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Non Buying Target</mat-label>
                  <input matInput type="number" formControlName="nonBuyingTarget" name="nonBuyingTarget"
                    placeholder="Non Buying Target" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Day Revenue Target</mat-label>
                  <input matInput type="number" formControlName="dayRevenueTarget" name="dayRevenueTarget"
                    placeholder="Day Revenue Target" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Task Revenue Target Percentage</mat-label>
                  <input matInput type="number" formControlName="taskRevenueTargetPercentage"
                    name="taskRevenueTargetPercentage" placeholder="Task Revenue Target Percentage" />
                </mat-form-field>
              </div>

            </div>
            <div class="row">
              <div class="buttonSection">
                @if(hasEdit){
                <button mat-raised-button color="primary" class="text-center ml" (click)="updateTargets()">
                  Update
                </button>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  </div>
</ng-container>
} @else {
  <ng-container>
    <p class="text-center">
      You dont have permission , Please contact administrator
    </p>
  </ng-container>
  }
