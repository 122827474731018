import { Component, Inject, Optional, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { MatPaginator } from '@angular/material/paginator';
import { PageId, Permissions } from '../../constants/enums';
import { flatten } from '@turf/turf';
import { MerchantBusinessType } from '../../enums/enums';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs';

@Component({
  template: '', // Empty template since this is a base component
})
export class BaseListComponent {
  protected unsubscribe$ = new Subject<void>();
  pageId: string = '';
  // Shared properties from BaseComponent
  hasEdit: boolean = false;
  hasCreate: boolean = false;
  hasView: boolean = false;
  hasDelete: boolean = false;
  hasExport: boolean = false;
  hasImport: boolean = false;
  hasPricing: boolean = false;
  hasInventory: boolean = false;
  hasProcurement: boolean = false;
  hasBMSAdmin: boolean = false;
  enablePricingUpdate: boolean = false;
  enableStockUpdate: boolean = false;
  hasLogisticsAdmin: boolean = false;
  hasSellerAdmin: boolean = false;
  hasStock: boolean = false;
  hasAccounting: boolean = false;
  BusinessType: MerchantBusinessType = MerchantBusinessType.None;

  userId: string = ''; // Adjust the type if it's not a string
  vendorId: string = ''; // Adjust the type if it's not a string
  name: string = ''; // Adjust the type if it's not a string
  originalFilterFormValues: any;
  vendor: any;
  // Additional properties for list components
  pageSizeOptions: number[] = [25, 50, 100, 250];
  pageNumber: number = 1;
  pageSize: number = 25;

  constructor(
    public auth: AuthService,
    public router: Router,
    public route: ActivatedRoute,
    @Optional() @Inject('rawPageId') public rawPageId?: string
  ) {
    this.pageId = rawPageId || '';
  }

  ngOnInit(pageId?: string) {
    this.fillPermissions();
  }

  // Method to handle page change event
  async onPageChange(event: any) {
    const { pageIndex, pageSize } = event;
    this.pageNumber = pageIndex + 1;
    this.pageSize = pageSize;
  }

  private fillPermissions() {
    if (!this.pageId && !this.auth.permission$.value) return;
    this.hasView = this.auth.hasPermission(Permissions.View, this.pageId);
    this.hasCreate = this.auth.hasPermission(Permissions.Create, this.pageId);
    this.hasEdit = this.auth.hasPermission(Permissions.Edit, this.pageId);
    this.hasDelete = this.auth.hasPermission(Permissions.Delete, this.pageId);
    this.hasExport = this.auth.hasPermission(Permissions.Export, this.pageId);
    this.hasImport = this.auth.hasPermission(Permissions.Import, this.pageId);

    this.hasProcurement = this.auth.hasRole('Seller Procurement');
    this.hasBMSAdmin = this.auth.hasRole('BMS Admin');
    this.hasPricing = this.auth.hasRole('Seller Pricing');
    this.hasInventory = this.auth.hasRole('Seller Inventory');
    this.hasLogisticsAdmin = this.auth.hasRole('Logistics Admin');
    this.hasSellerAdmin = this.auth.hasRole('Seller Admin');
    this.hasAccounting = this.auth.hasRole('Seller Accounting');

    this.userId = this.auth.UserId;
    this.vendorId = this.auth.VendorId;
    this.vendor = this.auth.getVendor();
    this.BusinessType = this.vendor?.business_type;
    console.log('base - business type', this.BusinessType);
    this.resolvePricingStockPermissions();
  }

  private resolvePricingStockPermissions() {
    if (
      this.BusinessType === MerchantBusinessType.JitVendor ||
      this.BusinessType === MerchantBusinessType.Distributor ||
      this.BusinessType === MerchantBusinessType.Wholesaler ||
      this.BusinessType === MerchantBusinessType.Manufacturer
    ) {
      this.enablePricingUpdate = true;
      this.enableStockUpdate = true;
      return;
    }
    if (
      this.BusinessType === MerchantBusinessType.Inventory &&
      this.hasPricing
    ) {
      this.enablePricingUpdate = true;
    }
    if (
      this.BusinessType === MerchantBusinessType.Inventory &&
      this.hasInventory
    ) {
      this.enableStockUpdate = true;
    }
  }

  //not useful
  private resolvePermissions() {
    if (this.hasSellerAdmin) {
      if (
        this.BusinessType === MerchantBusinessType.JitVendor ||
        this.BusinessType === MerchantBusinessType.Distributor ||
        this.BusinessType === MerchantBusinessType.Wholesaler ||
        this.BusinessType === MerchantBusinessType.Manufacturer
      ) {
        this.hasPricing = true;
        this.hasStock = true;
      } else if (this.BusinessType === MerchantBusinessType.Inventory) {
        if (this.auth.hasRole('Seller Pricing')) {
          this.hasPricing = true;
        } else if (this.hasInventory) {
          this.hasStock = true;
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
