<div mat-dialog-content>
  <div class="panel panel-default" style="width: 100%">
    <div class="panel-body">
      <div class="card cardcss">

        <div class="card-body scroll-55-rem">

          <div class="row">
            <div class="col-md-6">
              <h2 class="card-title m-3 text-violet">Customers</h2>
            </div>
            <div class="col-md-6 text-right">
              <mat-icon (click)="cancelCustomers()" class="float-right m-3 closeicon closeicon">
                close
              </mat-icon>
            </div>
          </div>


          <div class="row mat-elevation-z8 table-container p-2">

            <div class="col-md-8">
              <dl class="dl-horizontal">
                <dt>Upload Excel</dt>
                <dd>
                  <input type="file" class="p-t-10" id="file-upload" (change)="onFileSelect($event)"
                    accept=".xlsx, .xls, .csv" />
                </dd>
              </dl>
            </div>

          </div>
          <table class="table my-3 customertable" *ngIf="customerEntities.length > 0">
            <thead>
              <tr>
                <th class="heading">Customer ID</th>
                <th class="heading">Business Name</th>
                <th class="heading">Mobile Number</th>
              </tr>
            </thead>
            <tbody>
              <tr class="clickable-row" *ngFor="let customer of customersFromExcel">
                <td>{{ customer.id }}</td>
                <td>{{ customer.businessName }}</td>
                <td>{{ customer.mobileNumber }}</td>
              </tr>
            </tbody>
          </table>
          <div class="row" *ngIf="customerEntities.length > 0">
            <div class="col-md-12">
              <div class="d-flex  justify-content-center">
                <button mat-raised-button color="primary" id="sa-title" (click)="setCustomers()"> Upload</button>
                <button mat-raised-button color="warn" id="sa-title" (click)="cancelCustomers()"
                  style="margin-left: 10px;">Cancel</button>
              </div>

              <!--   <button
                class="btn btn-info waves-effect waves-button waves-float"
                id="sa-title"
                (click)="setCustomers()"
              >
                OK
              </button>
              <button
                class="btn btn-default waves-effect waves-button waves-float"
                id="sa-title"
                (click)="cancelCustomers()"
              >
                Cancel
              </button> -->
            </div>
          </div>
          <br />
        </div>
      </div>
    </div>
  </div>
</div>