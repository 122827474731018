import { Injectable } from '@angular/core';
import { ShopSettingEntity, ShopSettingListSearch, VendorConfigEntity, createShopSettingResponse } from '../models/shopsettings.model';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShopSettingsService {
  baseServiceUrl: string = `${environment.coreServiceUrl}`;
  cacheServiceApiUrl: string = `${environment.cacheServiceApiUrl}`;
  fileServicesApiUrl: string = `${environment.fileUploadBaseUrl}`;

  constructor(
    private readonly http: HttpClient) {
  }


  async createShopSetting(data: VendorConfigEntity) {
    let response = await lastValueFrom(this.http.post<any>(`${this.baseServiceUrl}/api/vendor_settings/create_vendor_config`, data));
    return response.res;
  }
  // async getShopSettings(body: ShopSettingListSearch): Promise<any> {
  //   const res = await lastValueFrom(this.http.post<any>(`${this.baseServiceUrl}/api/shopsetting/get_by_filters`, body));

  //   if (res && res.data) {
  //     return {
  //       shopsettingList: res.data.rows,
  //       totalShopSettings: res.data.count
  //     }
  //   }

  // }

  async getVendorSettingByVendorId(vendorId: string): Promise<any> {
    const res = await lastValueFrom(this.http.get<any>(`${this.baseServiceUrl}/api/vendor_settings/get_vendor_config/${vendorId}`));
    return res.data;
  }
  async updateShopSetting(shopsettingEntity: ShopSettingEntity, shopsettingid: string) {
    let { id, ...shopsettingUpdatedEntity } = shopsettingEntity;
    let response = await lastValueFrom(this.http.post<{ res: createShopSettingResponse }>(
      `${this.baseServiceUrl}/api/shopsetting/update/${shopsettingid}`,
      shopsettingUpdatedEntity
    ));
    return response.res
  }

  // async deleteShopSetting(id: string) {
  //   return await lastValueFrom(this.http.delete(`${this.baseServiceUrl}/api/shopsetting/delete/${id}`));
  // }

  async createVendorSettingInCache(data: VendorConfigEntity) {
    let response = await lastValueFrom(this.http.put<any>(`${this.cacheServiceApiUrl}/api/vendor_settings/update/vendor_config/${data.vendorId}`, data.vendorConfig));
    return response.res
  }
  uploadVendorShopImage(body: any) {
    return this.http.put(`${environment.fileUploadBaseUrl}/documents/vendorImage`, body);
  }
}
