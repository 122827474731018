@if(hasView){
<div class="container-fluid p-2">
  <mat-card class="p-3">
    <form [formGroup]="filterForm">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <!-- <div class="col-sm-6">
                <mat-form-field class="full-width">
                  <mat-label>Areas</mat-label>
                  <mat-select formControlName="areas">
                    <mat-option *ngFor="let area of areaList" [value]="area">
                      {{ area }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div> -->
              <div class="col-sm-6">
                <mat-form-field class="full-width">
                  <mat-label>Status</mat-label>
                  <mat-select formControlName="orderStatus" multiple>
                    <mat-option
                      *ngFor="let orderStatus of orderStatusList"
                      [value]="orderStatus.value"
                    >
                      {{ orderStatus.key }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-sm-6">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    formControlName="startDate"
                    placeholder="Start Date"
                    [matDatepicker]="appDatepickerStart"
                    [readonly]="true"
                    [max]="today"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="appDatepickerStart"
                  ></mat-datepicker-toggle>
                </mat-form-field>
                <mat-datepicker #appDatepickerStart></mat-datepicker>
              </div>
              <div class="col-sm-6">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    formControlName="endDate"
                    placeholder="End Date"
                    [matDatepicker]="appDatepickerEnd"
                    [readonly]="true"
                    [max]="today"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="appDatepickerEnd"
                  ></mat-datepicker-toggle>
                </mat-form-field>
                <mat-datepicker #appDatepickerEnd></mat-datepicker>
              </div>
              <div class="col-sm-6 searchbtns d-flex">
                <div class="col-sm-6 text-left mt-2">
                  <button
                    mat-raised-button
                    color="primary"
                    class="text-center ml"
                    [disabled]="isDisableFilter"
                    (click)="searchOrders()"
                  >
                    Search
                  </button>
                </div>
                <div class="col-sm-6 text-right mt-2 clearfiltrtbtn">
                  <button
                    mat-raised-button
                    color="primary"
                    class="text-center ml-w"
                    [disabled]="isDisableFilter"
                    (click)="clearFilters()"
                  >
                    Clear Filters
                  </button>
                </div>
              </div>
            </div>
          </div>
          <mat-divider [vertical]="true" class="divider"></mat-divider>
          <div class="col-md-5 mx-auto">
            <div class="row d-flex align-content-start flex-wrap">
              @if(hasExport &&
              this.vendor?.admin_config?.fulfillment?.fulfillmentBy != 1){
              <div class="col-md-4 mt-2">
                <button
                  mat-raised-button
                  color="primary"
                  class="topButtonSectionbtn btn-width"
                  [matMenuTriggerFor]="statusOptionsMenu"
                >
                  Action
                  <mat-icon>arrow_drop_down</mat-icon>
                </button>
                <mat-menu #statusOptionsMenu="matMenu" class="export-dropdown">
                  <button
                    class="dropdown-list"
                    mat-menu-item
                    *ngFor="let option of statusOptions"
                    (click)="onDownloadOptionClick(option)"
                  >
                    {{ option }}
                  </button>
                </mat-menu>
              </div>
              <div class="col-md-4 mt-2">
                <button
                  mat-raised-button
                  color="primary"
                  class="topButtonSectionbtn btn-width"
                  [matMenuTriggerFor]="generateOptionsMenu"
                >
                  Generate
                  <mat-icon>arrow_drop_down</mat-icon>
                </button>
                <mat-menu
                  #generateOptionsMenu="matMenu"
                  class="export-dropdown"
                >
                  <button
                    class="dropdown-list"
                    mat-menu-item
                    *ngFor="let option of generateOptions"
                    (click)="onDownloadOptionClick(option)"
                  >
                    {{ option }}
                  </button>
                </mat-menu>
              </div>
              }
          </div>
            <div class="col-md-4 mt-2">
              <button
                mat-raised-button
                color="primary"
                class="topButtonSectionbtn btn-width"
                [matMenuTriggerFor]="downloadOptionsMenu"
              >
                Download
                <mat-icon>arrow_drop_down</mat-icon>
              </button>
              <mat-menu #downloadOptionsMenu="matMenu" class="export-dropdown">
                <button
                  class="dropdown-list"
                  mat-menu-item
                  *ngFor="let option of downloadOptions"
                  (click)="onDownloadOptionClick(option)"
                >
                  {{ option }}
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>
    </form>
  </mat-card>
  <mat-card class="p-3 mt-1">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">
          <span><b>Revenue</b></span> :
          <span>{{ revenue | replaceCommas }}</span>
        </div>
        <div class="col-sm-6">
          <span><b>Weight(In Kg)</b></span> :
          <span>{{ totalWeight | number }}</span>
        </div>
      </div>
    </div>
  </mat-card>
</div>

<div class="col-md-12 mt-3">
  <mat-tab-group>
    <mat-tab label="Order View">
      <app-order-list-view
        (summaryEvent)="setSummary($event)"
      ></app-order-list-view>
    </mat-tab>
    <mat-tab label="Product View">
      <app-order-product-view
        (summaryEvent)="setSummary($event)"
      ></app-order-product-view>
    </mat-tab>
  </mat-tab-group>
</div>
} @else {
<ng-container>
  <p class="text-center">
    {{ hasView }}
    You dont have permission , Please contact administrator
  </p>
</ng-container>
}
