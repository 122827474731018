import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule, FormsModule, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import imageCompression from 'browser-image-compression';
import Swal from 'sweetalert2';
import { SupplierStatus, states, SwalMessageTypes } from '../../../../../enums/enums';
import { AuthService } from '../../../../../services/auth.service';
import { AppLoaderService } from '../../../../../shared/app-loader/app-loader.service';
import { validateNegativeValues, hasSpecialCharacters, validateGST } from '../../../../../utlity/utility';
import { State, ImageDialog } from '../../../../supplier/models/logisticsupplier-model';
import { CustomerEntity, BusinessType, CustomerStatus, CustomerForm } from '../../../models/customers.model';
import { CustomersService } from '../../../services/customers.service';
import { UploadImageDialogComponent } from '../../../../supplier/components/upload-image-dialog/upload-image-dialog.component';
import { ViewImageDialogComponent } from '../view-image-dialog/view-image-dialog.component';
import { BaseListComponent } from '../../../../../shared/core/base.list.component';
import { takeUntil } from 'rxjs';
import { PageId } from '../../../../../constants/enums';

@Component({
  selector: 'app-profile-information',
  standalone: true,
  imports: [MatCardModule, MatButtonModule, MatTableModule, RouterLink, MatInputModule, MatFormFieldModule, ReactiveFormsModule,
    MatSelectModule, MatIconModule, CommonModule, FormsModule, MatDatepickerModule, MatRadioModule, MatNativeDateModule, MatPaginatorModule, MatSidenavModule],
  templateUrl: './profile-information.component.html',
  styleUrl: './profile-information.component.scss'
})
export class ProfileInformationComponent extends BaseListComponent {
  supplierStatus = Object.values(SupplierStatus).filter(value => typeof value === 'number');
  dataSource: any;
  id: string = "";
  states: State[] = states;
  uploadedFiles: any = {};
  selectedImages: Array<any> = [];
  imageBusinessType: string = "";
  customer!: CustomerEntity;
  modalTitle: string = "";
  viewImgUrl: string = "";
  shopUrl: string = "";
  gstCertificateUrl: string = "";
  registrationDocumentUrl: string = "";
  photoIdUrl: string = "";
  businessTypeNames: { [key: number]: string } = {
    [BusinessType.Restaurant]: 'Restaurant',
    [BusinessType.Kirana]: 'Kirana',
    [BusinessType.Caterers]: 'Caterers',
    [BusinessType.TiffinCenters]: 'Tiffin Centers',
    [BusinessType.PG_Hostel]: 'PG/Hostel',
    [BusinessType.Institutional]: 'Institutional',
    [BusinessType.Bakery]: 'Bakery',
    [BusinessType.SweetHouse]: 'Sweet House',
    [BusinessType.VegetableShops]: 'Vegetable shops',
    [BusinessType.Tier2Customer]: 'Tier 2 Customer',
    [BusinessType.Medicals]: 'Medicals Shops',
    [BusinessType.BulkCategory]: 'Bulk Category',
    [BusinessType.Chemist]: 'Chemist',
    [BusinessType.Cosmetic]: 'Cosmetic',
    [BusinessType.Supermarket]: 'Supermarket',
    [BusinessType.Wholesale]: 'Wholesale',
    [BusinessType.RiceTraders]: 'Rice Traders',
    [BusinessType.OilTraders]: 'Oil Traders',
    [BusinessType.Others]: 'Others'
  };

  customerStatusNames: { [key: number]: string } = {
    [CustomerStatus.Lost]: 'Lost',
    [CustomerStatus.LostNotInterested]: 'Lost-Not-Interested',
    [CustomerStatus.BusinessShutdown]: 'Business-Shutdown',
    [CustomerStatus.InternalUser]: 'Internal-User',
    [CustomerStatus.ActiveUser]: 'Active-User',
    [CustomerStatus.TemporaryShutdown]: 'Temporary-Shutdown',
    [CustomerStatus.Duplicate]: 'Duplicate',
    [CustomerStatus.DistributorSales]: 'Distributor-sales'
  };
  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    public dialog: MatDialog,

    private customersService: CustomersService,
    private loader: AppLoaderService,
  ) {
    super(auth, router, route, PageId.bms_customers);
  }

  override async ngOnInit() {
    await super.ngOnInit()
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe(async (params) => {
      this.id = params['id'];
      await this.getCustomerById();
    });
    this.editForms();
  }
  businessTypeKeys(): number[] {
    return Object.keys(this.businessTypeNames).map(Number);
  }

  getCustomerStatusNames(): { [key: number]: string } {
    return {
      [CustomerStatus.Lost]: 'Lost',
      [CustomerStatus.LostNotInterested]: 'Lost-Not-Interested',
      [CustomerStatus.BusinessShutdown]: 'Business-Shutdown',
      [CustomerStatus.InternalUser]: 'Internal-User',
      [CustomerStatus.ActiveUser]: 'Active-User',
      [CustomerStatus.TemporaryShutdown]: 'Temporary-Shutdown',
      [CustomerStatus.Duplicate]: 'Duplicate',
      [CustomerStatus.DistributorSales]: 'Distributor-sales'
    };
  }
  getCustomerStatusKeys(): number[] {
    return Object.keys(this.getCustomerStatusNames()).map(Number);
  }

  editForms() {
    this.dataSource = this.fb.group({
      customerId: [null, [Validators.required]],
      name: [null, [Validators.required]],
      businessName: [null, [Validators.required]],
      businessTagStatus: [null, [Validators.required]],
      businessTypeId: [null, [Validators.required]],
      mobileNumber: [null, [Validators.required]],
      email: [null, [Validators.required]],
      createdDate: [null, [Validators.required]],
      salespersonName: [null, [Validators.required]],
      salespersonMobileNumber: [null, [Validators.required]],
      status: [null, [Validators.required]],
      gstNumber: [null, [Validators.required]],
      shopUrl: [null],
      gstCertificateUrl: [null],
      registrationDocumentUrl: [null],
      photoIdUrl: [null],
      isActive: [null],
    });
  }

  async getCustomerById() {
    try {
      this.loader.open();
      const response = await this.customersService.getCustomerById(this.id);

      this.dataSource = this.mapToEditInput(response);
      console.log("getCustomerById.dataSource:::::", this.dataSource);
      this.dataSource.patchValue({
        status: response.status
      });

    } catch (error) {
      this.showMessage('Failed to Get customer. Please try again later.', SwalMessageTypes.Error);
      this.router.navigateByUrl('/dashboard/customers');

    } finally {
      this.loader.close();

    }
  }

  toUppercase(event: any) {
    event.target.value = event.target.value.toUpperCase();
  }
  async updateCustomer() {
    this.loader.open();

    try {
      if (this.dataSource.invalid) {
        this.showMessage('Please fill all required fields.', SwalMessageTypes.Warning);
        return
      }


      await this.validateInput();
      await this.uploadFiles();
      await this.updateCustomerById();
    } catch (error) {
      console.error('Error occurred during customer creation:', error);
      this.showMessage('Failed to create customer. Please try again later.', SwalMessageTypes.Error);
    } finally {
      this.loader.close();
    }
  }

  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  getFileExtension(filename: any) {
    return filename.split('.').pop();
  }

  async uploadFiles() {
    const documentTypes = ['shopUrl', 'gstCertificateUrl', 'registrationDocumentUrl', 'photoIdUrl'];
    const urlEndpoints = ['shopImage', 'gstCertificate', 'shopRegistrationDocument', 'photoId'];

    await Promise.all(documentTypes.map(async (documentType, index) => {
      const urlEndpoint = urlEndpoints[index];

      if (this.uploadedFiles.hasOwnProperty(documentType)) {
        const requestImageBody = this.uploadedFiles[documentType];
        await this.uploadAndSetUrl(requestImageBody, documentType, urlEndpoint);
      }
    }));
  }

  async uploadAndSetUrl(requestImageBody: any, documentType: string, urlEndpoint: string) {
    try {
      const res: any = await this.customersService.imageUpload(requestImageBody, urlEndpoint);
      switch (documentType) {
        case 'shopUrl':
          this.shopUrl = res.data.url;
          break;
        case 'gstCertificateUrl':
          this.gstCertificateUrl = res.data.url;
          break;
        case 'registrationDocumentUrl':
          this.registrationDocumentUrl = res.data.url;
          break;
        case 'photoIdUrl':
          this.photoIdUrl = res.data.url;
          break;
        default:
          console.error('Invalid document type');
          break;
      }

    } catch (error) {
      console.error('Error uploading file:', error);
    }
  }


  mapCustomer(dataSource: any): any {
    return {
      name: dataSource.name || "",
      business_name: dataSource.businessName || "",
      business_tag_status: dataSource.businessTagStatus || 0,
      business_type_id: dataSource.businessTypeId || 0,
      email: dataSource.email || "",
      shop_url: this.shopUrl || dataSource.shopUrl || "",
      gst_number: dataSource.gstNumber || "",
      gst_certificate_url: this.gstCertificateUrl || dataSource.gstCertificateUrl || "",
      registration_document_url: this.registrationDocumentUrl || dataSource.registrationDocumentUrl || "",
      photo_id_url: this.photoIdUrl || dataSource.photoIdUrl || "",
      status: dataSource.status || 0
    };
  }

  async updateCustomerById() {
    const customerEntity = this.mapCustomer(this.dataSource.value);
    if (!customerEntity.name) {
      this.showMessage('Please enter name .', SwalMessageTypes.Warning);

      return;
    }

    if (!customerEntity.business_name) {
      this.showMessage('Please enter business name .', SwalMessageTypes.Warning);

      return;
    }

    if (customerEntity.gst_number != "Un-Registered") {
      customerEntity.gst_number =
        customerEntity?.gst_number.toLocaleUpperCase();

      if (!validateGST(customerEntity?.gst_number)) {
        this.showMessage('Please enter valid GST number .', SwalMessageTypes.Warning);

        return;
      }
    }
    try {
      const response = await this.customersService.updateCustomer(customerEntity, this.id);
      if (response) {
        this.showMessage('Customer updated successfully', SwalMessageTypes.Success);
        this.router.navigateByUrl('/dashboard/customers');
      } else {
        this.showMessage('Customer not updated successfully', SwalMessageTypes.Warning);
        this.router.navigateByUrl('/dashboard/customers');
      }
    } catch (error) {
      console.error('Error occurred while creating customer:', error);
      this.showMessage('Failed to create customer. Please try again later.', SwalMessageTypes.Error);
    }
  }

  private mapToEditInput(customer: any): FormGroup {
    const entity = customer;

    return this.fb.group({
      customerId: new FormControl(entity.id || ""),
      name: new FormControl(entity.name || ""),
      email: new FormControl(entity.email || ""),
      createdDate: new FormControl(entity.createdDate || ""),
      mobileNumber: new FormControl(entity.mobileNumber || ""),
      businessName: new FormControl(entity.businessName || ""),
      businessTypeId: new FormControl(entity.businessTypeId || ""),
      businessTagStatus: new FormControl(entity.businessTagStatus || ""),
      shopUrl: new FormControl(entity.shopUrl || ""),
      gstNumber: new FormControl(entity && entity.gstNumber ? entity.gstNumber : "Un-Registered",),
      gstCertificateUrl: new FormControl(entity.gstCertificateUrl || ""),
      registrationDocumentUrl: new FormControl(entity.registrationDocumentUrl || ""),
      photoIdUrl: new FormControl(entity.photoIdUrl || ""),
      status: new FormControl(entity.status || ""),
      salespersonName: new FormControl(entity.salesPerson.name || ""),
      salespersonMobileNumber: new FormControl(entity.salesPerson.mobileNumber || ""),
      userTypeId: new FormControl(entity.userTypeId || "")

    });
  }

  async validateInput() {
  }

  openPopUp(imageUrl: string) {
    const dialogRef = this.dialog.open(UploadImageDialogComponent, {
      width: "45%",
      height: "60%",
      data: {
        imageUrl: imageUrl,
      } as ImageDialog
    });
    dialogRef.afterClosed().subscribe(async (response: any) => {
      if (response) {

      }
    });
  }

  async onFileChange(event: any, documentType: string) {
    if (!event.target.files) {
      return;
    }
    if (event.target.files) {
      const file = event.target.files[0];
      const filename = file.name;
      const reader = new FileReader();
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      reader.onload = async (event) => {
        const result = event?.target?.result;

        if (typeof result === 'string') {
          const base64String: string = await this.compressAndConvertToBase64(file, options);
          this.uploadedFiles[documentType] = {
            fileName: filename,
            fileExtension: this.getFileExtension(filename),
            fileData: base64String,
          };
        } else if (result instanceof ArrayBuffer) {
          console.error('Unsupported file type');
        }
      };

      reader.readAsDataURL(file);
    }
  }

  async compressAndConvertToBase64(file: File, options: any): Promise<string> {
    try {
      const compressedFile = await imageCompression(file, options);
      const base64String: string = await imageCompression.getDataUrlFromFile(compressedFile);
      return base64String.split(',')[1];
    } catch (error) {
      console.error('Error compressing image:', error);
      throw error;
    }
  }

  private validateNegativeValues(data: CustomerForm): boolean {
    const numericInputs = [
      data.createdDate,
      data.latitude,
      data.longitude,
    ];

    return validateNegativeValues(numericInputs);
  }

  private checkSpecialCharacters(data: any): boolean {
    const inputValues = [];
    inputValues.push(data.name);
    inputValues.push(data.createdDate);
    inputValues.push(data.source);
    inputValues.push(data.businessName);


    return inputValues.length > 0 ? hasSpecialCharacters(inputValues) : false;
  }
  private buildViewImageUrl(url: string): string {
    const timestamp = new Date().getTime();
    const query = "?t=" + timestamp;
    return url + query;
  }

  modalImage(heading: string, url: string) {
    this.modalTitle = heading;
    this.viewImgUrl = this.buildViewImageUrl(url);
    const dialogRef = this.dialog.open(ViewImageDialogComponent, {

      data: { modalTitle: this.modalTitle, viewImgUrl: this.viewImgUrl },
    });
  }
  addmodalimage(fileUploadType: number) {
    this.imageBusinessType = this.mapUploadFileType(fileUploadType);
    const dialogRef = this.dialog.open(UploadImageDialogComponent, {

      data: { imageBusinessType: this.imageBusinessType },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.selectedImages) {
        this.selectedImages = result?.selectedImages
      }
    });

  }

  mapUploadFileType = (id: number | string) => {
    if (typeof id === 'string') {

      return {
        gst_certificate: 'gstCertificateUrl',
        shop_image: 'shopUrl',
        reg_doc: 'registrationDocumentUrl',
        photo_id: 'photoIdUrl'
      }[id] || ''
    }

    return {
      1: 'gst_certificate',
      2: 'shop_image',
      3: 'reg_doc',
      4: 'photo_id'
    }[id] || ''
  }
  editCustomer() {
    this.router.navigateByUrl(`/dashboard/customers/customers-details`)
  }

}


