import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { AuthService } from '../../../services/auth.service';
import { RolesService } from '../../../services/roles.service';
import { AppLoaderService } from '../../../shared/app-loader/app-loader.service';
import { Subscription, takeUntil } from 'rxjs';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { PageId } from '../../../constants/enums';

@Component({
  selector: 'app-roles-list',
  standalone: true,
  imports: [
    RouterLink,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatSelectModule,
    FormsModule,
    CommonModule],
  templateUrl: './roles-list.component.html',
  styleUrls: ['./roles-list.component.scss'],
  animations: [
    trigger('animate', [
      transition('* => *', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class RolesListComponent extends BaseListComponent {
  roles: any = [];
  public dataSource!: MatTableDataSource<any>;
  public displayedColumns: string[] = ["name", "actions"];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  constructor(
    private roleService: RolesService,
    private egretLoader: AppLoaderService,
    route: ActivatedRoute,
    router: Router,
    auth: AuthService,
  ) {
    super(auth, router, route, PageId.bms_roles);
  }

  override async ngOnInit() {
    await super.ngOnInit();
    this.getRoles();
  }

  getRoles() {
    this.egretLoader.open();
    this.roleService.getAllRoles().subscribe(res => {
      this.dataSource = new MatTableDataSource(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.egretLoader.close();
    });
  }


  editRole() {

  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    console.log(this.dataSource.filter, "filter data source");
    if (this.dataSource.filteredData.length === 0) {
      Swal.fire({
        title: '',
        text: 'No records found',
        icon: 'warning'
      })
    }

  }
}
