import { Component, OnDestroy } from '@angular/core';
import { ProductService } from '../product/services/product.service';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CommonService } from '../../services/common.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TypePipe } from '../../shared/pipes/type.pipe';
import { MatDialog } from '@angular/material/dialog';
import { AdminNotificationsFilterDialogComponent } from './admin-notifications-filter-dialog/admin-notifications-filter-dialog.component';
import { AuthService } from '../../services/auth.service';
import { first } from 'rxjs';

@Component({
  selector: 'app-admin-notifications',
  standalone: true,
  imports: [TypePipe, MatIconModule, CommonModule, MatCardModule, MatButtonModule, RouterModule],
  templateUrl: './admin-notifications.component.html',
  styleUrl: './admin-notifications.component.scss'
})
export class AdminNotificationsComponent implements OnDestroy {
  notifications: any = []
  filterList: any = []
  checkQueryParam: boolean = false;
  subscription:any
  constructor(public productService: ProductService,
    public router: Router,
    public dialog: MatDialog, public route: ActivatedRoute,
    public commonService: CommonService, public authService: AuthService) {
    this.commonService.showLoder()
    this.subscription=this.productService.getAdminNotifications().subscribe(res => {
      this.notifications = res
      this.filterList = res;
      if (this.route.snapshot.queryParams["name"]) {
        this.checkQueryParam = true;
        this.filterData({
          name:this.route.snapshot.queryParams["name"],
          type:this.route.snapshot.queryParams["type"],
          vendor_name:this.route.snapshot.queryParams["vendor_name"],
      })
      }
      this.authService.updateAdminNotificationsLastRead()
      this.commonService.hideLoder()
    },
      (error: any) => {
        console.log(error)
        this.commonService.showToaster("error", error.message)
        this.commonService.hideLoder()
      })
  }


  async deleteNotify(item: any) {
    this.commonService.showLoder()
    if(item.payload.type == 'add-sku-to-catalog' ||
    item.payload.type == 'update-sku-to-catalog' ||
    item.payload.type == 'add-sku' ||
    item.payload.type == 'update-sku'){
     this.router.navigate(['/dashboard/product/skus/pending-tab'], {
        queryParams: {id: item.payload.approval_doc_id}
       });
       await this.productService.deleteNotification(item.id)
    }
    else{
      this.router.navigate(['dashboard/pending-approval'], {
        queryParams: {id: item.payload.approval_doc_id}
       });
       await this.productService.deleteNotification(item.id)
    }


    this.commonService.hideLoder()
  }
  openFilter() {
    let routedata: any = { name: '' }
    if (this.route.snapshot.queryParams) {
      routedata = this.route.snapshot.queryParams
    }
    const dialogRef = this.dialog.open(AdminNotificationsFilterDialogComponent, {
      data: routedata,
      panelClass: 'custom-dialog-class'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.filterData(result)
      }
    });
  }
  filterData(result: any) {
    let convertedString:any;
    if(result.type){
      convertedString = result.type.toLowerCase().replace(/\s+/g, '-');
    }
    if(result.name || result.vendor_name || result.type){
      this.checkQueryParam = true;
    if(result.name){
      this.filterList = this.notifications.filter((item:any) =>
     item?.message?.toLowerCase().includes(result.name?.toLowerCase())
    );
  if(result.name && result.vendor_name ){
    this.filterList =  this.filterList.filter((item:any) =>
    item?.payload?.vendor_name?.toLowerCase().includes(result.vendor_name.toLowerCase())
    );
  }
    if(result.name && result.type ){
      this.filterList =  this.filterList.filter((item:any) =>
      item?.payload?.type?.toLowerCase().includes(convertedString.toLowerCase())
      );
  }
  }
   if(result.type){
    this.filterList =  this.notifications.filter((item:any) =>
    item?.payload?.type?.toLowerCase().includes(convertedString.toLowerCase())
    );
    if(result.type && result.vendor_name ){
      this.filterList =  this.filterList.filter((item:any) =>
      item?.payload?.vendor_name?.toLowerCase().includes(result.vendor_name.toLowerCase())
      );
    }
      if(result.type && result.name ){
        this.filterList = this.filterList.filter((item:any) =>
         item?.message?.toLowerCase().includes(result.name?.toLowerCase())
         );
    }
  }
  if(result.vendor_name){
    this.filterList =  this.notifications.filter((item:any) =>
    item?.payload?.vendor_name?.toLowerCase().includes(result.vendor_name.toLowerCase())
    );
    if(result.vendor_name && result.type ){
      this.filterList =  this.filterList.filter((item:any) =>
      item?.payload?.type?.toLowerCase().includes(convertedString.toLowerCase())
      );
    }
      if(result.vendor_name && result.name ){
        this.filterList = this.filterList.filter((item:any) =>
          item?.message?.toLowerCase().includes(result.name?.toLowerCase())
         );
    }
  }
  }
    else {
      this.filterList = this.notifications
      this.checkQueryParam = false;
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }


}
