// pricing-data.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { vendorDetails, skuDetails } from '../../../entities/pricing';

@Injectable({
  providedIn: 'root',
})
export class PricingDataService {
  private rowDataSubject = new BehaviorSubject<any>(null);
  rowData$ = this.rowDataSubject.asObservable();

  setRowData(data: { skuDetails: skuDetails, vendorDetails: vendorDetails }): void {
    this.rowDataSubject.next(data);
  }
}
