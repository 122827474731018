import { Component, HostListener, Inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CategoryService } from '../../category/services/category.service';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
  selector: 'app-varient-type-filter-dialog',
  standalone: true,
  imports: [
    ReactiveFormsModule,MatButtonModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIconModule,
    NgSelectModule
  ],
  templateUrl: './varient-type-filter-dialog.component.html',
  styleUrl: './varient-type-filter-dialog.component.scss'

})
export class VarientTypeFilterDialogComponent {
  filterForm:any;
  categoryList:any =[];
  groupName:any =[
    {value:'other',text:'No Group'},
    {value:'uom',text:'UOM'},
    {value:'package_type',text:'Package Type'}
  ]
  constructor(
    public dialogRef: MatDialogRef<VarientTypeFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb:FormBuilder,
    public categoryService: CategoryService

  ) {
    this.filterForm = this.fb.group({
      name: [null],
      value_type: [null],
      group_name: [null],
      categories_id: [null],
      page: [1],
      limit: [5]
    })
  }
  ngOnInit(): void {
    this.filterForm.patchValue(this.data);
    this.getAll()
  }
  @HostListener('document:keyup.enter', ['$event'])
  handleEnter(event: KeyboardEvent): void {
   console.log("enter key pressed")
   this.submit()
  }
  getAll(){
    this.categoryService.getAll().subscribe((res:any) =>{
      this.categoryList =res.data
    })
  }
  close(){
    this.dialogRef.close()
  }
  reset(){
    this.filterForm.patchValue(
      {
        name: null,
        value_type: null,
        group_name: null,
        categories_id: null,
        page: 1,
        limit: 5
      }
    )
    this.dialogRef.close(this.filterForm.value)
  }
  submit(){
    let data =   {
    name: this.filterForm.value.name,
    value_type: this.filterForm.value.value_type,
      group_name: this.filterForm.value.group_name,
    categories_id: this.filterForm.value.categories_id,
     page: 1,
    limit: 5
    }
    this.dialogRef.close(data)
  }
}

