<!-- <div class="d-flex justify-content-between">
  <h1 mat-dialog-title>{{data?.type}}</h1>

</div> -->
<div mat-dialog-content>
  @if (data?.type == 'error') {
  <div class="d-flex justify-content-center my-3" >
    <img src="assets/images/error.png" class="error-img">
  </div>}
  @if (data?.type == 'success') {
  <div class="d-flex justify-content-center my-3">
    <img src="assets/images/success.svg" class="success-img">
  </div>
}
<p class="text-center" style="font-size: 22px;">{{data?.message}}</p>
</div>
<div mat-dialog-actions>
  <div class="d-flex justify-content-center w-100 mt3">
    <button mat-raised-button color="primary" 
    [mat-dialog-close]="true">Okay</button>
  </div>
</div>