import { Injectable } from '@angular/core';
import { SalesPersonPerformanceEntity, SalesPersonPerformanceListSearch, createPerformanceResponse, createSalesPersonPerformanceResponse } from '../models/salesperonperformance.model';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { FirestoreService } from '../../../services/firestore.service';
import { DB_PATH } from '../../../constants/db-path';
import { GetTargetsResponse, SalesPersonPerformance } from '../../targets/models/targets.model';

@Injectable({
  providedIn: 'root'
})
export class SalesPeronPerformanceService {
  baseServiceUrl: string = `${environment.logisticsUrl}`;
  coseServiceUrl:string=`${environment.coreServiceUrl}`;
  orderServiceUrl:string=`${environment.ordersServiceUrl}`;
  constructor(
    private readonly http: HttpClient,public firestoreService: FirestoreService) {
  }


   async createSalesPersonPerformance (data: SalesPersonPerformanceEntity) {

    let response = await lastValueFrom(this.http.post<{ res: createSalesPersonPerformanceResponse }>(`${this.baseServiceUrl}/api/salespersonperformance/create`, data));

    return response.res
  }

//   async getSalesPeronPerformance (body: SalesPersonPerformanceListSearch): Promise<any> {
//     //const res = await lastValueFrom(this.http.post<any>(`${this.baseServiceUrl}/api/salespersonperformance/get_by_filters`, body));
// let res=this.getData();
//     if (res && res) {
//       return {
//         salesPersonPerformanceList:res, //res.data.rows,
//         totalSalesPeronPerformance: res.length//res.data.count
//       }
//     }

//   }

  async getSalesPeronPerformance(body: SalesPersonPerformanceListSearch): Promise<SalesPersonPerformance[]> {
    try {
      const res = await lastValueFrom(
        this.http.post<GetTargetsResponse>(
          `${this.orderServiceUrl}/query/get_salesperson_performance_goals`,
          body
        )
      );

      console.log("res.data :",res.data)

      return this.mapSalesPeronPerformance(res.data)


    } catch (error) {
      console.error("Error fetching targets:", error);
      return [];
    }
  }


  mapSalesPeronPerformance(res: SalesPersonPerformance[]): SalesPersonPerformance[] {
    return res.map(s => ({
        salesPersonId: s.salesPersonId,
        buyingRevenueAchieved: Math.round(+s.buyingRevenueAchieved),
        nonBuyingRevenueAchieved: Math.round(+s.nonBuyingRevenueAchieved),
        dayRevenueAchieved: Math.round(+s.dayRevenueAchieved),
        dayTasksRevenueAchieved: Math.round(+s.dayTasksRevenueAchieved),
        buyingCountTarget: Math.round(+s.buyingCountTarget),
        buyingCountAchieved: Math.round(+s.buyingCountAchieved)||0,
        nonBuyingCountTarget: Math.round(+s?.nonBuyingCountTarget),
        nonBuyingCountAchieved: s?.nonBuyingCountAchieved||0,
        dayRevenueTarget: Math.round(+s.dayRevenueTarget),
        dayTasksRevenueTarget: Math.round(+s?.dayTasksRevenueTarget),
        tasksCountTarget: Math.round(+s?.tasksCountTarget),
        tasksCountAchieved: Math.round(+s?.tasksCountAchieved),
        petrolAllowance: Math.round(+s?.petrolAllowance),
        incentiveAllowance: Math.round(+s?.incentiveAllowance),
        attendanceAllowance: Math.round(+s.attendanceAllowance),
        dayTaskRevenueTargetPercentage: s?.dayTaskRevenueTargetPercentage,
        attendanceEligibleCount: s?.attendanceEligibleCount,
        nonBuyingRevenueTarget: Math.round(+s.nonBuyingRevenueTarget),
        committed: s?.committed || false,
    }));
}



  async updateAllowance(targetsEntity:any , salespersonId: string,date:string) {
    let response = await lastValueFrom(
      this.http.post<{ res: createPerformanceResponse }>(
        `${this.coseServiceUrl}/api/sales-performance/${salespersonId}/${date}`,
        targetsEntity
      )
    );
    return response.res;
  }


  async getSalesPersonPerformanceById (id: string): Promise<any> {
    const res = await lastValueFrom(this.http.get<any>(`${this.baseServiceUrl}/api/salespersonperformance/get_by_id/${id}`));
    return res;
  }

   async updateSalesPersonPerformance (salespersonperformanceEntity: SalesPersonPerformanceEntity, salespersonperformanceid: string) {
    let { id, ...salespersonperformanceUpdatedEntity } = salespersonperformanceEntity;
    let response = await lastValueFrom(this.http.post<{ res: createSalesPersonPerformanceResponse }>(
      `${this.baseServiceUrl}/api/salespersonperformance/update/${ salespersonperformanceid }`,
      salespersonperformanceUpdatedEntity
    ));
    return response.res
  }

  async deleteSalesPersonPerformance (id: string) {
    return await lastValueFrom(this.http.delete(`${this.baseServiceUrl}/api/salespersonperformance/delete/${id}`));
  }


  async getSalesPersons() {
    const response = await this.firestoreService.fetchCollection(DB_PATH.SALES_PERSON);
    return this.mapToSalesPerson(response);
}

private mapToSalesPerson(salesPersons: Array<any> = []) { // TODO need to define type of sales person

  return salesPersons.map((salesPerson) => {

      return {
          name: salesPerson.name || "",
          mobileNumber: salesPerson.mobileNumber || "",
          id: salesPerson.id || "",
          businessName: salesPerson.businessName || "",
          isActive: salesPerson.isActive || false,
          areaName: salesPerson.cluster ? salesPerson.cluster.name || "" : "",
          areaId: salesPerson.cluster ? salesPerson.cluster.id || "" : "",
          area: salesPerson.cluster || {},
          customerCount: salesPerson.customerCount || 0,
          salesLeadId: salesPerson.salesLeadId || "",
          salesLeadName: salesPerson.salesLead ? salesPerson.salesLead.name || "" : "",
          salesLeadMobileNumber: salesPerson.salesLead ? salesPerson.salesLead.mobileNumber || "" : "",
          salesManagerId: salesPerson.salesManagerId || "",
          salesManagerName: salesPerson.salesManager ? salesPerson.salesManager.name || "" : "",
          salesManagerMobileNumber: salesPerson.salesManager ? salesPerson.salesManager.mobileNumber || "" : "",
          enableLogOut: salesPerson.enableLogOut ? salesPerson.enableLogOut || false : false

      };
  }).sort((a, b) => b.customerCount - a.customerCount)
}


}
