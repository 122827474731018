import { filter, takeUntil } from 'rxjs/operators';
import { Component } from "@angular/core";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { CommonService } from '../../../services/common.service';
import { RolesService } from '../../../services/roles.service';
import { AppLoaderService } from '../../../shared/app-loader/app-loader.service';
import { areObjectsEqual, getDistinctValues } from '../../../utlity/utility';
import Swal from "sweetalert2";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { CommonModule } from '@angular/common';
import { RolesObj } from '../../../models/pages';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { SearchFilterPipe } from '../../../shared/pipes/search-filter.pipe';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { AuthService } from '../../../services/auth.service';
import { PageId } from '../../../constants/enums';

@Component({
  selector: "app-create-edit-roles",
  standalone: true,
  imports: [
    RouterLink,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatSelectModule,
    FormsModule,
    CommonModule,
    SearchFilterPipe],
  templateUrl: "./create-edit-roles.component.html",
  styleUrls: ["./create-edit-roles.component.scss"],
})

export class CreateEditRolesComponent extends BaseListComponent {
  rolesObj: RolesObj = {
    role_name: "",
    permissions: {},
  };
  pages: any;
  id: any;
  savedObject: any;
  roleData: any;
  roleList!: any[];
  applications: any = [];
  selectedApplication: string = "";
  constructor(
    private roleService: RolesService,
    route: ActivatedRoute,
    router: Router,
    auth: AuthService,
    public commonService: CommonService,
    private egretLoader: AppLoaderService
  ) {
    super(auth, router, route, PageId.bms_roles);
  }

  override async ngOnInit() {
    await super.ngOnInit();
    if (this.userId) {
      this.roleService.getAllRoles().subscribe((res) => {
        this.roleList = res.map((role) => { return role['role_name'].trim().toLowerCase() });
      });
      this.route.params.subscribe(async (params) => {
        this.id = params['id'];
        if (this.id) {
          //this.egretLoader.open();
          let res = await this.roleService.getRoleById(this.id);
          this.savedObject = res;
          this.roleData = res;
          this.getPagesWithData();
        }
        else {
          this.getPages();
        }
      });
    }

  }

  getPages() {
    this.egretLoader.open();
    this.roleService.getAllPages().subscribe((res: any) => {
      this.pages = res;
      for (const page of this.pages) {
        this.rolesObj.permissions[page.page_id] = {
          view: false,
          create: false,
          delete: false,
          edit: false,
          import: false,
          export: false,
        };
      }
      this.egretLoader.close();
      this.setApplications(this.pages);
    });
  }

  getPagesWithData() {
    this.egretLoader.open();
    this.roleService.getAllPages().subscribe((res: any) => {
      this.rolesObj.role_name = this.roleData.role_name;
      this.pages = res;
      for (const page of this.pages) {

        this.rolesObj.permissions[page.page_id] = {
          view: this.roleData.permissions[page.page_id]?.view ? this.roleData.permissions[page.page_id]['view'] : false,
          create: this.roleData.permissions[page.page_id]?.create ? this.roleData.permissions[page.page_id]['create'] : false,
          delete: this.roleData.permissions[page.page_id]?.delete ? this.roleData.permissions[page.page_id]['delete'] : false,
          edit: this.roleData.permissions[page.page_id]?.edit ? this.roleData.permissions[page.page_id]['edit'] : false,
          import: this.roleData.permissions[page.page_id]?.import ? this.roleData.permissions[page.page_id]['import'] : false,
          export: this.roleData.permissions[page.page_id]?.export ? this.roleData.permissions[page.page_id]['export'] : false,
        };

      }
      this.setApplications(this.pages);
      this.egretLoader.close();
    });
  }
  private anyPermissionIsTrue(): boolean {
    const pages = this.pages.filter((pageObj: { application_name: string; }) => { return pageObj.application_name == this.selectedApplication });

    for (const page of pages) {
      if (Object.values(this.rolesObj.permissions[page.page_id]).some(value => value === true)) {
        return true;
      }
    }
    return false;
  }
  async saveRoles() {

    if (this.rolesObj.role_name == '') {
      // this.commonService.showAlert('','Something went wrong!','error')
      Swal.fire({
        title: '',
        text: 'Please fill the all mandatory fields!',
        icon: 'warning'
      });
      return true
    }
    const isAnyPermissionTrue = this.anyPermissionIsTrue();
    if (!isAnyPermissionTrue) {
      Swal.fire({
        title: '',
        text: 'Please select atleast one page!',
        icon: 'warning'
      });
      return true

    }

    const trimmedRoleName = this.rolesObj.role_name.trim().toLowerCase();
    const isDuplicate = this.roleList.some(role => {
      const isSameName = role.trim().toLowerCase() === trimmedRoleName;
      const isNewRole = !this.id;
      const isDifferentId = role.id !== this.id;
      return isSameName && (isNewRole || isDifferentId);
    });

    this.egretLoader.open();
    try {
      if (!this.id) {
        let res = await this.roleService.addRoles(this.rolesObj);
        // this.commonService.showAlert('Succesfully','Added Role','success')
        Swal.fire({
          title: '',
          text: 'Role Added Successfully',
          icon: 'success'
        });
      }
      else {
        if (this.savedObject) {
          delete this.savedObject.timestamp;
        }
        if (areObjectsEqual(this.rolesObj, this.savedObject)) {
          Swal.fire({
            title: '',
            text: 'no changes detected!',
            icon: 'warning'
          });
          this.egretLoader.close();
          return;
        }

        if (isDuplicate && areObjectsEqual(this.rolesObj, this.savedObject)) {
          this.egretLoader.close();

          Swal.fire({
            title: "",
            text: "Duplicate role name is not allowed.",
            icon: "warning",
          });

          return;
        }

        let res = await this.roleService.updateRole(this.id, this.rolesObj);
        // this.commonService.showAlert('Succesfully','Updated Role','success')
        Swal.fire({
          title: '',
          text: 'Role Updated Successfully',
          icon: 'success'
        });
      }
      this.egretLoader.close();
      this.router.navigate(['/dashboard/roles'])
      return true;
    } catch (err) {
      console.log(err);
      this.egretLoader.close();
      return false;
    }
  }
  setApplications(pages: any[]) {
    if (pages && pages.length > 0) {
      this.applications = getDistinctValues(pages, "application_name");
      this.selectedApplication = this.applications[0];
    }
  }
}
