import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { HeaderComponent } from '../header/header.component';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { AuthService } from '../../services/auth.service';
@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    MatSidenavModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    HeaderComponent,
    RouterOutlet,
    RouterLink,
    RouterModule
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent {
  //  @ViewChild('drawer') drawer!: MatDrawer;
  @Input() menuItems: any;
  showFiller: any = {};
  screenWidth: any;
  @Input() sidenav!: MatDrawer;
  permission_obj: any = {}
  constructor(public auth: AuthService) {
    this.auth.permission$.subscribe(permissions => {
      this.permission_obj = permissions;
    })
    this.screenWidth = window.innerWidth;
    setTimeout(() => {
      if (this.screenWidth < 768) {
        this.sidenav.toggle();
      }
    }, 1000);

  }
  toggleSubmenu(id: any) {
    this.showFiller[id] = !this.showFiller[id];
  }
  toggleMenu() {
    if (this.screenWidth < 768) {
      this.sidenav.toggle();
    }
  }
  checkSubpagePermission(items: Array<any>): boolean {
    return items.some(subMenu => this.permission_obj[subMenu.id]?.view);
  }
}
