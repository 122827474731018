<div class="container">
  <div class="heading-block">
    <div class="row">
      <div class="col-md-6">
        <mat-icon class="backicon" (click)="goBack()">arrow_back</mat-icon>
      </div>
      <div class="col-md-6 text-right"></div>
    </div>
  </div>
  <div class="col-md-12 mt-3">
    <mat-tab-group>
      <mat-tab label="Assignment">
        <app-trip-summarys-list></app-trip-summarys-list>
      </mat-tab>
      <mat-tab label="HOPS">
        <app-hop-list></app-hop-list>
      </mat-tab>
    </mat-tab-group>
  </div>
  <router-outlet></router-outlet>
</div>
