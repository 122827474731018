import { equalTo } from '@angular/fire/database';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { FirestoreService } from '../../../services/firestore.service';
import { DB_PATH } from '../../../constants/db-path';
import { ErrorEntity, ErrorSearchFilter } from '../model/model';
import { WhereFilterOp } from '../../../enums/enums';

@Injectable({
  providedIn: 'root',
})
export class ErrorLogService {
  constructor(public firestoreService: FirestoreService) {}

  async getErrorLOgsByFilters(input: ErrorSearchFilter): Promise<any> {
    const filters: Array<any> = [
      {
        fieldName: 'createdDate',
        operator: WhereFilterOp.GreaterThanOrEqualTo,
        fieldValue: input.startDate,
      },
      {
        fieldName: 'createdDate',
        operator: WhereFilterOp.LessThanOrEqualTo,
        fieldValue: input.endDate,
      },
    ];

    if (input.messageType && input.messageType.trim() !== '') {
      filters.push({
        fieldName: 'messageType',
        operator: WhereFilterOp.EqualTo,
        fieldValue: input.messageType.trim(),
      });
    }

    console.log('filters', filters);
    const response =
      (await this.firestoreService.queryCollectionByFilters(
        DB_PATH.BMS_HM_Queue_Log,
        filters
      )) || [];

    console.log('response', response);

    return this.mapErrorLogs(response);
  }

  async getErrorLOgs() {
    const response = await this.firestoreService.fetchCollection(
      DB_PATH.BMS_HM_Queue_Log
    );
    return this.mapErrorLogs(response);
  }

  private mapErrorLogs(errorLogs: Array<any> = []): ErrorEntity[] {
    const res = errorLogs
      .map((errorLog) => {
        const formattedDate = this.formatTimestamp(errorLog?.createdTimeStamp);
        return {
          status: errorLog.status || '',
          updatedTimeStamp: errorLog.updatedTimeStamp || '',
          id: errorLog.id || '',
          messageResult: {
            message: errorLog?.messageResult?.message || '',
            errors: errorLog?.messageResult?.errors || '',
            status: errorLog?.messageResult?.status || '',
          },
          createdDate: formattedDate,
          messageBody: errorLog.messageBody || '',
          messageType: errorLog?.messageType || '',
          createdTimeStamp: errorLog?.createdTimeStamp || 1577015520,
        };
      })
      .sort((a, b) => b.createdTimeStamp - a.createdTimeStamp);

    return res;
  }

  formatTimestamp(timestamp: number = 1577015520): string {
    const date = new Date(timestamp);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
}
