import { Injectable, inject } from '@angular/core';
 import {
  Firestore,
  addDoc,
  collection,
  collectionData,
  deleteDoc,
  doc,
  docData,
  getDoc,
  orderBy,
  query,
  setDoc,
  updateDoc,
} from '@angular/fire/firestore';
@Injectable({
  providedIn: 'root'
})
export class RolesService {
  firestore: Firestore = inject(Firestore);
  constructor(
  ) { }

 getAllPages() {
    const aCollection = collection(this.firestore, 'pages');
    let q = query(aCollection);
    let items$ = collectionData(q, { idField: 'id' });
    return items$;
  }
  getAllRoles() {
    const aCollection = collection(this.firestore, 'Roles');
    let q = query(aCollection);
    let items$ = collectionData(q, { idField: 'id' });
    return items$;
  }
  async getRoleById(id: any) {
    const aDoccument = doc(this.firestore, `Roles/${id}`);
    let item = (await getDoc(aDoccument)).data();
    return item;
  }
  addRoles(data: any) {
    data.timestamp = new Date();
    const aDoccument = collection(this.firestore, 'Roles');
    return addDoc(aDoccument, data);
  }
  updateRole(id: any,data:any) {
    const aDoccument = doc(this.firestore, `Roles/${id}`);
    return updateDoc(aDoccument,data);

  }
  deleteRoles(id: any) {
    const aDoccument = doc(this.firestore, `Roles/${id}`);
    return deleteDoc(aDoccument);
  }
}
