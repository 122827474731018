<ng-container>
  <div class="p-3">
    <div class="row">
      <div class="col-md-6">
        <h2 class="card-title m-3 text-violet">Add Pricing Rule</h2>
      </div>
      <div class="col-md-6 text-right">
        <mat-icon (click)="dialogRef.close()" class="float-right m-3 closeicon closeicon">
          close
        </mat-icon>
      </div>
    </div>



    <div class="mat-elevation-z8">


        <form [formGroup]="dataSource" class="m-3 formcss">
          <div class="table-container p-2">
            <div class="row filters">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="lableclass">
                      <mat-label>Min Qty</mat-label>
                    </div>
                    <mat-form-field appearance="outline" class="w-100">
                      <input matInput type="number" formControlName="min_quantity" name="min_quantity"
                        placeholder="Min Qty" [readonly]="isUpdate" />
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="lableclass">
                      <mat-label>Max Qty</mat-label>
                    </div>
                    <mat-form-field appearance="outline" class="w-100">
                      <input matInput type="number" formControlName="max_quantity" name="max_quantity"
                        placeholder="Max Qty" [readonly]="isUpdate" />
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-md-6 ">
                  <div class="lableclass">
                    <mat-label>Discount Type</mat-label>
                  </div>
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="pricing_type" placeholder="Select Scheme">
                      <mat-option [value]="1">Rate</mat-option>
                      <mat-option [value]="2">DiscountPercentage</mat-option>
                      <mat-option [value]="3">DiscountAmount</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="lableclass">
                      <mat-label>Value</mat-label>
                    </div>
                    <mat-form-field appearance="outline" class="w-100">
                      <input matInput type="number" formControlName="pricing_value" name="pricing_value"
                        placeholder="Discount Value" />
                    </mat-form-field>
                  </div>
                </div>


              </div>
              <div class="">
                <label class="errorMessage" *ngIf="isFieldsEmpty">Fields should not be empty.</label>
                <label class="errorMessage" *ngIf="shouldBeNumbers">Values should not have Negative or Special
                  Characters</label>
                <label class="errorMessage" *ngIf="isSlabExist">Slabs are Overlapping Please Check</label>
              </div>
            </div>
            <div class="col-md-12 custom-flex">
              <div class="text-center mt-2 flex-item ml-10">
                @if (loading) {
                  <mat-spinner [diameter]="30"></mat-spinner>
                  }
                  @else {
                    <button mat-raised-button color="primary" class="text-center ml" (click)="save()">
                      Save
                    </button>
                  }

              </div>
              <div class="text-center mt-2 flex-item">

              </div>
            </div>
          </div>
        </form>


    </div>
  </div>
</ng-container>
