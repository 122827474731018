import { filter, takeUntil } from 'rxjs/operators';
import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CommonService } from '../../../services/common.service';
import { RolesService } from '../../../services/roles.service';
import { UsersService } from '../../../services/users.service';
import { AppLoaderService } from '../../../shared/app-loader/app-loader.service';
import {
  areObjectsEqual,
  isValidEmail,
  isValidMobileNumber,
} from '../../../utlity/utility';
import { Observable, of, switchMap } from 'rxjs';
import Swal from 'sweetalert2';
import {
  CreateUserType,
  LookUpType,
  SwalMessageTypes,
} from '../../../enums/enums';
import { RolePermissions } from '../../../models/pages';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { SearchFilterPipe } from '../../../shared/pipes/search-filter.pipe';
import { LookUpTypeService } from '../../../services/lookup-type.service';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { AuthService } from '../../../services/auth.service';
import { PageId } from '../../../constants/enums';

@Component({
  selector: 'app-create-edit-manage-users',
  standalone: true,
  imports: [
    RouterLink,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatSelectModule,
    FormsModule,
    CommonModule,
    SearchFilterPipe,
  ],
  templateUrl: './create-edit-manage-users.component.html',
  styleUrls: ['./create-edit-manage-users.component.scss'],
})
export class CreateEditManageUsersComponent extends BaseListComponent {
  userObj = {
    name: '',
    email_id: '',
    mobile_number: '',
    password: '',
    roles: [],
    user_type: 0,
    status: '',
    vendor_id: '',
    extended_permissions: {} as RolePermissions,
  };
  //userObj: any;
  res: any;
  userTypes = CreateUserType;
  selectedUserType!: number;
  combined_permissions: RolePermissions = {};
  role_permissions: RolePermissions = {};
  pages: any;
  id: any;
  userData: any;
  savedObject: any;
  rolesList: any = [];
  selectedRoles: any;
  new_permissions = {};
  applications: any = [];
  selectedApplication: string = '';
  vendorSelected: string = '';
  vendorListSearchInput: LookUpType = this.initializeLookUpTypeSearchInput();
  vendorList: Array<any> = [];
  vendorSearchList: Array<any> = [];
  constructor(
    private roleService: RolesService,
    private usersService: UsersService,
    route: ActivatedRoute,
    router: Router,
    auth: AuthService,
    public commonService: CommonService,
    private egretLoader: AppLoaderService,
    private lookupService: LookUpTypeService
  ) {
    super(auth, router, route, PageId.bms_users);
  }

  override async ngOnInit() {
    super.ngOnInit();
    if (this.userId) {
      this.usersService.getAllRoles().subscribe((res) => {
        this.rolesList = res;
      });
      this.res = this.getUserTypeKeys();
      this.route.params.subscribe(async (params) => {
        this.id = params['id'];
        if (this.id) {
          this.egretLoader.open();
          let res = await this.usersService.getUserById(this.id);
          this.savedObject = JSON.parse(JSON.stringify(res));
          this.userData = res;

          this.getPagesWithData();
        } else {
          this.getPages().subscribe(() => {
            this.setApplications(this.pages);
          });
        }
      });
      await this.getMerchants();
    }
  }
  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }
  private initializeLookUpTypeSearchInput(): LookUpType {
    return {
      tableName: 'vendor',
      lookupType: 'vendor',
      idColumnName: 'id',
      valueColumnName: 'name',
    };
  }
  async getMerchants() {
    try {
      const response = await this.lookupService.getLookUpTypes(
        this.vendorListSearchInput
      );
      const vendorsRes =   response &&
      response.data &&
      response.data.find(
        (d: any) => d.tableName == 'vendor'
      );
      // response.data.find((d:any)=>d.tableName =='vendor')


      this.vendorList = (vendorsRes && vendorsRes.data)  && vendorsRes.data|| [];
      this.vendorSearchList = (vendorsRes && vendorsRes.data) || [];

    } catch (error) {
      this.egretLoader.close();
      this.showMessage(
        'Failed to Fetch Merchants. Please try again later.',
        SwalMessageTypes.Error
      );
    }
  }
  onKey(value: any) {
    const searchKeyword = value.target.value || '';
    this.vendorSearchList = this.search(searchKeyword);
  }

  search(value: string) {
    let filter = value.toLowerCase();
    return this.vendorList.filter((option) =>
      option.value.toLowerCase().includes(filter)
    );
  }
  getUserTypeKeys() {
    return Object.keys(this.userTypes).filter(
      (key) =>
        !isNaN(Number(this.userTypes[key as keyof typeof CreateUserType]))
    );
    // console.log("res.........",res);
  }

  resetPermission() {
    const applicationPages = this.pages.filter(
      (page: { application_id: string }) => {
        return page.application_id == this.selectedApplication;
      }
    );

    for (const page of applicationPages) {
      this.combined_permissions[page.page_id] = {
        view: false,
        create: false,
        delete: false,
        edit: false,
        import: false,
        export: false,
      };
      this.role_permissions[page.page_id] = {
        view: false,
        create: false,
        delete: false,
        edit: false,
        import: false,
        export: false,
      };
    }
  }
  getSelectedRoles(event: any) {
    this.resetPermission();
    if (event.value && event.value.length > 0) {
      this.selectedRoles = this.rolesList.filter((role: { id: any }) => {
        return event.value.includes(role.id);
      });

      for (const role of this.selectedRoles) {
        for (const page of this.pages) {
          this.combined_permissions[page.page_id] = {
            view: this.getCombinedPermissionValue(role, page, 'view'),
            create: this.getCombinedPermissionValue(role, page, 'create'),
            delete: this.getCombinedPermissionValue(role, page, 'delete'),
            edit: this.getCombinedPermissionValue(role, page, 'edit'),
            import: this.getCombinedPermissionValue(role, page, 'import'),
            export: this.getCombinedPermissionValue(role, page, 'export'),
          };
          this.role_permissions[page.page_id] = {
            view: this.getRolePermissionValue(role, page, 'view'),
            create: this.getRolePermissionValue(role, page, 'create'),
            delete: this.getRolePermissionValue(role, page, 'delete'),
            edit: this.getRolePermissionValue(role, page, 'edit'),
            import: this.getRolePermissionValue(role, page, 'import'),
            export: this.getRolePermissionValue(role, page, 'export'),
          };
        }
      }
    } else {
      const applicationPages = this.pages.filter(
        (page: { application_id: string }) => {
          return page.application_id == this.selectedApplication;
        }
      );
      for (const page of applicationPages) {
        this.combined_permissions[page.page_id] = {
          view: false,
          create: false,
          delete: false,
          edit: false,
          import: false,
          export: false,
        };
      }
    }
  }
  private getCombinedPermissionValue(
    role: any,
    page: any,
    action: string
  ): boolean {
    const combinedPermission =
      this.combined_permissions[page.page_id]?.[action] == true ||
      role.permissions[page.page_id]?.[action] == true ||
      this.userObj.extended_permissions[page.page_id]?.[action] == true
        ? true
        : false;
    return combinedPermission;
  }

  private getRolePermissionValue(
    role: any,
    page: any,
    action: string
  ): boolean {
    const rolePermission =
      this.role_permissions[page.page_id]?.[action] == true ||
      role.permissions[page.page_id]?.[action] == true
        ? true
        : false;
    return rolePermission;
  }

  getPages(): Observable<any[]> {
    // this.egretLoader.open();
    return this.roleService.getAllPages().pipe(
      switchMap((res: any) => {
        this.pages = res;
        for (const page of this.pages) {
          this.userObj.extended_permissions[page.page_id] = {
            view: false,
            create: false,
            delete: false,
            edit: false,
            import: false,
            export: false,
          };
          this.combined_permissions[page.page_id] = {
            view: false,
            create: false,
            delete: false,
            edit: false,
            import: false,
            export: false,
          };
          this.role_permissions[page.page_id] = {
            view: false,
            create: false,
            delete: false,
            edit: false,
            import: false,
            export: false,
          };
        }

        this.setApplications(this.pages);
        // this.egretLoader.close();
        return of(this.pages);
      })
    );
  }

  getPagesWithData() {
    this.roleService.getAllPages().subscribe((res: any) => {
      this.userObj.name = this.userData.name;
      this.userObj.email_id = this.userData.email_id;
      this.userObj.mobile_number = this.userData.mobile_number;
      this.userObj.password = this.userData.password;
      this.userObj.status = this.userData.status;
      this.userObj.roles = this.userData.roles;
      this.userObj.user_type = this.userData.user_type;
      this.userObj.vendor_id = this.userData.vendor_id || '';
      this.selectedUserType = this.userData.user_type;
      this.pages = res;

      this.setApplications(this.pages);
      //var roles = this.rolesList.filter((role) => { return this.userObj.roles?.[this.selectedApplication].includes(role.id) });
      let event = {
        value: this.userObj.roles,
      };

      for (const page of this.pages) {
        this.userObj.extended_permissions[page.page_id] = {
          view:
            this.userData.extended_permissions[page.page_id]?.['view'] || false,
          create:
            this.userData.extended_permissions[page.page_id]?.['create'] ||
            false,
          delete:
            this.userData.extended_permissions[page.page_id]?.['delete'] ||
            false,
          edit:
            this.userData.extended_permissions[page.page_id]?.['edit'] || false,
          import:
            this.userData.extended_permissions[page.page_id]?.['import'] ||
            false,
          export:
            this.userData.extended_permissions[page.page_id]?.['export'] ||
            false,
        };
      }
      this.getSelectedRoles(event);
      this.egretLoader.close();
    });
  }

  compareFn(val1: any, val2: any) {
    return val1 || val2 ? val1 === val2 : false;
  }
  async saveUser() {
    this.egretLoader.open();
    try {
      this.userObj['user_type'] = this.selectedUserType;
      this.setExtendedPermissions();
      console.log('this.userObj:- ', this.userObj);
      console.log(
        'this.roles:- ',
        this.userObj.roles,
        this.isAnyPermissionSelected()
      );
      if (this.userObj.roles?.length == 0 && this.isAnyPermissionSelected()) {
        Swal.fire({
          title: '',
          text: 'Please select any role to extend!',
          icon: 'warning',
        });
        this.egretLoader.close();
        return;
      }

      if (
        this.userObj.name == '' ||
        this.userObj.email_id == '' ||
        this.userObj.mobile_number == '' ||
        this.userObj.password == '' ||
        this.userObj.user_type == null
      ) {
        // this.commonService.showAlert("", "Something went wrong!", "error");
        Swal.fire({
          title: '',
          text: 'Please fill all mandatory fields.',
          icon: 'warning',
        });
        this.egretLoader.close();
        return true;
      }
      if (!isValidMobileNumber(this.userObj.mobile_number)) {
        Swal.fire({
          title: '',
          text: 'Please enter a valid phone number.',
          icon: 'error',
        });
        this.egretLoader.close();
        return true;
      }
      if (!this.id) {
        if (!isValidEmail(this.userObj.email_id)) {
          Swal.fire({
            title: '',
            text: 'Please enter a valid email address.',
            icon: 'error',
          });
          this.egretLoader.close();
          return true;
        }
        const userWithSameEmail = await this.usersService.getAuthUserByEmailId(
          this.userObj.email_id
        );
        console.log('Duplicate Mail Id  ::: ', userWithSameEmail);
        if (userWithSameEmail?.email) {
          Swal.fire({
            title: '',
            text: 'User already exists with given mailId, please use another mailId',
            icon: 'warning',
          });
          this.egretLoader.close();
          return true;
        }
        let res = await this.usersService.addUsers(this.userObj);
        // this.commonService.showAlert("Succesfully", "Added User", "success");
        Swal.fire({
          title: '',
          text: 'User Added successfully.',
          icon: 'success',
        });
        //return true;
      } else {
        if (this.savedObject) {
          if ('timestamp' in this.savedObject) {
            delete this.savedObject.timestamp;
          }
          // if ('password' in this.savedObject) {
          //   delete this.savedObject.password;
          // }
          // if ('password' in this.userObj) {
          //   delete this.userObj.password;
          // }
        }
        if (areObjectsEqual(this.userObj, this.savedObject)) {
          Swal.fire({
            title: '',
            text: 'no changes detected!',
            icon: 'warning',
          });
          this.egretLoader.close();
          return true;
        }
        if (
          this.userObj.name == '' ||
          this.userObj.email_id == '' ||
          this.userObj.mobile_number == ''
        ) {
          this.commonService.showAlert('', 'Something went wrong!', 'error');

          this.egretLoader.close();
          return true;
        }
        let res = await this.usersService.updateUsers(this.id, this.userObj);
        // this.commonService.showAlert("Succesfully", "Updated User", "success");

        Swal.fire({
          title: '',
          text: 'User Updated successfully.',
          icon: 'success',
        });
      }
      this.egretLoader.close();
      this.router.navigate(['/dashboard/users']);
      return true;
    } catch (err) {
      this.egretLoader.close();
      console.log(err);
      return true;
    }
  }
  isAnyExtendedRole(): boolean {
    const applicationPages = this.pages.filter(
      (page: { application_id: string }) => {
        return page.application_id == this.selectedApplication;
      }
    );
    for (const page of applicationPages) {
      if (
        Object.values(this.userObj.extended_permissions[page.page_id]).some(
          (value) => value === true
        )
      ) {
        return true;
      }
    }
    return false;
  }
  isAnyPermissionSelected(): boolean {
    const applicationPages = this.pages.filter(
      (page: { application_id: string }) => {
        return page.application_id == this.selectedApplication;
      }
    );
    for (const page of applicationPages) {
      if (
        Object.values(this.combined_permissions[page.page_id]).some(
          (value) => value === true
        )
      ) {
        return true;
      }
    }
    return false;
  }

  changeApplication(event: any) {
    this.selectedApplication = event;
    let eventChangeApplication = {
      value: this.userObj.roles,
    };
    this.getSelectedRoles(eventChangeApplication);
  }
  setExtendedPermissions() {
    const applicationPages = this.pages.filter(
      (page: { application_id: string }) => {
        return page.application_id == this.selectedApplication;
      }
    );
    for (const page of applicationPages) {
      this.userObj.extended_permissions[page.page_id] = {
        view: false,
        create: false,
        delete: false,
        edit: false,
        import: false,
        export: false,
      };
    }
    const roleIds: Array<string> = this.userObj.roles;
    const applicationRoles = this.rolesList.filter((role: { id: any }) => {
      return roleIds.includes(role.id);
    });
    if (applicationRoles.length > 0) {
      for (const page of applicationPages) {
        for (const permission in this.combined_permissions[page.page_id]) {
          if (
            this.combined_permissions[page.page_id][permission] &&
            !this.isTrueInAnyRole(applicationRoles, page, permission)
          ) {
            this.userObj.extended_permissions[page.page_id][permission] = true;
          } else {
            this.userObj.extended_permissions[page.page_id][permission] = false;
          }
        }
      }
    } else {
      for (const page of this.pages) {
        for (const permission in this.combined_permissions[page.page_id]) {
          this.userObj.extended_permissions[page.page_id][permission] = false;
        }
      }
    }
  }

  private isTrueInAnyRole(
    applicationRoles: any[],
    page: any,
    action: string
  ): boolean {
    const isTrue = applicationRoles.some(
      (obj: any) => obj?.permissions?.[page.page_id]?.[action] ?? false
    );
    return isTrue;
  }

  setApplications(pages: any[]): void {
    if (pages && pages.length > 0) {
      const uniqueApplications = Array.from(
        new Set(pages.map((obj) => obj.application_id))
      ).map((application_id) => {
        return pages.find((obj) => obj.application_id === application_id);
      });

      console.log('uniqueApplications:- ', uniqueApplications);
      this.applications = uniqueApplications;
      this.selectedApplication = this.applications[0].application_id;
    }
  }
}
